import {
  assertPartialSchema, 
  createAction, 
  useSelector, 
  v,
} from '../../../lib'

export enum SessionActionType {
  SESSION_EXPIRED_SET = 'SESSION_EXPIRED_SET'
}

export const doSESSION_EXPIRED_SET = createAction(
  SessionActionType.SESSION_EXPIRED_SET
)

export interface SessionState {
  sessionExpired: boolean
}

interface RootState {
  session: SessionState
}

export const selectSessionState = () => {
  return useSelector((state: RootState) => state.session)
}

export const sessionActionCreators = {
  doSESSION_EXPIRED_SET,
}

export const sessionStateDefaultState: SessionState = {
  sessionExpired: false,
}

type Action = 
  | {
      type: SessionActionType.SESSION_EXPIRED_SET
      payload: {
        sessionExpired: boolean
      }
  }

export const sessionReducer = (
  state: SessionState = {...sessionStateDefaultState}, 
  {type, payload}: Action
) => {
  switch (type) {
    case SessionActionType.SESSION_EXPIRED_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          sessionExpired: v.boolean().exist(),
        })
      })

      return {...state, sessionExpired: payload.sessionExpired}

    default:
      return {...state}
  }
}
