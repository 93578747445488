import {CSSProperties, useMemo, useState} from 'react'
import {
  CorrelationResult,
  CorrelationResultAnalysisType,
  CorrelationResultCoordinate,
  CorrelationResultCorrelationData,
  CorrelationResultGraph,
  Variable,
  VariableDataType,
} from '../../../shared/analysis'
import ReactGridLayout from 'react-grid-layout'
import {CorrelationGraph} from './correlation_graph'
import {CorrelationCoordinate} from './correlation_coordinate'
import {getVariableCode, getVariableDisplayName} from '../../../engine'
import {DataTypeDisplayNameMap} from '../../../model'
import {selectTheme} from '../../../store'

enum CorrelationMatrixItemType {
  Graph = 'graph',
  Header = 'header',
  Blank = 'blank',
}

interface CorrelationMatrixItemBase {
  dataGrid: {
    x: number
    y: number
    w: number
    h: number
    static: boolean
  }
  type: CorrelationMatrixItemType
}

interface CorrelationMatrixGraphItem extends CorrelationMatrixItemBase {
  graph: CorrelationResultGraph
  coord: CorrelationResultCoordinate
  data: CorrelationResultCorrelationData[]
}

interface CorrelationMatrixHeaderItem extends CorrelationMatrixItemBase {
  dataType: VariableDataType
  variableCode: string
  value: string
}

type CorrelationMatrixBlankItem = CorrelationMatrixItemBase

type CorrelationMatrixItem = CorrelationMatrixGraphItem | CorrelationMatrixHeaderItem | CorrelationMatrixBlankItem

export const CorrelationMatrix = (
  props: CorrelationResult & {
    itemStyle?: CSSProperties
    selected?: CorrelationCoordinate
    onClickGraph: (coord: CorrelationCoordinate) => void
    displayingVariableList: Variable[]
  },
) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {items, selected, onClickGraph, displayingVariableList} = props
  const blank = {
    dataGrid: {x: 0, y: 0, w: 1, h: 1, static: true},
    type: CorrelationMatrixItemType.Blank,
    dataType: '',
    value: '',
  }
  const [table, setTable] = useState<CorrelationMatrixItem[]>([blank])
  useMemo(() => {
    if (!items.length || !displayingVariableList.length) return setTable([blank])
    const header: CorrelationMatrixItem[] = displayingVariableList.flatMap((variable, i) => [
      {
        dataGrid: {x: i * 5 + 1, y: 0, w: 5, h: 1, static: true},
        dataType: variable.dataType,
        variableCode: getVariableCode(i),
        value: getVariableDisplayName(variable),
        type: CorrelationMatrixItemType.Header,
      },
      {
        dataGrid: {x: 0, y: i * 5 + 1, w: 1, h: 5, static: true},
        dataType: variable.dataType,
        variableCode: getVariableCode(i),
        value: getVariableDisplayName(variable),
        type: CorrelationMatrixItemType.Header,
      },
    ])
    const content = items.flatMap((row, rowIndex) => {
      return row.map((item, columnIndex) => {
        const {coord, graph, data} = item
        const correlationData = data.filter(
          ({analysis_type}) => analysis_type === CorrelationResultAnalysisType.Correlation,
        ) as CorrelationResultCorrelationData[]
        return {
          dataGrid: {x: columnIndex * 5 + 1, y: rowIndex * 5 + 1, w: 5, h: 5, static: true},
          type: CorrelationMatrixItemType.Graph,
          graph,
          coord,
          data: correlationData,
        }
      })
    })
    setTable([blank, ...header, ...content])
  }, [displayingVariableList, items])

  return (
    <ReactGridLayout
      cols={props.items[0].length * 5 + 1}
      width={props.items[0].length * 216 + 40}
      rowHeight={40}
      margin={[8, 8]}
    >
      {table.map((tableItem) => {
        if (tableItem.type === CorrelationMatrixItemType.Graph) {
          const graphItem = tableItem as CorrelationMatrixGraphItem
          const {dataGrid, coord, graph, data} = graphItem
          return (
            <div key={`${dataGrid.x}-${dataGrid.y}`} data-grid={dataGrid} style={props.itemStyle}>
              <CorrelationGraph
                selected={selected}
                onClickGraph={onClickGraph}
                coord={coord}
                item={graph}
                data={data}
              />
            </div>
          )
        }
        if (tableItem.type === CorrelationMatrixItemType.Blank) {
          return <div key={0} data-grid={{x: 0, y: 0, w: 1, h: 1, static: true}} />
        }
        if (tableItem.type === CorrelationMatrixItemType.Header) {
          const headerItem = tableItem as CorrelationMatrixHeaderItem
          const {dataGrid, value} = headerItem
          return (
            <div
              key={`${dataGrid.x}-${dataGrid.y}`}
              data-grid={dataGrid}
              style={{
                backgroundColor: color.tag.grey.light,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                css={{
                  transform: dataGrid.x === 0 ? 'rotate(-90deg)' : 'none',
                  transformOrigin: 'center',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                  }}
                >
                  <p
                    css={{
                      width: '12px',
                      height: '12px',
                      lineHeight: '12px',
                      borderRadius: '50%',
                      fontSize: fontSize.h8,
                      fontWeight: fontWeight.thick,
                      color: color.white,
                      backgroundColor: color.surface.dark,
                      textAlign: 'center',
                      marginRight: '4px',
                    }}
                  >
                    {headerItem.variableCode}
                  </p>
                  <p
                    css={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {DataTypeDisplayNameMap[headerItem.dataType]}
                  </p>
                </div>
                <p
                  css={{
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {value}
                </p>
              </div>
            </div>
          )
        }
      })}
    </ReactGridLayout>
  )
}
