import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'
import {CorrelationConfig, Variable} from '../../shared/analysis'
import { getToken } from './token_fetcher'
import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH = 'REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH'
export const doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH = createDoRequestAction(
  REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH,
)

export const requestAnalyticGraphCorrelationIndividualFetchActionCreators = {
  doREQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH,
}

export function* requestAnalyticGraphCorrelationIndividualFetch({
  payload,
  type,
  setRequestResult
}: {
  payload: {
    requestId: string
    participantId: string
    yymmddIndexStart: number
    yymmddIndexEnd: number
    variables: Variable[]
    config?: CorrelationConfig
  }
  type: string
  setRequestResult: Dispatch<SetStateAction<RequestResult>>
}): SagaIterator {
  debug('saga*requestAnalyticGraphCorrelationIndividualFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        yymmddIndexStart: v.number(),
        yymmddIndexEnd: v.number(),
        variables: v
          .array()
          .min(2)
          .max(4)
          .items(
            v
              .object({
                identifier: v.string().exist(),
              })
              .unknown(true)
              .required(),
          )
          .required(),
        config: v
          .object({
            color_discreate_sequence: v.array().items(v.string()).optional(),
          })
          .optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {participantId, yymmddIndexStart, yymmddIndexEnd, variables, config} = payload
  const accessToken = yield call(getToken)
  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/analytic-graph-correlation-individual-fetch`,
    accessToken,
    data: {
      participantId,
      yymmddIndexStart,
      yymmddIndexEnd,
      variables,
      config,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/analytic-graph-correlation-individual-fetch',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* analyticGraphCorrelationIndividualFetchSaga() {
  yield takeEvery(REQUEST_ANALYTIC_GRAPH_CORRELATION_INDIVIDUAL_FETCH, requestAnalyticGraphCorrelationIndividualFetch)
}
