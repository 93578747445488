import { IEntityModel } from './base'
import { IProject } from './project'
import { ITask } from './task'
import { IWorkspace } from './workspace'

export enum MovesenseSampleRate {
  HZ_13 = 13,
  HZ_26 = 26,
  HZ_52 = 52,
  HZ_104 = 104,
  HZ_208 = 208,
}

// QUESTION? why include ones with small gaps?
export enum MovesenseECGSampleRate {
  HZ_125 = 125,
  HZ_128 = 128,
  HZ_200 = 200,
  HZ_250 = 250,
  HZ_256 = 256,
  HZ_500 = 500,
  HZ_512 = 512,
}

export interface IStopwatchMovesenseStream extends IEntityModel {
  taskId: string
  projectId: string
  workspaceId: string
  name: string
  description: string
  task?: ITask
  project?: IProject
  workspace?: IWorkspace
  rrEnable: boolean
  imuEnable: boolean
  accEnable: boolean
  ecgEnable: boolean
  gyroscopeEnable: boolean
  magnetometerEnable: boolean
  ecgHz: MovesenseECGSampleRate
  imuHz: MovesenseSampleRate
  accHz: MovesenseSampleRate
  gyroscopeHz: MovesenseSampleRate
  magnetometerHz: MovesenseSampleRate
  timeLimitSeconds: number
}
