import { selectTheme } from "../../store"
import { 
  ButtonCancel,
  Button,
  TextLink,
} from ".."

interface PopupConfirmUnsavedChangesProps {
  closeAction: () => void
  discardAction: () => void
  saveAction: () => void
}

export const PopupConfirmUnsavedChanges = (props: PopupConfirmUnsavedChangesProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {closeAction, discardAction, saveAction} = props

  return (
    <div css={{
      background: '#00000040',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 104,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div css={{
        padding: '30px',
        borderRadius: '5px',
        background: color.white,
        boxShadow: '0px 4px 10px 0px #0000001A',
        width: '560px',
      }}>
        <ButtonCancel onClick={closeAction}/>
        <p css={{
          marginTop: '24px',
          marginBottom: '16px',
          fontSize: fontSize.h3,
          fontWeight: fontWeight.thick,
        }}>You have unsaved changes</p>
        <p css={{
          marginBottom: '98px',
        }}>Do you want to save changes before closing?</p>
        <div css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <TextLink 
            textColor={'warning'} 
            onClick={discardAction}
            css={{
              fontWeight: fontWeight.thick,
            }}
          >Discard Changes</TextLink>
          <Button onClick={saveAction}>Save Settings</Button>
        </div>
      </div>
    </div>
  )
}