import {selectTheme, selectMethod, selectTempTaskData, selectProjectData} from '../../store'
import {RIF, sortBy} from '../../lib'
import {useParams} from 'react-router-dom'
import {Tooltip} from '..'
import MobileAppPreviewImg from '../../asset/image/mobile_app_preview.png'
import {RepeatIcon, AboutProjectIcon, ParticipantTaskIcon, InstructionIcon, ContactsIcon} from '../../asset/image'
import {TaskStateType, TaskTempStateType, TaskType, getTaskContentName} from '../../model'

export interface MobileAppPreviewPropsT {
  aboutProjectValue: Record<string, any>
  instructionValue: string
  contactValue: string
  currentBlock: string
}

export const MobileAppPreview = (props: MobileAppPreviewPropsT) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  const {aboutProjectValue, instructionValue, contactValue, currentBlock} = props

  const methodState = selectMethod()
  const taskList: TaskStateType[] =
    methodState?.taskList
      ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
      .sort(sortBy('index')) || []
  const tempTaskData = selectTempTaskData()
  const tempTaskList =
    tempTaskData?.taskList?.filter((item: TaskTempStateType) => item.type !== TaskType.GarminDevice && item.enabled) || []
  const {projectId} = useParams()
  const project = selectProjectData()[projectId]
  const isEditingLiveProject = project?.status === 'live'

  return (
    // background
    <div
      css={{
        width: '280px',
        height: '500px',
        padding: '30px 40px',
        backgroundImage: `url(${MobileAppPreviewImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        top: isEditingLiveProject ? '100px' : '60px',
        right: '30px',
      }}
    >
      {/* container */}
      <div css={{width: '100%', height: '98%', overflowY: 'hidden'}}>
        {/* aboutProject */}
        {RIF(
          currentBlock === 'aboutProject' || currentBlock === 'device',
          <div css={{width: '100%', padding: `45px 15px`}}>
            <div
              css={{
                width: '40px',
                height: '40px',
                background: color.background,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '16px',
              }}
            >
              <img src={AboutProjectIcon} width='24px' />
            </div>
            <p
              css={{
                fontSize: fontSize.h5,
                fontWeight: fontWeight.thick,
                marginBottom: '16px',
              }}
            >
              About Project
            </p>
            <p css={{fontSize: '12px', color: color.grey_400, marginBottom: pad.xs}}>Project</p>
            <p
              css={{
                fontSize: '16px',
                fontWeight: fontWeight.thick,
                marginBottom: '15px',
                wordBreak: 'break-word',
                whiteSpace: 'break-spaces',
              }}
            >
              {aboutProjectValue?.name ? aboutProjectValue.name : 'Labfront Sleep Study'}
            </p>

            <p css={{fontSize: fontSize.h7, color: color.grey_400, marginBottom: '5px'}}>Principal Investigator</p>
            <p css={{fontSize: fontSize.h7, marginBottom: '15px', wordBreak: 'break-word', whiteSpace: 'break-spaces'}}>
              {aboutProjectValue?.investigator ? aboutProjectValue.investigator : 'Dr. Luna Shiba'}
            </p>

            <p css={{fontSize: fontSize.h7, color: color.grey_400, marginBottom: '5px'}}>Organization or Institution</p>
            <p css={{fontSize: fontSize.h7, marginBottom: '15px', wordBreak: 'break-word', whiteSpace: 'break-spaces'}}>
              {aboutProjectValue?.organization ? aboutProjectValue.organization : 'Labfront'}
            </p>

            <p css={{fontSize: fontSize.h7, color: color.grey_400, marginBottom: '5px'}}>Introduction</p>
            <p
              css={{
                overflowWrap: 'break-word',
                whiteSpace: 'pre-line',
                fontSize: '12px',
                fontWeight: fontWeight.regular,
              }}
            >
              {aboutProjectValue?.description ? aboutProjectValue.description : `Briefly introduce your study here.`}
            </p>
          </div>,
        )}

        {/* participant task */}
        {RIF(
          currentBlock === 'participantTask',
          <div css={{width: '100%', padding: `45px 15px`}}>
            <div
              css={{
                width: '40px',
                height: '40px',
                background: color.background,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '16px',
              }}
            >
              <img src={ParticipantTaskIcon} width='24px' />
            </div>
            <p
              css={{
                fontSize: fontSize.h5,
                fontWeight: fontWeight.thick,
                marginBottom: '16px',
              }}
            >
              Participant Tasks
            </p>
            {RIF(
              taskList.length === 0,
              <div
                css={{
                  textAlign: 'center',
                  fontSize: '12px',
                  marginTop: '140px',
                  color: color.grey_400,
                }}
              >
                Participant Task preview will show up here.
              </div>,
            )}
            {RIF(
              taskList.length > 0 && !isEditingLiveProject,
              taskList?.map((item, index: number) => (
                <AppTaskTag
                  {...{
                    key: item.id,
                    index,
                    task: item,
                  }}
                />
              )),
            )}
            {RIF(
              tempTaskList.length > 0 && isEditingLiveProject,
              tempTaskList?.map((item, index) => (
                <AppTaskTag
                  {...{
                    key: item.id,
                    index,
                    task: item,
                  }}
                />
              )),
            )}
          </div>,
        )}

        {/* Instructions & Contact */}
        {RIF(
          currentBlock === 'instruction' || currentBlock === 'contacts',
          <div css={{width: '100%', padding: `45px 15px`}}>
            <div
              css={{
                width: '40px',
                height: '40px',
                background: color.background,
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '16px',
              }}
            >
              <img src={currentBlock === 'instruction' ? InstructionIcon : ContactsIcon} width='24px' />
            </div>
            <p
              css={{
                fontSize: fontSize.h5,
                fontWeight: fontWeight.thick,
                marginBottom: '16px',
              }}
            >
              {currentBlock === 'instruction' ? 'Instructions' : 'Contact Organizer'}
            </p>
            {RIF(
              currentBlock === 'instruction',
              <p css={{fontSize: fontSize.h7, fontWeight: fontWeight.bold, whiteSpace: 'break-spaces'}}>
                {instructionValue ? instructionValue : 'Please read through documents provided to you carefully.'}
              </p>,
            )}
            {RIF(
              currentBlock === 'contacts',
              <p css={{fontSize: fontSize.h7, fontWeight: fontWeight.bold, whiteSpace: 'break-spaces'}}>
                {contactValue
                  ? contactValue
                  : 'If you have any questions or encounter issues during the project, please contact alix@bestuniversity.edu'}
              </p>,
            )}
          </div>,
        )}
      </div>

      <div
        css={{
          width: '105px',
          position: 'absolute',
          bottom: '-20px',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        App Preview
        <Tooltip content='This is what participants will see on their app.' />
      </div>
    </div>
  )
}

interface AppTaskTagProps {
  index: number
  task: TaskStateType | TaskTempStateType
}

const AppTaskTag = (props: AppTaskTagProps) => {
  const {color, fontWeight, colorTranslator} = selectTheme()
  const {task, index} = props

  return (
    <div
      css={{
        width: '100%',
        minHeight: '56px',
        border: `1px solid ${color.grey_100}`,
        borderLeft: task.color ? `4px solid #${task.color}` : `4px solid ${colorTranslator(index)}`,
        borderRadius: '3px',
        boxShadow: '#D4D4D440',
        padding: '10px 15px',
        marginBottom: '8px',
      }}
    >
      <div
        css={{
          fontSize: '12px',
          fontWeight: fontWeight.thick,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {task?.[getTaskContentName(task?.type)]?.name}
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '5px',
        }}
      >
        {RIF(task?.scheduleRepeat, <img src={RepeatIcon} width='14' height='14' />)}
        <p
          css={{
            fontSize: '12px',
            color: color.grey_400,
            WebkitTransform: 'scale(0.9)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {task?.scheduleDescription}
        </p>
      </div>
    </div>
  )
}
