import {HeartRateType} from '../model'
import {GarminConnectRecordDataDaily} from '../../../shared/mongo'
import {LocalDateGarminConnectTransformer} from './base'
import {DailyCompositeChartData} from '../../../model/chart'

export class DailySummaryHeartRateTransformer extends LocalDateGarminConnectTransformer<
  GarminConnectRecordDataDaily,
  DailyCompositeChartData<HeartRateType>
> {
  transform() {
    return this.toDataFrame(
      ({
        maxHeartRateInBeatsPerMinute,
        minHeartRateInBeatsPerMinute,
        restingHeartRateInBeatsPerMinute,
        averageHeartRateInBeatsPerMinute,
      }) => {
        return {
          values: [
            {value: maxHeartRateInBeatsPerMinute, type: HeartRateType.Max},
            {value: minHeartRateInBeatsPerMinute, type: HeartRateType.Min},
            {value: restingHeartRateInBeatsPerMinute, type: HeartRateType.Resting},
            {value: averageHeartRateInBeatsPerMinute, type: HeartRateType.Avg},
          ],
        }
      },
    )
  }
}
