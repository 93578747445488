import {SetStateAction, Dispatch} from 'react'
import {selectTheme} from '../../store'
import {CheckSuccessIcon} from '../../asset/image'
import {Button} from '../atoms'

export const PopupDownloadSuccess = (args: {
  setShowConfirmDownloadComplete: Dispatch<SetStateAction<boolean>>
}) => {
  const {color, fontSize, fontWeight} = selectTheme()
  return (
    <div
      css={{
        position: 'fixed',
        width: 'calc(100vw - 354px)',
        minWidth: '1086px',
        height: 'calc(100vh - 50px)',
        top: '50px',
        left: '0',
        background: 'rgba(247, 248, 248, .8)',
        zIndex: '100',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          padding: '40px',
          borderRadius: '5px',
          background: color.white,
          width: '440px',
          alignItems: 'center'
        }}
      >
        <img width={40} src={CheckSuccessIcon} />
        <p
          css={{
            marginTop: '24px',
            marginBottom: '8px',
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h5,
            textAlign: 'center',
          }}
        >
          Download Successful!
        </p>
        <p
          css={{
            fontSize: fontSize.h7,
            fontWeight: fontWeight.medium,
            color: color.grey_600,
            textAlign: 'center',
          }}
        >
          All files have been successfully downloaded. You’re all set!
        </p>
        <Button
          css={{
            marginTop: '24px',
          }}
          onClick={() => args.setShowConfirmDownloadComplete(false)}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.thick,
                color: color.white,
              }}
            >
              Done
            </p>
          </div>
        </Button>
      </div>
    </div>
  )
}
