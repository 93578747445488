import {CSSProperties} from 'react'
import {ScheduleSelection} from '../molecules'
import {IProjectScheduleConfig} from '../../shared/db'
import {LocalSchedule} from './add_schedule_pop'
import {TaskScheduleAbsoluteDateSelection} from './task_schedule_absolute_date_selection'
import {TaskScheduleRecurrentWeekdaySelection} from './task_schedule_recurrent_weekday_selection'
import {TaskScheduleRecurrentXDaySelection} from './task_schedule_recurrent_x_day_selection'
import {TaskScheduleParticipantJoinedXDaySelection} from './task_schedule_participant_joined_x_day_selection'
import {TaskScheduleParticipantJoinedXWeekSelection} from './task_schedule_participant_joined_x_week_selection'

export enum TaskScheduleSelectionType {
  RecurrentXDay = "recurrent_x_day",
  RecurrentWeekDay = 'recurrent_weekday',
  AbsoluteDay = 'absolute_day',
  ParticipantJoinedXDay = 'participant_joined_x_day',
  ParticipantJoinedXAbsoluteWeek = 'participant_joined_x_absolute_week',
  ParticipantJoinedXRelativeWeek = 'participant_joined_x_relative_week'
}

export interface TaskScheduleSelectionChildProps {
  selected: boolean
  scheduleType: TaskScheduleSelectionType | null
  taskSchedules: LocalSchedule[]
  setTaskSchedules: (taskSchedules: LocalSchedule[]) => void
  projectScheduleConfig: IProjectScheduleConfig
}

export const TaskScheduleTypeSelection = (props: {
  projectScheduleConfig: IProjectScheduleConfig
  scheduleType: TaskScheduleSelectionType | null
  selected: boolean
  onSelect: (scheduleType: TaskScheduleSelectionType | null) => void
  taskSchedules: LocalSchedule[]
  setTaskSchedules: (taskSchedules: LocalSchedule[]) => void
  selectionCSS?: CSSProperties
}) => {
  const {
    scheduleType,
    selected,
    onSelect,
    selectionCSS
  } = props
  const { title, description } = (() => {
    switch (scheduleType) {
      case TaskScheduleSelectionType.AbsoluteDay:
        return {
          title: "Specific Date",
          description: "TODO"
        }
      case TaskScheduleSelectionType.RecurrentWeekDay:
        return {
          title: "Recurring on specific day(s) of a week",
          description: "Which days of the week does a task recur? You can select multiple."
        }
      case TaskScheduleSelectionType.RecurrentXDay:
        return {
          title: "Recurrent every X day(s)",
          description: "TODO"
        }
      case TaskScheduleSelectionType.ParticipantJoinedXDay:
        return {
          title: "Participant Joined Date",
          description: "TODO"
        }
      case TaskScheduleSelectionType.ParticipantJoinedXAbsoluteWeek:
        return {
          title: "Specific Week",
          description: "TODO"
        }
      case TaskScheduleSelectionType.ParticipantJoinedXRelativeWeek:
        return {
          title: "Specific Week",
          description: "TODO"
        }
      default:
        throw new Error('unhandled schedule frequency setting')
    }
  })()
  const children = (() => {
    switch (scheduleType) {
      case TaskScheduleSelectionType.RecurrentXDay:
        return <TaskScheduleRecurrentXDaySelection {...props} />
      case TaskScheduleSelectionType.RecurrentWeekDay:
        return <TaskScheduleRecurrentWeekdaySelection {...props} />
      case TaskScheduleSelectionType.AbsoluteDay:
        return <TaskScheduleAbsoluteDateSelection {...props} />
      case TaskScheduleSelectionType.ParticipantJoinedXDay:
        return <TaskScheduleParticipantJoinedXDaySelection {...props}/>
      case TaskScheduleSelectionType.ParticipantJoinedXAbsoluteWeek:
        return <TaskScheduleParticipantJoinedXWeekSelection {...props}/>
      case TaskScheduleSelectionType.ParticipantJoinedXRelativeWeek:
        return <TaskScheduleParticipantJoinedXWeekSelection {...props}/>
      default:
        return (<div></div>)
    }
  })()
  return (
    <ScheduleSelection
      title={title}
      description={description}
      selected={selected}
      selectionValue={scheduleType}
      onSelect={onSelect}
      parentCSS={selectionCSS}
      children={children}
    />
  )
}
