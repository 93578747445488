import { MouseEventHandler } from 'react'
import { AddFilledIcon } from '../../asset/image'

export interface ButtonAddProps {
  disabled?: boolean
  onClick?: MouseEventHandler
  onMouseOver?: MouseEventHandler
  onMouseOut?: MouseEventHandler
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  type?: 'submit' | 'button' | 'reset'
  css?: any
}

/* a styled button */
export const ButtonAdd = (props: ButtonAddProps) => {
  const {disabled, type, ...rest} = props

  return (
    <button
      {...rest}
      type={type || 'submit'}
      disabled={disabled}
      css={{
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: 'none',
        background: `url(${AddFilledIcon})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        cursor: `${disabled ? 'auto' : 'pointer'}`,
      }}
    />
  )
}
