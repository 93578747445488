import {useEffect, RefObject} from 'react'

// closing popup on clicking outside component
export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: any,
  exceptRef?: RefObject<HTMLElement>,
) => {
  useEffect(() => {
    const listener = (e: any) => {
      if (!ref.current || ref.current.contains(e.target)) return
      if (exceptRef && exceptRef.current && exceptRef.current.contains(e.target)) return
      handler(e)
    }
    document.addEventListener('mousedown', listener)
    return () => document.removeEventListener('mousedown', listener)
  }, [ref, handler, exceptRef])
}
