import {Dispatch, SetStateAction} from 'react'
import {selectTheme} from '../../store'
import {handleEsc} from '../../lib'
import {Legend, Button, ButtonCancel} from '..'
import UpgradeContactImg from '../../asset/image/upgrade_contact.png'

export interface UpgradeContactPopProps {
  setDisplayUpgradeContactPop?: Dispatch<SetStateAction<boolean>>
  setDisplayCalcSlotsPop?: Dispatch<SetStateAction<boolean>>
}

export const UpgradeContactPop = (props: UpgradeContactPopProps) => {
  const {color} = selectTheme()
  const {setDisplayUpgradeContactPop, setDisplayCalcSlotsPop} = props

  const handleClose = () => {
    setDisplayUpgradeContactPop?.(false)
    if (setDisplayCalcSlotsPop) {
      setDisplayCalcSlotsPop?.(false)
    }
  }

  const handleBtnAction = () => {
    window.open('https://share.hsforms.com/1Rwqlz1DzQl-JqdJtiMuNvQ540x3', '_blank')
    handleClose()
  }

  handleEsc(handleClose)

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 105,
      }}
    >
      <div
        css={{
          top: 'calc(50vh - 200px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <div css={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '240px'}}>
          <div>
            <div css={{display: 'flex', alignItems: 'start'}}>
              <ButtonCancel onClick={handleClose} />
              <img src={UpgradeContactImg} width='154' height='153' css={{marginLeft: 'calc(50% - 90px)'}} />
            </div>
            <div css={{marginTop: '30px'}}>
              <Legend css={{textAlign: 'center'}}>Ready to upgrade? Complete form to continue</Legend>
              <div css={{margin: '30px 0 40px 0', textAlign: 'center'}}>
                Thanks for your interest in upgrading! To get started, please fill out the form linked below. Our team
                will be in touch shortly with a follow-up to get you upgraded."
              </div>
            </div>
          </div>

          <div
            css={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Button onClick={handleBtnAction} btnColor='primary' btnPadding='large'>
              Go to Form
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
