import {RIF, RequestResult, _, isInBetaType} from '../../lib'
import {
  createDispatchActions,
  selectProjectData,
  selectTheme,
  useSelectSettings,
  selectBatchData,
  selectCorrelationVariable,
  selectProjectSettings,
} from '../../store'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useState, useEffect, useMemo} from 'react'
import {ArrowLeftIcon} from '../../asset/image'
import {DataDownloadPage, AnalysisSideBar, AnalysisMatrixBlock} from '..'
import {IProject, IBatch, BetaType} from '../../shared/db'
import {ParticipantItem} from '../../model/participant'
import {DateObject} from 'react-multi-date-picker'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {CorrelationResponse, Variable} from '../../shared/analysis'
import {DataSetSelectionItem} from '../../model/analysis'
import {
  AnalysisDataResolverArgs,
  useResolveCorrelationApiCall,
} from '../../lib/chart_data/data_loader/analysis_data_resolver'

export const AnalysisPage = () => {
  const {color, fontWeight, fontSize} = selectTheme()
  const {
    doREQUEST_PROJECT_FETCH,
    doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
    doREQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
  }: // eslint-disable-next-line @typescript-eslint/ban-types
  Record<string, Function> = createDispatchActions()
  AttemptRequestParticipantStatus({})
  const settings = useSelectSettings()
  const projectId = useParams().projectId ?? ''
  const projectData = selectProjectData() as Record<string, IProject>
  const project = projectId ? projectData[projectId] : undefined
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined
  const correlationVariableList = selectCorrelationVariable(projectId)
  const batchId = _.head(project?.batchList)?.id
  const batchData = selectBatchData() as Record<string, IBatch>
  const batch = batchId ? batchData[batchId] : undefined
  const [duration, setDuration] = useState<[DateObject, DateObject]>([
    new DateObject().subtract(7, 'days'),
    new DateObject(),
  ])
  const [currentParticipantId, setCurrentParticipantId] = useState<string>('')
  const [resolution, setResolution] = useState<any>()
  const [participantList, setParticipantList] = useState<ParticipantItem[]>([])
  // the actual variable list that will be passed into the correlation graph
  const [displayingVariableList, setDisplayingVariableList] = useState<Variable[]>([])
  const [displayingDataSetSelectionList, setDisplayingDataSetSelectionList] = useState<DataSetSelectionItem[]>([])

  const [renderDataDownloadPage, setRenderDataDownloadPage] = useState(false)
  const [requestResultOfAnalyticGraphFetch, setRequestResultOfAnalyticGraphFetch] = useState<RequestResult | null>(null)
  const [correlationResponse, setCorrelationResponse] = useState<CorrelationResponse>()

  // disable analysis page in production
  const navigate = useNavigate()
  useEffect(() => {
    if (process.env.NODE_ENV === 'NODE_ENV') navigate('/project')
  }, [])

  useMemo(() => {
    if (!batch?.participantList) return
    const result = batch.participantList.map(({id, insignia, tagList}): ParticipantItem => {
      return {
        id,
        insignia: insignia ?? '',
        tagList: tagList ?? [],
      }
    })
    if (!_.isEqual(participantList, result)) {
      setParticipantList(result)
    }
  }, [batch])

  useEffect(() => {
    if (requestResultOfAnalyticGraphFetch?.success) {
      setCorrelationResponse(requestResultOfAnalyticGraphFetch.result.payload)
    }
  }, [requestResultOfAnalyticGraphFetch])

  const onParticipantSelect = (participant: ParticipantItem) => {
    setCurrentParticipantId(participant.id)
  }

  AttemptRequestParticipantStatus({
    requestAdherence: {durationBeforeToday: 6},
  })

  const resolveCorrelationApiCall = useResolveCorrelationApiCall()

  const onUpdateAnalysisArgs = (args: AnalysisDataResolverArgs) => {
    resolveCorrelationApiCall({
      ...args,
      setRequestResult: setRequestResultOfAnalyticGraphFetch,
    })
  }

  useEffect(() => {
    if (!projectId) return
    if (!project) {
      doREQUEST_PROJECT_FETCH({
        payload: {
          projectId,
        },
      })
    }
    if (!projectSettings) {
      doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH({
        payload: {},
      })
    }
    if (!correlationVariableList.length) {
      doREQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH({
        payload: {
          projectId,
        },
      })
    }
  }, [projectId])

  useEffect(() => {
    if (!participantList.length) return
    if (currentParticipantId.length) return
    setCurrentParticipantId(_.head(participantList)?.id || '')
  }, [participantList])

  useMemo(() => {
    if (!correlationVariableList || correlationVariableList.length === 0) return
    // randomly select 2 variables to display
    const initDataTypeList = _.slice(correlationVariableList, 0, 2)
    setDisplayingVariableList(initDataTypeList)
  }, [correlationVariableList])

  return RIF(
    isInBetaType({betaType: BetaType.ClosedBeta, settings}),
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 104,
        width: '100vw',
        height: '100vh',
        backgroundColor: color.background,
      }}
    >
      {RIF(renderDataDownloadPage, <DataDownloadPage {...{closeAction: () => setRenderDataDownloadPage(false)}} />)}

      {/* title bar */}
      <div
        css={{
          width: '100vw',
          height: '56px',
          boxShadow: '0 2px 10px 0 rgba(0,0,0,0.04)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: color.white,
          padding: '8px 32px 8px 16px',
          zIndex: 99,
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '8px',
              borderRight: `1px solid ${color.grey_100}`,
            }}
          >
            <Link
              css={{
                width: '28px',
                height: '28px',
                borderRadius: '5px',
                backgroundColor: color.white,
                backgroundImage: `url(${ArrowLeftIcon})`,
                backgroundPosition: 'center',
                backgroundSize: '20px',
                backgroundRepeat: 'no-repeat',
                ':hover': {
                  backgroundColor: color.hover,
                },
              }}
              to={`/adherence/${projectId}`}
            />
          </div>
          <p
            css={{
              fontWeight: fontWeight.thick,
              marginLeft: '20px',
            }}
          >
            Data Analysis
          </p>
        </div>
      </div>

      {/* page content */}
      <div css={{display: 'flex'}}>
        <AnalysisSideBar
          {...{
            participantList,
            onParticipantSelect,
            currentParticipantId,
            duration,
            setDuration,
            resolution,
            setResolution,
            displayingVariableList,
            setDisplayingVariableList,
            displayingDataSetSelectionList,
            setDisplayingDataSetSelectionList,
            onUpdateAnalysisArgs,
          }}
        />
        {/* right part of page content */}
        <div
          css={{
            padding: '32px 24px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <p
            css={{
              fontWeight: fontWeight.thick,
              fontSize: fontSize.h3,
              marginBottom: '16px',
            }}
          >
            Correlation and Distribution Analysis
          </p>
          <AnalysisMatrixBlock
            {...{
              participantInsignia:
                _.find(participantList, ['id', currentParticipantId])?.insignia ?? 'Unknown Participant',
              duration,
              displayingVariableList,
              correlationResponse,
            }}
          />
        </div>
      </div>
    </div>,
  )
}
