import {useRef, useEffect, useState} from 'react'
import {v4 as uuid} from 'uuid'
import {selectTheme} from '../../store'

export interface SlideCheckboxT {
  id?: string
  value: boolean
  onChange: (checked: boolean) => void
  disabledColor?: string
  readOnly?: boolean
  from?: string // for RTL
}

export const SlideCheckbox = (props: SlideCheckboxT) => {
  const {color} = selectTheme()
  const id = props.id || uuid()
  const inputRef = useRef(null)
  const labelRef = useRef(null)
  const [value, setValue] = useState(props.value || false)
  const [focused, setFocused] = useState(false)
  const [mouseover, setMouseover] = useState(false)
  const disabledColor = props.disabledColor || color.disabled

  const onChange = (e: any) => {
    setValue(e.target.checked)
    if (props.onChange) props.onChange(e.target.checked)
  }

  const getFocus = () => setFocused(true)
  const looseFocus = () => (mouseover ? getFocus() : setFocused(false))
  const getMouse = () => setMouseover(true)
  const looseMouse = () => setMouseover(false)

  useEffect(() => {
    if (value != props.value) {
      setValue(props.value)
    }
  }, [props.value])

  useEffect(() => {
    const input: any = inputRef.current
    const label: any = labelRef.current
    const cleanUp: any[] = []
    if (input) {
      input.addEventListener('focus', getFocus)
      input.addEventListener('blur', looseFocus)
      cleanUp.push(() => input.removeEventListener('focus', getFocus))
      cleanUp.push(() => input.removeEventListener('focus', looseFocus))
    }
    if (label) {
      label.addEventListener('mouseover', getMouse)
      label.addEventListener('mouseleave', looseMouse)
      cleanUp.push(() => label.removeEventListener('mouseover', getMouse))
      cleanUp.push(() => label.removeEventListener('mouseleave', looseMouse))
    }
    return () => {
      cleanUp.forEach((cb) => cb())
    }
  }, [labelRef.current, inputRef.current, focused, mouseover])

  return (
    <div css={{position: 'relative'}}>
      <input
        data-testid={`${props.from}_slide_checkbox`}
        type={props.readOnly ? 'hide' : 'checkbox'}
        id={props.id || id}
        ref={inputRef}
        onChange={onChange}
        checked={value}
        css={{
          position: 'absolute',
          curser: 'pointer',
          opacity: 0,
          height: 0,
          width: 0,
        }}
      />
      <label
        htmlFor={props.id || id}
        ref={labelRef}
        css={{
          position: 'relative',
          cursor: 'pointer',
          display: 'block',
          width: '28px',
          height: '16px',
          borderRadius: '1rem',
          background: value ? color.primary : disabledColor,
        }}
      >
        <div
          css={{
            position: 'absolute',
            width: '12px',
            height: '12px',
            borderRadius: '1rem',
            background: color.white,
            top: '50%',
            transform: `translateY(-50%) translateX(${value ? '.8rem' : '.2rem'})`,
            transition: 'transform 100ms',
          }}
        />
      </label>
    </div>
  )
}
