import { useParams } from "react-router-dom"
import { IProject } from "../../shared/db"
import { selectProjectData } from "../../store"

interface CurrenProjectState {
  //project might still not loaded when projectId present, use this for some
  //condition specific for projectId
  projectId: string | undefined
  project: IProject | undefined
}

export const useCurrentProjectState = (): CurrenProjectState => {
  const currentProjectId = useParams().projectId
  const projectData = selectProjectData()
  return { projectId: currentProjectId, project: projectData ? projectData[currentProjectId ?? ''] : undefined }
}
