import {Dispatch, SetStateAction} from 'react'

import {v, debug, useForm} from '../../lib'

import {PopupSimple} from '..'

interface Props {
  setDisplayDeleteDeveloperTokenPopup: Dispatch<SetStateAction<boolean>>
  deleteDeveloperToken: () => void
  deletingDeveloperTokenName: string
}

export const DeleteDeveloperTokenPopup = (props: Props) => {
  const {setDisplayDeleteDeveloperTokenPopup, deleteDeveloperToken, deletingDeveloperTokenName} = props

  const {handleSubmit} = useForm()

  const onSubmit = () => {
    deleteDeveloperToken()
  }

  const onError = (error: any) => {
    debug('DeleteDeveloperTokenForm.onError', {error})
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      data-testid='delete_developer_token_popup'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 104,
      }}
    >
      <PopupSimple
        method='Delete'
        name={deletingDeveloperTokenName}
        description='Note this is permanent'
        buttonColor='warning'
        buttonText='Delete'
        closeAction={setDisplayDeleteDeveloperTokenPopup}
      />
    </form>
  )
}
