import {DataFrame, IDataFrame} from 'data-forge'
import {head, last, times} from 'lodash'
import {addDays, subDays} from 'date-fns'

import {t} from '../../t'
import {NAValueSelector} from '../model'

export const fillGaps = <T extends object = object>(
  dataFrame: IDataFrame,
  expectedIndex: number[],
  naValueSelector: NAValueSelector,
): IDataFrame<number, T> => {
  const index = dataFrame.getIndex()
  const firstIndexDate = t.dateFromYYMMDD(index.first())
  const lastIndexDate = t.dateFromYYMMDD(index.last())
  const expectedFirstIndexDate = t.dateFromYYMMDD(t.unwrap(head(expectedIndex)))
  const expectedLastIndexDate = t.dateFromYYMMDD(t.unwrap(last(expectedIndex)))

  let filled = dataFrame.fillGaps(
    ([curIndex], [nextIndex]) => t.yymmddRange(curIndex, nextIndex).length > 2,
    ([curIndex], [nextIndex]) =>
      t
        .dateRangetoYYMMDDRange(addDays(t.dateFromYYMMDD(curIndex), 1), subDays(t.dateFromYYMMDD(nextIndex), 1))
        .map((index) => [index, naValueSelector(index)]),
  )

  if (firstIndexDate > expectedFirstIndexDate) {
    const index = t.dateRangetoYYMMDDRange(expectedFirstIndexDate, subDays(firstIndexDate, 1))
    filled = DataFrame.concat([
      new DataFrame({
        index,
        values: times(index.length, (index) => naValueSelector(t.dateToYYMMDD(addDays(expectedFirstIndexDate, index)))),
        considerAllRows: true,
      }),
      filled,
    ])
  }

  if (lastIndexDate < expectedLastIndexDate) {
    const index = t.dateRangetoYYMMDDRange(addDays(lastIndexDate, 1), expectedLastIndexDate)
    filled = DataFrame.concat([
      filled,
      new DataFrame({
        index,
        values: times(index.length, (index) => naValueSelector(t.dateToYYMMDD(addDays(lastIndexDate, index + 1)))),
        considerAllRows: true,
      }),
    ])
  }

  return filled
}
