import { VisualizerGraphSetting, VisualizerGraphDataType } from "../../../shared/db"

export interface TimeSeriesDataLoaderProps {
  participantId: string
  loadDataYYMMDDIndex: number[]
}

export const filterVisualizerSidebarSetting = (
  settings: VisualizerGraphSetting[],
  scope: VisualizerGraphDataType[],
) => {
  return (
    settings
      .filter((visualizerGraphSetting) => {
        return scope.includes(visualizerGraphSetting.type)
      })
      .map((visualizerGraphSetting) => visualizerGraphSetting.type) ?? []
  )
}

export const convertToYYMMIndex = (input: number): number => {
  return Math.floor(input / 100)
}
