import React from 'react'
import {createDispatchActions, selectTheme} from '../../store'
import {useSelector} from 'react-redux'

/* a styled h1 heading */
export const H1 = (props: any) => {
  const {fontWeight, fontSize} = selectTheme()
  return <h1 {...props} css={{fontWeight: fontWeight.bold, fontSize: fontSize.h1}} />
}

/* a styled h2 heading */
export const H2 = (props: any) => {
  const {fontWeight, fontSize} = selectTheme()
  return <h2 {...props} css={{fontWeight: fontWeight.bold, fontSize: fontSize.h2}} />
}

/* a styled h3 heading */
export const H3 = (props: any) => {
  const {fontWeight, fontSize} = selectTheme()
  return <h3 {...props} css={{fontWeight: fontWeight.bold, fontSize: fontSize.h3}} />
}

/* a styled h4 heading */
export const H4 = (props: any) => {
  const {fontWeight, fontSize} = selectTheme()
  return <h4 {...props} css={{fontWeight: fontWeight.bold, fontSize: fontSize.h4}} />
}

/* a styled h5 heading */
export const H5 = (props: any) => {
  const {fontWeight, fontSize} = selectTheme()
  const {doRESET_THEME}: any = createDispatchActions()
  return <h5 {...props} css={{fontWeight: fontWeight.bold, fontSize: fontSize.h5}} onClick={doRESET_THEME} />
}

/* a styled h6 heading */
export const H6 = (props: any) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const result = createDispatchActions()

  const {doTHEME_UPDATE}: any = createDispatchActions()

  const handleClick = () => {
    doTHEME_UPDATE({
      color: {
        ...color,
        black: 'green',
        primary: 'yellow',
      },
    })
  }

  return (
    <h6
      {...props}
      onClick={handleClick}
      css={{
        fontWeight: fontWeight.bold,
        fontSize: fontSize.h6,
        color: color.primary,
      }}
    />
  )
}
