import {ChartConfig, DataConverter, DataConverterOption, TimeSeriesChartType} from '../template/time_series_data_chart'
import {GenericStreamDataChartProps, StreamDataChart, StreamDataChartProps} from '../template/stream_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import { TimeSeriesDataSource } from '../../../model'
import { VisualizerGraphDataType } from '../../../shared/db'

export const MovesenseEcgChart = (props: StreamDataChartProps) => {
  const {height, width, completionIdList, plotDataRange, commonTaskDataMarkerList} = props
 
  const valueFilter: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    return plotData.map((item) => {
      if (item.v && item?.v < 10000) {
        return item
      } else {
        return {t: item.t}
      }
    })
  }

  const defaultDataConverterOption: DataConverterOption = {
    label: 'ECG',
    value: 'ECG',
    dataConverter: valueFilter
  }

  const dataConfig = {
    defaultDataConverterOption,
    maxDataLength: 80000
  }

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
    tooltip: false,
    enableSlider: true
  }

  const chartCardConfig = {
    height,
    width,
    title: 'Movesense Ecg',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
    sampleRatio: 0.3
  }

  const streamDataChartProp: GenericStreamDataChartProps = {
    dataType: VisualizerGraphDataType.MovesenseECGWaveform,
    config,
    completionIdList,
    plotDataRange,
    commonTaskDataMarkerList,
  }

  return (
    <>
      <StreamDataChart {...streamDataChartProp} />
    </>
  )
}
