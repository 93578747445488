import {useState, Dispatch, SetStateAction} from 'react'
import {selectTheme, selectProfile} from '../../store'
import {RIF} from '../../lib'
import {
  SaveBar,
  NewPasswordInputPage,
  NewEmailInputPage,
  VerifyCodePage,
  ProfileInformPage,
  ProfileSettingsProcessMethodString,
} from '..'

export interface ProfileSettingsProcessPagePropsT {
  profileSettingsProcessMethod: ProfileSettingsProcessMethodString
  setProfileSettingsProcessMethod: Dispatch<SetStateAction<ProfileSettingsProcessMethodString>>
  setRenderProfileSettingsProcessPage: Dispatch<SetStateAction<boolean>>
}

export type ProfileSettingsProcessString =
  | 'verify_code'
  | 'new_email_input'
  | 'new_password_input'
  | 'email_changed'
  | 'password_changed'

export const ProfileSettingsProcessPage = (props: ProfileSettingsProcessPagePropsT) => {
  const {color} = selectTheme()

  const {profileSettingsProcessMethod, setProfileSettingsProcessMethod, setRenderProfileSettingsProcessPage} = props

  const profile = selectProfile()

  const [profileSettingsProcess, setProfileSettingsProcess] = useState<ProfileSettingsProcessString>('verify_code')
  const [verifyCode, setVerifyCode] = useState('')
  const [incorrectCode, setIncorrectCode] = useState(false)

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        background: color.white,
        zIndex: 102,
        padding: '60px',
      }}
    >
      <SaveBar
        {...{
          closeAction: setRenderProfileSettingsProcessPage,
          hideButton: true,
        }}
      />

      {RIF(
        profileSettingsProcess === 'verify_code',
        <VerifyCodePage
          {...{
            profileSettingsProcessMethod,
            setProfileSettingsProcess,
            setVerifyCode,
            incorrectCode,
            verifyType: 'email',
            email: profile?.email,
          }}
        />,
      )}

      {RIF(
        profileSettingsProcess === 'new_email_input',
        <NewEmailInputPage
          {...{
            verifyCode,
            setProfileSettingsProcess,
            setIncorrectCode,
          }}
        />,
      )}

      {RIF(
        profileSettingsProcess === 'new_password_input',
        <NewPasswordInputPage
          {...{
            verifyCode,
            setProfileSettingsProcess,
            setIncorrectCode,
          }}
        />,
      )}

      {RIF(
        profileSettingsProcess === 'email_changed',
        <ProfileInformPage
          {...{
            title: 'Your email has been changed.',
            content: 'You can now sign in with your new email.',
            buttonText: 'Back to Profile Settings',
            successIcon: true,
            doAction: () => {
              setProfileSettingsProcessMethod('')
              setRenderProfileSettingsProcessPage(false)
            },
          }}
        />,
      )}

      {RIF(
        profileSettingsProcess === 'password_changed',
        <ProfileInformPage
          {...{
            title: 'Your password has been changed.',
            content: 'You can now use your new password to sign in to your account.',
            buttonText: 'Back to Profile Settings',
            successIcon: true,
            doAction: () => {
              setProfileSettingsProcessMethod('')
              setRenderProfileSettingsProcessPage(false)
            },
          }}
        />,
      )}
    </div>
  )
}
