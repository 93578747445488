import {Component} from 'react'

import {ErrorPage} from '.'
import Instrumentation from '../instrumentation'

export class ErrorBoundaries extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      profile: null,
    }
    this.collectInfoFromChild = this.collectInfoFromChild.bind(this)
  }
  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  collectInfoFromChild(info) {
    return (this.state.profile = info)
  }

  componentDidCatch(error, info) {
    Instrumentation.shared.perform((api) => {
      if (this.state?.profile?.id) {
        const {id, email, firstName, lastName} = this.state.profile
        api.setUser({
          id,
          email,
          username: `${firstName} ${lastName}`,
        })
      }
      api.pushError(error, {
        context: {
          info: JSON.stringify(info),
          source: 'ErrorBoundaries',
        },
      })
    })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage collectInfoFromChild={this.collectInfoFromChild} />
    }
    return this.props.children
  }
}
