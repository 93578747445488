import {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import LabfrontLogo from '../../asset/image/labfront_logo.png'
import {RIF} from '../../lib'
import {
  VerifyCodePage,
  ProfileInitialSettingsPage,
  ProfileSetupFeedbackPage,
  ResetPasswordPage,
  NewPasswordInputPage,
  ProfileInformPage,
  ProtectedRoute,
} from '..'

export type AccountSetupProcessString =
  | 'verify_code_page'
  | 'forget_password'
  | 'profile_initial_settings_page'
  | 'verify_code_for_forget_PWD'
  | 'new_password_input'
  | 'info_password_changed'
  | 'profile_setup_feedback_page'

export const AccountSetupPage = () => {
  const navigate = useNavigate()
  const location: string = useLocation().search
  const locationState: {email: string | undefined} = useLocation().state as any

  const initialPage =
    location.slice(1) === 'profile_initial_settings_page'
      ? 'profile_initial_settings_page'
      : location.slice(1) === 'forget_password'
      ? 'forget_password'
      : location.slice(1) === 'verify_code_for_forget_PWD'
      ? 'verify_code_for_forget_PWD'
      : location.slice(1) === 'new_password_input'
      ? 'new_password_input'
      : location.slice(1) === 'info_password_changed'
      ? 'info_password_changed'
      : location.slice(1) === 'profile_setup_feedback_page'
      ? 'profile_setup_feedback_page'
      : 'verify_code_page'

  const forgetPWDAutoFillEmail = initialPage === 'forget_password' ? locationState?.email : undefined
  const emailOfSignup = initialPage === 'verify_code_page' ? location.slice(1) : undefined
  const [accountSetupProcess, setAccountSetupProcess] = useState<AccountSetupProcessString>(initialPage)
  const [verifyCode, setVerifyCode] = useState('')
  const [incorrectCode, setIncorrectCode] = useState(false)
  const [emailOfForgetPWD, setEmailOfForgetPWD] = useState('')

  const handleLogout = () => {
    window.sessionStorage.removeItem('labfront')
    navigate('/')
    window.location.reload()
  }

  useEffect(() => {
    if (accountSetupProcess == undefined) {
      navigate('/')
    }
  }, [accountSetupProcess])

  return (
    <div>
      <header
        css={{
          width: '100vw',
          height: '50px',
          backgroundColor: 'white',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.13)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: `0 30px`,
          position: 'absolute',
          top: '0',
          left: '0',
          zIndex: '102',
        }}
      >
        <Link to='/'>
          <img src={LabfrontLogo} width='55' height='14' css={{marginRight: '20px'}} />
        </Link>
      </header>

      {/* sign up process */}
      {RIF(
        accountSetupProcess === 'verify_code_page',
        <VerifyCodePage
          {...{
            verifyType: 'email',
            email: emailOfSignup,
            setAccountSetupProcess,
          }}
        />,
      )}
      {RIF(
        accountSetupProcess === 'profile_initial_settings_page',
        <ProtectedRoute>
          <ProfileInitialSettingsPage />
        </ProtectedRoute>,
      )}
      {RIF(
        accountSetupProcess === 'profile_setup_feedback_page',
        <ProtectedRoute>
          <ProfileSetupFeedbackPage />
        </ProtectedRoute>,
      )}

      {/* forget password process */}
      {RIF(
        accountSetupProcess === 'forget_password',
        <ResetPasswordPage
          {...{
            forgetPWDAutoFillEmail,
            setAccountSetupProcess,
            setEmailOfForgetPWD,
          }}
        />,
      )}
      {RIF(
        accountSetupProcess === 'verify_code_for_forget_PWD',
        <VerifyCodePage
          {...{
            accountSetupProcess,
            setAccountSetupProcess,
            setVerifyCode,
            incorrectCode,
            email: emailOfForgetPWD,
            verifyType: 'email',
          }}
        />,
      )}
      {RIF(
        accountSetupProcess === 'new_password_input',
          <NewPasswordInputPage
            {...{
              verifyCode,
              setAccountSetupProcess,
              setIncorrectCode,
              emailOfForgetPWD,
              setEmailOfForgetPWD,
            }}
          />
      )}

      {RIF(
        accountSetupProcess === 'info_password_changed',
        <ProfileInformPage
          {...{
            title: 'Your password has been reset.',
            content: `You can now use your new password to sign in to your account.`,
            buttonText: 'Back to Sign In',
            successIcon: true,
            doAction: handleLogout,
          }}
        />,
      )}
    </div>
  )
}
