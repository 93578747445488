import React from 'react'
import {selectTheme} from '../../store'

/** a styled unordered_list */
export const Ul = (props) => {
  const {pad} = selectTheme()
  return (
    <ul
      {...props}
      css={{
        marginLeft: pad.medium,
        listStyle: 'disc',
      }}
    />
  )
}
