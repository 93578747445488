import React from 'react'

import {selectTheme} from '../../store'
import {Input} from '.'

import {SearchIcon} from '../../asset/image'

export const SearchBar = (props) => {
  const handleChange = (e, value) => {
    if (props.onChange) props.onChange(value)
  }

  return (
    <div
      css={{
        position: 'relative',
        width: '200px',
        height: '32px',
      }}
    >
      <Input
        placeholder={props.placeholder}
        onChange={handleChange}
        css={{
          width: '100%',
          height: '100%',
          padding: '7px 15px',
        }}
      />
      <img
        width='18'
        src={SearchIcon}
        css={{
          position: 'absolute',
          right: '15px',
          top: '7px',
        }}
      />
    </div>
  )
}
