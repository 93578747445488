import {put, takeEvery, debug, select, createAction, SagaIterator, call} from '../../lib'
import {RefreshTokenAction} from '../../lib/auth/refresh_token_action'

import {doAUTH_SET, doREQUEST_ERROR, doREQUEST_COMPLETE, doAUTH_REFRESHING, doAUTH_REFRESHING_FAILURE} from '../state'

export const REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT = 'REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT'
export const doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT = createAction(REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT)

export const requestIdentityRefreshTokenSubmitActionCreators = {
  doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
}

export function* requestIdentityRefreshTokenSubmit({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestIdentityRefreshTokenSubmit')

  yield put(doAUTH_REFRESHING())

  const refreshToken = yield select((state) => state?.auth?.refreshToken)
  if (!refreshToken) {
    yield put(doAUTH_REFRESHING_FAILURE())

    yield put(
      doREQUEST_COMPLETE({
        fromType: REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
        requestId: payload.requestId,
      }, setRequestResult),
    )

    return
  }

  const result = yield call(() => RefreshTokenAction.instance.execute(refreshToken))

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not success')
    debug('saga*requestIdentityRefreshTokenSubmit request error', error)

    yield put(doAUTH_REFRESHING_FAILURE())

    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-refresh-token-submit',
        ...result,
      }, setRequestResult),
    )
  }

  yield put(
    doAUTH_SET({
      accessToken: result.payload.accessToken,
      refreshToken: result.payload.refreshToken,
      accessTokenExpiresInSeconds: result.payload.accessTokenExpiresInSeconds,
      refreshTokenExpiresInSeconds: result.payload.refreshTokenExpiresInSeconds,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* identityRefreshTokenSubmitSaga() {
  yield takeEvery(REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT, requestIdentityRefreshTokenSubmit)
}
