import React, {useState, useEffect, Dispatch, SetStateAction, createRef} from 'react'
import Lottie from 'lottie-web'
import loadingAnimation from '../../asset/lotties/loading_animation.json'
import {selectTheme, selectMethod, selectTempTaskData} from '../../store'
import {RIF, _} from '../../lib'
import {LinkToBlueIcon, CollaborateIcon, SurveyBlackIcon} from '../../asset/image'
import GarminHelpCenterImg from '../../asset/image/garmin_helpcenter.png'
import GarminConnectAndCompanionAppImg from '../../asset/image/garmin_connect_companion_app.png'
import GarminCompanionAppImg from '../../asset/image/garmin_companion_app.png'

export interface GarminSidebarPropsT {
  garminConnectEnable: boolean
  garminStreamEnable: boolean
  garminDeviceSectionEnable: boolean
}

export const GarminSidebar = (props: GarminSidebarPropsT) => {
  const {color} = selectTheme()
  const {garminConnectEnable, garminStreamEnable, garminDeviceSectionEnable} = props

  const [sidebarState, setSideBarState] =
    useState<'garminConnectOnly' | 'garminDeviceOnly' | 'garminConnectAndDirect' | 'garminTurnOff'>('garminDeviceOnly')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const loading = setTimeout(() => {
      setIsLoading(false)
      if (garminConnectEnable && !garminDeviceSectionEnable) return setSideBarState('garminConnectOnly')
      if (!garminConnectEnable && garminDeviceSectionEnable) return setSideBarState('garminDeviceOnly')
      if (garminConnectEnable && garminDeviceSectionEnable) return setSideBarState('garminConnectAndDirect')
      if (!garminConnectEnable && !garminDeviceSectionEnable) return setSideBarState('garminTurnOff')
    }, 500)
    return () => clearTimeout(loading)
  }, [garminConnectEnable, garminDeviceSectionEnable])

  const animRef: any = createRef()
  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    })
    return () => Lottie.stop()
  }, [isLoading])

  return (
    <div
      css={{
        background: '#3455F414',
        width: '25%',
        height: 'fit-content',
        borderRadius: '12px',
        padding: '40px 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'sticky',
        top: '0px',
      }}
    >
      <img src={CollaborateIcon} width={24} height={24} />
      <div css={{color: color.primary, fontWeight: '700', marginTop: '16px', marginBottom: '24px'}}>
        PREVIEW PARTICIPANT EXPERIENCE
      </div>

      {RIF(isLoading, <div ref={animRef} css={{width: '100px', height: '100px', marginTop: '10px'}} />)}

      {RIF(
        !isLoading && sidebarState === 'garminConnectOnly',
        <GarminSidebarContent
          {...{
            sidebarState,
            image: GarminConnectAndCompanionAppImg,
            dailyLink: 'https://help.labfront.com/participant-onboarding-garmin',
            resourceLink1: 'https://help.labfront.com/participant-onboarding-garmin',
            resourceLink2: 'https://drive.google.com/file/d/1ts4oH7Eazc690Be64A8DvVE1qgFvLsj5/view',
            garminStreamEnable,
          }}
        />,
      )}

      {RIF(
        !isLoading && sidebarState === 'garminDeviceOnly',
        <GarminSidebarContent
          {...{
            sidebarState,
            image: GarminCompanionAppImg,
            dailyLink: 'https://help.labfront.com/participant-onboarding-labfront',
            resourceLink1: 'https://help.labfront.com/participant-onboarding-labfront',
            resourceLink2: 'https://drive.google.com/file/d/1aLlA0f7Mlm47lwwsxLsrQYARhDhzTMhc/view',
            garminStreamEnable,
          }}
        />,
      )}

      {RIF(
        !isLoading && sidebarState === 'garminConnectAndDirect',
        <GarminSidebarContent
          {...{
            sidebarState,
            image: GarminConnectAndCompanionAppImg,
            dailyLink: 'https://help.labfront.com/participant-onboarding-garmin-and-labfront',
            resourceLink1: 'https://help.labfront.com/participant-onboarding-garmin-and-labfront',
            resourceLink2: 'https://drive.google.com/file/d/1X80irR0qvUCEcO_BYxdYA87x1ovB9DsM/view',
            garminStreamEnable,
          }}
        />,
      )}

      {RIF(
        !isLoading && sidebarState === 'garminTurnOff',
        <div css={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div css={{marginBottom: '30px'}}>To collect Garmin data, please choose at least one source.</div>
          <img src={GarminHelpCenterImg} width={160} height={120} />
        </div>,
      )}
    </div>
  )
}

interface GarminSidebarContentPropsT {
  sidebarState: 'garminConnectOnly' | 'garminDeviceOnly' | 'garminConnectAndDirect' | 'garminTurnOff'
  image: any
  dailyLink: string
  resourceLink1: string
  resourceLink2: string
  garminStreamEnable: boolean
}

const GarminSidebarContent = (props: GarminSidebarContentPropsT) => {
  const {color} = selectTheme()
  const {sidebarState, image, dailyLink, resourceLink1, resourceLink2, garminStreamEnable} = props

  const appCount = sidebarState === 'garminDeviceOnly' ? 1 : 2
  const appOpen =
    sidebarState === 'garminDeviceOnly'
      ? 'Labfront Companion App'
      : sidebarState === 'garminConnectOnly'
      ? 'Garmin Connect App'
      : 'Garmin Connect and Labfront Companion Apps'
  const appListHtml =
    sidebarState === 'garminDeviceOnly' ? (
      <>
        <br />• Labfront Companion App
      </>
    ) : (
      <>
        {' '}
        <br />• Garmin Connect App
        <br />• Labfront Companion App
      </>
    )

  return (
    <div>
      <div css={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div>
          Based on the settings, your participants will have to set up{' '}
          <span css={{color: color.primary}}>{appCount}</span> apps on their smartphone:
          <br />
          {appListHtml}
        </div>
        <img css={{margin: '24px 0'}} src={image} width={160} height={120} />
        <div>
          During the study, participants should open the {appOpen}{' '}
          <a href={dailyLink} target='_blank' css={{color: color.primary}}>
            daily
          </a>{' '}
          and let them sync.
        </div>
        {RIF(
          garminStreamEnable,
          <div css={{marginTop: '16px', color: color.warning}}>
            To collect ACC data, participants will need to manually start and stop data recording on the Labfront app.
          </div>,
        )}
      </div>

      {/* Participant Resources */}
      <div
        css={{
          background: color.white,
          borderRadius: '8px',
          padding: '24px 18px',
          maxWidth: '250px',
          margin: '24px auto 0',
        }}
      >
        <div css={{display: 'flex', alignItems: 'center'}}>
          <img src={SurveyBlackIcon} height={20} width={20} />
          <p css={{marginLeft: '8px'}}>Participant Resources</p>
        </div>
        <div css={{marginTop: '16px', marginBottom: '24px', color: color.grey_600}}>
          Below are some resources for you to guide your participants:
        </div>
        <a href={resourceLink1} target='_blank' css={{display: 'flex', alignItems: 'center', marginBottom: '16px'}}>
          <p
            css={{
              color: color.primary,
              marginRight: '6px',
              textDecoration: 'underline',
              ':hover': {color: '#2E4BD7'},
            }}
          >
            Setting up app and device
          </p>
          <img src={LinkToBlueIcon} width={16} height={16} />
        </a>
        <a href={resourceLink2} target='_blank' css={{display: 'flex', alignItems: 'center'}}>
          <p
            css={{
              color: color.primary,
              marginRight: '6px',
              textDecoration: 'underline',
              ':hover': {color: '#2E4BD7'},
            }}
          >
            Instruction during the study
          </p>
          <img src={LinkToBlueIcon} width={16} height={16} />
        </a>
      </div>
    </div>
  )
}
