import {useNavigate} from 'react-router-dom'
import {selectTheme, selectProfile} from '../../store'
import {Button} from '..'
import ErrorImg from '../../asset/image/error_image.png'

export const ErrorPage = (props) => {
  const {color, fontWeight} = selectTheme()
  const profile = selectProfile()
  const navigate = useNavigate()

  const {collectInfoFromChild} = props
  collectInfoFromChild(profile)

  const handleClick = () => {
    navigate('/project')
    window.location.reload()
  }

  return (
    <div
      css={{
        background: color.grey_100,
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        zIndex: 110,
      }}
    >
      <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '50%'}}>
        <div css={{marginRight: '70px'}}>
          <div css={{fontSize: '32px', fontWeight: fontWeight.thick}}>Something went wrong!</div>
          <p css={{fontSize: '16px', fontWeight: fontWeight.thick, margin: '8px 0 32px'}}>
            Sorry, an error has occurred and we're working to fix the problem! If you need any support or want to report
            a bug, please
            <a
              href='https://www.labfront.com/contact'
              css={{fontSize: '16px', fontWeight: fontWeight.thick, color: color.primary}}
            >
              {' '}
              contact us.
            </a>
          </p>
          <Button onClick={handleClick}>Back to My Workspace</Button>
        </div>
        <img src={ErrorImg} width='268' height='234' />
      </div>
    </div>
  )
}
