import { selectTheme } from "../../store"

import { 
  ButtonCancel,
  ButtonReverse,
} from ".."

import {
  DataDownloadingImg
} from '../../asset/image'

import { Dispatch, SetStateAction } from "react"

interface PopupDataDownloadingProps {
  setRenderPopupDataDownloading: Dispatch<SetStateAction<boolean>>
}

export const PopupDataDownloading = (props: PopupDataDownloadingProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {setRenderPopupDataDownloading} = props

  const handleClose = () => {
    if (setRenderPopupDataDownloading) return setRenderPopupDataDownloading(false)
  }

  return (
    <div css={{
      background: color.white,
      borderRadius: '5px',
      padding: '24px',
      width: '473px',
      position: 'fixed',
      left: '48px',
      bottom: '48px',
      zIndex: '104',
      boxShadow: '0px 4px 12px 0px rgba(212, 212, 212, 0.25)',
    }}>
      <div css={{
        width: '100%',
        marginBottom: '24px',
      }}>
        <ButtonCancel onClick={handleClose}/>
      </div>
      <div css={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <div>
          <p css={{
            fontSize: fontSize.h5,
            fontWeight: fontWeight.h7,
            marginBottom: '8px',
          }}>Your file is downloading!</p>
          <p css={{
            fontWeight: fontWeight.medium,
            color: color.grey_600,
            marginBottom: '13px'
          }}>Don&apos;t leave the Labfront website until your download is complete.</p>
          <ButtonReverse children={'I Got It'} onClick={handleClose}/>
        </div>
        <img src={DataDownloadingImg} width={134}/>
      </div>
    </div>
  )
}