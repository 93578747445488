import {React} from 'react'
import {selectTheme} from '../../store'

export const Thin = (props) => {
  const {fontWeight} = selectTheme()
  return <span {...props} css={{fontWeight: fontWeight.thin}} />
}
export const Normal = (props) => {
  const {fontWeight} = selectTheme()
  return <span {...props} css={{fontWeight: fontWeight.normal}} />
}
export const Bold = (props) => {
  const {fontWeight} = selectTheme()
  return <span {...props} css={{fontWeight: fontWeight.bold}} />
}
export const Thick = (props) => {
  const {fontWeight} = selectTheme()
  return <span {...props} css={{fontWeight: fontWeight.thick}} />
}
