import {useNavigate, useParams} from 'react-router-dom'
import {PopupSimple} from '..'
import {useCurrentProjectState} from '../../lib'

export const ProjectDiscardPop = (props: any) => {
  const navigate = useNavigate()
  const { projectId } = useCurrentProjectState()
  const handleDiscardAction = () => {
    localStorage.removeItem('labfront_project_content')
    navigate(`/dashboard/${projectId}/?action=summary`)
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '101',
      }}
    >
      <PopupSimple
        {...props}
        method='Discard'
        name='Changes'
        description='Do you want to discard any changes?'
        buttonText='Discard'
        buttonColor='warning'
        doAction={handleDiscardAction}
      />
    </div>
  )
}
