import {Column, ColumnConfig} from '@ant-design/charts'
import {Meta} from '@antv/g2plot'

import {ChartDataProps, DailyChartDataWithType} from '../../../model/chart'
import {dailyGarminStressTheme} from '../theme/daily_garmin_stress_theme'
import {ChartCard} from '../template/chart_card'
import {StressType} from '../../../lib/chart_data/model'
import { defaultDailyChartXAxisConfig } from '../../../lib/chart_data/util/formatter'
import { useMemo } from 'react'

export const GarminDailySummaryStressChart = (props: ChartDataProps<DailyChartDataWithType<StressType>>) => {
  const {height, width} = props
  const xField: keyof DailyChartDataWithType = 'yymmdd'
  const yField: keyof DailyChartDataWithType = 'value'
  const seriesField: keyof DailyChartDataWithType = 'type'
  const meta: Record<keyof Omit<DailyChartDataWithType, 'type'>, Meta> = {
    yymmdd: {
      alias: 'Date',
    },
    value: {
      alias: 'Stress Level (%)',
    },
  } 
  const yAxisConfig = {
    title: {text: '(%)'},
    label: {
      autoRotate: true,
      formatter: (text: string) => {
        return `${+text * 100} %`
      },
    },
  }
  const columnConfig: ColumnConfig = {
    data: props.data,
    autoFit: true,
    padding: 'auto',
    xField,
    yField,
    xAxis: defaultDailyChartXAxisConfig,
    yAxis: yAxisConfig,
    seriesField,
    meta,
    isPercent: true,
    isStack: true,
    legend: {position: 'bottom-right'},
    theme: dailyGarminStressTheme,
  }

  const config = useMemo<ColumnConfig>(() => {
    return columnConfig
  }, [])

  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: 'Stress Level',
    hintDescription: '',
    chart: <Column {...config} />,
  }
  return <ChartCard {...chartCardProps} />
}
