import {Nullish, Subtract} from 'utility-types'
import {capitalize, isNil} from 'lodash'
import {CSSProperties, useEffect, useState} from 'react'
import {DateObject} from 'react-multi-date-picker'

import {IEntityModel, IProjectScheduleConfig, ProjectScheduleFrequency, ProjectScheduleReference} from '../../shared/db'
import {Button, TextLink} from '../atoms'
import {createDispatchActions, selectTheme} from '../../store'
import {debug, RequestResult, RIF, t} from '../../lib'
import {ScheduleDateSelection, ScheduleSelection, ScheduleSelectionProps} from '../molecules'
import Scrollbars from 'react-custom-scrollbars-2'

export interface EditProjectSchedulePopupProps {
  projectId: string
  scheduleConfig: IProjectScheduleConfig | Nullish
  onSaveComplete: (scheduleConfig: IProjectScheduleConfig) => void
  onClose: () => void
}

const ProjectScheduleFrequencySelection = (props: {
  frequency: ProjectScheduleFrequency | null
  selected: boolean
  onSelect: (frequency: ProjectScheduleFrequency | null) => void
  selectionCSS?: CSSProperties
}) => {
  const {frequency, selected, onSelect, selectionCSS} = props

  const frequencyString: string = frequency
    ? `${capitalize(frequency)} basis`
    : "There's no scheduled tasks in this project."
  const descriptionString: string = (() => {
    switch (frequency) {
      case ProjectScheduleFrequency.Daily:
        return 'Tasks are completed on a daily basis—meaning participants must complete tasks on the same day.'
      case ProjectScheduleFrequency.Weekly:
        return 'Tasks need to be completed on a weekly basis—meaning whenever they complete a task during the week, it is all the same.'
      case null:
        return 'Tasks can be completed whenever. There may or may not even be tasks that must be completed.'
      default:
        throw new Error('unhandled schedule frequency setting')
    }
  })()

  return (
    <ScheduleSelection
      title={frequencyString}
      description={descriptionString}
      selected={selected}
      selectionValue={frequency}
      onSelect={onSelect}
      parentCSS={selectionCSS}
    />
  )
}

const ProjectScheduleReferenceSelection = (props: {
  reference: ProjectScheduleReference
  absoluteReferenceDate: DateObject | null
  selected: boolean
  onSelect: (reference: ProjectScheduleReference) => void
  onDateSelect: (absoluteReferenceDate: DateObject) => void
  selectionCSS?: CSSProperties
}) => {
  const {reference, absoluteReferenceDate, selected, onSelect, onDateSelect, selectionCSS} = props

  const referenceString: string = (() => {
    switch (reference) {
      case ProjectScheduleReference.Absolute:
        return 'Absolute Start Date'
      case ProjectScheduleReference.Participant:
        return 'Based on Participant Join Date'
      default:
        throw new Error('unhandled schedule reference setting')
    }
  })()

  const descriptionString: string = (() => {
    switch (reference) {
      case ProjectScheduleReference.Absolute:
        return 'The first week of tasks will start on a specific date that you choose for all participants. For example, Week 1 will run from 5/1 to 5/7.'
      case ProjectScheduleReference.Participant:
        return "The first week of tasks will start based on each participant's individual join date. For example, if someone joins on 5/1, their Week 1 will run from 5/1 to 5/7."
      default:
        throw new Error('unhandled schedule frequency setting')
    }
  })()

  const selectionProps: ScheduleSelectionProps<ProjectScheduleReference> = {
    title: referenceString,
    description: descriptionString,
    selected,
    selectionValue: reference,
    onSelect,
    parentCSS: selectionCSS,
  }

  return (
    <div>
      {RIF(
        reference === ProjectScheduleReference.Absolute,
        <ScheduleDateSelection {...selectionProps} selectedDate={absoluteReferenceDate} onDateSelect={onDateSelect} />,
      )}
      {RIF(reference !== ProjectScheduleReference.Absolute, <ScheduleSelection {...selectionProps} />)}
    </div>
  )
}

export const EditProjectSchedulePopup = (props: EditProjectSchedulePopupProps) => {
  const {projectId, scheduleConfig, onSaveComplete, onClose} = props
  const {color, fontSize, fontWeight} = selectTheme()
  const {doREQUEST_PROJECT_SCHEDULE_CONFIG_CREATE, doREQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE}: any =
    createDispatchActions()

  const [selectedFrequency, setSelectedFrequency] = useState<ProjectScheduleFrequency | null>(
    scheduleConfig?.frequency ?? ProjectScheduleFrequency.Daily,
  )
  const [selectedReference, setSelectedReference] = useState<ProjectScheduleReference | null>(
    scheduleConfig?.reference ?? null,
  )
  const [selectedAbsoluteReferenceDate, setSelectedAbsoluteReferenceDate] = useState<DateObject | null>(
    scheduleConfig?.absoluteReferenceYymmdd
      ? new DateObject({
          date: t.dateFromYYMMDD(scheduleConfig.absoluteReferenceYymmdd),
        })
      : null,
  )
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  useEffect(() => {
    if (!requestResult?.success || !requestResult.result.payload) {
      debug(requestResult)
      return
    }
    onSaveComplete(requestResult.result.payload)
  }, [requestResult])

  const isSaveEnabled: boolean = (() => {
    switch (selectedFrequency) {
      case ProjectScheduleFrequency.Weekly:
        switch (selectedReference) {
          case ProjectScheduleReference.Absolute:
            return !isNil(selectedAbsoluteReferenceDate)
          case ProjectScheduleReference.Participant:
            return true
          case null:
            return false
          default:
            throw new Error('unhandled schedule reference setting')
        }
      case ProjectScheduleFrequency.Daily:
      case null:
        return true
      default:
        throw new Error('unhandled schedule frequency setting')
    }
  })()

  const resolvePayload = (): Required<Subtract<IProjectScheduleConfig, IEntityModel>> => {
    switch (selectedFrequency) {
      case ProjectScheduleFrequency.Weekly:
        switch (selectedReference) {
          case ProjectScheduleReference.Absolute:
            if (isNil(selectedAbsoluteReferenceDate)) {
              throw new Error('invalid absoluteReferenceYymmdd for weekly freqency with absolute reference')
            }
            return {
              projectId,
              frequency: selectedFrequency,
              reference: selectedReference,
              absoluteReferenceYymmdd: t.dateToYYMMDD(selectedAbsoluteReferenceDate.toDate()),
            }
          case ProjectScheduleReference.Participant:
            return {
              projectId,
              frequency: selectedFrequency,
              reference: selectedReference,
              absoluteReferenceYymmdd: null,
            }
          case null:
            throw new Error('invalid project schedule reference for weekly freqency')
          default:
            throw new Error('unhandled schedule reference setting')
        }
      case ProjectScheduleFrequency.Daily:
        return {
          projectId,
          frequency: selectedFrequency,
          reference: null,
          absoluteReferenceYymmdd: null,
        }
      case null:
        return {
          projectId,
          frequency: null,
          reference: null,
          absoluteReferenceYymmdd: null,
        }
      default:
        throw new Error('unhandled schedule frequency setting')
    }
  }

  const handleSubmit = () => {
    if (!isSaveEnabled) {
      debug('save not enabled, cannot handle submit')
      return
    }

    if (scheduleConfig) {
      doREQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE({
        setRequestResult,
        payload: resolvePayload(),
      })
    } else {
      doREQUEST_PROJECT_SCHEDULE_CONFIG_CREATE({
        setRequestResult,
        payload: resolvePayload(),
      })
    }
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <div
        css={{
          width: '50%',
          background: color.white,
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
        }}
      >
        {/* header */}
        <div
          css={{
            width: '100%',
            padding: '16px 24px',
            borderBottom: `1px solid ${color.grey_100}`,
          }}
        >
          <span
            css={{
              color: color.black,
              fontSize: fontSize.h5,
              fontWeight: fontWeight.thick,
              margin: '0 auto',
            }}
          >
            Setup Project Schedule
          </span>
        </div>

        {/* content */}
        <Scrollbars
          autoHide={true}
          autoHideTimeout={200}
          autoHeight={true}
          autoHeightMin={500}
          style={{
            borderBottom: `1px solid ${color.grey_100}`,
          }}
        >
          <div
            css={{
              width: '100%',
              padding: '16px 24px',
            }}
          >
            <div>
              <div
                css={{
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
              >
                How tasks should be organized: daily or weekly?
              </div>
              <div>
                {[ProjectScheduleFrequency.Daily, ProjectScheduleFrequency.Weekly, null].map((frequency) => {
                  return (
                    <ProjectScheduleFrequencySelection
                      key={frequency}
                      selectionCSS={{
                        marginBottom: '8px',
                      }}
                      {...{
                        frequency,
                        selected: frequency === selectedFrequency,
                        onSelect: (value) => setSelectedFrequency(value),
                      }}
                    />
                  )
                })}
              </div>
            </div>
            {RIF(
              selectedFrequency === ProjectScheduleFrequency.Weekly,
              <div>
                <div
                  css={{
                    marginTop: '32px',
                    marginBottom: '12px',
                  }}
                >
                  How would you like to set the start date for Week 1?
                </div>
                <div>
                  {[ProjectScheduleReference.Absolute, ProjectScheduleReference.Participant].map((reference) => {
                    return (
                      <ProjectScheduleReferenceSelection
                        key={reference}
                        selectionCSS={{
                          marginBottom: '8px',
                        }}
                        {...{
                          reference,
                          absoluteReferenceDate: selectedAbsoluteReferenceDate,
                          selected: reference === selectedReference,
                          onSelect: (reference) => setSelectedReference(reference),
                          onDateSelect: (absoluteReferenceYymmdd) =>
                            setSelectedAbsoluteReferenceDate(absoluteReferenceYymmdd),
                        }}
                      />
                    )
                  })}
                </div>
              </div>,
            )}
          </div>
        </Scrollbars>

        {/* footer */}
        <div
          css={{
            width: '100%',
            padding: '16px 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TextLink onClick={() => onClose()} children='Cancel' />
          <Button disabled={!isSaveEnabled} onClick={() => handleSubmit()} children='Save' />
        </div>
      </div>
    </div>
  )
}
