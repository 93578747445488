import { assertPartialSchema, createAction, useSelector, v } from '../../../lib'
import { Variable } from '../../../shared/analysis'
export enum CorrelationVariableActionType {
  CORRELATION_VARIABLE_LIST_SET = 'CORRELATION_VARIABLE_LIST_SET',
}

export const doCORRELATION_VARIABLE_LIST_SET = createAction(CorrelationVariableActionType.CORRELATION_VARIABLE_LIST_SET)

export interface CorrelationVariableState {
  [projectId: string]: Variable[]
}

export const correlationVariableInitialState: CorrelationVariableState = {}

export const selectCorrelationVariable = (projectId: string) => useSelector(
  (state: {correlationVariable: CorrelationVariableState}) => 
    state.correlationVariable[projectId] || []
)

export const correlationVariableActionCreators = {
  doCORRELATION_VARIABLE_LIST_SET,
}

type Action =
  | {
      type: CorrelationVariableActionType.CORRELATION_VARIABLE_LIST_SET
      payload: {
        projectId: string
        correlationVariableList: Variable[]
      }
    }

export const correlationVariableReducer = (
  state: CorrelationVariableState = correlationVariableInitialState, 
  {type, payload}: Action
): CorrelationVariableState => {
  switch (type) {
    case CorrelationVariableActionType.CORRELATION_VARIABLE_LIST_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          correlationVariableList: v.array().items(v.object({
            dataType: v.string().exist(),
            variableType: v.string().exist(),
            identifier: v.string().optional(),
            taskId: v.string().optional(),
            taskName: v.string().optional(),
            threshold: v.number().optional(),
          }))
        })
      })
      const {projectId, correlationVariableList} = payload
      return {
        ...state,
        [projectId]: correlationVariableList,
      }
    }
    default:
      return {...state}
  }
}