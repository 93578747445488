import {useState, useEffect} from 'react'
import {RIF, sortBy, useCurrentProjectState, _, useCurrentWorkspaceState, RequestResult} from '../../lib'
import {
  selectTheme,
  createDispatchActions,
  selectProjectData,
  selectQuestionnaires,
  selectWorkspaces,
  selectMethod,
  selectTempTaskData,
} from '../../store'
import {
  SetupProjectTag,
  MobileAppPreview,
  AboutProjectBlock,
  DeviceBlock,
  ParticipantTaskBlock,
  InstructionBlock,
  ContactBlock,
  QuestionnairePage,
  ProjectSetupBar,
  TutorialPopup,
  SaveBar,
  ProjectDiscardPop,
  ProjectConfirmUpdatePop,
  DeviceTaskMissingPop,
} from '..'
import {WarningIcon} from '../../asset/image/icon'
import {TaskStateType, TaskTempStateType, TaskType, TaskTypeForInstructionPage} from '../../model'

export const ProjectSetupPage = () => {
  const {pad, color, fontWeight} = selectTheme()

  const {
    doREQUEST_METHOD_FETCH,
    doTEMP_TASK_DATA_SET,
    doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE,
    doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE,
    doREQUEST_METHOD_UPDATE,
    doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH,
  }: any = createDispatchActions()

  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const projectState = selectProjectData()
  const { projectId, project } = useCurrentProjectState()
  const questionnairesState = selectQuestionnaires()
  const {editingQuestionnaireId} = questionnairesState.editing
  const methodState = selectMethod()
  const garminDeviceTask: TaskStateType | undefined = _.find(methodState?.taskList, {type: TaskType.GarminDevice})
  const garminDeviceEnable =
    methodState?.garminDeviceEnable ||
    _.filter(garminDeviceTask?.garminDevice, (item) => {
      return typeof item === 'boolean' && item === true
    }).length > 0
  const methodId = project?.methodList?.[0].id || methodState?.id
  const tempTaskData = selectTempTaskData()
  const isEditingLiveProject = project?.status === 'live'

  const workspacesState = selectWorkspaces()
  const { workspaceId} = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'

  // edit live project
  const [displayDiscardPopup, setDisplayDiscardPopup] = useState(false)
  const [displayConfirmUpdatePop, setDisplayConfirmUpdatePop] = useState(false)

  const [currentBlock, setCurrentBlock] = useState('aboutProject')
  const [aboutProjectValue, setAboutProjectValue] = useState<
    Record<'name' | 'description' | 'investigator' | 'organization', string>
  >({name: '', description: '', investigator: '', organization: ''})
  const [instructionValue, setInstructionValue] = useState('')
  const [contactValue, setContactsValue] = useState('')

  // device
  const tempMovesenseDeviceEnable = tempTaskData.movesenseDeviceEnable
  const movesenseTasks: TaskStateType[] = _.filter(methodState?.taskList, {type: TaskType.StopwatchMovesenseStream})
  const tempMovesenseTasks: TaskTempStateType[] = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchMovesenseStream,
  })
  const hasMovesenseTasks: boolean = !!movesenseTasks.length || !!tempMovesenseTasks.length

  const tempGarminStreamEnable = tempTaskData.garminStreamEnable
  const garminStreamTasks: TaskStateType[] = _.filter(methodState?.taskList, {type: TaskType.StopwatchGarminStream})
  const tempGarminStreamTasks: TaskTempStateType[] = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const hasGarminStreamTasks: boolean = !!garminStreamTasks.length || !!tempGarminStreamTasks.length

  const [autoAddTaskType, setAutoAddTaskType] = useState<TaskTypeForInstructionPage>('')
  const [displayWarningDevicePop, setDisplayWarningDevicePop] = useState({
    stopwatch_movesense_stream: false, //check_stopwatch_movesense_stream
    stopwatch_garmin_stream: false,
  })

  // when input completed change left checkIcon color
  const [aboutProjectValueFilled, setAboutProjectValueFilled] = useState(false)
  const [tasksFilled, setTasksFilled] = useState(false)
  const [contactValueFilled, setContactValueFilled] = useState(false)
  const [instructionValueFilled, setInstructionValueFilled] = useState(false)
  const [finishedRequiredField, setFinishedRequiredField] = useState(false)

  const [displayFinishPopup, setDisplayFinishPopup] = useState(false)

  useEffect(() => {
    let result = true
    if (!aboutProjectValueFilled) result = false
    if (!tasksFilled) result = false
    if (!contactValueFilled) result = false
    if (!instructionValueFilled) result = false
    setFinishedRequiredField(result)
  }, [aboutProjectValueFilled, tasksFilled, contactValueFilled, instructionValueFilled])

  // show tutorial popup if nothing has been filled
  const [showingTutorial, setShowingTutorial] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    setAboutProjectValue({
      name: project?.name || '',
      investigator: project?.investigator || '',
      organization: project?.organization || '',
      description: project?.description || '',
    })
    setInstructionValue(project?.participantInstructions || '')
    setContactsValue(project?.contactDescription || '')

    setShowingTutorial(false)
    if (project) {
      if (project.investigator) return
      if (project.organization) return
      if (project.description) return
      if (project.participantInstructions) return
      if (project.contactDescription) return
      if (Object.keys(projectState).length > 1) return
      setShowingTutorial(true)
    }
  }, [project])

  useEffect(() => {
    if (methodId) {
      doREQUEST_METHOD_FETCH({
        setRequestResult,
        payload: {
          methodId,
        },
      })
    }
  }, [methodId])

  useEffect(() => {
    if (workspaceId) {
      doREQUEST_DEXCOM_INTEGRATION_LIST_FETCH({
        payload: {
          workspaceId,
        },
      })
    }
  }, [workspaceId])

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult.success) return
    if (!isEditingLiveProject) return
    const enabledTempTaskList = methodState?.taskList
      ?.filter((item: any) => item.enabled)
      .sort(sortBy('index'))
      .map((task: any, index: number) => ({
        ...task,
        existed: true,
        index,
      }))
    const hiddenTempTaskList = methodState?.taskList
      ?.filter((item: any) => !item.enabled)
      .map((task: any, index: number) => ({
        ...task,
        existed: true,
        index,
      }))
    const result = enabledTempTaskList.concat(hiddenTempTaskList)
    doTEMP_TASK_DATA_SET({
      taskList: result,
      garminDeviceEnable,
      garminConnectEnable: methodState.garminConnectEnable,
      garminStreamEnable: methodState.garminStreamEnable,
      movesenseDeviceEnable: methodState.movesenseDeviceEnable,
      dexcomIntegrationId: methodState.dexcomIntegrationId,
    })
  }, [requestResult])

  const onScroll = () => {
    const dh = document.documentElement.offsetHeight
    const wh = window.innerHeight
    const scrollTop = window.pageYOffset
    const scrollMiddle = scrollTop + (wh - 110) / 2
    const middleLineOffset = scrollMiddle / 100
    const yPercentage = (scrollTop / (dh - wh)) * 100 + 1
    if (middleLineOffset > scrollBarOffset.contacts || yPercentage >= 100) {
      setCurrentBlock('contacts')
    } else if (middleLineOffset > scrollBarOffset.instruction) {
      setCurrentBlock('instruction')
    } else if (middleLineOffset > scrollBarOffset.participantTask) {
      setCurrentBlock('participantTask')
    } else if (middleLineOffset > scrollBarOffset.device) {
      setCurrentBlock('device')
    } else if (middleLineOffset > scrollBarOffset.aboutProject) {
      setCurrentBlock('aboutProject')
    }
  }

  const blockList = ['aboutProject', 'device', 'participantTask', 'instruction', 'contacts']

  const scrollBarOffset: {[key: string]: number} = {
    aboutProject: 0,
    device: 7.3,
    participantTask: 12,
    instruction: 16.5,
    contacts: 20.2,
  }

  const handleMovesenseRemove = () => {
    if (isEditingLiveProject) {
      doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE({movesenseDeviceEnable: false})
      setDisplayWarningDevicePop((prev) => ({
        ...prev,
        stopwatch_movesense_stream: false, //check_stopwatch_movesense_stream
      }))
      setDisplayConfirmUpdatePop(true)
    }
    if (!isEditingLiveProject) {
      doREQUEST_METHOD_UPDATE({
        payload: {
          methodId,
          movesenseDeviceEnable: false,
        },
      })
      setDisplayWarningDevicePop((prev) => ({
        ...prev,
        stopwatch_movesense_stream: false, //check_stopwatch_movesense_stream
      }))
      if (
        displayWarningDevicePop.stopwatch_garmin_stream === false &&
        displayWarningDevicePop.stopwatch_movesense_stream === false //check_stopwatch_movesense_stream
      ) {
        setDisplayFinishPopup(true)
      }
    }
  }

  const handleGarminStreamRemove = () => {
    if (isEditingLiveProject) {
      doTEMP_TASK_DATA_GARMIN_STREAM_ENABLE_UPDATE({garminStreamEnable: false})
      setDisplayWarningDevicePop((prev) => ({
        ...prev,
        stopwatch_garmin_stream: false,
      }))
      setDisplayConfirmUpdatePop(true)
    }
    if (!isEditingLiveProject) {
      doREQUEST_METHOD_UPDATE({
        payload: {
          methodId,
          garminStreamEnable: false,
        },
      })
      setDisplayWarningDevicePop((prev) => ({
        ...prev,
        stopwatch_garmin_stream: false,
      }))
      if (
        displayWarningDevicePop.stopwatch_garmin_stream === false &&
        displayWarningDevicePop.stopwatch_movesense_stream === false //check_stopwatch_movesense_stream
      ) {
        setDisplayFinishPopup(true)
      }
    }
  }

  const handleClickLiveEditSave = () => {
    if (tempMovesenseDeviceEnable && !hasMovesenseTasks) {
      //check_stopwatch_movesense_stream
      return setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_movesense_stream: true}))
    }
    if (tempGarminStreamEnable && !hasGarminStreamTasks)
      return setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_garmin_stream: true}))
    return setDisplayConfirmUpdatePop(true)
  }

  window.addEventListener('scroll', onScroll)

  return (
    <>
      {RIF(
        isEditingLiveProject,
        <>
          <SaveBar
            {...{
              disabled: !finishedRequiredField,
              buttonText: 'Save and Update',
              closeAction: () => setDisplayDiscardPopup(true),
              handleClickSave: handleClickLiveEditSave,
              html: (
                <div
                  onClick={() => setDisplayDiscardPopup(true)}
                  css={{
                    marginRight: '20px',
                    color: color.warning,
                    cursor: 'pointer',
                  }}
                >
                  Discard
                </div>
              ),
            }}
            css={{boxShadow: 'none'}}
          />

          <div
            css={{
              background: color.warning,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              color: color.white,
              position: 'fixed',
              zIndex: 101,
              width: '100%',
              left: 0,
              top: '50px',
            }}
          >
            You are editing a live project--until you click Save and Update, participants will not see your changes.
          </div>
        </>,
      )}
      <div
        css={{
          paddingTop: pad.xl,
          paddingLeft: '19vw',
          paddingBottom: '7.857rem',
          background: color.background,
          width: '100%',
          minHeight: 'calc(100vh - 50px)',
          marginTop: isEditingLiveProject ? '90px' : '50px',
          display: 'flex',
        }}
        onScroll={onScroll}
      >
        <div
          css={{
            position: 'fixed',
            left: '30px',
            top: isEditingLiveProject ? '135' : '85px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div css={{width: '190px', marginBottom: '24px'}}>
            <div
              css={{
                fontSize: '20px',
                fontWeight: '700',
                marginBottom: '8px',
              }}
            >
              {isEditingLiveProject ? 'Edit Live Project' : 'App Builder'}
            </div>
            <div>
              {isEditingLiveProject
                ? 'You can change some of the content. Some editing actions may be limited.'
                : 'Create and customize the participant mobile app for your research project.'}
            </div>
          </div>

          {blockList.map((item, index) => {
            return (
              <SetupProjectTag
                key={index}
                {...{
                  blockName: item,
                  scrollBarOffset: scrollBarOffset[item],
                  currentBlock,
                  setCurrentBlock,
                  aboutProjectValueFilled,
                  tasksFilled,
                  contactValueFilled,
                  instructionValueFilled,
                }}
              />
            )
          })}
          {/* <div css={{
              cursor: 'pointer',
              display: 'flex', alignItems: 'center',
              marginTop: '25px',
              color: color.primary}}>
              <img src={DownloadIcon} width='20' css={{marginRight: '10px'}}/>
              Download PDF
            </div> */}
        </div>
        <div>
          {RIF(
            role === 'viewer',
            <div
              css={{
                width: '55vw',
                height: '40px',
                borderRadius: '5px',
                background: 'rgba(241, 144, 110, 0.2)',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '18px',
                marginBottom: pad.slightlyLarger,
              }}
            >
              <img
                width={20}
                src={WarningIcon}
                css={{
                  marginRight: pad.mediumSmall,
                }}
              />
              <p
                css={{
                  fontWeight: fontWeight.medium,
                }}
              >
                Sorry - Your current access level does not allow you to edit project.
              </p>
            </div>,
          )}
          <AboutProjectBlock
            {...{
              setAboutProjectValueFilled,
              aboutProjectValue,
              setAboutProjectValue,
            }}
          />

          <DeviceBlock
            {...{
              setAutoAddTaskType,
            }}
          />

          <ParticipantTaskBlock
            {...{
              setTasksFilled,
              autoAddTaskType,
              setAutoAddTaskType,
            }}
          />

          <InstructionBlock
            {...{
              setInstructionValueFilled,
              instructionValue,
              setInstructionValue,
            }}
          />

          <ContactBlock
            {...{
              setContactValueFilled,
              contactValue,
              setContactsValue,
            }}
          />
        </div>
        <MobileAppPreview
          {...{
            aboutProjectValue,
            instructionValue,
            contactValue,
            currentBlock,
            isEditingLiveProject,
          }}
        />
      </div>

      {RIF(
        role !== 'viewer',
        <ProjectSetupBar
          {...{
            aboutProjectValue,
            instructionValue,
            contactValue,
            aboutProjectValueFilled,
            tasksFilled,
            contactValueFilled,
            instructionValueFilled,
            isEditingLiveProject,
            setDisplayWarningDevicePop,
            displayFinishPopup,
            setDisplayFinishPopup,
          }}
        />,
      )}

      {RIF(editingQuestionnaireId, <QuestionnairePage />)}

      {RIF(showingTutorial, <TutorialPopup setShowingTutorial={setShowingTutorial} />)}

      {RIF(displayDiscardPopup, <ProjectDiscardPop closeAction={() => setDisplayDiscardPopup(false)} />)}

      {RIF(displayConfirmUpdatePop, <ProjectConfirmUpdatePop closeAction={() => setDisplayConfirmUpdatePop(false)} />)}

      {RIF(
        displayWarningDevicePop.stopwatch_movesense_stream === true,
        <DeviceTaskMissingPop
          {...{
            deviceTaskType: 'stopwatch_movesense_stream',
            closeAction: handleMovesenseRemove,
            exitAction: () => setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_movesense_stream: false})),
            doAction: () => {
              setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_movesense_stream: false}))
              setAutoAddTaskType(TaskType.StopwatchMovesenseStream)
            },
          }}
        />,
      )}

      {RIF(
        displayWarningDevicePop.stopwatch_garmin_stream === true,
        <DeviceTaskMissingPop
          {...{
            deviceTaskType: 'stopwatch_garmin_stream',
            closeAction: handleGarminStreamRemove,
            exitAction: () => setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_garmin_stream: false})),
            doAction: () => {
              setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_garmin_stream: false}))
              setAutoAddTaskType(TaskType.StopwatchGarminStream)
            },
          }}
        />,
      )}
    </>
  )
}
