import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {selectTheme} from '../../store'
import {handleEsc, RIF} from '../../lib'
import {Legend, Button, ButtonCancel, Checkbox, ButtonReverse} from '..'

export interface PopupConfirmationPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
  doAction?: any
  checkInput?: boolean
  checkInputPage?: boolean
  method?: string
  name: string
  id?: string
  description: string
  term1: string
  term2?: string
  buttonText: string
  children?: string | JSX.Element
}

export const PopupConfirmation = (props: PopupConfirmationPropsT) => {
  const {color, fontWeight} = selectTheme()

  const {
    closeAction,
    doAction,
    checkInputPage,
    checkInput,
    method,
    name,
    id,
    description,
    term1,
    term2,
    buttonText,
    ...rest
  } = props

  handleEsc(closeAction)

  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [checkAll, setCheckAll] = useState(false)

  useEffect(() => {
    if (checkInputPage) {
      setCheckAll(check1 && check2 && (checkInput as boolean))
    } else {
      setCheckAll(check1 && check2)
    }
    // if only 1 term need to check
    if (!!term1 && !term2) {
      setCheckAll(check1)
    }
  }, [check1, check2, checkInput])

  return (
    <div
      {...rest}
      css={{
        width: '600px',
        borderRadius: '5px',
        padding: '30px',
        background: color.white,
      }}
    >
      <ButtonCancel onClick={() => closeAction(false)} />
      <div css={{marginTop: '30px'}}>
        <Legend>
          {method} {name} {id && `'${id}'`}
        </Legend>

        <div css={{margin: '30px 0 30px 0'}}>{description}</div>

        <div {...rest} css={{marginBottom: '30px'}}></div>

        <div css={{margin: '0 0 30px 0'}}>
          <label data-testid='confirmPop_checkTerm_1' css={{display: 'flex', cursor: 'pointer'}}>
            <Checkbox value={check1} color={color.warning} onChange={setCheck1} />
            <span css={{flex: '2', marginLeft: '5px'}}>{term1}</span>
          </label>
        </div>

        {RIF(
          term2,
          <div css={{margin: '0 0 50px 0'}}>
            <label data-testid='confirmPop_checkTerm_2' css={{display: 'flex', cursor: 'pointer'}}>
              <Checkbox value={check2} color={color.warning} onChange={setCheck2} />
              <span css={{flex: '2', marginLeft: '5px'}}>{term2}</span>
            </label>
          </div>,
        )}

        <div css={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <div
            onClick={() => closeAction(false)}
            css={{
              color: color.primary,
              fontWeight: fontWeight.bold,
              cursor: 'pointer',
            }}
          >
            Cancel
          </div>
          <ButtonReverse
            data-testid='confirmPop_submit_button'
            disabled={!checkAll}
            onClick={doAction}
            btnPadding='large'
            btnColor='warning'
          >
            {buttonText}
          </ButtonReverse>
        </div>
      </div>
    </div>
  )
}
