import {useState} from 'react'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {
  Legend, 
  Button, 
  ButtonCancel, 
  LabelBox, 
  Input, 
  UpgradeContactPop
} from '..'

export const CalcSlotsPop = (props: any) => {
  const {color, fontWeight} = selectTheme()

  const {setDisplayCalcSlotsPop} = props

  const [displayUpgradeContactPop, setDisplayUpgradeContactPop] = useState(false)
  const [value, setValue] = useState(0)

  const handleChange = (e: any) => {
    setValue(e.target.value)
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 104,
      }}
    >
      {RIF(
        displayUpgradeContactPop,
        <UpgradeContactPop
          {...{
            setDisplayUpgradeContactPop,
            setDisplayCalcSlotsPop,
          }}
        />,
      )}

      <div
        css={{
          top: 'calc(50vh - 200px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <div css={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '240px'}}>
          <div>
            <ButtonCancel onClick={() => setDisplayCalcSlotsPop(false)} />
            <div css={{marginTop: '30px'}}>
              <Legend>Buy More Participant Slots</Legend>
              <div css={{margin: '16px 0 30px 0'}}>
                Participant slots count for the entire workspace and cost $12/participant.
              </div>
              <LabelBox {...{
                label: 'How many participant slots do you want to buy?'
              }}>
                <Input onChange={handleChange} value={value} placeholder='Hint Text' type='number' />
              </LabelBox>
            </div>
          </div>

          <div
            css={{
              marginTop: '50px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <div css={{fontSize: '18px', fontWeight: fontWeight.thick}}>Total: ${Number(value) * 12}</div>
              <div css={{color: color.grey_400, marginTop: '4px'}}>$12 x {value} participant slots</div>
            </div>
            <Button onClick={() => setDisplayUpgradeContactPop(true)} btnColor='primary' btnPadding='large'>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
