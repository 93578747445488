import {debug, delay, createAction, takeLatest, put, select} from '../../lib'

import {
  REQUEST_COMPLETE, 
  doSESSION_EXPIRED_SET
} from '../state'

export const HANDLE_AUTO_LOGOUT = 'HANDLE_AUTO_LOGOUT'
export const doHANDLE_AUTO_LOGOUT = createAction(HANDLE_AUTO_LOGOUT)

export const handleAutoLogoutActionCreators = {
  doHANDLE_AUTO_LOGOUT,
}

export function* handleAutoLogout() {
  debug('saga*handleAutoLogout')
  while (true) {
    yield delay(3600000) // 1 hour

    yield put(doSESSION_EXPIRED_SET(true))

    // wait for 5 mins
    yield delay(300000)

    const sessionExpired: boolean = yield select((state) => state?.session?.sessionExpired)
    if (sessionExpired) {
      window.sessionStorage.removeItem('labfront')
      window.location.reload()
    }
  }
}

export function* handleAutoLogoutSaga() {
  yield takeLatest(REQUEST_COMPLETE, handleAutoLogout)
}
