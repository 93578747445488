import {Dispatch, SetStateAction} from 'react'
import {v4} from 'uuid'
import { BaseRequestResult } from './request'

/* create an actionCreator for updating state */
export const createAction =
  (type: string) => (payload?: any, setRequestResult?: Dispatch<SetStateAction<RequestResult>>) => ({
    type,
    payload,
    setRequestResult
  })

export interface RequestResult {
  requestType: string
  success: boolean
  result: BaseRequestResult
}

export interface CreateDoRequestActionInput {
  setRequestResult?: Dispatch<SetStateAction<RequestResult | null>>
  payload: {[key: string]: any}
}

interface CreateDoRequestActionOutput {
  [key: string]: any
}

export type RequestAction = (input: CreateDoRequestActionInput) => CreateDoRequestActionOutput

/* create an actionCreator for creating api requests with error handling */
export const createDoRequestAction =
  (type: string) =>
  ({setRequestResult, payload}: CreateDoRequestActionInput): CreateDoRequestActionOutput => {
    const requestId: string = v4()
    return {
      type,
      payload: {
        ...payload,
        requestId,
      },
      setRequestResult,
    }
  }
