import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import * as stateInterface from './state'
import * as sagaInterface from './saga'

const actionInterface = {
  ...stateInterface,
  ...sagaInterface,
}

const actionCreators = Object.keys(actionInterface).reduce((result, key) => {
  const current = key.endsWith('ActionCreators') ? actionInterface[key] : {}

  return {
    ...current,
    ...result,
  }
}, {})

export const createDispatchActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(
    {
      ...actionCreators,
    },
    dispatch,
  )
}
