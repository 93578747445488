import {Dispatch, SetStateAction} from 'react'
import {selectTheme} from '../../store'
import {SlideCheckbox, Tooltip} from '..'
import {RIF} from '../../lib'
import {
  DailySummaryIcon,
  ActivitySummaryIcon,
  SleepIcon,
  HeartRateIcon,
  StressIcon,
  StepsIcon,
  RespirationIcon,
  OxygenSaturationIcon,
  DataUploadIcon,
} from '../../asset/image'
import GarminConnectAndCompanionAppImg from '../../asset/image/garmin_connect_companion_app.png'

export interface GarminConnectPanelPropsT {
  garminConnectEnable: boolean
  setGarminConnectEnable: Dispatch<SetStateAction<boolean>>
}

export const GarminConnectPanel = (props: GarminConnectPanelPropsT) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()

  const {garminConnectEnable, setGarminConnectEnable} = props
  const handleChangeOfGarminCloud = (e: any) => {
    setGarminConnectEnable(e)
  }

  const ToolTipContent = `Supported devices: 
  ・VIVOMOVE 3
  ・VIVOACTIVE 4
  ・VENU
  ・VENU SQ / SQM, VENU 2 / 2S
  ・FENIX 6 SERIES / 6 SOLAR SERIES
  ・FORERUNNER 245 / 245M, 745, 945 / 945 LTE
  ・LILY 
  ・ENDURO`

  return (
    <div
      css={{
        border: `1px solid ${garminConnectEnable ? color.primary : color.grey_160}`,
        borderRadius: '5px',
        minHeight: '750px',
        width: '48.5%',
        padding: '24px',
        background: garminConnectEnable ? color.white : color.grey_20,
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '24px',
        }}
      >
        <div>
          <p css={{fontWeight: fontWeight.bold, color: garminConnectEnable ? color.primary : '', fontSize: '16px'}}>
            Garmin Connect App
          </p>
          <p
            css={{
              fontWeight: fontWeight.medium,
              fontSize: fontSize.base,
              color: color.grey_600,
              marginTop: '8px',
              marginRight: '24px',
            }}
          >
            The standard data with sampling rates collected by default from the Garmin Health Cloud.
          </p>
        </div>
        <div css={{display: 'flex', alignItems: 'center'}}>
          <SlideCheckbox
            from='garmin_connect'
            disabledColor={color.black}
            value={garminConnectEnable}
            onChange={(e) => handleChangeOfGarminCloud(e)}
          />
          <p
            css={{
              fontWeight: fontWeight.bold,
              marginLeft: pad.small,
              color: `${garminConnectEnable ? color.primary : color.black}`,
            }}
          >
            {garminConnectEnable ? 'ON' : 'OFF'}
          </p>
        </div>
      </div>

      <div
        css={{
          borderTop: `1px solid ${color.grey_160}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px',
        }}
      >
        <img src={GarminConnectAndCompanionAppImg} width={120} height={90} />
        <p css={{color: color.grey_600, marginLeft: '24px', fontSize: '12px'}}>
          Requires participants to set up both Garmin Connect App and Labfront Companion App on their smartphone.{' '}
        </p>
      </div>

      <div css={{borderRadius: '8px', border: `1px solid ${color.grey_100}`}}>
        <div
          css={{
            background: color.background,
            borderRadius: '8px 8px 0 0',
            padding: '16px',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${color.grey_160}`,
          }}
        >
          <img src={DataUploadIcon} width={20} height={20} />
          <div css={{marginLeft: '8px'}}>
            <div css={{fontWeight: '700'}}>Continuous Data Collection</div>
            <div css={{marginTop: '8px', fontSize: '12px', color: color.grey_600}}>
              Once activated, the Garmin device will continuously collect this data in the background.{' '}
            </div>
          </div>
        </div>

        <div css={{padding: '16px', paddingBottom: '0'}}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              margin: '8px 0',
            }}
          >
            <p css={{color: color.grey_400}}>Data Type</p>
            <div css={{display: 'flex', alignItems: 'center'}}>
              <p css={{color: color.grey_400, marginRight: pad.small}}>Sampling Rate</p>
              <Tooltip content='Choose how often the data is collected.' />
            </div>
          </div>

          <GarminConnectDataBar
            {...{
              icon: DailySummaryIcon,
              type: 'Daily Summary',
              sample: 'Daily',
              garminConnectEnable,
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: ActivitySummaryIcon,
              type: 'Activity Summary',
              sample: '15 min',
              garminConnectEnable,
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: SleepIcon,
              type: 'Sleep',
              sample: 'Auto-Detect',
              garminConnectEnable,
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: HeartRateIcon,
              type: 'Heart Rate',
              sample: '1 min',
              garminConnectEnable,
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: StressIcon,
              type: 'Stress',
              sample: '3 min',
              garminConnectEnable,
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: StepsIcon,
              type: 'Steps',
              sample: '15 min',
              garminConnectEnable,
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: RespirationIcon,
              type: 'Respiration',
              sample: '3 min',
              garminConnectEnable,
              html: (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '5px',
                    marginBottom: pad.small,
                  }}
                >
                  <p css={{color: color.grey_400, marginRight: pad.small, fontSize: '12px'}}>
                    Only available on some devices
                  </p>
                  <Tooltip content={ToolTipContent} textAlign='start' tooltipWidth='380px' />
                </div>
              ),
            }}
          />

          <GarminConnectDataBar
            {...{
              icon: OxygenSaturationIcon,
              type: 'Oxygen Saturation',
              sample: '3 min',
              garminConnectEnable,
              html: (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}
                >
                  <p css={{color: color.grey_400, marginRight: pad.small, fontSize: '12px'}}>
                    May require participant to turn on in Garmin <br /> Connect App
                  </p>
                </div>
              ),
              borderBottom: 'none',
            }}
          />
        </div>
      </div>
    </div>
  )
}

interface GarminConnectDataBarPropsT {
  icon: any
  type: string
  sample: string
  html?: any
  borderBottom?: string
  garminConnectEnable: boolean
}

const GarminConnectDataBar = (props: GarminConnectDataBarPropsT) => {
  const {pad, fontWeight, color} = selectTheme()
  const {icon, type, sample, html, borderBottom, garminConnectEnable} = props
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '16px 0',
        borderBottom: borderBottom === 'none' ? '' : `1px solid ${color.grey_100}`,
        position: 'relative',
      }}
    >
      {RIF(
        !garminConnectEnable,
        <div
          css={{
            background: 'rgba(255, 255, 255, 0.3)',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 102,
          }}
        ></div>,
      )}
      <div css={{display: 'flex', alignItems: html ? 'start' : 'center'}}>
        <img src={icon} width='26' css={{marginRight: pad.medium}} />
        <div>
          <div css={{fontWeight: fontWeight.medium}}>{type}</div>
          {html}
        </div>
      </div>
      <p css={{fontWeight: fontWeight.medium}}>{sample}</p>
    </div>
  )
}
