import {DataFrame} from 'data-forge'
import * as dateFns from 'date-fns'
import {getDataIntervalByDataType} from '../../lib/chart_data/util/time_series_chart_util'
import {TaskGarminDevice, TimeSeriesDataSource} from '../../model'
import {
  LocalDateDeviceLogData,
  DeviceDataChunk,
  GarminDeviceBBIChunk,
  GarminDeviceStepChunk,
  GarminDeviceHeartRateChunk,
  GarminDeviceStressChunk,
  GarminDevicePulseOxChunk,
  GarminDeviceActigraphyChunk,
  GarminDeviceRespirationChunk,
  ChunkMetaData,
  MetaDataMap,
  GarminDeviceDataChunk,
} from '../../shared/mongo/localdate_device_logdata'
import {DB_NAME, DbOperationResult, dailyDataDb, rawDataDb, streamDataDb, timeSeriesForChartDb} from '.'
import {setMany} from 'idb-keyval'
import {LocalDateDexcomData} from '../../shared/mongo/localdate_dexcom_data'
import {
  AnalysisDataType,
  DeviceDailyDataType,
  DexcomDeviceDataType,
  GarminConnectRecordDataDaily,
  GarminConnectRecordDataEpoch,
  GarminConnectRecordDataSleeps,
  GarminConnectRecordDataStress,
  GarminDeviceDailySummary,
  GarminDeviceLogDataType,
  GarminDeviceStreamDataType,
  LocalDateDataQueryKey,
  LocalDateDataType,
  MovesenseDeviceStreamDataType,
} from '../../shared/mongo'
import {LocalDateActigraphySleep, LocalDateAnalysisData} from '../../shared/mongo/localdate_analysis_data'
import {
  BasicXYZ,
  DeviceStreamData,
  MovesenseEcgChunk,
  MovesenseStreamBBI,
  MovesenseStreamIMU,
  RawDataChunk,
} from '../../shared/mongo/device_stream_data'
import {GarminConnectHookType} from '../../shared/api'
import {
  LocalDateGarminConnectDaily,
  LocalDateGarminConnectData,
  LocalDateGarminConnectSleeps,
} from '../../shared/mongo/localdate_garmin_connect_data'
import {last} from 'lodash'
import {VisualizerGraphDataType} from '../../shared/db'
import {
  GarminLogDataTypeToGraphDataTypeMap,
  StreamDataTypeToGraphDataTypeMap,
} from '../../components/charts/utils/utils'
import _ from 'lodash'
import {LocalDateDeviceDailySummary} from '../../shared/mongo/localdate_device_daily_summary'
import {t} from '../../lib'
import {GarminConnectDailySummaryDbData, GarminConnectDailySleepDbData} from './model'

export interface DbContent<T, R> {
  insertedDate: Date
  sourceUpdatedDate?: Date
  dataType: T
  yymmdd: number
  data: R
}

export interface TimeSeriesDataDbContent {
  participantId: string
  yymmddIndex: number
  rawDataDbContent: DbContent<string, TransformedGarminDeviceLogData | TransformedDexcomEgvData | object>
  timeSeriesForChartDbContentList: DbContent<VisualizerGraphDataType, TimeSeriesDataSource[]>[]
}

export interface DailyDataDbContent {
  participantId: string
  yymmddIndex: number
  dbContent: DbContent<DailyDataType, DailyDataDbData>
}

type DailyDataDbData = GarminDeviceDailySummary | GarminConnectDailySummaryDbData | GarminConnectDailySleepDbData

export interface TransformedGarminDeviceLogData {
  timezoneRef: string
  timeOffsetRef: number
  startChunkUtcIndex: number
  metaDataMap: MetaDataMap<ChunkMetaData>
  rawData: object[]
}

export interface TransformedDexcomEgvData {
  egvs: object[]
  events?: object[]
}

export async function insertGarminDirectDataToDb(
  queryParticipantId: string,
  queryYYMMDDIndex: number,
  queryDataTypeList: GarminDeviceLogDataType[],
  garminDeviceTaskConfig: TaskGarminDevice,
  dataList: LocalDateDeviceLogData[],
  storeRawData?: boolean,
): Promise<DbOperationResult<VisualizerGraphDataType>[]> {
  const expectResult: DbOperationResult<VisualizerGraphDataType>[] = queryDataTypeList
    .map((dataType) => GarminLogDataTypeToGraphDataTypeMap[dataType])
    .filter((graphDataType): graphDataType is VisualizerGraphDataType => {
      return graphDataType !== null
    })
    .map((graphDataType) => {
      return {
        dbName: DB_NAME.TimeSeriesForChart,
        participantId: queryParticipantId,
        dataType: graphDataType,
        yymmddIndex: queryYYMMDDIndex,
        operation: 'set',
        result: false,
      }
    })

  if (dataList.length > 0) {
    const dbContentList = dataList.map((data: LocalDateDeviceLogData) => {
      return convertLocalDateDeviceLogDataToGarminDirecetDbContent(data, garminDeviceTaskConfig)
    })

    if (storeRawData) {
      const expectRawDataResult: DbOperationResult<string>[] = queryDataTypeList.map((dataType) => {
        return {
          dbName: DB_NAME.TimeSeriesForChart,
          participantId: queryParticipantId,
          dataType,
          yymmddIndex: queryYYMMDDIndex,
          operation: 'set',
          result: false,
        }
      })
      insertRawDataDbContent(expectRawDataResult, dbContentList)
      // todo handle raw data inserted result
    }

    return insertTimeSeriesDataDbContent(expectResult, dbContentList)
  } else {
    return expectResult
  }
}

async function insertTimeSeriesDataDbContent(
  dbOperationResults: DbOperationResult<VisualizerGraphDataType>[],
  dbContentList: TimeSeriesDataDbContent[],
): Promise<DbOperationResult<VisualizerGraphDataType>[]> {
  const updateResult = (dataType: string, yymmddIndex: number) => {
    const resultIndex = dbOperationResults.findIndex(
      (result) => result.dataType === dataType && result.yymmddIndex === yymmddIndex,
    )

    if (resultIndex !== -1) {
      dbOperationResults[resultIndex].result = true
    }
  }

  try {
    const timeSeriesContentList: [IDBValidKey, object][] = []
    dbContentList.forEach((dbContent) => {
      dbContent.timeSeriesForChartDbContentList.forEach((timeSeriesDbContent) => {
        const key = `${dbContent.participantId}-${dbContent.yymmddIndex}-${timeSeriesDbContent.dataType}`
        timeSeriesContentList.push([key, timeSeriesDbContent])
        updateResult(timeSeriesDbContent.dataType, dbContent.yymmddIndex)
      })
    })

    await setMany(timeSeriesContentList, timeSeriesForChartDb)
  } catch (e) {
    console.log(e)
    return []
  }

  return dbOperationResults
}

async function insertRawDataDbContent(
  dbOperationResults: DbOperationResult<string>[],
  dbContentList: TimeSeriesDataDbContent[],
): Promise<DbOperationResult<string>[]> {
  const updateResult = (dataType: string, yymmddIndex: number) => {
    const resultIndex = dbOperationResults.findIndex(
      (result) => result.dataType === dataType && result.yymmddIndex === yymmddIndex,
    )

    if (resultIndex !== -1) {
      dbOperationResults[resultIndex].result = true
    }
  }

  const mapToDbContent = (dbContent: TimeSeriesDataDbContent) => {
    updateResult(dbContent.rawDataDbContent.dataType, dbContent.yymmddIndex)
    const key = `${dbContent.participantId}-${dbContent.yymmddIndex}-${dbContent.rawDataDbContent.dataType}`
    const content = dbContent.rawDataDbContent

    return [key, content] as [IDBValidKey, object]
  }

  try {
    const rawDataContentList = dbContentList.map((dbContent) => mapToDbContent(dbContent))
    await setMany(rawDataContentList, rawDataDb)
  } catch (e) {
    console.log(e)
  }

  return dbOperationResults
}

export function convertLocalDateDeviceLogDataToGarminDirecetDbContent(
  sourceData: LocalDateDeviceLogData,
  garminDeviceTaskConfig?: TaskGarminDevice,
): TimeSeriesDataDbContent {
  const participantId = sourceData.participantId
  const dataType = sourceData.dataType
  const sortedChunkList = new DataFrame(Object.values(sourceData.rawDataMap)).orderBy((chunk) => chunk.queryUtcIndex)
  const rawData = sortedChunkList
    .selectMany((chunk) => {
      return chunk.rawData.map((data) => data)
    })
    .toArray()

  const firstChunk = sortedChunkList.at(0) as GarminDeviceDataChunk
  const transformedGarminDeviceLogData: TransformedGarminDeviceLogData = {
    startChunkUtcIndex: firstChunk.queryUtcIndex,
    timezoneRef: firstChunk.timezone,
    timeOffsetRef: firstChunk.timeOffset,
    metaDataMap: sourceData.metaDataMap,
    rawData: rawData,
  }

  const timeSeriesRaw = sortedChunkList
    .selectMany((chunk) => convertRawDataMapToTimeSeriesRaw(dataType, chunk))
    .toArray()
  const dataIntervalSeconds = getDataIntervalByDataType(dataType, garminDeviceTaskConfig)
  // const timeSeriesForPlot = convertRawToFullDayTimeSeries(sourceData.yymmddIndex, timeSeriesRaw, dataIntervalSeconds)
  const timeSeriesForPlot = checkNeedPatchEmptyDataForLineChart(dataType)
    ? patchEmptyDataToTimeSeries(timeSeriesRaw, dataIntervalSeconds)
    : timeSeriesRaw

  const graphDataType = GarminLogDataTypeToGraphDataTypeMap[dataType]
  if (graphDataType === null) {
    throw Error(`${dataType} is not supported in visualization`)
  }
  return {
    participantId,
    yymmddIndex: sourceData.yymmddIndex,
    rawDataDbContent: {
      insertedDate: new Date(),
      sourceUpdatedDate: sourceData.updatedDate,
      dataType: dataType,
      yymmdd: sourceData.yymmddIndex,
      data: transformedGarminDeviceLogData,
    },
    timeSeriesForChartDbContentList: [
      {
        insertedDate: new Date(),
        sourceUpdatedDate: sourceData.updatedDate,
        dataType: graphDataType as VisualizerGraphDataType,
        yymmdd: sourceData.yymmddIndex,
        data: timeSeriesForPlot,
      },
    ],
  }
}

function convertRawDataMapToTimeSeriesRaw(
  dataType: GarminDeviceLogDataType,
  dataChunk: DeviceDataChunk,
): TimeSeriesDataSource[] {
  const timeOffset = dataChunk.timeOffset
  switch (dataType) {
    case GarminDeviceLogDataType.GarminBBI:
      return (dataChunk as GarminDeviceBBIChunk).rawData.map((item) => ({
        t: item.t + timeOffset,
        v: item.bbi,
        status: item.status,
      }))
    case GarminDeviceLogDataType.GarminStep:
      return (dataChunk as GarminDeviceStepChunk).rawData.map((item) => ({
        t: item.t + timeOffset,
        v: item.steps,
        total: item.totalSteps,
      }))
    case GarminDeviceLogDataType.GarminHeartRate:
      return (dataChunk as GarminDeviceHeartRateChunk).rawData.map((item) => ({
        t: item.t + timeOffset,
        v: item.bpm > 0 ? item.bpm : undefined,
      }))
    case GarminDeviceLogDataType.GarminStress:
      return (dataChunk as GarminDeviceStressChunk).rawData.map((item) => ({
        t: item.t + timeOffset,
        v: item.stress,
      }))
    case GarminDeviceLogDataType.GarminPulseOx:
      return (dataChunk as GarminDevicePulseOxChunk).rawData.map((item) => {
        return {
          t: item.t + timeOffset,
          v: item.pulseOx > 0 ? item.pulseOx : undefined,
          cofidence: item.confidence,
        }
      })
    case GarminDeviceLogDataType.GarminActigraphy:
      return (dataChunk as GarminDeviceActigraphyChunk).rawData.map((item) => ({
        t: item.t + timeOffset,
        v: item.zcCount,
        energy: item.energy,
      }))
    case GarminDeviceLogDataType.GarminRespiration:
      return (dataChunk as GarminDeviceRespirationChunk).rawData.map((item) => {
        return {
          t: item.t + timeOffset,
          v: item.bpm > 0 ? item.bpm : undefined,
        }
      })

    default:
      return []
  }
}

function checkNeedPatchEmptyDataForLineChart(dataType: GarminDeviceLogDataType): boolean {
  if (dataType == GarminDeviceLogDataType.GarminActigraphy || dataType === GarminDeviceLogDataType.GarminHeartRate)
    return true
  return false
}

function patchEmptyDataToTimeSeries(
  rawData: TimeSeriesDataSource[],
  dataIntervalSeconds: number,
): TimeSeriesDataSource[] {
  const dataForPlot: TimeSeriesDataSource[] = []
  if (rawData.length > 0) {
    const dataInervalMs = dataIntervalSeconds * 1000
    const dataInervalMs2Times = dataInervalMs * 2

    for (let i = 0; i < rawData.length - 1; i++) {
      const timeDiff = rawData[i + 1].t - rawData[i].t
      dataForPlot.push(rawData[i])
      if (timeDiff > dataInervalMs2Times) {
        dataForPlot.push({
          t: rawData[i].t + dataInervalMs,
        })
      }
    }
    dataForPlot.push(rawData[rawData.length - 1])
  }

  return dataForPlot
}

function convertRawToFullDayTimeSeries(
  yymmddIndex: number,
  rawData: TimeSeriesDataSource[],
  dataIntervalSeconds: number,
): TimeSeriesDataSource[] {
  const dataForPlot: TimeSeriesDataSource[] = []
  const dataCount = rawData.length
  if (dataCount > 0) {
    const year = 2000 + Math.floor(yymmddIndex / 10000)
    const mon = Math.floor((yymmddIndex % 10000) / 100) - 1
    const day = yymmddIndex % 100
    const startDate = new Date(Date.UTC(year, mon, day))
    const startTimestamp = startDate.getTime()
    const resultLength = Math.floor(86400 / dataIntervalSeconds)
    const dataInervalMs = dataIntervalSeconds * 1000

    let dataIndex = 0
    for (let i = 0; i < resultLength; i++) {
      const timestampMs = startTimestamp + i * dataInervalMs
      let plotData: TimeSeriesDataSource = {t: timestampMs / 1000}

      while (dataIndex < dataCount && rawData[dataIndex].t <= timestampMs + dataInervalMs) {
        const dataEntry = rawData[dataIndex]
        dataIndex++

        // get the first data in the second, and skip the rest
        if (dataEntry.t >= timestampMs) {
          const t = plotData.t
          plotData = {...dataEntry}
          plotData.t = t
          break
        }
      }
      dataForPlot.push(plotData)
    }
  }
  return dataForPlot
}

export async function insertDexcomDataToDb(
  queryParticipantId: string,
  queryYYMMDDIndexes: number[],
  dataList: LocalDateDexcomData[],
  storeRawData?: boolean,
) {
  if (dataList.length > 0) {
    const dbContentList = convertLocalDateDexcomDataToDbContentList(dataList)
    const expectResult: DbOperationResult<VisualizerGraphDataType>[] = []

    queryYYMMDDIndexes.forEach((yymmddIndex) => {
      expectResult.push({
        dbName: DB_NAME.TimeSeriesForChart,
        participantId: queryParticipantId,
        dataType: VisualizerGraphDataType.DexcomBloodGlucose,
        yymmddIndex: yymmddIndex,
        operation: 'set',
        result: false,
      })
    })

    return insertTimeSeriesDataDbContent(expectResult, dbContentList)
  } else {
    return []
  }
}

function convertLocalDateDexcomDataToDbContentList(dataList: LocalDateDexcomData[]): TimeSeriesDataDbContent[] {
  const result: TimeSeriesDataDbContent[] = []

  function extractTimeOffsetInSeconds(isoString: string): number {
    const pattern = /([+-]\d{2}):(\d{2})$/
    const match = pattern.exec(isoString)

    if (match && match.length === 3) {
      const hours = parseInt(match[1], 10)
      const minutes = parseInt(match[2], 10)
      return (hours * 60 + minutes) * 60000
    } else {
      return new Date().getTimezoneOffset() * 60000
    }
  }

  for (const data of dataList) {
    const participantId = data.participantId
    const rawData = data.egvs
      ? data.egvs
          .map((item) => {
            const dataDate = new Date(item.displayTime)
            const timeOffset = extractTimeOffsetInSeconds(item.displayTime)
            return {t: dataDate.getTime() + timeOffset, v: item.value}
          })
          .reverse()
          .sort((a, b) => {
            return a.t - b.t
          })
      : []

    result.push({
      participantId,

      yymmddIndex: data.yymmddIndex,
      rawDataDbContent: {
        insertedDate: new Date(),
        sourceUpdatedDate: data.updatedDate,
        dataType: DexcomDeviceDataType.DexcomRecord,
        yymmdd: data.yymmddIndex,
        data: {
          egvs: rawData,
          events: data.events,
        },
      },
      timeSeriesForChartDbContentList: [
        {
          insertedDate: new Date(),
          sourceUpdatedDate: data.updatedDate,
          dataType: VisualizerGraphDataType.DexcomBloodGlucose,
          yymmdd: data.yymmddIndex,
          data: rawData,
        },
      ],
    })
  }
  return result
}

export async function insertAnalysisDataToDb(
  garminDeviceTaskConfig: TaskGarminDevice,
  data: LocalDateAnalysisData,
  storeRawData?: boolean,
) {
  const {participantId, dataType, yymmddIndex} = data

  const dataIntervalSeconds = getDataIntervalByDataType(dataType, garminDeviceTaskConfig)
  const timeSeriesRaw = getTimeSeriesRawFromAnanlyzedData(data)

  const expectResult: DbOperationResult<VisualizerGraphDataType>[] = []
  expectResult.push({
    dbName: DB_NAME.TimeSeriesForChart,
    participantId,
    dataType: VisualizerGraphDataType.AnalysisSleepScore,
    yymmddIndex,
    operation: 'set',
    result: false,
  })

  const dbContentList = [
    {
      participantId,
      yymmddIndex,
      rawDataDbContent: {
        insertedDate: new Date(),
        sourceUpdatedDate: data.updatedDate,
        dataType,
        yymmdd: data.yymmddIndex,
        data: timeSeriesRaw,
      },
      timeSeriesForChartDbContentList: [
        {
          insertedDate: new Date(),
          sourceUpdatedDate: data.updatedDate,
          dataType: VisualizerGraphDataType.AnalysisSleepScore,
          yymmdd: data.yymmddIndex,
          data: timeSeriesRaw,
          // data: convertRawToFullDayTimeSeries(yymmddIndex, timeSeriesRaw, dataIntervalSeconds),
        },
      ],
    },
  ]

  return insertTimeSeriesDataDbContent(expectResult, dbContentList)
}

function getTimeSeriesRawFromAnanlyzedData(analysisData: LocalDateAnalysisData) {
  switch (analysisData.dataType) {
    case AnalysisDataType.ActigraphySleep:
      return (analysisData as LocalDateActigraphySleep).data.sleepScores.map((data, index) => {
        return {
          t: data.t + analysisData.firstDataTimeOffset,
          v: data.sc ?? undefined,
          sw: ((analysisData as LocalDateActigraphySleep).data?.sleepWake?.[index].sw ? 1 : 0) ?? null,
        }
      })
    case AnalysisDataType.HRV:
      return []
  }
}

export interface TransformedStreamDataForPlot {
  timezone: string
  timeOffset: number
  dataStartUnixTime: number
  timeLocalizedData: TimeSeriesDataSource[]
}

type StreamDataDbContent = [IDBValidKey, TransformedStreamDataForPlot]
export interface StreamDataDbInsertedResult {
  completionId: string
  dataType: string
  objectId: string
}

export async function insertStreamDataToDb(dataList: DeviceStreamData[]): Promise<StreamDataDbInsertedResult[]> {
  if (dataList.length > 0) {
    const objectIdList: StreamDataDbInsertedResult[] = []
    const dbContentList = dataList.map((data: DeviceStreamData) => {
      const dataType = data.dataType
      objectIdList.push({
        completionId: data.completionId,
        dataType: StreamDataTypeToGraphDataTypeMap[data.dataType],
        objectId: data._id!.toString(),
      })
      return convertStreamDataToDbContent(dataType, data)
    })

    try {
      // insert db
      await setMany(dbContentList, streamDataDb)
      return objectIdList
    } catch (e) {
      console.log(e)
      return []
    }
  } else {
    return []
  }
}

function convertStreamDataToDbContent(
  dataType: MovesenseDeviceStreamDataType | GarminDeviceStreamDataType,
  data: DeviceStreamData,
): StreamDataDbContent {
  const chunkList = data.chunks.sort((a, b) => {
    return a.unixTimestampStart - b.unixTimestampStart
  })

  const timeSeries: TimeSeriesDataSource[] = []
  for (const chunk of chunkList) {
    const localizedData = convertStreamDataChunkToTimeSeriesRaw(dataType, chunk)
    timeSeries.push(...localizedData)
  }

  const transformedStreamDataForPlot: TransformedStreamDataForPlot = {
    timezone: data.chunks[0].timezone,
    timeOffset: data.chunks[0].timeOffset,
    dataStartUnixTime: new Date(data.dataStartTime).getTime(),
    timeLocalizedData: timeSeries,
  }

  return [data._id!.toString() as string, transformedStreamDataForPlot]
}

function convertStreamDataChunkToTimeSeriesRaw(
  dataType: MovesenseDeviceStreamDataType | GarminDeviceStreamDataType,
  dataChunk: RawDataChunk,
): TimeSeriesDataSource[] {
  const timeOffset = dataChunk.timeOffset
  switch (dataType) {
    case MovesenseDeviceStreamDataType.MovesenseRr:
      return (dataChunk as RawDataChunk<MovesenseStreamBBI>).rawData.map((item) => ({
        t: item.t + timeOffset,
        v: item.bbi,
        snr: item.snr,
      }))
    case MovesenseDeviceStreamDataType.MovesenseImu:
      return (dataChunk as RawDataChunk<MovesenseStreamIMU>).rawData.map((item) => ({
        t: item.t + timeOffset,
        ax: item.acc.x,
        ay: item.acc.y,
        az: item.acc.z,
        gx: item.gyro.x,
        gy: item.gyro.y,
        gz: item.gyro.z,
        mx: item.mag.x,
        my: item.mag.y,
        mz: item.mag.z,
      }))
    case MovesenseDeviceStreamDataType.MovesenseEcg: {
      const result: TimeSeriesDataSource[] = []
      const data = dataChunk as RawDataChunk<MovesenseEcgChunk>
      const interval = Math.floor(1000 / data.sampleRate!)
      data.rawData.forEach((item) => {
        const baseTime = item.t + timeOffset
        item.ecg.forEach((v, index) => {
          result.push({t: baseTime + interval * index, v})
        })
      })
      return result
    }
    case MovesenseDeviceStreamDataType.MovesenseAcc:
    case MovesenseDeviceStreamDataType.MovesenseGyroscope:
    case MovesenseDeviceStreamDataType.MovesenseMagnetometer:
    case GarminDeviceStreamDataType.GarminAcc:
      return (dataChunk as RawDataChunk<BasicXYZ>).rawData.map((item) => ({
        t: item.t + timeOffset,
        x: item.x,
        y: item.y,
        z: item.z,
      }))
    default:
      return []
  }
}

const garminConnectHookTypeToTimeSeriesGraphDataType = (dataType: GarminConnectHookType) => {
  switch (dataType) {
    case GarminConnectHookType.HealthDailies:
      return [VisualizerGraphDataType.GarminConnectHeartRate]
    case GarminConnectHookType.HealthStress:
      return [VisualizerGraphDataType.GarminConnectStress, VisualizerGraphDataType.GarminConnectBodyBattery]
    case GarminConnectHookType.HealthEpochs:
      return [VisualizerGraphDataType.GarminConnectSteps]
    // todo: add spo2
    // case GarminConnectHookType.HealthPulseOx:
    //   return [VisualizerGraphDataType.GarminConnectSpo2]
    default:
      return []
  }
}

export async function generateGarminConnectDbContentList(dataList: LocalDateGarminConnectData[]) {
  return dataList.map(convertLocalDateGarminConnectDataToDbContent)
}

export async function insertGarminConnectDataToDb(
  queryParticipantId: string,
  queryYYMMDDIndex: number,
  queryDataTypeList: GarminConnectHookType[],
  dbContentList: TimeSeriesDataDbContent[],
): Promise<DbOperationResult<VisualizerGraphDataType>[]> {
  const expectResult: DbOperationResult<VisualizerGraphDataType>[] = queryDataTypeList
    .flatMap(garminConnectHookTypeToTimeSeriesGraphDataType)
    .map((dataType) => {
      return {
        dbName: DB_NAME.TimeSeriesForChart,
        participantId: queryParticipantId,
        dataType,
        yymmddIndex: queryYYMMDDIndex,
        operation: 'set',
        result: false,
      }
    })

  if (dbContentList.length > 0) {
    return insertTimeSeriesDataDbContent(expectResult, dbContentList)
  } else {
    return expectResult
  }
}

function convertLocalDateGarminConnectDataToDbContent(sourceData: LocalDateGarminConnectData): TimeSeriesDataDbContent {
  const participantId = sourceData.participantId
  const dataType = sourceData.dataType

  const timeSeriesForChartDbContentList: DbContent<VisualizerGraphDataType, TimeSeriesDataSource[]>[] = []

  if (sourceData.data.length) {
    let dbContentList: DbContent<VisualizerGraphDataType, TimeSeriesDataSource[]>[] = []
    if (sourceData.dataType == GarminConnectHookType.HealthStress) {
      dbContentList = garminConnectStressToGraphDataList(sourceData)
    } else if (sourceData.dataType == GarminConnectHookType.HealthDailies) {
      dbContentList = garminConnectDailiesToGraphDataList(sourceData)
    } else if (sourceData.dataType == GarminConnectHookType.HealthEpochs) {
      dbContentList = garminConnectEpochToGraphDataList(sourceData)
    }
    timeSeriesForChartDbContentList.push(...dbContentList)
  }

  return {
    participantId,
    yymmddIndex: sourceData.yymmddIndex,
    rawDataDbContent: {
      insertedDate: new Date(),
      sourceUpdatedDate: sourceData.updatedDate,
      dataType,
      yymmdd: sourceData.yymmddIndex,
      data: sourceData.data,
    },
    timeSeriesForChartDbContentList,
  }
}

function garminConnectStressToGraphDataList(sourceData: LocalDateGarminConnectData) {
  const stressDataRecord = last(sourceData.data) as GarminConnectRecordDataStress
  const result = []
  const startTime = (stressDataRecord.startTimeInSeconds + stressDataRecord.startTimeOffsetInSeconds) * 1000

  // VisualizerGraphDataType.GarminConnectStress
  const stressTimeSeriesRaw = []
  if (stressDataRecord.timeOffsetStressLevelValues) {
    for (const [offset, stressValue] of Object.entries(stressDataRecord.timeOffsetStressLevelValues)) {
      if (stressValue > 0) {
        stressTimeSeriesRaw.push({
          t: startTime + +offset * 1000,
          v: stressValue,
        })
      }
    }
  }
  result.push({
    insertedDate: new Date(),
    sourceUpdatedDate: sourceData.updatedDate,
    dataType: VisualizerGraphDataType.GarminConnectStress,
    yymmdd: sourceData.yymmddIndex,
    data: stressTimeSeriesRaw,
  })

  // VisualizerGraphDataType.GarminConnectBodyBattery
  const bodyBatteryTimeSeriesRaw = []
  if (stressDataRecord.timeOffsetBodyBatteryValues) {
    for (const [offset, bodyBatteryValue] of Object.entries(stressDataRecord.timeOffsetBodyBatteryValues)) {
      bodyBatteryTimeSeriesRaw.push({
        t: startTime + +offset * 1000,
        v: bodyBatteryValue,
      })
    }
  }
  result.push({
    insertedDate: new Date(),
    sourceUpdatedDate: sourceData.updatedDate,
    dataType: VisualizerGraphDataType.GarminConnectBodyBattery,
    yymmdd: sourceData.yymmddIndex,
    data: bodyBatteryTimeSeriesRaw,
  })
  return result
}

function garminConnectDailiesToGraphDataList(sourceData: LocalDateGarminConnectData) {
  const dailyDataRecord = last(sourceData.data) as GarminConnectRecordDataDaily
  const result = []
  const startTime = (dailyDataRecord.startTimeInSeconds + dailyDataRecord.startTimeOffsetInSeconds) * 1000

  const heartRateTimeSeriesRaw = []
  for (const [offset, value] of Object.entries(dailyDataRecord.timeOffsetHeartRateSamples)) {
    heartRateTimeSeriesRaw.push({
      t: startTime + +offset * 1000,
      v: value,
    })
  }
  result.push({
    insertedDate: new Date(),
    sourceUpdatedDate: sourceData.updatedDate,
    dataType: VisualizerGraphDataType.GarminConnectHeartRate,
    yymmdd: sourceData.yymmddIndex,
    data: heartRateTimeSeriesRaw,
  })

  return result
}

function garminConnectEpochToGraphDataList(sourceData: LocalDateGarminConnectData) {
  const epochDataRecord = sourceData.data as GarminConnectRecordDataEpoch[]
  const result = []

  // VisualizerGraphDataType.GarminConnectSteps
  const stepTimeSeriesRaw = epochDataRecord.reduce((acc, {startTimeInSeconds, startTimeOffsetInSeconds, steps}) => {
    const t = (startTimeInSeconds + startTimeOffsetInSeconds) * 1000
    if (!acc.has(t)) {
      acc.set(t, {t, v: steps})
    } else if (steps > 0) {
      acc.get(t).v += steps
    }
    return acc
  }, new Map())

  result.push({
    insertedDate: new Date(),
    sourceUpdatedDate: sourceData.updatedDate,
    dataType: VisualizerGraphDataType.GarminConnectSteps,
    yymmdd: sourceData.yymmddIndex,
    data: Array.from(stepTimeSeriesRaw.values()),
  })

  return result
}

export enum DailyDataType {
  GarminDirectDailySummary = 'GarminDirectDailySummary',
  GarminConnectDailySummary = 'GarminConnectDailySummary',
  GarminConnectDailySleep = 'GarminConnectDailySleep',
}

const convertLocalDateDataTypeToDailyDataType = (localDateDataType: LocalDateDataType): DailyDataType | undefined => {
  if (localDateDataType === DeviceDailyDataType.GarminDailySummary) {
    return DailyDataType.GarminDirectDailySummary
  } else if (localDateDataType === GarminConnectHookType.HealthDailies) {
    return DailyDataType.GarminConnectDailySummary
  } else if (localDateDataType === GarminConnectHookType.HealthSleeps) {
    return DailyDataType.GarminConnectDailySleep
  }
  return
}

export async function insertDailyDataToDb(
  participantId: string,
  requestYymmddIndexList: number[],
  dataList: LocalDateDataQueryKey[],
): Promise<DbOperationResult<DailyDataType>[]> {
  if (!dataList.length) return []
  const dataType = convertLocalDateDataTypeToDailyDataType(dataList[0].dataType)
  if (!dataType) return []

  const expectResult: DbOperationResult<DailyDataType>[] = requestYymmddIndexList.map((yymmddIndex) => {
    return {
      dbName: DB_NAME.DailyData,
      participantId,
      dataType,
      yymmddIndex,
      operation: 'set',
      result: false,
    }
  })

  if (dataList.length > 0) {
    const dbContentList: DailyDataDbContent[] = []
    dataList.forEach((data: LocalDateDataQueryKey) => {
      if (dataType == DailyDataType.GarminDirectDailySummary) {
        const dbContent = convertDailySummaryDataToGarminDirecetDbContent(data)
        if (dbContent != null) {
          dbContentList.push(dbContent)
        }
      }
    })

    return insertDailyDataDbContent(expectResult, dbContentList)
  } else {
    return expectResult
  }
}

async function insertDailyDataDbContent(
  dbOperationResults: DbOperationResult<DailyDataType>[],
  dbContentList: DailyDataDbContent[],
): Promise<DbOperationResult<DailyDataType>[]> {
  const updateResult = (dataType: string, yymmddIndex: number) => {
    const resultIndex = dbOperationResults.findIndex(
      (result) => result.dataType === dataType && result.yymmddIndex === yymmddIndex,
    )

    if (resultIndex !== -1) {
      dbOperationResults[resultIndex].result = true
    }
  }

  try {
    const dailySummaryContentList: [IDBValidKey, object][] = []
    dbContentList.forEach((dbContent) => {
      const dataType = dbContent.dbContent.dataType
      const key = `${dbContent.participantId}-${dbContent.yymmddIndex}-${dataType}`
      dailySummaryContentList.push([key, dbContent.dbContent])
      updateResult(dbContent.dbContent.dataType, dbContent.yymmddIndex)
    })

    await setMany(dailySummaryContentList, dailyDataDb)
  } catch (e) {
    console.log(e)
    return []
  }
  return dbOperationResults
}

function convertDailySummaryDataToGarminDirecetDbContent(sourceData: LocalDateDataQueryKey): DailyDataDbContent | null {
  const participantId = sourceData.participantId
  const dataType = convertLocalDateDataTypeToDailyDataType(sourceData.dataType)
  if (dataType && 'summary' in sourceData) {
    const dbContent: DbContent<DailyDataType, GarminDeviceDailySummary> = {
      insertedDate: new Date(),
      sourceUpdatedDate: sourceData.updatedDate,
      dataType,
      yymmdd: sourceData.yymmddIndex,
      data: (sourceData as LocalDateDeviceDailySummary).summary,
    }

    return {
      participantId,
      yymmddIndex: sourceData.yymmddIndex,
      dbContent,
    }
  }
  return null
}

export async function insertGarminConnectDataToDailyDb(
  participantId: string,
  requestYymmddRange: [number, number],
  dataType: GarminConnectHookType,
  dataList: LocalDateGarminConnectData[],
): Promise<DbOperationResult<DailyDataType>[]> {
  if (dataType === GarminConnectHookType.HealthDailies) {
    return insertGarminConnectDailySummaryDataToDb(
      participantId,
      requestYymmddRange,
      dataList as LocalDateGarminConnectDaily[],
    )
  } else if (dataType === GarminConnectHookType.HealthSleeps) {
    return insertGarminConnectDailySleepDataToDb(
      participantId,
      requestYymmddRange,
      dataList as LocalDateGarminConnectSleeps[],
    )
  }
  return []
}

export async function insertGarminConnectDailySummaryDataToDb(
  participantId: string,
  requestYymmddRange: [number, number],
  dataList: LocalDateGarminConnectDaily[],
): Promise<DbOperationResult<DailyDataType>[]> {
  function convertGarminConnectDailySummaryDataToDailyDataDbContent(
    garminConnectData: LocalDateGarminConnectData,
  ): DailyDataDbContent {
    garminConnectData
    const participantId = garminConnectData.participantId
    const summaryData = garminConnectData.data[0] as GarminConnectRecordDataDaily
    const dbData: GarminConnectDailySummaryDbData = {
      steps: summaryData.steps,
      calories: summaryData.activeKilocalories,
    }
    if (summaryData.maxHeartRateInBeatsPerMinute) {
      dbData.heartRate = {
        sampleCount: summaryData.timeOffsetHeartRateSamples
          ? Object.keys(summaryData.timeOffsetHeartRateSamples).length
          : 0,
        max: summaryData.maxHeartRateInBeatsPerMinute,
        min: summaryData.minHeartRateInBeatsPerMinute,
        avg: summaryData.averageHeartRateInBeatsPerMinute,
        resting: summaryData.restingHeartRateInBeatsPerMinute,
      }
    }
    if (summaryData.maxStressLevel && summaryData.highStressDurationInSeconds) {
      dbData.stress = {
        max: summaryData.maxStressLevel,
        avg: summaryData.averageStressLevel,
        durationHigh: summaryData.highStressDurationInSeconds,
        durationLow: summaryData.lowStressDurationInSeconds,
        durationMed: summaryData.mediumStressDurationInSeconds,
      }
    }

    const dbContent: DbContent<DailyDataType, GarminConnectDailySummaryDbData> = {
      insertedDate: new Date(),
      sourceUpdatedDate: garminConnectData.updatedDate,
      dataType: DailyDataType.GarminConnectDailySummary,
      yymmdd: garminConnectData.yymmddIndex,
      data: dbData,
    }

    return {
      participantId,
      yymmddIndex: garminConnectData.yymmddIndex,
      dbContent,
    }
  }

  return insertGarminConnectDailyDataToDb(
    participantId,
    requestYymmddRange,
    dataList,
    convertGarminConnectDailySummaryDataToDailyDataDbContent,
  )
}

export async function insertGarminConnectDailySleepDataToDb(
  participantId: string,
  requestYymmddRange: [number, number],
  dataList: LocalDateGarminConnectSleeps[],
): Promise<DbOperationResult<DailyDataType>[]> {
  function convertGarminConnectDailySleepDataToDailyDataDbContent(
    garminConnectData: LocalDateGarminConnectData,
  ): DailyDataDbContent {
    garminConnectData
    const participantId = garminConnectData.participantId
    const sleepData = garminConnectData.data[0] as GarminConnectRecordDataSleeps
    const dbData: GarminConnectDailySleepDbData = {
      duration: sleepData.durationInSeconds,
      score: sleepData.overallSleepScore?.value,
    }

    const dbContent: DbContent<DailyDataType, GarminConnectDailySleepDbData> = {
      insertedDate: new Date(),
      sourceUpdatedDate: garminConnectData.updatedDate,
      dataType: DailyDataType.GarminConnectDailySleep,
      yymmdd: garminConnectData.yymmddIndex,
      data: dbData,
    }

    return {
      participantId,
      yymmddIndex: garminConnectData.yymmddIndex,
      dbContent,
    }
  }

  return insertGarminConnectDailyDataToDb(
    participantId,
    requestYymmddRange,
    dataList,
    convertGarminConnectDailySleepDataToDailyDataDbContent,
  )
}

async function insertGarminConnectDailyDataToDb<T extends LocalDateGarminConnectData>(
  participantId: string,
  requestYymmddRange: [number, number],
  dataList: T[],
  dataConverter: (garminConnectData: LocalDateGarminConnectData) => DailyDataDbContent,
): Promise<DbOperationResult<DailyDataType>[]> {
  if (!dataList.length) return []
  const dataType = convertLocalDateDataTypeToDailyDataType(dataList[0].dataType)
  if (!dataType) return []

  const yymmddIndexStart = requestYymmddRange[0]
  const yymmddIndexEnd = requestYymmddRange[1]

  const expectResult: DbOperationResult<DailyDataType>[] = t
    .toYYMMDDRange(yymmddIndexStart, yymmddIndexEnd)
    .map((yymmddIndex) => {
      return {
        dbName: DB_NAME.DailyData,
        participantId,
        dataType,
        yymmddIndex,
        operation: 'set',
        result: false,
      }
    })

  if (dataList.length > 0) {
    const dbContentList: DailyDataDbContent[] = dataList.map(dataConverter)
    return insertDailyDataDbContent(expectResult, dbContentList)
  } else {
    return expectResult
  }
}
