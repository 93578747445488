import React from 'react'
import {handleEsc, RIF, useCurrentWorkspaceState} from '../../lib'
import {selectTheme, selectWorkspaces, selectProfile} from '../../store'

import {Legend, ButtonCancel, TextLink, Button} from '..'

export const RemoveCollaboratorPopup = (props) => {
  const {color, fontWeight, pad} = selectTheme()

  const { workspace: currentWorkspace } = useCurrentWorkspaceState()
  const profile = selectProfile()
  const leaving = profile.email === props.collaborator?.profile.email

  const handleCloseAction = () => {
    if (props.setCollaborator) props.setCollaborator({})
    if (props.setShowLeaveWorkspacePopup) props.setShowLeaveWorkspacePopup(false)
  }

  const handleRemoveAction = () => {
    if (props.removeAction) props.removeAction()
  }

  handleEsc(handleCloseAction)

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        zIndex: 99999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          width: '560px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <ButtonCancel onClick={handleCloseAction}/>
        {RIF(
          leaving,
          <>
            <Legend css={{
              marginBottom: pad.slightlyLarger,
              marginTop: pad.xl,
            }}>
              Do you want to leave {currentWorkspace?.name} ?
            </Legend>
            <p css={{
              fontWeight: fontWeight.medium,
              marginBottom: '80px',
            }}>
              You will lose access to this workspace and its projects after leaving.
            </p>
          </>
        )}
        {RIF(
          !leaving,
          <>
            <Legend css={{
              marginBottom: pad.slightlyLarger,
              marginTop: pad.xl,
            }}>
              Remove "{props.collaborator?.profile?.firstName}" ?
            </Legend>
            <p css={{
              fontWeight: fontWeight.medium,
              marginBottom: '80px',
            }}>
              This will remove all workspace access and permissions for this collaborator.
            </p>
          </>
        )}

        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextLink children='Cancel' onClick={handleCloseAction} />
          <Button
            onClick={handleRemoveAction}
            btnPadding='large'
            btnColor='warning'
            children={leaving ? 'Leave' : 'Remove'}
          />
        </div>
      </div>
    </div>
  )
}
