import {fromCSV} from 'data-forge'
import {t} from '../t'
import {Content, TABULAR_DATA_CONTENT_SEP} from './model'

export class ContentParser {
  async parse(contentStream: ReadableStream): Promise<Content> {
    const contents = await t
      .streamToString(contentStream)
      .then((contentString) => contentString.split(TABULAR_DATA_CONTENT_SEP))
    const body = t.unwrap(contents.pop())
    return {
      headers: contents,
      body: fromCSV(body),
    }
  }
}
