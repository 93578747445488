import React from 'react'
import {selectTheme} from '../../store'

/** a styled ordered list */
export const Ol = (props) => {
  const {pad} = selectTheme()
  return (
    <ol
      {...props}
      css={{
        marginLeft: pad.medium,
        listStyle: 'decimal',
      }}
    />
  )
}
