import {DualAxes, DualAxesConfig} from '@ant-design/charts'
import {Chart} from '@antv/g2/lib'
import {Meta} from '@antv/g2plot'
import {dailyGarminStressTheme} from '../theme/daily_garmin_stress_theme'
import {ChartCard} from '../template/chart_card'
import {ChartDualDataProps, DailyChartDataWithType} from '../../../model/chart'
import {defaultDailyChartXAxisConfig} from '../../../lib/chart_data/util/formatter'
import {StressType, StressValueType} from '../../../lib/chart_data/model'
import {useEffect, useState} from 'react'

export const GarminDailySummaryStresscCombinationChart = (
  props: ChartDualDataProps<DailyChartDataWithType<StressType>, DailyChartDataWithType<StressValueType>>,
) => {
  const {height, width, data: durationData, data2: valueData} = props
  const [chartInstance, setChartInstance] = useState<Chart | undefined>()

  // not working on DualAxes, don't know why
  // useEffect(() => {
  //   if (chartInstance) {
  //     chartInstance.changeData([durationData, valueData])
  //   }
  // }, [chartInstance, durationData, valueData])

  const xField: keyof DailyChartDataWithType = 'yymmdd'
  const yField: (keyof DailyChartDataWithType)[] = ['value', 'value']

  const yAxisConfigForValue1 = {
    title: {text: 'Level(%)'},
    label: {
      autoRotate: true,
      formatter: (text: string) => {
        return `${+text * 100} %`
      },
    },
  }

  const yAxisConfigForValue2 = {
    title: {text: 'value'},
    min: 0,
    max: 100
  }

  const config: DualAxesConfig = {
    data: [durationData, valueData],
    autoFit: true,
    theme: dailyGarminStressTheme,
    legend: {position: 'bottom-right'},
    xField,
    yField,
    xAxis: defaultDailyChartXAxisConfig,
    yAxis: [yAxisConfigForValue1, yAxisConfigForValue2],
    geometryOptions: [
      {
        geometry: 'column',
        seriesField: 'type',
        isPercent: true,
        isStack: true,
      },
      {
        geometry: 'line',
        seriesField: 'type',
        point: {},
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
    onReady: (chart) => {
      setChartInstance(chart.chart)
    },
  }

  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: 'Garmin Connect Stress',
    hintDescription: '',
    chart: <DualAxes {...config} />,
  }

  return <ChartCard {...chartCardProps} />
}
