import {put, takeEvery, debug, createAction} from '../../lib'
import {doLOADING_SET, doLOADING_DELETE} from '..'
import {Action} from 'redux'

export const HANDLE_LOADING_STATE = 'HANDLE_LOADING_STATE'
export const doHANDLE_LOADING_STATE = createAction(HANDLE_LOADING_STATE)

export const handleLoadingStateActionCreators = {
  doHANDLE_LOADING_STATE,
}

interface PayloadAction<T = any> extends Action {
  payload?: T
}

export function* handleLoadingState(action: PayloadAction) {
  debug('saga*handleLoadingState')

  const disableLoadingStateList = [
    'REQUEST_COMPLETE',
    'REQUEST_ERROR',
    'REQUEST_PROJECT_FETCH',
    'REQUEST_BATCH_FETCH',
    'REQUEST_METHOD_UPDATE',
    'REQUEST_TASK_GARMIN_DEVICE_CREATE',
    'REQUEST_PROJECT_ARCHIVE_BUILD_METADATA_FETCH',
    'REQUEST_PROJECT_ARCHIVE_DOWNLOAD_METADATA_FETCH',
    'REQUEST_PROJECT_DATA_DIGEST',
    'REQUEST_PROJECT_DATA_UPDATE_REQUEST',
    'REQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH',
    'REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH',
    'REQUEST_WORKSPACE_COLLABORATOR_LIST_FETCH',
  ]
  if (!disableLoadingStateList.includes(action.type)) {
    yield put(doLOADING_SET({requestId: action.payload?.requestId}))
  }

  if (action.type === 'REQUEST_COMPLETE' || action.type === 'REQUEST_ERROR') {
    yield put(doLOADING_DELETE({requestId: action.payload?.requestId}))
  }
}

const requestOrSubmitAction = (action: PayloadAction): boolean => /^REQUEST|SUBMIT$/.test(action.type)

export function* handleLoadingStateSaga() {
  yield takeEvery(requestOrSubmitAction, handleLoadingState)
}
