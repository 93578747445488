import React from 'react'
import {selectTheme} from '../../store'

export const InputWarning = (props) => {
  const {pad, color} = selectTheme()
  return (
    <div
      {...props}
      css={{
        display: 'inline',
        width: 'fit-content',
        padding: '4px 6px',
        backgroundColor: 'rgba(235, 114, 87, 0.05)',
        borderRadius: '5px',
        color: color.attention,
        marginLeft: pad.xs,
      }}
    >
      {props.message}
    </div>
  )
}
