import Lottie from 'lottie-web'
import loadingAnimation from '../../asset/lotties/loading_animation_attention.json'
import {
  useEffect, 
  useState, 
  Dispatch, 
  SetStateAction, 
  createRef
} from 'react'
import {
  selectTheme, 
  createDispatchActions, 
  selectArchiveStatus,
} from '../../store'
import { useParams } from 'react-router-dom'
import {RIF, timeConvert, TimeConvertType, useCurrentProjectState} from '../../lib'
import {
  DownloadWhiteIcon,
  WarningIcon,
  RefreshIcon,
  ZipIcon,
  ChevronRightBlueIcon,
  ChevronDownBlueIcon,
} from '../../asset/image'
import {ArchiveStatus} from '..'

export interface DataDownloadPopupProps {
  // projectId?: string
  dataDownloadPopupRef: any
  dataDownloadUrl: string
  // archiveStatus?: ArchiveStatus
  // setArchiveStatus: Dispatch<SetStateAction<ArchiveStatus | undefined>>
  // downloadAvailable: boolean
  setJobStatusQueue: Dispatch<SetStateAction<('running' | 'succeeded')[]>>
}

export const DataDownloadPopup = (props: DataDownloadPopupProps) => {
  const {color} = selectTheme()
  const {
    // projectId,
    dataDownloadPopupRef,
    dataDownloadUrl,
    // archiveStatus,
    // setArchiveStatus,
    // downloadAvailable,
    setJobStatusQueue,
  } = props

  const { projectId } = useCurrentProjectState()
  const archiveStatus: ArchiveStatus = selectArchiveStatus()[projectId as string] || {}
  const {jobStatus, latestBuildTimestamp, latestDataTimestamp, downloadAvailable} = archiveStatus

  const latestDataTimestampWording: string = latestDataTimestamp
    ? (timeConvert({time: latestDataTimestamp, type: TimeConvertType.lastDataUpload}) as string)
    : ''
  const timeSinceLatestDataTime: string = latestDataTimestamp
    ? (timeConvert({time: latestDataTimestamp, type: TimeConvertType.distanceToNow}) as string)
    : ''
  const timeSinceLatestBuildTime: string = latestBuildTimestamp
    ? (timeConvert({time: latestBuildTimestamp, type: TimeConvertType.distanceToNow}) as string)
    : ''

  const {doREQUEST_PROJECT_ARCHIVE_UPDATE}: any = createDispatchActions()
  const [isOver24Hours, setIsOver24Hours] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(jobStatus !== 'running')
  const animRef: any = createRef()

  useEffect(() => {
    if (!latestBuildTimestamp) return
    if ((new Date().getTime() - latestBuildTimestamp) / 1000 > 86400) return setIsOver24Hours(true)
  }, [latestBuildTimestamp])

  const handleClickDownloadBtn = () => window.open(dataDownloadUrl)

  const handleClickReqUpdateBtn = () => {
    setJobStatusQueue([])
    doREQUEST_PROJECT_ARCHIVE_UPDATE({
      payload: {
        projectId,
      },
    })
  }

  // useEffect(() => {
  //   if (!requestArchiveUpdateComplete?.success) return
  //   const archiveStatusFromBE = requestArchiveUpdateComplete.payload.data.payload
  //   setArchiveStatus(archiveStatusFromBE)
  // }, [requestArchiveUpdateComplete])

  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    })
    return () => Lottie.stop()
  }, [jobStatus])

  return (
    <div
      ref={dataDownloadPopupRef}
      css={{
        position: 'absolute',
        top: '100px',
        right: '30px',
        width: '440px',
        padding: `24px`,
        background: color.white,
        borderRadius: '5px',
        border: `1px solid ${color.grey_160}`,
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        zIndex: 50,
      }}
    >
      <div css={{fontSize: '16px', fontWeight: '700', marginBottom: '8px'}}>Download data</div>
      {RIF(
        !downloadAvailable,
        <>
          <p css={{color: color.grey_600}}>
            Please note, the creation of your first data zip file may take some time. Click on 'Prepare My Data Zip' to
            get started.
          </p>
          {RIF(
            jobStatus !== 'running',
            <div
              onClick={handleClickReqUpdateBtn}
              css={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginTop: '16px',
                padding: '8px 16px 8px 10px',
                background: color.primary,
                borderRadius: '5px',
                width: 'fit-content',
                color: color.white,
              }}
            >
              <img src={ZipIcon} width='18' css={{marginRight: '4px'}} />
              Prepare My Data Zip
            </div>,
          )}
          {RIF(
            jobStatus === 'running',
            <div css={{display: 'flex', alignItems: 'center', marginTop: '24px', position: 'relative'}}>
              <div
                ref={animRef}
                css={{
                  width: '55px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '-18px',
                  left: '-18px',
                }}
              />
              <p css={{color: color.attention, marginLeft: '28px'}}>Preparing your zip...</p>
            </div>,
          )}
          {RIF(jobStatus === 'failed', <FailedSection {...{handleClickReqUpdateBtn}} />)}
        </>,
      )}

      {RIF(
        downloadAvailable,
        <>
          <div css={{color: color.grey_600}}>
            The zip includes data up until {latestDataTimestampWording}{' '}
            <p css={{color: isOver24Hours ? color.attention : color.taskGreen, display: 'inline'}}>
              ({timeSinceLatestDataTime} ago)
            </p>
          </div>
          <div
            onClick={handleClickDownloadBtn}
            css={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginTop: '16px',
              padding: '8px 16px 8px 10px',
              background: color.primary,
              borderRadius: '5px',
              width: 'fit-content',
              color: color.white,
            }}
          >
            <img src={DownloadWhiteIcon} width='18' css={{marginRight: '4px'}} />
            Download
          </div>

          {RIF(
            isOver24Hours,
            <RequestSection
              {...{
                isOver24Hours,
                timeSinceLatestBuildTime,
                handleClickReqUpdateBtn,
                jobStatus,
                latestBuildTimestamp,
              }}
            />,
          )}

          {RIF(
            !isOver24Hours,
            <>
              <div
                onClick={() => setIsCollapsed((prev) => !prev)}
                css={{display: 'flex', alignItems: 'center', marginTop: '24px', cursor: 'pointer'}}
              >
                <p css={{color: color.primary, marginRight: '8px'}}>Get the latest data</p>
                <img src={isCollapsed ? ChevronRightBlueIcon : ChevronDownBlueIcon} width={14} height={14} />
              </div>
              {RIF(
                !isCollapsed,
                <RequestSection
                  {...{
                    isOver24Hours,
                    timeSinceLatestBuildTime,
                    handleClickReqUpdateBtn,
                    jobStatus,
                    latestBuildTimestamp,
                  }}
                />,
              )}
            </>,
          )}
        </>,
      )}
    </div>
  )
}

export interface RequestSectionPropsT {
  isOver24Hours: boolean
  timeSinceLatestBuildTime: string
  handleClickReqUpdateBtn: any
  jobStatus?: 'never' | 'succeeded' | 'running' | 'failed'
  latestBuildTimestamp?: Date | number | null
}

export const RequestSection = (props: RequestSectionPropsT) => {
  const {color} = selectTheme()
  const {isOver24Hours, timeSinceLatestBuildTime, jobStatus, handleClickReqUpdateBtn, latestBuildTimestamp} = props
  const animRef: any = createRef()

  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    })
    return () => Lottie.stop()
  }, [jobStatus])

  return (
    <>
      {RIF(jobStatus === 'failed', <FailedSection {...{handleClickReqUpdateBtn}} />)}
      {RIF(
        jobStatus !== 'failed',
        <div
          css={{
            borderRadius: '5px',
            border: isOver24Hours ? `1px solid #F1906E3D` : '0px solid',
            width: '100%',
            padding: '16px',
            marginTop: '24px',
            backgroundColor: isOver24Hours ? '#F1906E14' : color.background,
          }}
        >
          {RIF(
            isOver24Hours && jobStatus !== 'running',
            <div css={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
              <img src={WarningIcon} width='20' css={{marginRight: '8px'}} />
              <p css={{color: color.attention}}>Your zip is more than 24 hours out of date</p>
            </div>,
          )}
          {RIF(
            jobStatus === 'running',
            <>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  position: 'relative',
                }}
              >
                <div
                  ref={animRef}
                  css={{
                    width: '55px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: '-18px',
                    left: '-18px',
                  }}
                />
                <p css={{color: color.attention, marginLeft: '28px'}}>Preparing your zip...</p>
              </div>
              <p css={{color: color.grey_600}}>
                This may take anywhere from 10 minutes to several hours, depending on how much data you have. Please check back periodically.
              </p>
            </>,
          )}

          {RIF(
            !!latestBuildTimestamp && jobStatus !== 'running',
            <>
              <div css={{color: color.grey_600}}>
                This zip was last updated <p css={{display: 'inline'}}>{timeSinceLatestBuildTime} ago</p>. To get the
                latest data, please use the “Request Data Update” and check back in 10 minutes.
              </div>
              <div
                onClick={handleClickReqUpdateBtn}
                css={{cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '16px'}}
              >
                <img src={RefreshIcon} width='16' css={{marginRight: '4px'}} />
                <p css={{color: color.primary}}>Request Data Update</p>
              </div>
            </>,
          )}
        </div>,
      )}
    </>
  )
}

export const FailedSection = (props: {handleClickReqUpdateBtn: any}) => {
  const {color} = selectTheme()
  const {handleClickReqUpdateBtn} = props

  return (
    <>
      <div
        css={{
          borderRadius: '5px',
          border: `1px solid #F1906E3D`,
          width: '100%',
          padding: '16px',
          marginTop: '24px',
          backgroundColor: '#F1906E14',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src={WarningIcon} width='20' css={{marginRight: '8px'}} />
        <p css={{color: color.attention}}>
          It looks like an error has occurred. You can reload this website and try again, or contact us if the problem
          persists.
        </p>
      </div>
      <div css={{display: 'flex', alignItems: 'center', marginTop: '24px'}}>
        <p onClick={handleClickReqUpdateBtn} css={{color: color.primary, cursor: 'pointer', marginRight: '24px'}}>
          Try Again
        </p>
        <a href={'https://help.labfront.com/kb-tickets/new'} target='_blank' css={{color: color.primary}}>
          Contact Us
        </a>
      </div>
    </>
  )
}
