import {TimeSeriesDataSource} from '../../model'
import {DailyDataType, DbContent, TransformedStreamDataForPlot} from './db_data_setter'
import {dailyDataDb, rawDataDb, streamDataDb, timeSeriesForChartDb} from '.'
import {get} from 'idb-keyval'
import { VisualizerGraphDataType } from '../../shared/db'

export async function getTimeSeriesDataFromDb(
  participantId: string,
  dataType: VisualizerGraphDataType,
  yymmddIndex: number,
): Promise<DbContent<VisualizerGraphDataType, TimeSeriesDataSource[]> | undefined> {
  const key: IDBValidKey = `${participantId}-${yymmddIndex}-${dataType}`
  return await get(key, timeSeriesForChartDb)
}

export async function getDailyDataFromDb<T>(
  participantId: string,
  dataType: DailyDataType,
  yymmddIndex: number,
): Promise<DbContent<DailyDataType, T> | undefined> {
  const key: IDBValidKey = `${participantId}-${yymmddIndex}-${dataType}`
  return await get(key, dailyDataDb)
}

export async function getRawDataFromDb(
  participantId: string,
  dataType: string,
  yymmddIndex: number,
): Promise<DbContent<string, TimeSeriesDataSource[]> | undefined> {
  const key: IDBValidKey = `${participantId}-${yymmddIndex}-${dataType}`
  return await get(key, rawDataDb)
}

export async function getStreamDataFromDb(
objectId: string
): Promise<TransformedStreamDataForPlot| undefined> {
  const key: IDBValidKey = objectId
  return await get(key, streamDataDb)
}