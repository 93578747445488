import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const PASSWORD_RESET_SUBMIT = 'PASSWORD_RESET_SUBMIT'
export const doPASSWORD_RESET_SUBMIT = createDoRequestAction(PASSWORD_RESET_SUBMIT)

export const passwordResetSubmitActionCreators = {
  doPASSWORD_RESET_SUBMIT,
}

export function* passwordResetSubmit({payload, setRequestResult}: any): SagaIterator {
  debug('saga*passwordResetSubmit')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        verificationCode: v.number().exist(),
        email: v.string().exist(),
        password: v.string().min(8).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: PASSWORD_RESET_SUBMIT,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {verificationCode, email, password} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-password-reset-submit`,
    data: {
      verificationCode,
      email,
      password,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR({
        fromType: PASSWORD_RESET_SUBMIT,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-password-reset-submit',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: PASSWORD_RESET_SUBMIT,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* passwordResetSubmitSaga() {
  yield takeEvery(PASSWORD_RESET_SUBMIT, passwordResetSubmit)
}
