import Hotjar from '@hotjar/browser'

export default class Tracking {
  static readonly shared = new Tracking()

  private readonly siteId = 3046145
  private readonly hotjarVersion = 6

  initialize() {
    Hotjar.init(this.siteId, this.hotjarVersion)
  }

  perform(action: (api: typeof Hotjar) => void) {
    if (Hotjar.isReady()) {
      action(Hotjar)
    }
  }
}
