import {createAction, useSelector, assertPartialSchema, v} from '../../../lib'

export const REQUEST_ERROR = 'REQUEST_ERROR'
export const doREQUEST_ERROR = createAction(REQUEST_ERROR)

export const REQUEST_COMPLETE = 'REQUEST_COMPLETE'
export const doREQUEST_COMPLETE = createAction(REQUEST_COMPLETE)

export const RESET_ERROR = 'RESET_ERROR'
export const doRESET_ERROR = createAction(RESET_ERROR)

export const selectRequestComplete = (requestId) =>
  useSelector((state) => {
    return state.requestState[requestId] || null
  })

export const selectRequestState = () =>
  useSelector((state) => {
    return state.requestState
  })

export const requestStateActionCreators = {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doRESET_ERROR,
}

const requestStateDefaultState = {}
export const requestStateReducer = (state = requestStateDefaultState, {type, payload, setRequestResult}) => {
  // console.log(type)
  // console.log(payload)
  if (!payload) return state
  const requestId = payload?.requestId

  if (type == REQUEST_COMPLETE || type == REQUEST_ERROR) {
    assertPartialSchema({
      payload,
      schema: v.object({
        requestId: v.string().required(),
        fromType: v.string().required(),
      }),
    })

    assertPartialSchema({
      setRequestResult,
      schema: v.function().optional(),
    })
  }

  switch (type) {
    case REQUEST_ERROR: {
      const refreshTokenError = payload?.payload?.serverError === 'jwt expired'

      setRequestResult?.({
        requestType: payload.fromType,
        success: false,
        result: payload,
      })

      return {
        ...state,
        [requestId]: {
          success: false,
          payload,
          refreshTokenError,
        },
        hasError: !refreshTokenError && requestId,
      }
    }
    case REQUEST_COMPLETE:
      setRequestResult?.({
        requestType: payload.fromType,
        success: true,
        result: payload,
      })

      return {
        ...state,
        [requestId]: {
          success: true,
          type: payload.fromType,
          // payload,
        },
      }
    case RESET_ERROR:
      return {...state, hasError: null}
    default:
      return state
  }
}
