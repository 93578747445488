import {Dispatch, SetStateAction} from 'react'
import {PopupConfirmation} from '..'

export interface DeviceRemoveConfirmPopupPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
  doAction: any
  name: string
  description: string
  term1: string
  buttonText: string
}

export const DeviceRemoveConfirmPopup = (props: DeviceRemoveConfirmPopupPropsT) => {
  const {closeAction, doAction, ...rest} = props

  return (
    <div
      data-testid='device_remove_confirm_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '103',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...{
          closeAction,
          doAction,
          ...rest,
        }}
      />
      ,
    </div>
  )
}
