import {useState, ChangeEvent, useEffect} from 'react'
import {selectTheme} from '../../store'
import {RIF, useSearch} from '../../lib'
import {ProfileBlackIcon, SearchIcon, VisualizerFilterIcon} from '../../asset/image'
import {Input, ParticipantTagsContainer, PopupParticipantTagFilter} from '..'
import { ITag } from '../../shared/db'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { ParticipantItem } from "../../model"

export interface ParticipantListProps {
  participantList: ParticipantItem[]
  allParticipantTagList: ITag[]
  onParticipantClicked: (participantItem: ParticipantItem) => void
  currentParticipantId?: string
}

export const ParticipantList = (props: ParticipantListProps) => {
  const {allParticipantTagList, onParticipantClicked, currentParticipantId} = props
  const {color, pad, fontWeight, fontSize} = selectTheme()
  const [currentSelectedId, setCurrentSelectedId] = useState(currentParticipantId || '')
  const [isParticipantFilterPopup, setIsParticipantFilterPopup] = useState(false)
  const [participantList, setParticipantList] = useState(props.participantList)
  const [tagFilterState, setTagFilterState] = useState<Record<string, boolean>>({})
  const {searchValue, searchResult, setSearchValue} = useSearch('insignia', props.participantList)

  const handleParticipantClicked = (participantItem: ParticipantItem) => {
    setCurrentSelectedId(participantItem.id)
    onParticipantClicked(participantItem)
  }

  const handleParticipantFilterClicked = () => {
    setIsParticipantFilterPopup(!isParticipantFilterPopup)
  }

  useEffect(() => {
    const shouldInclude = (participant: ParticipantItem) => {
      const hasSearch = searchResult?.length
      const searched = hasSearch ? searchResult.some(({id}) => id === participant.id) : true
      const hasFilter = Object.values(tagFilterState).some((value) => value)
      const filtered = hasFilter ? participant.tagList.some(({id}) => tagFilterState[id]) : true
      return searched && filtered
    }
    setParticipantList(props.participantList.filter(shouldInclude))
  }, [tagFilterState, searchResult])

  useEffect(() => {
    if (searchResult?.length && currentSelectedId === '') {
      const selectedParticipant = searchResult[0]
      setCurrentSelectedId(selectedParticipant.id)
      onParticipantClicked(selectedParticipant)
    }
  }, [searchResult])

  const ParticipantElement = (item: ParticipantItem) => {
    const isSelected = item.id === currentSelectedId
    return (
      <div
        key={item.id}
        onClick={() => handleParticipantClicked(item)}
        css={{
          minHeight: '40px',
          width: '100%',
          borderRadius: '5px',
          paddingLeft: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: isSelected ? color.backgroundBlue : color.white,
          border: isSelected ? `1px solid ${color.eletricBlue}` : '1px solid transparent',
          ':hover': {
            background: '#EFF1FE',
            cursor: 'pointer',
          },
        }}
      >
        <span
          css={{
            textAlign: 'center',
            color: isSelected ? '#3455F4' : color.grey_600,
            whiteSpace: 'nowrap'
          }}
        >
          {item.insignia}
        </span>
        <div css={{padding: '12px 8px'}}>
          <ParticipantTagsContainer
            tags={item.tagList}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      data-testid='particiapant_list'
      css={{
        background: color.white,
        width: '216px',
        height: 'calc(100vh - 56px)',
        float: 'left',
        border: `1px solid ${color.grey_100}`,
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Title */}
      <div
        css={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '16px',
          fontWeight: fontWeight.base,
          fontSize: '12px',
        }}
      >
        <div
          css={{
            display: 'flex',
          }}
        >
          <img src={ProfileBlackIcon} width={16} style={{marginRight: '4px'}} />
          <p
            css={{
              fontSize: fontSize.h7,
              fontWeight: fontWeight.thick,
            }}
          >
            Participant List
          </p>
        </div>
        <img onClick={handleParticipantFilterClicked} src={VisualizerFilterIcon} width={24} height={24}></img>
        {RIF(isParticipantFilterPopup, <PopupParticipantTagFilter 
          tagList={allParticipantTagList} 
          checked={(tag) => tagFilterState[tag.id]}
          onChange={(tag, value) => setTagFilterState({
            ...tagFilterState,
            [tag.id]: value
          })}
          onClose={setIsParticipantFilterPopup}
        />)}
      </div>

      {/* search participant bar */}
      <div
        css={{
          width: '100%',
          height: '32px',
          border: `1px solid ${color.grey_160}`,
          borderRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          background: color.white,
          justifyContent: 'space-between',
          ':hover': {
            border: `1px solid ${color.grey_400}`,
            cursor: 'pointer',
          },
          marginBottom: '16px',
        }}
      >
        <Input
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          placeholder='Search'
          style={{
            border: 'none',
            background: 'inherit',
            height: '100%',
          }}
        />
        <img src={SearchIcon} width='16' css={{margin: '0 15px 0 5px'}} />
      </div>
      <Scrollbars
        style={{
          flex: 1,
        }}
        autoHide
        autoHideTimeout={200}
      >
        {/* participant list */}
        {RIF(participantList.length,
          <div
            css={{
              marginTop: pad.medium,
            }}
          >
            {participantList.map(ParticipantElement)}
          </div>,
        )}
      </Scrollbars>
    </div>
  )
}
