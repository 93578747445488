import { IEntityModel } from './base'
import { IParticipant } from './participant'

export enum EventGarminConnectAccountActionType {
  Create = 'create',
  Delete = 'delete',
  Update = 'update',
}

export interface IEventGarminConnectAccount extends IEntityModel {
  participantId: string
  participant?: IParticipant
  garminConnectUserId: string
  eventLocation: string
  actionType: EventGarminConnectAccountActionType
}
