import {selectTheme} from '../../store'
import {TickRequiredIcon} from '../../asset/image'
import {Legend} from '..'

export const PopupDeleting = (props) => {
  const {color, fontWeight} = selectTheme()

  return (
    <div
      {...props}
      css={{
        top: 'calc(50vh - 200px)',
        left: 'calc(50vw - 300px)',
        position: 'fixed',
        width: '600px',
        borderRadius: '5px',
        padding: '30px',
        background: color.white,
      }}
    >
      <div css={{display: 'flex', flexDirection: 'column', minHeight: '240px'}}>
        {/* TODO: stopping process animation */}
        <div>
          <div css={{display: 'flex', alignItems: 'center'}}>
            <div
              css={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                borderTop: `4px solid ${color.warning}`,
                borderRight: `4px solid ${color.warning}`,
                borderBottom: `4px solid ${color.grey_160}`,
                borderLeft: `4px solid ${color.grey_160}`,
                transform: 'rotate(45deg)',
              }}
            ></div>
            <div css={{color: color.warning, marginLeft: '15px'}}>Stop</div>
          </div>

          <div css={{margin: '30px 20px 0 20px'}}>
            <Legend>Deleting Your Account</Legend>
            <div css={{margin: '20px 0 20px 0'}}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies amet augue sit amet, consectetur
              adipiscing elit. Ultricies amet augue.
            </div>
          </div>
        </div>
        <div css={{margin: '0 20px'}}>
          <div css={{marginBottom: '15px'}}>
            <img src={TickRequiredIcon} width='18' height='14'></img>
            <span css={{margin: '8px'}}>Deleting all of your projects</span>
          </div>

          <div css={{marginBottom: '15px'}}>
            <img src={TickRequiredIcon} width='18' height='14'></img>
            <span css={{marginLeft: '8px'}}>Deleting all participants</span>
          </div>

          <div css={{marginBottom: '15px'}}>
            <img src={TickRequiredIcon} width='18' height='14'></img>
            <span css={{marginLeft: '8px'}}>Deleting all collected data</span>
          </div>
        </div>
      </div>
    </div>
  )
}
