import {Controller} from 'react-hook-form'
import {useEffect, useState} from 'react'

import {selectTheme} from '../../store'

import {v, useForm, RIF} from '../../lib'

import {LabelBox, Input, Button, Select} from '..'

const schema = v.object({
  country: v.any(),
  phoneNumber: v.number().required(),
})

// TODO: only finished UI, cant get select data

export const ProfileEnableMFAPage = (props: any) => {
  const {color, fontWeight} = selectTheme()

  const [hasError, setHasError] = useState(false)
  const [completed, setCompleted] = useState(false)

  const countryOptions = [
    {value: 'taiwan', label: 'Taiwan'},
    {value: 'canada', label: 'Canada'},
    {value: 'us', label: 'US'},
  ]

  const {register, handleSubmit, formState, watch, control} = useForm({schema})

  const onSubmit = (data: any) => {
    return
  }

  const onError = (error: any) => {
    setHasError(true)
  }

  // check every input is filled
  useEffect(() => {
    let count = ''
    Object.values(watch()).map((value) => {
      count += value
      if (count.length === 2) setCompleted(true)
    })
  }, [watch()])

  return (
    // wrapper
    <div
      css={{
        height: '300px',
        margin: '150px 0 0 30Vw',
        padding: '50px',
      }}
    >
      <div css={{fontSize: '20px'}}>Enable Multi-Factor Authentication</div>

      <div css={{margin: '15px 0 20px 0'}}>
        Add an extra layer of security by requiring a password and phone number to sign in.
        <br />
        Please enter your phone number. A verification code will be sent to you via text.
      </div>

      <div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div css={{display: 'flex', alignItems: 'center', marginTop: '30px', height: '50px'}}>
            <LabelBox
              {...{
                label: 'Country',
              }}
            >
              <Controller
                control={control}
                defaultValue={false}
                name='country'
                render={({field: {name, value, onChange}}) => (
                  <Select options={countryOptions} {...{name, value, onChange}} css={{width: '180px'}} />
                )}
              />
            </LabelBox>

            <div css={{marginLeft: '30px'}}>
              <LabelBox
                {...{
                  label: 'Phone Number',
                }}
              >
                <Input
                  {...register('phoneNumber')}
                  css={{width: '380px', height: '40px'}}
                  placeholder='(XXX) XXX-XXXX'
                />
              </LabelBox>
            </div>
          </div>

          <div css={{marginTop: '40px', display: 'flex', alignItems: 'center'}}>
            {RIF(!completed, <Button disabled>Next</Button>)}
            {RIF(completed, <Button type='submit'>Next</Button>)}
            <div css={{fontWeight: fontWeight.bold, color: color.primary, marginLeft: '20px', cursor: 'pointer'}}>
              Do this later
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
