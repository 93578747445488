import ReactDOM from 'react-dom/client'
import {HelmetProvider} from 'react-helmet-async'
import {MathJaxContext} from 'better-react-mathjax'
import {App} from './components/app'
import Instrumentation from './instrumentation'
import Tracking from './tracking'

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'test') {
  Instrumentation.shared.initialize()
  Tracking.shared.initialize()
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/static/sw.js')
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <HelmetProvider>
    <MathJaxContext>
      <App />
    </MathJaxContext>
  </HelmetProvider>,
)
