import {useEffect} from 'react'

// used in popup, if press ESC btn will close the popup
export const handleEsc = (closeAction: any) => {
  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) closeAction()
    }
    window.addEventListener('keydown', handleEsc)
    return () => window.removeEventListener('keydown', handleEsc)
  }, [])
}
