import React from 'react'
import ReactSelect, {NonceProvider} from 'react-select'
import {lighten} from '../../lib/color_util'
import {selectTheme} from '../../store'

export const SelectStatus = (props) => {
  const {color, pad} = selectTheme()
  return (
    <ReactSelect
      {...props}
      placeholder='status'
      styles={{
        placeholder: (provided, state) => ({
          ...provided,
          position: 'static',
          transform: 'none',
          color: state.isFocused ? color.primary : color.black,
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          width: '0px',
        }),
        singleValue: (provided, state) => ({
          ...provided,
          position: 'static',
          transform: 'none',
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          paddingLeft: '0',
        }),
        control: (provided, state) => ({
          ...provided,
          height: '30px',
          outline: `none`,
          border: `1px solid ${state.isFocused ? color.primary : color.grey_160}`,
          borderRadius: pad.small,
          ':hover': {
            border: `1px solid ${state.isFocused ? color.primary : color.black}`,
          },
        }),
        menu: (provided, state) => ({
          ...provided,
          width: '155px',
          marginTop: '0',
          padding: '10px 0',
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? color.primary : color.black,
          background: state.isSelected
            ? state.isFocused
              ? 'none'
              : 'none'
            : state.isFocused
            ? color.background
            : 'none',
        }),
      }}
    />
  )
}
