import React from 'react'
import {selectProfile, selectTheme} from '../../store'

import {ProfileImageDefault} from '../../asset/image'

export const ProfileImage = () => {
  const {profileState} = selectProfile()
  const {pad} = selectTheme()

  const getProfileImage = () => {
    if (profileState && profileState.imageUrl) {
      return profileState.imageUrl
    }

    return ProfileImageDefault
  }

  return (
    <img
      src={getProfileImage()}
      css={{
        width: '74px',
        height: '74px',
        borderRadius: '50%',
        marginRight: pad.large,
      }}
    />
  )
}
