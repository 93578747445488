import {AxisCfg} from '@antv/g2/lib/interface'
import { DateObject } from 'react-multi-date-picker'

export const defaultDailyChartXAxisConfig: AxisCfg = {
  title: {text: 'Date'},
  tickLine: null,
  label: {
    autoRotate: false,
    formatter: (text: string) => {
      return yymmddStringToChartLabel(text)
    },
  },
}

export const yymmddStringToChartLabel = (yymmdd: string): string => {
  const year = yymmdd.slice(0, 2)
  const month = yymmdd.slice(2, 4)
  const day = yymmdd.slice(4, 6)
  return `${year}/${month}/${day}`
}

export const yymmddToChartLabel = (yymmdd: number): string => {
  const year = Math.floor(yymmdd / 10000)
  const month = Math.floor((yymmdd % 10000) / 100)
  const day = yymmdd % 100
  return `${2000 + year}/${month}/${day}`
}

export const durationToYYMMDDInterval = (duration: [DateObject, DateObject]): [number, number] => 
  duration.map((date) => parseInt(date.format('YYMMDD'))) as [number, number]
