import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'

export enum DeveloperTokenActionType {
  // DEVELOPER_TOKEN_SET = 'DEVELOPER_TOKEN_SET',
  DEVELOPER_TOKEN_DELETE = 'DEVELOPER_TOKEN_DELETE',
  DEVELOPER_TOKEN_LIST_SET = 'DEVELOPER_TOKEN_LIST_SET',
}

// export const doDEVELOPER_TOKEN_SET = createAction(DeveloperTokenActionType.DEVELOPER_TOKEN_SET)
export const doDEVELOPER_TOKEN_DELETE = createAction(DeveloperTokenActionType.DEVELOPER_TOKEN_DELETE)
export const doDEVELOPER_TOKEN_LIST_SET = createAction(DeveloperTokenActionType.DEVELOPER_TOKEN_LIST_SET)

export interface DeveloperToken {
  id: string
  updatedAt: string
  createdAt: string
  name: string
  identityId: string
  secret: string | undefined
}

type DeveloperTokenState = DeveloperToken[]

interface RootState {
  developerToken: DeveloperTokenState
}

export const selectDeveloperToken = () => {
  return useSelector((state: RootState) => state.developerToken)
}

export const developerTokenActionCreators = {
  // doDEVELOPER_TOKEN_SET,
  doDEVELOPER_TOKEN_DELETE,
  doDEVELOPER_TOKEN_LIST_SET,
}

export const developerTokenDefaultState: DeveloperTokenState = []

type Action =
  // | {
  //     type: DeveloperTokenActionType.DEVELOPER_TOKEN_SET,
  //     payload: {
  //       id: string
  //       name: string
  //     },
  //   }
  | {
      type: DeveloperTokenActionType.DEVELOPER_TOKEN_DELETE
      payload: {
        id: string
      }
    }
  | {
      type: DeveloperTokenActionType.DEVELOPER_TOKEN_LIST_SET
      payload: any[]
    }

export const developerTokenReducer = (
  state = developerTokenDefaultState,
  {type, payload}: Action,
): DeveloperTokenState => {
  let newDeveloperTokenState
  switch (type) {
    // case DeveloperTokenActionType.DEVELOPER_TOKEN_SET:
    //   assertPartialSchema({
    //     payload,
    //     schema: v.object({
    //       id: v.string().uuid().exist(),
    //       name: v.string().exist(),
    //     }),
    //   })

    //   newDeveloperTokenState = {...state}
    //   newDeveloperTokenState[payload.id] = payload.name
    //   return {...newDeveloperTokenState}

    case DeveloperTokenActionType.DEVELOPER_TOKEN_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })

      newDeveloperTokenState = [...state].filter((developerToken: DeveloperToken) => developerToken.id !== payload.id)

      return newDeveloperTokenState

    case DeveloperTokenActionType.DEVELOPER_TOKEN_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.array().items(
          v.object({
            id: v.string().uuid().exist(),
            name: v.string().exist(),
            identityId: v.string().uuid().exist(),
            updatedAt: v.string().exist(),
            createdAt: v.string().exist(),
          }),
        ),
      })

      newDeveloperTokenState = [...payload]
      return newDeveloperTokenState

    default:
      return [...state]
  }
}
