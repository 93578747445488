import { IEntityModel } from './base'
import { ProjectCollaboratorType } from './project_collaborator'
import { WorkspaceCollaboratorType } from './workspace_collaborator'

export interface InviteFormContentWorkspaceCollaborator {
  workspaceId: string
  type:
    | WorkspaceCollaboratorType.Admin
    | WorkspaceCollaboratorType.Editor
    | WorkspaceCollaboratorType.Viewer
}

export interface InviteFormContentProjectCollaboratorListItem {
  projectId: string
  type: ProjectCollaboratorType
}

export interface InviteFormContentProjectCollaborator {
  workspaceId: string
  type: WorkspaceCollaboratorType.ProjectCollaborator
  projectCollaboratorList: InviteFormContentProjectCollaboratorListItem[]
}

export enum InviteFormType {
  WorkspaceCollaborator = 'workspaceCollaborator',
  ProjectCollaborator = 'projectCollaborator',
}

export type InviteForm =
  | {
      type: InviteFormType.WorkspaceCollaborator
      version: 1
      content: InviteFormContentWorkspaceCollaborator
    }
  | {
      type: InviteFormType.ProjectCollaborator
      version: 1
      content: InviteFormContentProjectCollaborator
    }

export interface IInvite extends IEntityModel {
  identityId: string
  form: InviteForm
  email: string
  invited: boolean
}

export function extractCollaboratorInfo(form: InviteForm) {
  const info = new Map<
    { workspaceId: string; type: WorkspaceCollaboratorType },
    { projectId: string; type: ProjectCollaboratorType }[]
  >()

  switch (form.type) {
    case InviteFormType.WorkspaceCollaborator:
      switch (form.version) {
        case 1:
          return info.set(form.content, [])
      }
      break
    case InviteFormType.ProjectCollaborator:
      switch (form.version) {
        case 1:
          return info.set(form.content, form.content.projectCollaboratorList)
      }
      break
  }
  // should be unreachable
  throw new Error('unhandled invite form case')
}
