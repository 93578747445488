import {selectTheme} from '../../store'
import {useProgressiveImg} from '../../lib'
import {Button, ButtonCancel, OptimizedImage} from '..'
import BackgroundPic from '../../asset/image/finish_setup.png'
import BackgroundPicPlaceholder from '../../asset/image/placeholder/finish_setup_placeholder.webp'

export interface ProjectFinishSetupPopPropsT {
  closeAction: (boolean: boolean) => void
  doAction: () => void
}

export const ProjectFinishSetupPop = (props: ProjectFinishSetupPopPropsT) => {
  const {color, fontWeight} = selectTheme()
  const {closeAction, doAction} = props
  const imgSrc = useProgressiveImg(BackgroundPicPlaceholder, BackgroundPic)

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '101',
      }}
    >
      <div
        {...props}
        css={{
          margin: '130px auto',
          width: '570px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <div css={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '240px'}}>
          <div>
            <ButtonCancel onClick={() => closeAction(false)} />
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={imgSrc} width='170' height='170' css={{marginBottom: '30px'}} />
              {/* <OptimizedImage src={BackgroundPic} width={170} height={170} css={{marginBottom: '30px'}}/> */}
              <div
                css={{
                  fontSize: '20px',
                  fontWeight: fontWeight.thick,
                  marginBottom: '15px',
                }}
              >
                Your app is about to go live!
              </div>
            </div>
            <div css={{textAlign: 'center', marginBottom: '15px'}}>
              Once you go live you'll be able to add participants and start capturing data.
            </div>
            <div css={{textAlign: 'center'}}>What are you waiting for?</div>
          </div>
          <div>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                margin: '0 20px',
              }}
            >
              <Button type='submit' onClick={doAction} css={{marginTop: '30px', marginBottom: '15px'}}>
                Finish Setup and Go Live
              </Button>

              <a
                onClick={() => closeAction(false)}
                css={{
                  color: color.primary,
                  cursor: 'pointer',
                }}
              >
                Back to edit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
