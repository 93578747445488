import { TagIcon } from "../../asset/image"
import { ITag } from "../../shared/db"
import { Tag, TagClickHandler } from "../atoms"

export interface ParticipantTagsContainerProps {
  tags: ITag[]
  showTrailingTagIcon?: boolean
  onTagClick?: TagClickHandler<ITag>
}

export const ParticipantTagsContainer = (props: ParticipantTagsContainerProps) => {
  const { tags, showTrailingTagIcon, onTagClick } = props
  const tagElements = tags.map((tag) => <Tag key={tag.id} tag={tag} onClick={onTagClick}/>)
  if (showTrailingTagIcon) {
    tagElements.push(<img key='tagIcon' src={TagIcon} width={16}/>)
  }
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
      {tagElements}
    </div>
  )
}