import {UnparseConfig} from 'papaparse'

import {Content, TABULAR_DATA_CONTENT_SEP, TABULAR_DATA_DELIMITER, TABULAR_DATA_NEWLINE} from './model'

export class CSVBuilder {
  build(content: Content): string {
    const csvConfig: UnparseConfig = {
      header: true,
      delimiter: TABULAR_DATA_DELIMITER,
      newline: TABULAR_DATA_NEWLINE,
    }
    const bodyCSV = content.body.toCSV(csvConfig)
    return [...content.headers, bodyCSV].join(TABULAR_DATA_CONTENT_SEP)
  }
}
