import {Chart, Geometry} from '@antv/g2/lib'
import {AxisOption, ScaleOption, ShapePoint} from '@antv/g2/lib/interface'

import _ from 'lodash'

import {
  CommonTaskDataMarker,
  GenericTimeSeriesDataChartConfig,
  GenericTimeSeriesDataChartProps,
  TimeSeriesDataChart,
  sliceTimeSeriesDataByPlotTimeRange,
} from './time_series_data_chart'
import {useEffect, useRef, useState} from 'react'
import {TimeSeriesDataSource} from '../../../model'

export interface GenericTimeSeriesXyzChartProps extends GenericTimeSeriesDataChartProps {
  defaultScaleOption: ScaleOption
}

export interface TimeSeriesXyz extends TimeSeriesDataSource {
  x: number
  y: number
  z: number
}

export const TimeSeriesXyzChart = (props: GenericTimeSeriesXyzChartProps) => {
  const {defaultScaleOption, config, timeSeriesData, plotDataRange, commonTaskDataMarkerList, chartReadyCallback} =
    props
  const chartRef = useRef<Chart>()
  const plotDataRef = useRef<TimeSeriesXyz[]>()
  const xLineRef = useRef<Geometry<ShapePoint>>()
  const yLineRef = useRef<Geometry<ShapePoint>>()
  const zLineRef = useRef<Geometry<ShapePoint>>()
  const [showXData, setShowXData] = useState<boolean>(true)
  const [showYData, setShowYData] = useState<boolean>(true)
  const [showZData, setShowZData] = useState<boolean>(true)

  const showXDataCheckboxClick = () => {
    if (xLineRef.current) {
      xLineRef.current.changeVisible(!showXData)
    }
    setShowXData?.(!showXData)
  }
  const showYDataCheckboxClick = () => {
    if (yLineRef.current) {
      yLineRef.current.changeVisible(!showYData)
    }
    setShowYData?.(!showYData)
  }
  const showZDataCheckboxClick = () => {
    if (zLineRef.current) {
      zLineRef.current.changeVisible(!showZData)
    }
    setShowZData?.(!showZData)
  }

  useEffect(() => {
    autoAdjustScale(true)
  }, [showXData, showYData, showZData])

  const xLineColor = 'blue'
  const yLineColor = 'red'
  const zLineColor = 'green'

  const dataVisibleSelector = (
    <div css={{height: '28px', padding: '5px 16px'}}>
      <input type='checkbox' id='showXDataCheckbox' name='x' checked={showXData} onChange={showXDataCheckboxClick} />
      <label htmlFor='x' style={{color: xLineColor, fontSize: '16px'}}>
        X
      </label>

      <input type='checkbox' id='showYDataCheckbox' name='y' checked={showYData} onChange={showYDataCheckboxClick} />
      <label htmlFor='y' style={{color: yLineColor, fontSize: '16px'}}>
        Y
      </label>

      <input type='checkbox' id='showZDataCheckbox' name='z' checked={showZData} onChange={showZDataCheckboxClick} />
      <label htmlFor='z' style={{color: zLineColor, fontSize: '16px'}}>
        Z
      </label>
    </div>
  )

  const initXyzChart = (chart: Chart) => {
    chartRef.current = chart

    chart.scale('x', defaultScaleOption).scale('y', defaultScaleOption).scale('z', defaultScaleOption)
    chart.scale('v', { formatter: () => {return ''}}) // make it empty

    const yAxisConfig: AxisOption = {
      position: 'left',
    }
    xLineRef.current = chart.axis('x', yAxisConfig).line().position('t*x').color(xLineColor)
    yLineRef.current = chart.axis('y', false).line().position('t*y').color(yLineColor)
    zLineRef.current = chart.axis('z', false).line().position('t*z').color(zLineColor)

    if (chartReadyCallback) {
      chartReadyCallback(chart)
    }
  }

  const xyzChartConfig: GenericTimeSeriesDataChartConfig = {
    dataConfig: {...config.dataConfig, maxDataLength: 80000},
    chartConfig: props.config.chartConfig,
    chartCardConfig: {
      ...props.config.chartCardConfig,
      extraTitleRightSideElements: dataVisibleSelector,
    },
  }

  const autoAdjustScale = (reRender: boolean) => {
    if (chartRef.current && plotDataRef.current) {
      let maxValue = 0
      let minValue = 0

      plotDataRef.current.forEach((item: TimeSeriesXyz) => {
        if (showXData) {
          if (item.x > maxValue) {
            maxValue = item.x
          }
          if (item.x < minValue) {
            minValue = item.x
          }
        }

        if (showYData) {
          if (item.y > maxValue) {
            maxValue = item.y
          }
          if (item.y < minValue) {
            minValue = item.y
          }
        }

        if (showZData) {
          if (item.z > maxValue) {
            maxValue = item.z
          }
          if (item.z < minValue) {
            minValue = item.z
          }
        }
      })
      const scaleOption = {min: Math.floor(minValue * 1.05), max: Math.floor(maxValue * 1.05)}
      chartRef.current.scale('x', scaleOption).scale('y', scaleOption).scale('z', scaleOption)
      if (reRender) {
        // chart.updateData() will excute render(), so don't render twice 
        chartRef.current.render()
      }
    }
  }

  const beforeUpdateDataCallback = (data: TimeSeriesDataSource[]) => {
    plotDataRef.current = data as TimeSeriesXyz[]
    autoAdjustScale(false)
  }

  const timeSeriesDataChartProps: GenericTimeSeriesDataChartProps = {
    config: xyzChartConfig,
    timeSeriesData,
    plotDataRange,
    commonTaskDataMarkerList,
    chartReadyCallback: initXyzChart,
    beforeUpdateDataCallback,
  }

  return (
    <>
      <TimeSeriesDataChart {...timeSeriesDataChartProps} />
    </>
  )
}
