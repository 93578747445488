import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {CollaboratorEntry} from '..'
import {RIF, t, useCurrentWorkspaceState} from '../../lib'
import {selectTheme, selectCollaborators, selectProfile, selectWorkspaces} from '../../store'
import { Collaborator } from '../../model'

interface CollaboratorListProps {
  workspaceId: string
  setRemovingCollaborator: Dispatch<SetStateAction<any>>
  searchingPhrase: string
  type?: string
  setShowingManageCollaboratorPopup: Dispatch<SetStateAction<boolean>>
  setEditingCollaborator: Dispatch<SetStateAction<Collaborator | null>>
}

export const CollaboratorList = (props: CollaboratorListProps) => {
  const {color} = selectTheme()

  const {
    workspaceId, 
    setRemovingCollaborator, 
    searchingPhrase, 
    type,
    setShowingManageCollaboratorPopup,
    setEditingCollaborator,
  } = props

  const collaboratorsState = selectCollaborators()
  // const userProfile = selectProfile()
  const { workspaceId: currentWorkspaceId, workspace: currentWorkspace } = useCurrentWorkspaceState()
  // const role = workspacesState.roleInWorkspaces[workspaceId] || 'owner'

  const [collaboratorList, setCollaboratorList] = useState<any>([])

  useEffect(() => {
    if (collaboratorsState[workspaceId]) {
      setCollaboratorList(Object.values(collaboratorsState[workspaceId]))
    }
  }, [workspaceId, collaboratorsState])

  const processIndex = (originIndex: number) => {
    if (originIndex < 8) {
      if (type) {
        return `0${originIndex + 1}`
      }
      return `0${originIndex + 2}`
    }
    return `${originIndex + 1}`
  }

  const handleSetRemovingCollaborator = (collaborator: any) => {
    if (setRemovingCollaborator) setRemovingCollaborator(collaborator)
  }

  const ownerProfile = currentWorkspace?.ownerProfile

  return (
    <div
      css={{
        border: `1px solid ${color.grey_100}`,
        borderBottom: 'none',
        width: '100%',
        background: color.white,
        marginBottom: '48px',
      }}
    >
      <CollaboratorEntry />
      {RIF(
        !type &&
        currentWorkspace &&
        currentWorkspace.ownerProfile?.id &&
        currentWorkspace?.ownerProfile?.email.includes(searchingPhrase),
        <CollaboratorEntry
          {...{
            index: '01',
            setRemovingCollaborator: handleSetRemovingCollaborator,
            key: '01',
            collaborator: ownerProfile ? {
              id: t.unwrap(ownerProfile.id),
              workspaceId: t.unwrap(currentWorkspaceId),
              type: 'owner',
              profile: ownerProfile ,
            } : undefined,
          }}
        />,
      )}
      {collaboratorList
        .filter((c: Collaborator) => {
          if (
            (!type && !c.projectCollaboratorList?.length) ||
            (type && c.projectCollaboratorList?.length)
          ) {
            return c
          }
        })
        .map((collaborator: Collaborator, index: number) => {
        if (collaborator.profile?.email?.includes(searchingPhrase)) {
          return (
            <CollaboratorEntry
              {...{
                index: processIndex(index),
                collaborator,
                setRemovingCollaborator: handleSetRemovingCollaborator,
                key: index,
                setShowingManageCollaboratorPopup,
                setEditingCollaborator,
              }}
            />
          )
        }
      })}
    </div>
  )
}
