import {MouseEventHandler, CSSProperties} from 'react'
import {darken} from '../../lib/color_util'
import {selectTheme} from '../../store'
import {BtnColor, BtnPaddingSize} from '..'

export interface ButtonReversePropsT {
  disabled?: boolean
  btnPadding?: keyof typeof BtnPaddingSize
  btnColor?: BtnColor
  bgColor?: string
  children?: string | JSX.Element
  onClick?: MouseEventHandler
  onMouseOver?: MouseEventHandler
  onMouseOut?: MouseEventHandler
  type?: 'submit'
  css?: CSSProperties
  id?: string
  fontWeight?: string
}

/* a styled button */
export const ButtonReverse = (props: ButtonReversePropsT) => {
  const {color, fontWeight} = selectTheme()
  const {disabled, btnPadding, btnColor, css, type, id, bgColor, ...rest} = props

  const backgroundColor: {[key: string]: any} = {
    primary: color.primary,
    warning: color.warning,
    black: color.black,
    grey_600: color.grey_600,
    disabled: color.disabled,
  }

  const buttonPadding = btnPadding ? BtnPaddingSize[btnPadding] : '12px 15px'
  const buttonColor = btnColor ? backgroundColor[btnColor] : color.primary

  return (
    <button
      {...rest}
      type={type ? 'submit' : 'button'}
      id={id}
      disabled={disabled}
      css={{
        ...css,
        borderRadius: '5px',
        fontWeight: props.fontWeight ?? fontWeight.thick,
        border: `1px solid`,
        borderColor: `${disabled ? color.disabled : buttonColor}`,
        color: `${disabled ? color.disabled : buttonColor}`,
        backgroundColor: bgColor ?? color.white,
        padding: buttonPadding,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        ':hover': {
          borderColor: `${disabled ? color.disabled : darken(buttonColor, 10)}`,
          color: `${disabled ? color.disabled : darken(buttonColor, 10)}`,
          background: bgColor ? darken(bgColor, 10) : color.hover,
        },
        ':active': {
          borderColor: `${disabled ? color.disabled : darken(buttonColor, 20)}`,
          color: `${disabled ? color.disabled : darken(buttonColor, 20)}`,
          background: color.hover,
        },
        ':focus': {
          outline: color.disabled,
        },
        cursor: `${disabled ? 'auto' : 'pointer'}`,
      }}
    />
  )
}
