import {useEffect, useRef, useState} from 'react'

export interface SegmentedControlT {
  name: string
  value: number
  onChange: (index: number) => void
  options: string[]
  id?: string
}

export const SegmentedControl = (props: SegmentedControlT) => {
  const [value, setValue] = useState(props.value || 0)
  const {name, options} = props

  const onChange = (index: number) => {
    setValue(index)
    if (props.onChange) props.onChange(index)
  }

  const divControlRef = useRef<HTMLDivElement>(null)
  const optionDivRefs = options.map(() => useRef<HTMLDivElement>(null))

  useEffect(() => {
    const activeSegmentRef = optionDivRefs[value];
    if (activeSegmentRef.current && divControlRef.current) {
      const { offsetWidth, offsetLeft } = activeSegmentRef.current;
      const { style } = divControlRef.current;
      style.setProperty('--highlight-width', `${offsetWidth}px`);
      style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
    }
  }, [value, onChange, options]);

  return (
    <div ref={divControlRef} css={{
      '--highlightWidth': 'auto',
      '--highlightXPos': 0,
      display: 'flex',
    }}>
      <div css={{
        display: 'inline-flex',
        justifyContent: 'space-between',
        background: '#F0F0F1',
        borderRadius: '5px',
        maxWidth: '500px',
        padding: '4px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
        '::before': {
          content: '""',
          background: 'white',
          borderRadius: '5px',
          width: 'var(--highlight-width)',
          transform: 'translateX(var(--highlight-x-pos))',
          position: 'absolute',
          top: '4px',
          bottom: '4px',
          left: 0,
          zIndex: 0,
          transition: 'transform 0.3s ease, width 0.3s ease'
        },
      }}>
        {options.map((item, i) => (
          <div
            key={`segmentedControl_${name}_${i}`}
            ref={optionDivRefs[i]}
            css={{
              maxWidth: '120px',
              position: 'relative',
              textAlign: 'center',
              zIndex: 1,
            }}
          >
            <input
              type="radio"
              value={item}
              id={`segmentedControl_${name}_${i}`}
              name={`segmentedControl_${name}_${i}`}
              onChange={() => onChange(i)}
              checked={i === value}
              css={{
                opacity: 0,
                margin: 0,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                position: 'absolute',
                width: '100%',
                cursor: 'pointer',
                height: '100%',
                maxWidth: '120px',
                textAlign: 'center',
                zIndex: 1,
              }}
            />
            <label htmlFor={item}
                   css={{
                     cursor: 'pointer',
                     display: 'block',
                     fontWeight: 500,
                     fontSize: '12px',
                     padding: '4px 16px',
                     position: 'relative',
                     transition: 'color 0.5s ease',
                     color: (i === value) ? '#1F1F1F' : '#9A9BA2'
                   }}>
              {item}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
