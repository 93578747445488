import {useState, useEffect} from 'react'
import {useDebounce} from '.'

export const useSearch = (key: any, targetList: any[]) => {
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState<any[]>(targetList || [])
  const debouncedSearch = useDebounce(searchValue, 500)

  useEffect(() => {
    if (targetList.length === 0) return
    // if there's no hits found, set searchResult back to all targetList
    if (searchValue.length <= 0) return setSearchResult(targetList)

    // filter rule
    const filterTerm = (searchValue || '')?.toLowerCase()
    const filterRegex = new RegExp((filterTerm?.split('').join('.*') || '.*').replace(/([.?*+^$[\]\\(){}|])/g, ''))

    // else, search on the provided 'key'
    const tempArray: any[] = []
    targetList?.map((item: any) => {
      // not sure how deep is target key,
      // split to array, and for loop to define keyValue
      const keyArray = key.split('.')

      let keyValue = item
      for (let i = 0; i < keyArray.length; i++) {
        keyValue = keyValue?.[keyArray[i]]
      }
      if (filterRegex.test(keyValue?.toLowerCase())) tempArray?.push(item)

      // for dev, search by uuid
      let parentObj = item
      for (let i = 0; i < keyArray.length - 1; i++) {
        parentObj = parentObj?.[keyArray[i]]
      }
      const id = parentObj.id || parentObj.participantId
      if (id?.toLowerCase() === searchValue?.toLowerCase()) tempArray?.push(item)
    })
    setSearchResult(tempArray)
  }, [debouncedSearch, targetList, targetList.length])

  return {searchValue, setSearchValue, searchResult}
}
