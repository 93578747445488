import {useState} from 'react'
import {selectTheme} from '../../store'
import {Controller} from 'react-hook-form'

import {RIF, v, useForm} from '../../lib'

import {Legend, Input, Checkbox, ButtonReverse, InputWarning, LabelBox} from '..'

const schema = v.object({
  delete: v.string().valid('delete').required(),
  termAgreement1: v.boolean().not(false),
  termAgreement2: v.boolean().not(false),
})

export const ProfileDeletePage = (props: any) => {
  const {color, pad} = selectTheme()

  const [hasRequiredError, setRequiredError] = useState(false)
  const [hasTermAgreementRequiredError, setTermAgreementRequiredError] = useState(false)

  const {register, handleSubmit, formState, watch, trigger, control} = useForm({schema})
  const formValues = watch()

  const onSubmit = () => {
    return
  }

  const onError = (error: any) => {
    setRequiredError(error?.delete?.type == 'any.only' ? true : false)
    setTermAgreementRequiredError(error?.termAgreement1 ? true : false)
    setTermAgreementRequiredError(error?.termAgreement2 ? true : false)
  }

  const onChange = async () => {
    if (formState.isSubmitted) {
      await trigger() // trigger the validation
      onError(formState.errors) // run onError with current validation errors
    }
  }

  return (
    // wrapper
    <div
      css={{
        margin: '150px 0 0 30vw',
        padding: '50px',
        position: 'relative',
      }}
    >
      <form onChange={onChange} onSubmit={handleSubmit(onSubmit, onError)} css={{width: '620px'}}>
        <Legend>Delete Account</Legend>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus mauris ac auctor malesuada id elit sit. In
          cras in interdum est. Lacinia nulla hendrerit faucibus amet adipiscing. Tellus id.
        </div>

        {/* first termAgreement */}
        <label css={{marginTop: '30px', display: 'flex', alignItems: 'start'}}>
          <Controller
            control={control}
            defaultValue={props.defaultTermAgreement || false}
            name='termAgreement1'
            render={({field: {name, value, onChange}}) => (
              <Checkbox
                {...{
                  name: name,
                  value: value,
                  onChange: onChange,
                }}
                color={color.warning}
              />
            )}
          />
          <span css={{flex: '2', marginLeft: '5px'}}>
            I understand that this will disconnect all participants and log them out of the project on the participant
            app.
          </span>
        </label>

        {/* second termAgreement */}
        <label css={{marginTop: '10px', display: 'flex', alignItems: 'start'}}>
          <Controller
            control={control}
            defaultValue={props.defaultTermAgreement || false}
            name='termAgreement2'
            render={({field: {name, value, onChange}}) => (
              <Checkbox
                {...{
                  name: name,
                  value: value,
                  onChange: onChange,
                }}
                color={color.warning}
              />
            )}
          />
          <span css={{flex: '2', marginLeft: '5px'}}>
            I understand that this will delete all the participants information and data.
          </span>
        </label>

        {RIF(
          !formValues?.termAgreement && hasTermAgreementRequiredError,
          <InputWarning css={{margin: '10px 0 0 0'}} message='You must agree to the Terms and Conditions' />,
        )}

        {/* input */}
        <div css={{marginTop: '40px'}}>
          <LabelBox
            {...{
              label: 'To continue please enter “Delete”',
              warningMsg: hasRequiredError ? `Required Input 'Delete'` : undefined,
            }}
          >
            <Input {...register('delete')} css={{marginBottom: pad.small}} type='text' placeholder='Delete' />
          </LabelBox>
        </div>

        <ButtonReverse type='submit' btnPadding='large' btnColor='warning' css={{marginTop: pad.large}}>
          Delete Account
        </ButtonReverse>
      </form>
    </div>
  )
}
