import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'
import {doCOLLABORATOR_SET, doREQUEST_COMPLETE, doREQUEST_ERROR} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_WORKSPACE_COLLABORATOR_UPDATE = 'REQUEST_WORKSPACE_COLLABORATOR_UPDATE'
export const doREQUEST_WORKSPACE_COLLABORATOR_UPDATE = createDoRequestAction(REQUEST_WORKSPACE_COLLABORATOR_UPDATE)

export const requestWorkspaceCollaboratorUpdateActionCreators = {
  doREQUEST_WORKSPACE_COLLABORATOR_UPDATE,
}

export function* requestWorkspaceCollaboratorUpdate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestWorkspaceCollaboratorUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        workspaceCollaboratorId: v.string().uuid().exist(),
        type: v.string().valid('admin', 'editor', 'viewer', 'project_collaborator').exist(),
        projectCollaboratorAction: v.object({
          upsert: v.array().items(v.object({
            projectId: v.string().uuid().exist(),
            type: v.string().valid('projectAdmin', 'projectEditor', 'projectViewer').exist(),
          })),
          delete: v.array().items(v.object({
            projectId: v.string().uuid().exist(),
          }))
        }).allow(null).optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_COLLABORATOR_UPDATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {
    workspaceCollaboratorId, 
    type, 
    projectCollaboratorAction,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/workspace-collaborator-update`,
    accessToken,
    data: {
      workspaceCollaboratorId,
      type,
      projectCollaboratorAction,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_WORKSPACE_COLLABORATOR_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/workspace-collaborator-update',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(doCOLLABORATOR_SET({...result.payload}))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_WORKSPACE_COLLABORATOR_UPDATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* workspaceCollaboratorUpdateSaga() {
  yield takeEvery(REQUEST_WORKSPACE_COLLABORATOR_UPDATE, requestWorkspaceCollaboratorUpdate)
}
