import {assertPartialSchema, createAction, useSelector, LOGOUT, setSessionStorage, v} from '../../../lib'
import {BetaType} from '../../../shared/db'

export const SETTINGS_SET = 'SETTINGS_SET'
export const doSETTINGS_SET = createAction(SETTINGS_SET)

export type SettingsState = {
  id?: string
  time_zone?: string
  country_code?: string
  locale?: string
  betaEnabled?: boolean
  enhancedBbiEnabled?: boolean
  betaType?: BetaType | null
}
interface RootState {
  settings: SettingsState
}
export const useSelectSettings = () => {
  return useSelector((state: RootState) => state.settings)
}

export const settingsActionCreators = {
  doSETTINGS_SET,
}

export const settingsDefaultState = {
  id: null,
  time_zone: null,
  country_code: null,
  locale: null,
  betaEnabled: null,
  enhancedBbiEnabled: null,
  betaType: null
}

type Action = {
  type: string
  payload: SettingsState
}

/* Handle state changing of user settings */
export const settingsReducer = (state = settingsDefaultState, {type, payload}: Action) => {
  switch (type) {
    case SETTINGS_SET:
      assertPartialSchema({
        payload,
        schema: v
          .object({
            id: v.string().uuid().optional(),
            time_zone: v.string().optional(),
            country_code: v.string().length(2).optional(),
            locale: v.string().optional(),
            betaEnabled: v.boolean().optional(),
            enhancedBbiEnabled: v.boolean().optional(),
            betaType: v.valid(...Object.values(BetaType)).allow(null),
          })
          .allow(null),
      })
      setSessionStorage({settings: payload})
      return payload
    case LOGOUT:
      return settingsDefaultState
    default:
      return state
  }
}
