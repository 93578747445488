import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {getToken} from './token_fetcher'
import {doREQUEST_ERROR, doREQUEST_COMPLETE, doPROJECT_SCHEDULE_CONFIG_SET} from '../state'
import {ProjectScheduleFrequency, ProjectScheduleReference} from '../../shared/db'

export const REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE = 'REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE'
export const doREQUEST_PROJECT_SCHEDULE_CONFIG_CREATE = createDoRequestAction(REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE)

export const requestProjectScheduleConfigCreateActionCreators = {
  doREQUEST_PROJECT_SCHEDULE_CONFIG_CREATE,
}

export function* requestProjectScheduleConfigCreate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectScheduleConfigCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        frequency: v
          .string()
          .allow(...Object.values(ProjectScheduleFrequency), null)
          .exist(),
        reference: v
          .string()
          .allow(...Object.values(ProjectScheduleReference), null)
          .exist(),
        absoluteReferenceYymmdd: v.number().integer().min(100000).max(999999).allow(null).exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {requestId, ...data} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/project-schedule-config-create`,
    accessToken,
    data,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE,
          fromPayload: payload,
          requestId,
          url: '/v1/web/project-schedule-config-create',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(doPROJECT_SCHEDULE_CONFIG_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* projectScheduleConfigCreateSaga() {
  yield takeEvery(REQUEST_PROJECT_SCHEDULE_CONFIG_CREATE, requestProjectScheduleConfigCreate)
}
