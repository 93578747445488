import {MouseEventHandler, CSSProperties} from 'react'
import {CancelBlackIcon} from '../../asset/image'
import { selectTheme } from '../../store'
import { darken } from '../../lib'

export interface ButtonCancelProps {
  onClick: MouseEventHandler
  bgColor?: string
  size?: 'small' | 'medium' | 'large'
}

export const ButtonCancel = (props: ButtonCancelProps) => {
  const {color} = selectTheme()
  const {onClick, bgColor, size, ...rest} = props

  return (
    <button onClick={onClick} {...rest} css={{
      width: size === 'small' ? '20px' : '24px',
      height: size === 'small' ? '20px' : '24px',
      outline: 'none',
      backgroundImage: `url(${CancelBlackIcon})`,
      backgroundPosition: 'center',
      backgroundSize: '20px 20px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: bgColor ?? 'white',
      cursor: 'pointer',
      border: 'none',
      borderRadius: '5px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: bgColor ? darken(bgColor, 10) : color.hover,
      },
    }}>
      {/* <img width='12' src={CancelBlackIcon}/> */}
    </button>
  )
}
