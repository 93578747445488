import {useEffect} from 'react'

// only use in delete account now, if need more contact form should be module
export const HubspotContactForm = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '8585895',
          formId: 'ee483f9b-632b-4b02-99ce-e010ddbbc5e8',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <div>
      <div id='hubspotForm'></div>
    </div>
  )
}
