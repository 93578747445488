import {useEffect, useState} from 'react'
import {ProjectScheduleReference, ScheduleType} from '../../shared/db'
import {LocalSchedule} from './add_schedule_pop'
import {t} from '../../lib'
import {TaskScheduleSelectionChildProps, TaskScheduleSelectionType} from './task_schedule_type_selection'
import {Select} from '../atoms'
import {selectTheme} from '../../store'

interface ParticipantJoinedXWeekOption {
  label: string
  value: number
}

export const TaskScheduleParticipantJoinedXWeekSelection = (
  props: TaskScheduleSelectionChildProps
) => {
  const {
    selected,
    scheduleType,
    taskSchedules,
    setTaskSchedules,
    projectScheduleConfig
  } = props
  const {fontSize} = selectTheme()
  const participantJoinedXWeekOptions: ParticipantJoinedXWeekOption[] = [
    { label: "Week 1", value: 1 },
    { label: "Week 2", value: 2 },
    { label: "Week 3", value: 3 },
    { label: "Week 4", value: 4 },
    { label: "Week 5", value: 5 },
    { label: "Week 6", value: 6 },
    { label: "Week 7", value: 7 },
    { label: "Week 8", value: 8 },
    { label: "Week 9", value: 9 },
    { label: "Week 10", value: 10 },
  ]
  const defaultParticipantJoinedXWeek: ParticipantJoinedXWeekOption | null = (() => {
    if ((scheduleType === TaskScheduleSelectionType.ParticipantJoinedXRelativeWeek
      || scheduleType === TaskScheduleSelectionType.ParticipantJoinedXAbsoluteWeek) && taskSchedules.length !== 0) {
      return participantJoinedXWeekOptions[t.unwrap(taskSchedules[0].schedule.day) - 1]
    } else {
      return null
    }
  })()

  const [participantJoinedXWeek, setParticipantJoinedXWeek] = useState<ParticipantJoinedXWeekOption | null>(defaultParticipantJoinedXWeek)
  useEffect(() => {
    if (participantJoinedXWeek) {
      const schedule: LocalSchedule = {
        type: ScheduleType.Relative,
        schedule: {
          day: participantJoinedXWeek.value
        }
      }
      setTaskSchedules([schedule])
    } else {
      setTaskSchedules([])
    }
  }, [participantJoinedXWeek])
  return (
    <Select
      value={selected ? participantJoinedXWeek : null }
      options={participantJoinedXWeekOptions}
      onChange={setParticipantJoinedXWeek}
      css={{
        marginBottom: '16px',
        width: '100%',
        fontSize: fontSize.h7,
      }}
    />
  )
}

