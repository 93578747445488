import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {v, debug, useForm, RequestResult} from '../../lib'
import {Button, Legend, LabelBox, Label, Input} from '..'
import {UploadIcon, ProfileImageDefault} from '../../asset/image'
import {selectTheme, createDispatchActions} from '../../store'

const schema = v.object({
  firstName: v.string().exist(),
  lastName: v.string().exist(),
  title: v.string().allow('').optional(),
})

export const ProfileInitialSettingsPage = () => {
  const {pad, color, fontWeight} = selectTheme()
  const navigate = useNavigate()

  const {doREQUEST_PROFILE_CREATE, doREQUEST_PROFILE_IMAGE_UPLOAD}: any = createDispatchActions()
  const [requestResultOfProfileCreate, setRequestResultOfProfileCreate] = useState<RequestResult | null>(null)
  const [requestResultOfImageUpload, setRequestResultOfImageUpload] = useState<RequestResult | null>(null)

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm({schema})

  const [avatar, setAvatar] = useState<File>()
  const [inputCompleted, setInputComplete] = useState(false)
  const [organizationWordCount, setOrganizationWordCount] = useState(0)

  useEffect(() => {
    setInputComplete(false)
    if (watch('firstName') !== '' && watch('lastName') !== '') setInputComplete(true)
    setOrganizationWordCount(watch('title')?.length)
  }, [watch('firstName'), watch('lastName'), watch('title')])

  const onSubmit = (data: any) => {
    if (data.title === '') data.title = undefined
    debug('ProfileForm.onSubmit', {data})
    doREQUEST_PROFILE_CREATE({
      setRequestResult: setRequestResultOfProfileCreate,
      payload: {
        ...data,
      },
    })
  }

  const onError = (error: any) => {
    debug('ProfileForm.onError', {error})
  }

  // if create profile success, then upload image
  useEffect(() => {
    if (!requestResultOfProfileCreate) return
    if (!requestResultOfProfileCreate?.success) return
    if (avatar) {
      doREQUEST_PROFILE_IMAGE_UPLOAD({
        setRequestResult: setRequestResultOfImageUpload,
        payload: {
          image: avatar,
        },
      })
    } else {
      navigate('/project')
    }
  }, [requestResultOfProfileCreate])

  useEffect(() => {
    if (!requestResultOfImageUpload) return
    if (!requestResultOfImageUpload?.success) return
    navigate('/project')
  }, [requestResultOfImageUpload])

  return (
    <div data-testid='profile_initial_settings_page' css={{margin: '20vh auto 0', width: '600px', padding: '50px'}}>
      <Legend>Let's get your profile ready.</Legend>
      <div css={{fontWeight: fontWeight.bold, marginBottom: pad.large}}>
        This will create your profile and workspace.
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '25px',
        }}
      >
        <img
          src={avatar ? URL.createObjectURL(avatar) : ProfileImageDefault}
          width='74'
          height='74'
          css={{borderRadius: '50%', marginRight: pad.large}}
        />
        <div>
          <div css={{fontWeight: fontWeight.bold, marginBottom: pad.small}}>My Avatar</div>
          <Label
            htmlFor='avatar_upload'
            css={{
              borderRadius: '5px',
              border: `1px solid ${color.primary}`,
              color: color.primary,
              background: color.white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '7px 18px',
            }}
          >
            <img width='16' css={{marginRight: pad.small}} src={UploadIcon} />
            Upload Image
          </Label>
          <input
            id='avatar_upload'
            type='file'
            onChange={(e) => {
              setAvatar(e.target.files?.[0])
            }}
            css={{display: 'none'}}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit, onError)} css={{width: 'fit-content'}}>
        <div css={{display: 'flex', minWidth: '600px', justifyContent: 'space-between', marginBottom: '28px'}}>
          <LabelBox {...{required: true, label: 'First Name'}}>
            <Input
              data-testid='firstName_input'
              {...register('firstName')}
              maxLength='50'
              css={{width: '282px', height: '43px'}}
            />
          </LabelBox>
          <LabelBox {...{required: true, label: 'Last Name'}}>
            <Input
              data-testid='lastName_input'
              {...register('lastName')}
              maxLength='50'
              css={{width: '282px', height: '43px'}}
            />
          </LabelBox>
        </div>
        <LabelBox
          {...{
            label: 'Organization / Company / Institute',
            leftChracters: 50 - organizationWordCount,
          }}
        >
          <Input
            data-testid='organization_input'
            {...register('title')}
            maxLength='50'
            css={{width: '100%', height: '43px'}}
          />
        </LabelBox>

        <div css={{display: 'flex', alignItems: 'center', marginTop: '30px'}}>
          <Button type='submit' disabled={!inputCompleted}>
            Next
          </Button>
          <span css={{marginLeft: '10px'}}>You can edit this later in Profile Settings.</span>
        </div>
      </form>
    </div>
  )
}
