import {GarminConnectRecordDataSleeps} from '../../../shared/mongo'
import {DailyTwoValueChartData} from '../../../model/chart'
import {LocalDateGarminConnectTransformer} from './base'

export class DailySummarySleepsTransformer extends LocalDateGarminConnectTransformer<
  GarminConnectRecordDataSleeps,
  DailyTwoValueChartData
> {
  transform() {
    return this.toDataFrame(({durationInSeconds, overallSleepScore}) => {
      return {
        value: durationInSeconds,
        value2: overallSleepScore?.value,
      }
    })
  }
}
