export interface PopupBackgroundPropsT {
  children: any
}

export const PopupBackground = (props: PopupBackgroundPropsT) => {
  const {children} = props
  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '103',
      }}
    >
      {children}
    </div>
  )
}
