export const CollaboratorProfileImage = (props) => {
  return (
    <div
      {...props}
      // src={props.src ? props.src : ProfileImageDefault}
      css={{
        borderRadius: '50%',
        backgroundImage: `url(${props.src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  )
}
