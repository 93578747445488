import { IEntityModel } from './base'
import { IDigestParticipantItem } from './digest_participant_item'
import { IParticipant } from './participant'
import { IProject } from './project'
import { IWorkspace } from './workspace'

export enum DigestParticipantType {
  Utc = 'utc',
  Participant = 'participant',
}

export interface IDigestParticipant extends IEntityModel {
  participantId: string
  projectId: string
  workspaceId: string
  day: string
  dayUnixTimestamp: number
  type: DigestParticipantType
  project?: IProject
  workspace?: IWorkspace
  participant: IParticipant
  digestParticipantItemList?: IDigestParticipantItem[]
}
