import {useNavigate} from 'react-router-dom'
import {selectTheme} from '../../store'
import {Button} from '..'
import Error404Img from '../../asset/image/error_404_image.png'

export const PageNotFoundPage = () => {
  const {color, fontWeight} = selectTheme()
  const navigate = useNavigate()

  const handleClick = () => {
    navigate('/project')
    window.location.reload()
  }

  return (
    <div
      css={{
        background: color.grey_100,
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        zIndex: 110,
      }}
    >
      <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '50%'}}>
        <div css={{marginRight: '70px'}}>
          <div css={{fontSize: '32px', fontWeight: fontWeight.thick}}>Page not found</div>
          <p css={{fontSize: '16px', fontWeight: fontWeight.thick, margin: '8px 0 32px'}}>
            Sorry, the page you are looking for doesn't exist or has been moved. If you need any support or want to
            report a bug, please
            <a
              href='https://www.labfront.com/contact'
              css={{fontSize: '16px', fontWeight: fontWeight.thick, color: color.primary}}
            >
              {' '}
              contact us.
            </a>
          </p>
          <Button onClick={handleClick}>Back to My Workspace</Button>
        </div>
        <img src={Error404Img} width='268' height='234' />
      </div>
    </div>
  )
}
