import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {v, debug, useForm, RequestResult} from '../../lib'
import {createDispatchActions} from '../../store'
import {PopupSimple, Input, LabelBox} from '..'

const schema = v.object({
  projectName: v.string().max(50).required(),
})

interface Props {
  setDisplayDuplicatePopup: Dispatch<SetStateAction<boolean>>
  projectId: string
  projectName: string
}

export const ProjectDuplicatePop = (props: Props) => {
  const {setDisplayDuplicatePopup, projectId, projectName} = props

  const {doREQUEST_PROJECT_DUPLICATE}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const [newProjectName, setNewProjectName] = useState(`${projectName} Copy`)
  const {register, handleSubmit, watch} = useForm({schema})

  useEffect(() => {
    setNewProjectName(watch('projectName'))
  }, [watch('projectName')])

  const onSubmit = (data: Record<string, string>) => {
    debug('ProjectDuplicateForm.onSubmit', {data})
    doREQUEST_PROJECT_DUPLICATE({
      setRequestResult,
      payload: {
        projectId,
        ...data,
      },
    })
  }

  const onError = (error: any) => {
    debug('ProjectDuplicateForm.onError', {error})
  }

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult.success) return
    return setDisplayDuplicatePopup(false)
  }, [requestResult])

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      data-testid='duplicate_project_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '102',
      }}
    >
      <PopupSimple
        method='Name'
        name='Your Duplicated Project'
        description='Duplicated projects only duplicate the project specs, and not the participants or data.'
        buttonColor='primary'
        buttonText='Duplicate Project'
        btnDisableAction={!newProjectName}
        closeAction={setDisplayDuplicatePopup}
        html={
          <LabelBox
            {...{
              label: 'Project Name',
              leftChracters: 50 - newProjectName?.length,
            }}
          >
            <Input
              {...register('projectName')}
              placeholder='Write a short and easy to identify name for your project'
              value={projectName}
              maxLength='50'
              css={{marginBottom: '50px'}}
            />
          </LabelBox>
        }
      />
    </form>
  )
}
