import React from 'react'
import {selectTheme} from '../../store'

/** a styled legend */
export const Legend = (props) => {
  const {pad, fontWeight, fontSize} = selectTheme()
  return (
    <legend
      css={{
        display: 'block',
        fontWeight: fontWeight.bold,
        fontSize: fontSize.h3,
        marginBottom: pad.medium,
      }}
      {...props}
    />
  )
}
