import { IEntityModel } from './base'
import { IDexcomAccount } from './dexcom_account'
import { IDexcomIntegration } from './dexcom_integration'

export enum DexcomAppRegion {
  US = 'us',
  EU = 'eu',
  Sandbox = 'sandbox',
}

export interface IDexcomApp extends IEntityModel {
  dexcomIntegrationId: string
  dexcomIntegration?: IDexcomIntegration
  name: string
  description: string
  region: DexcomAppRegion
  clientId: string
  secretClientSecret: string
  totalAccounts: number
  dexcomAccountList?: IDexcomAccount[]
}
