import React from 'react'
import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

const en = {
  translation: require(`${__dirname}/../asset/locale/lokalise.en.json`),
}

i18n.use(initReactI18next).init({
  resources: {
    en,
  },
  lng: 'en',
  fallbackLng: 'en',
  keySeparator: false,
  nsSeparator: false,
})

export default i18n
