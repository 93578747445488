import {CSSProperties, ReactNode} from 'react'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import {Nullish} from 'utility-types'

import {selectTheme} from '../../store'
import {RIF} from '../../engine'
import {CalendarGreyIcon} from '../../asset/image'

export interface ScheduleSelectionProps<T> {
  title: string
  description: string
  selected: boolean
  selectionValue: T
  onSelect: (value: T) => void
  children?: ReactNode
  parentCSS?: CSSProperties
}

export const ScheduleSelection = <T,>(props: ScheduleSelectionProps<T>) => {
  const {title, description, selected, selectionValue, onSelect, children, parentCSS} = props
  const {color, fontWeight} = selectTheme()

  const borderColor = selected ? color.border.blue.blue : color.border._80
  const background = selected ? color.surface.blue.light : color.white

  return (
    <div
      css={{
        border: `1px solid ${borderColor}`,
        borderRadius: '5px',
        background,
        width: '100%',
        padding: '16px',
        cursor: 'pointer',
        ...parentCSS,
      }}
      onClick={() => onSelect(selectionValue)}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <input
          type='radio'
          css={{
            accentColor: color.primary,
            marginRight: '16px',
          }}
          name={title}
          value={String(selectionValue)}
          checked={selected}
          onChange={() => {
            // console will warn about not having onChange handler, so just add an empty one
          }}
        />
        <div>
          <div
            css={{
              wordBreak: 'break-word',
              marginBottom: '12px',
              fontWeight: fontWeight.bold,
              color: color.black,
            }}
          >
            {title}
          </div>
          <div
            css={{
              wordBreak: 'break-word',
              fontWeight: fontWeight.medium,
              color: color.textIcon.grey,
            }}
          >
            {description}
          </div>
          {RIF(
            children,
            <div>
              <hr
                css={{
                  borderStyle: 'none',
                  borderTop: `1px solid ${color.border._80}`,
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
              />
              {children}
            </div>,
          )}
        </div>
      </div>
    </div>
  )
}

export interface ScheduleDateSelectionProps<T> extends Omit<ScheduleSelectionProps<T>, 'children'> {
  selectedDate: DateObject | Nullish
  onDateSelect: (date: DateObject) => void
}

export const ScheduleDateSelection = <T,>(props: ScheduleDateSelectionProps<T>) => {
  const {selectedDate, onDateSelect, ...selectionProps} = props
  const {color, fontWeight} = selectTheme()

  return (
    <ScheduleSelection
      {...selectionProps}
      children={
        <div>
          <div
            css={{
              color: color.textIcon.secondary,
              fontWeight: fontWeight.medium,
              marginBottom: '4px',
            }}
          >
            When does the first week start?
          </div>
          <DatePicker
            value={selectedDate}
            onChange={(date) => {
              if (date instanceof DateObject) {
                onDateSelect(date)
              }
            }}
            render={(value, openCalendar) => {
              return (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    border: `1px solid ${color.grey_160}`,
                    background: color.white,
                    padding: '9px 16px',
                    cursor: 'pointer',
                    gap: '16px',
                  }}
                  onClick={openCalendar}
                >
                  <div
                    css={{
                      color: color.grey_160,
                      fontWeight: fontWeight.medium,
                    }}
                  >
                    {value || 'Please choose a date'}
                  </div>
                  <img width={16} height={16} src={CalendarGreyIcon} />
                </div>
              )
            }}
          />
        </div>
      }
    />
  )
}

type Weekday = 1 | 2 | 3 | 4 | 5 | 6 | 7

export interface ScheduleWeekdaySelectionProps<T> extends Omit<ScheduleSelectionProps<T>, 'children'> {
  selectedWeekdays: Weekday[]
  onWeekdaySelect: (weekday: Weekday) => void
}

// TODO: implement
export const ScheduleWeekdaySelection = <T,>(props: ScheduleWeekdaySelectionProps<T>) => {
  const {selectedWeekdays, onWeekdaySelect, ...selectionProps} = props
  return <ScheduleSelection {...selectionProps} children={<div>weekday options here</div>} />
}

export interface ScheduleDropdownSelectionProps<T> extends Omit<ScheduleSelectionProps<T>, 'children'> {
  dropdownValues: string[]
  selectedDropdownValue: string
  onDropdownValueSelect: (value: string) => void
}

// TODO: implement
export const ScheduleDropdownSelection = <T,>(props: ScheduleDropdownSelectionProps<T>) => {
  const {dropdownValues, selectedDropdownValue, onDropdownValueSelect, ...selectionProps} = props
  return <ScheduleSelection {...selectionProps} children={<div>dropdown values here</div>} />
}
