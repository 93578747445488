import {v, assertPartialSchema, createAction, useSelector} from '../../../lib'
import {IProjectExtension} from '../../../shared/db'

export enum ProjectExtensionActionType {
  PROJECT_EXTENSION_SET = 'PROJECT_EXTENSION_SET',
  PROJECT_EXTENSION_UPDATE = 'PROJECT_EXTENSION_UPDATE',
  PROJECT_EXTENSION_DELETE = 'PROJECT_EXTENSION_DELETE',
}

export const doPROJECT_EXTENSION_SET = createAction(ProjectExtensionActionType.PROJECT_EXTENSION_SET)
export const doPROJECT_EXTENSION_UPDATE = createAction(ProjectExtensionActionType.PROJECT_EXTENSION_UPDATE)
export const doPROJECT_EXTENSION_DELETE = createAction(ProjectExtensionActionType.PROJECT_EXTENSION_DELETE)

interface ProjectExtensionState {
  [projectId: string]: IProjectExtension
}

interface RootState {
  projectExtension: ProjectExtensionState
}

export const selectProjectExtension = () => {
  return useSelector((state: RootState) => state.projectExtension)
}

export const projectExtensionActionCreators = {
  doPROJECT_EXTENSION_SET,
  doPROJECT_EXTENSION_UPDATE,
  doPROJECT_EXTENSION_DELETE,
}

export const projectExtensionDefaultState: ProjectExtensionState = {}

type Action =
  | {
      type: ProjectExtensionActionType.PROJECT_EXTENSION_SET
      payload: IProjectExtension[]
    }
  | {
      type: ProjectExtensionActionType.PROJECT_EXTENSION_UPDATE
      payload: IProjectExtension
    }
  | {
      type: ProjectExtensionActionType.PROJECT_EXTENSION_DELETE
      payload: {
        projectId: string
      }
    }

export const projectExtensionReducer = (
  state = projectExtensionDefaultState,
  action: Action,
): ProjectExtensionState => {
  switch (action.type) {
    case ProjectExtensionActionType.PROJECT_EXTENSION_SET: {
      assertPartialSchema({
        payload: action.payload,
        schema: v.array().items(
          v.object({
            projectId: v.string().uuid().exist(),
            imageUrl: v.string().optional(),
          }),
        ),
      })
      const newState = {...state}
      action.payload.forEach((projectExtension) => {
        newState[projectExtension.projectId] = projectExtension
      })
      return newState
    }
    case ProjectExtensionActionType.PROJECT_EXTENSION_UPDATE: {
      assertPartialSchema({
        payload: action.payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          imageUrl: v.string().optional(),
        }),
      })
      const newState = {...state}
      newState[action.payload.projectId] = action.payload
      return newState
    }
    case ProjectExtensionActionType.PROJECT_EXTENSION_DELETE: {
      assertPartialSchema({
        payload: action.payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
        }),
      })
      const newState = {...state}
      delete newState[action.payload.projectId]
      return newState
    }
    default:
      return {...state}
  }
}
