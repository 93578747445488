import {MouseEventHandler, CSSProperties} from 'react'
import {darken} from '../../lib/color_util'
import {selectTheme} from '../../store'
import {BtnColor, BtnPaddingSize} from '..'

export interface ButtonTextPropsT {
  disabled?: boolean
  btnPadding?: keyof typeof BtnPaddingSize
  btnColor?: BtnColor
  children?: string | JSX.Element
  onClick?: MouseEventHandler
  onMouseOver?: MouseEventHandler
  onMouseOut?: MouseEventHandler
  type?: 'submit'
  css?: CSSProperties
}

export const ButtonText = (props: ButtonTextPropsT) => {
  const {color, fontWeight} = selectTheme()
  const {disabled, btnPadding, btnColor, ...rest} = props

  const btnTextColor: {[key: string]: any} = {
    primary: color.primary,
    warning: color.warning,
  }

  const buttonPadding = btnPadding ? BtnPaddingSize[btnPadding] : '12px 15px'
  const buttonColor = btnColor ? btnTextColor[btnColor] : color.primary

  return (
    <button
      {...rest}
      css={{
        border: 'none',
        borderRadius: '5px',
        padding: buttonPadding,
        fontWeight: fontWeight.thick,
        color: `${disabled ? color.disabled : buttonColor}`,
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          color: `${disabled ? color.disabled : darken(buttonColor, 10)}`,
          background: `${disabled ? 'transparent' : color.hover}`,
        },
        ':active': {
          color: `${disabled ? color.disabled : darken(buttonColor, 20)}`,
          background: `${disabled ? 'transparent' : color.hover}`,
        },
        ':focus': {outline: 'none'},
        cursor: `${disabled ? 'auto' : 'pointer'}`,
      }}
    />
  )
}
