import {DateObject} from 'react-multi-date-picker'

export interface AnalysisSidebarSubjectParticipant {
  name: string
  type: AnalysisSidebarSubjectType.Individual
  participantId: string
}

export interface AnalysisSidebarSubjectGroup {
  name: string
  type: AnalysisSidebarSubjectType.Group
  participantIds: string[]
}

export type AnalysisSidebarSubject = AnalysisSidebarSubjectParticipant | AnalysisSidebarSubjectGroup

export enum AnalysisSidebarSubjectType {
  Group = 'group',
  Individual = 'individual',
}

export interface DataSetSelectionItem {
  key: string
  subject: AnalysisSidebarSubject
  duration: [DateObject, DateObject]
}

export const GROUP_MINIMUM_SIZE = 2
export const GROUP_COMPARISON_MINIMUM_COUNT = 2
export const GROUP_COMPARISON_MAXIMUM_COUNT = 2
export const INDIVIDUAL_COMPARISON_MINIMUM_COUNT = 2
export const INDIVIDUAL_COMPARISON_MAXIMUM_COUNT = 2
export const DURATION_COMPARISON_MINIMUM_COUNT = 2
export const DURATION_COMPARISON_MAXIMUM_COUNT = 2
