import React from 'react'
import {selectTheme} from '../../store'

/** a styled fieldset for forms */
export const Fieldset = (props) => {
  const {pad, color} = selectTheme()
  return (
    <fieldset
      {...props}
      css={{
        borderBottom: `1px solid ${color.black}`,
        marginBottom: pad.medium,
        paddingBottom: pad.medium,
      }}
    />
  )
}
