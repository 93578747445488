import React from 'react'
import {Input} from './input'

export const InputNumber = (props) => {
  const handleKeyDown = (e) => {
    let isValidKey = false
    const validKeyCodes = {
      e: 69,
      a: 65,
      up: 38,
      down: 40,
      left: 37,
      right: 39,
      backspace: 8,
      period: 190,
      plus: 187,
      minus: 189,
      tab: 9,
      escape: 27,
      enter: 13,
      insert: 45,
      delete: 56,
      pageDown: 34,
      pageUp: 33,
      home: 36,
      end: 35,
      control: 17,
      meta: 91,
      alt: 18,
    }

    if (Array.from(Object.values(validKeyCodes)).indexOf(e.keyCode) > -1) {
      isValidKey = true
    }

    // is the key 0-9
    if (!isNaN(parseInt(e.key))) {
      isValidKey = true
    }

    if (!isValidKey) e.preventDefault()
    if (props.onKeyDown) props.onKeyDown(e)
  }

  return <Input {...props} type='number' pattern='[0-9]*' inputMode='numeric' onKeyDown={handleKeyDown} />
}
