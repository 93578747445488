import {RIF} from '../../lib'
import {
  // selectDevState, 
  selectSessionState,
  createDispatchActions
} from '../../store'
import {PopupSimple} from '..'

export const SessionPopup = () => {
  const {doSESSION_EXPIRED_SET}: any = createDispatchActions()
  const sessionExpired = selectSessionState()?.sessionExpired

  const handleLogout = () => {
    window.sessionStorage.removeItem('labfront')
    window.location.reload()
  }

  const handleRenewSession = () => doSESSION_EXPIRED_SET(false)

  return (
    <>
      {RIF(
        sessionExpired,
        <div
          css={{
            background: '#00000040',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 110,
          }}
        >
          <PopupSimple
            name='Auto-logout in 5 minutes'
            description={`This is a friendly reminder that your session will be automatically logged out for security purposes. If you are still editing, please hit the "Renew Session" within 5 minutes and you'll have another hour before getting this warning again.
          
          Note: This only occurs since you are in a live-edit where we cannot save your status.
          `}
            cancelText='Logout'
            buttonText={`Renew Session (I'm still editing)`}
            closeAction={handleLogout}
            doAction={handleRenewSession}
          />
        </div>,
      )}
    </>
  )
}
