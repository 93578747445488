import {useEffect, useState} from 'react'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import {CalendarGreyIcon} from '../../asset/image'
import {selectTheme} from '../../store'
import {ScheduleType} from '../../shared/db'
import {LocalSchedule} from './add_schedule_pop'
import {t} from '../../lib'
import {TaskScheduleSelectionChildProps, TaskScheduleSelectionType} from './task_schedule_type_selection'

export const TaskScheduleAbsoluteDateSelection = (
  props: TaskScheduleSelectionChildProps
) => {
  const {
    selected,
    scheduleType,
    taskSchedules,
    setTaskSchedules
  } = props
  const {color, fontWeight} = selectTheme()
  const defaultAbsoluteDate: DateObject | null = (() => {
    if (scheduleType === TaskScheduleSelectionType.AbsoluteDay && taskSchedules.length !== 0) {
      return new DateObject({
        year: t.unwrap(taskSchedules[0].schedule.year),
        month: t.unwrap(taskSchedules[0].schedule.month),
        day: t.unwrap(taskSchedules[0].schedule.day)
      })
    } else {
      return null
    }
  })()
  const [absoluteDate, setAbsoluteDate] = useState<DateObject | null>(defaultAbsoluteDate)
  useEffect(() => {
    if (absoluteDate) {
      const taskSchedule: LocalSchedule = {
        type: ScheduleType.Absolute,
        schedule: {
          day: absoluteDate.day,
          month: absoluteDate.month.number,
          year: absoluteDate.year
        }
      }
      setTaskSchedules([taskSchedule])
    } else {
      setTaskSchedules([])
    }
  }, [absoluteDate])

  return <DatePicker
        value={absoluteDate}
        onChange={(date) => {
          if (date instanceof DateObject) {
            setAbsoluteDate(date)
          }
        }}
        render={(value, openCalendar) => {
          return (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                border: `1px solid ${color.grey_160}`,
                background: color.white,
                padding: '9px 16px',
                cursor: 'pointer',
                gap: '16px',
              }}
              onClick={openCalendar}
            >
              <div
                css={{
                  color: color.grey_160,
                  fontWeight: fontWeight.medium,
                }}
              >
                {selected ? value || 'Please choose a date' : 'Please choose a date'}
              </div>
              <img width={16} height={16} src={CalendarGreyIcon} />
            </div>
          )
        }}
      />
}

