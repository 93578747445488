import {isEmpty, isNil, last, omitBy} from 'lodash'
import {DataFrame} from 'data-forge'

import {t} from '../../../t'
import {GarminConnectRecordData} from '../../../../shared/mongo'
import {LocalDateGarminConnectData} from '../../../../shared/mongo/localdate_garmin_connect_data'
import {DataFrameTransformerOutput, NAValueSelector} from '../../model'
import {DataFrameTransformer} from './data_frame_transformer'
import {DailyChartData} from '../../../../model/chart'
import {fillGaps} from '../../util'

type OmitYYMMDD<T extends DailyChartData> = Omit<T, 'yymmdd'>

export abstract class LocalDateGarminConnectTransformer<
  T extends GarminConnectRecordData = GarminConnectRecordData,
  R extends DailyChartData = DailyChartData,
> extends DataFrameTransformer<number, R> {
  constructor(protected readonly input: LocalDateGarminConnectData<T>[], protected readonly expectedIndex: number[]) {
    super()
  }

  protected toDataFrame(
    valueSelector: (value: T) => OmitYYMMDD<R>,
    naValueSelector: NAValueSelector = (index) => {
      return {yymmdd: index}
    },
  ): DataFrameTransformerOutput<number, R> {
    // create DataFrame, omit nil values, and sort by yymmddIndex
    const dataFrame = new DataFrame({
      values: this.input
        .filter(({data}) => !isEmpty(data))
        .map(({yymmddIndex, data}) => {
          return {
            yymmdd: yymmddIndex,
            ...omitBy(valueSelector(t.unwrap(last(data))), isNil),
          }
        }),
      considerAllRows: true,
    })
      .orderBy(({yymmdd}) => yymmdd)
      .withIndex(({yymmdd}) => yymmdd)

    // fill gaps in yymmddIndex
    return fillGaps(dataFrame, this.expectedIndex, naValueSelector)
  }
}
