import {Dispatch, SetStateAction} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PopupSimple} from '..'
import {useCurrentProjectState} from '../../lib'

export interface ProjectEditPopPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
}

export const ProjectEditPop = (props: ProjectEditPopPropsT) => {
  const navigate = useNavigate()
  const { projectId } = useCurrentProjectState()

  const {closeAction} = props

  const handleEditConfirmPopClick = () => {
    closeAction(false)
    navigate(`/project_setup/${projectId}`)
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <PopupSimple
        {...props}
        {...{
          method: '',
          name: 'Are you sure you want to edit your “live” project?',
          description: `Editing a "live" project can have a significant impact on data collection. Your participant may need to update their app. Some editing actions may also be limited to ensure data consistency.`,
          subDescription: 'Note: This feature is currently in beta. If you experience issues, please contact support. Thank you for understanding.',
          buttonColor: 'primary',
          buttonText: `Yes, I want to edit project`,
          closeAction,
          doAction: handleEditConfirmPopClick,
        }}
        css={{height: '350px'}}
      />
    </div>
  )
}
