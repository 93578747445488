import {SetStateAction, Dispatch} from 'react'
import {handleEsc} from '../../lib'
import {selectTheme} from '../../store'
import {Legend, ButtonCancel, TextLink, Button} from '..'

export interface PopupConfirmDiscardQuestionnaireChangesProps {
  closeAction: Dispatch<SetStateAction<boolean>>
  confirmAction: () => void
}

export const PopupConfirmDiscardQuestionnaireChanges = (props: PopupConfirmDiscardQuestionnaireChangesProps) => {
  const {color, fontWeight} = selectTheme()

  const {closeAction, confirmAction} = props

  const handleCloseAction = () => {
    if (closeAction) closeAction(false)
  }

  const handleConfirmAction = () => {
    if (confirmAction) confirmAction()
  }

  handleEsc(closeAction)

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        zIndex: 99999,
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 'calc(50vh - 145px)',
          left: 'calc(50vw - 300px)',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <ButtonCancel onClick={handleCloseAction}/>
        <Legend css={{
          margin: '36px 0 16px',
        }}>
          Discard unsaved changes
        </Legend>
        <p
          css={{
            fontWeight: fontWeight.bold,
            marginBottom: '80px',
          }}
        >
          You have unsaved changes in your questionnaire. Are you sure you want to exit without saving? All unsaved changes will be discarded.
        </p>
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextLink children='Cancel' onClick={handleCloseAction} />
          <Button
            onClick={handleConfirmAction}
            btnPadding='large'
            btnColor='warning'
            children={'Confirm'}
          />
        </div>
      </div>
    </div>
  )
}
