import {useEffect, createRef} from 'react'
import Lottie from 'lottie-web'
import loadingAnimation from '../../asset/lotties/loading_animation.json'
import {RIF} from '../../lib'
import {selectLoading} from '../../store'

export const Loading = () => {
  const isLoading: any = selectLoading()?.length > 0
  const animRef: any = createRef()

  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    })
    return () => Lottie.stop()
  }, [isLoading])

  return (
    <>
      {RIF(
        isLoading,
        <div
          css={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#00000010',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 110,
          }}
        >
          <div ref={animRef} css={{width: '150px'}}></div>
        </div>,
      )}
    </>
  )
}
