import {createStore, clear} from 'idb-keyval'

const DB_BASE_NAME = 'Labfront'
const STORE_NAME = 'store'

export const enum DB_NAME {
  RawData = 'RawData',
  TimeSeriesForChart = 'TimeSeriesForChart',
  StreamDataForChart = 'StreamDataForChart',
  DailyData = 'DailyData'
}

export interface DbOperationResult<T> {
  dbName: DB_NAME
  participantId: string
  dataType: T
  yymmddIndex: number
  operation: 'set' | 'get'
  result: boolean
}

export const timeSeriesForChartDb = createStore(`${DB_BASE_NAME}-${DB_NAME.TimeSeriesForChart}`, STORE_NAME)
export const rawDataDb = createStore(`${DB_BASE_NAME}-${DB_NAME.RawData}`, STORE_NAME)
export const streamDataDb = createStore(`${DB_BASE_NAME}-${DB_NAME.StreamDataForChart}`, STORE_NAME)
export const dailyDataDb = createStore(`${DB_BASE_NAME}-${DB_NAME.DailyData}`, STORE_NAME)

export function clearDb() {
  clear(timeSeriesForChartDb)
  clear(rawDataDb)
  clear(streamDataDb)
  clear(dailyDataDb)
}
