import {useState, useEffect, SetStateAction, Dispatch} from 'react'
import {useParams} from 'react-router-dom'

import {
  selectTheme,
  selectMethod,
  selectTempTaskData,
  createDispatchActions,
} from '../../store'

import {RequestResult, RIF, useCurrentProjectState, useCurrentWorkspaceState} from '../../lib'

import {ButtonReverse, ButtonCancel, Button, TextLink, Tooltip} from '..'

import {DexcomIntegration, TaskTypeForDevicePage} from '../../model'
import {Scrollbars} from 'react-custom-scrollbars-2'

import {PlusBlueIcon, WarningIcon, CheckPrimaryIcon, ParticipantGreyIcon} from '../../asset/image'

export interface DexcomSelectorPopupProps {
  setDisplayDevicePage: Dispatch<SetStateAction<TaskTypeForDevicePage>>
}

export const DexcomSelectorPopup = (props: DexcomSelectorPopupProps) => {
  const {setDisplayDevicePage} = props
  const {color, fontSize, fontWeight} = selectTheme()
  const {doREQUEST_METHOD_UPDATE, doTEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE}: any = createDispatchActions()
  const methodState = selectMethod()
  const tempTaskData = selectTempTaskData()
  const {workspace: currentWorkspace} = useCurrentWorkspaceState()
  const dexcomIntegrationList = currentWorkspace?.dexcomIntegrationList ?? []
  const {project} = useCurrentProjectState()
  const isEditingLiveProject = project?.status === 'live'
  const [selectedDexcomIntegrationId, setSelectedDexcomIntegrationId] = useState<string | null | undefined>(
    isEditingLiveProject ? tempTaskData.dexcomIntegrationId : methodState.dexcomIntegrationId,
  )
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const handleSubmit = () => {
    if (isEditingLiveProject) {
      doTEMP_TASK_DATA_DEXCOM_INTEGRAION_ID_UPDATE({
        dexcomIntegrationId: selectedDexcomIntegrationId,
      })
      return setDisplayDevicePage('')
    }

    doREQUEST_METHOD_UPDATE({
      setRequestResult,
      payload: {
        methodId: methodState.id,
        dexcomIntegrationId: selectedDexcomIntegrationId,
      },
    })
  }

  useEffect(() => {
    if (requestResult?.success) {
      setDisplayDevicePage('')
    }
  }, [requestResult])

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        top: 0,
        left: 0,
        zIndex: 103,
      }}
    >
      <div
        css={{
          width: '560px',
          background: '#fff',
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
        }}
      >
        {/* header */}
        <div
          css={{
            width: '100%',
            padding: '24px 30px',
            borderBottom: `1px solid ${color.grey_100}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <ButtonCancel
            onClick={() => {
              setDisplayDevicePage('')
            }}
          />
          <p
            css={{
              fontSize: fontSize.h5,
              fontWeight: fontWeight.thick,
              margin: '0 auto',
            }}
          >
            Select Dexcom Project Integration
          </p>
          <div css={{width: '12px'}} />
        </div>

        {/* content */}
        <div
          css={{
            width: '100%',
            padding: '24px 30px',
            borderBottom: `1px solid ${color.grey_100}`,
          }}
        >
          <p css={{marginBottom: '24px'}}>
            Please link the correct integration to your project. You must use the integration as approved by Dexcom.
            <br />
            <br />
            If you have a new project, you must first request a new project integration and wait for Dexcom’s approval.
            <br />
            <br />
            Contact us at hello@labfront.com if you need to edit or delete an application
          </p>
          {RIF(
            !isEditingLiveProject,
            <ButtonReverse
              css={{marginBottom: '8px'}}
              onClick={() => {
                setDisplayDevicePage('dexcomIntegrationForm')
              }}
              children={
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    color: color.primary,
                    fontWeight: fontWeight.thick,
                  }}
                >
                  <img width='20' css={{marginRight: '5px'}} src={PlusBlueIcon} />
                  Request New Project Integration
                </div>
              }
            />,
          )}
          <Scrollbars
            autoHide
            autoHideTimeout={800}
            style={{
              width: '100%',
              height: '300px',
            }}
          >
            {dexcomIntegrationList?.map((dexcomIntegration: DexcomIntegration, index: number) =>
              RIF(
                !isEditingLiveProject || dexcomIntegration?.dexcomApp,
                <DexcomAppInfoCard
                  {...{
                    key: index,
                    selectedDexcomIntegrationId,
                    setSelectedDexcomIntegrationId,
                    isEditingLiveProject,
                    ...dexcomIntegration,
                  }}
                />,
              ),
            )}
          </Scrollbars>
        </div>

        {/* footer */}
        <div
          css={{
            width: '100%',
            padding: '24px 30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TextLink
            onClick={() => {
              setDisplayDevicePage('')
            }}
            children='Cancel'
          />
          <Button onClick={handleSubmit} children='Done' />
        </div>
      </div>
    </div>
  )
}

interface DexcomAppInfoCardProps extends DexcomIntegration {
  selectedDexcomIntegrationId: string | null | undefined
  setSelectedDexcomIntegrationId: Dispatch<SetStateAction<string | null | undefined>>
  isEditingLiveProject: boolean
}

const DexcomAppInfoCard = (props: DexcomAppInfoCardProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    id,
    name,
    description,
    investigator,
    organization,
    countries,
    dexcomApp,
    selectedDexcomIntegrationId,
    setSelectedDexcomIntegrationId,
    isEditingLiveProject,
  } = props

  const TOOLTIP_CONTENT_IF_CLIENT_ID_APPROVED = 'This is your unique key for secure integration with Dexcom.'
  const TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED =
    'Your application is waiting for Dexcom approval. Please check your email for update information. Contact us at hello@labfront.com if you have any other questions.'

  const handleClick = () => {
    if (selectedDexcomIntegrationId === id && !isEditingLiveProject) {
      return setSelectedDexcomIntegrationId(null)
    }
    setSelectedDexcomIntegrationId(id)
  }

  return (
    <button
      onClick={handleClick}
      css={{
        width: '100%',
        padding: '16px',
        borderRadius: '5px',
        border: `1px solid ${id === selectedDexcomIntegrationId ? color.primary : color.grey_160}`,
        marginTop: '8px',
        cursor: 'pointer',
        background: '#fff',
        outline: 'none',
        textAlign: 'start',
        ':hover': {
          border: `1px solid ${id === selectedDexcomIntegrationId ? color.primary : color.grey_400}`,
          background: color.background,
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <p
          css={{
            fontWeight: fontWeight.thick,
            marginBottom: '8px',
          }}
        >
          {name}
        </p>
        {RIF(id === selectedDexcomIntegrationId, <img width={24} src={CheckPrimaryIcon} />)}
      </div>
      <p
        css={{
          fontWeight: fontWeight.medium,
          fontSize: fontSize.h7,
          color: color.grey_600,
          marginBottom: '16px',
        }}
      >
        {description}
      </p>
      <p
        css={{
          fontWeight: fontWeight.medium,
          fontSize: fontSize.h7,
          color: color.grey_400,
          marginBottom: '16px',
        }}
      >
        PI: {investigator}
        <br />
        Organization: {organization}
        <br />
        Countries: {countries}
        <br />
      </p>
      {RIF(
        dexcomApp,
        <>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <div
              css={{
                margin: '16px 0',
                padding: '4px 8px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                background: color.background,
              }}
            >
              <img src={ParticipantGreyIcon} width={16} />
              <p
                css={{
                  marginLeft: '8px',
                  fontWeight: fontWeight.medium,
                  color: color.grey_400,
                  whiteSpace: 'nowrap',
                  fontSize: fontSize.h7,
                }}
              >
                {dexcomApp?.totalAccounts} Participant(s) Connected
              </p>
            </div>
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.medium,
                color: color.grey_600,
                textAlign: 'start',
                marginRight: '4px',
              }}
            >
              <span css={{whiteSpace: 'nowrap', color: color.highlightGreen}}>Approved -&nbsp;</span>
              Client ID: {dexcomApp?.clientId}
            </p>
            <Tooltip content={TOOLTIP_CONTENT_IF_CLIENT_ID_APPROVED} />
          </div>
        </>,
      )}
      {RIF(
        !dexcomApp,
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img src={WarningIcon} width={16} />
          <p
            css={{
              fontWeight: fontWeight.medium,
              color: color.warning,
              margin: '0 4px',
            }}
          >
            Awaiting approval from Dexcom
          </p>
          <Tooltip content={TOOLTIP_CONTENT_IF_CLIENT_ID_NOT_APPROVED} />
        </div>,
      )}
    </button>
  )
}
