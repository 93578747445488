import {Dispatch, SetStateAction} from 'react'
import {GarminConnectPanel, GarminDevicePanel} from '..'

export interface GarminSpecPanelPropsT {
  garminDeviceSpecCopy: any
  setGarminDeviceSpecCopy: Dispatch<SetStateAction<any>>
  garminDeviceSectionEnable: boolean
  setGarminDeviceSectionEnable: Dispatch<SetStateAction<boolean>>
  garminConnectEnable: boolean
  setGarminConnectEnable: Dispatch<SetStateAction<boolean>>
  garminStreamEnable: boolean
  setGarminStreamEnable: Dispatch<SetStateAction<boolean>>
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const GarminSpecPanel = (props: GarminSpecPanelPropsT) => {
  const {
    garminDeviceSpecCopy,
    setGarminDeviceSpecCopy,
    garminDeviceSectionEnable,
    setGarminDeviceSectionEnable,
    garminConnectEnable,
    setGarminConnectEnable,
    garminStreamEnable,
    setGarminStreamEnable,
    setDisplayChangePlanPage,
  } = props
  return (
    <div css={{width: '100%', marginTop: '40px'}}>
      <p css={{fontWeight: '500', marginBottom: '24px'}}>Turn on the source(s) you want to collect the data from:</p>
      <div css={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <GarminConnectPanel
          {...{
            garminConnectEnable,
            setGarminConnectEnable,
          }}
        />
        <GarminDevicePanel
          {...{
            garminDeviceSpecCopy,
            setGarminDeviceSpecCopy,
            garminDeviceSectionEnable,
            setGarminDeviceSectionEnable,
            garminStreamEnable,
            setGarminStreamEnable,
            setDisplayChangePlanPage,
          }}
        />
      </div>
    </div>
  )
}
