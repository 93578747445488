export enum ConnectBackfillEndpoints {
  UserMetrics = 'userMetrics',
  StressDetails = 'stressDetails',
  Sleeps = 'sleeps',
  // SkinTemp = 'skinTemp', // gets 403 Forbidden
  Respiration = 'respiration',
  PulseOx = 'pulseOx',
  MoveIQ = 'moveiq',
  // MCT = 'mct', // gets 412 Precondition Failed
  HRV = 'hrv',
  HealthSnapshot = 'healthSnapshot',
  Epochs = 'epochs',
  Dailies = 'dailies',
  BodyComps = 'bodyComps',
  BloodPressures = 'bloodPressures',
  ActivityDetails = 'activityDetails',
  Activities = 'activities',
}
