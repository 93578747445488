import {
  assertPartialSchema, 
  createAction, 
  useSelector, 
  v,
  _,
} from '../../../lib'

export enum ArchiveStatusActionType {
  ARCHIVE_STATUS_SET = 'ARCHIVE_STATUS_SET',
}

export const doARCHIVE_STATUS_SET = createAction(ArchiveStatusActionType.ARCHIVE_STATUS_SET)

export interface ArchiveStatusState {
  [projectId: string]: {
    projectId: string
    jobStatus: 'never' | 'running' | 'succeeded' | 'failed'
    latestDataTimestamp: number | null
    latestBuildTimestamp: number | null
    downloadAvailable: boolean
  }
}

interface RootState {
  archiveStatus: ArchiveStatusState
}

export const selectArchiveStatus = () => {
  return useSelector((state: RootState) => state.archiveStatus)
}

export const archiveStatusActionCreators = {
  doARCHIVE_STATUS_SET,
}

export const archiveStatusDefaultState: ArchiveStatusState = {}

type Action = 
  | {
    type: ArchiveStatusActionType.ARCHIVE_STATUS_SET
    payload: {
      projectId: string
      jobStatus: 'never' | 'running' | 'succeeded' | 'failed'
      latestDataTimestamp: number | null
      latestBuildTimestamp: number | null
      downloadAvailable: boolean
    }
  }

export const archiveStatusReducer = (state = {...archiveStatusDefaultState}, {type, payload}: Action): ArchiveStatusState => {
  const newState = {...state}

  switch (type) {
    case ArchiveStatusActionType.ARCHIVE_STATUS_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.string().uuid().exist(),
          jobStatus: v.string().valid('never', 'running', 'succeeded', 'failed').exist(),
          latestDataTimestamp: v.number().allow(null).exist(),
          latestBuildTimestamp: v.number().allow(null).exist(),
          downloadAvailable: v.boolean().exist(),
        })
      })

      const {
        projectId, 
        jobStatus, 
        latestBuildTimestamp, 
        latestDataTimestamp, 
        downloadAvailable
      } = payload
      newState[projectId] = {
        projectId,
        jobStatus,
        latestDataTimestamp,
        latestBuildTimestamp,
        downloadAvailable,
      }

      return newState
    }
    
    default:
      return {...state}
  }
}