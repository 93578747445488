export enum VariableDataType {
  Todo = 'todo',
  Timer = 'timer',
  GarminConnectSleep = 'garmin_connect_sleep',
  GarminConnectActivity = 'garmin_connect_activity',
  GarminConnectHeartRate = 'garmin_connect_heart_rate',
  GarminConnectStress = 'garmin_connect_stress',
  GarminDeviceHrv = 'garmin_device_hrv',
  GarminDeviceActigraphySleep = 'garmin_device_actigraphy_sleep',
}

export type TaskVariableDataType =
  | VariableDataType.Todo
  | VariableDataType.Timer

export type GarminConnectVariableDataType =
  | VariableDataType.GarminConnectActivity
  | VariableDataType.GarminConnectHeartRate
  | VariableDataType.GarminConnectSleep
  | VariableDataType.GarminConnectStress

export type GarminDeviceVariableDataType =
  | VariableDataType.GarminDeviceHrv
  | VariableDataType.GarminDeviceActigraphySleep

export type VariableType =
  | TodoVariableType
  | TimerVariableType
  | GarminConnectSleepVariableType
  | GarminConnectActivityVariableType
  | GarminConnectHeartRateVariableType
  | GarminConnectStressVariableType
  | GarminDeviceHrvVariableType
  | GarminDeviceActigraphySleepVariableType

export enum TodoVariableType {
  Occurred = 'occurred',
  Count = 'count',
}

export enum TimerVariableType {
  Occurred = 'occurred',
  Count = 'count',
  TotalDuration = 'total_duration',
  AverageDuration = 'average_duration',
}

export enum GarminConnectSleepVariableType {
  Score = 'score',
  Duration = 'duration',
  LightStageRatio = 'light_stage_ratio',
  DeepStageRatio = 'deep_stage_ratio',
  RemStageRatio = 'rem_stage_ratio',
  AwakeStageRatio = 'awake_stage_ratio',
  LightStageCount = 'light_stage_count',
  DeepStageCount = 'deep_stage_count',
  AwakeStageCount = 'awake_stage_count',
  StartTime = 'start_time',
  WakeTime = 'wake_time',
  BodyBatteryRecovery = 'body_battery_recovery',
}

export enum GarminConnectActivityVariableType {
  Steps = 'steps',
  SedentaryMovementRatio = 'sedentary_movement_ratio',
  ModerateIntensityDuration = 'moderate_intensity_duration',
  VigorousIntensityDuration = 'vigorous_intensity_duration',
}

export enum GarminConnectHeartRateVariableType {
  RestingBPM = 'resting_bpm',
  AverageBPM = 'average_bpm',
  MaxBPM = 'max_bpm',
  MinBPM = 'min_bpm',
}

export enum GarminConnectStressVariableType {
  AverageLevel = 'average_level',
  MaxLevel = 'max_level',
  LowDuration = 'low_duration',
  MediumDuration = 'medium_duration',
  HighDuration = 'high_duration',
}

export enum GarminDeviceHrvVariableType {
  MRR = 'mrr',
  SDRR = 'sdrr',
  RMSSD = 'rmssd',
  PRR50 = 'prr50',
  TP = 'tp',
  VLF = 'vlf',
  LF = 'lf',
  HF = 'hf',
  LFHF = 'lfhf',
}

export enum GarminDeviceActigraphySleepVariableType {
  OnsetTime = 'onset_time',
  OffsetTime = 'offset_time',
  BedTime = 'bed_time',
  GetUpTime = 'get_up_time',
  TotalInBedDuration = 'total_in_bed_duration',
  TotalSleepDuration = 'total_sleep_duration',
  OnsetOffsetInterval = 'onset_offset_interval',
  SleepOnsetLatency = 'sleep_onset_latency',
  WakeAfterSleepOnset = 'wake_after_sleep_onset',
  SleepEfficiency = 'sleep_efficiency',
  WakeEpisodeCount = 'wake_episode_count',
}

interface VariableBase<D extends VariableDataType, V extends VariableType> {
  dataType: D
  variableType: V
  identifier?: string
}

interface TaskVariableProps {
  taskId: string
  taskName: string
}

type TodoVariableBase<V extends TodoVariableType> = VariableBase<
  VariableDataType.Todo,
  V
> &
  TaskVariableProps

type TimerVariableBase<V extends TimerVariableType> = VariableBase<
  VariableDataType.Timer,
  V
> &
  TaskVariableProps

type GarminConnectSleepVariableBase<V extends GarminConnectSleepVariableType> =
  VariableBase<VariableDataType.GarminConnectSleep, V>

type GarminConnectActivityVariableBase<
  V extends GarminConnectActivityVariableType,
> = VariableBase<VariableDataType.GarminConnectActivity, V>

type GarminConnectHeartRateVariableBase<
  V extends GarminConnectHeartRateVariableType,
> = VariableBase<VariableDataType.GarminConnectHeartRate, V>

type GarminConnectStressVariableBase<
  V extends GarminConnectStressVariableType,
> = VariableBase<VariableDataType.GarminConnectStress, V>

type GarminDeviceHrvVariableBase<V extends GarminDeviceHrvVariableType> =
  VariableBase<VariableDataType.GarminDeviceHrv, V>

type GarminDeviceActigraphySleepVariableBase<
  V extends GarminDeviceActigraphySleepVariableType,
> = VariableBase<VariableDataType.GarminDeviceActigraphySleep, V>

/**
 * Display Name:
 * Description:
 */
export interface TodoOccurredVariable
  extends TodoVariableBase<TodoVariableType.Occurred> {
  threshold?: number
}

/**
 * Display Name:
 * Description:
 */
export type TodoCountVariable = TodoVariableBase<TodoVariableType.Count>

/**
 * Display Name:
 * Description:
 */
export interface TimerOccurredVariable
  extends TimerVariableBase<TimerVariableType.Occurred> {
  threshold?: number
}

/**
 * Display Name:
 * Description:
 */
export type TimerCountVariable = TimerVariableBase<TimerVariableType.Count>

/**
 * Display Name:
 * Description:
 */
export type TimerTotalDurationVariable =
  TimerVariableBase<TimerVariableType.TotalDuration>

/**
 * Display Name:
 * Description:
 */
export type TimerAverageDurationVariable =
  TimerVariableBase<TimerVariableType.AverageDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepScoreVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.Score>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepDurationVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.Duration>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepLightStageRatioVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.LightStageRatio>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepDeepStageRatioVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.DeepStageRatio>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepRemStageRatioVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.RemStageRatio>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepAwakeStageRatioVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.AwakeStageRatio>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepLightStageCountVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.LightStageCount>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepDeepStageCountVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.DeepStageCount>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepAwakeStageCountVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.AwakeStageCount>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepStartTimeVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.StartTime>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepWakeTimeVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.WakeTime>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectSleepBodyBatteryRecoveryVariable =
  GarminConnectSleepVariableBase<GarminConnectSleepVariableType.BodyBatteryRecovery>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectActivityStepsVariable =
  GarminConnectActivityVariableBase<GarminConnectActivityVariableType.Steps>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectActivitySedentaryMovementRatioVariable =
  GarminConnectActivityVariableBase<GarminConnectActivityVariableType.SedentaryMovementRatio>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectActivityModerateIntensityDurationVariable =
  GarminConnectActivityVariableBase<GarminConnectActivityVariableType.ModerateIntensityDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectActivityVigorousIntensityDurationVariable =
  GarminConnectActivityVariableBase<GarminConnectActivityVariableType.VigorousIntensityDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectHeartRateRestingBPMVariable =
  GarminConnectHeartRateVariableBase<GarminConnectHeartRateVariableType.RestingBPM>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectHeartRateAverageBPMVariable =
  GarminConnectHeartRateVariableBase<GarminConnectHeartRateVariableType.AverageBPM>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectHeartRateMaxBPMVariable =
  GarminConnectHeartRateVariableBase<GarminConnectHeartRateVariableType.MaxBPM>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectHeartRateMinBPMVariable =
  GarminConnectHeartRateVariableBase<GarminConnectHeartRateVariableType.MinBPM>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectStressAverageLevelVariable =
  GarminConnectStressVariableBase<GarminConnectStressVariableType.AverageLevel>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectStressMaxLevelVariable =
  GarminConnectStressVariableBase<GarminConnectStressVariableType.MaxLevel>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectStressLowDurationVariable =
  GarminConnectStressVariableBase<GarminConnectStressVariableType.LowDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectStressMediumDurationVariable =
  GarminConnectStressVariableBase<GarminConnectStressVariableType.MediumDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminConnectStressHighDurationVariable =
  GarminConnectStressVariableBase<GarminConnectStressVariableType.HighDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvMrrVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.MRR>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvSdrrVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.SDRR>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvRmssdVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.RMSSD>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvPrr50Variable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.PRR50>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvTpVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.TP>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvVlfVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.VLF>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvLfVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.LF>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvHfVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.HF>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceHrvLfhfVariable =
  GarminDeviceHrvVariableBase<GarminDeviceHrvVariableType.LFHF>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepOnsetTimeVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.OnsetTime>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepOffsetTimeVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.OffsetTime>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepBedTimeVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.BedTime>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepGetUpTimeVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.GetUpTime>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepTotalInBedDurationVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.TotalInBedDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepTotalSleepDurationVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.TotalSleepDuration>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepOnsetOffsetIntervalVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.OnsetOffsetInterval>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepSleepOnsetLatencyVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.SleepOnsetLatency>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepWakeAfterSleepOnsetVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.WakeAfterSleepOnset>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepSleepEfficiencyVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.SleepEfficiency>

/**
 * Display Name:
 * Description:
 */
export type GarminDeviceActigraphySleepWakeEpisodeCountVariable =
  GarminDeviceActigraphySleepVariableBase<GarminDeviceActigraphySleepVariableType.WakeEpisodeCount>

export type Variable =
  | VariableMap[VariableDataType.Todo][TodoVariableType]
  | VariableMap[VariableDataType.Timer][TimerVariableType]
  | VariableMap[VariableDataType.GarminConnectSleep][GarminConnectSleepVariableType]
  | VariableMap[VariableDataType.GarminConnectActivity][GarminConnectActivityVariableType]
  | VariableMap[VariableDataType.GarminConnectHeartRate][GarminConnectHeartRateVariableType]
  | VariableMap[VariableDataType.GarminConnectStress][GarminConnectStressVariableType]
  | VariableMap[VariableDataType.GarminDeviceHrv][GarminDeviceHrvVariableType]
  | VariableMap[VariableDataType.GarminDeviceActigraphySleep][GarminDeviceActigraphySleepVariableType]

export interface VariableMap {
  [VariableDataType.Todo]: TodoVariableMap
  [VariableDataType.Timer]: TimerVariableMap
  [VariableDataType.GarminConnectSleep]: GarminConnectSleepVariableMap
  [VariableDataType.GarminConnectActivity]: GarminConnectActivityVariableMap
  [VariableDataType.GarminConnectHeartRate]: GarminConnectHeartRateVariableMap
  [VariableDataType.GarminConnectStress]: GarminConnectStressVariableMap
  [VariableDataType.GarminDeviceHrv]: GarminDeviceHrvVariableMap
  [VariableDataType.GarminDeviceActigraphySleep]: GarminDeviceActigraphySleepVariableMap
}

export interface TodoVariableMap {
  [TodoVariableType.Occurred]: TodoOccurredVariable
  [TodoVariableType.Count]: TodoCountVariable
}

export interface TimerVariableMap {
  [TimerVariableType.Count]: TimerCountVariable
  [TimerVariableType.Occurred]: TimerOccurredVariable
  [TimerVariableType.TotalDuration]: TimerTotalDurationVariable
  [TimerVariableType.AverageDuration]: TimerAverageDurationVariable
}

export interface GarminConnectSleepVariableMap {
  [GarminConnectSleepVariableType.Score]: GarminConnectSleepScoreVariable
  [GarminConnectSleepVariableType.Duration]: GarminConnectSleepDurationVariable
  [GarminConnectSleepVariableType.LightStageRatio]: GarminConnectSleepLightStageRatioVariable
  [GarminConnectSleepVariableType.DeepStageRatio]: GarminConnectSleepDeepStageRatioVariable
  [GarminConnectSleepVariableType.RemStageRatio]: GarminConnectSleepRemStageRatioVariable
  [GarminConnectSleepVariableType.AwakeStageRatio]: GarminConnectSleepAwakeStageRatioVariable
  [GarminConnectSleepVariableType.LightStageCount]: GarminConnectSleepLightStageCountVariable
  [GarminConnectSleepVariableType.DeepStageCount]: GarminConnectSleepDeepStageCountVariable
  [GarminConnectSleepVariableType.AwakeStageCount]: GarminConnectSleepAwakeStageCountVariable
  [GarminConnectSleepVariableType.StartTime]: GarminConnectSleepStartTimeVariable
  [GarminConnectSleepVariableType.WakeTime]: GarminConnectSleepWakeTimeVariable
  [GarminConnectSleepVariableType.BodyBatteryRecovery]: GarminConnectSleepBodyBatteryRecoveryVariable
}

export interface GarminConnectActivityVariableMap {
  [GarminConnectActivityVariableType.Steps]: GarminConnectActivityStepsVariable
  [GarminConnectActivityVariableType.SedentaryMovementRatio]: GarminConnectActivitySedentaryMovementRatioVariable
  [GarminConnectActivityVariableType.ModerateIntensityDuration]: GarminConnectActivityModerateIntensityDurationVariable
  [GarminConnectActivityVariableType.VigorousIntensityDuration]: GarminConnectActivityVigorousIntensityDurationVariable
}

export interface GarminConnectHeartRateVariableMap {
  [GarminConnectHeartRateVariableType.RestingBPM]: GarminConnectHeartRateRestingBPMVariable
  [GarminConnectHeartRateVariableType.AverageBPM]: GarminConnectHeartRateAverageBPMVariable
  [GarminConnectHeartRateVariableType.MaxBPM]: GarminConnectHeartRateMaxBPMVariable
  [GarminConnectHeartRateVariableType.MinBPM]: GarminConnectHeartRateMinBPMVariable
}

export interface GarminConnectStressVariableMap {
  [GarminConnectStressVariableType.AverageLevel]: GarminConnectStressAverageLevelVariable
  [GarminConnectStressVariableType.MaxLevel]: GarminConnectStressMaxLevelVariable
  [GarminConnectStressVariableType.LowDuration]: GarminConnectStressLowDurationVariable
  [GarminConnectStressVariableType.MediumDuration]: GarminConnectStressMediumDurationVariable
  [GarminConnectStressVariableType.HighDuration]: GarminConnectStressHighDurationVariable
}

export interface GarminDeviceHrvVariableMap {
  [GarminDeviceHrvVariableType.MRR]: GarminDeviceHrvMrrVariable
  [GarminDeviceHrvVariableType.SDRR]: GarminDeviceHrvSdrrVariable
  [GarminDeviceHrvVariableType.RMSSD]: GarminDeviceHrvRmssdVariable
  [GarminDeviceHrvVariableType.PRR50]: GarminDeviceHrvPrr50Variable
  [GarminDeviceHrvVariableType.TP]: GarminDeviceHrvTpVariable
  [GarminDeviceHrvVariableType.VLF]: GarminDeviceHrvVlfVariable
  [GarminDeviceHrvVariableType.LF]: GarminDeviceHrvLfVariable
  [GarminDeviceHrvVariableType.HF]: GarminDeviceHrvHfVariable
  [GarminDeviceHrvVariableType.LFHF]: GarminDeviceHrvLfhfVariable
}

export interface GarminDeviceActigraphySleepVariableMap {
  [GarminDeviceActigraphySleepVariableType.OnsetTime]: GarminDeviceActigraphySleepOnsetTimeVariable
  [GarminDeviceActigraphySleepVariableType.OffsetTime]: GarminDeviceActigraphySleepOffsetTimeVariable
  [GarminDeviceActigraphySleepVariableType.BedTime]: GarminDeviceActigraphySleepBedTimeVariable
  [GarminDeviceActigraphySleepVariableType.GetUpTime]: GarminDeviceActigraphySleepGetUpTimeVariable
  [GarminDeviceActigraphySleepVariableType.TotalInBedDuration]: GarminDeviceActigraphySleepTotalInBedDurationVariable
  [GarminDeviceActigraphySleepVariableType.TotalSleepDuration]: GarminDeviceActigraphySleepTotalSleepDurationVariable
  [GarminDeviceActigraphySleepVariableType.OnsetOffsetInterval]: GarminDeviceActigraphySleepOnsetOffsetIntervalVariable
  [GarminDeviceActigraphySleepVariableType.SleepOnsetLatency]: GarminDeviceActigraphySleepSleepOnsetLatencyVariable
  [GarminDeviceActigraphySleepVariableType.WakeAfterSleepOnset]: GarminDeviceActigraphySleepWakeAfterSleepOnsetVariable
  [GarminDeviceActigraphySleepVariableType.SleepEfficiency]: GarminDeviceActigraphySleepSleepEfficiencyVariable
  [GarminDeviceActigraphySleepVariableType.WakeEpisodeCount]: GarminDeviceActigraphySleepWakeEpisodeCountVariable
}

// type check, does not compile if interface not conforms to enum
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type CheckVariableMap = VariableMap[VariableDataType]
