import {Scrollbars} from 'react-custom-scrollbars-2'
import {ParticipantGarminDirectDailySummaryChartSeries} from '../charts/chart_series/garmin_direct_daily_summary_series'

export interface EntireDurationBlockProps {
  participantId: string
  dataDateRange: number[]
}

export const ParticipantEntireDurationBoxPlotBlock = (props: EntireDurationBlockProps) => {
  const {participantId, dataDateRange} = props
  /* ------------------ default effect ------------------ */
  return (
    <div
      css={{
        flex: 1,
        padding: '0px 32px 60px 24px',
      }}
    >
      <Scrollbars autoHide={true} autoHideTimeout={200}>
        <ParticipantGarminDirectDailySummaryChartSeries
          {...{
            participantId,
            dataDateRange,
            cardHeight: '210px',
            cardWidth: '280px',
          }}
        />
      </Scrollbars>
    </div>
  )
}