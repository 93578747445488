import {useState, SetStateAction, Dispatch, useMemo, useEffect} from 'react'
import {selectTheme, selectCorrelationVariable, selectMethod} from '../../store'
import {Button, ButtonCancel, Select} from '..'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import {darken, _, t, getVariableDisplayName, getVariableCode} from '../../lib'
import {ParticipantItem, DataTypeDisplayNameMap, TaskStateType, TaskType} from '../../model'
import {useParams} from 'react-router-dom'
import {Scrollbars} from 'react-custom-scrollbars-2'
import {AddGreyIcon, AddBlackIcon} from '../../asset/image'
import {v4} from 'uuid'
import {RIF} from '../../lib'
import {Variable, VariableDataType, VariableType} from '../../shared/analysis'
import {
  AnalysisSidebarSubject,
  AnalysisSidebarSubjectType,
  DataSetSelectionItem,
  GROUP_MINIMUM_SIZE,
} from '../../model/analysis'
import {AnalysisDataResolverArgs} from '../../lib/chart_data/data_loader/analysis_data_resolver'

interface AnalysisSideBarProps {
  participantList: ParticipantItem[]
  onParticipantSelect: (participant: ParticipantItem) => void
  setDuration: Dispatch<SetStateAction<[DateObject, DateObject]>>
  duration: [DateObject, DateObject]
  currentParticipantId: string
  resolution: string
  setResolution: Dispatch<SetStateAction<string>>
  displayingVariableList: Variable[]
  setDisplayingVariableList: Dispatch<SetStateAction<Variable[]>>
  displayingDataSetSelectionList: DataSetSelectionItem[]
  setDisplayingDataSetSelectionList: Dispatch<SetStateAction<DataSetSelectionItem[]>>
  onUpdateAnalysisArgs: (args: AnalysisDataResolverArgs) => void
}

enum DataSetSelectionActionType {
  ADD,
  DELETE,
  UPDATE,
}

interface VariableTypeItem {
  value: VariableType
  label: string
}

type DataSetSelectionAction =
  | {
      type: DataSetSelectionActionType.ADD
    }
  | {
      type: DataSetSelectionActionType.DELETE
      payload: string
    }
  | {
      type: DataSetSelectionActionType.UPDATE
      payload: {
        key: string
        value: DataSetSelectionItem
      }
    }

export const AnalysisSideBar = (props: AnalysisSideBarProps) => {
  const {color, fontSize} = selectTheme()
  const {
    participantList,
    displayingVariableList,
    setDisplayingVariableList,
    displayingDataSetSelectionList,
    setDisplayingDataSetSelectionList,
    onUpdateAnalysisArgs,
  } = props
  const DATASET_COUNT_LIMIT = 2

  const [subjectList, setSubjectList] = useState<AnalysisSidebarSubject[]>([])
  useMemo(() => {
    const result = generateSubjectList(participantList)
    if (!_.isEqual(result, subjectList)) setSubjectList(result)
  }, [participantList])

  const projectId = useParams().projectId ?? ''
  const method = selectMethod()
  const taskList: TaskStateType[] = method?.taskList?.filter(
    (item: TaskStateType) => (item.type === TaskType.Todo || item.type === TaskType.Timer) && item.enabled,
  )
  const correlationVariableList = selectCorrelationVariable(projectId)
  const [dataTypeOptions, setDataTypeOptions] = useState<Record<string, string>[]>([])
  const [variableTypeOptions, setVariableTypeOptions] = useState<Record<string, VariableTypeItem[]>>({})
  useEffect(() => {
    if (!correlationVariableList.length) return
    if (!method?.taskList.length) return
    if (dataTypeOptions.length > 0 && Object.keys(variableTypeOptions).length > 0) return
    const tempDataTypeOptions: Record<string, string>[] = []
    const tempVariableTypeOptions: Record<string, VariableTypeItem[]> = {}
    correlationVariableList.forEach((variable) => {
      let variableTypeOptionsKey: string | VariableDataType = variable.dataType
      if (variable.dataType === VariableDataType.Todo || variable.dataType === VariableDataType.Timer) {
        variableTypeOptionsKey = variable.taskId
        const task = taskList.find((task) => task.id === variable.taskId)
        const taskName = task?.[task.type].name ?? 'Unknown'
        tempDataTypeOptions.push({
          value: variable.taskId,
          label: `${DataTypeDisplayNameMap[variable.dataType]}: ${taskName}`,
          dataType: variable.dataType,
        })
      } else {
        tempDataTypeOptions.push({
          value: variable.dataType,
          label: DataTypeDisplayNameMap[variable.dataType] || variable.dataType,
          dataType: variable.dataType,
        })
      }
      if (!tempVariableTypeOptions[variableTypeOptionsKey]) tempVariableTypeOptions[variableTypeOptionsKey] = []
      tempVariableTypeOptions[variableTypeOptionsKey].push({
        ...variable,
        value: variable.variableType,
        label: getVariableDisplayName(variable),
      })
    })
    setDataTypeOptions(_.uniqBy(tempDataTypeOptions, 'value'))
    setVariableTypeOptions(tempVariableTypeOptions)
  }, [correlationVariableList, taskList])

  const [dataSetSelectionList, setDataSetSelectionList] = useState<DataSetSelectionItem[]>(
    subjectList.length
      ? [
          {
            key: v4(),
            subject: subjectList[0],
            duration: [new DateObject().subtract(7, 'days'), new DateObject()],
          },
        ]
      : [],
  )

  const [dataSetSubjectList, setDataSetSubjectList] = useState<AnalysisSidebarSubject[]>([])
  useMemo(() => {
    if (subjectList.length) setDataSetSubjectList(subjectList)
  }, [subjectList])

  const [editingVariableList, setEditingVariableList] = useState<Variable[]>(displayingVariableList)
  const [resolutionValue, setResolutionValue] = useState<Record<string, string>>({value: 'daily', label: 'Daily'})
  const resolutionOptions = [
    {value: 'daily', label: 'Daily'},
    {value: 'weekly', label: 'Weekly (Coming soon)', isDisabled: true},
    {value: 'monthly', label: 'Monthly (Coming soon)', isDisabled: true},
  ]

  const updateSettingsButtonDisabled = () => {
    if (!dataSetSelectionList.length) return true
    if (
      _.isEqual(displayingVariableList, editingVariableList) &&
      _.isEqual(displayingDataSetSelectionList, dataSetSelectionList)
    ) {
      return displayingDataSetSelectionList.every((dataSetSelection, i) =>
        dataSetSelection.duration.every((date, j) => date.toUnix() === dataSetSelectionList[i].duration[j].toUnix()),
      )
    }
  }

  const onDataSetSelectionAction = (action: DataSetSelectionAction) => {
    let newDataSetSelectionList: DataSetSelectionItem[] = [...dataSetSelectionList]

    if (!newDataSetSelectionList.length) {
      if (action.type === DataSetSelectionActionType.ADD) {
        if (dataSetSubjectList.length) {
          const subject = dataSetSubjectList[0]
          setDataSetSelectionList([
            {
              key: v4(),
              subject,
              duration: [new DateObject().subtract(7, 'days'), new DateObject()],
            },
          ])
        }
      }
      return
    }

    const anchorSubject = newDataSetSelectionList[0].subject

    switch (action.type) {
      case DataSetSelectionActionType.ADD: {
        const selectedSubjects = newDataSetSelectionList.map((dataSetSelection) => dataSetSelection.subject)
        const selectedDurations = newDataSetSelectionList.map((dataSetSelection) => dataSetSelection.duration)

        if (newDataSetSelectionList.length === 1) {
          const subject =
            dataSetSubjectList.find(
              (subject) => subject.type === anchorSubject.type && subject.name !== anchorSubject.name,
            ) ?? anchorSubject
          newDataSetSelectionList.push({
            key: v4(),
            subject,
            duration: selectedDurations[0],
          })
          setDataSetSubjectList(subjectList.filter((subject) => subject.type === anchorSubject.type))
          // setDataSetSubjectList(subjectList)
        } else {
          const incompleteSubject =
            _.head(findIncomplete(selectedSubjects, JSON.stringify)) ?? t.unwrap(_.sample(selectedSubjects))
          const incompleteDuration =
            _.head(
              findIncomplete(selectedDurations, ([d1, d2]) =>
                JSON.stringify([d1.format('YYYY-MM-DD'), d2.format('YYYY-MM-DD')]),
              ),
            ) ?? t.unwrap(_.sample(selectedDurations))
          newDataSetSelectionList.push({
            key: v4(),
            subject: incompleteSubject,
            duration: incompleteDuration,
          })
        }
        break
      }
      case DataSetSelectionActionType.DELETE: {
        newDataSetSelectionList = newDataSetSelectionList.filter(
          (dataSetSelection) => dataSetSelection.key !== action.payload,
        )
        break
      }
      case DataSetSelectionActionType.UPDATE: {
        const {key, value} = action.payload
        const index = newDataSetSelectionList.findIndex((item) => item.key === key)
        newDataSetSelectionList.splice(index, 1, value)
        break
      }
    }
    // Reset data set subject list to all inclusive
    if (newDataSetSelectionList.length === 1) {
      setDataSetSubjectList(subjectList)
    }
    setDataSetSelectionList(newDataSetSelectionList)
  }

  const handleUpdateSettings = () => {
    const tempVariableList = editingVariableList.map((variable, index) => ({
      ...variable,
      identifier: `variable${getVariableCode(index)}`,
    }))
    setDisplayingVariableList(tempVariableList)
    setDisplayingDataSetSelectionList(dataSetSelectionList)
    const subjects = _.uniqBy(
      dataSetSelectionList.map((dataSetSelection) => dataSetSelection.subject),
      'name',
    )
    const durations = _.uniqBy(
      dataSetSelectionList.map((dataSetSelection) => dataSetSelection.duration),
      (duration) => `${duration[0].format('YYYY-MM-DD')}-${duration[1].format('YYYY-MM-DD')}`,
    )
    const analysisDataResolverArgs = {
      subjects,
      durations,
      variables: tempVariableList,
    }
    onUpdateAnalysisArgs(analysisDataResolverArgs)
  }

  const handleChangeResolution = (e: Record<string, string>) => {
    setResolutionValue(e)
  }

  const addVariableBlock = () => {
    setEditingVariableList([...editingVariableList, _.sample(correlationVariableList) as Variable])
  }

  useEffect(() => {
    if (!subjectList.length) return
    setDataSetSelectionList([
      {
        key: v4(),
        subject: subjectList[0],
        duration: [new DateObject().subtract(7, 'days'), new DateObject()],
      },
    ])
  }, [subjectList])

  useMemo(() => {
    if (displayingVariableList.length === 0) return
    setEditingVariableList(displayingVariableList)
  }, [displayingVariableList])

  const handleAddDataSetSelection = () => {
    onDataSetSelectionAction({type: DataSetSelectionActionType.ADD})
  }

  return (
    <div
      css={{
        width: '313px',
        boxSizing: 'border-box',
        height: 'calc(100vh - 56px)',
        backgroundColor: color.white,
        padding: '16px',
        borderRight: `1px solid ${color.borderLight}`,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Scrollbars css={{flex: 1, width: '100%', boxSizing: 'border-box'}}>
        {/* data set section */}
        <div
          css={{
            paddingBottom: '24px',
            marginBottom: '24px',
            borderBottom: `1px solid ${color.border._80}`,
            width: '100%',
          }}
        >
          <div
            css={{
              display: 'flex',
              marginBottom: '8px',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginRight: '4px',
              }}
            >
              Data Set
            </p>

            {/* <Tooltip content={''}/> */}
          </div>
          {dataSetSelectionList.map((dataSetSelection, index) => (
            <DataSetSelection
              {...{
                key: dataSetSelection.key,
                dataSetSelectionKey: dataSetSelection.key,
                index,
                selected: dataSetSelection,
                subjectList: dataSetSubjectList,
                showCloseButton: dataSetSelectionList.length > 1,
                onUpdate: (key, value) => {
                  onDataSetSelectionAction({type: DataSetSelectionActionType.UPDATE, payload: {key, value}})
                },
                onDelete: (key) => {
                  onDataSetSelectionAction({type: DataSetSelectionActionType.DELETE, payload: key})
                },
                dataSetSelectionList,
              }}
            />
          ))}
          {RIF(
            dataSetSelectionList.length < DATASET_COUNT_LIMIT,
            <button
              onClick={handleAddDataSetSelection}
              css={{
                width: '100%',
                borderRadius: '3px',
                border: 'none',
                backgroundColor: color.surface.grey.background,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: color.textIcon.secondary,
                cursor: 'pointer',
                fontSize: fontSize.h8,
                padding: '4px',
                ':hover': {
                  backgroundColor: darken(color.surface.grey.background, 10),
                },
              }}
            >
              Compare with another data set
            </button>,
          )}
        </div>
        {/* resolution section */}
        {/* <div
          css={{
            paddingBottom: '24px',
            marginBottom: '24px',
            borderBottom: `1px solid ${color.border._80}`,
          }}
        >
          <div css={{display: 'flex', marginBottom: '8px'}}>
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginRight: '4px',
              }}
            >
              Resolution
            </p>
          </div>
          <Select value={resolutionValue} options={resolutionOptions} onChange={handleChangeResolution} />
        </div> */}

        {/* variable section */}
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            marginBottom: '100px',
          }}
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <div css={{display: 'flex'}}>
              <p
                css={{
                  fontSize: fontSize.h7,
                  color: color.textIcon.secondary,
                  marginRight: '4px',
                }}
              >
                Variables
              </p>
              {/* <Tooltip content={''}/> */}
            </div>
            <ButtonAddVariable
              {...{
                disabled: editingVariableList.length === 4,
                addVariableBlock,
                setEditingVariableList,
              }}
            />
          </div>
          {editingVariableList.map((variable, index) => (
            <VariableBlock
              {...{
                key: v4(),
                variable,
                editingVariableList,
                setEditingVariableList,
                dataTypeOptions,
                variableTypeOptions,
                index,
              }}
            />
          ))}
        </div>
        {/* update settings button section */}
      </Scrollbars>
      <div
        css={{
          boxSizing: 'border-box',
          padding: '40px 16px 24px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.90) 18.5%, #FFF 100%)',
        }}
      >
        <Button
          css={{
            margin: '0 auto',
            width: '100%',
          }}
          disabled={updateSettingsButtonDisabled()}
          onClick={handleUpdateSettings}
          btnPadding={'medium'}
        >
          Update Settings
        </Button>
      </div>
    </div>
  )
}

interface VariableBlockProps {
  variable: Variable
  editingVariableList: Variable[]
  setEditingVariableList: Dispatch<SetStateAction<Variable[]>>
  dataTypeOptions: Record<string, string>[]
  variableTypeOptions: Record<string, VariableTypeItem[]>
  index: number
}

const VariableBlock = (props: VariableBlockProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {variable, editingVariableList, setEditingVariableList, dataTypeOptions, variableTypeOptions, index} = props
  // const [dataTypeOptions, setDataTypeOptions] = useState<Record<string, string>[]>([])
  const [dataTypeValue, setDataTypeValue] = useState<Record<string, string>>(
    variable.dataType === VariableDataType.Todo || variable.dataType === VariableDataType.Timer
      ? {
          value: variable.taskId,
          label: `${DataTypeDisplayNameMap[variable.dataType]}: ${variable.taskName}`,
          dataType: variable.dataType,
        }
      : {
          value: variable.dataType,
          label: DataTypeDisplayNameMap[variable.dataType] || variable.dataType,
          dataType: variable.dataType,
        },
  )

  const [variableTypeValue, setVariableTypeValue] = useState<VariableTypeItem>({
    value: variable.variableType,
    label: getVariableDisplayName(variable),
    ...variable,
  })

  const handleClose = () => {
    setEditingVariableList((prev) => {
      const newVariableList = [...prev]
      newVariableList.splice(index, 1)
      return newVariableList
    })
  }

  useMemo(() => {
    if (dataTypeValue.dataType === editingVariableList[index].dataType) return
    const newVariableList = [...editingVariableList]
    if (dataTypeValue.dataType === VariableDataType.Todo || dataTypeValue.dataType === VariableDataType.Timer) {
      newVariableList[index] = {
        dataType: dataTypeValue.dataType,
        variableType: variableTypeOptions[dataTypeValue.value][0].value,
        taskId: dataTypeValue.value,
        taskName: dataTypeValue.label.split(': ')[1].trim(),
      } as Variable
    } else {
      newVariableList[index] = {
        dataType: dataTypeValue.dataType,
        variableType: variableTypeOptions[dataTypeValue.value][0].value,
      } as Variable
    }
    setEditingVariableList(newVariableList)
    setVariableTypeValue(variableTypeOptions[dataTypeValue.value][0])
  }, [dataTypeValue])

  useMemo(() => {
    if (variableTypeValue.value === editingVariableList[index].variableType) return
    const newVariableList = [...editingVariableList]
    newVariableList[index].variableType = variableTypeValue.value
  }, [variableTypeValue])

  return (
    <div
      css={{
        width: '100%',
        padding: '12px',
        borderRadius: '5px',
        border: `1px solid ${color.border._80}`,
        backgroundColor: color.surface.grey.light,
        marginBottom: '8px',
      }}
    >
      <div
        css={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            css={{
              width: '12px',
              height: '12px',
              lineHeight: '12px',
              borderRadius: '50%',
              fontSize: fontSize.h8,
              fontWeight: fontWeight.thick,
              color: color.white,
              backgroundColor: color.surface.dark,
              textAlign: 'center',
              marginRight: '4px',
            }}
          >
            {getVariableCode(index)}
          </p>
          <p
            css={{
              fontSize: fontSize.h7,
              color: color.textIcon.light,
            }}
          >
            Variable {getVariableCode(index)}
          </p>
        </div>
        {RIF(
          editingVariableList.length > 2,
          <ButtonCancel size='small' onClick={handleClose} bgColor={color.surface.grey.light} />,
        )}
      </div>
      <Select
        options={dataTypeOptions}
        value={dataTypeValue}
        onChange={setDataTypeValue}
        css={{
          marginBottom: '8px',
        }}
      />
      <Select
        options={variableTypeOptions[dataTypeValue.value] || []}
        value={variableTypeValue}
        onChange={setVariableTypeValue}
      />
    </div>
  )
}

interface ButtonAddVariableProps {
  disabled: boolean
  addVariableBlock: () => void
}

const ButtonAddVariable = (props: ButtonAddVariableProps) => {
  const {color, fontSize} = selectTheme()

  const {disabled, addVariableBlock} = props

  return (
    <button
      onClick={addVariableBlock}
      disabled={disabled}
      css={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: color.surface.grey.background,
        padding: '4px 8px 4px 4px',
        cursor: disabled ? 'default' : 'pointer',
        ':hover': {
          backgroundColor: disabled ? color.surface.grey.background : darken(color.surface.grey.background, 10),
        },
      }}
    >
      <img src={disabled ? AddGreyIcon : AddBlackIcon} width={14} />
      <p
        css={{
          color: disabled ? color.disabled : color.textIcon.secondary,
          marginLeft: '4px',
          fontSize: fontSize.h8,
        }}
      >
        Add
      </p>
    </button>
  )
}

const DataSetSelection = (props: {
  key: string
  dataSetSelectionKey: string
  index: number
  selected: DataSetSelectionItem
  subjectList: AnalysisSidebarSubject[]
  showCloseButton: boolean
  onUpdate: (key: string, value: DataSetSelectionItem) => void
  onDelete: (key: string) => void
  dataSetSelectionList: DataSetSelectionItem[]
}) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {dataSetSelectionKey, index, selected, subjectList, showCloseButton, onUpdate, onDelete, dataSetSelectionList} =
    props

  const toOption = (subject: AnalysisSidebarSubject) => ({
    value: subject,
    label: subject.name,
  })

  const subjectOptions = subjectList.map(toOption)

  const handleChangeDuration = (e: [DateObject, DateObject] | DateObject) => {
    const dayDiff =
      (dataSetSelectionList[0].duration[1].toUnix() - dataSetSelectionList[0].duration[0].toUnix()) / (60 * 60 * 24)
    if (_.isArray(e) && e.length === 2) {
      const newDayDiff = (e[1].toUnix() - e[0].toUnix()) / (60 * 60 * 24)

      // update all data set selection duration if day diff is different
      if (newDayDiff !== dayDiff) {
        dataSetSelectionList.slice(1).forEach((dataSetSelection) => {
          if (
            (dataSetSelection.duration[1].toUnix() - dataSetSelection.duration[0].toUnix()) / (60 * 60 * 24) !==
            newDayDiff
          ) {
            onUpdate(dataSetSelection.key, {
              ...dataSetSelection,
              duration: [dataSetSelection.duration[0], dataSetSelection.duration[0].add(newDayDiff, 'days')],
            })
          }
        })
      }
      return onUpdate(dataSetSelectionKey, {
        ...selected,
        duration: e,
      })
    } else if (!Array.isArray(e)) {
      const endDate = new DateObject(e).add(dayDiff, 'days')
      return onUpdate(dataSetSelectionKey, {
        ...selected,
        duration: [e, endDate],
      })
    }
  }

  const handleChangeSubject = (e: {value: AnalysisSidebarSubject; label: string}) => {
    onUpdate(dataSetSelectionKey, {
      ...selected,
      subject: e.value,
    })
  }

  return (
    <>
      {RIF(
        index != 0,
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            margin: '8px 0',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Compared with
        </p>,
      )}
      <div
        css={{
          width: '100%',
          borderRadius: '5px',
          border: `1px solid ${color.border._80}`,
          backgroundColor: color.surface.grey.light,
          padding: '12px',
          marginBottom: '8px',
        }}
      >
        {RIF(
          showCloseButton,
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '16px',
            }}
          >
            <p
              css={{
                fontSize: fontSize.h7,
                color: color.textIcon.light,
              }}
            >
              Data Set {getVariableCode(index)}
            </p>
            <ButtonCancel
              size='small'
              onClick={() => onDelete(dataSetSelectionKey)}
              bgColor={color.surface.grey.light}
            />
          </div>,
        )}
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginBottom: '4px',
          }}
        >
          Participant or Group
        </p>
        <Select
          value={toOption(selected.subject)}
          options={subjectOptions}
          onChange={handleChangeSubject}
          css={{
            marginBottom: '16px',
          }}
        />
        <p
          css={{
            fontSize: fontSize.h7,
            color: color.textIcon.secondary,
            marginBottom: '4px',
          }}
        >
          Duration
        </p>
        <div
          css={{
            width: '100%',
            display: 'flex',
            alingnItems: 'center',
          }}
        >
          <DatePicker
            value={index ? selected.duration[0] : selected.duration}
            offsetX={100}
            calendarPosition='bottom-left'
            onChange={handleChangeDuration}
            range={index ? false : true}
            rangeHover={index ? false : true}
            format='MMM D, YYYY'
            maxDate={new Date()}
            style={{
              padding: '12px 12px',
              width: index ? '100px' : '256px',
              height: '38px',
              fontSize: fontSize.h7,
              border: `1px solid ${color.grey_160}`,
              borderRadius: '5px',
              color: color.textIcon.secondary,
              cursor: 'pointer',
            }}
          />
          {RIF(
            index !== 0,
            <p
              css={{
                display: 'flex',
                alignItems: 'center',
                fontSize: fontSize.h7,
                color: color.textIcon.secondary,
                marginLeft: '8px',
              }}
            >
              - {selected.duration[1].format('MMM DD, YYYY')}
            </p>,
          )}
        </div>
        {RIF(
          index !== 0,
          <p
            css={{
              fontSize: fontSize.h8,
              color: color.border._400,
              marginTop: '16px',
              fontWeight: fontWeight.bold,
            }}
          >
            The number of days in both data sets must be the same.
          </p>,
        )}
      </div>
    </>
  )
}

const generateSubjectList = (participantList: ParticipantItem[]) => {
  const subjectList: AnalysisSidebarSubject[] = []
  const groupMap = new Map<string, string[]>()
  participantList.forEach((participant) => {
    participant.tagList?.forEach(({value}) => {
      if (!groupMap.has(value)) groupMap.set(value, [])
      groupMap.get(value)?.push(participant.id)
    })
    subjectList.push({
      name: participant.insignia,
      type: AnalysisSidebarSubjectType.Individual,
      participantId: participant.id,
    })
  })
  groupMap.forEach((group, groupName) => {
    if (group.length < GROUP_MINIMUM_SIZE) return
    subjectList.push({
      name: groupName,
      type: AnalysisSidebarSubjectType.Group,
      participantIds: group,
    })
  })
  return subjectList
}

const findIncomplete = <T, R>(items: T[], iteratee: (item: T) => R): T[] => {
  const values = items.map(iteratee)
  const incompleteIndexes: number[] = []
  for (const [index, value] of Object.entries(values)) {
    const isIncomplete = values.reduce((acc, cur) => (cur == value ? acc + 1 : acc), 0)
    if (isIncomplete % 2 !== 0) {
      incompleteIndexes.push(parseInt(index))
    }
  }
  return _.uniqBy(
    items.filter((_, index) => incompleteIndexes.includes(index)),
    iteratee,
  )
}
