import {SettingsState} from '../store'
import {BetaType} from '../shared/db'

const betaTypeValueMap: Record<BetaType, number> = {
  [BetaType.PublicBeta]: 0,
  [BetaType.ClosedBeta]: 1,
  [BetaType.Internal]: 2,
}
export const isInBetaType = (arg: {betaType: BetaType; settings: SettingsState}): boolean => {
  const {betaType, settings} = arg
  if (!settings.betaType) {
    return false
  }
  return betaTypeValueMap[settings.betaType] >= betaTypeValueMap[betaType]
}
