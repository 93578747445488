import {CheckSuccessIcon} from '../../asset/image'
import {RIF} from '../../lib'
import {Button} from '..'

export interface ProfileInformPagePropsT {
  title: string
  content: string
  subContent?: string
  buttonText: string
  successIcon: boolean
  linkText?: string
  doAction: () => void
}

export const ProfileInformPage = (props: ProfileInformPagePropsT) => {
  const {title, content, buttonText, successIcon, doAction} = props

  return (
    <div
      data-testid='profile_inform_page'
      css={{
        width: '600px',
        margin: '30vh auto',
        padding: '50px',
        position: 'relative',
      }}
    >
      {RIF(successIcon, <img src={CheckSuccessIcon} width='30' height='30' css={{position: 'absolute', top: '0px'}} />)}

      <div css={{fontSize: '20px'}}>{title}</div>
      <div css={{marginTop: '15px'}}>{content}</div>
      <Button onClick={doAction} css={{marginTop: '30px'}}>
        {buttonText}
      </Button>
    </div>
  )
}
