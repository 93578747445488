import Lottie from 'lottie-web'
import {useState, useEffect, createRef, Dispatch, SetStateAction} from 'react'
import loadingAnimation from '../../asset/lotties/loading_animation.json'
import {useNavigate, useParams} from 'react-router-dom'
import {RIF, useDebounce, _, useCurrentProjectState, useCurrentWorkspaceState, RequestResult} from '../../lib'
import {
  selectTheme,
  createDispatchActions,
  selectMethod,
  selectWorkspaces,
  selectTempTaskData,
  selectProfile,
} from '../../store'
import {Button, ProjectFinishSetupPop, ProjectSavePop, ProjectDiscardPop, SlideCheckbox} from '..'
import {CheckDefaultIcon, CheckSuccessIcon, CloudIcon, NoInternetIcon, WarningIcon} from '../../asset/image'
import {TaskStateType, TaskTempStateType, TaskType} from '../../model'

export interface ProjectSetupBarProps {
  aboutProjectValue: Record<string, string>
  instructionValue: string
  contactValue: string
  aboutProjectValueFilled: boolean
  tasksFilled: boolean
  contactValueFilled: boolean
  instructionValueFilled: boolean
  isEditingLiveProject: boolean
  setDisplayWarningDevicePop: Dispatch<
    SetStateAction<Record<'stopwatch_movesense_stream' | 'stopwatch_garmin_stream', boolean>>
  >
  displayFinishPopup: boolean
  setDisplayFinishPopup: Dispatch<SetStateAction<boolean>>
}

export const ProjectSetupBar = (props: ProjectSetupBarProps) => {
  const {pad, color, fontSize} = selectTheme()

  const {
    aboutProjectValue,
    instructionValue,
    contactValue,
    aboutProjectValueFilled,
    tasksFilled,
    contactValueFilled,
    instructionValueFilled,
    isEditingLiveProject,
    setDisplayWarningDevicePop,
    displayFinishPopup,
    setDisplayFinishPopup,
    ...rest
  } = props

  const {doREQUEST_PROJECT_UPDATE, doREQUEST_PROJECT_STATUS_UPDATE, doREQUEST_METHOD_UPDATE}: any =
    createDispatchActions()
  const [requestResultOfStatusUpdate, setRequestResultOfStatusUpdate] = useState<RequestResult | null>(null)

  const navigate = useNavigate()
  const {projectId, project} = useCurrentProjectState()
  const methodState = selectMethod()
  const methodId = methodState?.id as string
  const dexcomIntegrationId = methodState?.dexcomIntegrationId
  const profile = selectProfile()

  const workspacesState = selectWorkspaces()
  const {workspace: currentWorkspace} = useCurrentWorkspaceState()
  const dexcomIntegrationList = currentWorkspace?.dexcomIntegrationList ?? []
  const dexcomIntegration = dexcomIntegrationId ? _.find(dexcomIntegrationList, {id: dexcomIntegrationId}) : undefined

  const dexcomAwaitingApproval =
    !!dexcomIntegrationId && !!dexcomIntegration && !dexcomIntegration?.dexcomApp?.dexcomIntegrationId

  const tempTaskData = selectTempTaskData()
  const movesenseDeviceEnable = methodState.movesenseDeviceEnable
  const tempMovesenseDeviceEnable = tempTaskData.movesenseDeviceEnable
  // todo check_StopwatchMovesenseStream
  const movesenseTasks: TaskStateType[] = _.filter(methodState?.taskList, {
    type: TaskType.StopwatchMovesenseStream || TaskType.StopwatchMovesenseLogData,
  })
  const tempMovesenseTasks: TaskTempStateType[] = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchMovesenseStream || TaskType.StopwatchMovesenseLogData,
  })
  const hasMovesenseTasks: boolean = !!movesenseTasks.length || !!tempMovesenseTasks.length

  const garminStreamEnable = methodState.garminStreamEnable
  const tempGarminStreamEnable = tempTaskData.garminStreamEnable
  const garminStreamTasks: TaskStateType[] = _.filter(methodState?.taskList, {type: TaskType.StopwatchGarminStream})
  const tempGarminStreamTasks: TaskTempStateType[] = _.filter(tempTaskData?.taskList, {
    type: TaskType.StopwatchGarminStream,
  })
  const hasGarminStreamTasks: boolean = !!garminStreamTasks.length || !!tempGarminStreamTasks.length

  const [finishedSetup, setFinishedSetup] = useState(false)
  const [displayUpdatePopup, setDisplayUpdatePopup] = useState(false)
  const [displayDiscardPopup, setDisplayDiscardPopup] = useState(false)
  const [isTemplateProject, setIsTemplateProject] = useState(project?.type === 'template')

  // when all filled then can available to click
  useEffect(() => {
    const allInputCompleted = aboutProjectValueFilled && tasksFilled && contactValueFilled && instructionValueFilled
    setFinishedSetup(allInputCompleted)
  }, [aboutProjectValueFilled, tasksFilled, contactValueFilled, instructionValueFilled])

  const handleSave = () => {
    doREQUEST_PROJECT_UPDATE({
      payload: {
        projectId: projectId,
        name: aboutProjectValue.name || undefined,
        investigator: aboutProjectValue.investigator || undefined,
        organization: aboutProjectValue.organization || undefined,
        description: aboutProjectValue.description || undefined,
        contactDescription: contactValue || undefined,
        participantInstructions: instructionValue || undefined,
        type: isTemplateProject ? 'template' : 'user',
      },
    })

    if (isEditingLiveProject && tempTaskData.dexcomIntegrationId) {
      doREQUEST_METHOD_UPDATE({
        payload: {
          methodId,
          dexcomIntegrationId: tempTaskData.dexcomIntegrationId,
        },
      })
    }
  }

  const handleFinish = () => {
    doREQUEST_PROJECT_STATUS_UPDATE({
      setRequestResult: setRequestResultOfStatusUpdate,
      payload: {
        projectId: projectId,
        status: 'live',
      },
    })
  }

  const handleClickFinish = () => {
    handleSave()
    if (project?.status === 'draft') {
      if (movesenseDeviceEnable && !hasMovesenseTasks)
        // todo check_stopwatch_movesense_stream
        return setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_movesense_stream: true}))
      if (garminStreamEnable && !hasGarminStreamTasks)
        return setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_garmin_stream: true}))
      return setDisplayFinishPopup(true)
    }
    if (project?.status === 'live') {
      if (tempMovesenseDeviceEnable && !hasMovesenseTasks)
        // todo check_stopwatch_movesense_stream
        return setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_movesense_stream: true}))
      if (tempGarminStreamEnable && !hasGarminStreamTasks)
        return setDisplayWarningDevicePop((prev) => ({...prev, stopwatch_garmin_stream: true}))
      return setDisplayUpdatePopup(true)
    }
  }

  const handleDiscard = () => {
    navigate(`/project`)
  }

  useEffect(() => {
    if (requestResultOfStatusUpdate?.success) {
      navigate(`/dashboard/${projectId}`)
    }
  }, [requestResultOfStatusUpdate])

  /* ------------------ auto save ------------------ */
  useEffect(() => {
    localStorage.setItem(
      'labfront_project_content',
      JSON.stringify({
        id: projectId,
        ...aboutProjectValue,
        contactValue,
        instructionValue,
      }),
    )
  }, [aboutProjectValue, contactValue, instructionValue])

  // fake loading animation
  const [isOnline, setIsOnline] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [lessThanMinute, setLessThanMinute] = useState(true)
  const animRef: any = createRef()

  window.ononline = () => setIsOnline(true)
  window.onoffline = () => setIsOnline(false)

  const aboutProjectValueDebounced = useDebounce(aboutProjectValue, 1000)
  const contactValueDebounced = useDebounce(contactValue, 1000)
  const instructionValueDebounced = useDebounce(instructionValue, 1000)
  const aboutProjectValueDebouncedMinute = useDebounce(aboutProjectValue, 60000)
  const contactValueDebouncedMinute = useDebounce(contactValue, 60000)
  const instructionValueDebouncedMinute = useDebounce(instructionValue, 60000)

  useEffect(() => {
    setIsSaving(true)
  }, [aboutProjectValue, contactValue, instructionValue])

  useEffect(() => {
    setIsSaving(false)
    setLessThanMinute(true)
  }, [aboutProjectValueDebounced, contactValueDebounced, instructionValueDebounced])

  useEffect(() => {
    setLessThanMinute(false)
  }, [aboutProjectValueDebouncedMinute, contactValueDebouncedMinute, instructionValueDebouncedMinute])

  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingAnimation,
    })
    return () => Lottie.stop()
  }, [isSaving])

  return (
    <div
      {...rest}
      css={{
        width: '100vw',
        height: '60px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.13)',
        padding: `0 ${pad.xl}`,
        display: isEditingLiveProject ? 'none' : 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: color.white,
        position: 'fixed',
        bottom: '0',
        left: '0',
      }}
    >
      {RIF(displayFinishPopup, <ProjectFinishSetupPop closeAction={setDisplayFinishPopup} doAction={handleFinish} />)}

      {RIF(displayUpdatePopup, <ProjectSavePop closeAction={setDisplayUpdatePopup} doAction={handleFinish} />)}

      {RIF(displayDiscardPopup, <ProjectDiscardPop closeAction={setDisplayDiscardPopup} doAction={handleDiscard} />)}

      <div>
        {RIF(
          isOnline,
          <>
            {RIF(
              isSaving,
              <div css={{display: 'flex', alignItems: 'center', marginLeft: '-12px'}}>
                <div
                  ref={animRef}
                  css={{width: '45px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                />
                <span css={{color: color.grey_400, marginLeft: '-8px'}}>Saving...</span>
              </div>,
            )}
            {RIF(
              !isSaving,
              <div css={{display: 'flex', alignItems: 'center'}}>
                <img src={CloudIcon} width='20' />
                <span css={{margin: '0 8px'}}>Saved</span>
                <span css={{color: color.grey_400}}>Last edit was {lessThanMinute ? 'seconds' : 'minutes'} ago</span>
              </div>,
            )}
          </>,
        )}
        {RIF(
          !isOnline,
          <div css={{display: 'flex', alignItems: 'center'}}>
            <img src={NoInternetIcon} width='20' />
            <span css={{margin: '0 8px'}}>No Internet Connection</span>
          </div>,
        )}
      </div>
      {/* <div css={{
        fontWeight: fontWeight.bold,
        display: 'flex',
        alignItems: 'center'}}>
        <ButtonReverse onClick={handleSave}
          btnPadding='large'
          css={{marginRight: '30px'}}>
          Save and Edit Next Time
        </ButtonReverse>
        <div onClick={() => setDisplayDiscardPopup(true)}
          css={{color: color.warning, cursor: 'pointer'}}>Discard</div>
      </div> */}

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {RIF(
          profile?.email === 'admin@labfront.com',
          <div
            css={{
              marginRight: '20px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              css={{
                color: color.textIcon.light,
                fontSize: fontSize.h6,
                marginRight: '10px',
              }}
            >
              Template Project
            </p>
            <SlideCheckbox
              {...{
                value: isTemplateProject,
                onChange: (e) => setIsTemplateProject(e),
              }}
            />
          </div>,
        )}
        {RIF(
          !finishedSetup,
          <div css={{display: 'flex', alignItems: 'center', marginRight: '25px'}}>
            <img src={CheckDefaultIcon} width='14' />
            <span css={{color: color.grey_400, marginLeft: '6px'}}>Please complete all required fields</span>
          </div>,
        )}
        {RIF(
          finishedSetup && dexcomAwaitingApproval,
          <div css={{display: 'flex', alignItems: 'center', marginRight: '25px'}}>
            <img src={WarningIcon} width='14' />
            <span css={{color: color.warning, marginLeft: '6px'}}>Awaiting for Dexcom's Approval</span>
          </div>,
        )}
        {RIF(
          finishedSetup && !dexcomAwaitingApproval,
          <div css={{display: 'flex', alignItems: 'center', marginRight: '25px'}}>
            <img src={CheckSuccessIcon} width='14' />
            <span css={{color: color.grey_400, marginLeft: '6px'}}>Great! You have completed all required fields!</span>
          </div>,
        )}
        <Button
          disabled={!finishedSetup || dexcomAwaitingApproval}
          onClick={handleClickFinish}
          css={{weight: '110px'}}
          type='button'
        >
          Finish Setup
        </Button>
      </div>
    </div>
  )
}
