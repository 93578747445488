import {PopupSimple} from '..'

export interface ParticipantLimitReachedPopPropsT {
  closeAction: (arg: boolean) => void
}

export const ParticipantLimitReachedPop = (props: ParticipantLimitReachedPopPropsT) => {
  const {closeAction} = props

  return (
    <div
      data-testid='test_delete_participant_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
    >
      <PopupSimple
        {...props}
        {...{
          method: '',
          name: `Participant limit reached`,
          description: 'If you have more than 50 participants, we recommend you duplicate this project.',
          buttonText: 'Okay',
          doAction: () => closeAction(false),
          closeAction,
          buttonColor: 'primary',
        }}
      />
      ,
    </div>
  )
}
