import {MouseEventHandler} from 'react'
import {darken} from '../../lib/color_util'
import {selectTheme} from '../../store'

export type BtnColor = 
  'primary' | 
  'black' | 
  'warning' | 
  'upgrade' | 
  'disabled' | 
  'grey_600' |
  'grey_400'

export enum BtnPaddingSize {
  xl = '16px 32px',
  large = '12px 15px',
  medium = '8px 16px',
  mediumSmall = '6px 12px',
  small = '4px 8px',
}

export interface ButtonProps {
  disabled?: boolean
  btnPadding?: keyof typeof BtnPaddingSize
  btnColor?: BtnColor
  children?: string | JSX.Element
  onClick?: MouseEventHandler
  onMouseOver?: MouseEventHandler
  onMouseOut?: MouseEventHandler
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
  type?: 'submit' | 'button' | 'reset'
  css?: any
}

/* a styled button */
export const Button = (props: ButtonProps) => {
  const {color, fontWeight} = selectTheme()
  const {disabled, btnPadding, btnColor, type, ...rest} = props

  const backgroundColor: {[key: string]: any} = {
    primary: color.primary,
    warning: color.warning,
    upgrade: color.upgrade,
    black: color.black,
  }

  const buttonPadding = btnPadding ? BtnPaddingSize[btnPadding] : '12px 15px'
  const buttonColor = btnColor ? backgroundColor[btnColor] : color.primary

  return (
    <button
      {...rest}
      type={type || 'submit'}
      disabled={disabled}
      css={{
        borderRadius: '5px',
        whiteSpace: 'nowrap',
        border: 'none',
        fontWeight: fontWeight.thick,
        color: color.white,
        padding: buttonPadding,
        background: disabled ? color.disabled : buttonColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          background: disabled ? color.disabled : darken(buttonColor, 10),
        },
        ':active': {
          background: disabled ? color.disabled : darken(buttonColor, 20),
        },
        cursor: disabled ? 'auto' : 'pointer',
      }}
    />
  )
}
