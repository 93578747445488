import {useState} from 'react'

import {selectTheme} from '../../store'

import {RIF} from '../../lib'

import {Button, ButtonText, PageIndicator} from '../atoms'

import TutorialImageA from '../../asset/image/tutorial_a.png'
import TutorialImageB from '../../asset/image/tutorial_b.png'
import TutorialImageC from '../../asset/image/tutorial_c.png'
import TutorialImageD from '../../asset/image/tutorial_d.png'
import TutorialImageE from '../../asset/image/tutorial_e.png'

export const TutorialPopup = (props: any) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()
  const [page, setPage] = useState(1)

  const nextPage = () => {
    if (page < 5) {
      setPage((oldState) => oldState + 1)
    } else {
      if (props.setShowingTutorial) props.setShowingTutorial(false)
    }
  }

  const previousPage = () => {
    if (page > 1) setPage((oldState) => oldState - 1)
  }

  const content1: any = {
    1: 'Customize your data collection',
    2: 'Gather physiological insights using wearables',
    3: 'Use questionnaires to capture participant-reported outcomes',
    4: 'Snapshot moments in time with event markers',
    5: `Now let's build the data collection app`,
  }

  const content2: any = {
    1: 'Labfront can capture a variety of data. Figure out what makes sense for your project.',
    2: 'Utilize daily metrics like activity, steps, and sleep. Or derive your own metrics from raw, beat-to-beat heart rate interval.',
    3: 'Ask any type of question, including qualitative response, journaling, or even scored questionnaires.',
    4: 'Using event markers, capture a precise moment in time, often used in analysis to compare before and after noteworthy events.',
    5: 'This content will be what the participants will see, including instructions and tasks they need to accomplish.',
  }

  const TutorialImage: any = {
    1: TutorialImageA,
    2: TutorialImageB,
    3: TutorialImageC,
    4: TutorialImageD,
    5: TutorialImageE,
  }

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, .25)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          width: '720px',
          minHeight: '450px',
          backgroundColor: '#FFF',
          borderRadius: '5px',
        }}
      >
        {/* top part */}
        <div
          css={{
            width: '100%',
            minHeight: '380px',
            borderBottom: `1px solid ${color.grey_160}`,
            paddingBottom: '30px',
            paddingTop: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img css={{marginBottom: pad.slightlyLarger}} width='440' src={TutorialImage[page]} />
          <p
            css={{
              fontSize: fontSize.h3,
              fontWeight: fontWeight.thick,
              marginBottom: pad.slightlyLarger,
            }}
          >
            {content1[page]}
          </p>
          <p
            css={{
              maxWidth: '75%',
              textAlign: 'center',
              marginBottom: pad.xl,
            }}
          >
            {content2[page]}
          </p>
          <PageIndicator
            {...{
              length: 5,
              index: page,
            }}
          />
        </div>
        {/* bottom part */}
        <div
          css={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px 30px 16px 45px',
          }}
        >
          <a
            target='_blank'
            href='https://www.labfront.com/how-it-works'
            css={{
              fontWeight: fontWeight.thick,
              color: color.primary,
              cursor: 'pointer',
            }}
          >
            Learn how it works
          </a>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {RIF(page > 1, <ButtonText onClick={previousPage} css={{marginRight: pad.xl}} children='Back' />)}
            <Button onClick={nextPage} children={page < 5 ? 'Next' : 'Get started'} />
          </div>
        </div>
      </div>
    </div>
  )
}
