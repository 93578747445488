import { IEntityModel } from './base'
import { IProject } from './project'
import { IWorkspaceCollaborator } from './workspace_collaborator'

export enum ProjectCollaboratorType {
  Admin = 'projectAdmin',
  Editor = 'projectEditor',
  Viewer = 'projectViewer',
}

export enum ProjectCollaboratorRole {
  CollaboratorCreate = 'projectCollaboratorRoleCollaboratorCreate',
  CollaboratorRead = 'projectCollaboratorRoleCollaboratorRead',
  CollaboratorUpdate = 'projectCollaboratorRoleCollaboratorUpdate',
  CollaboratorDelete = 'projectCollaboratorRoleCollaboratorDelete',

  ProjectRead = 'projectCollaboratorRoleProjectRead',
  ProjectUpdate = 'projectCollaboratorRoleProjectUpdate',
  ProjectStatusUpdate = 'projectCollaboratorRoleProjectStatusUpdate',

  TaskCreate = 'projectCollaboratorRoleTaskCreate',
  TaskUpdate = 'projectCollaboratorRoleTaskUpdate',
  TaskDelete = 'projectCollaboratorRoleTaskDelete',

  ParticipantCreate = 'projectCollaboratorRoleParticipantCreate',
  ParticipantUpdate = 'projectCollaboratorRoleParticipantUpdate',
  ParticipantDelete = 'projectCollaboratorRoleParticipantDelete',

  MethodCreate = 'projectCollaboratorRoleMethodCreate',
  MethodUpdate = 'projectCollaboratorRoleMethodUpdate',
  MethodDelete = 'projectCollaboratorRoleMethodDelete',

  BatchCreate = 'projectCollaboratorRoleBatchCreate',
  BatchUpdate = 'projectCollaboratorRoleBatchUpdate',
  BatchDelete = 'projectCollaboratorRoleBatchDelete',
}

export const ProjectCollaboratorPermissionLayout: {
  [key in ProjectCollaboratorType]: {
    [key in ProjectCollaboratorRole]: boolean
  }
} = {
  [ProjectCollaboratorType.Admin]: {
    [ProjectCollaboratorRole.CollaboratorCreate]: true,
    [ProjectCollaboratorRole.CollaboratorRead]: true,
    [ProjectCollaboratorRole.CollaboratorUpdate]: true,
    [ProjectCollaboratorRole.CollaboratorDelete]: true,
    [ProjectCollaboratorRole.ProjectRead]: true,
    [ProjectCollaboratorRole.ProjectUpdate]: true,
    [ProjectCollaboratorRole.ProjectStatusUpdate]: true,
    [ProjectCollaboratorRole.TaskCreate]: true,
    [ProjectCollaboratorRole.TaskUpdate]: true,
    [ProjectCollaboratorRole.TaskDelete]: true,
    [ProjectCollaboratorRole.ParticipantCreate]: true,
    [ProjectCollaboratorRole.ParticipantUpdate]: true,
    [ProjectCollaboratorRole.ParticipantDelete]: true,
    [ProjectCollaboratorRole.MethodCreate]: true,
    [ProjectCollaboratorRole.MethodUpdate]: true,
    [ProjectCollaboratorRole.MethodDelete]: true,
    [ProjectCollaboratorRole.BatchCreate]: true,
    [ProjectCollaboratorRole.BatchUpdate]: true,
    [ProjectCollaboratorRole.BatchDelete]: true,
  },
  [ProjectCollaboratorType.Editor]: {
    [ProjectCollaboratorRole.CollaboratorCreate]: false,
    [ProjectCollaboratorRole.CollaboratorRead]: true,
    [ProjectCollaboratorRole.CollaboratorUpdate]: false,
    [ProjectCollaboratorRole.CollaboratorDelete]: false,
    [ProjectCollaboratorRole.ProjectRead]: true,
    [ProjectCollaboratorRole.ProjectUpdate]: false,
    [ProjectCollaboratorRole.ProjectStatusUpdate]: false,
    [ProjectCollaboratorRole.TaskCreate]: true,
    [ProjectCollaboratorRole.TaskUpdate]: true,
    [ProjectCollaboratorRole.TaskDelete]: true,
    [ProjectCollaboratorRole.ParticipantCreate]: true,
    [ProjectCollaboratorRole.ParticipantUpdate]: true,
    [ProjectCollaboratorRole.ParticipantDelete]: true,
    [ProjectCollaboratorRole.MethodCreate]: true,
    [ProjectCollaboratorRole.MethodUpdate]: true,
    [ProjectCollaboratorRole.MethodDelete]: true,
    [ProjectCollaboratorRole.BatchCreate]: true,
    [ProjectCollaboratorRole.BatchUpdate]: true,
    [ProjectCollaboratorRole.BatchDelete]: true,
  },
  [ProjectCollaboratorType.Viewer]: {
    [ProjectCollaboratorRole.CollaboratorCreate]: false,
    [ProjectCollaboratorRole.CollaboratorRead]: true,
    [ProjectCollaboratorRole.CollaboratorUpdate]: false,
    [ProjectCollaboratorRole.CollaboratorDelete]: false,
    [ProjectCollaboratorRole.ProjectRead]: true,
    [ProjectCollaboratorRole.ProjectUpdate]: false,
    [ProjectCollaboratorRole.ProjectStatusUpdate]: false,
    [ProjectCollaboratorRole.TaskCreate]: false,
    [ProjectCollaboratorRole.TaskUpdate]: false,
    [ProjectCollaboratorRole.TaskDelete]: false,
    [ProjectCollaboratorRole.ParticipantCreate]: false,
    [ProjectCollaboratorRole.ParticipantUpdate]: false,
    [ProjectCollaboratorRole.ParticipantDelete]: false,
    [ProjectCollaboratorRole.MethodCreate]: false,
    [ProjectCollaboratorRole.MethodUpdate]: false,
    [ProjectCollaboratorRole.MethodDelete]: false,
    [ProjectCollaboratorRole.BatchCreate]: false,
    [ProjectCollaboratorRole.BatchUpdate]: false,
    [ProjectCollaboratorRole.BatchDelete]: false,
  },
} as const

export interface IProjectCollaborator extends IEntityModel {
  workspaceCollaboratorId: string
  workspaceCollaborator?: IWorkspaceCollaborator
  projectId: string
  project?: IProject
  type: ProjectCollaboratorType
}
