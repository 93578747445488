import {Plot, Box, BoxConfig, Scatter, ScatterConfig, Violin, ViolinConfig} from '@ant-design/charts'
import {Chart, Scale} from '@antv/g2/lib'
import {Tooltip} from '@ant-design/plots'
import {TimeSeriesDataSource} from '../../../model/chart'
import {useEffect, useMemo, useRef, useState} from 'react'
import _ from 'lodash'
import {selectTheme} from '../../../store'
import {Interpolation} from '@emotion/react'
import {Theme} from 'react-select'

export interface GenericBoxChartConfig {
  dataName: string
  dataUnit: string
  chartConfig: ChartConfig
  chartCardConfig: ChartCardConfig
}

export interface GenericBoxChartCardProps {
  config: GenericBoxChartConfig
  dataSource?: number[]
  chartReadyCallback?: (chart: Chart) => void
  beforeUpdateDataCallback?: (data: TimeSeriesDataSource[]) => void
}

export interface ChartConfig {
  theme?: object
  tooltip?: false | Tooltip
}

export interface ChartCardConfig {
  height: string
  width: string
  title: string
  hintDescription: string
}

export const ViolinBoxChart = (props: GenericBoxChartCardProps) => {
  const {config, dataSource} = props
  const {dataName, dataUnit, chartCardConfig} = config
  const {height, width, title, hintDescription} = chartCardConfig
  const {color, fontWeight} = selectTheme()

  const violinChartInstance = useRef<Chart>()
  const boxChartInstance = useRef<Chart>()
  const violinYScale = useRef<Scale>()
  const [boxPlotData, setBoxPlotData] = useState<number[]>([])

  function listsAreEqual(list1: number[], list2: number[]) {
    if (list1.length !== list2.length) return false
    for (let i = 0; i < list1.length; i++) {
      if (list1[i] !== list2[i]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    const filteredData = violinChartInstance.current?.['filteredData'][0]
    if (filteredData && filteredData.low !== undefined) {
      const boxData = [filteredData.low, filteredData.q1, filteredData.median[0], filteredData.q3, filteredData.high]
      if (!listsAreEqual(boxData, boxPlotData)) {
        violinYScale.current = violinChartInstance.current?.getScale('violinY')
        setBoxPlotData(boxData)
      }
    } else if (boxPlotData.length > 0) {
      setBoxPlotData([])
    }
  }, [violinChartInstance.current])

  const onViolinChartReady: (chart: Plot<any>) => void = (chart) => {
    violinChartInstance.current = chart.chart
  }

  const chartStyle: Interpolation<Theme> = {
    position: 'absolute',
    width: '35%',
    height: '85%',
  }

  const violinConfig: ViolinConfig = {
    data:
      dataSource?.map((value) => {
        return {x: 'x', y: value}
      }) ?? [],
    xField: 'x',
    yField: 'y',
    xAxis: {
      label: null,
    },
    animation: false,
    onReady: onViolinChartReady,
    legend: false,
    box: false,
    tooltip: {
      title: 'Box plot data',
      position: 'right',
    },
  }

  const VilolinPlot = () => {
    return (
      <div css={chartStyle}>
        <Violin {...violinConfig} />
      </div>
    )
  }

  useEffect(() => {
    if (boxChartInstance.current) {
      console.log('boxPlot change data')
      if (violinYScale.current) {
        boxChartInstance.current.scale('y', {min: violinYScale.current.min, max: violinYScale.current.max, nice: true})
      }
      boxChartInstance.current.changeData([{x: 'x', y: boxPlotData ?? []}])
    }
  }, [boxPlotData, dataSource])

  const onBoxChartReady: (chart: Plot<any>) => void = (chart) => {
    boxChartInstance.current = chart.chart
  }

  const boxConfig: BoxConfig = {
    data: [],
    xField: 'x',
    yField: 'y',
    boxStyle: {
      lineWidth: 1.5,
      stroke: 'green',
      fill: '#FFFFFF',
      fillOpacity: 1,
    },
    xAxis: {
      label: null,
    },
    animation: false,
    onReady: onBoxChartReady,
  }

  const BoxPlot = () => {
    return (
      <div css={chartStyle}>
        <Box {...boxConfig} />
      </div>
    )
  }

  const titleSection = (
    <div
      css={{
        width: '100%',
        padding: '16px 16px 0px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontWeight: fontWeight.base,
        fontSize: '18px',
      }}
    >
      <p
        css={{
          fontWeight: fontWeight.thick,
          marginRight: '6px',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </p>
      {/* <Tooltip content={''} /> */}

      {/* title right end side optional components */}
      <div
        css={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          fontWeight: fontWeight.base,
          fontSize: '18px',
          zIndex: 5,
        }}
      >
        {/* filter? */}
      </div>
    </div>
  )

  const ViolinBoxPlot = (
    <div
      css={{
        width: '170px',
        height: '100%',
        display: 'flex',
        background: '#F7F8F8',
        borderRadius: '5px',
        padding: '10px',
        // border: '1px solid red',
      }}
    >
      <BoxPlot />
      <VilolinPlot />
    </div>
  )

  const valueDescription = (
    <div
      css={{
        width: '60%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        css={{
          fontWeight: fontWeight.base,
          whiteSpace: 'nowrap',
          fontSize: '12px',
          // border: '1px solid red',
        }}
      >
        <p
          css={{
            whiteSpace: 'nowrap',
            marginBottom: '5px',
            color: 'gray',
          }}
        >
          {dataName}
        </p>
        <p
          css={{
            fontWeight: fontWeight.thick,
            marginBottom: '5px',
            fontSize: '24px',
          }}
        >
          {/* display median value*/}
          {boxPlotData[2] ?? 'N/A'}
        </p>
        <p
          css={{
            color: 'gray',
          }}
        >
          {dataUnit}
        </p>

        <p
          css={{
            whiteSpace: 'nowrap',
            marginTop: '25px',
            color: 'gray',
          }}
        >
          n = {dataSource?.length ?? 0}
        </p>
      </div>
    </div>
  )

  const contentSection = (
    <div
      css={{
        padding: '16px',
        height: '85%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        position: 'relative',
        zIndex: 0,
      }}
    >
      {valueDescription}
      {ViolinBoxPlot}
    </div>
  )

  return (
    <div
      css={{
        position: 'relative',
        background: color.white,
        height: height,
        width: width,
        borderRadius: '5px',
        boxShadow: '0px 4px 12px 0px rgba(212, 212, 212, 0.25)',
        margin: '12px',
      }}
    >
      {titleSection}
      {contentSection}
    </div>
  )
}
