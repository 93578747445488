export enum ChartDataType {
  DailySteps = 'dailySteps',
  DailyStress = 'dailyStress',
  DailyStressValue = 'dailyStressValue',
  DailySleeps = 'dailySleeps',
  DailyHeartRate = 'dailyHeartRate',
  DailyCalories = 'dailyCalories',
  DailyHrSampleCount = 'dailyHrSampleCount',
}

export enum StressType {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}

export enum StressValueType {
  Avg = 'Avg.',
  Max = 'Max',
}

export enum HeartRateType {
  Max = 'Max HR',
  Min = 'Min HR',
  Resting = 'Resting HR',
  Avg = 'Avg. HR',
}

export enum CaloriesType {
  Active = 'Active',
  BMR = 'BMR',
}

export enum ArrayDescriptionType {
  Mean = 'Mean',
  Max = 'Max',
  Min = 'Min',
}
