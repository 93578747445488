import {cloneDeep} from 'lodash'
import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {ChunkMetaData, MetaDataMap} from '../../../shared/mongo/localdate_device_logdata'
import {TimeSeriesDataSource} from '../../../model/chart'
import {DexcomEventData} from '../../../shared/mongo/localdate_dexcom_data'
import {GarminDeviceLogDataType, DexcomDeviceDataType} from '../../../shared/mongo'
import _ from 'lodash'
import {AnalysisDataType} from '../../../shared/mongo'
import {DB_NAME, DbOperationResult} from '../../db'
import { GarminConnectHookType } from '../../../shared/api'
import { VisualizerGraphDataType } from '../../../shared/db'

export enum ParticipantTimeSeriesDataActionType {
  PARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET = 'PARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET',
  PARTICIPANT_GARMIN_TIMESERIES_DATA_SET = 'PARTICIPANT_GARMIN_TIMESERIES_DATA_SET',
  PARTICIPANT_DEXCOM_DATA_SET = 'PARTICIPANT_DEXCOM_DATA_SET',
  PARTICIPANT_ANALYSIS_DATA_SET = 'PARTICIPANT_ANALYSIS_DATA_SET',
  PARTICIPANT_ANALYSIS_DATA_SET_EMPTY = 'PARTICIPANT_ANALYSIS_DATA_SET_EMPTY',
  PARTICIPANT_TIMESERIES_DATA_DELETE = 'PARTICIPANT_GARMIN_DIRECT_DATA_DELETE',
}
export const doPARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET = createAction(
  ParticipantTimeSeriesDataActionType.PARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET,
)
export const doPARTICIPANT_GARMIN_TIMESERIES_DATA_SET = createAction(
  ParticipantTimeSeriesDataActionType.PARTICIPANT_GARMIN_TIMESERIES_DATA_SET,
)
export const doPARTICIPANT_ANALYSIS_DATA_SET = createAction(
  ParticipantTimeSeriesDataActionType.PARTICIPANT_ANALYSIS_DATA_SET,
)
export const doPARTICIPANT_ANALYSIS_DATA_SET_EMPTY = createAction(
  ParticipantTimeSeriesDataActionType.PARTICIPANT_ANALYSIS_DATA_SET_EMPTY,
)
export const doPARTICIPANT_DEXCOM_DATA_SET = createAction(
  ParticipantTimeSeriesDataActionType.PARTICIPANT_DEXCOM_DATA_SET,
)
export const doPARTICIPANT_TIMESERIES_DATA_DELETE = createAction(
  ParticipantTimeSeriesDataActionType.PARTICIPANT_TIMESERIES_DATA_DELETE,
)

export interface TimeSeriesDataState {
  [participantId: string]: ParticipantTimeSeriesDataState
}

export interface ParticipantTimeSeriesDataState {
  digestCoveredIndexes?: number[]
  digest?: Record<GarminDeviceLogDataType, number[]>
  data?: {
    [dataType in VisualizerGraphDataType]?: Record<string, boolean>
  }
}

export interface TimeSeriesData {
  timeSeriesRaw: TimeSeriesDataSource[]
  timeSeriesForPlot: TimeSeriesDataSource[]
  timezoneRef: string
  timeOffsetRef: number
}

export interface TransformedGarminDeviceLogData extends TimeSeriesData {
  metaDataMap: MetaDataMap<ChunkMetaData>
  startChunkUtcIndex: number
}

export interface GlucoseTimeSeriesData {
  rawData: TimeSeriesDataSource[]
  timeSeriesForPlot: TimeSeriesDataSource[]
  events?: DexcomEventData[]
}

interface RootState {
  participantTimeSeriesData: TimeSeriesDataState
}
/* selector */
export const selectParticipantTimeSeriesData = () => {
  return useSelector((state: RootState) => state.participantTimeSeriesData)
}

export const participantTimeSeriesDataActionCreators = {
  doPARTICIPANT_GARMIN_TIMESERIES_DATA_SET,
  doPARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET,
  doPARTICIPANT_ANALYSIS_DATA_SET,
  doPARTICIPANT_ANALYSIS_DATA_SET_EMPTY,
  doPARTICIPANT_DEXCOM_DATA_SET,
  doPARTICIPANT_TIMESERIES_DATA_DELETE,
}

export const participantTimeSeriesDataDefaultState: TimeSeriesDataState = {}

type Action =
  | {
      type: ParticipantTimeSeriesDataActionType.PARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET
      payload: {
        projectDataDigest: {
          projectId: string
          coveredYYMMIndex: number[]
          participantDataDigestMap: {
            [participantId: string]: {
              garminDeviceDataDatesByType?: Record<GarminDeviceLogDataType, number[]>
            }
          }
        }
      }
    }
  | {
      type: ParticipantTimeSeriesDataActionType.PARTICIPANT_GARMIN_TIMESERIES_DATA_SET
      payload: {
        participantId: string
        yymmddIndex: number
        dbOperationResultList: DbOperationResult<VisualizerGraphDataType>[]
      }
    }
  | {
      type: ParticipantTimeSeriesDataActionType.PARTICIPANT_ANALYSIS_DATA_SET
      payload: {
        dbOperationResultList: DbOperationResult<VisualizerGraphDataType>[]
      }
    }
  | {
      type: ParticipantTimeSeriesDataActionType.PARTICIPANT_ANALYSIS_DATA_SET_EMPTY
      payload: {
        participantId: string
        yymmddIndex: number
        dataType: VisualizerGraphDataType
      }
    }
  | {
      type: ParticipantTimeSeriesDataActionType.PARTICIPANT_DEXCOM_DATA_SET
      payload: {
        participantId: string
        yymmddIndexes: number[]
        dbOperationResultList: DbOperationResult<VisualizerGraphDataType>[]
      }
    }
  | {
      type: ParticipantTimeSeriesDataActionType.PARTICIPANT_TIMESERIES_DATA_DELETE
      payload: {
        participantIdList: string[]
      }
    }

export const participantTimeSeriesDataReducer = (
  state: TimeSeriesDataState = participantTimeSeriesDataDefaultState,
  {type, payload}: Action,
) => {
  const newState = cloneDeep(state)
  switch (type) {
    case ParticipantTimeSeriesDataActionType.PARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectDataDigest: v.object({
            projectId: v.string().exist(),
            coveredYYMMIndex: v.array().items(v.number()).exist(),
            participantDataDigestMap: v.object().exist(),
          }),
        }),
      })

      for (const [participantId, digestData] of Object.entries(payload.projectDataDigest.participantDataDigestMap)) {
        if (!newState[participantId]) {
          newState[participantId] = {
            digestCoveredIndexes: payload.projectDataDigest.coveredYYMMIndex,
            digest: digestData.garminDeviceDataDatesByType,
          }
        } else {
          newState[participantId].digestCoveredIndexes = Array.from(
            new Set(newState[participantId].digestCoveredIndexes?.concat(payload.projectDataDigest.coveredYYMMIndex)),
          )

          if (digestData.garminDeviceDataDatesByType) {
            if (!newState[participantId].digest) {
              newState[participantId].digest = digestData.garminDeviceDataDatesByType
            } else {
              const participantDigest = newState[participantId]?.digest

              if (participantDigest) {
                for (const [dataType, dataDates] of Object.entries(digestData.garminDeviceDataDatesByType)) {
                  const dataTypeEnum = Object.values(GarminDeviceLogDataType).find((value) => value === dataType)
                  if (dataTypeEnum) {
                    if (!participantDigest[dataTypeEnum]) {
                      participantDigest[dataTypeEnum] = dataDates
                    } else {
                      participantDigest[dataTypeEnum] = Array.from(
                        new Set(participantDigest[dataTypeEnum].concat(dataDates)),
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }

      return newState
    }

    case ParticipantTimeSeriesDataActionType.PARTICIPANT_GARMIN_TIMESERIES_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          participantId: v.string().uuid().required(),
          yymmddIndex: v.number().required(),
          dbOperationResultList: v.array().items(
            v.object({
              dbName: v.string().required(),
              participantId: v.string().uuid().required(),
              dataType: v.string().required(),
              yymmddIndex: v.number().required(),
              operation: v.string().required(),
              result: v.bool().required(),
            }),
          ),
        }),
      })

      updateTimeSeriesDataState(newState, payload.dbOperationResultList)
      return newState
    }

    case ParticipantTimeSeriesDataActionType.PARTICIPANT_ANALYSIS_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          dbOperationResultList: v.array().items(
            v.object({
              dbName: v.string().required(),
              participantId: v.string().uuid().required(),
              dataType: v.string().required(),
              yymmddIndex: v.number().required(),
              operation: v.string().required(),
              result: v.bool().required(),
            }),
          ),
        }),
      })

      updateTimeSeriesDataState(newState, payload.dbOperationResultList)
      return newState
    }

    case ParticipantTimeSeriesDataActionType.PARTICIPANT_ANALYSIS_DATA_SET_EMPTY: {
      assertPartialSchema({
        payload,
        schema: v.object({
          data: v.array().items(
            v.object({
              participantId: v.string().uuid().required(),
              dataType: v.string().required(),
            }),
          ),
        }),
      })

      const {participantId, yymmddIndex, dataType} = payload
      updateEmptyTimeSeriesData(newState, participantId, dataType, [yymmddIndex])
      return newState
    }

    case ParticipantTimeSeriesDataActionType.PARTICIPANT_DEXCOM_DATA_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          participantId: v.string().uuid().required(),
          yymmddIndexes: v.array().items(v.number()).required(),
          dbOperationResultList: v.array().items(
            v.object({
              dbName: v.string().required(),
              participantId: v.string().uuid().required(),
              dataType: v.string().required(),
              yymmddIndex: v.number().required(),
              operation: v.string().required(),
              result: v.bool().required(),
            }),
          ),
        }),
      })

      if (payload.dbOperationResultList.length > 0) {
        updateTimeSeriesDataState(newState, payload.dbOperationResultList)
      } else {
        const {participantId, yymmddIndexes} = payload
        updateEmptyTimeSeriesData(newState, participantId, VisualizerGraphDataType.DexcomBloodGlucose, yymmddIndexes)
      }
      return newState
    }

    case ParticipantTimeSeriesDataActionType.PARTICIPANT_TIMESERIES_DATA_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          participantIdList: v.array().items(v.string()),
        }),
      })

      for (const participantId of payload.participantIdList) {
        if (newState[participantId]) delete newState[participantId]
      }

      return newState
    }
    default:
      return state
  }
}

async function updateTimeSeriesDataState(state: TimeSeriesDataState, dbOperationResultList: DbOperationResult<VisualizerGraphDataType>[]) {
  dbOperationResultList.forEach((dbResult) => {
    const {dbName, participantId, dataType, yymmddIndex, result} = dbResult

    if (dbName == DB_NAME.TimeSeriesForChart) {
      if (!state[participantId]) {
        state[participantId] = {}
      }
      if (!state[participantId].data) {
        state[participantId].data = {}
      }
      const participantData = state[participantId].data
      if (participantData) {
        if (dataType) {
          if (!participantData[dataType]) {
            participantData[dataType] = {}
          }
          const typeData = participantData[dataType]
          if (typeData) {
            typeData[yymmddIndex] = result
          }
        }
      }
    }
  })
}

function updateEmptyTimeSeriesData(
  state: TimeSeriesDataState,
  participantId: string,
  dataType: VisualizerGraphDataType,
  yymmddIndexes: number[],
) {
  if (!state[participantId]) {
    state[participantId] = {}
  }
  if (!state[participantId].data) {
    state[participantId].data = {}
  }
  const participantData = state[participantId].data
  if (participantData) {
    if (!participantData[dataType]) {
      participantData[dataType] = {}
    }
    const typeData = participantData[dataType]
    if (typeData) {
      for (const yymmddIndex of yymmddIndexes) {
        typeData[`${yymmddIndex}`] = false
      }
    }
  }
}
