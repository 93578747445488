import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import { 
  doREQUEST_ERROR, 
  doREQUEST_COMPLETE,
  doWORKSPACE_DEXCOM_INTEGRATION_DELETE,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_DEXCOM_INTEGRATION_DELETE = 'REQUEST_DEXCOM_INTEGRATION_DELETE'
export const doREQUEST_DEXCOM_INTEGRATION_DELETE = createDoRequestAction(REQUEST_DEXCOM_INTEGRATION_DELETE)

export const requestDexcomIntegrationDeleteActionCreators = {
  doREQUEST_DEXCOM_INTEGRATION_DELETE,
}

export interface RequestDexcomIntegrationDeletePayload {
  payload: {
    requestId: string
    dexcomIntegrationId: string
    workspaceId: string
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestDexcomIntegrationDelete({payload, setRequestResult}: RequestDexcomIntegrationDeletePayload): SagaIterator {
  debug('saga*requestDexcomIntegrationDelete')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        dexcomIntegrationId: v.string().exist(),
        workspaceId: v.string().uuid().exist(),
      })
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_DEXCOM_INTEGRATION_DELETE,
        requestId: payload.requestId,
        error,
      }, setRequestResult)
    )
  }

  const {
    dexcomIntegrationId,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/dexcom-integration-delete`,
    accessToken,
    data: {
      dexcomIntegrationId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_DEXCOM_INTEGRATION_DELETE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/dexcom-integration-delete',
        ...result,
        error,
      }, setRequestResult)
    )
  }

  yield(put(
    doWORKSPACE_DEXCOM_INTEGRATION_DELETE({...payload})
  ))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_DEXCOM_INTEGRATION_DELETE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* dexcomIntegrationDeleteSaga() {
  yield takeEvery(REQUEST_DEXCOM_INTEGRATION_DELETE as any, requestDexcomIntegrationDelete)
}