import { 
  VariableDataType,
  VariableMap,
  TodoVariableType,
  TimerVariableType,
  GarminConnectSleepVariableType,
  GarminConnectActivityVariableType,
  GarminConnectHeartRateVariableType,
  GarminConnectStressVariableType,
  GarminDeviceHrvVariableType,
  GarminDeviceActigraphySleepVariableType,
} from '../shared/analysis'

export const DataTypeDisplayNameMap: Record<VariableDataType, string> = {
  [VariableDataType.Todo]: 'Todo',
  [VariableDataType.Timer]: 'Timer',
  [VariableDataType.GarminConnectSleep]: 'Garmin Connect Sleep',
  [VariableDataType.GarminConnectActivity]: 'Garmin Connect Activity',
  [VariableDataType.GarminConnectHeartRate]: 'Garmin Connect Heart Rate',
  [VariableDataType.GarminConnectStress]: 'Garmin Connect Stress',
  [VariableDataType.GarminDeviceHrv]: 'Garmin Device HRV',
  [VariableDataType.GarminDeviceActigraphySleep]: 'Garmin Device Actigraphy Sleep',
}
export const CorrelationVariableTypeDisplayNameMap: {
  [K in keyof VariableMap]: Record<keyof VariableMap[K], string>
} = {
  [VariableDataType.Todo]: {
    [TodoVariableType.Occurred]: 'Event Occurred',
    [TodoVariableType.Count]: 'Number of Event Occurrences'
  },
  [VariableDataType.Timer]: {
    [TimerVariableType.Occurred]: 'Event Occurred',
    [TimerVariableType.Count]: 'Number of Event Occurrences',
    [TimerVariableType.TotalDuration]: 'Total Timed Duration',
    [TimerVariableType.AverageDuration]: 'Average Timed Duration',
  },
  [VariableDataType.GarminConnectSleep]: {
    [GarminConnectSleepVariableType.Score]: 'Sleep Score',
    [GarminConnectSleepVariableType.Duration]: 'Sleep Duration',
    [GarminConnectSleepVariableType.LightStageRatio]: 'Ratio of Light Sleep',
    [GarminConnectSleepVariableType.DeepStageRatio]: 'Ratio of Deep Sleep',
    [GarminConnectSleepVariableType.RemStageRatio]: 'Ratio of REM Sleep',
    [GarminConnectSleepVariableType.AwakeStageRatio]: 'Ratio of Awake time during Sleep',
    [GarminConnectSleepVariableType.LightStageCount]: 'Light Sleep Count',
    [GarminConnectSleepVariableType.DeepStageCount]: 'Deep Sleep Count',
    [GarminConnectSleepVariableType.AwakeStageCount]: 'Awake Count during Sleep',
    [GarminConnectSleepVariableType.StartTime]: 'Sleep Start Time',
    [GarminConnectSleepVariableType.WakeTime]: 'Wake Time',
    [GarminConnectSleepVariableType.BodyBatteryRecovery]: 'Body Battery Recovery Amount',
  },
  [VariableDataType.GarminConnectActivity]: {
    [GarminConnectActivityVariableType.Steps]: 'Total Steps',
    [GarminConnectActivityVariableType.SedentaryMovementRatio]: 'Sedentary Ratio',
    [GarminConnectActivityVariableType.ModerateIntensityDuration]: 'Moderate Intensity Duration',
    [GarminConnectActivityVariableType.VigorousIntensityDuration]: 'Vigorous Intensity Duration',
  },
  [VariableDataType.GarminConnectHeartRate]: {
    [GarminConnectHeartRateVariableType.RestingBPM]: 'Resting Heart Rate',
    [GarminConnectHeartRateVariableType.AverageBPM]: 'Average Heart Rate',
    [GarminConnectHeartRateVariableType.MaxBPM]: 'Maximum Heart Rate',
    [GarminConnectHeartRateVariableType.MinBPM]: 'Minimum Heart Rate Value',
  },
  [VariableDataType.GarminConnectStress]: {
    [GarminConnectStressVariableType.AverageLevel]: 'Average Stress Level',
    [GarminConnectStressVariableType.MaxLevel]: 'Maximum Stress Level',
    [GarminConnectStressVariableType.LowDuration]: 'Low Stress Duration',
    [GarminConnectStressVariableType.MediumDuration]: 'Medium Stress Duration',
    [GarminConnectStressVariableType.HighDuration]: 'High Stress Duration',
  },
  [VariableDataType.GarminDeviceHrv]: {
    [GarminDeviceHrvVariableType.MRR]: 'MRR',
    [GarminDeviceHrvVariableType.SDRR]: 'SDRR',
    [GarminDeviceHrvVariableType.RMSSD]: 'RMSSD',
    [GarminDeviceHrvVariableType.PRR50]: 'pRR50',
    [GarminDeviceHrvVariableType.TP]: 'TP',
    [GarminDeviceHrvVariableType.VLF]: 'VLF',
    [GarminDeviceHrvVariableType.LF]: 'LF',
    [GarminDeviceHrvVariableType.HF]: 'HF',
    [GarminDeviceHrvVariableType.LFHF]: 'LF/HF',
  },
  [VariableDataType.GarminDeviceActigraphySleep]: {
    [GarminDeviceActigraphySleepVariableType.OnsetTime]: 'Sleep Onset Time',
    [GarminDeviceActigraphySleepVariableType.OffsetTime]: 'Sleep Offset Time',
    [GarminDeviceActigraphySleepVariableType.BedTime]: 'Bed Time',
    [GarminDeviceActigraphySleepVariableType.GetUpTime]: 'Get Up Time',
    [GarminDeviceActigraphySleepVariableType.TotalInBedDuration]: 'Total Time in Bed',
    [GarminDeviceActigraphySleepVariableType.TotalSleepDuration]: 'Total Sleep Duration',
    [GarminDeviceActigraphySleepVariableType.OnsetOffsetInterval]: 'Sleep Onset Offset Interval',
    [GarminDeviceActigraphySleepVariableType.SleepOnsetLatency]: 'Sleep Onset Latency',
    [GarminDeviceActigraphySleepVariableType.WakeAfterSleepOnset]: 'Wake After Sleep Onset',
    [GarminDeviceActigraphySleepVariableType.SleepEfficiency]: 'Sleep Efficiency',
    [GarminDeviceActigraphySleepVariableType.WakeEpisodeCount]: 'Wake Episode Count',
  },
}