import {useEffect, Dispatch, SetStateAction, useState} from 'react'
import {Controller} from 'react-hook-form'
import {selectTheme, selectMethod} from '../../store'
import {v, useForm, RIF, useCurrentWorkspaceAccountPlan, useCurrentProjectState} from '../../lib'
import {SlideCheckbox, Checkbox, Select, Tooltip} from '..'
import {WarningIcon, DataUploadIcon, TouchIcon} from '../../asset/image'
import GarminCompanionAppImg from '../../asset/image/garmin_companion_app.png'
import {GarminDeviceSpecCopy} from '../../model'
import {components, OptionProps} from 'react-select'

export interface GarminDevicePanelProps {
  garminDeviceSpecCopy: any
  setGarminDeviceSpecCopy: Dispatch<SetStateAction<any>>
  garminDeviceSectionEnable: boolean
  setGarminDeviceSectionEnable: Dispatch<SetStateAction<boolean>>
  garminStreamEnable: boolean
  setGarminStreamEnable: Dispatch<SetStateAction<boolean>>
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

export const GarminDevicePanel = (props: GarminDevicePanelProps) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()

  const {
    garminDeviceSpecCopy,
    setGarminDeviceSpecCopy,
    garminDeviceSectionEnable,
    setGarminDeviceSectionEnable,
    garminStreamEnable,
    setGarminStreamEnable,
    setDisplayChangePlanPage,
  } = props

  const {
    heartRateEnable,
    heartRateSampleRate,
    stressEnable,
    stressSampleRate,
    stepsEnable,
    respirationEnable,
    respirationSampleRate,
    pulseOxEnable,
    pulseOxSampleRate,
    bbiEnable,
    zeroCrossingEnable,
    zeroCrossingSampleRate,
    actigraphyEnable,
    actigraphySampleRate,
    actigraphyZeroCrossingThreshold,
    actigraphyEnergyThreshold,
  } = garminDeviceSpecCopy

  const { hasActivePlanPurchase} = useCurrentWorkspaceAccountPlan()

  const garminDeviceSelectOptions = {
    heartRateOption: [
      {value: 3600, label: '60 min'},
      {value: 1800, label: '30 min'},
      {value: 1200, label: '20 min'},
      {value: 900, label: '15 min'},
      {value: 600, label: '10 min'},
      {value: 300, label: '5 min'},
      {value: 180, label: '3 min'},
      {value: 60, label: '1 min'},
      {value: 30, label: '30 sec', isDisabled: !hasActivePlanPurchase},
      {value: 15, label: '15 sec', isDisabled: !hasActivePlanPurchase},
      {value: 10, label: '10 sec', isDisabled: !hasActivePlanPurchase},
      {value: 5, label: '5 sec', isDisabled: !hasActivePlanPurchase},
      {value: 3, label: '3 sec', isDisabled: !hasActivePlanPurchase},
      {value: 2, label: '2 sec', isDisabled: !hasActivePlanPurchase},
      {value: 1, label: '1 sec', isDisabled: !hasActivePlanPurchase},
    ],
    sampleRateOptions: [
      {value: 3600, label: '60 min'},
      {value: 1800, label: '30 min'},
      {value: 1200, label: '20 min'},
      {value: 900, label: '15 min'},
      {value: 600, label: '10 min'},
      {value: 300, label: '5 min'},
      {value: 180, label: '3 min'},
      {value: 60, label: '1 min'},
      {value: 30, label: '30 sec', isDisabled: !hasActivePlanPurchase},
      {value: 10, label: '10 sec', isDisabled: !hasActivePlanPurchase},
    ],
    zeroCrossingsOptions: [
      {value: 3600, label: '60 min'},
      {value: 1800, label: '30 min'},
      {value: 1200, label: '20 min'},
      {value: 900, label: '15 min'},
      {value: 600, label: '10 min'},
      {value: 300, label: '5 min'},
      {value: 180, label: '3 min'},
      {value: 60, label: '1 min'},
      {value: 30, label: '30 sec', isDisabled: !hasActivePlanPurchase},
    ],
    actigraphyOptions: [
      {value: 60, label: '1 min'},
      {value: 30, label: '30 sec', isDisabled: !hasActivePlanPurchase},
      {value: 15, label: '15 sec', isDisabled: !hasActivePlanPurchase},
      {value: 10, label: '10 sec', isDisabled: !hasActivePlanPurchase},
    ],
  }
  
  const ToolTipContent = 
  `Supported devices: 
  ・VIVOMOVE 3
  ・VIVOACTIVE 4
  ・VIVOSMART 5
  ・VENU
  ・VENU SQ / SQM, VENU 2 / 2S
  ・FENIX 6 SERIES / 6 SOLAR SERIES
  ・FORERUNNER 245 / 245M, 745, 945 / 945 LTE
  ・LILY 
  ・ENDURO`

  const {
    heartRateOption, 
    sampleRateOptions, 
    zeroCrossingsOptions,
    actigraphyOptions,
  } = garminDeviceSelectOptions

  const methodState = selectMethod()
  const {  project } = useCurrentProjectState()
  const isEditingLiveProject = project?.status === 'live'

  const handleToggleGarminDeviceSectionEnable = (e: any) => {
    if (isEditingLiveProject && methodState?.garminStreamEnable) return
    setGarminDeviceSectionEnable(e)
  }

  const schema = v.object({
    heartRateSampleRate: v.number(),
    stressSampleRate: v.number(),
    respirationSampleRate: v.number(),
    pulseOxSampleRate: v.number(),
    zeroCrossingSampleRate: v.number(),
    actigraphySampleRate: v.number(),
    actigraphyZeroCrossingThreshold: v.number(),
    actigraphyEnergyThreshold: v.number(),
  })

  const {watch, control} = useForm({schema})

  useEffect(() => {
    setGarminDeviceSpecCopy((prevState: GarminDeviceSpecCopy) => ({
      ...prevState,
      heartRateSampleRate: watch('heartRateSampleRate')?.value,
      stressSampleRate: watch('stressSampleRate')?.value,
      respirationSampleRate: watch('respirationSampleRate')?.value,
      pulseOxSampleRate: watch('pulseOxSampleRate')?.value,
      zeroCrossingSampleRate: watch('zeroCrossingSampleRate')?.value,
      actigraphySampleRate: watch('actigraphySampleRate')?.value,
      actigraphyZeroCrossingThreshold: watch('actigraphyZeroCrossingThreshold')?.value,
      actigraphyEnergyThreshold: watch('actigraphyEnergyThreshold')?.value,
    }))
  }, [
    watch('heartRateSampleRate'),
    watch('stressSampleRate'),
    watch('respirationSampleRate'),
    watch('pulseOxSampleRate'),
    watch('zeroCrossingSampleRate'),
    watch('actigraphySampleRate'),
    watch('actigraphyZeroCrossingThreshold'),
    watch('actigraphyEnergyThreshold'),
  ])

  return (
    <div
      css={{
        border: `1px solid ${garminDeviceSectionEnable ? color.primary : color.grey_160}`,
        borderRadius: '5px',
        minHeight: '750px',
        width: '49%',
        padding: '24px',
        background: garminDeviceSectionEnable ? color.white : color.grey_20,
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '24px',
        }}
      >
        <div>
          <p
            css={{fontWeight: fontWeight.bold, color: garminDeviceSectionEnable ? color.primary : '', fontSize: '16px'}}
          >
            Labfront Companion App
          </p>
          <p
            css={{
              fontWeight: fontWeight.medium,
              fontSize: fontSize.base,
              color: color.grey_600,
              marginTop: '8px',
              marginRight: '24px',
            }}
          >
            The additional, more granular data obtained through Labfront’s programmatic access.
          </p>
        </div>
        <div css={{display: 'flex', alignItems: 'center'}}>
          <SlideCheckbox
            from='garmin_device_section'
            disabledColor={color.black}
            value={garminDeviceSectionEnable}
            onChange={(e) => handleToggleGarminDeviceSectionEnable(e)}
            readOnly={isEditingLiveProject && methodState?.garminStreamEnable}
          />
          <p
            css={{
              fontWeight: fontWeight.bold,
              marginLeft: pad.small,
              color: `${garminDeviceSectionEnable ? color.primary : color.black}`,
            }}
          >
            {garminDeviceSectionEnable ? 'ON' : 'OFF'}
          </p>
        </div>
      </div>

      <div
        css={{
          borderTop: `1px solid ${color.grey_160}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '16px',
        }}
      >
        <img src={GarminCompanionAppImg} width={120} height={90} />
        <p css={{color: color.grey_600, marginLeft: '24px', fontSize: '12px'}}>
          Requires participants to set up Labfront Companion App on their smartphone.{' '}
        </p>
      </div>

      <div css={{borderRadius: '8px', border: `1px solid ${color.grey_100}`}}>
        <div
          css={{
            background: color.background,
            borderRadius: '8px 8px 0 0',
            padding: '16px',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${color.grey_160}`,
          }}
        >
          <img src={DataUploadIcon} width={20} height={20} />
          <div css={{marginLeft: '8px'}}>
            <p css={{fontWeight: '700'}}>Continuous Data Collection</p>
            <p css={{marginTop: '8px', fontSize: '12px', color: color.grey_600}}>
              Once activated, the Garmin device will continuously collect this data in the background.{' '}
            </p>
            <p css={{marginTop: '4px', fontSize: '12px', fontWeight: fontWeight.bold}}>
              NOTE: Turning on ALL sensors to MAX resolution may incur issues, such as running out of storage on the device. Please contact <a href="mailto:Support@labfront.com" css={{color: color.primary, textDecoration: 'underline', fontWeight: fontWeight.bold}}>Support@labfront.com</a> to check your settings.
            </p>
          </div>
        </div>

        <div css={{padding: '16px', paddingBottom: '0'}}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              margin: '8px 0',
            }}
          >
            <p css={{color: color.grey_400}}>Data Type</p>
            <div css={{display: 'flex', alignItems: 'center'}}>
              <p css={{color: color.grey_400, marginRight: pad.small}}>Sampling Rate</p>
              <Tooltip content='Choose how often the data is collected.' />
            </div>
          </div>

          <GarminDirectDataBar
            {...{
              type: 'Heart Rate',
              garminDeviceSectionEnable,
              checkboxValue: heartRateEnable,
              checkBoxTestFrom: '_garmin_direct_heart_rate',
              setGarminDeviceSpecCopy,
              garminDeviceSpecCopyKey: 'heartRateEnable',
              hasSelectedOption: true,
              control,
              controlValue: heartRateSampleRate,
              controlName: 'heartRateSampleRate',
              selectIsDisabled: !garminDeviceSectionEnable || !heartRateEnable,
              selectOption: heartRateOption,
              setDisplayChangePlanPage,
            }}
          />

          <GarminDirectDataBar
            {...{
              type: 'Stress',
              garminDeviceSectionEnable,
              checkboxValue: stressEnable,
              checkBoxTestFrom: '_garmin_direct_stress',
              setGarminDeviceSpecCopy,
              garminDeviceSpecCopyKey: 'stressEnable',
              hasSelectedOption: true,
              control,
              controlValue: stressSampleRate,
              controlName: 'stressSampleRate',
              selectIsDisabled: !garminDeviceSectionEnable || !stressEnable,
              selectOption: sampleRateOptions,
              setDisplayChangePlanPage,
            }}
          />

          <GarminDirectDataBar
            {...{
              type: 'Steps',
              garminDeviceSectionEnable,
              checkboxValue: stepsEnable,
              checkBoxTestFrom: '_garmin_direct_steps',
              setGarminDeviceSpecCopy,
              garminDeviceSpecCopyKey: 'stepsEnable',
              hasSelectedOption: false,
              typeFixedRate: '1 min',
              setDisplayChangePlanPage,
            }}
          />

          {RIF(
            process.env.DEPLOY_STAGE === 'develop' || process.env.DEPLOY_STAGE === 'staging' || respirationEnable,
            <GarminDirectDataBar
              {...{
                type: 'Respiration',
                garminDeviceSectionEnable,
                checkboxValue: respirationEnable,
                checkBoxTestFrom: '_garmin_direct_respiration',
                setGarminDeviceSpecCopy,
                garminDeviceSpecCopyKey: 'respirationEnable',
                hasSelectedOption: true,
                control,
                controlValue: respirationSampleRate,
                controlName: 'respirationSampleRate',
                selectIsDisabled: !garminDeviceSectionEnable || !respirationEnable,
                setDisplayChangePlanPage,
                selectOption: sampleRateOptions,
                html: (
                  <div css={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                    <div css={{color: color.grey_400, fontSize: '12px', paddingRight: '5px'}}>
                      Only available on some devices
                    </div>
                    <Tooltip content={ToolTipContent} textAlign='start' tooltipWidth='380px' css={{width: '16px', zIndex: 99}} />
                  </div>
                ),
              }}
            />
          )}

          <GarminDirectDataBar
            {...{
              type: 'Heart Rate Interval',
              garminDeviceSectionEnable,
              checkboxValue: bbiEnable,
              checkBoxTestFrom: '_garmin_direct_bbi',
              setGarminDeviceSpecCopy,
              garminDeviceSpecCopyKey: 'bbiEnable',
              hasSelectedOption: false,
              setDisplayChangePlanPage,
              typeFixedRate: 'Each Beat',
              html: (
                <div css={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                  <div css={{color: color.taskYellow, fontSize: '12px', paddingRight: '5px'}}>
                    Minor impact on device battery life
                  </div>
                </div>
              ),
            }}
          />

          <GarminDirectDataBar
            {...{
              type: 'Actigraphy',
              garminDeviceSectionEnable,
              checkboxValue: actigraphyEnable,
              checkBoxTestFrom: '_garmin_direct_actigraphy',
              setGarminDeviceSpecCopy,
              garminDeviceSpecCopyKey: 'actigraphyEnable',
              hasSelectedOption: true,
              control,
              controlValue: actigraphySampleRate,
              controlName: 'actigraphySampleRate',
              selectIsDisabled: !garminDeviceSectionEnable || !actigraphyEnable,
              setDisplayChangePlanPage,
              selectOption: actigraphyOptions,
              actigraphyZeroCrossingThreshold,
              actigraphyEnergyThreshold,
              html: (
                <div css={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                  <div css={{color: color.grey_400, fontSize: '12px', paddingRight: '5px'}}>
                    Only available on some devices
                  </div>
                  <Tooltip content={ToolTipContent} textAlign='start' tooltipWidth='380px' css={{width: '16px', zIndex: 99}} />
                </div>
              ),
            }}
          />

          <GarminDirectDataBar
            {...{
              type: 'Oxygen Saturation',
              garminDeviceSectionEnable,
              checkboxValue: pulseOxEnable,
              checkBoxTestFrom: '_garmin_direct_oxygen_saturation',
              setGarminDeviceSpecCopy,
              garminDeviceSpecCopyKey: 'pulseOxEnable',
              hasSelectedOption: true,
              control,
              controlValue: pulseOxSampleRate,
              controlName: 'pulseOxSampleRate',
              selectIsDisabled: !garminDeviceSectionEnable || !pulseOxEnable,
              setDisplayChangePlanPage,
              selectOption: sampleRateOptions,
              html: (
                <div css={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                  <div css={{display: 'flex', alignItems: 'center'}}>
                    <img src={WarningIcon} width={16} height={16} />
                    <div
                      css={{
                        color: color.taskOrange,
                        fontSize: '12px',
                        marginLeft: '4px',
                        paddingRight: '5px',
                      }}
                    >
                      Severe impact on device battery life.
                      <br />
                      Only use if necessary for your study.
                    </div>
                  </div>
                </div>
              ),
              borderBottom:
                process.env.DEPLOY_STAGE === 'develop' || process.env.DEPLOY_STAGE === 'staging' ? '' : 'none',
            }}
          />

          {RIF(
            process.env.DEPLOY_STAGE === 'develop' || process.env.DEPLOY_STAGE === 'staging',
            <GarminDirectDataBar
              {...{
                type: 'Zero Crossings',
                garminDeviceSectionEnable,
                checkboxValue: zeroCrossingEnable,
                checkBoxTestFrom: '_garmin_direct_zero_crossings',
                setGarminDeviceSpecCopy,
                garminDeviceSpecCopyKey: 'zeroCrossingEnable',
                hasSelectedOption: true,
                control,
                controlValue: zeroCrossingSampleRate,
                controlName: 'zeroCrossingSampleRate',
                selectIsDisabled: !garminDeviceSectionEnable || !zeroCrossingEnable,
                setDisplayChangePlanPage,
                selectOption: zeroCrossingsOptions,
                html: (
                  <div css={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                    <div css={{color: color.grey_400, fontSize: '12px', paddingRight: '5px'}}>
                      Moderate impact on device battery life
                    </div>
                  </div>
                ),
                borderBottom: 'none',
              }}
            />,
          )}
        </div>
      </div>

      <div css={{borderRadius: '8px', border: `1px solid ${color.grey_100}`, marginTop: '24px'}}>
        <div
          css={{
            background: garminDeviceSectionEnable ? '#ECB75129' : color.grey_100,
            borderRadius: '8px 8px 0 0',
            padding: '16px',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${color.grey_160}`,
          }}
        >
          <img src={TouchIcon} width={20} height={20} />
          <div css={{marginLeft: '8px'}}>
            <div css={{fontWeight: '700'}}>Manually Activated Data Collection</div>
            <div css={{marginTop: '8px', fontSize: '12px', color: color.grey_600}}>
              Data types such as ACC capture a lot of data and cannot be turned on in the background. Participants
              need to manually start recording data through a separate task you will create in the App Builder.{' '}
            </div>
          </div>
        </div>

        <div css={{padding: '16px', paddingBottom: '0'}}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              margin: '8px 0',
            }}
          >
            <p css={{color: color.grey_400}}>Data Type</p>
            <div css={{display: 'flex', alignItems: 'center'}}>
              <p css={{color: color.grey_400, marginRight: pad.small}}>Sampling Rate</p>
              <Tooltip content='Choose how often the data is collected.' />
            </div>
          </div>

          <GarminDirectDataBar
            {...{
              type: '3-Axis Accelerometer (Streaming)',
              garminDeviceSectionEnable,
              checkboxValue: garminStreamEnable,
              checkBoxTestFrom: '_garmin_stream',
              setGarminStreamEnable,
              hasSelectedOption: false,
              setDisplayChangePlanPage,
              typeFixedRate: '25Hz-100Hz',
              html: (
                <div css={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                  <div css={{display: 'flex', alignItems: 'center'}}>
                    <img src={WarningIcon} width={16} height={16} />
                    <div css={{color: color.taskOrange, fontSize: '12px', marginLeft: '4px', paddingRight: '5px'}}>
                      Severe impact on device battery life.
                      <br />
                      Only use if necessary for your study.
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

interface GarminDirectDataBarProps {
  type: string
  garminDeviceSectionEnable: boolean
  checkboxValue: boolean
  checkBoxTestFrom: string
  setGarminDeviceSpecCopy?: Dispatch<SetStateAction<any>>
  garminDeviceSpecCopyKey?: string
  setGarminStreamEnable?: Dispatch<SetStateAction<boolean>>
  hasSelectedOption: boolean
  control?: any
  controlValue?: number
  controlName?: string
  selectIsDisabled?: boolean
  selectOption?: any[]
  typeFixedRate?: string
  html?: any
  borderBottom?: string
  actigraphyZeroCrossingThreshold?: number
  actigraphyEnergyThreshold?: number
  setDisplayChangePlanPage: Dispatch<SetStateAction<boolean>>
}

const GarminDirectDataBar = (props: GarminDirectDataBarProps) => {
  const {pad, fontWeight, color, fontSize} = selectTheme()

  const {
    type,
    garminDeviceSectionEnable,
    checkboxValue,
    checkBoxTestFrom,
    setGarminDeviceSpecCopy,
    garminDeviceSpecCopyKey,
    setGarminStreamEnable,
    hasSelectedOption,
    control,
    controlValue,
    controlName,
    selectIsDisabled,
    selectOption,
    typeFixedRate,
    html,
    borderBottom,
    actigraphyZeroCrossingThreshold,
    actigraphyEnergyThreshold,
    setDisplayChangePlanPage,
  } = props

  const thresholdValueOptionFormat = (value: number | undefined) => {
    if (!value) return {value: 50, label: '50 mG'}
    return {value, label: `${value} mG`}
  }

  const convertToOptionsForm = (num: number | undefined) => {
    if (!num) return
    if (num >= 60) return {value: num, label: `${num / 60} min`}
    return {value: num, label: `${num} sec`}
  }

  const createThresholdOptions = () => {
    return Array.from(
      Array(100), 
      (_, index) => {return {value: index + 1, label: `${index + 1} mG`}}
    )
  }

  const methodState = selectMethod()
  const { project } = useCurrentProjectState()
  const isEditingLiveProject = project?.status === 'live'
  const methodGarminStreamEnable = methodState?.garminStreamEnable

  const garminStreamBtnDisable =
    type === '3-Axis Accelerometer (Streaming)'
      ? !garminDeviceSectionEnable || (isEditingLiveProject && checkboxValue && methodGarminStreamEnable)
      : undefined

  const handleClickCheckbox = (e: any) => {
    if (!!garminDeviceSpecCopyKey && !!setGarminDeviceSpecCopy) {
      setGarminDeviceSpecCopy((prevState: GarminDeviceSpecCopy) => ({
        ...prevState,
        [garminDeviceSpecCopyKey]: e,
      }))
    } else if (setGarminStreamEnable) {
      setGarminStreamEnable(e as boolean)
    }
  }

  const Option = (props: OptionProps) => {
    return (
      <components.Option
        {...props}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        }}
      >
        {props.label}
        {RIF(
          props.isDisabled,
          <div
            onClick={() => {
              if (props.isDisabled) {
                setDisplayChangePlanPage(true)
              }
            }}
            css={{
              borderRadius: '2px',
              color: 'white',
              padding: '2px 3px',
              background: color.upgrade,
              cursor: 'pointer',
              fontSize: fontSize.h8,
            }}
          >
            Upgrade
          </div>,
        )}
      </components.Option>
    )
  }

  return (
    <div css={{
      borderBottom: borderBottom === 'none' ? '' : `1px solid ${color.grey_100}`,
    }}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          minHeight: '60px',
          padding: '16px 0',
        }}
      >
        <label css={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          <Checkbox
            from={checkBoxTestFrom} // for RTL
            value={checkboxValue}
            disabled={type === '3-Axis Accelerometer (Streaming)' ? garminStreamBtnDisable : !garminDeviceSectionEnable}
            onChange={handleClickCheckbox}
          />
          <div
            css={{
              marginLeft: pad.mediumSmall,
              fontWeight: fontWeight.medium,
              color: garminDeviceSectionEnable ? color.black : color.grey_600,
            }}
          >
            {type}
            {html}
          </div>
        </label>
        {RIF(
          hasSelectedOption,
          <Controller
            control={control}
            defaultValue={convertToOptionsForm(controlValue)}
            name={controlName as string}
            render={({field: {name, value, onChange}}) => (
              <Select
                {...{
                  name,
                  value,
                  onChange,
                }}
                isDisabled={selectIsDisabled}
                options={selectOption}
                components={{Option}}
                css={{width: '35%', minWidth: '104px', marginLeft: '5px'}}
              />
            )}
          />,
        )}
        {RIF(!hasSelectedOption, <p css={{fontWeight: fontWeight.medium, color: color.grey_600}}>{typeFixedRate}</p>)}
      </div>
      {RIF(type === 'Actigraphy',
        <div css={{
          width: 'calc(100%-24px)',
          marginLeft: '24px',
          marginBottom: '16px',
          padding: '16px',
          borderRadius: '5px',
          background: color.background,
        }}>
          <div css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingBottom: '16px',
            borderBottom: `1px solid ${color.grey_100}`
          }}>
            <div css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <p
                css={{
                  fontWeight: fontWeight.medium,
                  color: garminDeviceSectionEnable ? color.black : color.grey_600,
                  marginRight: '4px',
                }}
              >Zero Crossing Threshold</p>
              <Tooltip content={'Measure movement frequency, excluding small movements or noise with amplitude smaller than the threshold.'}/>
            </div>
            <Controller
              control={control}
              defaultValue={thresholdValueOptionFormat(actigraphyZeroCrossingThreshold)}
              name={'actigraphyZeroCrossingThreshold'}
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={selectIsDisabled}
                  options={createThresholdOptions()}
                  css={{width: '25%', minWidth: '110px', marginLeft: '5px'}}
                />
              )}
            />
          </div>
          <div css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '16px',
          }}>
            <div css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
              <p
                css={{
                  fontWeight: fontWeight.medium,
                  color: garminDeviceSectionEnable ? color.black : color.grey_600,
                  marginRight: '4px',
                }}
              >Energy Threshold</p>
              <Tooltip content={'Measure movement amplitude, excluding small movements or noise with amplitude smaller than the threshold.'}/>
            </div>
            <Controller
              control={control}
              defaultValue={thresholdValueOptionFormat(actigraphyEnergyThreshold)}
              name={'actigraphyEnergyThreshold'}
              render={({field: {name, value, onChange}}) => (
                <Select
                  {...{
                    name,
                    value,
                    onChange,
                  }}
                  isDisabled={selectIsDisabled}
                  options={createThresholdOptions()}
                  css={{width: '25%', minWidth: '104px', marginLeft: '5px'}}
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}
