import {useState, useEffect, CSSProperties} from 'react'
import {Blurhash} from 'react-blurhash'
import blurhashJson from '../../asset/image/blurhash.json'
import {RIF} from '../../lib'
export interface OptimizedImagePropsT {
  src: any
  width?: number
  height?: number
  css?: CSSProperties
}

export const OptimizedImage = (props: OptimizedImagePropsT) => {
  const {src, width, height, ...rest} = props

  // /images/this_photo.webp ---> this_photo
  const imgName = src?.split('/')[2]?.split('.')[0] as string
  const blurhashObj: {[key: string]: string} = blurhashJson
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      setLoaded(true)
    }
  }, [src])

  return (
    <div
      {...rest}
      css={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
      }}
    >
      <div
        css={{
          opacity: loaded ? '100' : '0',
          transitionProperty: 'opacity',
          transitionDuration: '500ms',
          position: 'absolute',
        }}
      >
        <img src={src} width={width} height={height} />
      </div>
      <div
        css={{
          opacity: loaded ? '0' : '100',
          transitionProperty: 'opacity',
          transitionDuration: '500ms',
          position: 'absolute',
        }}
      >
        {RIF(imgName, <Blurhash hash={blurhashObj[imgName]} width={width} height={height} />)}
      </div>
    </div>
  )
}
