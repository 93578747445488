import {Nullish} from 'utility-types'
import {DragDropContext, Droppable} from 'react-beautiful-dnd'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  createDispatchActions,
  selectMethod,
  selectProjectData,
  selectTempTaskData,
  selectTheme,
  useSelectSettings,
} from '../../store'
import {
  CalendarBlueIcon,
  DeviceBlueIcon,
  ParticipantTaskIcon,
  PlusBlueIcon,
  SurveyIcon,
  TimerIcon,
  TodoIcon,
} from '../../asset/image'
import MovesenseImg from '../../asset/image/movesense.png'
import {collaboratorTypeHasRole, darken, isInBetaType, RIF, sortBy, useCollaboratorTypeInProject} from '../../lib'
import {
  EditingTask,
  getTaskContentName,
  TaskContentName,
  TaskStateType,
  TaskTempStateType,
  TaskType,
  TaskTypeForInstructionPage,
} from '../../model'
import {
  AddTaskPage,
  ButtonReverse,
  DropdownMenu,
  EditProjectSchedulePopup,
  HiddenTaskTag,
  PopupConfirmDeleteTask,
  PricingPage,
  Step,
  TaskScheduleSettingType,
  TaskTag,
} from '..'
import {BetaType, IProject, ProjectCollaboratorRole, WorkspaceCollaboratorRole} from '../../shared/db'

export interface ParticipantTaskBlockProps {
  setTasksFilled: Dispatch<SetStateAction<boolean>>
  autoAddTaskType: TaskTypeForInstructionPage
  setAutoAddTaskType: Dispatch<SetStateAction<TaskTypeForInstructionPage>>
}

export const ParticipantTaskBlock = (props: ParticipantTaskBlockProps) => {
  const {pad, color, fontWeight, fontSize} = selectTheme()

  const {
    doMETHOD_SET,
    doREQUEST_TASK_DELETE,
    doTEMP_TASK_DATA_UPDATE_TASK,
    doTEMP_TASK_DATA_DELETE_TASK,
    doTEMP_TASK_DATA_TASK_LIST_UPDATE,
    doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE,
  }: any = createDispatchActions()

  const {setTasksFilled, autoAddTaskType, setAutoAddTaskType} = props

  const collaboratorType = useCollaboratorTypeInProject()
  const canAddTask = collaboratorTypeHasRole({
    collaboratorType,
    workspaceRole: WorkspaceCollaboratorRole.TaskCreate,
    projectRole: ProjectCollaboratorRole.TaskCreate
  })

  const {projectId} = useParams()
  const project = selectProjectData()[projectId as string] as IProject | Nullish
  const scheduleConfig = project?.scheduleConfig
  const methodState = selectMethod()
  const tempTaskData = selectTempTaskData()
  const isEditingLiveProject = project?.status === 'live'
  const settings = useSelectSettings()
  const isInternalUser = isInBetaType({
    betaType: BetaType.Internal,
    settings
  })

  const taskList: TaskStateType[] =
    methodState?.taskList
      ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
      .sort(sortBy('index')) || []
  const hiddenTaskList: TaskStateType[] =
    methodState?.taskList
      ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && !item.enabled)
      .sort(sortBy('index')) || []
  const tempTaskList: TaskTempStateType[] =
    tempTaskData?.taskList?.filter((item: TaskTempStateType) => item.type !== TaskType.GarminDevice) || []

  const movesenseDeviceEnable: boolean = methodState?.movesenseDeviceEnable || false
  const tempMovesenseDeviceEnable = tempTaskData?.movesenseDeviceEnable || false
  const garminStreamEnable: boolean = methodState?.garminStreamEnable || false
  const tempGarminStreamEnable = tempTaskData?.garminStreamEnable || false

  const [isAddingTask, setIsAddingTask] = useState(false)
  const [removingTaskId, setRemovingTaskId] = useState('')
  const [removingTaskName, setRemovingTaskName] = useState('')
  const [showHiddenTask, setShowHiddenTask] = useState(false)
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)
  const [editingTask, setEditingTask] = useState<EditingTask>({})
  const [taskType, setTaskType] = useState<TaskContentName>('todo')
  const [taskScheduleSettingType, setTaskScheduleSettingType] = useState<TaskScheduleSettingType>(TaskScheduleSettingType.Legacy)
  const [displayEditProjectSchedulePopUp, setDisplayEditProjectSchedulePopUp] = useState(false)
  const [showScheduleTaskDropdown, setShowScheduleTaskDropdown] = useState(false)
  const [showEventTrackerTaskDropdown, setShowEventTrackerTaskDropdown] = useState(false)

  useEffect(() => {
    if (!autoAddTaskType) return
    setIsAddingTask(true)
    setTaskType(getTaskContentName(autoAddTaskType))
  }, [autoAddTaskType])

  useEffect(() => {
    if (isEditingLiveProject) {
      setTasksFilled(!!tempTaskList.filter((item: any) => item.enabled).length)
    } else {
      setTasksFilled(!!taskList.length)
    }
  }, [methodState.taskList, tempTaskData.taskList])

  const onDragEnd = (result: any) => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    if (!isEditingLiveProject) {
      const newMethodState = JSON.parse(JSON.stringify(methodState))
      const newTaskList = JSON.parse(JSON.stringify(taskList))
      newTaskList.splice(destination.index, 0, newTaskList.splice(source.index, 1)[0])
      const taskIdList = newTaskList.map((task: any, index: number) => {
        task.index = index
        return task.id
      })
      doREQUEST_METHOD_TASK_INDEX_ORDER_UPDATE({
        payload: {
          methodId: methodState.id,
          taskIdList,
        },
      })
      doMETHOD_SET({...newMethodState, taskList: newTaskList})
    } else {
      const taskList: any[] = JSON.parse(JSON.stringify(tempTaskList))
      const enabledTaskList = taskList.filter((item) => item.enabled)
      const hiddenTaskList = taskList.filter((item) => !item.enabled)
      enabledTaskList.splice(destination.index, 0, enabledTaskList.splice(source.index, 1)[0])
      const newTaskList = enabledTaskList.concat(hiddenTaskList)
      doTEMP_TASK_DATA_TASK_LIST_UPDATE({
        taskList: newTaskList,
      })
    }
  }

  const deleteTask = () => {
    if (!isEditingLiveProject) {
      doREQUEST_TASK_DELETE({
        payload: {
          taskId: removingTaskId,
        },
      })
    } else {
      const removingTask = {...tempTaskList.find((task) => task.id === removingTaskId)}
      // hide task if it's already exist in backend
      if (removingTask.existed) {
        removingTask.enabled = false
        removingTask.actionType = 'hide'
        doTEMP_TASK_DATA_UPDATE_TASK(removingTask)
      } else {
        // or if not, remove it
        doTEMP_TASK_DATA_DELETE_TASK({
          taskId: removingTaskId,
        })
      }
    }
    closeConfirmRemovingTaskPopup()
  }

  const closeConfirmRemovingTaskPopup = () => {
    setRemovingTaskId('')
    setRemovingTaskName('')
  }

  return (
    <div
      css={{
        display: 'flex',
        background: color.white,
        borderRadius: '5px;',
        width: '55vw',
        boxShadow: '0px 4px 12px 0px #D4D4D440',
        marginBottom: pad.large,
      }}
    >
      <div
        css={{
          padding: pad.large,
          borderRight: `1px solid ${color.grey_100}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '26%',
        }}
      >
        <div>
          <div
            css={{
              width: '40px',
              height: '40px',
              background: color.background,
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: pad.large,
            }}
          >
            <img src={ParticipantTaskIcon} width='24px' />
          </div>
          <p
            css={{
              fontSize: '18px',
              fontWeight: fontWeight.thick,
              marginBottom: pad.medium,
            }}
          >
            Participant Tasks
          </p>
          <p css={{lineHeight: '19px', fontWeight: fontWeight.regular}}>
            Create tasks for your participants to complete throughout your study.
          </p>
        </div>
      </div>
      <div
        css={{
          padding: pad.xl,
          width: '74%',
        }}
      >
        {RIF(isInternalUser,
          <>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '14px',
              }}
            >
              <div css={{marginRight: '8px'}}>
                <Step text="STEP 1" />
              </div>
              <p
                css={{
                  fontSize: '14px',
                  fontWeight: fontWeight.medium,
                }}
              >
                Setup project schedule
              </p>
            </div>
            {RIF(
              canAddTask,
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '32px',
                }}
              >
                {RIF(
                  !scheduleConfig,
                  <ButtonReverse
                    data-testid="setup_project_schedule_btn"
                    btnPadding="medium"
                    onClick={() => setDisplayEditProjectSchedulePopUp(true)}
                    children={
                      <span
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          color: color.primary,
                        }}
                      >
                    <img
                      src={CalendarBlueIcon}
                      width="18"
                      css={{
                        marginRight: pad.xs,
                      }}
                    />
                    Setup Project Schedule
                  </span>
                    }
                    css={{marginRight: pad.small, marginBottom: '10px'}}
                  />,
                )}
                {RIF(
                  scheduleConfig,
                  <div
                    css={{
                      width: '100%',
                      padding: '16px',
                      borderRadius: '5px',
                      background: color.surface.grey.background,
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                      }}
                    >
                      <div
                        css={{
                          fontWeight: fontWeight.thick,
                          fontSize: fontSize.h6,
                          color: color.black,
                        }}
                      >
                        Project Schedule
                      </div>
                      <button
                        onClick={() => setDisplayEditProjectSchedulePopUp(true)}
                        css={{
                          backgroundColor: color.surface.grey.background,
                          ':hover': {
                            background: darken(color.surface.grey.background, 10),
                          },
                          color: color.grey_600,
                          fontWeight: fontWeight.bold,
                          fontSize: fontSize.h7,
                          borderStyle: 'solid',
                          borderWidth: '1px',
                          borderColor: color.grey_160,
                          borderRadius: '3px',
                          padding: '0px 6px',
                        }}
                      >
                        Edit Project Schedule
                      </button>
                    </div>
                    {RIF(
                      scheduleConfig?.frequency,
                      <div css={{wordBreak: 'break-word'}}>
                        This project operates on a{' '}
                        <span
                          css={{
                            color: color.primary,
                            fontWeight: fontWeight.thick,
                          }}
                        >
                      {scheduleConfig?.frequency} basis
                    </span>
                        .
                      </div>,
                    )}
                    {RIF(
                      !scheduleConfig?.frequency,
                      <div css={{wordBreak: 'break-word'}}>This project does not operate on any schedule.</div>,
                    )}
                  </div>,
                )}
              </div>,
            )}
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '14px',
              }}
            >
              <div css={{marginRight: '8px'}}>
                <Step text="STEP 2" />
              </div>
              <p
                css={{
                  fontSize: '14px',
                  fontWeight: fontWeight.medium,
                }}
              >
                Create tasks for participants to complete
              </p>
            </div>

          </>,
        )}
        {RIF(
          canAddTask && isInternalUser,
          <div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: '6px',
            }}
          >
            <div
              onMouseOver={() => setShowScheduleTaskDropdown(true)}
              onMouseOut={() => setShowScheduleTaskDropdown(false)}
              css={{
                display: 'flex', flexWrap: 'wrap',
              }}>
              <ButtonReverse
                data-testid="add_scheduled_task_btn"
                btnPadding="medium"
                disabled={!scheduleConfig}
                children={
                  <span
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.primary,
                    }}
                  >
                    <img
                      src={PlusBlueIcon}
                      width="18"
                      css={{
                        marginRight: pad.xs,
                      }}
                    />
                    Add Scheduled Task
                  </span>
                }
                css={{marginRight: pad.small, marginBottom: '10px'}}
              />
              <DropdownMenu
                dropped={showScheduleTaskDropdown && scheduleConfig !== undefined && scheduleConfig !== null}
                parentCSS={{
                  marginTop: '40px',
                }}
                options={[
                  {
                    text: 'Todo Task',
                    onClick: () => {
                      setShowScheduleTaskDropdown(false)
                      setTaskScheduleSettingType(TaskScheduleSettingType.Schedule)
                      setIsAddingTask(true)
                      setTaskType('todo')
                    },
                  },
                  {
                    text: 'Questionnaire Task',
                    onClick: () => {
                      setShowScheduleTaskDropdown(false)
                      setTaskScheduleSettingType(TaskScheduleSettingType.Schedule)
                      setIsAddingTask(true)
                      setTaskType('questionnaire')
                    },
                  },
                  {
                    text: 'Timer Task',
                    onClick: () => {
                      setShowScheduleTaskDropdown(false)
                      setTaskScheduleSettingType(TaskScheduleSettingType.Schedule)
                      setIsAddingTask(true)
                      setTaskType('timer')
                    },
                  },
                ]}
              />
            </div>
            <div
              onMouseOver={() => setShowEventTrackerTaskDropdown(true)}
              onMouseOut={() => setShowEventTrackerTaskDropdown(false)}
              css={{
                display: 'flex', flexWrap: 'wrap',
              }}>
              <ButtonReverse
                data-testid="add_event_tracker_task_btn"
                btnPadding="medium"
                disabled={!scheduleConfig}
                onClick={() => {
                  setIsAddingTask(true)
                  setTaskType('todo')
                }}
                children={
                  <span
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.primary,
                    }}
                  >
                    <img
                      src={PlusBlueIcon}
                      width="18"
                      css={{
                        marginRight: pad.xs,
                      }}
                    />
                    Add Event Tracker
                  </span>
                }
                css={{marginRight: pad.small, marginBottom: '10px'}}
              />
              <DropdownMenu
                dropped={showEventTrackerTaskDropdown && scheduleConfig !== undefined && scheduleConfig !== null}
                parentCSS={{
                  marginTop: '40px',
                }}
                options={[
                  {
                    text: 'Todo Task',
                    onClick: () => {
                      setShowScheduleTaskDropdown(false)
                      setTaskScheduleSettingType(TaskScheduleSettingType.EventTracker)
                      setIsAddingTask(true)
                      setTaskType('todo')
                    },
                  },
                  {
                    text: 'Questionnaire Task',
                    onClick: () => {
                      setShowScheduleTaskDropdown(false)
                      setTaskScheduleSettingType(TaskScheduleSettingType.EventTracker)
                      setIsAddingTask(true)
                      setTaskType('questionnaire')
                    },
                  },
                  {
                    text: 'Timer Task',
                    onClick: () => {
                      setShowScheduleTaskDropdown(false)
                      setTaskScheduleSettingType(TaskScheduleSettingType.EventTracker)
                      setIsAddingTask(true)
                      setTaskType('timer')
                    },
                  },
                ]}
              />
            </div>
          </div>)}
        {RIF(
          canAddTask && !isInternalUser,
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '6px',
            }}
          >
            <div css={{display: 'flex', flexWrap: 'wrap'}}>
              <ButtonReverse
                data-testid="add_todo_btn"
                btnPadding="medium"
                onClick={() => {
                  setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                  setIsAddingTask(true)
                  setTaskType('todo')
                }}
                children={
                  <span
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.primary,
                    }}
                  >
                    <img
                      src={TodoIcon}
                      width="18"
                      css={{
                        marginRight: pad.xs,
                      }}
                    />
                    To-Do
                  </span>
                }
                css={{marginRight: pad.small, marginBottom: '10px'}}
              />
              <ButtonReverse
                data-testid="add_questionnaire_btn"
                btnPadding="medium"
                onClick={() => {
                  setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                  setIsAddingTask(true)
                  setTaskType('questionnaire')
                }}
                children={
                  <span
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.primary,
                    }}
                  >
                    <img
                      src={SurveyIcon}
                      width="18"
                      css={{
                        marginRight: pad.xs,
                      }}
                    />
                    Questionnaire
                  </span>
                }
                css={{marginRight: pad.small, marginBottom: '10px'}}
              />
              <ButtonReverse
                data-testid="add_timer_btn"
                btnPadding="medium"
                onClick={() => {
                  setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                  setIsAddingTask(true)
                  setTaskType('timer')
                }}
                children={
                  <span
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      color: color.primary,
                    }}
                  >
                    <img
                      src={TimerIcon}
                      width='18'
                      css={{
                        marginRight: pad.xs,
                      }}
                    />
                    Timer
                  </span>
                }
                css={{marginRight: pad.small, marginBottom: '10px'}}
              />
              {RIF(
                isEditingLiveProject ? tempGarminStreamEnable || garminStreamEnable : garminStreamEnable,
                <ButtonReverse
                  data-testid='add_garmin_stream_btn'
                  btnPadding='medium'
                  onClick={() => {
                    setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                    setIsAddingTask(true)
                    setTaskType('stopwatchGarminStream')
                  }}
                  children={
                    <span
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        color: color.primary,
                      }}
                    >
                      <img
                        src={DeviceBlueIcon}
                        width='18'
                        css={{
                          marginRight: pad.xs,
                        }}
                      />
                      Garmin Streaming
                    </span>
                  }
                  css={{marginRight: pad.small, marginBottom: '10px'}}
                />,
              )}
              {RIF(
                isEditingLiveProject ? tempMovesenseDeviceEnable || movesenseDeviceEnable : movesenseDeviceEnable,
                <ButtonReverse
                  data-testid='add_movesense_btn'
                  onClick={() => {
                    setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                    setIsAddingTask(true)
                    setTaskType('stopwatchMovesenseStream')
                  }}
                  btnPadding='medium'
                  css={{marginRight: pad.small, marginBottom: '10px'}}
                >
                  <span css={{display: 'flex', alignItems: 'center', color: color.primary}}>
                    <img
                      src={MovesenseImg}
                      width='18'
                      height='18'
                      css={{marginRight: '4px', transform: 'scale(1.2)'}}
                    />
                    Movesense
                  </span>
                </ButtonReverse>,
              )}
            </div>
            {/* hide task set for now */}
            {/* <div css={{
              height: '29px',
              width: '1px',
              borderRight: `1px solid ${color.grey_160}`,
            }}></div>
            <TextLink
              onClick={()=>{
                setIsAddingTask(true)
                setTaskType('task_set')
              }}
              children={
                <span css={{
                  display: 'flex',
                  alignItems: 'center',
                  color: color.primary,
                }}>
                  <img src={TaskSetIcon} width='24' css={{
                    marginRight: pad.xs,
                  }}/>Task-Set
                </span>
              }/> */}
          </div>,
        )}
        {RIF(
          !isEditingLiveProject,
          <>
            {RIF(!taskList?.length, <EmptyTaskBlock />)}
            {RIF(
              !!taskList?.length,
              <>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='taskList'>
                    {(provided) => {
                      return (
                        <>
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            css={{
                              minHeight: '296px',
                              width: '100%',
                              marginTop: pad.large,
                              marginBottom: '24px',
                            }}
                          >
                            {taskList.map((task: TaskStateType, i: number) => {
                              const onEditing = () => {
                                if (scheduleConfig) {
                                  if (task.scheduleList && task.scheduleList.length !== 0) {
                                    setTaskScheduleSettingType(TaskScheduleSettingType.Schedule)
                                  } else {
                                    setTaskScheduleSettingType(TaskScheduleSettingType.EventTracker)
                                  }
                                } else {
                                  setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                                }
                                setIsAddingTask(true)
                                setEditingTask({
                                  ...task,
                                  index: i,
                                })
                                setTaskType(getTaskContentName(task.type))
                              }
                              return (
                                <TaskTag
                                  {...{
                                    task,
                                    setRemovingTaskId,
                                    setRemovingTaskName,
                                    index: i,
                                    key: task.id,
                                    onEditing: onEditing,
                                  }}
                                />
                              )
                            })}
                            {provided.placeholder}
                          </div>
                        </>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </>,
            )}
          </>,
        )}
        {RIF(
          isEditingLiveProject,
          <>
            {RIF(!tempTaskList.filter((item) => item.enabled).length, <EmptyTaskBlock />)}
            {RIF(
              !!tempTaskList.filter((item) => item.enabled).length,
              <>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='taskList'>
                    {(provided) => {
                      return (
                        <>
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            css={{
                              minHeight: '296px',
                              width: '100%',
                              marginTop: pad.large,
                            }}
                          >
                            {tempTaskList
                              .filter((item) => item.enabled)
                              .map((task: TaskTempStateType, i: number) => {
                                const onEditing = () => {
                                  if (scheduleConfig) {
                                    if (task.scheduleList && task.scheduleList.length !== 0) {
                                      setTaskScheduleSettingType(TaskScheduleSettingType.Schedule)
                                    } else {
                                      setTaskScheduleSettingType(TaskScheduleSettingType.EventTracker)
                                    }
                                  } else {
                                    setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                                  }
                                  setIsAddingTask(true)
                                  setEditingTask({
                                    ...task,
                                    index: i,
                                  })
                                  setTaskType(getTaskContentName(task.type))
                                }
                                return (
                                  <TaskTag
                                    {...{
                                      task,
                                      setRemovingTaskId,
                                      setRemovingTaskName,
                                      index: i,
                                      key: task.id,
                                      onEditing: onEditing,
                                    }}
                                  />
                                )
                              })}
                            {provided.placeholder}
                          </div>
                        </>
                      )
                    }}
                  </Droppable>
                </DragDropContext>
              </>,
            )}
            {RIF(
              tempTaskList.filter((item) => !item.enabled).length > 0,
              <div css={{width: '100%', textAlign: 'end', marginTop: '16px'}}>
                <a
                  css={{
                    color: color.grey_600,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    textDecorationColor: color.grey_600,
                    ':hover': {
                      color: color.black,
                      textDecorationColor: color.black,
                    },
                  }}
                  onClick={() => {
                    setShowHiddenTask((prev) => !prev)
                  }}
                >
                  {showHiddenTask ? 'Hide' : 'See'} Hidden tasks
                </a>
              </div>,
            )}
            {RIF(
              showHiddenTask && !!tempTaskList.filter((item) => !item.enabled).length,
              <div
                css={{
                  width: '100%',
                  borderRadius: '8px',
                  border: `1px dashed ${color.grey_300}`,
                  padding: '32px',
                  marginTop: '24px',
                }}
              >
                <p
                  css={{
                    fontWeight: fontWeight.medium,
                    marginBottom: '24px',
                  }}
                >
                  The following tasks are hidden from participant app:
                </p>
                {tempTaskList
                  .filter((item) => !item.enabled)
                  .map((task: TaskTempStateType, i: number) => {
                    const onEditing = () => {
                      if (scheduleConfig) {
                        if (task.scheduleList && task.scheduleList.length !== 0) {
                          setTaskScheduleSettingType(TaskScheduleSettingType.Schedule)
                        } else {
                          setTaskScheduleSettingType(TaskScheduleSettingType.EventTracker)
                        }
                      } else {
                        setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
                      }
                      setIsAddingTask(true)
                      setEditingTask({
                        ...task,
                        index: i,
                      })
                      setTaskType(getTaskContentName(task.type))
                    }
                    return (
                      <HiddenTaskTag
                        {...{
                          task,
                          key: task.id,
                          onEditing: onEditing,
                        }}
                      />
                    )
                  })}
              </div>,
            )}
          </>,
        )}
      </div>
      {RIF(
        isAddingTask,
        <AddTaskPage
          {...{
            isEditing: editingTask,
            taskType,
            closePopup: () => {
              setTaskScheduleSettingType(TaskScheduleSettingType.Legacy)
              setIsAddingTask(false)
              setEditingTask({})
            },
            setAutoAddTaskType,
            setDisplayChangePlanPage,
            taskScheduleSettingType,
            projectScheduleConfig: scheduleConfig
          }}
        />,
      )}
      {RIF(
        displayChangePlanPage,
        <PricingPage
          {...{
            setDisplayChangePlanPage,
          }}
        />,
      )}
      {/* {RIF(isAddingTask && taskType === 'task_set',
        <AddTaskSetPopup
          isEditing={editingTask}
          closePopup={() => {
            setIsAddingTask(false)
            setEditingTask({})
          }
          }/>,
      )} */}
      {RIF(
        !!removingTaskId,
        <PopupConfirmDeleteTask
          {...{
            deleteAction: deleteTask,
            closeAction: closeConfirmRemovingTaskPopup,
            taskName: removingTaskName,
          }}
        />,
      )}
      {RIF(displayEditProjectSchedulePopUp, <EditProjectSchedulePopup {...{
        projectId: projectId as string,
        scheduleConfig,
        onClose: () => setDisplayEditProjectSchedulePopUp(false),
        onSaveComplete: () => setDisplayEditProjectSchedulePopUp(false) // ignore scheduleConfig in callback, project state should be updated when api call succeed
      }}/>)}
    </div>
  )
}

const EmptyTaskBlock = () => {
  const {color, pad, fontSize} = selectTheme()
  return (
    <div
      css={{
        borderRadius: '5px',
        border: `1px dashed ${color.grey_400}`,
        height: '296px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: pad.large,
      }}
    >
      <p
        css={{
          fontSize: fontSize.h4,
          color: color.grey_400,
        }}
      >
        You don&#39;t have any tasks set up yet
      </p>
    </div>
  )
}
