import {
  TabularFilePathComponents,
  TabularGarminConnectRecordType,
  TabularGarminDeviceDataType,
  TabularGarminDeviceStreamDataType,
  TabularMovesenseDeviceStreamDataType,
  TabularStopwatchTaskType,
} from '../../../shared/tabulator'
import {TaskType} from '../../../shared/db'

export type TabularTypeInfo = Pick<TabularFilePathComponents, 'taskType' | 'dataType'>

export const TimeSeriesTypes = Object.freeze(
  new Array<TabularTypeInfo>().concat(
    [
      TabularGarminConnectRecordType.DailyHeartRate,
      TabularGarminConnectRecordType.ThirdPartyDailyHeartRate,
      TabularGarminConnectRecordType.SleepRespiration,
      TabularGarminConnectRecordType.SleepPulseOx,
      TabularGarminConnectRecordType.Stress,
      TabularGarminConnectRecordType.PulseOx,
      TabularGarminConnectRecordType.Respiration,
      TabularGarminConnectRecordType.HealthSnapshotHeartRate,
      TabularGarminConnectRecordType.HealthSnapshotStress,
      TabularGarminConnectRecordType.HealthSnapshotPulseOx,
      TabularGarminConnectRecordType.HealthSnapshotRespiration,
      TabularGarminConnectRecordType.HRVValues,
    ].map((taskType) => {
      return Object.freeze({taskType})
    }),
    Object.values(TabularGarminDeviceDataType).map((dataType) => {
      return Object.freeze({
        taskType: TaskType.GarminDevice,
        dataType,
      })
    }),
    Object.values(TabularGarminDeviceStreamDataType).map((dataType) => {
      return Object.freeze({
        taskType: TabularStopwatchTaskType.GarminStream,
        dataType,
      })
    }),
    Object.values(TabularMovesenseDeviceStreamDataType).map((dataType) => {
      return Object.freeze({
        taskType: TabularStopwatchTaskType.MovesenseStream,
        dataType,
      })
    }),
  ),
)
