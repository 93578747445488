import {CSSProperties} from 'react'
import Plot, {PlotParams} from 'react-plotly.js'
import {
  CorrelationResultCoordinate,
  CorrelationResultCorrelationData,
  CorrelationResultGraph,
} from '../../../shared/analysis'
import {compact} from 'lodash'
import {CorrelationCoordinate} from './correlation_coordinate'
import {selectTheme} from '../../../store'

const CorrelationGraphStats = (props: {lines: string[]; style: CSSProperties; onClick?: () => void}) => {
  // TODO: style line contents
  return (
    <div
      style={{
        ...props.style,
        flexDirection: 'column',
      }}
    >
      {props.lines}
    </div>
  )
}

const CorrelationGraphPlot = (props: {graph: string; style: CSSProperties; onClick?: () => void}) => {
  const style = {
    ...props.style,
    color: undefined,
  }

  const params = JSON.parse(props.graph) as PlotParams

  // update listeners
  params.onClick = () => props.onClick?.()

  // update layout
  params.layout = {
    ...params.layout,
    title: undefined,
    margin: {
      t: 0,
      b: 0,
      l: 0,
      r: 0,
      pad: 0,
    },
    paper_bgcolor: style.backgroundColor,
    plot_bgcolor: style.backgroundColor,
    yaxis: {
      visible: false,
    },
    xaxis: {
      visible: false,
    },
    showlegend: false,
    width: 188,
    bargap: 0.5,
  }

  // update config
  params.config = {
    ...params.config,
    editable: false,
    displaylogo: false,
    displayModeBar: false,
  }

  return <Plot {...params} style={style} />
}

const DefaultCorrelationGraphStyle: Readonly<CSSProperties> = {
  width: '200px',
  height: '200px',
  color: '#9a9ba2', // only works for data panel
  backgroundColor: '#fafafa',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const correlationDataToText = (dataList: CorrelationResultCorrelationData[]) =>
  dataList.flatMap((data) => {
    const {individual, group, duration} = data.coord
    // TODO: update labelComponents
    const labelComponents = compact([
      individual ? `P ${individual}` : undefined,
      group ? `G ${group}` : undefined,
      duration ? `D ${duration}` : undefined,
    ])
    const label = labelComponents.length ? labelComponents.join(', ') : undefined

    const r_value = data.r_value ?? NaN
    const p_value = data.p_value ?? NaN

    const isStatisticallySignificant = p_value <= 0.05
    const rValueString = `r=${r_value.toFixed(2)}`
    const pValueString = `p=${p_value.toFixed(2)}`
    const statisticallySignificantString = 'p <= 0.05'

    const lines = [rValueString, pValueString].map((line) =>
      isStatisticallySignificant ? `${line}, ${statisticallySignificantString}` : line,
    )

    if (label) {
      lines.unshift(label)
    }

    return lines
  })

export const CorrelationGraph = (props: {
  coord: CorrelationResultCoordinate
  item: CorrelationResultGraph
  data?: CorrelationResultCorrelationData[]
  style?: CSSProperties
  selected?: CorrelationCoordinate
  onClickGraph: (coord: CorrelationCoordinate) => void
}) => {
  const {coord, item, selected} = props
  const {color, fontSize, fontWeight} = selectTheme()
  const graphSelected = selected?.row === coord.row && selected?.column === coord.column
  const style = {
    ...DefaultCorrelationGraphStyle,
    ...props.style,
    backgroundColor: graphSelected ? color.surface.grey.dark : DefaultCorrelationGraphStyle.backgroundColor,
  }

  const onClick = () => {
    props.onClickGraph({
      analysisType: props.item.analysis_type,
      row: coord.row,
      column: coord.column,
    })
  }

  const graph = props.item?.graph
  if (graph) {
    return (
      <div
        css={{
          border: `1px solid ${graphSelected ? color.border._400 : color.white}`,
          boxSize: 'border-box',
          overFlow: 'hidden',
          display: 'flex',
        }}
      >
        <CorrelationGraphPlot
          {...{
            onClick,
            graph,
            style,
          }}
        />
      </div>
    )
  }
  const data = props.data
  if (data?.length) {
    const lines = correlationDataToText(data)
    if (lines.length) {
      return (
        <CorrelationGraphStats
          {...{
            onClick,
            lines,
            style,
          }}
        />
      )
    }
  }
  return <div style={style} />
}
