import {Dispatch, SetStateAction} from 'react'
import {PopupSimple} from '..'
import {TaskReminder} from '../../model'

export interface ReminderRemovePopPropsT {
  closeAction: Dispatch<SetStateAction<boolean>>
  doAction: Dispatch<SetStateAction<TaskReminder[]>>
  onClick: any
}

export const ReminderRemovePop = (props: ReminderRemovePopPropsT) => {
  const {closeAction, doAction} = props
  return (
    <div
      id='removePopBackgroundId'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 103,
        cursor: 'auto',
      }}
    >
      <PopupSimple
        {...props}
        {...{
          name: 'Are you sure you want to remove this reminder?',
          buttonColor: 'warning',
          buttonText: `Remove this Reminder`,
          closeAction,
          doAction,
          type: 'button',
        }}
      />
    </div>
  )
}
