import { ArrayDescription } from '../algorithm'
import { StoreType } from './type'

export enum SummaryType {
  GarminDeviceDaily = 'garminDeviceDaily',
  GarminDeviceHRVDaily = 'garminDeviceHrvDaily',
}

export interface DailySummary extends StoreType {
  createdAt: Date
  participantId: string
  yymmddIndex: number
}

export interface GarminDeviceDailySummary {
  step?: TTL
  heartRate?: ArrayDescription
  pulseOx?: ArrayDescription
  stress?: ArrayDescription
}

export interface HRVDailySummary extends DailySummary {
  hrv?: {
    mrr: ArrayDescription
    sdrr: ArrayDescription
    rmssd: ArrayDescription
    prr50: ArrayDescription
    tp: ArrayDescription
    vlf: ArrayDescription
    lf: ArrayDescription
    hf: ArrayDescription
    lfhf: ArrayDescription
  }
}

interface TTL {
  total: number
}
