interface BaseResult<T, E> {
  isOk(): this is Ok<T, E>
  isErr(): this is Err<T, E>
  unwrap(): T | never
}

export type Result<T, E> = Ok<T, E> | Err<T, E>

export class Ok<T, E> implements BaseResult<T, E> {
  constructor(readonly value: T) {}

  isOk(): this is Ok<T, E> {
    return true
  }

  isErr(): this is Err<T, E> {
    return false
  }

  unwrap(): T {
    return this.value
  }
}

export class Err<T, E> implements BaseResult<T, E> {
  constructor(readonly value: E) {}

  isOk(): this is Ok<T, E> {
    return false
  }

  isErr(): this is Err<T, E> {
    return true
  }

  unwrap(): never {
    throw this.value
  }
}
