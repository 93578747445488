import {useSelector} from 'react-redux'
import {create_theme_init_state, colorTranslator} from './theme_init_state'

// ACTON TYPES
export const THEME_SET = 'THEME_SET'
export const THEME_UPDATE = 'THEME_UPDATE'

// ACTION CREATORS
const doTHEME_SET = (theme) => {
  return {
    type: THEME_SET,
    payload: theme,
  }
}

const doTHEME_RESET = () => {
  return doTHEME_SET(create_theme_init_state())
}

const doTHEME_UPDATE = (payload) => {
  return {
    type: THEME_UPDATE,
    payload,
  }
}

export const themeActionCreators = {
  doTHEME_SET,
  doTHEME_UPDATE,
  doTHEME_RESET,
}

/* store.theme reducer */
const INIT_STATE = create_theme_init_state()
export const themeReducer = (state = INIT_STATE, {type, payload}) => {
  switch (type) {
    case THEME_SET:
      return payload
    case THEME_UPDATE:
      return {...state, ...payload}
    default:
      return state
  }
}

/* selector */
export const selectTheme = () => {
  return useSelector((state) => state.theme)
}

export {colorTranslator} from './theme_init_state'
