import React from 'react'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {CheckIcon} from '../../asset/image'
import {RadioGroup as ReactRadioGroup, Radio as ReactRadio, RadioGroup} from 'react-radio-group'

interface Props {
  taskColor: string
  setTaskColor: (e: string) => void
}

export const ColorSelector = (props: Props) => {
  const {color, taskColorList} = selectTheme()
  const {taskColor, setTaskColor} = props

  const handleChangeColor = (e: string) => {
    if (setTaskColor) setTaskColor(e)
  }

  return (
    <ReactRadioGroup name='colorSelector' onChange={handleChangeColor}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {taskColorList?.map((colorValue: string) => {
          return (
            <label
              key={colorValue}
              css={{
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '5px',
                marginRight: '8px',
                cursor: 'pointer',
                border: taskColor === colorValue ? `1px solid ${color.grey_300}` : 'none',
              }}
            >
              <div
                css={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  textAlign: 'center',
                  backgroundColor: colorValue as string,
                }}
              >
                {RIF(taskColor === colorValue, <img width='12' src={CheckIcon} />)}
              </div>
              <ReactRadio css={{display: 'none'}} value={colorValue}></ReactRadio>
            </label>
          )
        })}
      </div>
    </ReactRadioGroup>
  )
}
