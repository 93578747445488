import {
  API_URL,
  SagaIterator,
  assertTotalSchema,
  call,
  createDoRequestAction,
  debug,
  put,
  request,
  takeEvery,
  v,
} from '../../lib'
import {
  doBATCH_PARTICIPANT_TAG_LIST_UPDATE,
  doPROJECT_TAG_LIST_ADD,
  doREQUEST_COMPLETE,
  doREQUEST_ERROR,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_TAG_UPDATE = 'REQUEST_PROJECT_TAG_UPDATE'
export const doREQUEST_PROJECT_TAG_UPDATE = createDoRequestAction(REQUEST_PROJECT_TAG_UPDATE)

export const requestProjectTagUpdateActionCreators = {
  doREQUEST_PROJECT_TAG_UPDATE,
}

export function* requestProjectTagUpdate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectTagUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v
        .object({
          requestId: v.string().uuid().exist(),
          projectId: v.string().uuid().exist(),
          tagId: v.string().uuid().exist(),
          color: v.string().hex().length(6),
          value: v.string().max(250),
        })
        .or('color', 'value'),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_TAG_UPDATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {projectId, tagId, color, value} = payload
  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/project-tag-update`,
    accessToken,
    data: {
      projectId,
      tagId,
      color,
      value,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_TAG_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-tag-update',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  // PROJECT_TAG_LIST_ADD does uniqBy so it suits the use case
  yield put(
    doPROJECT_TAG_LIST_ADD({
      id: projectId,
      tagList: Array.of(result.payload),
    }),
  )

  yield put(
    doBATCH_PARTICIPANT_TAG_LIST_UPDATE({
      projectId,
      tags: Array.of(result.payload),
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_TAG_UPDATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* projectTagUpdateSaga() {
  yield takeEvery(REQUEST_PROJECT_TAG_UPDATE, requestProjectTagUpdate)
}
