import {useState, useEffect} from 'react'

export const useProgressiveImg = (lowQualitySrc: any, highQualitySrc: any) => {
  const [imgSrc, setImgSrc] = useState(lowQualitySrc)

  useEffect(() => {
    setImgSrc(lowQualitySrc)

    const img = new Image()
    img.src = highQualitySrc

    img.onload = () => {
      setImgSrc(highQualitySrc)
    }
  }, [lowQualitySrc, highQualitySrc])

  return imgSrc
}
