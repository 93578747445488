import {ChartConfig, DataConverter, DataConverterOption, TimeSeriesChartType} from '../template/time_series_data_chart'
import {StreamDataChart, StreamDataChartProps} from '../template/stream_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import {TimeSeriesDataSource} from '../../../model'
import { VisualizerGraphDataType } from '../../../shared/db'

export const MovesenseImuChart = (props: StreamDataChartProps) => {
  const {height, width, completionIdList, plotDataRange, commonTaskDataMarkerList} = props

  const accConverter: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    return plotData.map((item) => {
      return {t: item.t, x: item.ax, y: item.ay, z: item.az}
    })
  }

  const accScaleOption = {min: -15, max: 15}
  const defaultDataConverterOption: DataConverterOption = {
    label: 'Acc',
    value: 'Acc',
    dataConverter: accConverter,
    chartConfig: {
      chartType: TimeSeriesChartType.Line,
    },
  }

  const gyroConverter: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    return plotData.map((item) => {
      return {t: item.t, x: item.gx, y: item.gy, z: item.gz}
    })
  }

  const magnetoConverter: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    return plotData.map((item) => {
      return {t: item.t, x: item.mx, y: item.my, z: item.mz}
    })
  }

  const dataConverterOptions: DataConverterOption[] = [
    defaultDataConverterOption,
    {
      label: 'Gyro',
      value: 'Gyro',
      dataConverter: gyroConverter,
      chartConfig: {
        chartType: TimeSeriesChartType.Line,
      },
    },
    {
      label: 'Magneto',
      value: 'Magneto',
      dataConverter: magnetoConverter,
      chartConfig: {
        chartType: TimeSeriesChartType.Line,
      },
    },
  ]

  const dataConfig = {
    defaultDataConverterOption,
    dataConverterOptions,
  }

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
  }


  const chartCardConfig = {
    height,
    width,
    title: 'Movesense Imu',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const streamDataChartProp = {
    dataType: VisualizerGraphDataType.MovesenseIMU,
    config,
    completionIdList,
    plotDataRange,
    commonTaskDataMarkerList,
    defaultScaleOption: accScaleOption
  }

  return (
    <>
      <StreamDataChart {...streamDataChartProp} />
    </>
  )
}
