import {format, formatInTimeZone, utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz'
import {
  isValid,
  addHours,
  addDays,
  startOfWeek,
  startOfDay,
  isBefore,
  differenceInDays,
  formatDistanceToNowStrict,
} from 'date-fns'
// docs: https://date-fns.org/v2.29.3/docs/format

export enum TimeConvertType {
  createAt = 'createAt',
  AMPM = 'AMPM',
  lastSeen = 'lastSeen',
  lastSeen24 = 'lastSeen24',
  lastDataUpload = 'lastDataUpload',
  distanceToNow = 'distanceToNow',
  entireWeek = 'entireWeek',
  todayWeekFormat = 'todayWeekFormat',
  adherenceSidebarTitle = 'adherenceSidebarTitle',
  firstDayOfWeekUtc = 'firstDayOfWeekUtc',
  firstDayOfWeekLocal = 'firstDayOfWeekLocal',
  adherenceDisplaySwitchWeek = 'adherenceDisplaySwitchWeek',
  adherenceSwitchWeek = 'adherenceSwitchWeek',
  bbiAccumulated = 'bbiAccumulated',
  timerDuration = 'timerDuration',
  formatMinutes = 'formatMinutes',
  realTimeUTCClock = 'realTimeUTCClock',
  compareIsBeforeDate = 'compareIsBeforeDate',
  compareIsFutureDate = 'compareIsFutureDate',
  sinceTime = 'sinceTime',
  diffInDay = 'diffInDay',
  formatToYYMMDD = 'formatToYYMMDD',
  localizedUtcCustomFormat = 'localizedUtcCustomFormat',
}

interface TimeConvertProps {
  time: Date | string | number
  type: TimeConvertType
  arg?: Date | string | number
  utc?: boolean
}

// format fn only convert local time, so need to force to "UTC local time" at first or force convert "local UTC time"
const UTCToLocal = (time: Date | string | number): Date => utcToZonedTime(time, 'UTC')
const localToUTC = (time: Date | string | number): Date => zonedTimeToUtc(time, 'UTC')

const DEFAULT_START_OF_WEEK = 1 // Monday

export const timeConvert = ({time, type, arg, utc}: TimeConvertProps) => {
  if (!isValid(time) || !type) return

  switch (type) {
    case TimeConvertType.createAt: // Sep 22, 2022
      if (utc) return format(UTCToLocal(time), 'LLL dd, yyyy')
      else return format(time, 'LLL dd, yyyy')

    case TimeConvertType.sinceTime: // Sep 5 (used in Project Card)
      if (utc) return format(UTCToLocal(time), 'MMM d')
      return format(time, 'MMM d')

    case TimeConvertType.diffInDay:
      return differenceInDays(localToUTC(new Date()), UTCToLocal(time))

    case TimeConvertType.realTimeUTCClock: // 08:52:27 06/10/2022
      return format(UTCToLocal(time), `kk:mm:ss dd/MM/yyyy`)

    case TimeConvertType.AMPM: // 6:49 AM
      return format(UTCToLocal(time), 'h:mm aa')

    case TimeConvertType.lastSeen: // Oct 6, 12:23 AM
      if (utc) return format(UTCToLocal(time), 'LLL d, h:mm aa')
      else return format(time, 'LLL d, h:mm aa')

    case TimeConvertType.lastSeen24: // Oct 6, 12:23
      if (utc) return format(UTCToLocal(time), 'LLL d, H:mm')
      else return format(time, 'LLL d, H:mm')

    case TimeConvertType.lastDataUpload: // 12:23 AM, Oct 6
      if (utc) return format(UTCToLocal(time), 'h:mm aa, LLL d')
      else return format(time, 'h:mm aa, LLL d')

    case TimeConvertType.distanceToNow: // https://date-fns.org/v2.29.3/docs/formatDistanceToNowStrict
      return formatDistanceToNowStrict(time as Date)

    case TimeConvertType.adherenceSidebarTitle: // Thursday, 6 Oct, 2022
      if (typeof time === 'number') {
        const utcDate = parseYYMMDDIndexToDate(time, true)
        const timeOffsetMs = new Date().getTimezoneOffset() * 60000
        const date = new Date(utcDate.getTime() + timeOffsetMs)
        return format(date, 'cccc, d LLL, yyyy')
      }
      return

    case TimeConvertType.firstDayOfWeekUtc: {
      if (typeof time === 'string') return
      const firstDayOfWeek = startOfWeek(time, {weekStartsOn: DEFAULT_START_OF_WEEK}) // base on UI, start on Monday
      return localToUTC(firstDayOfWeek)
    }

    case TimeConvertType.firstDayOfWeekLocal: {
      if (typeof time === 'string') return
      return startOfWeek(time, {weekStartsOn: DEFAULT_START_OF_WEEK}) // base on UI, start on Monday
    }

    case TimeConvertType.todayWeekFormat: {
      // MON (10/3)
      return toAdherenceTableHeaderDateString(time as Date)
    }

    case TimeConvertType.adherenceDisplaySwitchWeek: {
      // Sep 26 - Oct 2
      const dayOfWeek = time as Date
      const firstDayOfWeek = format(dayOfWeek, 'LLL d')
      const lastDayOfWeek = addDays(dayOfWeek, 6)
      const lastDayOfWeekFormat = format(lastDayOfWeek, 'LLL d')
      return `${firstDayOfWeek} - ${lastDayOfWeekFormat}`
    }

    // adherence table header used
    // ['MON (10/3)', 'TUE (10/4)', 'WED (10/5)', 'THU (10/6)', 'FRI (10/7)', 'SAT (10/8)', 'SUN (10/9)']
    case TimeConvertType.entireWeek: {
      const week = []
      let dayOfWeek = time as Date
      for (let i = 0; i < 7; i++) {
        week.push(toAdherenceTableHeaderDateString(dayOfWeek))
        dayOfWeek = addDays(dayOfWeek, 1)
      }
      return week
    }

    // add or sub 7 day, return first day of week
    case TimeConvertType.adherenceSwitchWeek: {
      if (typeof time === 'string' || typeof arg !== 'number') return

      const firstDayOfWeek = addDays(time, arg * 7)
      const hour = firstDayOfWeek.getHours()

      if (hour == 0) {
        return firstDayOfWeek
      } else {
        // daylight saving handle
        if (hour > 12) {
          return addHours(firstDayOfWeek, 24 - hour)
        } else {
          return addHours(firstDayOfWeek, 0 - hour)
        }
      }
    }

    case TimeConvertType.bbiAccumulated: {
      // 20 hr 48 min 51 sec
      if (typeof time !== 'number') return
      if (time >= 86400000) return '~ 24 hr'
      const duration = Math.floor(time / 1000)
      const hours = Math.floor(duration / 3600)
      const minutes = Math.floor((duration % 3600) / 60)
      const remainingSeconds = duration % 60

      if (hours > 0) {
        return `${hours} hr ${minutes} min ${remainingSeconds} sec`
      } else if (minutes > 0) {
        return `${minutes} min ${remainingSeconds} sec`
      } else {
        return `${remainingSeconds} sec`
      }
    }

    case TimeConvertType.formatMinutes: {
      // 20 hr 48 min
      if (typeof time !== 'number') return
      if (time >= 1440) return '24 hr'
      const hours = Math.floor(time / 60)
      const minutes = Math.floor(time % 60)

      if (hours > 0) {
        return `${hours} hr ${minutes} min`
      } else {
        return `${minutes} min`
      }
    }

    case TimeConvertType.timerDuration: {
      // 20 hr 48 min 51 sec
      if (typeof time !== 'number' || typeof arg !== 'number') return
      const duration = Math.floor((arg - time) / 1000)
      const hours = Math.floor(duration / 3600)
      const minutes = Math.floor((duration % 3600) / 60)
      const remainingSeconds = duration % 60

      if (hours > 0) {
        return `${hours} hr ${minutes} min ${remainingSeconds} sec`
      } else if (minutes > 0) {
        return `${minutes} min ${remainingSeconds} sec`
      } else {
        return `${remainingSeconds} sec`
      }
    }

    case TimeConvertType.compareIsBeforeDate: {
      if (!arg) return
      const UTCLocal = UTCToLocal(time)
      const startDayTime = startOfDay(UTCLocal)
      const localUTC = localToUTC(startDayTime)
      const compareWith = new Date(arg)
      return isBefore(compareWith, localUTC)
    }

    case TimeConvertType.compareIsFutureDate: {
      if (!arg) return
      const UTCLocal = UTCToLocal(time)
      const startDayTime = startOfDay(UTCLocal)
      const localUTC = localToUTC(startDayTime)
      const compareWith = new Date(arg)
      return isBefore(localUTC, compareWith)
    }

    case TimeConvertType.formatToYYMMDD: {
      return format(time, `yyMMdd`)
    }

    case TimeConvertType.localizedUtcCustomFormat: {
      if (typeof time !== 'number' || !arg || typeof arg !== 'string') return

      const timeOffsetMs = new Date().getTimezoneOffset() * 60000
      const date = new Date(time + timeOffsetMs)
      return format(date, arg as string)
    }
  }
}

const toAdherenceTableHeaderDateString = (time: Date): string => {
  const day = format(time, 'EEE')
  const date = format(time, ' (M/d)')
  return day.toUpperCase() + date
}

const parseYYMMDDIndexToDate = (yymmddIndex: number, utc?: boolean): Date => {
  const year = 2000 + Math.trunc(yymmddIndex / 10000)
  const month = Math.trunc((yymmddIndex % 10000) / 100)
  const day = Math.trunc(yymmddIndex % 100)

  if (utc) {
    return new Date(Date.UTC(year, month - 1, day))
  } else {
    return new Date(year, month - 1, day)
  }
}
