import { TaskType } from '../db'
import {
  TaskDataType,
  GarminDeviceLogDataType,
  MovesenseDeviceStreamDataType,
  GarminDeviceStreamDataType,
} from './enums'
import {
  QuestionnaireQuestionData,
  QuestionnaireSectionData,
} from './localdate_task_record'
import { OptionalId } from './type'

export type ParticipantUploadDataType =
  | TaskDataType
  | GarminDeviceLogDataType
  | MovesenseDeviceStreamDataType
  | GarminDeviceStreamDataType

export interface ParticipantUploadData<
  T extends ParticipantUploadDataContentType = ParticipantUploadDataContentType,
> extends OptionalId {
  backfill?: boolean
  createdAt: Date
  participantId: string
  participantInsignia: null | string
  projectId: string
  batchId: null | string
  taskId: string
  taskType: TaskType
  dataType?: ParticipantUploadDataType
  garminDeviceType?: string
  timezone: string
  timeOffsetToUTCInMs?: number
  taskSetItterationId?: string
  data?: T
}

export type ParticipantUploadDataContentType =
  | ParticipantUploadTaskData
  | ParticipantUploadDataGarminDevice
  | ParticipantUploadDataMovesense

export type ParticipantUploadTaskData =
  | ParticipantUploadDataTodo
  | ParticipantUploadDataTimer
  | ParticipantUploadDataQuestionnaire
  | ParticipantUploadDataStopwatchStreamDataEvent

export type ParticipantUploadDataGarminDevice =
  | ParticipantUploadDataGarminDeviceBBI
  | ParticipantUploadDataGarminDeviceStep
  | ParticipantUploadDataGarminDevicePulseOx
  | ParticipantUploadDataGarminDeviceAcc
  | ParticipantUploadDataGarminDeviceHeartRate
  | ParticipantUploadDataGarminDeviceRespiration
  | ParticipantUploadDataGarminDeviceStress
  | ParticipantUploadDataGarminDeviceZeroCrossing
  | ParticipantUploadDataGarminDeviceActigraphy

export type ParticipantUploadDataMovesense =
  | ParticipantUploadDataMovesenseImu
  | ParticipantUploadDataMovesenseAcc
  | ParticipantUploadDataMovesenseGyroscope
  | ParticipantUploadDataMovesenseMagnetometer
  | ParticipantUploadDataMovesenseEcg
  | ParticipantUploadDataMovesenseRr

export interface ParticipantUploadDataTodo {
  unixTimestampComplete: number
}

export interface ParticipantUploadDataTimer {
  unixTimestampStart: number
  unixTimestampComplete: number
}

export interface ParticipantUploadDataQuestionnaire {
  unixTimestampComplete: number
  sectionList: QuestionnaireSectionData[]
}

export type ParticipantUploadDataQuestionnaireSection = QuestionnaireSectionData
export type ParticipantUploadDataQuestionnaireQuestion =
  QuestionnaireQuestionData

export interface ParticipantUploadDataGarminDeviceBBI {
  garminDeviceType: string
  data: ParticipantUploadDataGarminDeviceBBIItem[]
}

export interface UnixTimestampItem {
  unixTimestamp: number
}

export interface ParticipantUploadDataGarminDeviceBBIItem
  extends UnixTimestampItem {
  value: number
}

export type ParticipantUploadDataGarminDeviceStep =
  ParticipantUploadDataGarminDeviceStepItem[]

export interface ParticipantUploadDataGarminDeviceStepItem
  extends UnixTimestampItem {
  steps: number
  duration: number
  totalSteps: number
}

export type ParticipantUploadDataGarminDevicePulseOx =
  ParticipantUploadDataGarminDevicePulseOxItem[]

export interface ParticipantUploadDataGarminDevicePulseOxItem
  extends UnixTimestampItem {
  confidence: number
  pulseOxReading: number
}

export type ParticipantUploadDataGarminDeviceAcc =
  ParticipantUploadDataGarminDeviceAccItem[]

export interface ParticipantUploadDataGarminDeviceAccItem
  extends UnixTimestampItem {
  x: number
  y: number
  z: number
}

export type ParticipantUploadDataGarminDeviceHeartRate =
  ParticipantUploadDataGarminDeviceHeartRateItem[]

export interface ParticipantUploadDataGarminDeviceHeartRateItem
  extends UnixTimestampItem {
  status: string
  beatsPerMinute: number
}

export type ParticipantUploadDataGarminDeviceRespiration =
  ParticipantUploadDataGarminDeviceRespirationItem[]

export interface ParticipantUploadDataGarminDeviceRespirationItem
  extends UnixTimestampItem {
  breathsPerMinute: number
}

export type ParticipantUploadDataGarminDeviceStress =
  ParticipantUploadDataGarminDeviceStressItem[]

export interface ParticipantUploadDataGarminDeviceStressItem
  extends UnixTimestampItem {
  stressLevel: number
  averageIntensity: number
}

export type ParticipantUploadDataGarminDeviceZeroCrossing =
  ParticipantUploadDataGarminDeviceZeroCrossingItem[]

export interface ParticipantUploadDataGarminDeviceZeroCrossingItem
  extends UnixTimestampItem {
  duration: number
  count: number
  energy: number
}

export type ParticipantUploadDataGarminDeviceActigraphy =
  ParticipantUploadDataGarminDeviceActigraphyItem[]

export interface ParticipantUploadDataGarminDeviceActigraphyItem
  extends UnixTimestampItem {
  zcCount: number
  tatTime: number
  energy: number
}

export interface ParticipantUploadDataStopwatchStreamDataEvent {
  unixTimestampStart: number
  unixTimestampComplete: number
  completionId: string
}

export type ParticipantUploadDataMovesenseImuSampleList =
  ParticipantUploadDataMovesenseImuSample[]

export interface ParticipantUploadDataMovesenseImuSample {
  unixTimestamp: number
  acc: {
    x: number
    y: number
    z: number
  }
  gyroscope: {
    x: number
    y: number
    z: number
  }
  magnetometer: {
    x: number
    y: number
    z: number
  }
}

export interface ParticipantUploadDataMovesenseImu {
  completionId: string
  unixTimestampStart: number
  imuSampleList: ParticipantUploadDataMovesenseImuSampleList
}

export type ParticipantUploadDataXYZSampleList =
  ParticipantUploadDataXYZSample[]

export interface ParticipantUploadDataXYZSample {
  unixTimestamp: number
  x: number
  y: number
  z: number
}

export interface ParticipantUploadDataGarminStreamAcc {
  completionId: string
  unixTimestampStart: number
  accSampleList: ParticipantUploadDataXYZSampleList
}

export interface ParticipantUploadDataMovesenseAcc {
  completionId: string
  unixTimestampStart: number
  accSampleList: ParticipantUploadDataXYZSampleList
}

export interface ParticipantUploadDataMovesenseGyroscope {
  completionId: string
  unixTimestampStart: number
  gyroscopeSampleList: ParticipantUploadDataXYZSampleList
}

export interface ParticipantUploadDataMovesenseMagnetometer {
  completionId: string
  unixTimestampStart: number
  magnetometerSampleList: ParticipantUploadDataXYZSampleList
}

export type ParticipantUploadDataMovesenseEcgSampleList =
  ParticipantUploadDataMovesenseEcgSample[]

export interface ParticipantUploadDataMovesenseEcgSample {
  unixTimestamp: number
  ecgValueList: number[]
}

export interface ParticipantUploadDataMovesenseEcg {
  completionId: string
  unixTimestampStart: number
  ecgHz: number
  ecgSampleList: ParticipantUploadDataMovesenseEcgSampleList
}

export type ParticipantUploadDataMovesenseRrList =
  ParticipantUploadDataMovesenseRrItem[]

export interface ParticipantUploadDataMovesenseRrItem {
  unixTimestamp: number
  rrValue: number
}

export interface ParticipantUploadDataMovesenseRr {
  completionId: string
  unixTimestampStart: number
  data: ParticipantUploadDataMovesenseRrList
}

export function isParticipantUploadDataTodo(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataTodo> {
  return data.taskType == TaskType.Todo
}

export function isParticipantUploadDataTimer(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataTimer> {
  return data.taskType == TaskType.Timer
}

export function isParticipantUploadDataQuestionnaire(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataQuestionnaire> {
  return data.taskType == TaskType.Questionnaire
}

export function isParticipantUploadDataGarminDevice(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDevice> {
  return data.taskType == TaskType.GarminDevice
}

export function isParticipantUploadDataGarminDeviceBBI(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceBBI> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminBBI
  )
}

export function isParticipantUploadDataGarminDeviceStep(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceStep> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminStep
  )
}

export function isParticipantUploadDataGarminDevicePulseOx(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDevicePulseOx> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminPulseOx
  )
}

export function isParticipantUploadDataGarminDeviceAcc(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceAcc> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminAcc
  )
}

export function isParticipantUploadDataGarminDeviceHeartRate(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceHeartRate> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminHeartRate
  )
}

export function isParticipantUploadDataGarminDeviceRespiration(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceRespiration> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminRespiration
  )
}

export function isParticipantUploadDataGarminDeviceStress(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceStress> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminStress
  )
}

export function isParticipantUploadDataGarminDeviceZeroCrossing(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataGarminDeviceZeroCrossing> {
  return (
    data.taskType == TaskType.GarminDevice &&
    data.dataType == GarminDeviceLogDataType.GarminZeroCrossing
  )
}

export function isParticipantUploadDataMovesenseStopwatchEvent(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataStopwatchStreamDataEvent> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == TaskDataType.MovesenseStopwatchEvent
  )
}

export function isParticipantUploadDataMovesenseImu(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataMovesenseImu> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == MovesenseDeviceStreamDataType.MovesenseImu
  )
}

export function isParticipantUploadDataMovesenseAcc(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataMovesenseAcc> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == MovesenseDeviceStreamDataType.MovesenseAcc
  )
}

export function isParticipantUploadDataMovesenseGyroscope(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataMovesenseGyroscope> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == MovesenseDeviceStreamDataType.MovesenseGyroscope
  )
}

export function isParticipantUploadDataMovesenseMagnetometer(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataMovesenseMagnetometer> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == MovesenseDeviceStreamDataType.MovesenseMagnetometer
  )
}

export function isParticipantUploadDataMovesenseEcg(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataMovesenseEcg> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == MovesenseDeviceStreamDataType.MovesenseEcg
  )
}

export function isParticipantUploadDataMovesenseRr(
  data: ParticipantUploadData,
): data is ParticipantUploadData<ParticipantUploadDataMovesenseRr> {
  return (
    data.taskType == TaskType.StopwatchMovesenseStream &&
    data.dataType == MovesenseDeviceStreamDataType.MovesenseRr
  )
}
