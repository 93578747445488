import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {AddSchedulePop, ButtonReverse, LocalSchedule, RequiredTag, ScheduleTag} from '..'
import {CalendarIcon, PlusBlueIcon} from '../../asset/image'
import {IProjectScheduleConfig} from '../../shared/db'

export interface AddTaskNewScheduleSectionPropsT {
  taskId: string
  projectScheduleConfig: IProjectScheduleConfig
  allScheduleList: LocalSchedule[][]
  setAllScheduleList: Dispatch<SetStateAction<LocalSchedule[][]>>
}

export const AddTaskNewScheduleSection = (props: AddTaskNewScheduleSectionPropsT) => {
  const {color, fontWeight} = selectTheme()
  const {projectScheduleConfig, allScheduleList, setAllScheduleList} = props
  const [displaySchedulePop, setDisplaySchedulePop] = useState(false)
  const [editingScheduleList, setEditingScheduleList] = useState<LocalSchedule[] | undefined>(undefined)

  return (
    <div
      data-testid='add_task_new_schedule_section'
      css={{marginBottom: '80px', paddingTop: '40px', borderTop: `1px solid ${color.grey_100}`}}
    >
      <div css={{display: 'flex', justifyContent: 'space-between', marginBottom: '32px'}}>
        <div
          css={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '144px',
          }}
        >
          <div>
            <div css={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
              <img src={CalendarIcon} width={20} height={20} />
              <p css={{fontWeight: '500', marginLeft: '8px'}}>Task Schedule</p>
              <RequiredTag />
            </div>
            <p css={{color: color.grey_600}}>
              Set up task schedule so participants know when they should complete this task.
            </p>
          </div>
          <ButtonReverse
            onClick={() => {
              setDisplaySchedulePop(true)
              setEditingScheduleList(undefined)
            }}
            css={{marginTop: '24px'}}
            {...{
              btnPadding: 'medium',
              children: (
                <div css={{display: 'flex', alignItems: 'center', color: color.primary, fontWeight: fontWeight.bold}}>
                  <img src={PlusBlueIcon} width='18' css={{marginRight: '3px'}} />
                  Add an Task Schedule
                </div>
              ),
            }}
          />
        </div>
      </div>

      {/* reminder tag */}
      {allScheduleList.map((scheduleList, index) => (
        <ScheduleTag
          {...{
            key: index,
            setDisplaySchedulePop,
            setEditingScheduleList,
            setAllScheduleList,
            allScheduleList,
            scheduleList,
            index
          }}
        />
      ))}

      {RIF(
        displaySchedulePop,
        <AddSchedulePop
          {...{
            projectScheduleConfig,
            editingScheduleList,
            allScheduleList,
            setAllScheduleList,
            setDisplaySchedulePop,
          }}
        />,
      )}
    </div>
  )
}
