import { CSSProperties } from 'react'
import {selectTheme} from '../../store'

export interface StepProps {
  text: string
  textColor?: string
  backgroundColor?: string
  css?: CSSProperties
}

export const Step = (props: StepProps) => {
  const {fontSize, fontWeight, color} = selectTheme()
  const value = props.text
  const textColor = props.textColor ?? color.white
  const backgroundColor = props.backgroundColor ?? color.surface.dark

  return (
      <div
        css={{
          padding: '4px 8px',
          backgroundColor,
          borderRadius: 5,
          ...props.css
        }}
      >
        <span
          style={{
            color: textColor,
            fontSize: fontSize.h7,
            fontWeight: fontWeight.thick,
            wordBreak: 'break-word',
          }}
        >
          {value}
        </span>
      </div>
  )
}
