import {useEffect, useState, Dispatch, SetStateAction} from 'react'
import {
selectTheme,
  createDispatchActions,
  selectCollaborators,
} from '../../store'
import {
  v,
  useForm,
  RIF,
  handleEsc,
  CreateDoRequestActionInput,
  _, useCurrentProjectState, useCurrentWorkspaceState,
  RequestResult,
} from '../../lib'
import {Input, TextLink, Button, Select} from '..'
import {WarningIcon} from '../../asset/image'
import { Collaborator } from '../../model'
import { Controller } from 'react-hook-form'

interface ManageProjectCollaboratorPopupProps {
  workspaceId: string
  setShowingManageProjectCollaboratorPopup: Dispatch<SetStateAction<boolean>>
  setFetchCollaboratorList: Dispatch<SetStateAction<boolean>>
  editingCollaborator: Collaborator | null
  setEditingCollaborator?: Dispatch<SetStateAction<Collaborator | null>>
}

export const ManageProjectCollaboratorPopup = (props: ManageProjectCollaboratorPopupProps) => {
  const {color, fontWeight} = selectTheme()

  const {workspaceId, setShowingManageProjectCollaboratorPopup, setFetchCollaboratorList, editingCollaborator, setEditingCollaborator} = props
  const {
    doREQUEST_WORKSPACE_COLLABORATOR_CREATE,
    doREQUEST_WORKSPACE_COLLABORATOR_UPDATE,
  }: Record<string, Dispatch<CreateDoRequestActionInput>> = createDispatchActions()

  const { projectId } = useCurrentProjectState()
  const { workspace: currentWorkspace } = useCurrentWorkspaceState()
  // const userPermission = workspacesState.roleInWorkspaces[currentWorkspace.id] || 'owner'

  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const collaboratorsState = selectCollaborators()
  const [currentWorkspaceCollaboratorEmails, setCurrentWorkspaceCollaboratorEmails] = useState<{
    email?: string
  }[]>([])
  const projectPermissionOptions = [
    {value: 'projectAdmin', label: 'Admin'},
    {value: 'projectEditor', label: 'Editor'},
    {value: 'projectViewer', label: 'Viewer'},
  ]

  // push owner profile to collaborator list
  useEffect(() => {
    if (workspaceId && collaboratorsState?.[workspaceId]) {
      const collaboratorList = Object.values(collaboratorsState?.[workspaceId])
        .map((collaborator) => {
          return { email: collaborator.profile.email}
        })
      collaboratorList.push({email: currentWorkspace?.ownerProfile?.email})
      setCurrentWorkspaceCollaboratorEmails(collaboratorList)
    }
  }, [workspaceId])

  const getPermissionForProjectCollaborator = () => {
    return {value: 'projectViewer', label: 'Viewer'}
  }

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(true)
  const [mailWarningMsg, setMailWarningMsg] = useState<string | null>(null)
  const {register, handleSubmit, watch, control} = useForm({mode: 'onChange'})

  useEffect(() => {
    if (editingCollaborator) return setSubmitButtonDisabled(false)
    setSubmitButtonDisabled(!watch('mail'))
    // reset warning msg on input
    setMailWarningMsg(null) 

    if (_.find(currentWorkspaceCollaboratorEmails, c =>
      c?.email === watch('mail')
    )) setMailWarningMsg('This collaborator is already exist.')
  }, [watch('mail')])

  const emailSchema = v.string().email({tlds: {allow: false}})

  const onSubmit = (data: Record<string, any>) => {
    if (submitButtonDisabled || !!mailWarningMsg) return
    const {error} = emailSchema.validate(data.mail)
    if (error) setMailWarningMsg('Invalid email address. Please check it for typos or mistakes.')
    if (!editingCollaborator) {
      doREQUEST_WORKSPACE_COLLABORATOR_CREATE({
        setRequestResult,
        payload: {
          email: data.mail,
          workspaceId,
          type: 'project_collaborator',
          projectCollaboratorList: [{projectId, type: watch('permission').value}]
        },
      })
    } else {
      doREQUEST_WORKSPACE_COLLABORATOR_UPDATE({
        setRequestResult,
        payload: {
          workspaceCollaboratorId: editingCollaborator.id,
          type: 'project_collaborator',
          projectCollaboratorAction: {
            upsert: [{projectId, type: watch('permission').value}]
          }
        },
      })
    }
  }

  useEffect(() => {
    if (!requestResult) return
    if (requestResult?.result?.statusCode === 400)
      return setMailWarningMsg('Invalid email address. Please check it for typos or mistakes.')
    if (requestResult?.result?.statusCode === 410)
      return setMailWarningMsg('This user has not joined Labfront yet. Please ask them to create an account, then re-add them as a collaborator.')

    if (requestResult?.result) {
      setFetchCollaboratorList(true)
      setShowingManageProjectCollaboratorPopup(false)
    }
  }, [requestResult])

  const onError = (error: unknown) => {
    return
  }

  const handleClose = () => {
    if (editingCollaborator && setEditingCollaborator) setEditingCollaborator(null)
    setShowingManageProjectCollaboratorPopup(false)
  }

  // press esc to cancel
  handleEsc(handleClose)

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99999,
      }}
    >
      <form 
        onSubmit={handleSubmit(onSubmit, onError)}
        css={{
          borderRadius: '5px',
          backgroundColor: color.white,
          width: '560px',
        }}
      >
        <p css={{
          fontWeight: fontWeight.thick,
          width: '100%',
          padding: '16px 24px', 
          borderBottom: `1px solid ${color.border._80}`,
        }}>{editingCollaborator ? 'Edit Collaborator' : 'Invite Collaborator'}</p>
                <div css={{
          width: '100%',
          borderBottom: `1px solid ${color.border._80}`,
          padding: '24px',
        }}>
          <p css={{
            fontWeight: fontWeight.thick,
            marginBottom: '4px',
          }}>Email</p>
          {RIF(
            !editingCollaborator,
            <div css={{marginBottom: '32px'}}>
              <Input
                {...register('mail')}
                placeholder='example@labfront.com'
                type='email'
                hasError={!!mailWarningMsg}
                css={{
                  width: '100%',
                  height: '40px',
                }}
              />
              {RIF(
                !!mailWarningMsg,
                <div css={{display: 'flex', alignItems: 'center', marginTop: '8px'}}>
                  <img src={WarningIcon} width='20' />
                  <span css={{marginLeft: '8px', color: color.warning}}>{mailWarningMsg}</span>
                </div>,
              )}
            </div>
          )}
          {RIF(
            editingCollaborator,
            <div css={{
              marginBottom: '32px',
              width: '100%',
              height: '40px',
              padding: '11px 20px',
              display: 'flex',
              alignItems: 'center',
              border: `1px solid ${color.border._160}`,
              borderRadius: '5px',
              backgroundColor: color.surface.grey.background,
              color: color.textIcon.light,
            }}>{editingCollaborator?.profile.email}</div>
          )}
          <Controller
            control={control}
            defaultValue={getPermissionForProjectCollaborator()}
            name={'permission'}
            render={({field: {name, value, onChange}}) => (
              <Select
                {...{
                  name,
                  value,
                  onChange
                }}
                css={{width: '120px'}}
                options={projectPermissionOptions}
              />
            )}
          />
        </div>
        <div css={{
          padding: '16px 24px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <TextLink onClick={handleClose}>Cancel</TextLink>
          <Button 
            disabled={submitButtonDisabled || !!mailWarningMsg}
          >{editingCollaborator ? 'Save' : 'Send Invitation'}</Button>
        </div>
      </form>
    </div>
  )
}