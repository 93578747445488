import {Dispatch, SetStateAction} from 'react'
import {PopupSimple} from '..'

export interface ProjectSavePopProps {
  closeAction: Dispatch<SetStateAction<boolean>>
  doAction: () => void
}

export const ProjectSavePop = (props: ProjectSavePopProps) => {
  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '101',
      }}
    >
      <PopupSimple
        {...props}
        method=''
        name='Save changes and update participant app?'
        description='This will automatically update the participant app. Depending on participants’ internet connection, it may take a moment for changes to be seen.'
        buttonColor='primary'
        buttonText='Save &amp; Update'
      />
    </div>
  )
}
