import {Dispatch, SetStateAction, useState} from 'react'
import {selectTheme} from '../../store'
import {DeviceGarmin, DexcomIntegrationForm, PricingPage} from '..'
import {RIF, _} from '../../lib'
import {TaskTypeForInstructionPage} from '../../model'
export interface DevicePageProps {
  device: 'garmin_device' | 'smartphone' | 'polar' | 'dexcomIntegrationForm' | 'dexcomSelectorPopup' | ''
  closeAction: () => void
  setDisplayInstructionPop: Dispatch<SetStateAction<TaskTypeForInstructionPage>>
}

export const DevicePage = (props: DevicePageProps) => {
  const {color} = selectTheme()
  const {device, closeAction, setDisplayInstructionPop} = props
  const [displayChangePlanPage, setDisplayChangePlanPage] = useState(false)

  return (
    <div
      data-testid='device_page'
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        zIndex: 102,
        background: color.background,
        marginTop: '30px',
        padding: '60px 20px',
      }}
    >
      {RIF(
        displayChangePlanPage,
        <PricingPage
          {...{
            setDisplayChangePlanPage,
          }}
        />,
      )}
      {RIF(
        device === 'garmin_device',
        <DeviceGarmin
          {...{
            closeAction,
            setDisplayInstructionPop,
            setDisplayChangePlanPage,
          }}
        />,
      )}

      {/* TODO: smartphones and polar or others, create new page, separate logic */}
      {/* {RIF(
        displayPolarCollectPopup,
        <PolarCollectPop
          {...{
            closeAction: setDisplayPolarCollectPopup,
            doAction: setDisplayPolarCollectPopup,
          }}
        />,
      )}

      <div css={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
        <DeviceDetail {...{device}} />

        {RIF(
          device === 'smartphone',
          <div css={{width: '80%'}}>
            <SmartphoneSpecPanel />
          </div>,
        )}

        {RIF(
          device === 'polar',
          <div css={{width: '80%'}}>
            <PolarHelpCenterBlock />
            <PolarSpecPanel />
          </div>,
        )}
      </div> */}
    </div>
  )
}
