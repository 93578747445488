import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {useForm, debug, RIF, handleEsc, RequestResult} from '../../lib'
import {selectTheme, createDispatchActions} from '../../store'
import {Input, Button, ButtonCancel, Textarea, LabelBox} from '..'
import {ParticipantStateType} from '../../model'
export interface ParticipantPopupProps {
  closeAction: Dispatch<SetStateAction<boolean>>
  setIsDeletePopup?: Dispatch<SetStateAction<boolean>>
  setSearchValue?: Dispatch<SetStateAction<string>>
  method: string
  projectId?: string
  batchId?: string
  participant?: ParticipantStateType
}

export const ParticipantPopup = (props: ParticipantPopupProps) => {
  const {color, fontWeight} = selectTheme()

  const {closeAction, setIsDeletePopup, setSearchValue, method, projectId, batchId, participant} = props

  handleEsc(closeAction)

  const {doREQUEST_PARTICIPANT_CREATE, doREQUEST_PARTICIPANT_UPDATE, doADHERENCE_DELETE}: any = createDispatchActions()
  const [requestResultOfIdOfParticipantCreate, setRequestResultOfIdOfParticipantCreate] = useState<RequestResult | null>(null)
  const [requestResultOfIdOfParticipantUpdate, setRequestResultOfIdOfParticipantUpdate] = useState<RequestResult | null>(null)

  const [participantName, setParticipantName] = useState(participant?.insignia || undefined)
  const [participantNote, setParticipantNote] = useState(participant?.note || '')

  const {register, handleSubmit} = useForm()

  const onSubmit = (data: any) => {
    debug('ParticipantPopup.onSubmit', {data})
    
    // clear adherence cache to force request update adherenece for other page needs
    doADHERENCE_DELETE({projectId})

    if (method === 'Add') {
      doREQUEST_PARTICIPANT_CREATE({
        setRequestResult: setRequestResultOfIdOfParticipantCreate,
        payload: {
          batchId,
          insignia: participantName,
          note: participantNote === '' ? undefined : participantNote, // backend not allow empty string
        },
      })
    }
    if (method === 'Edit') {
      doREQUEST_PARTICIPANT_UPDATE({
        setRequestResult: setRequestResultOfIdOfParticipantUpdate,
        payload: {
          participantId: participant?.id,
          insignia: participantName,
          note: participantNote === '' ? ' ' : participantNote, // backend not allow empty string
        },
      })
    }
  }

  const onError = (error: any) => {
    debug('ParticipantPopup.onError', {error})
  }

  useEffect(() => {
    if (!requestResultOfIdOfParticipantCreate && !requestResultOfIdOfParticipantUpdate) return
    closeAction(false)
    if (method === 'Add') setSearchValue?.('')
  }, [requestResultOfIdOfParticipantCreate, requestResultOfIdOfParticipantUpdate])

  return (
    <div
      style={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
      data-testid='test_participant_pop'
    >
      <div
        style={{
          top: 'calc(50vh - 200px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          background: color.white,
          zIndex: '99',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
        }}
      >
        <ButtonCancel onClick={() => closeAction(false)} />
        <div
          style={{
            fontSize: '20px',
            fontWeight: fontWeight.thick,
            margin: '35px 0px',
          }}
        >
          {method === 'Add' ? 'Add ' : 'Edit '}Participant
        </div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <LabelBox
            {...{
              label: 'Participant ID',
              leftChracters: participantName ? 50 - participantName?.length : 50,
              required: true,
            }}
          >
            <Input
              {...register('insignia')}
              style={{marginBottom: '27px'}}
              placeholder='Use a unique and anonymous identifier'
              maxLength='50'
              value={participantName}
              onChange={(e: any) => setParticipantName(e.target.value)}
              autoFocus={method === 'Add'}
            />
          </LabelBox>

          <LabelBox
            {...{
              label: 'Notes',
              leftChracters: 250 - participantNote?.length,
            }}
          >
            <Textarea
              {...register('note', {required: false})}
              style={{marginBottom: '50px'}}
              rows='3'
              placeholder='Add internal notes here'
              maxLength='250'
              value={participantNote}
              onChange={(e: any) => setParticipantNote(e.target.value)}
            />
          </LabelBox>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {RIF(
              method === 'Add',
              <div
                onClick={() => closeAction(false)}
                style={{
                  color: color.primary,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: fontWeight.bold,
                  cursor: 'pointer',
                }}
              >
                Cancel
              </div>,
            )}

            {RIF(
              method === 'Edit',
              <div
                onClick={() => {
                  setIsDeletePopup?.(true)
                  closeAction(false)
                }}
                style={{
                  color: color.warning,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: fontWeight.thick,
                  cursor: 'pointer',
                }}
              >
                Delete Participant
              </div>,
            )}

            {RIF(!participantName, <Button disabled>{method === 'Add' ? 'Add' : 'Save'}</Button>)}

            {RIF(
              participantName,
              <Button data-testid='participant_submit_button' type='submit' btnPadding='large' btnColor='primary'>
                <>
                  {RIF(method === 'Add', 'Add')}
                  {RIF(method === 'Edit', 'Save')}
                </>
              </Button>,
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
