import {merge} from 'lodash'
import {DataFrame, IDataFrame} from 'data-forge'
import {ISerializedDataFrame} from '@data-forge/serialization'

export interface HydratorConfig<IndexT, ValueT> {
  range?: {startIndexValue: IndexT; endIndexValue: IndexT}
  flatten?: (value: ValueT, index: number) => Iterable<unknown>
}

export class Hydrator<IndexT = number, ValueT = unknown> {
  constructor(private readonly config: HydratorConfig<IndexT, ValueT> = {}) {}

  withConfig(config: Partial<HydratorConfig<IndexT, ValueT>>): Hydrator<IndexT, ValueT> {
    return new Hydrator(merge(this.config, config))
  }

  hydrate<T extends ValueT = ValueT>(input: ISerializedDataFrame): IDataFrame<IndexT, T> {
    const {range, flatten} = this.config

    let dataFrame = DataFrame.deserialize(input)
    if (range) {
      const {startIndexValue, endIndexValue} = range
      dataFrame = dataFrame.between(startIndexValue, endIndexValue)
    }
    if (flatten) {
      dataFrame = dataFrame.flatMap(flatten)
    }

    return dataFrame
  }
}
