import {AccountPlan, PurchaseItem} from '../../model'
import {_, t, useCurrentWorkspaceState} from '..'
import {selectPurchase} from '../../store'

export interface AccountPlanState {
  accountPlan?: AccountPlan
  purchase?: PurchaseItem
  hasActivePlanPurchase?: boolean
}

/**
 * Get the target purchase/plan that make sense:
 *
 * filter purchases that has plan -> sort by expire time ┬> if no purchases  -> free
 *                                                       ├> if all expired   -> get the latest purchase
 *                                                       └> if active exists -> get the highest plan purchase
 *
 * most plan usages should be refactored to use product capabilities in the future
 */
export const useCurrentWorkspaceAccountPlan = (arg?: {
  projectId?: string
}): AccountPlanState => {
  const purchaseState = selectPurchase()
  const { workspaceId } = useCurrentWorkspaceState({ projectId: arg?.projectId })
  if (!workspaceId) {
    return {}
  } else {
    const purchaseList = purchaseState[workspaceId] || []
    const planPurchaseList = purchaseList
      .filter((purchase) => purchase.product?.plan)
    if (planPurchaseList.length === 0) {
      return { accountPlan: 'Free', purchase: undefined, hasActivePlanPurchase: false }
    } else {
      const sorted = _.sortBy(planPurchaseList, (purchaseItem) => {
        if (!purchaseItem.expireAt) {
          return Number.MAX_SAFE_INTEGER
        } else {
          return new Date(purchaseItem.expireAt).getTime()
        }
      })
      const currentTime = new Date().getTime()
      const unExpiredList = sorted.filter((purchaseItem) => {
        return !purchaseItem.expireAt || (new Date(purchaseItem.expireAt).getTime()) > currentTime
      })
      if (unExpiredList.length === 0) {
        const target = sorted[sorted.length - 1]
        return { accountPlan: t.unwrap(target.product?.plan), purchase: target, hasActivePlanPurchase: false}
      } else {
        const target = unExpiredList.reduce((target: PurchaseItem, purchaseItem) => {
          // should be refactored if we have more then 3 plans
          if (purchaseItem.product?.plan === 'Advanced') {
            return purchaseItem
          }
          return target
        }, unExpiredList[0])
        return { accountPlan: t.unwrap(target.product?.plan), purchase: target, hasActivePlanPurchase: true}
      }
    }
  }
}
