import {
  RIF,
  _,
} from '../../lib'
import {
  selectTheme, 
} from '../../store'
import {
  Question,
} from '../../model'
import {QuestionnaireInput, QuestionnaireInputSize, ButtonText} from '..'
import {CancelBlackIcon} from '../../asset/image'

interface QuestionnaireOptionProps {
  isEditing: boolean
  index: number
  option?: {
    name: string
  }
  editingQuestion: Question
  setEditingQuestion: (arg: Question) => void
}

export const QuestionnaireOption = (props: QuestionnaireOptionProps) => {
  const {pad, color} = selectTheme()
  const {
    isEditing, 
    index, 
    option, 
    editingQuestion, 
    setEditingQuestion,
  } = props

  const handleChangeOfOption = (value: string) => {
    const questionCopy = JSON.parse(JSON.stringify(editingQuestion))
    questionCopy.inputConfig.options[index].name = value
    console.log(questionCopy)
    setEditingQuestion(questionCopy)
  }

  const removeOption = (i: number) => {
    const questionCopy = JSON.parse(JSON.stringify(editingQuestion))
    questionCopy.inputConfig.options.splice(i, 1)
    console.log(questionCopy)
    setEditingQuestion(questionCopy)
  }

  return (
    <div
      key={index}
      css={{
        display: 'flex',
        alignItems: 'center',
        margin: `${pad.large} 0 0 2rem`,
        height: '43px',
      }}
    >
      <div
        css={{
          width: pad.slightlyLarger,
          height: pad.slightlyLarger,
          borderRadius: editingQuestion?.type === 'radio' ? '50%' : '0',
          border: `1px solid ${color.grey_400}`,
        }}
      />
      {RIF(
        isEditing,
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div css={{
            margin: `0 ${pad.mediumSmall}`
          }}>
            <QuestionnaireInput
              size={QuestionnaireInputSize.small}
              defaultValue={option?.name}
              onChangeAction={handleChangeOfOption}
              placeholder={'New Option'}
              initialValue={'New Option'}
              maxLength={250}
            />
          </div>
          {RIF(
            editingQuestion?.inputConfig.options?.length,
            <ButtonText onClick={() => removeOption(index)} children={<img src={CancelBlackIcon} width='12' />} />,
          )}
        </div>,
      )}
      {RIF(!isEditing, <div css={{marginLeft: '10px'}}>{option?.name}</div>)}
    </div>
  )
}
