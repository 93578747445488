import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_UPDATE_TASK_SCHEDULE_LIST, doMETHOD_UPDATE_TASK} from '../state'
import { getToken } from './token_fetcher'
import {ScheduleComponents, ScheduleType} from '../../shared/db'

export const REQUEST_TASK_SCHEDULE_LIST_EDIT = 'REQUEST_TASK_SCHEDULE_LIST_EDIT'
export const doREQUEST_TASK_SCHEDULE_LIST_EDIT = createDoRequestAction(REQUEST_TASK_SCHEDULE_LIST_EDIT)

export const requestTaskScheduleListEditActionCreators = {
  doREQUEST_TASK_SCHEDULE_LIST_EDIT,
}

interface Payload {
  payload: { 
    requestId: string
    taskId: string
    schedules: {
      type: ScheduleType
      schedule: ScheduleComponents
    }[]
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestTaskScheduleListEdit({payload, setRequestResult}: Payload): SagaIterator {
  debug('saga*requestTaskScheduleListEdit')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        taskId: v.string().uuid().exist(),
        schedules: v.array().items(
          v.object({
            type: v.valid(...Object.values(ScheduleType)),
            schedule: v.object({
              year: v.number().optional(),
              month: v.number().optional(),
              day: v.number().optional(),
              hour: v.number().optional(),
              minute: v.number().optional(),
              second: v.number().optional(),
              weekday: v.number().optional(),
            })
          })
        )
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_SCHEDULE_LIST_EDIT,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {
    taskId,
    schedules
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-schedule-list-edit`,
    accessToken,
    data: {
      taskId,
      schedules
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_SCHEDULE_LIST_EDIT,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-schedule-list-edit',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(doMETHOD_UPDATE_TASK_SCHEDULE_LIST({
    taskId,
    scheduleList: result.payload
  }))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_TASK_SCHEDULE_LIST_EDIT,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* taskScheduleListEditSaga() {
  yield takeEvery(REQUEST_TASK_SCHEDULE_LIST_EDIT as any, requestTaskScheduleListEdit)
}
