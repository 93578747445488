import {selectTheme} from '../../store'
import {
  AdherenceOverviewCard, 
  AdherenceOverviewDataType, 
  ProjectAdherenceOverviewProp,
} from '..'
import { useEffect, useState } from 'react'
import {_} from '../../lib'
import { TaskStateType, getTaskContentName } from '../../model'

export const TaskAdherenceCard = (props: ProjectAdherenceOverviewProp) => {
  const {color} = selectTheme()
  const {taskList} = props
  const subDataTypeOptions: {label: string, value: string}[] = (taskList as TaskStateType[]).map((task) => {
    return {label: task[getTaskContentName(task.type)]?.name, value: task.id}
  }) || []
  const [defaultSubDataType, setDefaultSubDataType] = useState({label: '', value: ''})
  
  useEffect(() => {
    if (!subDataTypeOptions.length) return
    if (_.isEqual(subDataTypeOptions[0], defaultSubDataType)) return
    setDefaultSubDataType(subDataTypeOptions[0])
  }, [subDataTypeOptions])
  
  const weekCardProps = {
    ...props,
    title: 'Tasks',
    hintDescription: '',
    dataType: AdherenceOverviewDataType.LabfronTask,
    mainColor: color.taskYellow,
    subDataTypeOptions,
    defaultSubDataType,
  }

  return <AdherenceOverviewCard {...weekCardProps}/>
}
