import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {v, debug, useForm, RequestResult} from '../../lib'
import {selectProfile, selectTheme, createDispatchActions} from '../../store'
import {Button, LabelBox, Legend, Input, AccountSetupProcessString, ProfileSettingsProcessString} from '..'

const schema = v.object({
  newPassword: v.string().min(8).required(),
  confirmPassword: v.string().min(8).required(),
})

export interface NewPasswordInputPagePropsT {
  verifyCode: string
  setAccountSetupProcess?: Dispatch<SetStateAction<AccountSetupProcessString>>
  setIncorrectCode: Dispatch<SetStateAction<boolean>>
  emailOfForgetPWD?: string
  setEmailOfForgetPWD?: Dispatch<SetStateAction<string>>
  setProfileSettingsProcess?: Dispatch<SetStateAction<ProfileSettingsProcessString>>
}

export const NewPasswordInputPage = (props: NewPasswordInputPagePropsT) => {
  const {color, pad} = selectTheme()

  const {
    verifyCode,
    setIncorrectCode,
    emailOfForgetPWD,
    setAccountSetupProcess,
    setEmailOfForgetPWD,
    setProfileSettingsProcess,
  } = props

  const {doPASSWORD_RESET_SUBMIT}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const profile = selectProfile()

  const [hasPasswordRequiredErrorNew, setPasswordRequiredErrorNew] = useState(false)
  const [hasPasswordMinLengthErrorNew, setPasswordMinLengthErrorNew] = useState(false)
  const [hasPasswordRequiredErrorConfirm, setPasswordRequiredErrorConfirm] = useState(false)
  const [hasPasswordMinLengthErrorConfirm, setPasswordMinLengthErrorConfirm] = useState(false)
  const [hasPasswordDifferentError, setHasPasswordDifferentError] = useState(false)
  const [hasPasswordFormatError, setHasPasswordFormatError] = useState(false)

  const {register, handleSubmit, formState, watch, trigger} = useForm({schema})

  const onSubmit = (data: any) => {
    debug('passwordReset.onSubmit', data)
    if (data?.newPassword !== data?.confirmPassword) return setHasPasswordDifferentError(true)
    if (regExpCheckInput(data?.newPassword) === false || regExpCheckInput(data?.confirmPassword) === false) {
      return setHasPasswordFormatError(true)
    }
    doPASSWORD_RESET_SUBMIT({
      setRequestResult,
      payload: {
        verificationCode: verifyCode,
        email: emailOfForgetPWD || profile?.email,
        password: data?.newPassword,
      },
    })
  }

  const onError = (error: any) => {
    setPasswordRequiredErrorNew(error?.newPassword?.type == 'string.empty' ? true : false)
    setPasswordMinLengthErrorNew(error?.newPassword?.type == 'string.min' ? true : false)
    setPasswordRequiredErrorConfirm(error?.confirmPassword?.type == 'string.empty' ? true : false)
    setPasswordMinLengthErrorConfirm(error?.confirmPassword?.type == 'string.min' ? true : false)
  }

  const regExpCheckInput = (data: string) => {
    const passwordRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
    const passwordChecked = passwordRegex.test(data)
    if (!passwordChecked) setHasPasswordFormatError(true)
    return passwordChecked
  }

  const backToVerifyPage = () => {
    setIncorrectCode(true)
    // reset password process
    setProfileSettingsProcess?.('verify_code')
    // forget password process
    if (emailOfForgetPWD) setEmailOfForgetPWD?.(emailOfForgetPWD)
    setAccountSetupProcess?.('verify_code_for_forget_PWD')
  }

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult.success) return backToVerifyPage()
    if (profile?.email) return setProfileSettingsProcess?.('password_changed')
    if (emailOfForgetPWD) return setAccountSetupProcess?.('info_password_changed')
  }, [requestResult])

  const onChange = async () => {
    if (formState.isSubmitted) {
      await trigger() // trigger the validation
      onError(formState.errors) // run onError with current validation errors
    }
    setHasPasswordDifferentError(false)
    setHasPasswordFormatError(false)
  }

  return (
    <div
      data-testid='new_password_input_page'
      css={{
        width: 'fit-content',
        margin: '20vh auto',
        padding: '50px',
        position: 'relative',
      }}
    >
      <form onChange={onChange} onSubmit={handleSubmit(onSubmit, onError)} css={{width: '480px'}}>
        <Legend>Enter Your New Password</Legend>

        <LabelBox
          {...{
            label: 'New Password',
            warningMsg: hasPasswordRequiredErrorNew
              ? 'Password Required'
              : hasPasswordMinLengthErrorNew
              ? 'Must be at least 8 characters'
              : '',
          }}
        >
          <Input
            data-testid='new_pwd_input'
            {...register('newPassword')}
            css={{marginBottom: pad.small}}
            type='password'
            placeholder='********'
          />
        </LabelBox>
        <div css={{marginBottom: '30px', color: color.grey_400}}>
          8+ characters. Must include uppercase, lowercase, and a number.
        </div>

        <LabelBox
          {...{
            label: 'Confirm Password',
            warningMsg: hasPasswordRequiredErrorConfirm
              ? 'Password Required'
              : hasPasswordMinLengthErrorConfirm
              ? 'Must be at least 8 characters'
              : hasPasswordDifferentError
              ? 'Password and Confirm Password must be match'
              : hasPasswordFormatError
              ? 'Must be a valid password'
              : '',
          }}
        >
          <Input
            data-testid='confirm_pwd_input'
            {...register('confirmPassword')}
            css={{marginBottom: pad.medium}}
            type='password'
            placeholder='********'
          />
        </LabelBox>

        <Button
          css={{
            marginTop: pad.medium,
            marginRight: pad.large,
          }}
        >
          Done
        </Button>
      </form>
    </div>
  )
}
