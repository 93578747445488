import { IEntityModel } from './base'
import { IIdentity } from './identity'

export enum BetaType {
  Internal = 'internal',
  ClosedBeta = 'closed_beta',
  PublicBeta = 'public_beta',
}

export interface ISettings extends IEntityModel {
  identityId: string
  identity?: IIdentity
  timeZone: string
  countryCode: string
  locale: string
  betaEnabled: boolean
  enhancedBbiEnabled: boolean
  betaType?: BetaType
}
