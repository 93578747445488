import {useEffect, useRef, useState} from 'react'
import {
  DragDropContext, 
  DropResult, 
  Droppable,
} from 'react-beautiful-dnd'
import {
  QuestionnaireSection, 
  QuestionnaireQuestion,
} from '..'
import {_} from '../../lib'
import {
  createDispatchActions,
  selectQuestionnaires,
} from '../../store'
import { 
  ContentListItem,
} from '../../model'

export const QuestionnaireContentList = () => {
  const {
    doQUESTIONNAIRES_SET, 
    doQUESTIONNAIRES_EDITING_STATUS_SET,
  }: any = createDispatchActions()
  const ref = useRef()
  const questionnairesState = selectQuestionnaires()
  const editingQuestionnaireId = questionnairesState.editing.editingQuestionnaireId
  const [editingQuestionnaireContentList, setEditingQuestionnaireContentList] = useState<ContentListItem[]>([])

  useEffect(() => {
    if (!editingQuestionnaireId) return
    const findEditingQuestionnaireResult = _.find(
      questionnairesState.questionnaireList,
      ['id', editingQuestionnaireId]
    )
    if (!findEditingQuestionnaireResult?.contentList) return setEditingQuestionnaireContentList([])
    if (findEditingQuestionnaireResult.contentList.toString() === editingQuestionnaireContentList.toString()) return
    setEditingQuestionnaireContentList(findEditingQuestionnaireResult.contentList)
  }, [editingQuestionnaireId, questionnairesState.questionnaireList])

  useEffect(() => {
    if (!editingQuestionnaireId) return
    if (questionnairesState?.editing?.viewOnly) return
    const findEditingQuestionnaireResult = _.find(
      questionnairesState.questionnaireList, 
      ['id', editingQuestionnaireId],
    )
    if (!findEditingQuestionnaireResult?.contentList) return
    doQUESTIONNAIRES_EDITING_STATUS_SET({
      editingBlock: findEditingQuestionnaireResult.contentList[0].id
    })
  }, [editingQuestionnaireId])

  useEffect(() => {
    if (!editingQuestionnaireContentList.length) return
    const findEditingQuestionnaireResult = _.find(
      questionnairesState.questionnaireList, 
      ['id', editingQuestionnaireId],
    )
    const editingQuestionnaireCopy = JSON.parse(JSON.stringify(findEditingQuestionnaireResult))
    if (
      _.isEqual(
        editingQuestionnaireCopy.contentList,
        editingQuestionnaireContentList,
      )
    ) return
    doQUESTIONNAIRES_SET({
      ...editingQuestionnaireCopy, 
      contentList: editingQuestionnaireContentList
    })
  }, [editingQuestionnaireContentList])

  const onDragEnd = (result: DropResult) => {
    if (
      _.find(
        questionnairesState.sectionList,
        ['id', result.draggableId],
      ) &&
      result.source?.index === 0
    ) return
    if (
      _.find(
        questionnairesState.questionList,
        ['id', result.draggableId],
      ) &&
      result.source?.index === 0
    ) return
    if (!result.destination || !result.source) return
    if (result.destination.index === result.source.index) return
    const editingQuestionnaireContentListCopy = JSON.parse(JSON.stringify(editingQuestionnaireContentList))
    editingQuestionnaireContentListCopy.splice(
      result.destination.index,
      0,
      editingQuestionnaireContentListCopy.splice(result.source.index, 1)[0],
    )
    setEditingQuestionnaireContentList(editingQuestionnaireContentListCopy)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='contentList' type='block'>
        {(provided) => (
          <div
            css={{
              width: '57.4rem',
              paddingBottom: '150px',
            }}
            ref={provided.innerRef}
          >
            {editingQuestionnaireContentList.map((item, i) => {
              const isLast = i + 1 === editingQuestionnaireContentList.length
              if (item.category === 'section') {
                return (
                  <QuestionnaireSection
                    ref={ref}
                    {...{
                      key: item.id,
                      id: item.id,
                      index: i,
                      isLast,
                    }}
                  />
                )
              } else if (item.category === 'question') {
                return (
                  <QuestionnaireQuestion
                    ref={ref}
                    {...{
                      key: item.id,
                      id: item.id,
                      sectionId: item.sectionId,
                      index: i,
                      isLast,
                      contentList: editingQuestionnaireContentList,
                    }}
                  />
                )
              }
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
