import {
  createDispatchActions,
  selectTheme,
  selectBatchData,
  selectProjectSettings,
} from '../../store'
import {ArrowLeftIcon, CalendarGreyIcon, CustomizeVisualizerIcon} from '../../asset/image'
import {TimeSeriesChartBlock, CustomizationPopup, ParticipantList} from '..'
import {useState, useEffect} from 'react'
import DatePicker, {DateObject} from 'react-multi-date-picker'
import {IBatch, IProject, VisualizerGraphResolution} from '../../shared/db'
import {AttemptRequestParticipantStatus} from './request_project_data_hook'
import {_, RIF, darken, useCurrentProjectState} from '../../lib'
import {ParticipantItem} from '../../model'
import {useNavigate, useParams} from 'react-router-dom'

// interface DataVisualizerTimeseriesPageProps {
//   // batchId?: string
//   participantList: ParticipantItem[]
//   allParticipantTagList: ITag[]
//   handleCloseTimeseriesPage: () => void
//   initGraphDate?: number
//   onParticipantClicked: (arg: ParticipantItem) => void
//   participantInsignia: string
//   participantTagList: ITag[]
//   currentParticipantId: string
//   graphDateFromAdherenceTable: number | null
// }

export const DataVisualizerTimeseriesPage = () => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    doREQUEST_PROJECT_FETCH, 
    doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH,
  }: any = createDispatchActions()
  const navigate = useNavigate()
  // const profile = selectProfile()
  const { projectId, project } = useCurrentProjectState()
  const projectSettings = projectId ? (selectProjectSettings()[projectId] as any) : undefined // FIXME: temporary cast to any to avoid invalid key type warning
  const batchId = _.head(project?.batchList)?.id
  const batchData = selectBatchData() as Record<string, IBatch>
  const batch = batchId ? batchData[batchId] : undefined
  const participantList = (batch?.participantList ?? []).map(
    ({id, insignia, tagList}): ParticipantItem => ({
      id,
      insignia: insignia ?? '',
      tagList: tagList ?? [],
    }),
  )
  const allParticipantTagList = _.sortBy(
    _.uniqBy(
      participantList.flatMap(({tagList}) => tagList),
      ({id}) => id,
    ),
    ({value}) => value,
  )
  const [currentParticipantId, setCurrentParticipantId] = useState(useParams().participantId || '')
  // const [graphDateFromAdherenceTable, setGraphDateFromAdherenceTable] = useState<number | null>(null)
  const onParticipantClicked = (participantItem: ParticipantItem) => {
    navigate(`/data_visualization_timeseries/${projectId}/${participantItem.id}/${graphDate}`)
    setCurrentParticipantId(participantItem.id)
  }
  AttemptRequestParticipantStatus({})
  const initGraphDate = useParams().initGraphDate || new DateObject().format('YYMMDD')
  const [renderCustomizationPopup, setRenderCustomizationPopup] = useState(false)
  const handleCloseCustomizationPopup = () => {
    setRenderCustomizationPopup(false)
  }

  const handleRenderCustomizationPopup = () => {
    setRenderCustomizationPopup(true)
  }

  const parseNumberToDate = (dateNumber: string) => {
    // const dateString = dateNumber.toString()
    const year = parseInt(dateNumber.substring(0, 2))
    const month = parseInt(dateNumber.substring(2, 4))
    const day = parseInt(dateNumber.substring(4, 6))

    return new DateObject({
      year: year + 2000,
      month,
      day,
    })
  }

  const [selectedDate, setSelectedDate] = useState<DateObject>(parseNumberToDate(initGraphDate))
  const [graphDate, setGraphDate] = useState<number>(parseInt(initGraphDate))

  useEffect(() => {
    setGraphDate(parseInt(selectedDate.format('YYMMDD')))
  }, [selectedDate])

  useEffect(() => {
    navigate(`/data_visualization_timeseries/${projectId}/${currentParticipantId}/${graphDate}`)
  }, [graphDate])

  useEffect(() => {
    if (projectId && !project) {
      doREQUEST_PROJECT_FETCH({
        payload: {
          projectId,
        },
      })
    }

    if (!projectSettings) {
      doREQUEST_IDENTITY_PROJECT_SETTINGS_LIST_FETCH({
        payload: {},
      })
    }
  }, [projectId])

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '105',
        width: '100vw',
        height: '100vh',
        background: color.background,
      }}
    >
      {RIF(
        renderCustomizationPopup,
        <CustomizationPopup {...{handleCloseCustomizationPopup, resolution: VisualizerGraphResolution.TimeSeries}} />,
      )}
      {/* title bar, Link */}
      <div
        css={{
          width: '100vw',
          height: '56px',
          boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.04)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: color.white,
          padding: '8px 32px 8px 16px',
          zIndex: 99,
          position: 'relative',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div
            css={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '8px',
              borderRight: `1px solid ${color.grey_100}`,
            }}
          >
            <button
              css={{
                width: '28px',
                height: '28px',
                borderRadius: '5px',
                backgroundColor: color.white,
                backgroundImage: `url(${ArrowLeftIcon})`,
                backgroundPosition: 'center',
                backgroundSize: '20px',
                backgroundRepeat: 'no-repeat',
                cursor: 'pointer',
                border: 'none',
                ':hover': {
                  backgroundColor: color.hover,
                },
              }}
              onClick={()=>{navigate(`/data_visualization/${projectId}/${currentParticipantId}`)}}
            />
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '8px',
              height: '100%',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.thick,
                margin: '0 8px',
              }}
            >
              Data Visualization &gt; Time Series
            </p>
            <div
              css={{
                position: 'relative',
                margin: '0 8px',
              }}
            >
              <DatePicker
                value={selectedDate}
                onChange={(dates) => {
                  if (dates instanceof DateObject) {
                    setSelectedDate(dates)
                  }
                }}
                range={false}
                rangeHover={false}
                format='MMM D, YYYY'
                maxDate={new Date()}
                style={{
                  padding: '9px 16px 9px 32px',
                  width: '200px',
                  height: '38px',
                  fontWeight: fontWeight.medium,
                  fontSize: fontSize.h7,
                  border: `1px solid ${color.grey_160}`,
                  color: 'black',
                  cursor: 'pointer',
                }}
              />
              <img
                src={CalendarGreyIcon}
                width={16}
                css={{
                  position: 'absolute',
                  top: '12px',
                  left: '12px',
                }}
              />
            </div>
            <button
              onClick={handleRenderCustomizationPopup}
              css={{
                height: '38px',
                width: '38px',
                margin: '0 8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff',
                border: '1px solid',
                borderColor: color.grey_160,
                cursor: 'pointer',
                borderRadius: '5px',
                ':hover': {
                  borderColor: `${darken(color.grey_160, 10)}`,
                  background: color.hover,
                },
              }}
            >
              <img src={CustomizeVisualizerIcon} width={20} />
            </button>
          </div>
        </div>
      </div>
      {/* page container */}
      <div css={{display: 'flex'}}>
        <ParticipantList
          {...{
            participantList,
            allParticipantTagList,
            onParticipantClicked,
            currentParticipantId,
          }}
        />
        <div
          css={{
            width: 'calc(100% - 216px)',
            height: 'calc(100vh - 56px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* Participant Insignia */}
          {/* <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              padding: '24px 32px',
              height: '74px',
            }}
          >
            <img src={ProfileBlackIcon} width={24} />
            <p
              css={{
                fontSize: fontSize.h3,
                fontWeight: fontWeight.thick,
              }}
            >
              {participantInsignia}
            </p>
            <ParticipantTagsContainer tags={participantTagList} showTrailingTagIcon={true} />
          </div> */}
          <TimeSeriesChartBlock
            {...{
              participantId: currentParticipantId,
              yymmddIndex: graphDate,
              setSelectedDate,
            }}
          />
        </div>
      </div>
    </div>
  )
}