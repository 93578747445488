import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {
  doREQUEST_ERROR, 
  doREQUEST_COMPLETE,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH = 'REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH'
export const doREQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH = createDoRequestAction(
  REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH,
)

export const requestProjectDataDownloadMetadataFetchActionCreators = {
  doREQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH,
}

interface RequestProjectDataDownloadMetadataFetchPayload {
  payload: {
    requestId: string
    projectId: string
    participantIds: string[]
    startDate?: Date
    endDate?: Date
  },
  type: string
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestProjectDataDownloadMetadataFetch(
  {payload, setRequestResult}: RequestProjectDataDownloadMetadataFetchPayload
): SagaIterator {
  debug('saga*requestProjectDataDownloadMetadataFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        participantIds: v.array().exist(),
        startDate: v.date().optional(),
        endDate: v.date().optional(),
      })
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult)
    )
  }

  const {
    projectId, 
    participantIds,
    startDate,
    endDate,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-data-download-metadata-fetch`,
    accessToken,
    data: {
      projectId,
      participantIds,
      startDate,
      endDate,
    }
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-data-download-metadata-fetch',
        ...result,
        error,
      }, setRequestResult)
    )
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v1/web/project-data-download-metadata-fetch',
      ...result,
    }, setRequestResult)
  )
}

export function* ProjectDataDownloadMetadataFetchSaga() {
  yield takeEvery(
    REQUEST_PROJECT_DATA_DOWNLOAD_METADATA_FETCH,
    requestProjectDataDownloadMetadataFetch,
  )
}