import {useParams} from 'react-router-dom'
import {Workspace} from '../../model'
import {selectProjectData, selectWorkspaces} from '../../store'

interface CurrentWorkspaceState {
  workspaceId: string | undefined
  workspace: Workspace | undefined
  isFavoritesOrSharedWithMe: boolean
}

/*
*  will try to load project's workspace if projectId or useParams().projectId
* exists, then will use currentWorkspaceId
* */
export const useCurrentWorkspaceState = (arg?: {
  //will default to current selected project id (id in the url path)
  projectId?: string
}): CurrentWorkspaceState => {
  const customProjectId = arg?.projectId
  const paramProjectId = useParams().projectId
  const workspacesState = selectWorkspaces()
  const projectData = selectProjectData()
  if (customProjectId) {
    if (projectData[customProjectId]) {
      const workspaceId = projectData[customProjectId].workspaceId
      return {
        workspaceId: workspaceId,
        workspace: workspacesState[workspaceId],
        isFavoritesOrSharedWithMe: false,
      }
    } else {
      return {
        workspaceId: undefined,
        workspace: undefined,
        isFavoritesOrSharedWithMe: false,
      }
    }
  }
  const isFavoritesOrSharedWithMe = ['favorites', 'sharedWithMe'].includes(workspacesState.currentWorkspaceId)
  const shouldUseParamProjectId =
    paramProjectId && (isFavoritesOrSharedWithMe || !workspacesState.currentWorkspaceId)
  if (shouldUseParamProjectId) {
    if (projectData[paramProjectId]) {
      const workspaceId = projectData[paramProjectId].workspaceId
      return {
        workspaceId: workspaceId,
        workspace: workspacesState[workspaceId],
        isFavoritesOrSharedWithMe: false,
      }
    } else {
      return {
        workspaceId: undefined,
        workspace: undefined,
        isFavoritesOrSharedWithMe: false,
      }
    }
  }
  return {
    workspace: workspacesState[workspacesState.currentWorkspaceId],
    workspaceId: workspacesState.currentWorkspaceId,
    isFavoritesOrSharedWithMe: isFavoritesOrSharedWithMe,
  }
}
