import {RIF} from '../../lib'
import {selectTheme} from '../../store'
import {
  Tooltip,
  AdherenceOverviewCard, 
  AdherenceOverviewDataType, 
  ProjectAdherenceOverviewProp,
} from '..'

export const GarminConnectAdherenceCard = (props: ProjectAdherenceOverviewProp) => {
  const {color} = selectTheme()
  const weekCardProps = {
    ...props,
    title: 'Garmin Connect Data Collection',
    hintDescription: '',
    dataType: AdherenceOverviewDataType.GarminConnect,
    mainColor: color.surface.dark,
  }

  return <AdherenceOverviewCard {...weekCardProps}/>
}
