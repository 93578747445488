export function copyArrayWithReplacedIndex<T>(array: T[], index: number, value: T): T[] {
  return [
    ...array.slice(0, index),
    value,
    ...array.slice(index + 1),
  ]
}

export function copyArrayWithReplacedItem<T>(arg: {
  array: T[],
  predicate: (item: T) => boolean,
  value: (item: T) => T
}): T[] {
  const {array, predicate, value} = arg
  const index = array.findIndex(predicate)
  if (index === -1) {
    return array
  }
  return [
    ...array.slice(0, index),
    value(array[index]),
    ...array.slice(index + 1),
  ]
}
