import {PopupSimple} from '..'
import {snakeToTitle} from '../../lib'

export interface DeviceRemovePopupPropsT {
  closeAction: (boolean: boolean) => void
  doAction: () => void
  // todo check_stopwatch_movesense_stream
  removeType: '' | 'garmin_device' | 'stopwatch_movesense_stream' | 'dexcom'
}

export const DeviceRemovePopup = (props: DeviceRemovePopupPropsT) => {
  const {closeAction, doAction, removeType, ...rest} = props
  return (
    <div
      data-testid='device_remove_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '103',
      }}
    >
      <PopupSimple
        {...{
          method: 'Remove',
          name: `“${snakeToTitle(removeType)}”?`,
          description: 'This action is permanent.',
          buttonText: 'Remove',
          buttonColor: 'warning',
          closeAction,
          doAction,
        }}
      />
    </div>
  )
}
