import { IEntityModel } from './base'
import { IIdentity } from './identity'
import { IProfile } from './profile'
import { IProjectCollaborator } from './project_collaborator'
import { IWorkspace } from './workspace'

export enum WorkspaceCollaboratorRole {
  WorkspaceUpdate = 'roleWorkspaceUpdate',

  CollaboratorCreate = 'roleCollaboratorCreate',
  CollaboratorRead = 'roleCollaboratorRead',
  CollaboratorUpdate = 'roleCollaboratorUpdate',
  CollaboratorDelete = 'roleCollaboratorDelete',

  ProjectCreate = 'roleProjectCreate',
  ProjectRead = 'roleProjectRead',
  ProjectUpdate = 'roleProjectUpdate',
  ProjectDelete = 'roleProjectDelete',
  ProjectStatusUpdate = 'roleProjectStatusUpdate',
  ProjectWorkspaceUpdate = 'roleProjectWorkspaceUpdate',

  TaskCreate = 'roleTaskCreate',
  TaskUpdate = 'roleTaskUpdate',
  TaskDelete = 'roleTaskDelete',

  ParticipantCreate = 'roleParticipantCreate',
  ParticipantUpdate = 'roleParticipantUpdate',
  ParticipantDelete = 'roleParticipantDelete',

  MethodCreate = 'roleMethodCreate',
  MethodUpdate = 'roleMethodUpdate',
  MethodDelete = 'roleMethodDelete',

  BatchCreate = 'roleBatchCreate',
  BatchUpdate = 'roleBatchUpdate',
  BatchDelete = 'roleBatchDelete',

  DexcomCreate = 'roleDexcomCreate',
  DexcomRead = 'roleDexcomRead',
  DexcomUpdate = 'roleDexcomUpdate',
  DexcomDelete = 'roleDexcomDelete',
}

export enum WorkspaceCollaboratorType {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer',
  ProjectCollaborator = 'project_collaborator',
}

export const WorkspaceCollaboratorPermissionLayout: {
  [key in WorkspaceCollaboratorType]: {
    [key in WorkspaceCollaboratorRole]: boolean
  }
} = {
  [WorkspaceCollaboratorType.Admin]: {
    [WorkspaceCollaboratorRole.WorkspaceUpdate]: true,
    [WorkspaceCollaboratorRole.CollaboratorCreate]: true,
    [WorkspaceCollaboratorRole.CollaboratorRead]: true,
    [WorkspaceCollaboratorRole.CollaboratorUpdate]: true,
    [WorkspaceCollaboratorRole.CollaboratorDelete]: true,
    [WorkspaceCollaboratorRole.ProjectCreate]: true,
    [WorkspaceCollaboratorRole.ProjectRead]: true,
    [WorkspaceCollaboratorRole.ProjectUpdate]: true,
    [WorkspaceCollaboratorRole.ProjectDelete]: true,
    [WorkspaceCollaboratorRole.ProjectStatusUpdate]: true,
    [WorkspaceCollaboratorRole.ProjectWorkspaceUpdate]: true,
    [WorkspaceCollaboratorRole.TaskCreate]: true,
    [WorkspaceCollaboratorRole.TaskUpdate]: true,
    [WorkspaceCollaboratorRole.TaskDelete]: true,
    [WorkspaceCollaboratorRole.ParticipantCreate]: true,
    [WorkspaceCollaboratorRole.ParticipantUpdate]: true,
    [WorkspaceCollaboratorRole.ParticipantDelete]: true,
    [WorkspaceCollaboratorRole.MethodCreate]: true,
    [WorkspaceCollaboratorRole.MethodUpdate]: true,
    [WorkspaceCollaboratorRole.MethodDelete]: true,
    [WorkspaceCollaboratorRole.BatchCreate]: true,
    [WorkspaceCollaboratorRole.BatchUpdate]: true,
    [WorkspaceCollaboratorRole.BatchDelete]: true,
    [WorkspaceCollaboratorRole.DexcomCreate]: true,
    [WorkspaceCollaboratorRole.DexcomRead]: true,
    [WorkspaceCollaboratorRole.DexcomUpdate]: true,
    [WorkspaceCollaboratorRole.DexcomDelete]: true,
  },
  [WorkspaceCollaboratorType.Editor]: {
    [WorkspaceCollaboratorRole.WorkspaceUpdate]: false,
    [WorkspaceCollaboratorRole.CollaboratorCreate]: false,
    [WorkspaceCollaboratorRole.CollaboratorRead]: true,
    [WorkspaceCollaboratorRole.CollaboratorUpdate]: false,
    [WorkspaceCollaboratorRole.CollaboratorDelete]: false,
    [WorkspaceCollaboratorRole.ProjectCreate]: true,
    [WorkspaceCollaboratorRole.ProjectRead]: true,
    [WorkspaceCollaboratorRole.ProjectUpdate]: true,
    [WorkspaceCollaboratorRole.ProjectDelete]: true,
    [WorkspaceCollaboratorRole.ProjectStatusUpdate]: true,
    [WorkspaceCollaboratorRole.ProjectWorkspaceUpdate]: true,
    [WorkspaceCollaboratorRole.TaskCreate]: true,
    [WorkspaceCollaboratorRole.TaskUpdate]: true,
    [WorkspaceCollaboratorRole.TaskDelete]: true,
    [WorkspaceCollaboratorRole.ParticipantCreate]: true,
    [WorkspaceCollaboratorRole.ParticipantUpdate]: true,
    [WorkspaceCollaboratorRole.ParticipantDelete]: true,
    [WorkspaceCollaboratorRole.MethodCreate]: true,
    [WorkspaceCollaboratorRole.MethodUpdate]: true,
    [WorkspaceCollaboratorRole.MethodDelete]: true,
    [WorkspaceCollaboratorRole.BatchCreate]: true,
    [WorkspaceCollaboratorRole.BatchUpdate]: true,
    [WorkspaceCollaboratorRole.BatchDelete]: true,
    [WorkspaceCollaboratorRole.DexcomCreate]: true,
    [WorkspaceCollaboratorRole.DexcomRead]: true,
    [WorkspaceCollaboratorRole.DexcomUpdate]: true,
    [WorkspaceCollaboratorRole.DexcomDelete]: true,
  },
  [WorkspaceCollaboratorType.Viewer]: {
    [WorkspaceCollaboratorRole.WorkspaceUpdate]: false,
    [WorkspaceCollaboratorRole.CollaboratorCreate]: false,
    [WorkspaceCollaboratorRole.CollaboratorRead]: true,
    [WorkspaceCollaboratorRole.CollaboratorUpdate]: false,
    [WorkspaceCollaboratorRole.CollaboratorDelete]: false,
    [WorkspaceCollaboratorRole.ProjectCreate]: false,
    [WorkspaceCollaboratorRole.ProjectRead]: true,
    [WorkspaceCollaboratorRole.ProjectUpdate]: false,
    [WorkspaceCollaboratorRole.ProjectDelete]: false,
    [WorkspaceCollaboratorRole.ProjectStatusUpdate]: false,
    [WorkspaceCollaboratorRole.ProjectWorkspaceUpdate]: false,
    [WorkspaceCollaboratorRole.TaskCreate]: false,
    [WorkspaceCollaboratorRole.TaskUpdate]: false,
    [WorkspaceCollaboratorRole.TaskDelete]: false,
    [WorkspaceCollaboratorRole.ParticipantCreate]: false,
    [WorkspaceCollaboratorRole.ParticipantUpdate]: false,
    [WorkspaceCollaboratorRole.ParticipantDelete]: false,
    [WorkspaceCollaboratorRole.MethodCreate]: false,
    [WorkspaceCollaboratorRole.MethodUpdate]: false,
    [WorkspaceCollaboratorRole.MethodDelete]: false,
    [WorkspaceCollaboratorRole.BatchCreate]: false,
    [WorkspaceCollaboratorRole.BatchUpdate]: false,
    [WorkspaceCollaboratorRole.BatchDelete]: false,
    [WorkspaceCollaboratorRole.DexcomCreate]: false,
    [WorkspaceCollaboratorRole.DexcomRead]: true,
    [WorkspaceCollaboratorRole.DexcomUpdate]: false,
    [WorkspaceCollaboratorRole.DexcomDelete]: false,
  },
  [WorkspaceCollaboratorType.ProjectCollaborator]: {
    [WorkspaceCollaboratorRole.WorkspaceUpdate]: false,
    [WorkspaceCollaboratorRole.CollaboratorCreate]: false,
    [WorkspaceCollaboratorRole.CollaboratorRead]: false,
    [WorkspaceCollaboratorRole.CollaboratorUpdate]: false,
    [WorkspaceCollaboratorRole.CollaboratorDelete]: false,
    [WorkspaceCollaboratorRole.ProjectCreate]: false,
    [WorkspaceCollaboratorRole.ProjectRead]: false,
    [WorkspaceCollaboratorRole.ProjectUpdate]: false,
    [WorkspaceCollaboratorRole.ProjectDelete]: false,
    [WorkspaceCollaboratorRole.ProjectStatusUpdate]: false,
    [WorkspaceCollaboratorRole.ProjectWorkspaceUpdate]: false,
    [WorkspaceCollaboratorRole.TaskCreate]: false,
    [WorkspaceCollaboratorRole.TaskUpdate]: false,
    [WorkspaceCollaboratorRole.TaskDelete]: false,
    [WorkspaceCollaboratorRole.ParticipantCreate]: false,
    [WorkspaceCollaboratorRole.ParticipantUpdate]: false,
    [WorkspaceCollaboratorRole.ParticipantDelete]: false,
    [WorkspaceCollaboratorRole.MethodCreate]: false,
    [WorkspaceCollaboratorRole.MethodUpdate]: false,
    [WorkspaceCollaboratorRole.MethodDelete]: false,
    [WorkspaceCollaboratorRole.BatchCreate]: false,
    [WorkspaceCollaboratorRole.BatchUpdate]: false,
    [WorkspaceCollaboratorRole.BatchDelete]: false,
    [WorkspaceCollaboratorRole.DexcomCreate]: false,
    [WorkspaceCollaboratorRole.DexcomRead]: true,
    [WorkspaceCollaboratorRole.DexcomUpdate]: false,
    [WorkspaceCollaboratorRole.DexcomDelete]: false,
  },
} as const

export interface IWorkspaceCollaborator extends IEntityModel {
  identityId: string
  identity?: IIdentity
  type: WorkspaceCollaboratorType
  workspaceId: string
  workspace?: IWorkspace
  profile?: IProfile
  projectCollaboratorList?: IProjectCollaborator[]
}
