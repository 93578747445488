import {Dispatch, SetStateAction} from 'react'
import {selectTheme} from '../../store'
import {HubspotContactForm, ButtonCancel, Legend} from '..'

export interface AccountDeletePopPropsT {
  setDisplayAccountDeletePop: Dispatch<SetStateAction<boolean>>
}

export const AccountDeletePop = (props: AccountDeletePopPropsT) => {
  const {color} = selectTheme()
  const {setDisplayAccountDeletePop} = props
  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '101',
      }}
    >
      <div
        css={{
          top: 'calc(50vh - 200px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
         }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div>
            <ButtonCancel onClick={() => setDisplayAccountDeletePop(false)} />
            <div css={{marginTop: '30px'}}>
              <Legend css={{marginBottom: '16px'}}>Confirm Account Deletion</Legend>
              <p css={{marginBottom: '40px'}}>
                Deleting your account will result in the loss of access to this account, and all related data will be permanently erased.
                If you are certain about proceeding with this action, please send an email to support@labfront.com with the subject "Account Deletion Request." We will process your request promptly.
              </p>
            </div>
          </div>
          <HubspotContactForm />
        </div>
      </div>
    </div>
  )
}
