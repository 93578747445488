import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {getToken} from './token_fetcher'
import {doREQUEST_ERROR, doREQUEST_COMPLETE, doPROJECT_SCHEDULE_CONFIG_SET} from '../state'
import {ProjectScheduleFrequency, ProjectScheduleReference} from '../../shared/db'

export const REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE = 'REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE'
export const doREQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE = createDoRequestAction(REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE)

export const requestProjectScheduleConfigUpdateActionCreators = {
  doREQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE,
}

export function* requestProjectScheduleConfigUpdate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectScheduleConfigUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v
        .object({
          requestId: v.string().uuid().exist(),
          projectId: v.string().uuid().exist(),
          frequency: v
            .string()
            .allow(...Object.values(ProjectScheduleFrequency), null)
            .optional(),
          reference: v
            .string()
            .allow(...Object.values(ProjectScheduleReference), null)
            .optional(),
          absoluteReferenceYymmdd: v.number().integer().min(100000).max(999999).allow(null).optional(),
        })
        .or('frequency', 'reference', 'absoluteReferenceYymmdd'),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {requestId, ...data} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/project-schedule-config-update`,
    accessToken,
    data,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE,
          fromPayload: payload,
          requestId,
          url: '/v1/web/project-schedule-config-update',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(doPROJECT_SCHEDULE_CONFIG_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* projectScheduleConfigUpdateSaga() {
  yield takeEvery(REQUEST_PROJECT_SCHEDULE_CONFIG_UPDATE, requestProjectScheduleConfigUpdate)
}
