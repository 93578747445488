import { NotificationBlackIcon, WarningIcon } from "../../asset/image"
import { selectTheme } from "../../store"
import { ButtonCancel, LabelBox, TextLink, Textarea, Button} from ".."
import { Controller } from 'react-hook-form'
import { RIF, useForm, v } from '../../lib'
import { ChangeEvent, Dispatch, SetStateAction } from "react"

interface PopupSendNotificationProps {
  sendingList: string[]
  amountOfDisableNotification: number
  closeAction: () => void
  setPushNotificationTitle: Dispatch<SetStateAction<string>>
  pushNotificationTitle: string
  setPushNotificationContent: Dispatch<SetStateAction<string>>
  pushNotificationContent: string
  handleSubmitSendingPushNotification: () => void
}

export const PopupSendNotification = (props: PopupSendNotificationProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    sendingList,
    amountOfDisableNotification,
    closeAction,
    setPushNotificationTitle,
    pushNotificationTitle,
    setPushNotificationContent,
    pushNotificationContent,
    handleSubmitSendingPushNotification,
  } = props

  const schema = v.object({
    title: v.string().max(250).required(),
    content: v.string().max(1000).required(),
  })

  const {
    control,
    watch,
    formState: {errors},
  } = useForm({schema})


  const handleTitleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPushNotificationTitle(e.target.value)
  } 
  const handleContentInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPushNotificationContent(e.target.value)
  } 

  return (
    <div css={{
      background: '#00000040',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '103',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div css={{
        backgroundColor: color.white,
        borderRadius: '5px',
        display: 'flex',
      }}>
        {/* left part */}
        <div css={{
          width: '284px',
          padding: '30px',
          borderRight: `1px solid ${color.border._100}`
        }}>
          <ButtonCancel onClick={closeAction}/>
          <img css={{margin: '32px 0 6px 0'}} width={26} src={NotificationBlackIcon}/>
          <p css={{
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h3,
            marginBottom: '16px',
          }}>Send notification to {sendingList.length} participant(s)</p>
          <p css={{
            fontWeight: fontWeight.regular,
            fontSize: fontSize.h6,
            marginBottom: '24px',
          }}>The selected participants will see the notification message in the Labfront app and get a push-notification on their lock screen if they have notifications turned on in their phone settings.</p>
          {RIF(
            amountOfDisableNotification > 0,
            <div css={{
              padding: '16px',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: color.surface.orange.light,
            }}>
              <img width={20} css={{marginBottom: '8px'}} src={WarningIcon}/>
              <p css={{
                fontSize: fontSize.h6,
                color: color.attention,
                marginBottom: '8px',
              }}>{amountOfDisableNotification} participant{amountOfDisableNotification > 1 ? 's' : ''} has turned off the push-notification.</p>
              <p css={{
                fontSize: fontSize.h6,
                color: color.grey_600,
              }}>This means they will not get an alert on their phone, but they can still see the notification message if they open the Labfront app.</p>
            </div>
          )}
        </div>
        {/* right part */}
        <div css={{
          width: '624px',
          padding: '80px 48px 30px',
          backgroundColor: color.surface.grey.light,
        }}>
          <LabelBox
            {...{
              label: 'Title',
              required: true,
              leftChracters: 250 - pushNotificationTitle.length,
            }}
          >
            <>
              <Controller
                control={control}
                name='title'
                render={({field: {name, value, onChange}}) => (
                  <Textarea
                    {...{
                      name: name,
                      value: pushNotificationTitle,
                      onChange: onChange,
                      onInput: handleTitleInput
                    }}
                    maxLength='250'
                    placeholder='Please write a short and concise title here'
                    rows='4'
                    css={{marginBottom: '30px'}}
                  />
                )}
              />
            </>
          </LabelBox>
          <LabelBox
            {...{
              label: 'Content',
              required: true,
              leftChracters: 1000 - pushNotificationContent.length,
            }}
          >
            <>
              <Controller
                control={control}
                name='content'
                render={({field: {name, value, onChange}}) => (
                  <Textarea
                    {...{
                      name: name,
                      value: pushNotificationContent,
                      onChange: onChange,
                      onInput: handleContentInput
                    }}
                    maxLength='1000'
                    placeholder='Please write the notification content here'
                    css={{marginBottom: '40px'}}
                    rows='16'
                  />
                )}
              />
            </>
          </LabelBox>
          <div css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}>
            <div css={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <TextLink onClick={closeAction} children="Discard" textColor="warning" css={{
                fontSize: fontSize.h6,
                fontWeight: fontWeight.bold,
                marginRight: '16px',
              }}/>
              <Button onClick={handleSubmitSendingPushNotification} btnPadding="large" children="Send"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}