import React from 'react'
import {selectTheme} from '../../store'
import {useNavigate, Link} from 'react-router-dom'
import {Controller} from 'react-hook-form'

import {v, useForm} from '../../lib'

import {Button, Legend, LabelBox, Select} from '..'

const schema = v.object({
  firstName: v.string().exist(),
  lastName: v.string().exist(),
  title: v.string().optional(),
})

export const ProfileSetupFeedbackPage = () => {
  const {pad, color, fontWeight} = selectTheme()
  const navigate = useNavigate()

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm({schema})

  const roleOptions = [
    {value: 'Principal Investigator', label: 'Principal Investigator'},
    {value: 'Research Assistant', label: 'Research Assistant'},
    {value: 'Data Analyst', label: 'Data Analyst'},
    {value: 'Other', label: 'Other'},
  ]

  const typeOptions = [
    {value: 'Anesthesiology', label: 'Anesthesiology'},
    {value: 'Cardiology', label: 'Cardiology'},
    {value: 'Epidemiology', label: 'Epidemiology'},
    {value: 'Medical (general)', label: 'Medical (general)'},
    {value: 'Exercise Science', label: 'Exercise Science'},
    {value: 'Oncology', label: 'Oncology'},
    {value: 'Neurology', label: 'Neurology'},
    {value: 'Pediatrics', label: 'Pediatrics'},
    {value: 'Physiology', label: 'Physiology'},
    {value: 'Physiotherapy', label: 'Physiotherapy'},
    {value: 'Psychiatry', label: 'Psychiatry'},
    {value: 'Psychology', label: 'Psychology'},
    {value: 'Public Health', label: 'Public Health'},
    {value: 'Technology', label: 'Technology'},
    {value: 'Other', label: 'Other'},
  ]

  const howOptions = [
    {value: 'Google search', label: 'Google search'},
    {value: 'Newsletter', label: 'Newsletter'},
    {value: 'Seminar', label: 'Seminar'},
    {value: 'Article', label: 'Article'},
    {value: 'Friend/Colleague', label: 'Friend/Colleague'},
    {value: 'Other', label: 'Other'},
  ]

  const onSubmit = (data: any) => {
    return
  }

  const onError = (error: any) => {
    return
  }

  const onClick = () => {
    navigate('/project')
  }

  return (
    // wrapper
    <div
      css={{
        margin: '25vh auto',
        width: '600px',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Legend>Tell us a little more about your work</Legend>

        <div
          css={{
            fontWeight: fontWeight.bold,
            margin: `16px 0`,
          }}
        >
          This will help us learn more about the people using our products.
        </div>

        <div css={{marginTop: '40px'}}>
          <LabelBox {...{label: 'What is your role?'}}>
            <Controller
              control={control}
              defaultValue={false}
              name='role'
              render={({field: {name, value, onChange}}) => (
                <Select
                  options={roleOptions}
                  placeholder='Select your role'
                  {...{name, value, onChange}}
                  css={{marginBottom: '30px'}}
                />
              )}
            />
          </LabelBox>
          <LabelBox {...{label: 'What type of research do you do?'}}>
            <Controller
              control={control}
              defaultValue={false}
              name='type'
              render={({field: {name, value, onChange}}) => (
                <Select
                  options={typeOptions}
                  placeholder='Select research type'
                  {...{name, value, onChange}}
                  css={{marginBottom: '30px'}}
                />
              )}
            />
          </LabelBox>
          <LabelBox {...{label: 'How did you hear about us?'}}>
            <Controller
              control={control}
              defaultValue={false}
              name='how'
              render={({field: {name, value, onChange}}) => (
                <Select options={howOptions} {...{name, value, onChange}} css={{marginBottom: '30px'}} />
              )}
            />
          </LabelBox>
        </div>

        <div css={{display: 'flex', alignItems: 'center', marginTop: '30px'}}>
          <Button onClick={onClick}>Done</Button>
          <Link to='/project' css={{marginLeft: '20px', color: color.primary}}>
            Skip
          </Link>
        </div>
      </form>
    </div>
  )
}
