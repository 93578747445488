import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import GarminDeviceImg from '../../asset/image/garmin_device.png'
import SmartPhoneImg from '../../asset/image/smartphone.png'
import PolarImg from '../../asset/image/polar.png'

export interface DeviceDetailPropsT {
  device: 'garmin_device' | 'smartphone' | 'polar' | ''
}

export const DeviceDetail = (props: DeviceDetailPropsT) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()
  if (props.device === 'garmin_device') return <div css={{display: 'none'}}></div>
  return (
    <div
      css={{
        width: '18%',
        height: '587px',
        padding: `${pad.medium} ${pad.large}`,
        background: color.white,
        borderRadius: '5px',
        outline: '1px solid red',
      }}
    >
      {/* {RIF(
        props.device === 'garmin_device',
        <div css={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={GarminDeviceImg} width='150' height='150' css={{margin: '8px 30px 8px'}} />
          <p css={{fontSize: fontSize.h1, fontWeight: fontWeight.bold, marginBottom: pad.medium}}>Garmin Devices</p>
          <p css={{marginBottom: pad.xl}}>
            Labfront is compatible with many Garmin devices. The available sensors and data collection capabilities vary
            according to each device.
          </p>
          <a
            href='https://www.labfront.com/compatible-devices'
            target='_blank'
            css={{color: color.primary, textDecoration: 'none'}}>
            See all compatible Garmin devices
          </a>
        </div>,
      )} */}

      {RIF(
        props.device === 'smartphone',
        <div css={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={SmartPhoneImg} width='190' height='190' css={{marginBottom: pad.small}} />
          <p css={{fontSize: fontSize.h1, fontWeight: fontWeight.bold, marginBottom: pad.medium}}>Smart Phone</p>
          <p css={{marginBottom: pad.xl}}>Lorem ipsom of Use your phone's accelerometer to collect movement data. </p>
        </div>,
      )}

      {RIF(
        props.device === 'polar',
        <div css={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <img src={PolarImg} width='190' height='190' css={{marginBottom: pad.small}} />
          <p css={{fontSize: fontSize.h1, fontWeight: fontWeight.bold, marginBottom: pad.medium}}>Polar H10</p>
          <p css={{marginBottom: pad.xl}}>Exercise-friendly, water-resistant ECG that can measure RR-interval.</p>
        </div>,
      )}
    </div>
  )
}
