import { OptionalId } from './type'

export enum GarminDeviceQueryEventType {
  Initial = 'initial',
  Verification = 'verification',
}

export interface GarminDeviceQueryEvent extends OptionalId {
  createdAt: Date
  timezone: string
  participantId: string
  participantInsignia?: string
  garminDeviceType: string
  mobileDeviceType: string
  mobileDeviceVersion: string
  unixTimestampQueryStart: number
  unixTimestampQueryEnd: number
  queryType: GarminDeviceQueryEventType
  data: object
}
