import {BrowserRouter, Routes, Route} from 'react-router-dom'
import {useEffect} from 'react'
import {Provider} from 'react-redux'
import {createAppStore} from '../store'
import {StyleReset} from './style_reset'
import {Helmet} from 'react-helmet-async'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import '../lib/i18n'

import {
  ProtectedRoute,
  Navbar,
  SignupPage,
  LoginPage,
  ProjectLandingPage,
  AccountSetupPage,
  ProjectSetupPage,
  ParticipantsPage,
  OverviewPage,
  AdherencePage,
  DataVisualizerPage,
  DataVisualizerTimeseriesPage,
  QuestionnairePage,
  Loading,
  PageNotFoundPage,
  SessionPopup,
  ProjectSettingsPage,
  AnalysisPage,
} from '.'

import {ErrorBoundaries} from './error_boundaries'
import {API_URL} from '../lib'

const trustedSourceList = [
  'https://*.hotjar.com',
  'https://faro-collector-prod-us-east-0.grafana.net',
  'https://s-d-pomelo-tabular-data.s3.us-east-1.amazonaws.com',
  'https://s-s-pomelo-tabular-data.s3.us-east-1.amazonaws.com',
  'https://r-r-pomelo-tabular-data.s3.us-east-1.amazonaws.com',
  'https://fonts.gstatic.com',
  'https://develop.api.pomelo.click',
  'https://staging.api.pomelo.click',
  'https://api.labfront.com',
  'http://localhost:9000',
  API_URL,
]

const trustedImgSourceList = [
  'https://img.youtube.com',
  'https://develop.bin.pomelo.click',
  'https://staging.bin.pomelo.click',
  'https://asset.labfront.com',
  'data:',
]

export const App = () => {
  useEffect(() => {
    const webUrl = window.location.href
    if (process.env.DEPLOY_STAGE === 'develop') document.title = 'LF-Develop'
    if (process.env.DEPLOY_STAGE === 'staging') document.title = 'LF-Staging'
    if (webUrl.slice(0, 32) === 'https://release.app.pomelo.click') document.title = 'LF-Hotfix only'
    if (webUrl.slice(0, 21) === 'http://localhost:9000') document.title = 'LF-Localhost'
  }, [])

  return (
    <main>
      <Helmet>
        <meta
          httpEquiv='Content-Security-Policy'
          content={`
            default-src 'self' ${trustedSourceList.join(' ')};
            script-src 'self' 'unsafe-eval';
            worker-src 'self';
            img-src 'self' ${trustedImgSourceList.join(' ')};
            style-src 'unsafe-inline';
          `}
        />
      </Helmet>
      <Provider store={createAppStore()}>
        <StyleReset />
        <BrowserRouter>
          <ErrorBoundaries>
            <SessionPopup />
            <Loading />
            <Navbar />
            <Routes>
              <Route path='/' element={<LoginPage />} />
              <Route path='/signup' element={<SignupPage />} />
              <Route
                path='/project'
                element={
                  <ProtectedRoute>
                    <ProjectLandingPage />
                  </ProtectedRoute>
                }
              />
              <Route path='/account_setup/*' element={<AccountSetupPage />} />
              <Route
                path='/dashboard/:projectId'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <OverviewPage />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/project_setup/:projectId'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <ProjectSetupPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/project_setup/:projectId/questionnaire'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <QuestionnairePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/participants/:projectId'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <ParticipantsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/adherence/:projectId'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <AdherencePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/settings/:projectId'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <ProjectSettingsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/data_visualization/:projectId/:participantId?'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <DataVisualizerPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/data_analysis/:projectId/:participantId?'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <AnalysisPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/data_visualization_timeseries/:projectId/:participantId?/:initGraphDate?'
                element={
                  <ProtectedRoute requireProjectId={true}>
                    <DataVisualizerTimeseriesPage />
                  </ProtectedRoute>
                }
              />
              <Route path='*' element={<PageNotFoundPage />} />
            </Routes>
          </ErrorBoundaries>
        </BrowserRouter>
      </Provider>
    </main>
  )
}
