import { OptionalId } from './type'
import { GarminConnectHookType } from '../api'

export interface GarminConnectRecord<
  T extends GarminConnectRecordDataType = GarminConnectRecordDataType,
> extends OptionalId {
  date: Date
  type: GarminConnectHookType
  projectId: string
  batchId: string
  participantId: string
  participantInsignia: string
  participantGarminConnectUserId: string
  data: T
}

export interface GarminConnectRecordData {
  summaryId: string
  sourceId?: string
  insertedDate?: Date
  calendarDate?: string
  startTimeInSeconds?: number
  startTimeOffsetInSeconds?: number
  measurementTimeInSeconds?: number
  measurementTimeOffsetInSeconds?: number
}

export interface GarminConnectRecordDataWithDuration
  extends GarminConnectRecordData {
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
}

export interface GarminConnectRecordDataWithMeasurementTime
  extends GarminConnectRecordData {
  measurementTimeInSeconds: number
  measurementTimeOffsetInSeconds: number
}

export type GarminConnectRecordDataType =
  | GarminConnectRecordDataDaily
  | GarminConnectRecordDataThirdPartyDaily
  | GarminConnectRecordDataEpoch
  | GarminConnectRecordDataSleeps
  | GarminConnectRecordDataBodyCompositions
  | GarminConnectRecordDataStress
  | GarminConnectRecordDataUserMetrics
  | GarminConnectRecordDataPulseOx
  | GarminConnectRecordDataRespiration
  | GarminConnectRecordDataHealthSnapshot
  | GarminConnectRecordDataHRVSummary
  | GarminConnectRecordDataBloodPressure
  | GarminConnectRecordDataActivity
  | GarminConnectRecordDataActivityDetailsSummary
  | GarminConnectRecordDataActivityFiles
  | GarminConnectRecordDataActivityMoveIQ
  | GarminConnectRecordDataWomenHealthMCT

export type TimeOffsetData = Record<number, number>

export interface GarminConnectRecordDataDaily
  extends GarminConnectRecordDataWithDuration {
  calendarDate: string
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  activityType: string
  durationInSeconds: number
  steps: number
  distanceInMeters: number
  activeTimeInSeconds: number
  activeKilocalories: number
  bmrKilocalories: number
  moderateIntensityDurationInSeconds: number
  vigorousIntensityDurationInSeconds: number
  floorsClimbed: number
  minHeartRateInBeatsPerMinute: number
  averageHeartRateInBeatsPerMinute: number
  maxHeartRateInBeatsPerMinute: number
  restingHeartRateInBeatsPerMinute: number
  timeOffsetHeartRateSamples: TimeOffsetData
  averageStressLevel: number
  maxStressLevel: number
  stressDurationInSeconds: number
  restStressDurationInSeconds: number
  activityStressDurationInSeconds: number
  lowStressDurationInSeconds: number
  mediumStressDurationInSeconds: number
  highStressDurationInSeconds: number
  stressQualifier: string
  stepsGoal: number
  intensityDurationGoalInSeconds: number
  floorsClimbedGoal: number
}

export interface GarminConnectRecordDataThirdPartyDaily
  extends GarminConnectRecordDataWithDuration {
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  activityType: string
  durationInSeconds: number
  steps: number
  distanceInMeters: number
  activeTimeInSeconds: number
  activeKilocalories: number
  bmrKilocalories: number
  moderateIntensityDurationInSeconds: number
  vigorousIntensityDurationInSeconds: number
  floorsClimbed: number
  minHeartRateInBeatsPerMinute: number
  averageHeartRateInBeatsPerMinute: number
  maxHeartRateInBeatsPerMinute: number
  timeOffsetHeartRateSamples: TimeOffsetData
  source: string
}

export interface GarminConnectRecordDataEpoch
  extends GarminConnectRecordDataWithDuration {
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  activityType: string
  durationInSeconds: number
  activeTimeInSeconds: number
  steps: number
  distanceInMeters: number
  activeKilocalories: number
  met: number
  intensity: string
  meanMotionIntensity: number
  maxMotionIntensity: number
}

export interface GarminConnectRecordDataSleeps
  extends GarminConnectRecordDataWithDuration {
  calendarDate: string
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
  unmeasurableSleepInSeconds: number
  deepSleepDurationInSeconds: number
  lightSleepDurationInSeconds: number
  remSleepInSeconds: number
  awakeDurationInSeconds: number
  sleepLevelsMap: GarminConnectRecordDataSleepLevelsMap
  validation: string
  timeOffsetSleepRespiration: TimeOffsetData
  timeOffsetSleepSpo2: TimeOffsetData
  overallSleepScore: GarminConnectRecordDataOverallSleepScore
  sleepScores: GarminConnectRecordDataSleepScores
}

export enum GarminConnectRecordDataSleepLevelsMapKey {
  Deep = 'deep',
  Light = 'light',
  Awake = 'awake',
}

export type GarminConnectRecordDataSleepLevelsMap = {
  [K in GarminConnectRecordDataSleepLevelsMapKey]?: GarminConnectRecordDataSleepLevelItem[]
}

export interface GarminConnectRecordDataSleepLevelItem {
  startTimeInSeconds: number
  endTimeInSeconds: number
}

export interface GarminConnectRecordDataOverallSleepScore {
  value: number
  qualifierKey: string
}

export interface GarminConnectRecordDataSleepScores {
  totalDuration: GarminConnectRecordDataSleepScoreValue
  stress: GarminConnectRecordDataSleepScoreValue
  awakeCount: GarminConnectRecordDataSleepScoreValue
  remPercentage: GarminConnectRecordDataSleepScoreValue
  restlessness: GarminConnectRecordDataSleepScoreValue
  lightPercentage: GarminConnectRecordDataSleepScoreValue
  deepPercentage: GarminConnectRecordDataSleepScoreValue
}

export interface GarminConnectRecordDataSleepScoreValue {
  qualifierKey: string
}

export interface GarminConnectRecordDataBodyCompositions
  extends GarminConnectRecordDataWithMeasurementTime {
  measurementTimeInSeconds: number
  measurementTimeOffsetInSeconds: number
  muscleMassInGrams: number
  boneMassInGrams: number
  bodyWaterInPercent: number
  bodyFatInPercent: number
  bodyMassIndex: number
  weightInGrams: number
}

export interface GarminConnectRecordDataStress
  extends GarminConnectRecordDataWithDuration {
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
  calendarDate: string
  timeOffsetStressLevelValues: TimeOffsetData
  timeOffsetBodyBatteryValues: TimeOffsetData
}

export interface GarminConnectRecordDataUserMetrics
  extends GarminConnectRecordData {
  calendarDate: string
  vo2Max: number
  enhanced: boolean
  fitnessAge: number
}

export interface GarminConnectRecordDataPulseOx
  extends GarminConnectRecordDataWithDuration {
  calendarDate: string
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
  timeOffsetSpo2Values: TimeOffsetData
  onDemand: boolean
}

export interface GarminConnectRecordDataRespiration
  extends GarminConnectRecordDataWithDuration {
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
  timeOffsetEpochToBreaths: TimeOffsetData
}

export interface GarminConnectRecordDataHealthSnapshot
  extends GarminConnectRecordDataWithDuration {
  calendarDate: string
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
  summaries: GarminConnectRecordDataHealthSnapshotSummary[]
}

export interface GarminConnectRecordDataHealthSnapshotSummary {
  summaryType: GarminConnectRecordDataHealthSnapshotSummaryType
  minValue?: number
  maxValue?: number
  avgValue: number
  epochSummaries?: TimeOffsetData
}

export enum GarminConnectRecordDataHealthSnapshotSummaryType {
  HeartRate = 'heart_rate',
  Stress = 'stress',
  Respiration = 'respiration',
  PulseOx = 'spo2',
  Rmssd = 'rmssd_hrv',
  Sdrr = 'sdrr_hrv',
}

export interface GarminConnectRecordDataHRVSummary
  extends GarminConnectRecordDataWithDuration {
  calendarDate: string
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  durationInSeconds: number
  lastNightAvg: number
  lastNight5MinHigh: number
  hrvValues: TimeOffsetData
}

export interface GarminConnectRecordDataBloodPressure
  extends GarminConnectRecordDataWithMeasurementTime {
  measurementTimeInSeconds: number
  measurementTimeOffsetInSeconds: number
  systolic: number
  diastolic: number
  pulse: number
  sourceType: string
}

export interface GarminConnectRecordDataActivity
  extends GarminConnectRecordDataWithDuration {
  activityId: string
  startTimeInSeconds: number
  startTimeOffsetInSeconds: number
  activityType: string
  durationInSeconds: number
  averageBikeCadenceInRoundsPerMinute: number
  averageHeartRateInBeatsPerMinute: number
  averageRunCadenceInStepsPerMinute: number
  averageSpeedInMetersPerSecond: number
  averageSwimCadenceInStrokesPerMinute: number
  averagePaceInMinutesPerKilometer: number
  activeKilocalories: number
  deviceName: string
  distanceInMeters: number
  maxBikeCadenceInRoundsPerMinute: number
  maxHeartRateInBeatsPerMinute: number
  maxPaceInMinutesPerKilometer: number
  maxRunCadenceInStepsPerMinute: number
  maxSpeedInMetersPerSecond: number
  numberOfActiveLengths: number
  startingLatitudeInDegree: number
  startingLongitudeInDegree: number
  steps: number
  totalElevationGainInMeters: number
  totalElevationLossInMeters: number
  isParent: boolean
  parentSummaryId: string
  manual: boolean
}

export type GarminConnectRecordDataActivityManuallyUpdatedSummary =
  GarminConnectRecordDataActivity

export interface GarminConnectRecordDataActivityDetailsSummary
  extends GarminConnectRecordData {
  activityId: string
  summary: GarminConnectRecordDataActivity
  samples?: GarminConnectRecordDataActivityDetailsSample[]
  laps?: GarminConnectRecordDataActivityDetailsLap[]
}

export function isGarminConnectActivityDetailsSummaryData(
  data: GarminConnectRecordData,
): data is GarminConnectRecordDataActivityDetailsSummary {
  return 'activityId' in data && 'summary' in data
}

export function isGarminConnectActivityFilesData(
  data: GarminConnectRecordData,
): data is GarminConnectRecordDataActivityFiles {
  return 'activityId' in data && 'callbackURL' in data
}

export function isGarminConnectWomenHealthMCTData(
  data: GarminConnectRecordData,
): data is GarminConnectRecordDataWomenHealthMCT {
  return 'periodStartDate' in data
}

export interface GarminConnectRecordDataActivityDetailsSample {
  startTimeInSeconds: number
  latitudeInDegree: number
  longitudeInDegree: number
  elevationInMeters: number
  airTemperatureCelcius: number
  heartRate: number
  speedMetersPerSecond: number
  stepsPerMinute: number
  totalDistanceInMeters: number
  timerDurationInSeconds: number
  clockDurationInSeconds: number
  movingDurationInSeconds: number
  powerInWatts: number
  bikeCadenceInRPM: number
  swimCadenceInStrokesPerMinute: number
}

export interface GarminConnectRecordDataActivityDetailsLap {
  startTimeInSeconds: number
}

// TODO: handle oauth ping-pong to get and store activity file somewhere
export interface GarminConnectRecordDataActivityFiles {
  userId: string
  userAccessToken: string
  summaryId: string
  fileType: string
  callbackURL: string
  startTimeInSeconds: number
  activityId: string
  activityName: string
  activityDescription?: string
  manual: boolean
}

export interface GarminConnectRecordDataActivityMoveIQ
  extends GarminConnectRecordData {
  calendarDate: string
  startTimeInSeconds: number
  offsetInSeconds: number //this offset is the only one name in "offsetInSeconds"
  durationInSeconds: number
  activityType: string
  activitySubType: string
}

export interface GarminConnectRecordDataWomenHealthMCT
  extends GarminConnectRecordData {
  periodStartDate: string
  dayInCycle: number
  periodLength: number
  currentPhase: number
  currentPhaseType: string
  lengthOfCurrentPhase: number
  daysUntilNextPhase: number
  predictedCycleLength: number
  isPredictedCycle: boolean
  cycleLength: number
  lastUpdatedTimeInSeconds: number
  hasSpecifiedCycleLength: boolean
  hasSpecifiedPeriodLength: boolean
  pregnancySnapshot?:
    | GarminConnectRecordDataWomenHealthMCTPregnancyShapshot
    | Record<string, never>
}

export interface GarminConnectRecordDataWomenHealthMCTPregnancyShapshot {
  title: string
  originalDueDate: string
  dueDate: string
  pregnancyCycleStartDate: string
  numOfBabies: string
  weightGoalUserInput?: {
    heightInCentimeters: number
    weightInGrams: number
  }
  bloodGlucoseList?: {
    valueInMilligramsPerDeciliter: number
    logType: string
    reportTimestampInSeconds: number
  }[]
}

export function isGarminConnectRecordDataDaily(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataDaily> {
  return data.type == GarminConnectHookType.HealthDailies
}

export function isGarminConnectRecordDataThirdPartyDaily(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataThirdPartyDaily> {
  return data.type == GarminConnectHookType.HealthThirdPartyDailies
}

export function isGarminConnectRecordDataEpoch(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataEpoch> {
  return data.type == GarminConnectHookType.HealthEpochs
}

export function isGarminConnectRecordDataSleeps(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataSleeps> {
  return data.type == GarminConnectHookType.HealthSleeps
}

export function isGarminConnectRecordBodyCompositions(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataBodyCompositions> {
  return data.type == GarminConnectHookType.HealthBodyCompositions
}

export function isGarminConnectRecordDataStress(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataStress> {
  return data.type == GarminConnectHookType.HealthStress
}

export function isGarminConnectRecordDataUserMetrics(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataUserMetrics> {
  return data.type == GarminConnectHookType.HealthUserMetrics
}

export function isGarminConnectRecordDataPulseOx(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataPulseOx> {
  return data.type == GarminConnectHookType.HealthPulseOx
}

export function isGarminConnectRecordDataRespiration(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataRespiration> {
  return data.type == GarminConnectHookType.HealthRespiration
}

export function isGarminConnectRecordDataHealthSnapshot(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataHealthSnapshot> {
  return data.type == GarminConnectHookType.HealthSnapshot
}

export function isGarminConnectRecordDataHRVSummary(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataHRVSummary> {
  return data.type == GarminConnectHookType.HealthHRVSummary
}

export function isGarminConnectRecordDataBloodPressure(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataBloodPressure> {
  return data.type == GarminConnectHookType.HealthBloodPressure
}

export function isGarminConnectRecordDataActivity(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataActivity> {
  return data.type == GarminConnectHookType.Activity
}

export function isGarminConnectRecordDataWomenHealthMCT(
  data: GarminConnectRecord,
): data is GarminConnectRecord<GarminConnectRecordDataWomenHealthMCT> {
  return data.type == GarminConnectHookType.WomenHealthMCT
}
