import {
  useEffect, 
  useState, 
  Dispatch, 
  SetStateAction
} from 'react'

import {
  selectTheme, 
  selectProfile, 
  createDispatchActions
} from '../../store'

import {
  ContinuousInputFields,
  Button,
  AccountSetupProcessString,
  ProfileSettingsProcessString,
  ProfileSettingsProcessMethodString,
} from '..'

import { RequestResult, RIF } from '../../lib'
export interface VerifyCodePageProps {
  email?: string
  phone?: string
  verifyType: 'email' | 'phone'
  accountSetupProcess?: AccountSetupProcessString
  setAccountSetupProcess?: Dispatch<SetStateAction<AccountSetupProcessString>>
  profileSettingsProcess?: string
  setProfileSettingsProcess?: Dispatch<SetStateAction<ProfileSettingsProcessString>>
  setVerifyCode?: Dispatch<SetStateAction<string>>
  incorrectCode?: boolean
  profileSettingsProcessMethod?: ProfileSettingsProcessMethodString
}

export const VerifyCodePage = (props: VerifyCodePageProps) => {
  const {color, fontWeight} = selectTheme()

  const {
    email,
    phone,
    verifyType,
    accountSetupProcess,
    setAccountSetupProcess,
    profileSettingsProcess,
    setProfileSettingsProcess,
    setVerifyCode,
    incorrectCode,
    profileSettingsProcessMethod,
  } = props

  const displayName = verifyType === 'email' ? email : `${phone?.slice(0, 2)}******${phone?.slice(-2)}`

  const LENGTH_OF_VERIFY_CODE = 10
  const [values, setValues] = useState<string[]>(Array(10).fill(''))
  // const [value, setValue] = useState('')

  const {doEMAIL_VERIFY_SUBMIT, doREQUEST_EMAIL_UPDATE, doPASSWORD_RESET_REQUEST, doEMAIL_VERIFY_REQUEST}: any =
    createDispatchActions()

  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)
  const [requestResultOfSendCode, setRequestResultOfSendCode] = useState<RequestResult | null>(null)

  const profile = selectProfile()

  const [hasError, setHasError] = useState(incorrectCode)
  const [completed, setCompleted] = useState(false)
  const [sendCodeWording, setSendCodeWording] = useState('Send code again')


  useEffect(() => {
    if (hasError) return
    setCompleted(values.join('').length === 10)
  }, [values, hasError])

  const handleChange = (values: string[]) => {
    setValues(values)
  }

  const onSubmit = () => {
    console.log(values)
    console.log(values.join(''))
    if (setVerifyCode) setVerifyCode(values.join(''))
    if (profileSettingsProcessMethod === 'change_email') return setProfileSettingsProcess?.('new_email_input')
    if (profileSettingsProcessMethod === 'reset_password') return setProfileSettingsProcess?.('new_password_input')
    if (accountSetupProcess === 'verify_code_for_forget_PWD') return setAccountSetupProcess?.('new_password_input')
    doEMAIL_VERIFY_SUBMIT({
      setRequestResult,
      payload: {
        email,
        verificationCode: values.join(''),
      },
    })
  }

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult.success) return setHasError(true)
    setAccountSetupProcess?.('profile_initial_settings_page')
  }, [requestResult])

  const handleSendCodeAgain = () => {
    if (profileSettingsProcess === 'change_email') {
      return doREQUEST_EMAIL_UPDATE({
        setRequestResult: setRequestResultOfSendCode,
        payload: {},
      })
    }
    if (profileSettingsProcess === 'reset_password' || accountSetupProcess === 'verify_code_for_forget_PWD') {
      return doPASSWORD_RESET_REQUEST({
        setRequestResult: setRequestResultOfSendCode,
        payload: {
          email: accountSetupProcess === 'verify_code_for_forget_PWD' ? email : profile?.email,
        },
      })
    }
    doEMAIL_VERIFY_REQUEST({
      setRequestResult: setRequestResultOfSendCode,
      payload: {
        email,
      },
    })
    setValues(Array(10).fill(''))
    setHasError(false)
  }

  useEffect(() => {
    if (!requestResultOfSendCode) return
    if (!requestResultOfSendCode.success) return
    setSendCodeWording('Code sent')
    const delay = setTimeout(() => {
      setSendCodeWording('Send code again')
    }, 5000)
    return () => clearTimeout(delay)
  }, [requestResultOfSendCode])

  return (
    // wrapper
    <div
      data-testid='verify_code_page'
      css={{
        width: 'fit-content',
        height: '300px',
        margin: '20vh auto',
        padding: '50px',
      }}
    >
      <div css={{fontSize: '20px'}}>Verify Your {verifyType === 'email' ? 'Email Address' : 'Phone Number'}</div>

      <div css={{margin: '15px 0 20px 0'}}>
        {RIF(verifyType !== 'email', <div>Your account has enabled Multi-Factor Authentication.</div>)}
        <div>
          We've sent a ten-digit verification code to
          <span css={{fontWeight: fontWeight.bold}}> {displayName}</span>
          {verifyType === 'email' ? '' : ' via text.'}.
        </div>
        <div>Please enter it below to verify your {verifyType === 'email' ? 'email address' : 'this action'}.</div>
      </div>

      <div>
        <div
          css={{
            position: 'relative',
            display: 'flex',
            width: `calc(${LENGTH_OF_VERIFY_CODE} * 50px)`,
          }}
        >
          <ContinuousInputFields
            {...{
              numFields: LENGTH_OF_VERIFY_CODE,
              hasError,
              handleChange,
              values,
            }}
          />

          {RIF(
            hasError,
            <div
              css={{
                fontWeight: fontWeight.bold,
                color: color.warning,
                position: 'absolute',
                right: '-100px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            >
              Incorrect Code
            </div>,
          )}
        </div>

        <div css={{marginTop: '25px', display: 'flex', alignItems: 'center'}}>
          <Button onClick={onSubmit} disabled={!completed}>
            Next
          </Button>
          <div
            onClick={handleSendCodeAgain}
            css={{fontWeight: fontWeight.bold, color: color.primary, marginLeft: '20px', cursor: 'pointer'}}
          >
            {sendCodeWording}
          </div>
        </div>
      </div>
    </div>
  )
}

interface CellsInInputFieldProps {
  inputValue: string
  inputLength: number
  hasError?: boolean
}

const CellsInInputField = (props: CellsInInputFieldProps) => {
  const {color, fontWeight} = selectTheme()

  const LENGTH_OF_VERIFY_CODE = 10

  const calculateBorderStyle = (index: number) => {
    let borderWidth = '1px'
    let borderColor = color.grey_160

    if (props.inputLength === index) {
      borderWidth = '2px'
      borderColor = color.primary
    }
    
    if (props.hasError) {
      borderColor = color.attention
    }

    return `${borderWidth} solid ${borderColor}`
  }

  const calcuclateBoxShadowStyle = (index: number) => {
    let boxShadow = 'none'

    if (index === props.inputLength) {
      boxShadow = `0px 2px 2px ${color.primary}`
    }

    if (props.hasError) {
      boxShadow = `0px 2px 2px ${color.attention}`
    }

    return boxShadow
  }
  
  return (
    <>
      {[...Array(LENGTH_OF_VERIFY_CODE).keys()].map((index) => (
        <div
          key={index}
          css={{
            width: '40px',
            height: '40px',
            border: calculateBorderStyle(index),
            boxShadow: calcuclateBoxShadowStyle(index),
            borderRadius: '5px',
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px',
          }}
        >
          {props.inputValue[index]}
        </div>
      ))}
    </>
  )
}