import {handleEsc} from '../../lib'
import {selectTheme} from '../../store'
import {Legend, ButtonCancel, TextLink, Button} from '..'

export const PopupConfirmDiscardTask = (props) => {
  const {color, fontWeight} = selectTheme()

  const handleCloseAction = () => {
    if (props.closeAction) props.closeAction(false)
  }

  const handleDiscardAction = () => {
    if (props.discardAction) props.discardAction()
  }

  handleEsc(handleCloseAction)

  return (
    <div
      {...props}
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        zIndex: 99999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <ButtonCancel onClick={handleCloseAction}/>
        <Legend css={{
          margin: '36px 0 16px',
        }}>
          Discard this task ?
        </Legend>
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextLink children='Cancel' onClick={handleCloseAction} />
          <Button onClick={handleDiscardAction} btnPadding='large' btnColor='warning' children='Discard' />
        </div>
      </div>
    </div>
  )
}
