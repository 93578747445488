import {Dispatch, SetStateAction, useState} from 'react'
import {useParams} from 'react-router-dom'
import {selectTheme, selectProjectData} from '../../store'
import {RIF} from '../../lib'
import {taskPlaceholder} from '..'
import {TaskReminder} from '../../model'
import AddTaskAppPreviewPhoneImg from '../../asset/image/add_task_app_preview_phone.png'
import AddTaskAppPreview1Img from '../../asset/image/add_task_app_preview_1.png'
import AddTaskAppPreviewGarminImg from '../../asset/image/add_task_app_preview_1_garmin.png'
import AddTaskAppPreviewMovesenseImg from '../../asset/image/add_task_app_preview_1_movesense.png'
import AddTaskAppPreview2Img from '../../asset/image/add_task_app_preview_2.png'
import AddTaskAppPreview3Img from '../../asset/image/add_task_app_preview_3.png'
import {RepeatIcon} from '../../asset/image'

export interface AddTaskAppPreviewSectionPropsT {
  watch: any
  taskType: 'todo' | 'questionnaire' | 'timer' | 'garminDevice' | 'stopwatchMovesenseStream' | 'stopwatchGarminStream' | 'dexcom'
  taskColor: string
  reminderList: TaskReminder[]
}

export const AddTaskAppPreviewSection = (props: AddTaskAppPreviewSectionPropsT) => {
  const {color} = selectTheme()
  const {watch, taskType, taskColor, reminderList} = props
  const {projectId} = useParams()
  const project = selectProjectData()[projectId]
  const [page, setPage] = useState<number>(1)

  const hasReminder = reminderList.length > 0
  const taskNamePlaceholder = taskPlaceholder(taskType).taskNamePlaceholder
  const taskDescriptionPlaceholder = taskPlaceholder(taskType).taskDescriptionPlaceholder
  const taskScheduleDescriptionPlaceholder = 'Every Day'
  const projectNamePlaceholder = 'Sleep and exercise study in Lab front University'

  return (
    <div css={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      <div css={{width: '256px', height: '512px', margin: '60px auto 20px', position: 'relative'}}>
        <img src={AddTaskAppPreviewPhoneImg} width={256} height={512} />
        {/* template 1 */}
        {RIF(
          page === 1 && taskType !== 'stopwatchMovesenseStream' && taskType !== 'stopwatchGarminStream',
          <>
            <div css={{position: 'absolute', top: '14px', left: '14px', width: '230px', height: '486px'}}>
              <img src={AddTaskAppPreview1Img} width={228} height={484} css={{borderRadius: '20px', zIndex: 1}} />
            </div>
            <div
              css={{
                zIndex: 2,
                position: 'absolute',
                top: '80px',
                left: '25px',
                width: '205px',
                height: '350px',
              }}
            >
              <div css={{fontWeight: '700', fontSize: '16px', marginTop: '10px'}}>
                {watch('name') ? watch('name') : taskNamePlaceholder}
              </div>
              <div css={{display: 'flex', alignItems: 'center', marginTop: '4px'}}>
                {RIF(watch('scheduleRepeat'), <img src={RepeatIcon} height={16} width={16} />)}
                <p css={{color: color.grey_400, marginLeft: '5px', fontSize: '12px', fontWeight: '700'}}>
                  {watch('scheduleDescription') ? watch('scheduleDescription') : taskScheduleDescriptionPlaceholder}
                </p>
              </div>
              <p
                css={{
                  marginTop: '8px',
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  webkitLineClamp: '15',
                  webkitBoxOrient: 'vertical',
                }}
              >
                {watch('description') ? watch('description') : taskDescriptionPlaceholder}
              </p>
            </div>
            <div
              css={{
                zIndex: 2,
                position: 'absolute',
                top: '436px',
                left: '25px',
                width: '205px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: color.white,
                fontSize: '10px',
                fontWeight: '700',
              }}
            >
              Mark as Complete
            </div>
          </>,
        )}

        {/* template 1 garmin/movesense */}
        {RIF(
          page === 1 && (taskType === 'stopwatchGarminStream' || taskType === 'stopwatchMovesenseStream'),
          <>
            <div css={{position: 'absolute', top: '14px', left: '14px', width: '230px', height: '486px'}}>
              <img
                src={taskType === 'stopwatchGarminStream' ? AddTaskAppPreviewGarminImg : AddTaskAppPreviewMovesenseImg}
                width={228}
                height={484}
                css={{borderRadius: '20px', zIndex: 1}}
              />
            </div>
            <div
              css={{
                zIndex: 2,
                position: 'absolute',
                top: '80px',
                left: '25px',
                width: '205px',
                height: '260px',
              }}
            >
              <div css={{fontWeight: '700', fontSize: '16px', marginTop: '10px'}}>
                {watch('name') ? watch('name') : taskNamePlaceholder}
              </div>
              <div css={{display: 'flex', alignItems: 'center', marginTop: '4px'}}>
                {RIF(watch('scheduleRepeat'), <img src={RepeatIcon} height={16} width={16} />)}
                <p css={{color: color.grey_400, marginLeft: '5px', fontSize: '12px', fontWeight: '700'}}>
                  {watch('scheduleDescription') ? watch('scheduleDescription') : taskScheduleDescriptionPlaceholder}
                </p>
              </div>
              <p
                css={{
                  marginTop: '8px',
                  fontSize: '12px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  webkitLineClamp: '11',
                  webkitBoxOrient: 'vertical',
                }}
              >
                {watch('description') ? watch('description') : taskDescriptionPlaceholder}
              </p>
            </div>
          </>,
        )}

        {/* template 2 */}
        {RIF(
          page === 2,
          <>
            <div css={{position: 'absolute', top: '14px', left: '14px', width: '230px', height: '486px'}}>
              <img src={AddTaskAppPreview2Img} width={228} height={484} css={{borderRadius: '20px', zIndex: 1}} />
            </div>
            <div
              css={{
                zIndex: 2,
                position: 'absolute',
                top: '60px',
                left: '25px',
                width: '205px',
                height: '50px',
              }}
            >
              <div css={{width: '72%', fontSize: '12px', fontWeight: '700'}}>
                {project?.name ? project?.name : projectNamePlaceholder}
              </div>
            </div>
            <div
              css={{
                zIndex: 2,
                position: 'absolute',
                top: '120px',
                left: '25px',
                width: '205px',
                height: '200px',
              }}
            >
              <div css={{width: '100%', fontSize: '10px'}}>Your Tasks</div>
              <div
                css={{
                  width: '100%',
                  border: `1px solid ${color.grey_100}`,
                  borderLeft: taskColor ? `4px solid ${taskColor}` : `4px solid ${color.taskGreen}`,
                  borderRadius: '3px',
                  boxShadow: '#D4D4D440',
                  padding: '8px 10px',
                  marginTop: '10px',
                }}
              >
                <div
                  css={{
                    fontSize: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {watch('name') ? watch('name') : taskNamePlaceholder}
                </div>
                <div css={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                  {RIF(watch('scheduleRepeat'), <img src={RepeatIcon} width='12' height='12' />)}
                  <p
                    css={{
                      fontSize: '12px',
                      color: color.grey_400,
                      WebkitTransform: 'scale(0.9)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {watch('scheduleDescription') ? watch('scheduleDescription') : taskScheduleDescriptionPlaceholder}
                  </p>
                </div>
              </div>
            </div>
          </>,
        )}

        {/* template 3 reminder */}
        {RIF(
          page === 3,
          <>
            <div css={{position: 'absolute', top: '14px', left: '14px', width: '230px', height: '486px'}}>
              <img src={AddTaskAppPreview3Img} width={228} height={484} css={{borderRadius: '20px', zIndex: 1}} />
            </div>
            <div
              css={{
                zIndex: 2,
                position: 'absolute',
                top: '181px',
                left: '54px',
                width: '140px',
                height: '10px',
                color: color.grey_600,
                fontSize: '9px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {watch('name')}
            </div>
          </>,
        )}
      </div>

      {/* pagination */}
      <Pagination
        {...{
          page,
          setPage,
          hasReminder,
        }}
      />
    </div>
  )
}

interface PaginationPropsT {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  hasReminder: boolean
}

const Pagination = (props: PaginationPropsT) => {
  const {color} = selectTheme()
  const {page, setPage, hasReminder} = props

  const [leftHoverState, setLeftHoverState] = useState(false)
  const [rightHoverState, setRightHoverState] = useState(false)
  const lastPage = hasReminder ? 3 : 2

  return (
    <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 'fit-content'}}>
      {/* left */}
      <div css={{position: 'relative'}}>
        <div
          onClick={() => page !== 1 && setPage((prev) => prev - 1)}
          onMouseOver={() => page !== 1 && setLeftHoverState(true)}
          onMouseOut={() => setLeftHoverState(false)}
          css={{
            border: `2px solid ${page === 1 ? color.disabled : color.grey_600}`,
            height: '10px',
            width: '10px',
            borderBottom: 'none',
            borderRight: 'none',
            transform: 'rotate(-45deg)',
            cursor: 'pointer',
            position: 'relative',
            zIndex: 2,
          }}
        />
        {RIF(
          leftHoverState,
          <div
            css={{
              position: 'absolute',
              top: '50%',
              left: '43%',
              transform: 'translate(-50%, -50%)',
              width: '32px',
              height: '32px',
              background: color.grey_160,
              borderRadius: '50%',
              zIndex: 1,
            }}
          />,
        )}
      </div>

      {/* dots */}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          margin: '0 30px',
        }}
      >
        <div
          onClick={() => setPage(1)}
          css={{
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            background: page === 1 ? color.black : color.disabled,
            cursor: 'pointer',
            marginRight: '8px',
          }}
        />
        <div
          onClick={() => setPage(2)}
          css={{
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            background: page === 2 ? color.black : color.disabled,
            cursor: 'pointer',
            marginRight: '8px',
          }}
        />
        {RIF(
          hasReminder,
          <div
            onClick={() => setPage(3)}
            css={{
              height: '8px',
              width: '8px',
              borderRadius: '50%',
              background: page === 3 ? color.black : color.disabled,
              cursor: 'pointer',
            }}
          />,
        )}
      </div>

      {/* right */}
      <div css={{position: 'relative'}}>
        <div
          onClick={() => page !== lastPage && setPage((prev) => prev + 1)}
          onMouseOver={() => page !== lastPage && setRightHoverState(true)}
          onMouseOut={() => setRightHoverState(false)}
          css={{
            border: `2px solid ${page === lastPage ? color.disabled : color.grey_600}`,
            height: '10px',
            width: '10px',
            borderBottom: 'none',
            borderRight: 'none',
            transform: 'rotate(135deg)',
            cursor: 'pointer',
            position: 'relative',
            zIndex: 2,
          }}
        />
        {RIF(
          rightHoverState,
          <div
            css={{
              position: 'absolute',
              top: '50%',
              left: '67%',
              transform: 'translate(-50%, -50%)',
              width: '32px',
              height: '32px',
              background: color.grey_160,
              borderRadius: '50%',
              zIndex: 1,
            }}
          />,
        )}
      </div>
    </div>
  )
}
