import {selectTheme} from '../store'

export const calculateInputCSS = (props: any) => {
  const {color, pad} = selectTheme()
  return {
    width: '100%',
    padding: pad.mediumSmall,
    borderRadius: pad.small,
    backgroundColor: `${props.readOnly ? color.background : color.white}`,
    border: `1px solid ${props.hasError ? color.attention : color.grey_160}`,
    ':hover': {
      border: `1px solid ${color.grey_400}`,
    },
    ':active': {
      border: `1px solid ${props.hasError ? color.attention : color.grey_400}`,
      outline: 'none',
    },
    ':focus': {
      border: `1px solid ${props.hasError ? color.attention : color.grey_400}`,
      boxShadow: `none`,
      outline: 'none',
    },
    boxSizing: 'border-box',
    resize: 'none',
    '::placeholder': {
      color: color.grey_300,
    },
  }
}
