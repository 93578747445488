import {SetStateAction, Dispatch} from 'react'
import {useParams} from 'react-router-dom'
import {handleEsc, RIF} from '../../lib'
import {selectTheme, selectProjectData} from '../../store'
import {Legend, ButtonCancel, TextLink, Button} from '..'

export interface PopupConfirmDeleteTaskPropsT {
  taskName: string
  closeAction: Dispatch<SetStateAction<boolean>>
  deleteAction: () => void
}

export const PopupConfirmDeleteTask = (props: PopupConfirmDeleteTaskPropsT) => {
  const {color, fontWeight} = selectTheme()
  const {projectId} = useParams()
  const project = selectProjectData()[projectId]
  const isEditingLiveProject = project.status === 'live'

  const {taskName, closeAction, deleteAction} = props

  const handleCloseAction = () => {
    if (closeAction) closeAction(false)
  }

  const handleDeleteAction = () => {
    if (deleteAction) deleteAction()
  }

  handleEsc(handleCloseAction)

  return (
    <div
      data-testid='task_confirm_delete_pop'
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        zIndex: 99999,
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 'calc(50vh - 145px)',
          left: 'calc(50vw - 300px)',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <ButtonCancel onClick={handleCloseAction}/>
        {RIF(
          !isEditingLiveProject,
          <>
            <Legend css={{
              margin: '36px 0 16px',
            }}>
              Delete "{taskName}" ?
            </Legend>
            <p
              css={{
                fontWeight: fontWeight.bold,
                marginBottom: '80px',
              }}
            >
              This action is permanent.
            </p>
          </>,
        )}
        {RIF(
          isEditingLiveProject,
          <>
            <Legend
              css={{
                marginTop: '36px',
                marginBottom: '16px',
              }}
            >
              Are you sure you want to hide this task?
            </Legend>
            <p
              css={{
                fontWeight: fontWeight.bold,
                marginBottom: '80px',
              }}
            >
              Hiding this task means participants will no longer see this task on their app. This does not affect data
              that has already been collected from this task.
            </p>
          </>,
        )}
        <div
          css={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextLink children='Cancel' onClick={handleCloseAction} />
          <Button
            onClick={handleDeleteAction}
            btnPadding='large'
            btnColor='warning'
            children={isEditingLiveProject ? 'Hide this task' : 'Delete Task'}
          />
        </div>
      </div>
    </div>
  )
}
