import { useEffect, useState } from 'react'
import {
  AdherenceOverviewCard, 
  AdherenceOverviewDataType, 
  ProjectAdherenceOverviewProp,
} from '..'
import { selectTheme } from '../../store'
import {_} from '../../lib'

export const GarminDirectDataAdherenceCard = (props: ProjectAdherenceOverviewProp) => {
  const {color} = selectTheme()  
  const {garminDeviceSpec} = props
  const subDataTypeOptions: {label: string, value: string}[] = []
  if (garminDeviceSpec?.bbiEnable) {
    subDataTypeOptions.push({
      label: 'BBI',
      value: 'garmin_bbi',
    })
  }
  if (garminDeviceSpec?.stepsEnable) {
    subDataTypeOptions.push({
      label: 'Step',
      value: 'garmin_step',
    })
  }
  if (garminDeviceSpec?.stressEnable) {
    subDataTypeOptions.push({
      label: 'Stress',
      value: 'garmin_stress',
    })
  }
  if (garminDeviceSpec?.pulseOxEnable) {
    subDataTypeOptions.push({
      label: 'Pulse Ox',
      value: 'garmin_pulse_ox',
    })
  }
  if (garminDeviceSpec?.heartRateEnable) {
    subDataTypeOptions.push({
      label: 'Heart Rate',
      value: 'garmin_heart_rate',
    })
  }
  if (garminDeviceSpec?.respirationEnable) {
    subDataTypeOptions.push({
      label: 'Respiration',
      value: 'garmin_respiration',
    })
  }
  if (garminDeviceSpec?.actigraphyEnable) {
    subDataTypeOptions.push({
      label: 'Actigraphy',
      value: 'garmin_actigraphy',
    })
  }
  
  const [defaultSubDataType, setDefaultSubDataType] = useState(subDataTypeOptions[0])
  useEffect(() => {
    if (_.isEqual(defaultSubDataType, subDataTypeOptions[0])) return
    if (subDataTypeOptions.length) {
      setDefaultSubDataType(subDataTypeOptions[0])
    }
  }, [subDataTypeOptions])
  const weekCardProps = {
    ...props,
    title: 'Garmin Direct Data Collection',
    hintDescription: '',
    dataType: AdherenceOverviewDataType.GarminDirect,
    mainColor: color.primary,
    subDataTypeOptions,
    defaultSubDataType,
  }

  return <AdherenceOverviewCard {...weekCardProps}/>
}
