import {PromiseType} from 'utility-types'

import {Action} from '../reactive/action'
import {baseRequest} from '../request'

export class RefreshTokenAction extends Action<string, PromiseType<ReturnType<typeof baseRequest>>> {
  static readonly instance = Object.freeze(new RefreshTokenAction())

  private constructor() {
    super((refreshToken) =>
      baseRequest({
        method: 'post',
        url: `${process.env.API_URL}/v1/web/identity-refresh-token-submit`,
        data: {
          refreshToken,
        },
      }),
    )
  }
}
