import { CheckSuccessIcon } from "../../asset/image"
import { selectTheme } from "../../store"
import { ButtonCancel, LabelBox, TextLink, Textarea, Button} from ".."

interface PopupSendNotificationSucceedProps {
  messageTitle: string
  messageContent: string
  closeAction: () => void
}

export const PopupSendNotificationSucceed = (props: PopupSendNotificationSucceedProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    messageTitle,
    messageContent,
    closeAction,
  } = props

  return (
    <div css={{
      background: '#00000040',
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: '103',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <div css={{
        width: '560px',
        padding: '30px',
        borderRadius: '5px',
        backgroundColor: color.white,
      }}>
        <ButtonCancel onClick={closeAction} />
        <img css={{margin: '28px 0 16px'}} width={24} src={CheckSuccessIcon}/>
        <p css={{
          fontSize: fontSize.h3,
          fontWeight: fontWeight.thick,
          marginBottom: '16px',
        }}>Notification sent!</p>
        <p css={{
          fontSize: fontSize.h6,
          marginBottom: '24px',
        }}>Your participants will now see this notification when they open the app.</p>
        <div css={{
          padding: '16px 24px',
          backgroundColor: color.surface.grey.light,
          marginBottom: '48px',
        }}>
          <p css={{
            fontWeight: fontWeight.thick,
            marginBottom: '10px',
          }}>{messageTitle}</p>
          <p css={{
            fontSize: fontSize.h6,
          }}>{messageContent}</p>
        </div>
        <div css={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Button onClick={closeAction} btnPadding="large" children="Done"/>
        </div>
      </div>
    </div>
  )
}