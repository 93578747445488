import {StressValueType} from '../model'
import {GarminConnectRecordDataDaily} from '../../../shared/mongo'
import {LocalDateGarminConnectTransformer} from './base'
import {DailyCompositeChartData} from '../../../model/chart'

export class DailySummaryStressValueTransformer extends LocalDateGarminConnectTransformer<
  GarminConnectRecordDataDaily,
  DailyCompositeChartData<StressValueType>
> {
  transform() {
    return this.toDataFrame(({averageStressLevel, maxStressLevel}) => {
      if (averageStressLevel > 0) {
        return {
          values: [
            {value: maxStressLevel, type: StressValueType.Max},
            {value: averageStressLevel, type: StressValueType.Avg},
          ],
        }
      } else {
        return {}
      }
    })
  }
}
