import { IEntityModel } from './base'
import { IDexcomIntegration } from './dexcom_integration'
import { IIdentity } from './identity'
import { IPurchase } from './purchase'

export enum WorkspaceType {
  Global = 'global',
  User = 'user',
}

export interface IWorkspace extends IEntityModel {
  name: string
  type: WorkspaceType
  identityId: string
  identity?: IIdentity
  participantLimit: number
  dexcomIntegrationList?: IDexcomIntegration[]
  totalParticipants: number
  purchaseList?: IPurchase[]
}
