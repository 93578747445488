import {createAction, useSelector} from '../../../lib'

export const ERROR_REPORT = 'ERROR_REPORT'
export const doERROR_REPORT = createAction(ERROR_REPORT)

export const ERROR_REQUEST_REPORT = 'ERROR_REQUEST_REPORT'
export const doERROR_REQUEST_REPORT = createAction(ERROR_REQUEST_REPORT)

export const selectError = () => useSelector((state) => state.error)

export const selectErrorFromId = (id) =>
  useSelector((state) => {
    return state.error[id] || null
  })

export const errorActionCreators = {
  doERROR_REPORT,
  doERROR_REQUEST_REPORT,
}

const errorDefaultState = {
  general: [],
}

export const errorReducer = (state = errorDefaultState, {type, payload}) => {
  switch (type) {
    case ERROR_REPORT:
      // TODO @slugbyte give general errors IDs
      return {
        ...state,
        general: [payload, ...state.general],
      }
    case ERROR_REQUEST_REPORT: {
      let requestId = payload.requestId
      return {
        ...state,
        [requestId]: payload,
      }
    }
    default:
      return state
  }
}
