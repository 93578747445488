export enum GarminConnectHookType {
  Activity = 'activity',
  ActivityDetails = 'activity_details',
  ActivityFiles = 'activity_files',
  ActivityManuallyUpdated = 'activity_manually_updated',
  ActivityMoveIQ = 'activity_move_iq',
  HealthHRVSummary = 'health_hrv_summary',
  HealthBodyCompositions = 'health_body_compositions',
  HealthDailies = 'health_dailies',
  HealthEpochs = 'health_epochs',
  HealthSnapshot = 'health_snapshot',
  HealthPulseOx = 'health_pulse_ox',
  HealthRespiration = 'health_respiration',
  HealthSleeps = 'health_sleeps',
  HealthStress = 'health_stress',
  HealthThirdPartyDailies = 'health_third_party_dailies',
  HealthUserMetrics = 'health_user_metrics',
  HealthBloodPressure = 'health_blood_pressure',
  WomenHealthMCT = 'women_health_mct',
}
