import {head, last, sortBy} from 'lodash'
import {InputWithoutMeta} from 'client-zip'

import {t} from '../t'
import {componentsFromString} from '../../shared/tabulator'
import {DataDownloaderItem} from '../data_downloader/model'
import {Content, TABULAR_FILE_PATH_COMPONENT_SEP} from './model'
import {ContentParser} from './content_parser'
import {CSVBuilder} from './csv_builer'
import {DataFrame} from 'data-forge'

export type FileMergerInput = Omit<DataDownloaderItem, 'url'> & InputWithoutMeta & {input: ReadableStream}

export class FileMerger {
  async merge(inputs: FileMergerInput[]): Promise<InputWithoutMeta> {
    switch (inputs.length) {
      case 0:
        throw new Error('empty inputs')
      case 1:
        return inputs[0]
      default:
        break
    }

    const sortedInputs = sortBy(inputs, ({key}) => {
      const {localDateIndex} = componentsFromString(key, TABULAR_FILE_PATH_COMPONENT_SEP)
      return localDateIndex
    })

    const parser = new ContentParser()
    const contents: Content[] = []
    for (const {input} of sortedInputs) {
      const parsed = await parser.parse(input)
      contents.push(parsed)
    }

    const builder = new CSVBuilder()
    const csvString = builder.build({
      headers: t.unwrap(last(contents)?.headers),
      body: DataFrame.concat(contents.map(({body}) => body)),
    })

    return {
      input: t.stringToStream(csvString),
      name: head(sortedInputs)?.name,
      lastModified: last(sortedInputs)?.lastModified,
    }
  }
}
