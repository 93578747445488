import {useEffect, useState} from 'react'
import {ScheduleType} from '../../shared/db'
import {LocalSchedule} from './add_schedule_pop'
import {t} from '../../lib'
import {TaskScheduleSelectionChildProps, TaskScheduleSelectionType} from './task_schedule_type_selection'
import {DayNumberWeekT} from './repeat_date_dot'
import {Select} from '../atoms'
import {selectTheme} from '../../store'

interface RecurrentXDayOption {
  label: string
  value: number
}

export const TaskScheduleRecurrentXDaySelection = (
  props: TaskScheduleSelectionChildProps
) => {
  const {
    selected,
    scheduleType,
    taskSchedules,
    setTaskSchedules
  } = props
  const {fontSize} = selectTheme()
  const recurrentXDayOptions: RecurrentXDayOption[] = [
    { label: "Daily (Everyday)", value: 1 },
    { label: "Every 2 Days (Every other day)", value: 2 },
    { label: "Every 3 Days", value: 3 },
    { label: "Every 4 Days", value: 4 },
    { label: "Every 5 Days", value: 5 },
    { label: "Every 6 Days", value: 6 },
    { label: "Every 7 Days", value: 7 },
    { label: "Every 8 Days", value: 8 },
    { label: "Every 9 Days", value: 9 },
    { label: "Every 10 Days", value: 10 },
  ]
  const defaultRecurrentXDay: RecurrentXDayOption | null = (() => {
    if (scheduleType === TaskScheduleSelectionType.RecurrentXDay && taskSchedules.length !== 0) {
      return recurrentXDayOptions[t.unwrap(taskSchedules[0].schedule.day) - 1]
    } else {
      return null
    }
  })()

  const [recurrentXDays, setRecurrentXDays] = useState<RecurrentXDayOption | null>(defaultRecurrentXDay)
  useEffect(() => {
    if (recurrentXDays) {
      const schedule: LocalSchedule = {
        type: ScheduleType.Recurring,
        schedule: {
          day: recurrentXDays.value
        }
      }
      setTaskSchedules([schedule])
    } else {
      setTaskSchedules([])
    }
  }, [recurrentXDays])
  return (
    <Select
      value={selected ? recurrentXDays : null }
      options={recurrentXDayOptions}
      onChange={setRecurrentXDays}
      css={{
        marginBottom: '16px',
        width: '100%',
        fontSize: fontSize.h7,
      }}
    />
  )
}

