import {put, takeEvery, debug, createAction} from '../../lib'
import {REQUEST_COMPLETE} from '../state'
import {
  REQUEST_IDENTITY_WORKSPACE_COLLABORATOR_LIST_FETCH,
  REQUEST_IDENTITY_WORKSPACE_LIST_FETCH,
  REQUEST_PROJECT_LIST_FETCH,
  REQUEST_BATCH_ADHERENCE,
  REQUEST_BATCH_FETCH,
} from '..'

export const HANDLE_LIST_FETCH = 'HANDLE_LIST_FETCH'
export const doHANDLE_LIST_FETCH = createAction(HANDLE_LIST_FETCH)

export const handleListFetchActionCreators = {
  doHANDLE_LIST_FETCH,
}

export function* handleListFetch({payload, setRequestResult}: any) {
  debug('saga*handleListFetch')

  const {fromType, fromPayload, page} = payload
  if (page?.next) {
    if (
      fromType === REQUEST_BATCH_ADHERENCE ||
      fromType === REQUEST_BATCH_FETCH ||
      fromType === REQUEST_IDENTITY_WORKSPACE_LIST_FETCH ||
      fromType === REQUEST_PROJECT_LIST_FETCH ||
      fromType === REQUEST_IDENTITY_WORKSPACE_COLLABORATOR_LIST_FETCH
    ) {
      fromPayload.page = page?.next
      yield put({
        type: fromType,
        payload: fromPayload,
        setRequestResult
      })
    }
  }
}

export function* handleListFetchSaga() {
  yield takeEvery(REQUEST_COMPLETE, handleListFetch)
}
