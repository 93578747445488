import {useEffect, useState} from 'react'
import {ScheduleType} from '../../shared/db'
import {LocalSchedule} from './add_schedule_pop'
import {t} from '../../lib'
import {TaskScheduleSelectionChildProps, TaskScheduleSelectionType} from './task_schedule_type_selection'
import {DayNumberWeekT, RepeatDateDot} from './repeat_date_dot'

export const TaskScheduleRecurrentWeekdaySelection = (
  props: TaskScheduleSelectionChildProps
) => {
  const {
    selected,
    scheduleType,
    taskSchedules,
    setTaskSchedules
  } = props
  const defaultRecurrentWeekdays: DayNumberWeekT[] = (() => {
    if (scheduleType === TaskScheduleSelectionType.RecurrentWeekDay && taskSchedules.length !== 0) {
      return taskSchedules.map((schedule) => (t.unwrap(schedule.schedule.weekday) - 1) as DayNumberWeekT)
    } else {
      return []
    }
  })()
  const [recurrentWeekdays, setRecurrentWeekDays] = useState<DayNumberWeekT[]>(defaultRecurrentWeekdays)
  useEffect(() => {
    const schedules: LocalSchedule[] = recurrentWeekdays
      .map((dayNumberWeekT) => dayNumberWeekT + 1)
      .map((weekday) => {
        return {
          type: ScheduleType.Recurring,
          schedule: {
            weekday: weekday
          }
        }
      })
    setTaskSchedules(schedules)
  }, [recurrentWeekdays])
  const weekdayTextAndValues: {text: 'S' | 'M' | 'T' | 'W' | 'F', value: DayNumberWeekT}[] =
    [{text: 'S', value: 0},
      {text: 'M', value: 1},
      {text: 'T', value: 2},
      {text: 'W', value: 3},
      {text: 'T', value: 4},
      {text: 'F', value: 5},
      {text: 'S', value: 6},]
  return (<div css={{marginTop: '32px'}}>
    <p>Which days of the week does a task recur? You can select multiple.</p>
    <div css={{marginTop: '16px', display: 'flex', alignItems: 'center'}}>
      {weekdayTextAndValues
        .map(({text, value}) => {
          return (<RepeatDateDot {...{
            key: value,
            text,
            setReminderRepeatDays: setRecurrentWeekDays,
            value,
            reminderRepeatDays: selected ? recurrentWeekdays : []
          }} />)
        })
      }
    </div>
  </div>)
}

