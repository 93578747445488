import {ChartConfig, TimeSeriesChartType} from '../template/time_series_data_chart'
import {StreamDataChart, StreamDataChartProps} from '../template/stream_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import { VisualizerGraphDataType } from '../../../shared/db'

export const GarminStreamAccChart = (props: StreamDataChartProps) => {
  const {height, width, completionIdList, plotDataRange, commonTaskDataMarkerList} = props

  const accScaleOption = {min: -1500, max: 1500}
  
  const dataConfig = {}

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
  }
  
  const chartCardConfig = {
    height,
    width,
    title: 'Garmin Acc',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const streamDataChartProp = {
    dataType: VisualizerGraphDataType.GarminDirectAccelerometerRaw,
    config,
    completionIdList,
    plotDataRange,
    commonTaskDataMarkerList,
    defaultScaleOption: accScaleOption
  }

  return (
    <>
      <StreamDataChart {...streamDataChartProp} />
    </>
  )
}
