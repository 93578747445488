import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'

export const TASK_SETS_SET = 'TASK_SETS_SET'
export const doTASK_SETS_SET = createAction(TASK_SETS_SET)

export const TASK_SETS_DELETE = 'TASK_SETS_DELETE'
export const doTASK_SETS_DELETE = createAction(TASK_SETS_DELETE)

export const selectTaskSets = () => {
  return useSelector((state) => state.taskSets)
}

export const taskSetsActionCreators = {
  doTASK_SETS_SET,
  doTASK_SETS_DELETE,
}

export const taskSetsDefaultState = {}

export const taskSetsReducer = (state = taskSetsDefaultState, {type, payload}) => {
  switch (type) {
    case TASK_SETS_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          name: v.string().exist(),
          identityId: v.string().uuid().exist(),
          workspaceId: v.string().uuid().exist(),
          projectId: v.string().uuid().exist(),
          methodId: v.string().uuid().exist(),
          description: v.string().optional(),
          updatedAt: v.string().optional(),
          createdAt: v.string().optional(),
          scheduleDescription: v.string().optional(),
          scheduleRepeat: v.boolean().optional(),
          taskList: v.array().optional(),
        }),
      })

      const newState = {...state}
      newState[payload.id] = payload
      return {...newState}
    }

    case TASK_SETS_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })

      const newState = {...state}
      delete newState[payload.id]
      return {...newState}
    }

    default:
      return {...state}
  }
}
