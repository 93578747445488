import React from 'react'

import {Navigate} from 'react-router-dom'
import {selectAuth} from '../../store/state'
import {useCurrentProjectState} from '../../lib/custom_hooks'

interface ProtectedRouteProps {
  children: React.ReactNode
  requireProjectId?: boolean
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children, requireProjectId}) => {
  const accessToken = selectAuth()?.accessToken
  if (!accessToken) {
    return <Navigate to='/' />
  }

  const {projectId, project} = useCurrentProjectState()
  if (requireProjectId) {
    if (!projectId) return <Navigate to='/project' />
  }

  return <>{children}</>
}