import {
  assertPartialSchema, 
  createAction, 
  useSelector, 
  v,
  _,
} from '../../../lib'
import {
  Questionnaire,
  Question,
  Section,
} from '../../../model'

export enum QuestionnairesActionType {
  QUESTIONNAIRES_SET = 'QUESTIONNAIRES_SET',
  QUESTIONNAIRES_DELETE = 'QUESTIONNAIRES_DELETE',
  QUESTIONNAIRES_EDITING_STATUS_SET = 'QUESTIONNAIRES_EDITING_STATUS_SET',
  QUESTIONNAIRES_EDITING_STATUS_RESET = 'QUESTIONNAIRES_EDITING_STATUS_RESET',
  QUESTIONNAIRES_QUESTION_SET = 'QUESTIONNAIRES_QUESTION_SET',
  QUESTIONNAIRES_QUESTION_DELETE = 'QUESTIONNAIRES_QUESTION_DELETE',
  QUESTIONNAIRES_SECTION_SET = 'QUESTIONNAIRES_SECTION_SET',
  QUESTIONNAIRES_SECTION_DELETE = 'QUESTIONNAIRES_SECTION_DELETE',
  QUESTIONNAIRES_BACKUP_SET = 'QUESTIONNAIRES_BACKUP_SET',
  QUESTIONNAIRES_BACKUP_RESET = 'QUESTIONNAIRES_BACKUP_RESET',
  QUESTIONNAIRES_RESTORE = 'QUESTIONNAIRE_RESTORE',
  QUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET = 'QUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET',
}

export const doQUESTIONNAIRES_SET = createAction(QuestionnairesActionType.QUESTIONNAIRES_SET)
export const doQUESTIONNAIRES_DELETE = createAction(QuestionnairesActionType.QUESTIONNAIRES_DELETE)
export const doQUESTIONNAIRES_EDITING_STATUS_SET = createAction(QuestionnairesActionType.QUESTIONNAIRES_EDITING_STATUS_SET)
export const doQUESTIONNAIRES_EDITING_STATUS_RESET = createAction(QuestionnairesActionType.QUESTIONNAIRES_EDITING_STATUS_RESET)
export const doQUESTIONNAIRES_QUESTION_SET = createAction(QuestionnairesActionType.QUESTIONNAIRES_QUESTION_SET)
export const doQUESTIONNAIRES_QUESTION_DELETE = createAction(QuestionnairesActionType.QUESTIONNAIRES_QUESTION_DELETE)
export const doQUESTIONNAIRES_SECTION_SET = createAction(QuestionnairesActionType.QUESTIONNAIRES_SECTION_SET)
export const doQUESTIONNAIRES_SECTION_DELETE = createAction(QuestionnairesActionType.QUESTIONNAIRES_SECTION_DELETE)
export const doQUESTIONNAIRES_BACKUP_SET = createAction(QuestionnairesActionType.QUESTIONNAIRES_BACKUP_SET)
export const doQUESTIONNAIRES_BACKUP_RESET = createAction(QuestionnairesActionType.QUESTIONNAIRES_BACKUP_RESET)
export const doQUESTIONNAIRES_RESTORE = createAction(QuestionnairesActionType.QUESTIONNAIRES_RESTORE)
export const doQUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET = createAction(QuestionnairesActionType.QUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET)
 
interface QuestionnairesState {
  questionnaireList: Questionnaire[]
  editing: {
    editingQuestionnaireId: string | null
    editingBlock: string | null
    viewOnly: boolean
  }
  questionList: Question[]
  sectionList: Section[]
  backup: {
    questionnaireList: Questionnaire[]
    questionList: Question[]
    sectionList: Section[]
  }
  duplicatedQuestionnaireList: string[]
}

interface RootState {
  questionnaires: QuestionnairesState
}

export const selectQuestionnaires = () => {
  return useSelector((state: RootState) => state.questionnaires)
}

export const questionnairesActionCreators = {
  doQUESTIONNAIRES_SET,
  doQUESTIONNAIRES_DELETE,
  doQUESTIONNAIRES_EDITING_STATUS_SET,
  doQUESTIONNAIRES_EDITING_STATUS_RESET,
  doQUESTIONNAIRES_QUESTION_SET,
  doQUESTIONNAIRES_QUESTION_DELETE,
  doQUESTIONNAIRES_SECTION_SET,
  doQUESTIONNAIRES_SECTION_DELETE,
  doQUESTIONNAIRES_BACKUP_SET,
  doQUESTIONNAIRES_BACKUP_RESET,
  doQUESTIONNAIRES_RESTORE,
  doQUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET,
}

export const questionnairesDefaultState: QuestionnairesState = {
  questionnaireList: [],
  editing: {
    editingQuestionnaireId: null,
    editingBlock: null,
    viewOnly: false,
  },
  questionList: [],
  sectionList: [],
  backup: {
    questionnaireList: [],
    questionList: [],
    sectionList: [],
  },
  duplicatedQuestionnaireList: [],
}

type Action =
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_SET
      payload: Questionnaire
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_DELETE
      payload: {
        id: string
      }
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_EDITING_STATUS_SET
      payload: {
        editingQuestionnaireId?: string
        editingBlock?: string
        viewOnly?: boolean
      }
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_EDITING_STATUS_RESET
      payload: undefined
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_QUESTION_SET
      payload: Question
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_QUESTION_DELETE
      payload: {
        id: string
      }
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_SECTION_SET
      payload: Section
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_SECTION_DELETE
      payload: {
        id: string
      }
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_BACKUP_SET
      payload: {
        questionnaireList: Questionnaire[]
        sectionList: Section[]
        questionList: Question[]
      }
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_BACKUP_RESET
      payload: undefined
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_RESTORE
      payload: undefined
    }
  | {
      type: QuestionnairesActionType.QUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET
      payload: {
        id: string
      }
  }  

export const questionnairesReducer = (
  state: QuestionnairesState=questionnairesDefaultState, 
  {type, payload}: Action
): QuestionnairesState => {
  switch (type) {
    case QuestionnairesActionType.QUESTIONNAIRES_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().allow('questionnaireDraft').exist(),
          methodId: v.string().uuid().exist(),
          updatedAt: v.string().optional(),
          createdAt: v.string().optional(),
          taskId: v.string().optional(),
          projectId: v.string().uuid().optional(),
          workspaceId: v.string().uuid().optional(),
          name: v.string().optional(),
          description: v.string().optional(),
          contentList: v
            .array()
            .optional()
            .items(
              v
                .object({
                  id: v.string().uuid().exist(),
                  category: v.string().exist().allow('question', 'section'),
                  sectionId: v.string().uuid().optional(),
                })
                .optional(),
            ),
          sectionList: v.array().exist(),
        }),
      })
      const newQuestionnaireList = _.unionBy(
        [payload as Questionnaire],
        [...state.questionnaireList],
        'id',
      )
      return {...state, questionnaireList: newQuestionnaireList}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().allow('questionnaireDraft').exist(),
        }),
      })

      const newQuestionnaireList = state.questionnaireList
      _.remove(newQuestionnaireList, (questionnaire) => questionnaire.id === payload.id)
      return {...state, questionnaireList: newQuestionnaireList}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_EDITING_STATUS_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          editingQuestionnaireId: v.string().uuid().allow('questionnaireDraft').optional().allow(null),
          editingBlock: v.string().uuid().optional().allow(null),
          viewOnly: v.boolean().optional(),
        })
      })

      const editing = {
        editingQuestionnaireId: payload.editingQuestionnaireId ?? state.editing.editingQuestionnaireId,
        editingBlock: payload.editingBlock ?? state.editing.editingBlock,
        viewOnly: payload.viewOnly ?? state.editing.viewOnly,
      }
      return {...state, editing}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_EDITING_STATUS_RESET:
      return {...state, editing: {...questionnairesDefaultState.editing}}

    case QuestionnairesActionType.QUESTIONNAIRES_QUESTION_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          sectionId: v.string().uuid().exist(),
          id: v.string().uuid().exist(),
          label: v.string().exist(),
          index: v.number().optional(),
          description: v.string().optional().allow(''),
          type: v.string().exist(),
          inputConfig: v.object().optional(),
        }),
      })
      
      const newQuestionList = _.unionBy(
        [payload as Question],
        [...state.questionList],
        'id'
      )
      return {...state, questionList: newQuestionList}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_QUESTION_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().allow('questionnaireDraft').exist(),
        }),
      })

      const newQuestionList = state.questionList
      _.remove(newQuestionList, (question) => question.id === payload.id)
      return {...state, questionList: newQuestionList}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_SECTION_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          questionnaireId: v.string().exist(),
          id: v.string().uuid().exist(),
          legend: v.string().exist().allow(''),
          index: v.number().optional(),
          description: v.string().optional().allow(''),
          questionList: v.array().exist(),
        }),
      })
      const newSectionList = _.unionBy(
        [payload as Section],
        [...state.sectionList],
        'id'
      )
      return {...state, sectionList: newSectionList}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_SECTION_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().allow('questionnaireDraft').exist(),
        }),
      })

      const newSectionList = state.sectionList
      _.remove(newSectionList, (section) => section.id === payload.id)
      return {...state, sectionList: newSectionList}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_BACKUP_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          questionnaireList: v.array().items(
            v.object({
              id: v.string().uuid().allow('questionnaireDraft').exist(),
              methodId: v.string().uuid().exist(),
              updatedAt: v.string().optional(),
              createdAt: v.string().optional(),
              taskId: v.string().optional(),
              projectId: v.string().uuid().optional(),
              workspaceId: v.string().uuid().optional(),
              name: v.string().optional(),
              description: v.string().optional(),
              contentList: v
                .array()
                .optional()
                .items(
                  v
                    .object({
                      id: v.string().uuid().exist(),
                      category: v.string().exist().allow('question', 'section'),
                      sectionId: v.string().uuid().optional(),
                    })
                    .optional(),
                ),
              sectionList: v.array().exist(),
            })
          ),
          sectionList: v.array().items(
            v.object({
              questionnaireId: v.string().optional(),
              id: v.string().uuid().optional(),
              legend: v.string().exist().allow(''),
              index: v.number().optional(),
              description: v.string().optional().allow(''),
              questionList: v.array().exist(),
            })
          ),
          questionList: v.array().items(
            v.object({
              sectionId: v.string().uuid().optional(),
              id: v.string().uuid().optional(),
              label: v.string().exist().allow(''),
              index: v.number().optional(),
              description: v.string().optional().allow(''),
              type: v.string().exist(),
              inputConfig: v.object().optional(),
            })
          )
        })
      })

      return {...state, backup: payload}

    case QuestionnairesActionType.QUESTIONNAIRES_BACKUP_RESET:
      return {...state, backup: questionnairesDefaultState.backup}

    case QuestionnairesActionType.QUESTIONNAIRES_RESTORE: {
      const backup = {...state.backup}

      return {...questionnairesDefaultState, ...backup}
    }

    case QuestionnairesActionType.QUESTIONNAIRES_DUPLICATED_QUESTIONNAIRE_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
        }),
      })
      const duplicatedQuestionnaireList = _.uniq([...state.duplicatedQuestionnaireList, payload.id])
      return {...state, duplicatedQuestionnaireList}
    }
      
    default:
      return {...state}
  }
}
