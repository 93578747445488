import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST'
export const doPASSWORD_RESET_REQUEST = createDoRequestAction(PASSWORD_RESET_REQUEST)

export const passwordResetRequestActionCreators = {
  doPASSWORD_RESET_REQUEST,
}

export function* passwordResetRequest({payload, setRequestResult}: any): SagaIterator {
  debug('saga*passwordResetRequest')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        email: v.string().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: PASSWORD_RESET_REQUEST,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {email} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-password-reset-request`,
    data: {
      email,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR(
        {
          fromType: PASSWORD_RESET_REQUEST,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/identity-password-reset-request',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: PASSWORD_RESET_REQUEST,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* passwordResetRequestSaga() {
  yield takeEvery(PASSWORD_RESET_REQUEST, passwordResetRequest)
}
