import React from 'react'
import {selectTheme} from '../../store'

export const StatusTag = (props) => {
  const {color, fontWeight} = selectTheme()
  const {status} = props

  const statusColor = (status) => {
    const backgroundColor =
      status && (status === 'draft' ? '#0000000D' : status === 'completed' ? color.white : '#5AAA7C1A')
    const fontColor = status && (status === 'draft' ? '#797979' : status === 'completed' ? color.grey_400 : '#5AAA7C')
    return {
      backgroundColor,
      fontColor,
    }
  }

  return (
    <div
      {...props}
      css={{
        width: 'fit-content',
        height: 'fit-content',
        background: statusColor(status).backgroundColor,
        color: statusColor(status).fontColor,
        fontWeight: fontWeight.thick,
        fontSize: '12px',
        display: 'flex',
        border: status === 'completed' && `1px solid ${color.grey_400}`,
        borderRadius: '2px',
        padding: `2px 6px`,
      }}
    >
      {status?.charAt(0).toUpperCase() + status?.slice(1)}
    </div>
  )
}
