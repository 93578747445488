import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doPARTICIPANT_TASK_TIMELINE_DATA_SET} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH = 'REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH'
export const doREQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH = createDoRequestAction(
  REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH,
)

export const requestParticipantTaskTimelineDataFetchActionCreators = {
  doREQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH,
}

export function* requestParticipantTaskDataTimelineFetch({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestParticipantTaskDataTimelineFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid(),
        projectId: v.string().uuid().exist(),
        participantId: v.string().uuid().exist(),
        yymmddIndex: v.number(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {participantId, yymmddIndex} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v2/web/participant-daily-data-digest`,
    accessToken,
    data: {
      participantId,
      yymmddIndex,
      requestGarminDirectData: false,
      requestGarminConnectData: false,
      requestDexcomData: false,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v2/web/participant-daily-data-digest',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  console.log('participant-daily-data-digest', result.payload)

  yield put(
    doPARTICIPANT_TASK_TIMELINE_DATA_SET({
      projectId: payload.projectId,
      ...result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      url: '/v2/web/participant-daily-data-digest',
      ...result,
    }, setRequestResult),
  )
}

export function* participantTaskTimelinDataFetchSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_TASK_DATA_TIMELINE_FETCH, requestParticipantTaskDataTimelineFetch)
}
