import {selectTheme} from '../../store'

export interface LabelPropsT {
  htmlFor?: string
  css?: any
  children?: any
}

/* styled label for form inputs, use LabelBox instead if possible*/
export const Label = (props: LabelPropsT) => {
  const {fontWeight, pad} = selectTheme()
  const sanitizedProps = {...props}
  return (
    <label
      {...sanitizedProps}
      css={{
        display: 'block',
        fontWeight: fontWeight.bold,
        marginBottom: pad.mediumSmall,
      }}
    />
  )
}
