import {v, useSelector, createAction, assertPartialSchema, setSessionStorage} from '../../../lib'

// SELECTOR
export const selectAuth = () => {
  return useSelector((state) => state?.auth || null)
}
export const selectAccessToken = () => {
  return useSelector((state) => state?.auth?.accessToken || null)
}
export const selectRefreshToken = () => {
  return useSelector((state) => state?.auth?.refreshToken || null)
}
// ACTIONS
export const AUTH_SET = 'AUTH_SET'
export const doAUTH_SET = createAction(AUTH_SET)

export const AUTH_REFRESHING = 'AUTH_REFRESHING'
export const doAUTH_REFRESHING = createAction(AUTH_REFRESHING)

export const AUTH_REFRESHING_FAILURE = 'AUTH_REFRESHING_FAILURE'
export const doAUTH_REFRESHING_FAILURE = createAction(AUTH_REFRESHING_FAILURE)

export const LOGOUT = 'LOGOUT'
export const doLOGOUT = createAction(LOGOUT)

export const authActionCreators = {
  doAUTH_SET,
  doAUTH_REFRESHING,
  doAUTH_REFRESHING_FAILURE,
  doLOGOUT,
}

// REDUCER
export const authDefaultState = {
  accessToken: null,
  refreshToken: null,
  isRefreshing: false,
  accessTokenExpireTimestamp: 0,
  refreshTokenExpireTimestamp: 0,
}

export const authReducer = (state = {...authDefaultState}, {type, payload}) => {
  switch (type) {
    case AUTH_SET: {
      assertPartialSchema({
        payload,
        schema: v.object({
          accessToken: v.string().exist(),
          refreshToken: v.string().exist(),
          accessTokenExpiresInSeconds: v.number().exist(),
          refreshTokenExpiresInSeconds: v.number().exist(),
        }),
      })

      const {accessToken, refreshToken, accessTokenExpiresInSeconds, refreshTokenExpiresInSeconds} = payload
      const now = new Date().getTime()

      const auth = {
        accessToken,
        refreshToken,
        accessTokenExpireTimestamp: now + accessTokenExpiresInSeconds * 1000,
        refreshTokenExpireTimestamp: now + refreshTokenExpiresInSeconds * 1000,
        isRefreshing: false,
      }

      setSessionStorage({auth})
      return auth
    }

    case AUTH_REFRESHING:
      if (!state.isRefreshing) return {...state, isRefreshing: true}
      else return state

    case AUTH_REFRESHING_FAILURE:
      return {...state, accessToken: null, isRefreshing: false}

    case LOGOUT:
      return authDefaultState
    default:
      return state
  }
}
