import { IEntityModel } from './base'
import { IProject } from './project'
import { ITask } from './task'
import { IWorkspace } from './workspace'

export enum StopwatchGarminStreamAccSampleRate {
  HZ_25 = 25,
  HZ_50 = 50,
  HZ_100 = 100,
}

export interface IStopwatchGarminStream extends IEntityModel {
  taskId: string
  projectId: string
  workspaceId: string
  task?: ITask
  project?: IProject
  workspace?: IWorkspace
  name: string
  description: string
  accEnable: boolean
  accHz: StopwatchGarminStreamAccSampleRate
  timeLimitSeconds: number
}
