import {v4 as uuid} from 'uuid'
import {selectTheme} from '../../store'
import {lighten} from '../../lib/color_util'
import {RadioGroup as ReactRadioGroup, Radio as ReactRadio} from 'react-radio-group'

/*
 * Use RadioGroup and RadioOption to create a radio Menu
 *
 * under the hood it is using react-radio-group. prop documentaion can be found here [react-radio-group docs](https://github.com/chenglou/react-radio-group)
 *
 */
export const RadioGroup = (props) => {
  const {fontWeight, pad} = selectTheme()
  const sanitizedProps = {...props}
  delete sanitizedProps.label
  sanitizedProps.name = sanitizedProps.name || uuid()
  return (
    <fieldset>
      <legend
        css={{
          display: 'block',
          fontWeight: fontWeight.bold,
          marginBottom: pad.small,
        }}
      >
        {props.label}
      </legend>
      <ReactRadioGroup {...sanitizedProps} />
    </fieldset>
  )
}

// RadioGroup.propTypes = {
//   /** visable label for the radio group */
//   label: PropTypes.string,
//   /** a unique name applied to all of the inputs in the radio group, defaults to a uuid*/
//   name: PropTypes.string,
//   /** select a an input */
//   selectedValue: PropTypes.string,
// }

/*
 * Use inside RadioGroup to create options
 */
export const RadioOption = (props) => {
  // TODO @slugbyte custom style like SlideCheckbox and https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_radio
  const sanitizedProps = {...props}
  delete sanitizedProps.children
  const id = props.id || uuid()
  const {pad, color} = selectTheme()
  return (
    <label
      css={{
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        // ':hover': {
        //   background: lighten(color.primary, 40),
        // },
        // ':active': {
        //   background: lighten(color.primary, 30),
        // },
      }}
      htmlFor={id}
    >
      <ReactRadio id={id} {...sanitizedProps} />
      <span css={{paddingLeft: pad.small}}>{props.label}</span>
    </label>
  )
}

// RadioOption.propTypes = {
//   /** content for the label associated with the radio option */
//   label: PropTypes.string.isRequired,
//   /** value for the input associated with the radio opion */
//   value: PropTypes.string.isRequired,
//   /** id will default to a uuid and probably doesn't need to be manualy set */
//   id: PropTypes.string,
// }
