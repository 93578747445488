import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doPARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST = 'REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST'
export const doREQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST = createDoRequestAction(REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST)

export const requestProjectGarminDirectDataDigestActionCreators = {
  doREQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST,
}

export function* requestProjectGarminDirectDataDigest({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectGarminDirectDataDigest')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        yymmIndexList: v.array().items(v.number()),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {projectId, yymmIndexList } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-garmin-direct-data-digest`,
    accessToken,
    data: {
      projectId,
      yymmIndexList,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-garmin-direct-data-digest',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  console.log('project garmin direct data digest', result.payload)

  yield put(
    doPARTICIPANT_GARMIN_DIRECT_DATA_DIGEST_SET({
      ...result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST,
      fromPayload: payload,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* projectGarminDirectDataDigestSaga() {
  yield takeEvery(REQUEST_PROJECT_GARMIN_DIRECT_DATA_DIGEST, requestProjectGarminDirectDataDigest)
}
