import {put, takeEvery, debug, select, createAction, delay, take} from '../../lib'
import {v4} from 'uuid'
import {AUTH_REFRESHING_FAILURE, AUTH_SET, REQUEST_ERROR} from '../state'
import {
  REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
  REQUEST_IDENTITY_AUTHENTICATE,
  PASSWORD_RESET_SUBMIT,
  EMAIL_UPDATE_SUBMIT,
  EMAIL_VERIFY_SUBMIT,
  doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT,
} from '..'

export const HANDLE_EXPIRED_TOKEN = 'HANDLE_EXPIRED_TOKEN'
export const doHANDLE_EXPIRED_TOKEN = createAction(HANDLE_EXPIRED_TOKEN)

export const handleExpiredTokenActionCreators = {
  doHANDLE_EXPIRED_TOKEN,
}

export function* handleExpiredToken({payload}: any) {
  debug('saga*handleExpiredToken')

  const {fromType, fromPayload, statusCode} = payload

  if (statusCode === 401) {
    if (
      fromType === REQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT ||
      fromType === PASSWORD_RESET_SUBMIT ||
      fromType === REQUEST_IDENTITY_AUTHENTICATE ||
      fromType === EMAIL_UPDATE_SUBMIT ||
      fromType === EMAIL_VERIFY_SUBMIT
    )
      return

    const requestId = v4()
    yield put(doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT({requestId}))

    yield take([AUTH_SET, AUTH_REFRESHING_FAILURE])
    yield put({
      type: fromType,
      payload: fromPayload,
    })
  }
}

export function* handleExpiredTokenSaga() {
  yield takeEvery(REQUEST_ERROR, handleExpiredToken)
}
