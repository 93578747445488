import {Line, LineConfig} from '@ant-design/charts'
import {Chart} from '@antv/g2/lib'
import {ChartDataProps, DailyChartDataWithType} from '../../../model/chart'
import {dailyGarminHeartRateTheme} from '../theme/daily_garmin_heart_rate_theme'
import {ChartCard} from '../template/chart_card'
import {HeartRateType} from '../../../lib/chart_data/model'
import {defaultDailyChartXAxisConfig} from '../../../lib/chart_data/util/formatter'
import {useEffect, useMemo, useState} from 'react'

export const GarminDailySummaryHeartRateChart = (props: ChartDataProps<DailyChartDataWithType<HeartRateType>>) => {
  const {height, width, data} = props
  const [chartInstance, setChartInstance] = useState<Chart | undefined>()
  const xField: keyof DailyChartDataWithType = 'yymmdd'
  const yField: keyof DailyChartDataWithType = 'value'
  const seriesField: keyof DailyChartDataWithType = 'type'
  const yAxisConfig = {
    title: {text: 'Heart Rate(bpm)'},
    min: 40,
  }
  useEffect(() => {
    if (chartInstance) {
      chartInstance.changeData(data)
    }
  }, [chartInstance, data])

  const config = useMemo<LineConfig>(() => {
    return {
      data: [],
      autoFit: true,
      padding: 'auto',
      xField,
      yField,
      xAxis: defaultDailyChartXAxisConfig,
      yAxis: yAxisConfig,
      seriesField,
      point: {},
      legend: {position: 'bottom-right'},
      theme: dailyGarminHeartRateTheme,
      // color: (d) => {
      //   return d.type === 'Max HR' ? '#0f759c' : d.type === 'Min HR' ? '#26a2cb' : d.type === 'Avg. HR' ? '#65d1fc' : '#2b6fd5'
      // },
      onReady: (chart) => {
        setChartInstance(chart.chart)
      },
    }
  }, [])

  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: 'Garmin Connect Heart Rate',
    hintDescription: '',
    chart: <Line {...config} />,
  }

  return <ChartCard {...chartCardProps} />
}
