import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doPROJECT_SET, doREQUEST_ERROR, doREQUEST_COMPLETE, doBATCH_DELETE} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_STATUS_UPDATE = 'REQUEST_PROJECT_STATUS_UPDATE'
export const doREQUEST_PROJECT_STATUS_UPDATE = createDoRequestAction(REQUEST_PROJECT_STATUS_UPDATE)

export const requestProjectStatusUpdateActionCreators = {
  doREQUEST_PROJECT_STATUS_UPDATE,
}

export function* requestProjectStatusUpdate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectStatusUpdate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        status: v.string().valid('draft', 'completed', 'live', 'deleted').exist(),
        batchId: v.string().uuid().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_STATUS_UPDATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {projectId, status, batchId} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-status-update`,
    accessToken,
    data: {
      projectId,
      status,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_STATUS_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/project-status-update',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(doPROJECT_SET(result.payload))

  if (status === 'deleted' && !!batchId) {
    yield put(doBATCH_DELETE({id: batchId}))
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_STATUS_UPDATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* projectStatusUpdateSaga() {
  yield takeEvery(REQUEST_PROJECT_STATUS_UPDATE, requestProjectStatusUpdate)
}
