import {
  assertPartialSchema,
  createAction,
  useSelector,
  v,
  _,
} from '../../../lib'

export enum FavoriteListActionType {
  FAVORITE_SET = 'FAVORITE_SET',
  FAVORITE_DELETE = 'FAVORITE_DELETE',
  FAVORITE_LIST_SET = 'FAVORITE_LIST_SET',
}

export const doFAVORITE_SET = createAction(FavoriteListActionType.FAVORITE_SET)
export const doFAVORITE_DELETE = createAction(FavoriteListActionType.FAVORITE_DELETE)
export const doFAVORITE_LIST_SET = createAction(FavoriteListActionType.FAVORITE_LIST_SET)

export interface FavoriteListItem {
  id: string
  updatedAt: string
  createdAt: string
  identityId: string
  projectId: string
}

interface RootState {
  favoriteList: FavoriteListItem[]
}

export const selectFavoriteList = () => {
  return useSelector((state: RootState) => state.favoriteList)
}

export const favoriteListActionCreators = {
  doFAVORITE_SET,
  doFAVORITE_DELETE,
  doFAVORITE_LIST_SET,
}

export const favoriteListDefaultState = []

type Action = 
  | {
      type: FavoriteListActionType.FAVORITE_SET
      payload: FavoriteListItem
    }
  | {
      type: FavoriteListActionType.FAVORITE_DELETE
      payload: {
        workspaceFavoriteId: string
      }
    }
  | {
      type: FavoriteListActionType.FAVORITE_LIST_SET
      payload: FavoriteListItem[]
    }

export const favoriteListReducer = (
  state = favoriteListDefaultState, 
  {type, payload}: Action
): FavoriteListItem[] => {
  switch (type) {
    case FavoriteListActionType.FAVORITE_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          createdAt: v.string().exist(),
          updatedAt: v.string().exist(),
          identityId: v.string().uuid().exist(),
          projectId: v.string().uuid().exist(),
        })
      })

      return _.unionBy([payload], [...state], 'projectId')

    case FavoriteListActionType.FAVORITE_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          workspaceFavoriteId: v.string().uuid().exist(),
        })
      })
      const newState = [...state]
      _.remove(
        newState,
        (e: FavoriteListItem) => e.id === payload.workspaceFavoriteId
      )
      return newState
    }

    case FavoriteListActionType.FAVORITE_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.array().items(v.object({
          id: v.string().uuid().exist(),
          createdAt: v.string().exist(),
          updatedAt: v.string().exist(),
          identityId: v.string().uuid().exist(),
          projectId: v.string().uuid().exist(),
        }))
      })
      return _.unionBy(payload, [...state], 'projectId')
      
    default:
      return [...state]
  }
}