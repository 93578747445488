import {useState, useEffect} from 'react'
import {selectTheme} from '../../store'

import ResearcherYehAvatar from '../../asset/image/Researcher_Yeh.png'
import ResearcherBryanAvatar from '../../asset/image/Researcher_Bryan.png'
import ResearcherKristinAvatar from '../../asset/image/Researcher_Kristin.png'

const researchersArray = [
  {
    name: 'Dr. Gloria Yeh, MD',
    title: 'Associate Professor of Medicine, Harvard Medical School',
    avatar: ResearcherYehAvatar,
    quote: `“Now, more than ever, we need tools like Labfront that can help researchers take their research and data collection virtual.”`,
  },
  {
    name: 'Bryan Edwards, PhD',
    title: 'Professor,  Management Department, Oklahoma State University',
    avatar: ResearcherBryanAvatar,
    quote: `"Labfront's analysts understood our needs as researchers and saved us weeks of work when they prepared our datasets for analysis."`,
  },
  {
    name: 'Dr. Kristin Scott, PhD',
    title: 'Professor of Business, Clemson University',
    avatar: ResearcherKristinAvatar,
    quote: `“The physiological data really helped provide deeper insights. We recommend Labfront and will definitely be using it again!”`,
  },
]

export const SignupPageTestimonial = () => {
  const {color, fontWeight, fontSize} = selectTheme()

  const [researcherIndex, setResearcherIndex] = useState(0)

  // // auto slide
  // useEffect(() => {
  //   const carousel = setInterval(() => {
  //     setResearcherIndex((prev) => (prev + 1) % 3)
  //   }, 5000)
  //   return () => clearInterval(carousel)
  // }, [])

  return (
    <div
      css={{
        zIndex: 2,
        width: '328px',
        maxWidth: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          borderRadius: '8px',
          background: color.white,
          width: '100%',
          minHeight: '212px',
          padding: '24px',
        }}
      >
        <div css={{fontSize: '15px', fontStyle: 'italic', fontWeight: fontWeight.bold}}>
          {researchersArray[researcherIndex].quote}
        </div>
        <div css={{marginTop: '24px', display: 'flex'}}>
          <img src={researchersArray[researcherIndex].avatar} width='36' height='36' />
          <div css={{marginLeft: '16px'}}>
            <p css={{marginBottom: '4px', fontWeight: fontWeight.bold, fontSize: fontSize.h7}}>
              {researchersArray[researcherIndex].name}
            </p>
            <p css={{fontWeight: fontWeight.regular, fontSize: fontSize.h7}}>
              {researchersArray[researcherIndex].title}
            </p>
          </div>
        </div>
      </div>
      <div css={{display: 'flex', marginTop: '16px', alignItems: 'center'}}>
        <div
          onClick={() => {
            if (researcherIndex === 0) return
            setResearcherIndex((prev) => prev - 1)
          }}
          css={{
            width: '8px',
            height: '8px',
            borderLeft: `2px solid`,
            borderBottom: `2px solid`,
            borderColor: researcherIndex === 0 ? color.grey_160 : color.grey_600,
            transform: 'rotate(45deg)',
            marginRight: '10px',
            cursor: researcherIndex === 0 ? 'auto' : 'pointer',
          }}
        />
        <div
          onClick={() => setResearcherIndex(0)}
          css={{
            borderRadius: '50%',
            width: '6px',
            height: '6px',
            background: researcherIndex === 0 ? color.black : color.grey_400,
            marginRight: '6px',
            cursor: 'pointer',
          }}
        />
        <div
          onClick={() => setResearcherIndex(1)}
          css={{
            borderRadius: '50%',
            width: '6px',
            height: '6px',
            background: researcherIndex === 1 ? color.black : color.grey_400,
            marginRight: '6px',
            cursor: 'pointer',
          }}
        />
        <div
          onClick={() => setResearcherIndex(2)}
          css={{
            borderRadius: '50%',
            width: '6px',
            height: '6px',
            background: researcherIndex === 2 ? color.black : color.grey_400,
            marginRight: '6px',
            cursor: 'pointer',
          }}
        />
        <div
          onClick={() => {
            if (researcherIndex === 2) return
            setResearcherIndex((prev) => prev + 1)
          }}
          css={{
            width: '8px',
            height: '8px',
            marginLeft: '4px',
            borderTop: `2px solid`,
            borderRight: `2px solid`,
            borderColor: researcherIndex === 2 ? color.grey_160 : color.grey_600,
            transform: 'rotate(45deg)',
            cursor: researcherIndex === 2 ? 'auto' : 'pointer',
          }}
        />
      </div>
    </div>
  )
}
