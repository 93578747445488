import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import {selectProfile, selectAuth, selectTheme} from '../../store'

import {LoginForm} from '..'

import LabfrontLogo from '../../asset/image/labfront_logo.png'

export const LoginPage = () => {
  const {color} = selectTheme()
  const navigate = useNavigate()
  const accessToken = selectAuth()?.accessToken
  const profile = selectProfile()
  useEffect(() => {
    if (accessToken && profile?.id) navigate('/project')
  }, [])

  console.log(process.env.NODE_ENV)
  console.log(process.env.DEPLOY_STAGE)
  console.log(process.env.API_URL)
  console.log(process.env.VERSION)

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
        width: '100vw',
        height: '100vh',
        background: color.background,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '100px',
      }}
    >
      <img src={LabfrontLogo} width='110' height='30' />
      <LoginForm />
    </div>
  )
}
