export enum VisualizerGraphDataType {
  DexcomBloodGlucose = 'DexcomBloodGlucose',
  GarminDirectAccelerometerRaw = 'GarminDirectAccelerometerRaw',
  GarminDirectBBI = 'GarminDirectBBI',
  GarminDirectActigraphyRaw = 'GarminDirectActigraphyRaw',
  GarminDirectHeartRate = 'GarminDirectHeartRate',
  GarminDirectSpO2 = 'GarminDirectSpO2',
  GarminDirectStress = 'GarminDirectStress',
  GarminDirectSteps = 'GarminDirectSteps',
  GarminConnectStress = 'GarminConnectStress',
  GarminConnectHeartRate = 'GarminConnectHeartRate',
  GarminConnectRestingHR = 'GarminConnectRestingHR',
  GarminConnectBodyBattery = 'GarminConnectBodyBattery',
  GarminConnectSleepStaging = 'GarminConnectSleepStaging',
  GarminConnectActivityEpochs = 'GarminConnectActivityEpochs',
  GarminConnectSteps = 'GarminConnectSteps',
  GarminConnectCalories = 'GarminConnectCalories',
  GarminDeviceWearingTime = 'GarminDeviceWearingTime',
  MovesenseIMU = 'MovesenseIMU',
  MovesenseECGWaveform = 'MovesenseECGWaveform',
  MovesenseECGRR = 'MovesenseECGRR',
  AnalysisSleepScore = 'AnalysisSleepScore',
}

export enum VisualizerGraphResolution {
  TimeSeries = 'TimeSeries',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}

export const availableGraphResolutions = new Set(
  Object.keys(VisualizerGraphResolution),
)

export const availableGraphDataTypes = new Set(
  Object.keys(VisualizerGraphDataType),
)

export const VisualizerResolutionSupportTypeMap: Record<
  VisualizerGraphResolution,
  Set<VisualizerGraphDataType>
> = {
  TimeSeries: new Set([
    VisualizerGraphDataType.DexcomBloodGlucose,
    VisualizerGraphDataType.GarminDirectAccelerometerRaw,
    VisualizerGraphDataType.GarminDirectBBI,
    VisualizerGraphDataType.GarminDirectActigraphyRaw,
    VisualizerGraphDataType.GarminDirectHeartRate,
    VisualizerGraphDataType.GarminDirectSpO2,
    VisualizerGraphDataType.GarminDirectStress,
    VisualizerGraphDataType.GarminDirectSteps,
    VisualizerGraphDataType.GarminConnectStress,
    VisualizerGraphDataType.GarminConnectHeartRate,
    VisualizerGraphDataType.GarminConnectBodyBattery,
    VisualizerGraphDataType.GarminConnectSleepStaging,
    VisualizerGraphDataType.GarminConnectActivityEpochs,
    VisualizerGraphDataType.GarminConnectSteps,
    VisualizerGraphDataType.MovesenseIMU,
    VisualizerGraphDataType.MovesenseECGWaveform,
    VisualizerGraphDataType.MovesenseECGRR,
    VisualizerGraphDataType.AnalysisSleepScore,
  ]),
  Daily: new Set([
    VisualizerGraphDataType.DexcomBloodGlucose,
    VisualizerGraphDataType.GarminDirectBBI,
    VisualizerGraphDataType.GarminDirectHeartRate,
    VisualizerGraphDataType.GarminDirectSpO2,
    VisualizerGraphDataType.GarminDirectStress,
    VisualizerGraphDataType.GarminDirectSteps,
    VisualizerGraphDataType.GarminConnectStress,
    VisualizerGraphDataType.GarminConnectHeartRate,
    VisualizerGraphDataType.GarminConnectRestingHR,
    VisualizerGraphDataType.GarminConnectBodyBattery,
    VisualizerGraphDataType.GarminConnectSleepStaging,
    VisualizerGraphDataType.GarminConnectActivityEpochs,
    VisualizerGraphDataType.GarminConnectSteps,
    VisualizerGraphDataType.GarminConnectCalories,
    VisualizerGraphDataType.GarminDeviceWearingTime,
  ]),
  Weekly: new Set([
    VisualizerGraphDataType.DexcomBloodGlucose,
    VisualizerGraphDataType.GarminDirectBBI,
    VisualizerGraphDataType.GarminDirectHeartRate,
    VisualizerGraphDataType.GarminDirectSpO2,
    VisualizerGraphDataType.GarminDirectStress,
    VisualizerGraphDataType.GarminDirectSteps,
    VisualizerGraphDataType.GarminConnectStress,
    VisualizerGraphDataType.GarminConnectHeartRate,
    VisualizerGraphDataType.GarminConnectRestingHR,
    VisualizerGraphDataType.GarminConnectSleepStaging,
    VisualizerGraphDataType.GarminConnectActivityEpochs,
    VisualizerGraphDataType.GarminConnectSteps,
  ]),
  Monthly: new Set([
    VisualizerGraphDataType.DexcomBloodGlucose,
    VisualizerGraphDataType.GarminDirectBBI,
    VisualizerGraphDataType.GarminDirectHeartRate,
    VisualizerGraphDataType.GarminDirectSpO2,
    VisualizerGraphDataType.GarminDirectStress,
    VisualizerGraphDataType.GarminDirectSteps,
    VisualizerGraphDataType.GarminConnectStress,
    VisualizerGraphDataType.GarminConnectHeartRate,
    VisualizerGraphDataType.GarminConnectRestingHR,
    VisualizerGraphDataType.GarminConnectSleepStaging,
    VisualizerGraphDataType.GarminConnectActivityEpochs,
    VisualizerGraphDataType.GarminConnectSteps,
  ]),
}

export interface VisualizerGraphSetting {
  type: VisualizerGraphDataType
  index: number
  option?: unknown
}

export type VisualizerSidebarSetting = {
  [key in VisualizerGraphResolution]: VisualizerGraphSetting[] | null
}

export function createEmptyVisualizerSidebarSetting(): VisualizerSidebarSetting {
  return {
    [VisualizerGraphResolution.Daily]: null,
    [VisualizerGraphResolution.Monthly]: null,
    [VisualizerGraphResolution.TimeSeries]: null,
    [VisualizerGraphResolution.Weekly]: null,
  }
}
