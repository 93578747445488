import {useState, useEffect, useRef} from 'react'

import {selectTheme} from '../../store'

import {ChevronDownIcon, ChevronUpBlueIcon, TickBlackIcon, ChevronDownBlackIcon} from '../../asset/image'

import {RIF} from '../../lib'

export const PermissionSelector = (props) => {
  const {permission, changePermission, userPermission} = props
  const {color, pad, fontWeight, fontSize} = selectTheme()
  const ref = useRef()
  const btnRef = useRef()
  const [isShowingOptions, setIsShowingOptions] = useState(false)
  const [hoverSelector, setHoverSelector] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isShowingOptions && ref.current && !ref.current.contains(e.target)) {
        if (btnRef.current.contains(e.target)) return
        setIsShowingOptions(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isShowingOptions])

  const handleChangePermission = (permission) => {
    if (changePermission) changePermission(permission)
    setIsShowingOptions(false)
  }

  return (
    <div
      css={{
        width: '94px',
        height: '43px',
      }}
    >
      <button
        type='button'
        ref={btnRef}
        onClick={() => setIsShowingOptions(!isShowingOptions)}
        onMouseOver={() => setHoverSelector(true)}
        onMouseOut={() => setHoverSelector(false)}
        css={{
          width: '100%',
          height: '100%',
          paddingLeft: pad.medium,
          paddingRight: pad.mediumSmall,
          border: `1px solid ${isShowingOptions ? color.primary : hoverSelector ? color.black : color.grey_160}`,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          backgroundColor: color.white,
          borderRadius: '5px',
        }}
      >
        <p
          css={{
            fontWeight: fontWeight.medium,
          }}
        >
          {permission ? permission.replace(/^\w/, (c) => c.toUpperCase()) : 'Editor'}
        </p>
        <img
          width='14'
          src={isShowingOptions ? ChevronUpBlueIcon : hoverSelector ? ChevronDownBlackIcon : ChevronDownIcon}
        />
      </button>
      {RIF(
        isShowingOptions,
        <div
          ref={ref}
          css={{
            width: '320px',
            borderRadius: '5px',
            boxShadow: '0px 4px 10px 1px rgba(104, 104, 104, 0.25)',
            border: `1px solid ${color.disabled}`,
            backgroundColor: color.white,
            overflow: 'hidden',
            position: 'absolute',
            marginTop: '1px',
            zIndex: 999,
          }}
        >
          {RIF(
            ['owner', 'admin'].includes(userPermission),
            <button
              type='button'
              onClick={() => handleChangePermission('admin')}
              css={{
                width: '100%',
                padding: '16px 40px',
                position: 'relative',
                cursor: 'pointer',
                backgroundColor: color.white,
                border: 'none',
                borderBottom: `1px solid ${color.disabled}`,
                textAlign: 'start',
                ':hover': {
                  backgroundColor: color.background,
                },
              }}
            >
              {RIF(
                permission === 'admin',
                <img
                  css={{
                    position: 'absolute',
                    top: '16px',
                    left: '16px',
                  }}
                  width='16'
                  src={TickBlackIcon}
                />,
              )}
              <p
                css={{
                  fontWeight: fontWeight.thick,
                  marginBottom: pad.small,
                }}
              >
                Admin
              </p>
              <p
                css={{
                  fontWeight: fontWeight.medium,
                  fontsize: fontSize.h7,
                  color: color.grey_400,
                }}
              >
                Admins can manage billing and workspace upgrades.
              </p>
            </button>,
          )}
          {RIF(
            ['owner', 'admin', 'editor'].includes(userPermission),
            <button
              type='button'
              onClick={() => handleChangePermission('editor')}
              css={{
                width: '100%',
                padding: '16px 40px',
                position: 'relative',
                border: 'none',
                borderBottom: `1px solid ${color.disabled}`,
                backgroundColor: color.white,
                cursor: 'pointer',
                textAlign: 'start',
                ':hover': {
                  backgroundColor: color.background,
                },
              }}
            >
              {RIF(
                !permission || permission === 'editor',
                <img
                  css={{
                    position: 'absolute',
                    top: '16px',
                    left: '16px',
                  }}
                  width='16'
                  src={TickBlackIcon}
                />,
              )}
              <p
                css={{
                  fontWeight: fontWeight.thick,
                  marginBottom: pad.small,
                }}
              >
                Editor
              </p>
              <p
                css={{
                  fontWeight: fontWeight.medium,
                  fontsize: fontSize.h7,
                  color: color.grey_400,
                  // }}>Editors can invite collaborators, create, duplicate and edit projects.</p>
                }}
              >
                Editors can create, duplicate and edit projects.
              </p>
            </button>,
          )}
          <button
            type='button'
            onClick={() => handleChangePermission('viewer')}
            css={{
              width: '100%',
              padding: '16px 40px',
              position: 'relative',
              cursor: 'pointer',
              border: 'none',
              backgroundColor: color.white,
              textAlign: 'start',
              ':hover': {
                backgroundColor: color.background,
              },
            }}
          >
            {RIF(
              permission === 'viewer',
              <img
                css={{
                  position: 'absolute',
                  top: '16px',
                  left: '16px',
                }}
                width='16'
                src={TickBlackIcon}
              />,
            )}
            <p
              css={{
                fontWeight: fontWeight.thick,
                marginBottom: pad.small,
              }}
            >
              Viewer
            </p>
            <p
              css={{
                fontWeight: fontWeight.medium,
                fontsize: fontSize.h7,
                color: color.grey_400,
              }}
            >
              Viewers can only view the projects.
            </p>
          </button>
        </div>,
      )}
    </div>
  )
}
