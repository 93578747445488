import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {Controller} from 'react-hook-form'
import {useNavigate, Link} from 'react-router-dom'
import {v, debug, useForm, RequestResult} from '../../lib'
import {selectTheme, createDispatchActions} from '../../store'
import {LabelBox, Button, Legend, Input, AccountSetupProcessString} from '..'

const schema = v.object({
  email: v.string().optional(),
})

export interface ResetPasswordPagePropsT {
  forgetPWDAutoFillEmail?: string | undefined
  setAccountSetupProcess: Dispatch<SetStateAction<AccountSetupProcessString>>
  setEmailOfForgetPWD: Dispatch<SetStateAction<string>>
}

export const ResetPasswordPage = (props: ResetPasswordPagePropsT) => {
  const {color, pad} = selectTheme()

  const {setAccountSetupProcess, setEmailOfForgetPWD, forgetPWDAutoFillEmail} = props
  const {doPASSWORD_RESET_REQUEST}: any = createDispatchActions()
  const [requestResult, setRequestResult] = useState<RequestResult | null>(null)

  const [hasEmailRequiredError, setEmailRequiredError] = useState(false)
  const [hasEmailValidError, setEmailValidError] = useState(false)
  const [hasEmailNotFoundError, setEmailNotFoundError] = useState(false)

  const [emailValue, setEmailValue] = useState('') // order to auto fill up the email to prev page
  const {handleSubmit, watch, control} = useForm({schema})

  useEffect(() => {
    if (forgetPWDAutoFillEmail) setEmailValue(forgetPWDAutoFillEmail)
    if (watch('email')) setEmailValue(watch('email'))
  }, [forgetPWDAutoFillEmail, watch('email')])

  const onSubmit = (data: any) => {
    debug('confirmPassword.onSubmit', data)
    setEmailOfForgetPWD(emailValue)
    return doPASSWORD_RESET_REQUEST({
      setRequestResult,
      payload: {
        email: emailValue,
      },
    })
  }

  const onError = (error: any) => {
    setEmailRequiredError(error?.email?.type == 'string.empty' ? true : false)
    setEmailValidError(error?.email?.type == 'string.email' ? true : false)
  }

  useEffect(() => {
    if (!requestResult) return
    if (!requestResult?.success && requestResult?.result.statusCode === 400) return setEmailNotFoundError(true)
    setAccountSetupProcess('verify_code_for_forget_PWD')
  }, [requestResult])

  useEffect(() => {
    setEmailRequiredError(false)
    setEmailValidError(false)
    setEmailNotFoundError(false)
  }, [emailValue])

  return (
    <div
      data-testid='reset_password_page'
      css={{
        width: 'fit-content',
        margin: '20vh auto',
        padding: '50px',
        position: 'relative',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit, onError)} css={{width: '480px'}}>
        <div>
          <Legend>Reset Password</Legend>
          <div css={{marginTop: '20px'}}>Enter the email you use for your Labfront account.</div>
          <div css={{marginTop: '20px'}}>
            <LabelBox
              {...{
                label: 'Email',
                warningMsg: hasEmailRequiredError
                  ? 'Email Required'
                  : hasEmailValidError
                  ? 'Must be a valid email'
                  : hasEmailNotFoundError
                  ? 'An account with this email not found'
                  : '',
              }}
            >
              <Controller
                control={control}
                name='email'
                render={({field: {name, value, onChange}}) => (
                  <Input
                    {...{
                      name: name,
                      value: emailValue,
                      onChange: onChange,
                    }}
                    type='email'
                    placeholder='name@email.com'
                    css={{marginBottom: pad.large}}
                  />
                )}
              />
            </LabelBox>
          </div>
        </div>

        <div css={{display: 'flex', alignItems: 'center'}}>
          <Button
            type='submit'
            css={{
              marginRight: pad.medium,
              background: emailValue ? color.primary : color.grey_160,
            }}
          >
            Continue
          </Button>
          <Link to='/' state={{email: emailValue}} css={{color: color.primary, cursor: 'pointer'}}>
            Back to sign in
          </Link>
        </div>
      </form>
    </div>
  )
}
