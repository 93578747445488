import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  SagaIterator,
  assertPartialSchema,
  call,
  select,
} from '../../lib'

import {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doSTREAM_DATA_METADATA_SET,
  doSTREAM_DATA_METADATA_UPDATE,
  TypedStreamDataMetadataRecord,
} from '../state'
import {insertStreamDataToDb} from '../db/db_data_setter'
import {StreamDataTypeToGraphDataTypeMap} from '../../components/charts/utils/utils'
import {GarminDeviceStreamDataType, MovesenseDeviceStreamDataType} from '../../shared/mongo'
import { getToken } from './token_fetcher'

export const REQUEST_STREAM_DATA = 'REQUEST_STREAM_DATA'
export const doREQUEST_STREAM_DATA = createDoRequestAction(REQUEST_STREAM_DATA)

export const requestStreamDataActionCreators = {
  doREQUEST_STREAM_DATA,
}

export function* requestStreamData({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestStreamData')

  assertPartialSchema({
    payload,
    schema: v.object({
      dataTypeList: v.array().items(v.string().required()),
      timeRange: v.array().items(v.number().required()),
      streamTaskEvent: v.object({
        completionId: v.string().required(),
        startTime: v.number().required(),
        endTime: v.number().required(),
        timeOffset: v.number().required(),
      }),
    }),
  })

  const {dataTypeList, timeRange, streamTaskEvent} = payload

  const accessToken = yield call(getToken)

  let completionStreamDataMetadataMap = yield select((state) => state.completionStreamDataMetadataMap)
  let fetchData = true
  if (completionStreamDataMetadataMap) {
    const selectedTypedStreamDataMetadata = completionStreamDataMetadataMap[streamTaskEvent.completionId]
    if (selectedTypedStreamDataMetadata) {
      fetchData = false
    }
  }

  if (fetchData) {
    const result = yield request({
      method: 'post',
      url: `${API_URL}/v1/web/stream-data-metadata-fetch`,
      accessToken,
      data: {
        completionId: streamTaskEvent.completionId,
      },
    })

    if (!result.success || result.error) {
      const error = result.error || new Error('request did not succeed')
      debug(error)
      return yield put(
        doREQUEST_ERROR({
          fromType: REQUEST_STREAM_DATA,
          fromPayload: payload,
          requestId: payload.requestId,
          url: '/v1/web/stream-data-metadata-fetch',
          ...result,
          error,
        }, setRequestResult),
      )
    }

    yield put(
      doSTREAM_DATA_METADATA_SET({
        completionId: streamTaskEvent.completionId,
        timeOffset: streamTaskEvent.timeOffset,
        dataIndexList: result.payload,
      }),
    )
  }

  completionStreamDataMetadataMap = yield select((state) => state.completionStreamDataMetadataMap)
  if (completionStreamDataMetadataMap) {
    //dataType selected
    const selectedCompletionMetadata = completionStreamDataMetadataMap[streamTaskEvent.completionId]
    if (selectedCompletionMetadata) {
      const requestObjectIdList: string[] = []

      //check visualizerSidebarSetting

      dataTypeList
        .map(
          (dataType: GarminDeviceStreamDataType | MovesenseDeviceStreamDataType) =>
            StreamDataTypeToGraphDataTypeMap[dataType],
        )
        .forEach((graphDataType: string) => {
          const streamDataMetadata: Record<string, TypedStreamDataMetadataRecord> =
            selectedCompletionMetadata[graphDataType]
          if (streamDataMetadata) {
            for (const [objectId, metadata] of Object.entries(streamDataMetadata)) {
              if (!metadata.dbInserted) {
                const chunkStartLocalTime = metadata.dataStartTimeStamp
                const chunkEndLocalTime = metadata.dataEndTimeStamp
                if (
                  (chunkStartLocalTime >= timeRange[0] && chunkStartLocalTime <= timeRange[1]) ||
                  (chunkEndLocalTime >= timeRange[0] && chunkEndLocalTime <= timeRange[1])
                ) {
                  requestObjectIdList.push(objectId)
                }
              }
            }
          }
        })

      if (requestObjectIdList.length > 0) {
        const result = yield request({
          method: 'post',
          url: `${API_URL}/v1/web/stream-data-fetch`,
          accessToken,
          data: {
            objectIdList: requestObjectIdList,
          },
        })

        if (!result.success || result.error) {
          const error = result.error || new Error('request did not succeed')
          debug(error)
          return yield put(
            doREQUEST_ERROR({
              fromType: REQUEST_STREAM_DATA,
              fromPayload: payload,
              requestId: payload.requestId,
              url: '/v1/web/stream-data-fetch',
              ...result,
              error,
            }, setRequestResult),
          )
        }

        const insertedResultList = yield call(async () => {
          return await insertStreamDataToDb(result.payload)
        })

        yield put(
          doSTREAM_DATA_METADATA_UPDATE({
            insertedResultList,
          }),
        )
      }
    }
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_STREAM_DATA,
      requestId: payload.requestId,
    }, setRequestResult),
  )
}

export function* StreamDataFetchSaga() {
  yield takeEvery(REQUEST_STREAM_DATA, requestStreamData)
}
