import {GarminConnectRecordDataDaily} from '../../../shared/mongo'
import {LocalDateGarminConnectTransformer} from './base'
import {DailyChartData} from '../../../model/chart'

export class DailySummaryStepsTransformer extends LocalDateGarminConnectTransformer<
  GarminConnectRecordDataDaily,
  DailyChartData
> {
  transform() {
    return this.toDataFrame(({steps}) => {
      return {value: steps}
    })
  }
}
