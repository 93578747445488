import {v4} from 'uuid'
import {call, take, select, put} from '../../lib'
import {doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT} from './identity_refresh_token_submit'
import {AUTH_REFRESHING, AUTH_REFRESHING_FAILURE, AUTH_SET} from '../state/auth'

function* waitForRefreshToComplete() {
  let isRefreshing: boolean = yield select((state) => state?.auth?.isRefreshing)
  const waited = isRefreshing ? true : false
  while (isRefreshing) {
    // console.log('waiting refresh token')
    yield take([AUTH_SET, AUTH_REFRESHING_FAILURE])
    isRefreshing = yield select((state) => state?.auth?.isRefreshing)
  }
  return waited
}

function* waitForTokenRefresh() {
  const waited = yield* waitForRefreshToComplete()
  if (!waited) {
    const accessTokenExpireTimestamp: number = (yield select((state) => state?.auth?.accessTokenExpireTimestamp)) ?? 0
    const now = new Date().getTime()
    const expireTime = accessTokenExpireTimestamp - now
    // console.log(`expireTime: ${expireTime}`)

    // if token expire in 15 seconds, refresh token
    if (expireTime < 15000) {
      const requestId = v4()
      yield put(doREQUEST_IDENTITY_REFRESH_TOKEN_SUBMIT({requestId}))
      yield take(AUTH_REFRESHING)
      yield call(waitForRefreshToComplete)
    }
  }
}

// Saga to get token, waits refreshing finished
export function* getToken() {
  yield call(waitForTokenRefresh)
  const token: string | null = yield select((state) => state?.auth?.accessToken)
  return token
}
