import React from 'react'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import { ProfileImageDefault } from '../../asset/image'

export const Avatars = (props) => {
  const {color} = selectTheme()

  // hard code now
  const overflowLimit = 3
  const {users} = props
  const displayUsers = users?.slice(0, overflowLimit)
  const overflowNumber = users?.length - 3

  return (
    <div
      {...props}
      css={{
        height: '30px',
        position: 'relative',
        marginLeft: '10px',
        display: 'flex',
        paddingLeft: '8px',
        width: 'auto',
      }}
    >
      {displayUsers.map((user, index) => {
        if (user.profile) return (
          <img
            src={user.profile?.imageUrl || ProfileImageDefault}
            width='28'
            height='28'
            key={index}
            css={{
              borderRadius: '50px',
              border: '1px solid white',
              marginLeft: '-8px',
            }}
          />
        )
      })}

      {RIF(
        !!overflowNumber && overflowNumber > 0,
        <div
          css={{
            height: '28px',
            width: '28px',
            borderRadius: '50px',
            background: color.white,
            border: `1px solid ${color.grey_160}`,
            color: color.grey_160,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: '600',
            marginLeft: '-8px',
          }}
        >
          +{overflowNumber}
        </div>,
      )}
    </div>
  )
}
