import { CloseIcon } from '../../asset/image'
import { RIF, t } from '../../lib'
import { ITag } from '../../shared/db'
import {selectTheme} from '../../store'

type TagInput = Pick<ITag, 'color' | 'value'> | ITag
export type TagClickHandler<T extends TagInput> = (tag: T) => void

export interface TagProps<T extends TagInput> {
  tag: T
  showTrailingRemoveIcon?: boolean
  onClick?: TagClickHandler<T>
}

export const Tag = <T extends TagInput = TagInput>(props: TagProps<T>) => {
  const {fontSize, tagBackgroundColorOpacity} = selectTheme()
  const { tag, showTrailingRemoveIcon, onClick } = props
  const { value } = tag
  const color = t.addHashIfNeeded(tag.color)
  const background = t.applyOpacity(color, tagBackgroundColorOpacity)

  return (
    <div
      onClick={() => onClick?.(tag)}
      style={{
        display: 'inline-flex',
      }}
    >
      <div
        style={{
          padding: '2px 6px',
          background,
          borderRadius: 2,
          gap: 6,
          justifyContent: 'flex-start',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <span
          style={{
            color,
            fontSize: fontSize.h8,
            wordBreak: 'break-word',
          }}
        >
          {value}
        </span>
        {RIF(showTrailingRemoveIcon, <img src={CloseIcon} width={8}/>)}
      </div>
    </div>
  )
}
