import React from 'react'
import {selectTheme} from '../../store'
import {handleEsc, RIF} from '../../lib'

import {BtnColor, Legend, Button, ButtonReverse, ButtonCancel} from '..'

export interface PopupSimplePropsT {
  method?: string
  name?: string
  description?: string
  subDescription?: any
  buttonColor?: BtnColor
  buttonText?: string
  cancelText?: string
  cancelTextColor?: string
  closeAction?: (arg: boolean) => void
  exitAction?: (arg: boolean) => void // only available if cancel text and X btn are diff action
  doAction?: any
  btnDisableAction?: boolean
  buttonType?: string
  html?: JSX.Element
  children?: any
  css?: any
  type?: 'button' | 'submit' | 'reset'
}

export const PopupSimple = (props: PopupSimplePropsT) => {
  const {color, fontWeight} = selectTheme()

  const {
    method,
    name,
    description,
    buttonColor,
    buttonText,
    cancelText,
    cancelTextColor,
    closeAction,
    exitAction,
    doAction,
    btnDisableAction,
    buttonType,
    html,
    type,
    subDescription,
    ...rest
  } = props

  const handleXBtnAction = () => {
    if (exitAction) return exitAction(false)
    closeAction?.(false)
  }

  handleEsc(closeAction)

  return (
    <div
      {...rest}
      css={{
        top: 'calc(50vh - 200px)',
        left: 'calc(50vw - 300px)',
        position: 'fixed',
        width: '600px',
        borderRadius: '5px',
        padding: '30px',
        background: color.white,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          minHeight: '240px',
        }}
      >
        <div>
          <ButtonCancel onClick={handleXBtnAction} />
          <div css={{marginTop: '30px'}}>
            <Legend>
              {method} {name}
            </Legend>
            <div css={{paddingTop: '16px', paddingBottom: '40px'}}>
              <div css={{whiteSpace: 'pre-line'}}>{description}</div>
              {RIF(subDescription, <br/>)}
              <div css={{fontWeight: fontWeight.bold}}>{subDescription}</div>
            </div>
            {html}
          </div>
        </div>
        <div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <a
              onClick={() => closeAction?.(false)}
              css={{
                color: cancelTextColor ? cancelTextColor : color.primary,
                fontWeight: fontWeight.bold,
                cursor: 'pointer',
              }}
            >
              {cancelText ? cancelText : 'Cancel'}
            </a>

            {RIF(
              btnDisableAction,
              <>
                {RIF(!buttonType, <Button disabled>{buttonText}</Button>)}
                {RIF(buttonType === 'reverse', <ButtonReverse disabled>{buttonText}</ButtonReverse>)}
              </>,
            )}

            {RIF(
              !btnDisableAction,
              <>
                {RIF(
                  !buttonType,
                  <Button type={type || 'submit'} onClick={doAction} btnColor={buttonColor} btnPadding='large'>
                    {props.buttonText}
                  </Button>,
                )}
                {RIF(
                  buttonType === 'reverse',
                  <ButtonReverse type='submit' onClick={doAction} btnPadding='large' btnColor={buttonColor}>
                    {props.buttonText}
                  </ButtonReverse>,
                )}
              </>,
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
