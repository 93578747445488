import {useState, useEffect, forwardRef, Ref, ChangeEvent} from 'react'
import {calculateInputCSS} from '../../lib/calculate_input_css'

export const Textarea = forwardRef((props: any, ref: Ref<HTMLInputElement>) => {
  const [value, setValue] = useState(props.value || '')
  const sanitizedProps = {...props}
  delete sanitizedProps.hasError

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (props.onChange) props.onChange(e, e.target.value)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      props.onFinish?.(value)
      if (props.clearOnFinish) {
        setValue('')
      }
    }
  }

  useEffect(() => {
    if (props.value != value) setValue(props.value)
  }, [props.value])

  return (
    <textarea
      {...sanitizedProps}
      ref={ref}
      css={{...calculateInputCSS(props), ...sanitizedProps.style}}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
})
