import {Dispatch, SetStateAction} from 'react'
import {handleEsc} from '../../lib'
import {selectTheme} from '../../store'
import {Legend, ButtonCancel, TextLink, Button} from '..'

interface Props {
  closeAction: Dispatch<SetStateAction<boolean>>
  showAction: () => void
}

export const PopupConfirmShowTask = (props: Props) => {
  const {color, fontWeight} = selectTheme()
  const {closeAction, showAction} = props
  const handleCloseAction = () => {
    if (closeAction) closeAction(false)
  }

  const handleShowAction = () => {
    if (showAction) showAction()
  }

  handleEsc(handleCloseAction)

  return (
    <div
      {...props}
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        zIndex: 99999,
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 'calc(50vh - 145px)',
          left: 'calc(50vw - 300px)',
          width: '600px',
          borderRadius: '5px',
          padding: '30px',
          background: color.white,
        }}
      >
        <ButtonCancel onClick={handleCloseAction}/>
        <Legend css={{
          margin: '36px 0 16px',
        }}>
          Are you sure you want to add this task back?
        </Legend>
        <p css={{
          fontWeight: fontWeight.bold,
          marginBottom: '80px',
        }}>
          This task is currently hidden. Adding this task back into the app will mean participants will see this task in
          their app.
        </p>
        <div css={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <TextLink children='Cancel' onClick={handleCloseAction} />
          <Button
            onClick={handleShowAction}
            btnPadding='large'
            btnColor='warning'
            children={'Add task back into the app'}
          />
        </div>
      </div>
    </div>
  )
}
