import {useState} from 'react'
import {selectTheme} from '../../store'
import {Input, Button, TextLink, ButtonCancel} from '..'

export const PopupEditWorkspaceName = (props) => {
  const {color, fontWeight, fontSize} = selectTheme()
  const [newWorkspaceName, setNewWorkspaceName] = useState(props.workspaceName)

  const handleClose = () => {
    if (props.closeAction) props.closeAction()
  }

  const handleChange = (e, value) => {
    setNewWorkspaceName(value)
  }

  const handleSave = () => {
    if (props.saveAction) props.saveAction(newWorkspaceName)
  }

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, .4)',
        zIndex: 9999,
      }}
    >
      <div
        css={{
          width: '560px',
          padding: '30px',
          borderRadius: '5px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: color.white,
        }}
      >
        <ButtonCancel onClick={handleClose}/>
        <p
          css={{
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h3,
            margin: '36px 0 39px',
          }}
        >
          Edit Workspace Name
        </p>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '7px',
          }}
        >
          <p
            css={{
              fontWeight: fontWeight.medium,
            }}
          >
            Workspace Name
          </p>
          <p
            css={{
              fontWeight: fontWeight.medium,
            }}
          >
            {50 - newWorkspaceName.length} characters left
          </p>
        </div>
        <Input
          value={newWorkspaceName}
          placeholder='Write a name for your workspace'
          onChange={handleChange}
          maxLength={50}
          css={{
            marginBottom: '50px',
          }}
        />
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <TextLink children='Cancel' onClick={handleClose} />
          <Button disabled={!newWorkspaceName.length} onClick={handleSave} children='Save' />
        </div>
      </div>
    </div>
  )
}
