import {call} from 'redux-saga/effects'
import axios from 'axios'

export interface DataPage {
  current: number
  last: number
  next: number
  size: number
  total: number
}

export interface BaseRequestResult {
  success: boolean
  statusCode: number
  type: string
  payload: any
  page?: DataPage
  error?: any
}

export const baseRequest = async (options: any): Promise<BaseRequestResult> => {
  try {
    const accessToken = options.accessToken
    if (accessToken) {
      delete options.accessToken
      options.headers = {Authorization: `Bearer ${accessToken}`}
    }
    const response = await axios.request(options)
    const statusCode = response.status
    const success = statusCode >= 200 && statusCode < 300
    const type = response.data.type ?? 'NONE'
    const payload = response.data.payload ?? null
    const page = response.data.page ?? null

    return {
      success,
      statusCode,
      type,
      payload,
      page,
      error: null,
    }
  } catch (error: any) {
    const success = false
    const response = error?.response || null
    const statusCode = response?.status || null
    const type = response.data?.type ?? 'NONE'
    const payload = response.data?.payload ?? null
    const page = response.data.page ?? null

    error.success = success
    error.statusCode = statusCode
    error.type = type
    error.payload = payload

    delete error.response
    error.axiosResponse = response

    return {
      success,
      statusCode,
      type,
      payload,
      page,
      error,
    }
  }
}

export const request = (options: any) => call(() => baseRequest(options))
