import {useState, useMemo, Dispatch, SetStateAction} from 'react'
import {RIF, handleEsc} from '../../lib'
import {selectTheme} from '../../store'
import {Button, ButtonCancel} from '..'
import {CheckSuccessIcon, WarningIcon} from '../../asset/image'

export interface ParticipantNotificationInfoPopPropsT {
  closeAction: (arg: boolean) => void
  notificationList: any[]
  notificationInfo: {[key: string]: any}
  setSelectedList: Dispatch<SetStateAction<any[]>>
}

export const ParticipantNotificationInfoPop = (props: ParticipantNotificationInfoPopPropsT) => {
  const {color, fontWeight} = selectTheme()

  const {closeAction, notificationList, notificationInfo, setSelectedList} = props
  const {message, response} = notificationInfo

  const [displayErrorDetail, setDisplayErrorDetail] = useState(false)
  const resHasError = response?.some((item: any) => !item.success)
  const resErrorAmount = response?.filter((item: any) => !item.success).length

  // "POO2", "P001" ...
  const allErrorParticipants: string = useMemo(() => {
    let tempString = ''
    response?.map((item: any) => {
      if (!item.success) {
        const errorParticipantInsignia = notificationList?.find(
          (el) => el.participantId === item.participantId,
        ).insignia
        tempString += `"${errorParticipantInsignia}", `
      }
    })
    return tempString.slice(0, -2)
  }, [notificationList, response])

  handleEsc(closeAction)

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          background: color.white,
          zIndex: '99',
          width: '560px',
          maxHeight: '85vh',
          borderRadius: '5px',
          padding: '30px',
        }}
      >
        <ButtonCancel
          onClick={() => {
            if (displayErrorDetail) return setDisplayErrorDetail(false)
            closeAction(false)
            setSelectedList([]) // empty, order to cancel all
          }}
        />
        <div css={{marginTop: '35px'}}>
          <img src={displayErrorDetail ? WarningIcon : CheckSuccessIcon} width={24} />
        </div>
        <div
          css={{
            fontSize: '20px',
            fontWeight: fontWeight.thick,
            margin: '16px 0px',
          }}
        >
          Message sent!
        </div>
        <div>
          {RIF(
            displayErrorDetail,
            <div>
              <div>Something went wrong with the push notifications for the following participants:</div>
              <div css={{color: color.grey_600, marginTop: '16px'}}>{allErrorParticipants}</div>
            </div>,
          )}
          {RIF(
            !displayErrorDetail,
            <div>
              <div>Your participants will now see this notification when they open the app.</div>
              {RIF(
                resHasError,
                <div
                  css={{
                    background: '#FEF6F3',
                    height: '36px',
                    width: '100%',
                    marginBottom: '24px',
                    marginTop: '16px',
                    padding: '8px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div css={{display: 'flex'}}>
                    <img src={WarningIcon} width={20} />
                    <div css={{color: color.warning, marginLeft: '8px'}}>
                      Failed to sent to {resErrorAmount} participant(s).
                    </div>
                  </div>
                  <div onClick={() => setDisplayErrorDetail(true)} css={{cursor: 'pointer', color: color.primary}}>
                    See error details
                  </div>
                </div>,
              )}
              <div
                css={{
                  background: color.background,
                  borderRadius: '8px',
                  padding: '16px 24px',
                  marginBottom: '48px',
                  marginTop: '24px',
                  maxHeight: 'calc(85vh - 380px)',
                  overflowY: 'scroll',
                }}
              >
                <div css={{marginBottom: '8px', fontWeight: fontWeight.thick}}>{message?.msgTitle}</div>
                <div css={{whiteSpace: 'pre-line', wordBreak: 'break-word'}}>{message?.msgContent}</div>
              </div>
            </div>,
          )}
        </div>

        <div
          css={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button
            onClick={() => {
              if (displayErrorDetail) return setDisplayErrorDetail(false)
              closeAction(false)
              setSelectedList([]) // empty, order to cancel all
            }}
            btnPadding='large'
            btnColor='primary'
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  )
}
