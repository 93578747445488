import {selectTheme} from '../../store'

export interface ToolTipHoverProps {
  tooltipWidth?: string
  title: string | JSX.Element
  subtitle?: string
  hoverTopPosition?: string
  hoverRightPosition?: string
  pseudoTopPosition?: string
  pseudoRightPosition?: string
}

export const ToolTipHover = (props: ToolTipHoverProps) => {
  const {fontWeight, color} = selectTheme()
  const {
    tooltipWidth,
    title,
    subtitle,
    hoverTopPosition,
    hoverRightPosition,
    pseudoRightPosition,
    pseudoTopPosition,
    ...rest
  } = props

  return (
    <div
      {...rest}
      css={{
        width: tooltipWidth ? tooltipWidth : 'max-content',
        background: 'rgba(0, 0, 0, 0.88)',
        color: color.white,
        borderRadius: '5px',
        fontWeight: fontWeight.bold,
        padding: '10px 8px',
        textAlign: 'start',
        position: 'absolute',
        top: hoverTopPosition ? hoverTopPosition : '130%',
        right: hoverRightPosition ?? '50%',
        transform: hoverRightPosition ? `translateX(${hoverRightPosition})` : 'translateX(50%)',
        whiteSpace: tooltipWidth ? 'pre-line' : 'nowrap',
        zIndex: 99,
        ':after': {
          position: 'absolute',
          top: pseudoTopPosition ? pseudoTopPosition : '-8px',
          right: pseudoRightPosition ?? '50%',
          transform: pseudoRightPosition ? `translateX(${pseudoRightPosition})` : 'translateX(50%)',
          content: '""',
          borderTop: pseudoTopPosition ? '10px solid rgba(0, 0, 0, 0.88)' : '',
          borderBottom: pseudoTopPosition ? '' : '10px solid rgba(0, 0, 0, 0.88)',
          borderRight: '5px solid transparent',
          borderLeft: '5px solid transparent',
        },
      }}
    >
      <div css={{color: color.white, maxWidth: 'fit-content', fontSize: '14px'}}>{title}</div>
      <div css={{color: color.grey_400, marginTop: subtitle && '5px', fontSize: '12px'}}>{subtitle}</div>
    </div>
  )
}
