import {Dispatch, SetStateAction, useState} from 'react'
import {selectTheme} from '../../store'
import {Button, TaskScheduleSelectionType, TaskScheduleTypeSelection} from '..'
import {
  IProjectScheduleConfig,
  ITaskSchedule,
  ProjectScheduleFrequency,
  ProjectScheduleReference,
  ScheduleType,
} from '../../shared/db'
import Scrollbars from 'react-custom-scrollbars-2'
import {_, t} from '../../lib'

export type LocalSchedule = Pick<ITaskSchedule, 'type' | 'schedule'> & { id?: string }

export interface AddSchedulePopProsT {
  projectScheduleConfig: IProjectScheduleConfig
  editingScheduleList: LocalSchedule[] | undefined
  setDisplaySchedulePop: Dispatch<SetStateAction<boolean>>
  allScheduleList: LocalSchedule[][]
  setAllScheduleList: Dispatch<SetStateAction<LocalSchedule[][]>>
}

export const AddSchedulePop = (props: AddSchedulePopProsT) => {
  const {color} = selectTheme()
  const {
    projectScheduleConfig,
    editingScheduleList,
    setDisplaySchedulePop,
    allScheduleList,
    setAllScheduleList
  } = props

  const isEditing = !!editingScheduleList
  const { defaultSelectedType, defaultSchedules }: { defaultSelectedType: TaskScheduleSelectionType | null, defaultSchedules: LocalSchedule[]}  = (() => {
    if (editingScheduleList && editingScheduleList.length > 0) {
      const first = editingScheduleList[0]
      if (first.schedule.year) {
        return {
          defaultSelectedType: TaskScheduleSelectionType.AbsoluteDay,
          defaultSchedules: [first]
        }
      } else if (first.schedule.weekday) {
        return {
          defaultSelectedType: TaskScheduleSelectionType.RecurrentWeekDay,
          defaultSchedules: editingScheduleList
        }
      } else if (first.schedule.day) {
        switch (first.type) {
          case ScheduleType.Relative: {
            switch (t.unwrap(projectScheduleConfig.frequency)) {
              case ProjectScheduleFrequency.Daily:
                return {
                  defaultSelectedType: TaskScheduleSelectionType.ParticipantJoinedXDay,
                  defaultSchedules: [first]
                }
              case ProjectScheduleFrequency.Weekly: {
                switch (t.unwrap(projectScheduleConfig.reference)) {
                  case ProjectScheduleReference.Absolute:
                    return {
                      defaultSelectedType: TaskScheduleSelectionType.ParticipantJoinedXAbsoluteWeek,
                      defaultSchedules: [first]
                    }
                  case ProjectScheduleReference.Participant:
                    return {
                      defaultSelectedType: TaskScheduleSelectionType.ParticipantJoinedXRelativeWeek,
                      defaultSchedules: [first]
                    }
                }
              }
            }
            break
          }
          case ScheduleType.Recurring:
            return {
              defaultSelectedType: TaskScheduleSelectionType.RecurrentXDay,
              defaultSchedules: [first]
            }
          default:
            return {
              defaultSelectedType: null,
              defaultSchedules: []
            }
        }
      } else {
        return {
          defaultSelectedType: null,
          defaultSchedules: []
        }
      }
    } else {
      return {
        defaultSelectedType: null,
        defaultSchedules: []
      }
    }
  })()
  const [selectedScheduleType, setSelectedScheduleType] = useState<TaskScheduleSelectionType | null>(defaultSelectedType)
  const emptySelectionResult: Record<TaskScheduleSelectionType, LocalSchedule[]> = {
    [TaskScheduleSelectionType.AbsoluteDay]: [],
    [TaskScheduleSelectionType.RecurrentXDay]: [],
    [TaskScheduleSelectionType.RecurrentWeekDay]: [],
    [TaskScheduleSelectionType.ParticipantJoinedXDay]: [],
    [TaskScheduleSelectionType.ParticipantJoinedXAbsoluteWeek]: [],
    [TaskScheduleSelectionType.ParticipantJoinedXRelativeWeek]: []
  }
  const defaultTypeScheduleMap = defaultSelectedType ? {...emptySelectionResult, [defaultSelectedType] : defaultSchedules } : emptySelectionResult
  const [resultTypeScheduleMap, setResultTypeScheduleMap] = useState<Record<TaskScheduleSelectionType, LocalSchedule[]>>(defaultTypeScheduleMap)
  const scheduleSelectionTypeList: TaskScheduleSelectionType[] = (() => {
    switch (t.unwrap(projectScheduleConfig.frequency)) {
      case ProjectScheduleFrequency.Daily:
        return [TaskScheduleSelectionType.AbsoluteDay,
          TaskScheduleSelectionType.RecurrentXDay,
          TaskScheduleSelectionType.RecurrentWeekDay,
          TaskScheduleSelectionType.ParticipantJoinedXDay]
      case ProjectScheduleFrequency.Weekly: {
        switch (t.unwrap(projectScheduleConfig.reference)) {
          case ProjectScheduleReference.Absolute:
            return [TaskScheduleSelectionType.AbsoluteDay,
              TaskScheduleSelectionType.RecurrentXDay,
              TaskScheduleSelectionType.RecurrentWeekDay,
              TaskScheduleSelectionType.ParticipantJoinedXAbsoluteWeek]
          case ProjectScheduleReference.Participant:
            return [TaskScheduleSelectionType.AbsoluteDay,
              TaskScheduleSelectionType.RecurrentXDay,
              TaskScheduleSelectionType.RecurrentWeekDay,
              TaskScheduleSelectionType.ParticipantJoinedXRelativeWeek]
        }
      }
    }
  })()

  const handleClickSave = () => {
    const resultScheduleList = resultTypeScheduleMap[t.unwrap(selectedScheduleType)]
    if (isEditing) {
      const index = allScheduleList.indexOf(editingScheduleList)
      const cloned = _.cloneDeep(allScheduleList)
      cloned[index] = resultScheduleList
      setAllScheduleList(cloned)
    } else {
      setAllScheduleList([...allScheduleList, resultScheduleList])
    }
    setDisplaySchedulePop(false)
  }
  const canSave = selectedScheduleType ? (resultTypeScheduleMap[selectedScheduleType].length !== 0) : false

  return (
    <div
      data-testid='add_schedule_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
    >
      <div
        css={{
          top: 'calc(50vh - 300px)',
          left: 'calc(50vw - 300px)',
          position: 'fixed',
          width: '560px',
          background: color.white,
          borderRadius: '5px',
          boxShadow: '0px 4px 10px 0px #0000001A',
          paddingTop: '16px',
          paddingLeft: '24px',
          paddingRight: '24px',
          paddingBottom: '24px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <div>
          <div css={{fontWeight: '700', fontSize: '20px'}}>
            {isEditing ? 'Edit' : 'Add an'} Task schedule
          </div>

          <Scrollbars
            autoHide={true}
            autoHideTimeout={200}
            autoHeight={true}
            autoHeightMin={500}
            style={{
              borderBottom: `1px solid ${color.grey_100}`,
            }}
          >
            <div
              css={{
                width: '100%',
                padding: '16px 24px',
              }}
            >
              <div>
                <div
                  css={{
                    marginTop: '12px',
                    marginBottom: '12px',
                  }}
                >
                  Choose a schedule for this task.
                  Note: You can add more than one schedule. To do so, first choose one schedule, save, then add a new one.
                </div>
                <div>
                  {scheduleSelectionTypeList.map((scheduleType) => {
                    return (
                      <TaskScheduleTypeSelection
                        key={scheduleType}
                        projectScheduleConfig={projectScheduleConfig}
                        scheduleType={scheduleType}
                        selected={selectedScheduleType === scheduleType}
                        onSelect={setSelectedScheduleType}
                        taskSchedules={resultTypeScheduleMap[scheduleType]}
                        setTaskSchedules={(taskSchedules) => {
                          setResultTypeScheduleMap({
                            ...resultTypeScheduleMap,
                            [scheduleType] : taskSchedules
                          })
                        }}
                        selectionCSS={{
                          marginBottom: '8px',
                        }}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>

        <div css={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '40px'}}>
          <p onClick={() => setDisplaySchedulePop(false)} css={{color: color.primary, cursor: 'pointer'}}>
            Cancel
          </p>
          <Button onClick={handleClickSave}
                  data-testid='schedule_save_button'
                  btnPadding='large'
                  disabled={!canSave}
                  type='button'>
            save
          </Button>
        </div>
      </div>
    </div>
  )
}
