import {isUndefined} from 'lodash'
import {ReplayEventEmitter} from '@twilio/replay-event-emitter'

type Sink<T> = (value: T) => void

export class ReplayRelay<T> {
  private readonly valueEmitter = new ReplayEventEmitter<{
    value: Sink<T>
  }>().setMaxListeners(0)

  constructor(initialValue?: T) {
    if (!isUndefined(initialValue)) {
      this.valueEmitter.emit('value', initialValue)
    }
  }

  setValue(value: T) {
    this.valueEmitter.emit('value', value)
  }

  async getValue(): Promise<T> {
    return new Promise((resolve) => this.valueEmitter.onceWithReplay('value', (value) => resolve(value)))
  }

  subscribe(onNext: Sink<T>) {
    this.valueEmitter.on('value', (value) => onNext(value))
  }

  subscribeWithReplay(onNext: Sink<T>) {
    this.valueEmitter.onWithReplay('value', (value) => onNext(value))
  }

  subscribeOnce(onNext: Sink<T>) {
    this.valueEmitter.once('value', (value) => onNext(value))
  }

  subscribeOnceWithReplay(onNext: Sink<T>) {
    this.valueEmitter.onceWithReplay('value', (value) => onNext(value))
  }
}
