import React from 'react'
import {Global, css} from '@emotion/react'
import emotionReset from 'emotion-reset'
import {selectTheme} from '../store'

export const StyleReset = () => {
  const {color, fontWeight} = selectTheme()
  return (
    <Global
      styles={css`
        ${emotionReset}

        html * {
          font-size: 0.875rem;
          font-family: 'DM Sans', sans-serif;
          font-weight: ${fontWeight.medium};
          line-height: normal;
          box-sizing: border-box;
          color: ${color.black};
          text-decoration: none;
        }
      `}
    />
  )
}
