import {assertPartialSchema, createAction, useSelector, LOGOUT, v} from '../../../lib'

import {AUTH_SET} from '../auth'
export const MFA_REQUIRED_SET = 'MFA_REQUIRED_SET'
export const doMFA_REQUIRED_SET = createAction(MFA_REQUIRED_SET)

/* selector */
export const selectMFARequired = () => {
  return useSelector((state) => state.mfaRequired)
}

export const mfaRequiredActionCreators = {
  doMFA_REQUIRED_SET,
}

export const mfaRequiredDefaultState = null

export const mfaRequiredReducer = (state = mfaRequiredDefaultState, {type, payload}) => {
  switch (type) {
    case AUTH_SET:
      return mfaRequiredDefaultState
    case MFA_REQUIRED_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          verificationCodeType: v.string().exist(),
          alternateMFAAllowed: v.any(),
        }),
      })
      return payload
    case LOGOUT:
      return mfaRequiredDefaultState
    default:
      return state
  }
}
