import {useState} from 'react'
import {DragIcon, DraggingIcon} from '../../asset/image'

export const DragHandle = (props) => {
  const [hovering, setHovering] = useState(false)
  const {isShowing, isDragging, ...rest} = props

  const handleMouseEnter = () => {
    setHovering(true)
  }

  const handleMouseLeave = () => {
    setHovering(false)
  }

  return (
    <div
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      css={{
        width: '24px',
        height: '31px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: isShowing ? 1 : 0,
      }}
    >
      <img width='8' src={hovering || isDragging ? DraggingIcon : DragIcon} />
    </div>
  )
}
