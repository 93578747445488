import {SignupForm, SignupPageTestimonial} from '..'
import {selectTheme} from '../../store'

import {CheckPrimaryIcon} from '../../asset/image'
import LabfrontLogo from '../../asset/image/labfront_logo.png'
import SecurityBadge from '../../asset/image/security_badge.png'
import SignupBackgroundPattern from '../../asset/image/signup_background_pattern.png'

export const SignupPage = () => {
  const {color, fontWeight} = selectTheme()
  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 102,
        width: '100vw',
        height: '100vh',
        display: 'flex',
      }}
    >
      <section
        css={{
          width: '30%',
          height: '100vh',
          background: '#EBF0F6',
          backgroundImage: `url(${SignupBackgroundPattern})`,
          backgroundSize: 'cover',
          overflowY: 'auto',
          // temp hide section when mobile
          '@media(max-width: 600px)': {
            display: 'none',
          },
        }}
      >
        <div css={{padding: '48px', zIndex: 2, maxWidth: '100%', height: '100%'}}>
          <img src={LabfrontLogo} width='95' height='24' />
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height: '100%',
              paddingBottom: '40px',
            }}
          >
            <div>
              <p css={{marginTop: '40px', marginBottom: '24px', fontWeight: fontWeight.bold, fontSize: '24px'}}>
                All-in-one research solution for real-world data capture
              </p>
              <div css={{display: 'flex', marginBottom: '10px'}}>
                <img src={CheckPrimaryIcon} width='20' height='20' />
                <p css={{marginLeft: '8px', fontSize: '16px'}}>Collect high-resolution wearable data</p>
              </div>
              <div css={{display: 'flex', marginBottom: '10px'}}>
                <img src={CheckPrimaryIcon} width='20' height='20' />
                <p css={{marginLeft: '8px', fontSize: '16px'}}>Remotely manage participant adherence</p>
              </div>
              <div css={{display: 'flex', marginBottom: '10px'}}>
                <img src={CheckPrimaryIcon} width='20' height='20' />
                <p css={{marginLeft: '8px', fontSize: '16px'}}>Data analysis from biomarker experts</p>
              </div>
            </div>
            <SignupPageTestimonial />
          </div>
        </div>
      </section>
      <section
        css={{
          width: '70%',
          '@media(max-width: 600px)': {
            width: '100%',
          },
          background: color.white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div css={{display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column'}}>
          <SignupForm />
          <img src={SecurityBadge} width='208' height='30' css={{marginTop: '40px', filter: 'grayscale(100%)'}} />
        </div>
      </section>
    </div>
  )
}
