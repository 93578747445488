import {
  assertPartialSchema, 
  createAction, 
  useSelector, 
  v,
  _,
} from '../../../lib'

export enum LoadingActionType {
  LOADING_SET = 'LOADING_SET',
  LOADING_DELETE = 'LOADING_DELETE',
  LOADING_RESET = 'LOADING_RESET',
}

export const doLOADING_SET = createAction(LoadingActionType.LOADING_SET)
export const doLOADING_DELETE = createAction(LoadingActionType.LOADING_DELETE)
export const doLOADING_RESET = createAction(LoadingActionType.LOADING_RESET)

export interface LoadingState {
  requestId?: string
  type?: string
 }

interface RootState {
  loading: LoadingState[]
}

export const selectLoading = () => {
  return useSelector((state: RootState) => state.loading)
}

export const loadingActionCreators = {
  doLOADING_SET,
  doLOADING_DELETE,
  doLOADING_RESET,
}

export const loadingDefaultState: LoadingState[] = []

type Action = 
  | {
      type: LoadingActionType.LOADING_SET
      payload: {
        requestId?: string
        type?: string
      }
    }
  | {
      type: LoadingActionType.LOADING_DELETE
      payload: {
        requestId: string
      }
    }
  | {
      type: LoadingActionType.LOADING_RESET
      payload: never
    } 

export const loadingReducer = (
  state: LoadingState[] = [...loadingDefaultState], 
  {type, payload}: Action
) => {
  const newState = [...state]
  switch (type) {
    case LoadingActionType.LOADING_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          requestId: v.string().uuid().optional(),
          type: v.string().optional(),
        }),
      })

      return [...newState, {requestId: payload.requestId, type: payload.type}]
    
    case LoadingActionType.LOADING_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          requestId: v.string().uuid(),
        }),
      })
      _.remove(newState, (item: LoadingState) => {
        return item.requestId === payload.requestId
      })

      return [...newState]

    case LoadingActionType.LOADING_RESET:
      return []
    
    default:
      return [...state]
  }
}
