import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doADHERENCE_GARMIN_CONNECT_WERA_TIME_DATA_SET,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH = 'REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH'
export const doREQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH = createDoRequestAction(
  REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH,
)

export const requestProjectGarminConnectWearTimeDataFetchActionCreators = {
  doREQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH,
}

export function* requestProjectGarminConnectWearTimeDataFetch({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectGarminConnectWearTimeDataFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
        yymmddIndexList: v.array().items(v.number()),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {projectId, yymmddIndexList} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/project-garmin-connect-wear-time-data-fetch`,
    accessToken,
    data: {
      projectId,
      yymmddIndexList,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v2/web/project-garmin-connect-wear-time-data-fetch',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doADHERENCE_GARMIN_CONNECT_WERA_TIME_DATA_SET({
      ...result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH,
      fromPayload: payload,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* projectGarminConnectWearTimeDataFetchSaga() {
  yield takeEvery(REQUEST_PROJECT_GARMIN_CONNECT_WEAR_TIME_DATA_FETCH, requestProjectGarminConnectWearTimeDataFetch)
}
