import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import { Collaborator } from '../../../model'

export enum CollaboratorActionType {
  COLLABORATOR_SET = 'COLLABORATOR_SET',
  COLLABORATOR_LIST_SET = 'COLLABORATOR_LIST_SET',
  COLLABORATOR_DELETE = 'COLLABORATOR_DELETE',
}

export const doCOLLABORATOR_SET = createAction(CollaboratorActionType.COLLABORATOR_SET)
export const doCOLLABORATOR_LIST_SET = createAction(CollaboratorActionType.COLLABORATOR_LIST_SET)
export const doCOLLABORATOR_DELETE = createAction(CollaboratorActionType.COLLABORATOR_DELETE)

export interface CollaboratorState {
  [workspaceId: string]: {
    [id: string]: Collaborator
  }
}

// todo: combine all rootstate and put it somewhere else
interface RootState {
  collaborators: CollaboratorState
}

export const selectCollaborators = () => {
  return useSelector((state: RootState) => state.collaborators)
}

export const collaboratorsActionCreators = {
  doCOLLABORATOR_SET,
  doCOLLABORATOR_DELETE,
  doCOLLABORATOR_LIST_SET,
}

export const collaboratorsDefaultState: CollaboratorState = {}

type Action = 
  | {
      type: CollaboratorActionType.COLLABORATOR_SET
      payload: Collaborator
    }
  | {
      type: CollaboratorActionType.COLLABORATOR_DELETE
      payload: {
        id: string
        workspaceId: string
      }
    }
  | {
      type: CollaboratorActionType.COLLABORATOR_LIST_SET
      payload: {
        workspaceId: string
        collaboratorList: Collaborator[]
      }
    }

export const collaboratorsReducer = (
  state = collaboratorsDefaultState, 
  {type, payload}: Action
): CollaboratorState => {
  const newState: CollaboratorState = {...state}
  switch (type) {
    case CollaboratorActionType.COLLABORATOR_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          identityId: v.string().uuid().exist(),
          type: v.string().valid('admin', 'editor', 'viewer', 'project_collaborator').exist(),
          projectCollaboratorList: v.array().allow(null).optional(),
          workspaceId: v.string().uuid().exist(),
          profile: v.object({
            id: v.string().uuid().optional(),
            email: v.string().email({tlds: {allow: false}}).optional(),
            fistName: v.string().optional(),
            lastName: v.string().optional(),
            title: v.string().optional().allow('', null),
            imageUrl: v.string().optional().allow('', null),
          }),
        }),
      })

      if (!newState[payload.workspaceId]) newState[payload.workspaceId] = {}
      newState[payload.workspaceId][payload.id] = payload

      return {...newState}

    case CollaboratorActionType.COLLABORATOR_DELETE:
      assertPartialSchema({
        payload,
        schema: v.object({
          id: v.string().uuid().exist(),
          workspaceId: v.string().uuid().exist(),
        }),
      })

      delete newState[payload.workspaceId][payload.id]
      return {...newState}

    case CollaboratorActionType.COLLABORATOR_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.object({
          workspaceId: v.string().uuid().exist(),
          collaboratorList: v.array().items(
            v.object({
              id: v.string().uuid().exist(),
              identityId: v.string().uuid().exist(),
              type: v.string().valid('admin', 'editor', 'viewer', 'project_collaborator').exist(),
              projectCollaboratorList: v.array().allow(null).optional(),
              workspaceId: v.string().uuid().exist(),
              profile: v.object({
                id: v.string().uuid().optional(),
                email: v.string().email({tlds: {allow: false}}).optional(),
                fistName: v.string().optional(),
                lastName: v.string().optional(),
                title: v.string().optional().allow(null, ''),
                imageUrl: v.string().optional().allow(null, ''),
              }).allow(null),
            }),
          ),
        })
      })

      if (!newState[payload.workspaceId]) newState[payload.workspaceId] = {}
      payload.collaboratorList.map((collaborator) => {
        // if (!newState[collaborator.workspaceId]) newState[collaborator.workspaceId] = {}
        newState[payload.workspaceId][collaborator.id] = collaborator
      })
      return {...newState}

    default:
      return {...state}
  }
}
