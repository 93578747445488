import {ReactElement, useState} from 'react'
import {selectTheme} from '../../store'
import QuestionIcon from '../../asset/image/icon/question.png'
import {RIF} from '../../lib'

interface TooltipProps {
  tooltipWidth?: string
  position?: string
  pseudoPosition?: string
  content: string | ReactElement
  textAlign?: 'start' | 'end' | 'center'
  css?: {[key: string]: string | number}
}

export const Tooltip = (props: TooltipProps) => {
  const {color} = selectTheme()
  const [displayTooltip, setDisplayTooltip] = useState(false)
  const {
    tooltipWidth, 
    position, 
    pseudoPosition, 
    content, 
    textAlign, 
    ...rest
  } = props
  

  return (
    <div
      {...rest}
      onMouseOver={() => setDisplayTooltip(true)}
      onMouseOut={() => setDisplayTooltip(false)}
      css={{
        width: '16px',
        minWidth: '16px',
        height: '16px',
        minHeight: '16px',
        borderRadius: '50%',
        background: '#C2CCFC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        ':hover': {
          background: color.primary,
        },
      }}
    >
      <img src={QuestionIcon} width='6' height='8' />

      {RIF(
        displayTooltip,
        <div
          css={{
            minWidth: '220px',
            zIndex: 100,
            width: tooltipWidth ?? '220px',
            background: 'rgba(0, 0, 0, 0.88)',
            borderRadius: '5px',
            color: color.white,
            fontSize: '14px',
            padding: '10px 8px',
            textAlign: textAlign ?? 'center',
            position: 'absolute',
            top: position ? '25px' : 'auto', 
            bottom: position ? 'auto' : '25px',
            right: pseudoPosition && `-${pseudoPosition}`,
            wordWrap: 'break-word',
            webkitHyphens: 'auto',
            mozHyphens: 'auto',
            msHyphens: 'auto',
            hyphens: 'auto',
            whiteSpace: 'pre-line',
            ':after': {
              position: 'absolute',
              top: position ? 'auto' : '100%',
              bottom: position ? '100%' : 'auto',
              left: pseudoPosition ? pseudoPosition : '50%',
              transform: 'translateX(-50%)',
              content: '""',
              borderTop: position === 'up' ? 'none' : '10px solid black',
              borderBottom: position === 'up' ? '10px solid black' : 'none',
              borderRight: '5px solid transparent',
              borderLeft: '5px solid transparent',
            },
          }}
        >
          {content}
        </div>,
      )}
    </div>
  )
}
