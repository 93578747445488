import Lottie from 'lottie-web'
import AddingAnimation from '../../asset/lotties/adding_animation.json'
import {useState, createRef, useEffect, Dispatch, SetStateAction} from 'react'
import {
  selectTheme,
  selectMethod,
  selectTempTaskData,
  createDispatchActions,
} from '../../store'
import {RIF, handleEsc, _, t, useCurrentWorkspaceState, useCurrentProjectState, RequestResult} from '../../lib'
import {Legend, ButtonCancel, ButtonReverse, Button} from '..'
import {
  DexcomIntegration, 
  TaskTypeForDevicePage,
  TaskTypeForInstructionPage,
  TaskType,
} from '../../model'
import {
  TickIcon, 
  TickWhiteIcon, 
  WarningGreyIcon,
  GarminDeviceImg, 
  MovesenseImg, 
  DexcomImg
} from '../../asset/image'

// todo check_stopwatch_movesense_stream
type AddDeviceProcess = '' | 'garmin_device' | 'stopwatch_movesense_stream' | 'dexcom'
export interface AddDevicePopupProps {
  closeAction: Dispatch<SetStateAction<boolean>>
  setDisplayDevicePage: Dispatch<SetStateAction<TaskTypeForDevicePage>>
  setDisplayInstructionPop: Dispatch<SetStateAction<TaskTypeForInstructionPage>>
}

export const AddDevicePopup = (props: AddDevicePopupProps) => {
  const {closeAction, setDisplayDevicePage, setDisplayInstructionPop, ...rest} = props
  const {
    doREQUEST_METHOD_UPDATE,
    doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE,
    doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE,
  }: any = createDispatchActions()

  // fetch from root state
  const {color} = selectTheme()
  const methodState = selectMethod()
  const tempTaskData = selectTempTaskData()

  // extract data from state
  const methodId = methodState?.id as string
  const { workspace: currentWorkspace } = useCurrentWorkspaceState()
  const { project } = useCurrentProjectState()
  const isEditingLiveProject = project?.status === 'live'

  // get status of device enable
  const hasGarminData: boolean = isEditingLiveProject
  ? !!tempTaskData?.garminConnectEnable || !!tempTaskData?.garminDeviceEnable || !!tempTaskData?.garminStreamEnable
  : !!methodState?.garminConnectEnable || !!methodState?.garminDeviceEnable || !!methodState?.garminStreamEnable
  const movesenseDeviceEnable = isEditingLiveProject
  ? !!tempTaskData?.movesenseDeviceEnable || !!methodState?.movesenseDeviceEnable
  : !!methodState?.movesenseDeviceEnable
  const dexcomIntegrationId = isEditingLiveProject ? tempTaskData.dexcomIntegrationId : methodState.dexcomIntegrationId
  const dexcomIntegrationList = currentWorkspace?.dexcomIntegrationList ?? []
  // user can only add approved dexcom integration in live project
  const dexcomDisabledForLiveProject = isEditingLiveProject && !dexcomIntegrationList?.filter(
    (dexcomIntegration: DexcomIntegration) => 
    !!dexcomIntegration.dexcomApp
  ).length

  // request complete hook
  const [requestResultOfAddMovesenseTask, setRequestResultOfAddMovesenseTask]= useState<RequestResult | null>(null)
  const [requestResultOfAddGarminConnectTask, setRequestResultOfAddGarminConnectTask]= useState<RequestResult | null>(null)

  // custom hook
  const [addProcess, setAddProcess] = useState<AddDeviceProcess>('')
  const [addAnimationLoading, setAddAnimationLoading] = useState(false)
  const [addAnimationLoadingCompleted, setAddAnimationLoadingCompleted] = useState(false)

  // private functions
  const addingTransition = (exeFunc: any) => {
    const loading = setTimeout(() => {
      setAddAnimationLoading(false)
      setAddAnimationLoadingCompleted(true)
    }, 1000)
    const waiting = setTimeout(() => {
      closeAction(false)
      exeFunc()
      setAddProcess('')
    }, 2000)
    return () => {
      clearTimeout(loading)
      clearTimeout(waiting)
    }
  }

  /* -------------------- click Garmin btn -------------------- */
  const garminBtnClickAction = () => {
    if (hasGarminData) {
      setDisplayDevicePage('garmin_device')
      closeAction(false)
      return
    }
    if (!hasGarminData) {
      setAddProcess('garmin_device')
      setAddAnimationLoading(true)
      if (!isEditingLiveProject) {
        // default only turn on garmin connect
        return doREQUEST_METHOD_UPDATE({
          setRequestResult: setRequestResultOfAddGarminConnectTask,
          payload: {
            methodId,
            garminConnectEnable: true,
          },
        })
      }
      if (isEditingLiveProject) {
        doTEMP_TASK_DATA_GARMIN_CONNECT_ENABLE_UPDATE({garminConnectEnable: true})
        addingTransition(() => {
          setDisplayDevicePage('garmin_device')
          closeAction(false)
        })
      }
    }
  }

  useEffect(() => {
    if (requestResultOfAddGarminConnectTask?.success) {
      addingTransition(() => {
        setDisplayDevicePage('garmin_device')
        closeAction(false)
      })
    }
  }, [requestResultOfAddGarminConnectTask])

  /* -------------------- click MS btn -------------------- */
  const movesenseBtnClickAction = () => {
    // todo check_stopwatch_movesense_stream
    setAddProcess('stopwatch_movesense_stream')
    if (movesenseDeviceEnable) return
    setAddAnimationLoading(true)
    if (isEditingLiveProject) {
      doTEMP_TASK_DATA_MOVESENSE_DEVICE_ENABLE_UPDATE({movesenseDeviceEnable: true})
      addingTransition(() => setDisplayInstructionPop(TaskType.StopwatchMovesenseStream))
    }
    if (!isEditingLiveProject) {
      doREQUEST_METHOD_UPDATE({
        setRequestResult: setRequestResultOfAddMovesenseTask,
        payload: {
          methodId,
          movesenseDeviceEnable: true,
        },
      })
    }
  }

  useEffect(() => {
    if (requestResultOfAddMovesenseTask?.success) {
      addingTransition(() => setDisplayInstructionPop(TaskType.StopwatchMovesenseStream))
    }
  }, [requestResultOfAddMovesenseTask])

  // click dexcom btn
  const addDexcomBtnClickAction = () => {
    // only show selector when there is approved integration for live project
    if (isEditingLiveProject) {
      if (!dexcomDisabledForLiveProject) {
        return setDisplayDevicePage('dexcomSelectorPopup')
      }
      return
    }
    if (isEditingLiveProject) return
    if (dexcomIntegrationList?.length > 0) {
      return setDisplayDevicePage('dexcomSelectorPopup')
    }
    return setDisplayDevicePage('dexcomIntegrationForm')
  }

  handleEsc(closeAction)

  return (
    <div
      data-testid='add_device_pop'
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'scroll',
        top: 0,
        left: 0,
        zIndex: 102,
      }}
    >
      <main
        {...rest}
        css={{
          margin: '120px auto',
          width: '900px',
          height: 'fit-content',
          borderRadius: '5px',
          background: color.white,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* left */}
        <div css={{padding: '30px', width: '30%'}}>
          <ButtonCancel onClick={() => closeAction(false)} />
          <Legend css={{margin: '30px 0 10px'}}>Add Devices</Legend>
          <div>
            Add the device you want to use in your project to collect the physiological data.
            <br />
            <br />
            Not sure which device to choose?
            <br />
            <br />
            1. Check out the{' '}
            <a href='https://www.labfront.com/compatible-devices' css={{color: color.primary}}>
              full device list
            </a>{' '}
            and the data type they can collect.
            <br />
            <br />
            2. Check out{' '}
            <a href='https://www.labfront.com/case-studies' css={{color: color.primary}}>
              case studies
            </a>{' '}
            to learn how other researchers use device in their project
            <br />
            <br />
            3. Check out our{' '}
            <a href='https://www.labfront.com/course/physiological-data-in-research' css={{color: color.primary}}>
              video course
            </a>{' '}
            video course: How To Enrich Your Research Through Physiological Data Collection
          </div>
        </div>

        {/* right */}
        <div css={{width: '70%', background: color.background, borderRadius: '5px'}}>
          {/* <div css={{margin: '80px 0 0 30px', fontWeight: fontWeight.bold}}>Choose devices:</div> */}
          <div css={{width: '550px', margin: '75px 50px 80px 30px'}}>
            <AddDeviceSection
              {...{
                img: GarminDeviceImg,
                title: 'Garmin Devices',
                description: 'Use a Garmin wearable to collect a wide range of physiological data.',
                device: 'garmin_device',
                isAdded: !!hasGarminData,
                learnMoreLink: 'https://www.labfront.com/compatible-devices',
                handleClickBtn: garminBtnClickAction,
                addAnimationLoading,
                addAnimationLoadingCompleted,
                addProcess,
              }}
            />

            <AddDeviceSection
              {...{
                img: MovesenseImg,
                title: 'Movesense (Beta)',
                description: `Small and light, this durable sensor can be used as an ECG or movement sensor (IMU).`,
                device: 'stopwatch_movesense_stream', // todo check_stopwatch_movesense_stream
                isAdded: !!movesenseDeviceEnable,
                learnMoreLink: 'https://www.labfront.com/compatible-devices',
                handleClickBtn: movesenseBtnClickAction,
                addAnimationLoading,
                addAnimationLoadingCompleted,
                addProcess,
              }}
            />

            <AddDeviceSection
              {...{
                img: DexcomImg,
                title: 'Dexcom',
                description: 'Best for people with T1 diabetes using a connected insulin dosing system.',
                device: 'dexcom',
                isAdded: !!dexcomIntegrationId,
                learnMoreLink: 'https://www.labfront.com/compatible-devices',
                handleClickBtn: addDexcomBtnClickAction,
                addAnimationLoading,
                addAnimationLoadingCompleted,
                addProcess,
                btnDisabled: dexcomDisabledForLiveProject,
              }}
            />

            {/* <AddDeviceSection
              {...{
                img: Smartphone,
                title: 'Smart Phone',
                description: `Use your phone's accelerometer to collect movement data.`,
                device: 'smartphone',
                isAdded: false,
                // handleClickBtn: () => setDisplayDevicePage('smartphone'),
              }}
            /> */}

            {/* <AddDeviceSection
              {...{
                img: Polar,
                title: 'Polar H10',
                description:
                  'Exercise-friendly, water-resistant ECG that can measure RR-interval and stream ECG waveform.',
                device: 'polar',
                isAdded: !!polarTaskData,
                // handleClickBtn: () => setDisplayDevicePage('polar'),
              }}
            /> */}
          </div>

          {/* <div css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            margin: '40px 50px 0 0'}}>
            <a onClick={() => closeAction(false)}
              css={{color: color.primary,
                fontWeight: fontWeight.bold,
                cursor: 'pointer',
                marginRight: '45px'}}>
              Cancel
            </a>
            <Button type='submit'
              onClick={() => closeAction(false)}
              btnPadding='large'
              btnColor='primary'>
              Done
            </Button>
          </div> */}
        </div>
      </main>
    </div>
  )
}

interface AddDeviceSectionProps {
  img: any
  title: string
  description: string
  device: 'garmin_device' | 'smartphone' | 'polar' | 'stopwatch_movesense_stream' | 'dexcom'
  isAdded: boolean
  handleClickBtn?: () => void
  btnDisabled?: boolean
  learnMoreLink?: string
  addAnimationLoading?: boolean
  addAnimationLoadingCompleted?: boolean
  addProcess?: AddDeviceProcess
}

const AddDeviceSection = (props: AddDeviceSectionProps) => {
  const {color, fontWeight} = selectTheme()
  const {
    img,
    title,
    description,
    device,
    isAdded,
    handleClickBtn,
    learnMoreLink,
    addAnimationLoading,
    addAnimationLoadingCompleted,
    addProcess,
    btnDisabled,
  } = props

  // adding animation
  const animRef: any = createRef()

  useEffect(() => {
    Lottie.loadAnimation({
      container: animRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: AddingAnimation,
    })
    return () => Lottie.stop()
  }, [addAnimationLoading, isAdded])

  return (
    <div
      css={{
        width: '100%',
        height: 'fit-content',
        background: color.white,
        borderRadius: '5px',
        border: '1px solid',
        borderColor: isAdded && !addProcess ? color.primary : color.grey_100,
        padding: '24px 32px 24px 16px',
        marginBottom: '15px',
        display: 'flex',
        ':hover': {
          borderColor: isAdded && !addProcess ? color.primary : '',
        },
      }}
    >
      <div css={{width: '96px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img src={img} css={{width: '96px', height: '96px'}} />
      </div>
      <div css={{width: '388px', marginLeft: '16px'}}>
        <div css={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
          <div css={{width: '254px'}}>
            <div css={{fontSize: '16px', fontWeight: fontWeight.bold, margin: '0px 0 8px 0'}}>{title}</div>
            <div css={{marginBottom: '8px'}}>{description}</div>
            {RIF(
              learnMoreLink,
              <a href={learnMoreLink} target='_blank' css={{fontWeight: '700', color: color.primary}}>
                learn more
              </a>,
            )}
          </div>

          <div 
            css={{
              width: '110px', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'end'
            }}
          >
            {RIF(
              ['garmin_device', 'stopwatch_movesense_stream', 'dexcom'].includes(device),
              <>
                {RIF(
                  addProcess !== device,
                  <>
                    {RIF(
                      !isAdded,
                      <Button 
                        data-testid={`add_device_btn_${device}`} 
                        onClick={handleClickBtn} 
                        btnPadding='medium'
                        disabled={btnDisabled}
                      >
                        Add Device
                      </Button>,
                    )}
                    {RIF(
                      isAdded,
                      <div>
                        <div css={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                          <img src={TickIcon} width='18' height='14' />
                          <span css={{color: color.primary, marginLeft: '8px'}}>Added</span>
                        </div>
                        {RIF(
                          title !== 'Movesense',
                          <div css={{marginTop: '10px'}}>
                            <ButtonReverse onClick={handleClickBtn} btnPadding='medium'>
                              View Details
                            </ButtonReverse>
                          </div>,
                        )}
                      </div>,
                    )}
                  </>,
                )}

                {RIF(
                  addProcess == device,
                  <>
                    {RIF(
                      !isAdded,
                      <>
                        {RIF(
                          !addAnimationLoading && !addAnimationLoadingCompleted,
                          <Button 
                            data-testid={`add_device_btn_${device}`} 
                            onClick={handleClickBtn} 
                            btnPadding='medium'
                          >
                            Add Device
                          </Button>,
                        )}
                        {RIF(
                          addAnimationLoading && !addAnimationLoadingCompleted,
                          <Button css={{width: '108px', height: '35px'}}>
                            <div ref={animRef} css={{width: '110px', height: '34px'}} />
                          </Button>,
                        )}
                      </>,
                    )}
                    {RIF(
                      isAdded,
                      <>
                        {RIF(
                          addAnimationLoading && !addAnimationLoadingCompleted,
                          <Button css={{width: '108px', height: '35px'}}>
                            <div ref={animRef} css={{width: '110px', height: '34px'}} />
                          </Button>,
                        )}
                        {RIF(
                          !addAnimationLoading && addAnimationLoadingCompleted,
                          <div
                            css={{
                              borderRadius: '5px',
                              width: '110px',
                              height: '33px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: color.taskGreen,
                            }}
                          >
                            <img src={TickWhiteIcon} width='18' height='18' />
                            <span css={{color: color.white, marginLeft: '8px'}}>Added</span>
                          </div>,
                        )}
                      </>,
                    )}
                  </>,
                )}
              </>,
            )}

            {/* coming soon button */}
            {/* {RIF(
              device === 'device that is coming soon',
              <Button data-testid={`coming_soon_${device}`} btnPadding='medium' disabled>
                Coming Soon
              </Button>,
            )} */}
          </div>
        </div>
        {RIF(device === 'dexcom',
          <div css={{
            width: '100%',
            borderRadius: '8px',
            background: color.background,
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            marginTop: '16px',
          }}>
            <img src={WarningGreyIcon} width={13.33}/>
            <p css={{
              fontWeight: fontWeight.medium,
              color: color.grey_600,
              marginLeft: '8px',
            }}>
              This device requires approval from Dexcom. Once added, we will guide you through the Dexcom approval process. 
              <a target='_blank' href={learnMoreLink} css={{marginLeft: '5px', fontWeight: fontWeight.thick, color: color.primary}}>Learn more</a>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
