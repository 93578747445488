import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doBATCH_ADD_PARTICIPANT_LIST} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_PARTICIPANT_BATCH_CREATE = 'REQUEST_PARTICIPANT_BATCH_CREATE'
export const doREQUEST_PARTICIPANT_BATCH_CREATE = createDoRequestAction(REQUEST_PARTICIPANT_BATCH_CREATE)

export const requestParticipantBatchCreateActionCreators = {
  doREQUEST_PARTICIPANT_BATCH_CREATE,
}

export function* requestParticipantBatchCreate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestParticipantCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        batchId: v.string().uuid().exist(),
        baseInsignia: v.string().optional().allow('', null),
        amount: v.number().required()
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_BATCH_CREATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {batchId, baseInsignia, amount} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v2/web/participant-batch-create`,
    accessToken,
    data: {
      batchId,
      baseInsignia,
      amount,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PARTICIPANT_BATCH_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v2/web/participant-batch-create',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doBATCH_ADD_PARTICIPANT_LIST({
      batchId: payload.batchId,
      participantList: result.payload.participantList,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PARTICIPANT_BATCH_CREATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* participantBatchCreateSaga() {
  yield takeEvery(REQUEST_PARTICIPANT_BATCH_CREATE as any, requestParticipantBatchCreate)
}
