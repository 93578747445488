import {Line, LineConfig} from '@ant-design/charts'
import {Chart} from '@antv/g2/lib'
import {ChartDataProps, DailyChartDataWithType} from '../../../model/chart'
import {dailyGarminHeartRateTheme} from '../theme/daily_garmin_heart_rate_theme'
import {ChartCard} from './chart_card'
import {ArrayDescriptionType} from '../../../lib/chart_data/model'
import {defaultDailyChartXAxisConfig} from '../../../lib/chart_data/util/formatter'
import {useEffect, useMemo, useState} from 'react'

export const DailyArrayDescriptionChart = (props: ChartDataProps<DailyChartDataWithType<ArrayDescriptionType>>) => {
  const {height, width, data, title, yAxisTitle, yAxisMin, yAxisMax} = props
  const [chartInstance, setChartInstance] = useState<Chart | undefined>()
  const xField: keyof DailyChartDataWithType = 'yymmdd'
  const yField: keyof DailyChartDataWithType = 'value'
  const seriesField: keyof DailyChartDataWithType = 'type'
  const yAxisConfig = {
    title: {text: yAxisTitle},
    min: yAxisMin,
    max: yAxisMax,
    nice: true,
  }
  useEffect(() => {
    if (chartInstance) {
      chartInstance.changeData(data)
    }
  }, [chartInstance, data])

  const config = useMemo<LineConfig>(() => {
    return {
      data: [],
      autoFit: true,
      padding: 'auto',
      xField,
      yField,
      xAxis: defaultDailyChartXAxisConfig,
      yAxis: yAxisConfig,
      seriesField,
      point: {},
      legend: {position: 'bottom-right'},
      theme: dailyGarminHeartRateTheme,
      onReady: (chart) => {
        setChartInstance(chart.chart)
      },
    }
  }, [])

  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: title ?? '',
    hintDescription: '',
    chart: <Line {...config} />,
  }

  return <ChartCard {...chartCardProps} />
}
