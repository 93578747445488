import {ChartConfig, TimeSeriesChartType} from '../template/time_series_data_chart'
import {DailyTimeSeriesDataChart, DailyTimeSeriesChartProps} from '../template/daily_time_series_chart'
import {DataConverter, DataConverterOption, GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import {TimeSeriesDataSource} from '../../../model'
import {VisualizerGraphDataType} from '../../../shared/db'

export const TimeSeriesStepChart = (props: DailyTimeSeriesChartProps) => {
  const {height, width, participantId, plotDataRange, commonTaskDataMarkerList} = props

  const makeUpPointsUsingPreviousValue: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    const result = plotData.map((item) => {
      return {
        t: item.t,
        v: item.total,
      }
    })
    if (result.length > 0) {
      for (let i = 1; i < result.length; i++) {
        if (!result[i].v) {
          result[i].v = result[i - 1].v
        }
      }
    }
    return result
  }

  const defaultDataConverterOption: DataConverterOption = {
    label: 'steps/min',
    value: 'steps/min',
  }

  const dataConverterOptions: DataConverterOption[] = [
    defaultDataConverterOption,
    {
      label: 'total steps',
      value: 'total steps',
      dataConverter: makeUpPointsUsingPreviousValue,
    },
  ]

  const dataConfig = {
    defaultDataConverterOption,
    dataConverterOptions,
  }

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Column,
  }

  const chartCardConfig = {
    height,
    width,
    title: 'Step',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const timeSeriesChartProp = {
    dataType: VisualizerGraphDataType.GarminDirectSteps,
    config,
    participantId,
    plotDataRange,
    commonTaskDataMarkerList,
  }

  return (
    <>
      <DailyTimeSeriesDataChart {...timeSeriesChartProp} />
    </>
  )
}
