import React from 'react'
import {Fieldset} from './fieldset'
import {Legend} from './legend'

/** a styled fieldset with a legend */
export const FieldsetBox = (props) => {
  const sanitizedProps = {...props}
  delete sanitizedProps.legend
  delete sanitizedProps.legendProps
  delete sanitizedProps.children
  const legendProps = {...props.legendProps}

  return (
    <Fieldset {...sanitizedProps}>
      <Legend {...legendProps}>{props.legend}</Legend>
      {props.children}
    </Fieldset>
  )
}
