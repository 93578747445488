import {selectTheme} from '../../store'
import {
  CheckDefaultIcon,
  CheckSuccessIcon,
  AboutProjectIcon,
  DeviceIcon,
  ParticipantTaskIcon,
  InstructionIcon,
  ContactsIcon,
  ProjectSetupTestIcon,
  AboutProjectGreyIcon,
  DeviceGreyIcon,
  ParticipantGreyTaskIcon,
  InstructionGreyIcon,
  ContactsGreyIcon,
} from '../../asset/image'

export const SetupProjectTag = (props) => {
  const {pad, color, fontWeight} = selectTheme()

  const {
    blockName,
    currentBlock,
    scrollBarOffset,
    aboutProjectValueFilled,
    tasksFilled,
    contactValueFilled,
    instructionValueFilled,
  } = props

  const scrollTo = () => {
    let wh = window.innerHeight
    window.scrollTo({
      top: scrollBarOffset * 100 + 1 - (wh - 110) / 2,
      behavior: 'smooth',
    })
  }

  // input blockName then convert to custom render item (name, img, displayIcon)
  const nameConvertRenderItem = (name) => {
    let renderItem = {}
    switch (name) {
      case 'aboutProject':
        renderItem.name = 'About Project'
        renderItem.img = AboutProjectIcon
        renderItem.disableImg = AboutProjectGreyIcon
        renderItem.displayCheckIcon = true
        renderItem.inputCompleted = aboutProjectValueFilled
        break
      case 'device':
        renderItem.name = 'Devices and Data'
        renderItem.img = DeviceIcon
        renderItem.disableImg = DeviceGreyIcon
        renderItem.displayCheckIcon = false
        renderItem.inputCompleted = null
        break
      case 'participantTask':
        renderItem.name = 'Participant Tasks'
        renderItem.img = ParticipantTaskIcon
        renderItem.disableImg = ParticipantGreyTaskIcon
        renderItem.displayCheckIcon = true
        renderItem.inputCompleted = tasksFilled
        break
      case 'instruction':
        renderItem.name = 'Instructions'
        renderItem.img = InstructionIcon
        renderItem.disableImg = InstructionGreyIcon
        renderItem.displayCheckIcon = true
        renderItem.inputCompleted = instructionValueFilled
        break
      case 'contacts':
        renderItem.name = 'Contacts'
        renderItem.img = ContactsIcon
        renderItem.disableImg = ContactsGreyIcon
        renderItem.displayCheckIcon = true
        renderItem.inputCompleted = contactValueFilled
        break
      case 'testSetup':
        renderItem.name = 'Test Setup'
        renderItem.img = ProjectSetupTestIcon
        renderItem.disableImg = 'TODO'
        renderItem.displayCheckIcon = false
        renderItem.inputCompleted = null
        break
      default:
    }
    return renderItem
  }

  return (
    <button
      onClick={scrollTo}
      css={{
        width: '190px',
        height: '40px',
        borderRadius: '5px',
        border: 'none',
        marginBottom: '5px',
        cursor: 'pointer',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: fontWeight.bold,
        background: `${currentBlock === blockName ? color.grey_100 : 'transparent'}`,
        color: `${currentBlock === blockName ? color.black : color.grey_400}`,
        ':hover': {
          color: color.black,
          background: color.grey_100,
        },
      }}
    >
      <img
        width='18px'
        css={{marginRight: pad.small}}
        src={
          currentBlock === blockName
            ? nameConvertRenderItem(blockName)?.img
            : nameConvertRenderItem(blockName)?.disableImg
        }
      />

      {nameConvertRenderItem(blockName)?.name}

      <img
        width='12px'
        css={{marginLeft: pad.small, display: nameConvertRenderItem(blockName)?.displayCheckIcon ? 'block' : 'none'}}
        src={nameConvertRenderItem(blockName)?.inputCompleted ? CheckSuccessIcon : CheckDefaultIcon}
      />
    </button>
  )
}
