import React from 'react'
import {selectTheme} from '../../store'
import {CheckSuccessIcon} from '../../asset/image'

import {Legend, Button} from '..'

export const PopupSuccess = (props) => {
  const {color, fontWeight} = selectTheme()

  return (
    <div
      {...props}
      css={{
        top: 'calc(50vh - 200px)',
        left: 'calc(50vw - 300px)',
        position: 'fixed',
        width: '600px',
        borderRadius: '5px',
        padding: '40px 50px',
        background: color.white,
      }}
    >
      <div css={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '200px'}}>
        <div>
          <img src={CheckSuccessIcon} width='25px' height='25px' />
          <div css={{margin: '15px 0'}}>
            <Legend>{props.title}</Legend>
            <div css={{margin: '15px 0'}}>{props.description}</div>
          </div>
        </div>
        <div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              margin: '0 20px',
            }}
          >
            <Button onClick={props.onSubmit}>{props.buttonText}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
