import {useState, useEffect, Dispatch, SetStateAction, ChangeEvent, CSSProperties} from 'react'
import {
  RIF, 
  sortBy, 
  _
} from '../../lib'
import {selectTheme, selectMethod} from '../../store'
import {GarminGIcon, GarminLIcon} from '../../asset/image'
import {Checkbox} from '..'
import {
  TaskStateType,
  SelectTaskListItem,
  HandleToggleTaskPayload,
  GarminDirect,
  TaskType,
  getTaskContentName
} from '../../model'

export interface ViewMenuPropsT {
  viewBy: 'DeviceDataType' | 'Tasks'
  setViewBy: Dispatch<SetStateAction<'DeviceDataType' | 'Tasks'>>
  viewTagList: any[]
  setViewTagList: Dispatch<SetStateAction<any[]>>
  viewDatatype: GarminDirect
  setViewDatatype: Dispatch<SetStateAction<GarminDirect>>
  hasGarminDirect: boolean
  viewBtnPosition: Record<string, number>
}

export const ViewMenu = (props: ViewMenuPropsT) => {
  const {color, colorTranslator, fontWeight} = selectTheme()

  const {
    viewBy, 
    setViewBy, 
    viewTagList, 
    setViewTagList, 
    viewDatatype, 
    setViewDatatype, 
    hasGarminDirect,
    viewBtnPosition,
  } = props

  const method = selectMethod()
  const garminConnect: boolean = method?.garminConnectEnable
  const garminDeviceTask: TaskStateType | any = _.find(method?.taskList, {type: TaskType.GarminDevice}) // TODO: check type
  const methodTaskListWithoutGarmin: TaskStateType[] = method?.taskList
    ?.filter((item: TaskStateType) => item.type !== TaskType.GarminDevice && item.enabled)
    ?.sort(sortBy('index'))
  const [selectTaskList, setSelectTaskList] = useState<SelectTaskListItem[]>([])
  const [garminDirectList, setGarminDirectList] = useState<GarminDirect[]>([])
  const GarminDirectypeRef: Record<string, GarminDirect> = {
    heartRateEnable: 'Heart Rate',
    pulseOxEnable: 'Oxygen Saturation',
    stressEnable: 'Stress',
    stepsEnable: 'Steps',
    respirationEnable: 'Respiration',
    bbiEnable: 'Heart Rate Interval (BBI)',
    zeroCrossingEnable: 'Zero Crossings',
    actigraphyEnable: 'Actigraphy',
  }

  // get available item and change name then store in list
  useEffect(() => {
    if (hasGarminDirect) {
      Object.keys(garminDeviceTask?.garminDevice).map((key) => {
        if (garminDeviceTask?.garminDevice[key] == true && !!GarminDirectypeRef[key]) {
          setGarminDirectList((prev) => [...prev, GarminDirectypeRef[key]])
        }
      })
    }
  }, [])

  useEffect(() => {
    setViewDatatype(garminDirectList[0])
  }, [garminDirectList])

  useEffect(() => {
    let taskList: { id: string; name: string; color: any; index: number }[] = []
    if(method?.dexcomIntegrationId) {
      taskList.push({
        id: method.dexcomIntegrationId,
        name: 'Dexcom App',
        color: color.dexcomGreen,
        index: 0,
      })
    }

    const sanitizedMethodTaskListWithoutGarmin = methodTaskListWithoutGarmin?.map(
      (item: TaskStateType, index: number) => ({
        id: item.id,
        name: item[getTaskContentName(item.type)].name,
        color: item.color ? `#${item.color}` : colorTranslator(index),
        index: item.index || index,
      }),
    )
    taskList = taskList.concat(sanitizedMethodTaskListWithoutGarmin)

    setSelectTaskList((oldArr) => [...oldArr, ...taskList])
  }, [])

  // add or remove task depend on target in viewTagList or not
  const handleToggleTask = (e: ChangeEvent<HTMLInputElement>, obj: HandleToggleTaskPayload) => {
    // edge case: if diff task have same name, should compare by 'id'
    if (obj.type === 'task') {
      const targetInList = viewTagList.some((item) => item.id === obj?.payload?.id)
      if (e && !targetInList) return setViewTagList((oldArr) => [...oldArr, obj?.payload])
      if (viewTagList?.length === 1) return // if last tag not allow to remove
      if (!e && targetInList) return setViewTagList(viewTagList.filter((value) => value.id !== obj?.payload?.id))
    }
    // will not exit 2 garmin in once, so can compare by 'name'
    if (obj.type === 'garmin') {
      const targetInList = viewTagList.some((item) => item.name === obj?.payload?.name)
      if (e && !targetInList) return setViewTagList((oldArr) => [...oldArr, obj?.payload])
      if (viewTagList?.length === 1) return // if last tag not allow to remove
      if (!e && targetInList) return setViewTagList(viewTagList.filter((value) => value.name !== obj?.payload?.name))
    }
  }

  const handleDisableCheck = (obj: HandleToggleTaskPayload) => {
    if (viewTagList?.length !== 1) return false
    if (obj.type === 'task' && viewTagList?.length === 1) return viewTagList?.some((item) => item.id === obj.payload.id)
    if (obj.type === 'garmin' && viewTagList?.length === 1) {
      return viewTagList?.some((item) => item.name === obj.payload.name)
    }
  }

  return (
    <div
      id='viewMenu'
      css={{
        position: 'fixed',
        left: `${viewBtnPosition.xPos}px`,
        top: `${viewBtnPosition.yPos + 32}px`,
        zIndex: 100,
        width: '315px',
        border: `1px solid ${color.disabled}`,
        boxShadow: '0px 4px 10px 1px rgba(104, 104, 104, 0.25)',
        borderRadius: '5px',
        background: color.white,
        cursor: 'auto',
        padding: '20px',
        paddingBottom: '5px',
      }}
    >
      {/* switch */}
      <div css={{borderBottom: `1px solid ${color.grey_100}`, display: 'flex', marginBottom: '20px'}}>
        <div
          onClick={() => setViewBy('Tasks')}
          style={
            {
              color: viewBy === 'Tasks' && color.primary,
              borderBottom: viewBy === 'Tasks' && `2px solid ${color.primary}`,
              paddingBottom: '15px',
              fontWeight: fontWeight.bold,
              marginRight: '30px',
              cursor: 'pointer',
            } as CSSProperties
          }
        >
          By Tasks
        </div>
{/* 
        {RIF(
          hasGarminDirect,
          <div
            onClick={() => setViewBy('DeviceDataType')}
            style={
              {
                color: viewBy === 'DeviceDataType' && color.primary,
                borderBottom: viewBy === 'DeviceDataType' && `2px solid ${color.primary}`,
                paddingBottom: '15px',
                fontWeight: fontWeight.bold,
                marginRight: '30px',
                cursor: 'pointer',
              } as CSSProperties
            }
          >
            By Device Data Type
          </div>,
        )} */}
      </div>

      {RIF(
        viewBy === 'Tasks',
        <div>
          {RIF(
            garminConnect,
            <label
              css={{
                display: 'flex',
                alignItems: 'start',
                width: 'fit-content',
                marginBottom: '15px',
                cursor: 'pointer',
              }}
            >
              <Checkbox
                value={viewTagList?.some((obj) => obj.name === 'Garmin Connect App')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleToggleTask(e, {
                    type: 'garmin',
                    payload: {
                      name: 'Garmin Connect App',
                      color: color.black,
                    },
                  })
                }
                disableCheck={handleDisableCheck({
                  type: 'garmin',
                  payload: {
                    name: 'Garmin Connect App',
                    color: color.black,
                  },
                })}
              />
              <div css={{margin: 'auto 6px', marginTop: '-2px'}}>Garmin Connect App</div>
              <div
                css={{
                  borderRadius: '50%',
                  width: '13px',
                  height: '13px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={GarminGIcon} width='13' />
              </div>
            </label>,
          )}

          {RIF(
            hasGarminDirect,
            <label
              css={{
                display: 'flex',
                alignItems: 'start',
                width: 'fit-content',
                marginBottom: '15px',
                cursor: 'pointer',
              }}
            >
              <Checkbox
                value={viewTagList?.some((obj) => obj.name === 'Labfront Companion App')}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleToggleTask(e, {
                    type: 'garmin',
                    payload: {
                      name: 'Labfront Companion App',
                      id: garminDeviceTask?.id,
                      color: color.primary,
                    },
                  })
                }
                disableCheck={handleDisableCheck({
                  type: 'garmin',
                  payload: {
                    name: 'Labfront Companion App',
                    id: garminDeviceTask?.id,
                    color: color.primary,
                  },
                })}
              />
              <div css={{margin: 'auto 6px', marginTop: '-2px'}}>Labfront Companion App</div>
              <div
                css={{
                  background: color.primary,
                  color: color.white,
                  borderRadius: '50%',
                  fontSize: '7px',
                  width: '13px',
                  height: '13px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '2px',
                  paddingBottom: '1px',
                  justifyContent: 'center',
                }}
              >
                <img src={GarminLIcon} width='13' />
              </div>
            </label>,
          )}

          {RIF(
            selectTaskList,
            selectTaskList?.map((item) => (
              <label
                key={item.id}
                css={{
                  display: 'flex',
                  alignItems: 'start',
                  width: 'fit-content',
                  marginBottom: '15px',
                  cursor: 'pointer',
                }}
              >
                <Checkbox
                  value={viewTagList?.some((obj) => obj.id === item.id)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleToggleTask(e, {type: 'task', payload: item})}
                  disableCheck={handleDisableCheck({type: 'task', payload: item})}
                />
                <div css={{margin: 'auto 6px', maxWidth: '89%', marginTop: '-2px'}}>{item.name}</div>
              </label>
            )),
          )}
        </div>,
      )}

      {RIF(
        viewBy === 'DeviceDataType' && hasGarminDirect,
        <div>
          <div>Below are data type collected from Labfront Companion App:</div>
          <div css={{display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
            {garminDirectList?.map((item, index) => (
              <label key={index} css={{marginBottom: '15px', cursor: 'pointer'}}>
                <input
                  type='radio'
                  name='dataType'
                  defaultChecked={item === viewDatatype}
                  onClick={(e: any) => setViewDatatype(e?.target?.labels[0]?.innerText)}
                />
                {item}
              </label>
            ))}
          </div>
        </div>,
      )}
    </div>
  )
}
