import {Chart} from '@antv/g2/lib'
import {ScaleOption} from '@antv/g2/lib/interface'
import {TimeSeriesDataSource} from '../../../model/chart'
import {useEffect, useState} from 'react'
// import {selectCompletionStreamDataMetadataMap} from '../../../store'

import _ from 'lodash'

import {getStreamDataFromDb} from '../../../store/db/db_data_getter'
import {GarminDeviceStreamDataType, MovesenseDeviceStreamDataType} from '../../../shared/mongo'
import {TransformedStreamDataForPlot} from '../../../store/db/db_data_setter'
import {
  CommonTaskDataMarker,
  GenericTimeSeriesDataChartConfig,
  TimeSeriesDataChart,
  sliceTimeSeriesDataByPlotTimeRange,
} from './time_series_data_chart'
import {TimeSeriesXyzChart} from './time_series_xyz_chart'
import { selectCompletionStreamDataMetadataMap } from '../../../store/state'
import { VisualizerGraphDataType } from '../../../shared/db'

export interface StreamDataChartProps {
  height: string
  width: string
  completionIdList: string[]
  plotDataRange?: [Date, Date]
  commonTaskDataMarkerList?: CommonTaskDataMarker[]
}

export type StreamDataType = GarminDeviceStreamDataType | MovesenseDeviceStreamDataType

export interface GenericStreamDataChartProps {
  dataType: VisualizerGraphDataType
  config: GenericTimeSeriesDataChartConfig
  completionIdList: string[]
  plotDataRange?: [Date, Date]
  commonTaskDataMarkerList?: CommonTaskDataMarker[]
  chartReadyCallback?: (chart: Chart) => void
}

export interface GenericStreamXyzChartProps extends GenericStreamDataChartProps {
  defaultScaleOption: ScaleOption
}

export const StreamDataChart = (props: GenericStreamDataChartProps | GenericStreamXyzChartProps) => {
  const {dataType, config, completionIdList, plotDataRange, commonTaskDataMarkerList, chartReadyCallback} = props
  const completionStreamDataMetadataMap = selectCompletionStreamDataMetadataMap()
  const [timeSeriesData, setTimeSeriesData] = useState<TimeSeriesDataSource[]>([])

  const loadDataFromDbToPlot = async (plotTimeRange: [number, number]) => {
    const plotStartTime = plotTimeRange[0]
    const plotEndTime = plotTimeRange[1]
    let newPlotData: TimeSeriesDataSource[] = []

    for (const completionId of completionIdList) {
      // const typedStreamDataMetadata = completionStreamDataMetadataMap.get(completionId)
      const typedStreamDataMetadata = completionStreamDataMetadataMap[completionId]
      if (typedStreamDataMetadata) {
        const streamDataMetadata = typedStreamDataMetadata[dataType]
        if (streamDataMetadata) {
          const requestObjectIdList: string[] = []
          for (const [objectId, metadata] of Object.entries(streamDataMetadata)) {
            if (metadata.dbInserted) {
              if (!(metadata.dataEndTimeStamp < plotStartTime || metadata.dataStartTimeStamp > plotEndTime)) {
                requestObjectIdList.push(objectId)
              }
            }
          }
          if (requestObjectIdList.length > 0) {
            const recordList: TransformedStreamDataForPlot[] = []

            for (const objectId of requestObjectIdList) {
              const streamDataRecord = await getStreamDataFromDb(objectId)
              if (streamDataRecord) {
                recordList.push(streamDataRecord)
              }
            }

            const dataToPlot = recordList
              .sort((a, b) => {
                return a.dataStartUnixTime - b.dataStartUnixTime
              })
              .reduce((acc, record) => {
                const dataChunk = sliceTimeSeriesDataByPlotTimeRange(record.timeLocalizedData, plotTimeRange)
                const newChunk = acc.concat(dataChunk)
                return newChunk
              }, [] as TimeSeriesDataSource[])

            newPlotData = newPlotData.concat(dataToPlot)
          }
        }
      }
    }
    setTimeSeriesData(newPlotData)
  }

  useEffect(() => {
    if (plotDataRange === undefined || !completionStreamDataMetadataMap) return

    const plotStartTime = plotDataRange[0].getTime()
    const plotEndTime = plotDataRange[1].getTime()
    loadDataFromDbToPlot([plotStartTime, plotEndTime])
  }, [completionStreamDataMetadataMap, completionIdList, plotDataRange])

  const XyzDataTypeList = [
    VisualizerGraphDataType.GarminDirectAccelerometerRaw,
    VisualizerGraphDataType.MovesenseIMU,
  ]

  const TimeSeriesChartByDataType = () => {
    if (XyzDataTypeList.includes(dataType)) {
      const {defaultScaleOption} = props as GenericStreamXyzChartProps
      return (
        <>
          <TimeSeriesXyzChart
            {...{
              defaultScaleOption,
              config,
              timeSeriesData,
              plotDataRange,
              commonTaskDataMarkerList,
              chartReadyCallback,
            }}
          />
        </>
      )
    }
    return (
      <>
        <TimeSeriesDataChart
          {...{config, timeSeriesData, plotDataRange, commonTaskDataMarkerList, chartReadyCallback}}
        />
      </>
    )
  }

  return TimeSeriesChartByDataType()
}
