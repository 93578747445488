import React from 'react'

import {selectTheme} from '../../store'

import {
  ParticipantTutorialStepOneIcon,
  ParticipantTutorialStepTwoIcon,
  ParticipantTutorialStepThreeIcon,
  DoubleRightwardArrowIcon,
} from '../../asset/image'

export const ParticipantTutorial = () => {
  const {color, fontSize, fontWeight, pad} = selectTheme()
  return (
    <>
      <div
        css={{
          width: '100%',
          padding: '24px 24px 32px 32px',
          borderRadius: '5px',
          backgroundColor: 'rgba(154, 171, 255, 0.16)',
          position: 'relative',
          marginBottom: '16px',
        }}
      >
        <div
          css={{
            width: '6px',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: color.primary,
          }}
        />
        <p
          css={{
            fontWeight: fontWeight.thick,
            fontSize: fontSize.h5,
            marginBottom: '8px',
          }}
        >
          Participant Setup Guide
        </p>
        <p
          css={{
            fontWeight: fontWeight.medium,
            marginBottom: '32px',
          }}
        >
          Ask your participants to follow the steps below to join your project:
        </p>
        <div
          css={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img width='70' src={ParticipantTutorialStepOneIcon} />
          <div>
            <p
              css={{
                fontWeight: fontWeight.thick,
                color: color.grey_600,
                marginBottom: '8px',
              }}
            >
              Step 1
            </p>
            <p
              css={{
                width: '11.429rem',
                fontWeight: fontWeight.medium,
              }}
            >
              Download the Labfront Companion app{' '}
              <a
                src={'#'}
                css={{
                  color: color.primary,
                  fontWeight: fontWeight.thick,
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                here
              </a>
            </p>
          </div>
          <img width='24' src={DoubleRightwardArrowIcon} />
          <img width='70' src={ParticipantTutorialStepTwoIcon} />
          <div>
            <p
              css={{
                fontWeight: fontWeight.thick,
                color: color.grey_600,
                marginBottom: '8px',
              }}
            >
              Step 2
            </p>
            <p
              css={{
                width: '11.429rem',
                fontWeight: fontWeight.medium,
              }}
            >
              Enter the 4 digit invite code into Labfront app
            </p>
          </div>
          <img width='24' src={DoubleRightwardArrowIcon} />
          <img width='70' src={ParticipantTutorialStepThreeIcon} />
          <div>
            <p
              css={{
                fontWeight: fontWeight.thick,
                color: color.grey_600,
                marginBottom: '8px',
              }}
            >
              Step 3
            </p>
            <p
              css={{
                width: '11.429rem',
                fontWeight: fontWeight.medium,
              }}
            >
              Follow the instructions to complete setup
            </p>
          </div>
        </div>
      </div>
      <p
        css={{
          color: color.grey_600,
          fontWeight: fontWeight.medium,
        }}
      >
        Note: We recommend you try this yourself before inviting participants.
      </p>
    </>
  )
}
