import {useEffect, useState} from 'react'
import {ScheduleType} from '../../shared/db'
import {LocalSchedule} from './add_schedule_pop'
import {t} from '../../lib'
import {TaskScheduleSelectionChildProps, TaskScheduleSelectionType} from './task_schedule_type_selection'
import {Select} from '../atoms'
import {selectTheme} from '../../store'

interface ParticipantJoinedXDayOption {
  label: string
  value: number
}

export const TaskScheduleParticipantJoinedXDaySelection = (
  props: TaskScheduleSelectionChildProps
) => {
  const {
    selected,
    scheduleType,
    taskSchedules,
    setTaskSchedules
  } = props
  const {fontSize} = selectTheme()
  const recurrentXDayOptions: ParticipantJoinedXDayOption[] = [
    { label: "Day 1 (The day they join the project)", value: 1 },
    { label: "Day 2", value: 2 },
    { label: "Day 3", value: 3 },
    { label: "Day 4", value: 4 },
    { label: "Day 5", value: 5 },
    { label: "Day 6", value: 6 },
    { label: "Day 7", value: 7 },
    { label: "Day 8", value: 8 },
    { label: "Day 9", value: 9 },
    { label: "Day 10", value: 10 },
  ]
  const defaultParticipantJoinedXDay: ParticipantJoinedXDayOption | null = (() => {
    if (scheduleType === TaskScheduleSelectionType.ParticipantJoinedXDay && taskSchedules.length !== 0) {
      return recurrentXDayOptions[t.unwrap(taskSchedules[0].schedule.day) - 1]
    } else {
      return null
    }
  })()

  const [participantJoinedXDays, setParticipantJoinedXDays] = useState<ParticipantJoinedXDayOption | null>(defaultParticipantJoinedXDay)
  useEffect(() => {
    if (participantJoinedXDays) {
      const schedule: LocalSchedule = {
        type: ScheduleType.Relative,
        schedule: {
          day: participantJoinedXDays.value
        }
      }
      setTaskSchedules([schedule])
    } else {
      setTaskSchedules([])
    }
  }, [participantJoinedXDays])
  return (
    <Select
      value={selected ? participantJoinedXDays : null }
      options={recurrentXDayOptions}
      onChange={setParticipantJoinedXDays}
      css={{
        marginBottom: '16px',
        width: '100%',
        fontSize: fontSize.h7,
      }}
    />
  )
}

