import { selectTheme } from "../../store"

export const UpgradeTag = () => {
  const { color, fontSize } = selectTheme()
  return (
    <div
      css={{
        borderRadius: '2px',
        color: 'white',
        padding: '4px 6px',
        background: color.upgrade,
        fontSize: fontSize.h8,
      }}
    >
      Upgrade
    </div>
  )
}