import {DualAxes, DualAxesConfig} from '@ant-design/charts'
import {Meta} from '@antv/g2plot'

import {dailyGarminSleepTheme} from '../theme/daily_garmin_sleep_theme'
import {ChartCard} from '../template/chart_card'
import {ChartDataProps, DailyTwoValueChartData} from '../../../model/chart'
import {defaultDailyChartXAxisConfig} from '../../../lib/chart_data/util/formatter'

export const GarminDailySleepChart = (props: ChartDataProps<DailyTwoValueChartData>) => {
  const {height, width, data} = props
  const durationInHourData = data.map((item) => {
    if (item.value) {
      const durationInHour = +(item.value / 3600).toFixed(2)
      return {yymmdd: item.yymmdd, value: durationInHour, value2: item.value2}
    }
    return item
  })
  const xField: keyof DailyTwoValueChartData = 'yymmdd'
  const yField: (keyof DailyTwoValueChartData)[] = ['value', 'value2']
  const meta: {[key in keyof DailyTwoValueChartData]: Meta} = {
    yymmdd: {
      alias: 'Date',
    },
    value: {
      alias: 'sleep duration',
    },
    value2: {
      alias: 'sleep score',
    },
  }
  const yAxisConfigForValue1 = {
    title: {text: 'Hours'},
  }
  const yAxisConfigForValue2 = {
    title: {text: 'Score'},
    min: 30,
    max: 100
  }

  const config: DualAxesConfig = {
    data: [durationInHourData, durationInHourData],
    autoFit: true,
    theme: dailyGarminSleepTheme,
    legend: {position: 'bottom-right'},
    xField,
    yField,
    xAxis: defaultDailyChartXAxisConfig,
    yAxis: [yAxisConfigForValue1, yAxisConfigForValue2],
    meta,
    geometryOptions: [
      {
        geometry: 'column',
      },
      {
        geometry: 'line',
        point: {},
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  }

  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: 'Garmin Connect Sleep Staging',
    hintDescription: '',
    chart: <DualAxes {...config} />,
  }

  return <ChartCard {...chartCardProps} />
}
