import {Column, ColumnConfig} from '@ant-design/charts'
import {Chart} from '@antv/g2/lib'
import {ChartDataProps, DailyChartDataWithType} from '../../../model/chart'
import {dailyGarminHeartRateTheme} from '../theme/daily_garmin_heart_rate_theme'
import {ChartCard} from '../template/chart_card'
import {CaloriesType} from '../../../lib/chart_data/model'
import {defaultDailyChartXAxisConfig} from '../../../lib/chart_data/util/formatter'
import {useEffect, useMemo, useState} from 'react'

export const GarminDailySummaryCaloriesChart = (props: ChartDataProps<DailyChartDataWithType<CaloriesType>>) => {
  const {height, width, data} = props
  const [chartInstance, setChartInstance] = useState<Chart | undefined>()

  const xField: keyof DailyChartDataWithType = 'yymmdd'
  const yField: keyof DailyChartDataWithType = 'value'
  const seriesField: keyof DailyChartDataWithType = 'type'
  const yAxisConfig = {
    title: {text: 'KiloCalories'},
  }

  useEffect(() => {
    if (chartInstance) {
      chartInstance.changeData(data)
    }
  }, [chartInstance, data])

  const columnConfig: ColumnConfig = {
    data: [],
    autoFit: true,
    padding: 'auto',
    xField,
    yField,
    xAxis: defaultDailyChartXAxisConfig,
    yAxis: yAxisConfig,
    seriesField,
    legend: {position: 'bottom-right'},
    theme: dailyGarminHeartRateTheme,
    color: (d) => {
      return '#F5AE9F'
      // return d.type === CaloriesType.Active ? '#0f759c' : d.type === CaloriesType.BMR ? '#26a2cb' : '#2b6fd5'
    },
    onReady: (chart) => {
      setChartInstance(chart.chart)
    },
  }

  const config = useMemo<ColumnConfig>(() => {
    return columnConfig
  }, [])

  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: 'Garmin Connect Calories',
    hintDescription: '',
    chart: <Column {...config} />,
  }

  return <ChartCard {...chartCardProps} />
}
