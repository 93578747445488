import {Dispatch, SetStateAction, useRef, useState} from 'react'
import {selectTheme} from '../../store'
import {RIF, _, useClickOutside} from '../../lib'
import {ButtonMore, LocalSchedule} from '..'
import {CalendarIcon} from '../../asset/image'
import {ScheduleRemovePop} from '../organisms/schedule_remove_pop'

export interface ScheduleTagPropsT {
  setDisplaySchedulePop: Dispatch<SetStateAction<boolean>>
  setEditingScheduleList: Dispatch<SetStateAction<LocalSchedule[] | undefined>>
  setAllScheduleList: Dispatch<SetStateAction<LocalSchedule[][]>>
  allScheduleList: LocalSchedule[][]
  scheduleList: LocalSchedule[]
  index: number
}

export const ScheduleTag = (props: ScheduleTagPropsT) => {
  const {color} = selectTheme()
  const {
    setDisplaySchedulePop,
    setEditingScheduleList,
    scheduleList,
    index,
    setAllScheduleList,
    allScheduleList
  } = props
  const [displayBrick, setDisplayBrick] = useState(false)
  const brickRef = useRef<HTMLInputElement | null>(null)
  useClickOutside(brickRef, () => setDisplayBrick(false))
  const [displayRemovePop, setDisplayRemovePop] = useState(false)

  const handleRemoveSchedule = () => {
    const cloned = _.cloneDeep(allScheduleList)
    cloned.splice(index, 1)
    setAllScheduleList(cloned)
  }

  return (
    <div
      data-testid='schedule_tag'
      onClick={(e: any) => {
        if (e.target.id === 'removePopBackgroundId') return e.preventDefault()
        setDisplaySchedulePop(true)
        setEditingScheduleList(scheduleList)
      }}
      key={index}
      css={{
        width: '100%',
        height: '70px',
        borderRadius: '5px',
        background: color.white,
        padding: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
        cursor: 'pointer',
        ':hover': {
          background: color.hover,
        },
      }}
    >
      <div css={{display: 'flex', alignItems: 'center'}}>
        <img src={CalendarIcon} width={20} height={20} />
        <div css={{marginLeft: '16px'}}>
          <p css={{color: color.grey_400, fontSize: '12px', marginBottom: '4px'}}>Task Schedule {index + 1}</p>
        </div>
      </div>

      <span
        ref={brickRef}
        css={{
          color: color.grey_400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '24px',
          position: 'relative',
          cursor: 'pointer',
          borderRadius: '4px',
        }}
      >
        <ButtonMore
          type='button'
          onClick={(e: any) => {
            setDisplayBrick(!displayBrick)
            e.stopPropagation()
          }}
        />
        {RIF(
          displayBrick,
          <div
            css={{
              width: '88px',
              height: 'fit-content',
              padding: '5px 00px',
              border: `1px solid ${color.grey_160}`,
              borderRadius: '5px',
              position: 'absolute',
              right: 0,
              top: 25,
              background: color.white,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              onClick={(e: any) => {
                setDisplayRemovePop(true)
                e.stopPropagation()
              }}
              id='displayRemoveBrickId'
              css={{
                width: '100%',
                padding: '6px',
                textAlign: 'center',
                ':hover': {
                  background: 'rgba(156, 158, 174, 0.05)',
                },
              }}
            >
              Remove
            </div>
          </div>,
        )}
      </span>

      {RIF(
        displayRemovePop,
        <ScheduleRemovePop
          onClick={(e: any) => e.stopPropagation()}
          {...{
            closeAction: () => setDisplayRemovePop(false),
            doAction: handleRemoveSchedule,
          }}
        />,
      )}
    </div>
  )
}
