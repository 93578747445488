import {selectTheme} from '../../store'
import {useTranslation} from '../../lib'
import {Tooltip} from '..'
import {HeartRateIcon} from '../../asset/image'

export const PolarSpecPanel = (props) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()
  const {t} = useTranslation()

  return (
    <div
      css={{
        width: '100%',
        borderRadius: '5px',
        padding: pad.xl,
        background: color.white,
      }}
    >
      <p
        css={{
          fontWeight: fontWeight.bold,
          marginBottom: pad.large,
        }}
      >
        Setup Data Type and Sampling Rate
      </p>
      <div
        css={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: pad.small,
        }}
      >
        <p
          css={{
            color: color.grey_400,
          }}
        >
          Data Type
        </p>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            css={{
              color: color.grey_400,
              marginRight: pad.small,
            }}
          >
            Sampling Rate
          </p>
          <Tooltip />
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: `${pad.medium} ${pad.large}`,
          border: `1px solid ${color.grey_160}`,
          borderRadius: '5px',
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src={HeartRateIcon}
            width='40'
            css={{
              marginRight: pad.large,
            }}
          />
          <div>
            <p
              css={{
                fontWeight: fontWeight.bold,
                marginBottom: pad.xs,
              }}
            >
              Heart Rate Interval
            </p>
            <p>
              Also known as beat to beat interval (BBI), interbeat interval (IBI), or pulse to pulse interval (PPI).
            </p>
          </div>
        </div>
        <p
          css={{
            fontWeight: fontWeight.bold,
          }}
        >
          Each beat
        </p>
      </div>
    </div>
  )
}
