import { IEntityModel } from './base'

export enum ProjectScheduleFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
}

export enum ProjectScheduleReference {
  Absolute = 'absolute',
  Participant = 'participant',
}

export interface IProjectScheduleConfig extends IEntityModel {
  projectId: string
  frequency?: ProjectScheduleFrequency | null
  reference?: ProjectScheduleReference | null
  absoluteReferenceYymmdd?: number | null
}

export function validateScheduleConfig(arg: Partial<IProjectScheduleConfig>) {
  const { frequency, reference, absoluteReferenceYymmdd } = arg
  switch (frequency) {
    case ProjectScheduleFrequency.Daily: {
      if (reference || absoluteReferenceYymmdd) {
        throw new Error(
          'daily schedule does not support setting reference or reference date',
        )
      }
      break
    }
    case ProjectScheduleFrequency.Weekly: {
      switch (reference) {
        case ProjectScheduleReference.Absolute: {
          if (!absoluteReferenceYymmdd) {
            throw new Error(
              'absolute weekly schedule must provide yymmdd reference date',
            )
          }
          break
        }
        case ProjectScheduleReference.Participant: {
          if (absoluteReferenceYymmdd) {
            throw new Error(
              'participant weekly schedule does not support setting yymmdd reference date',
            )
          }
          break
        }
        default:
          throw new Error('unhandled schedule reference')
      }
      break
    }
    default: {
      if (!frequency) {
        if (reference || absoluteReferenceYymmdd) {
          throw new Error(
            'null schedule does not support setting reference or reference date',
          )
        }
      } else {
        throw new Error('unhandled schedule frequency')
      }
    }
  }
}
