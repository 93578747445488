import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { selectTheme, selectWorkspaces } from "../../store"
import { IParticipant } from "../../shared/db"
import { Button, ButtonCancel, Checkbox, AdherenceOverviewDataType } from ".."
import { CalendarGreyIcon, MessageWhiteIcon } from '../../asset/image/icon'
import { Scrollbars } from 'react-custom-scrollbars-2'
import {RIF, _, useCurrentWorkspaceState} from '../../lib'

interface SendNotificationParticipantSelectorProps {
  participantList: IParticipant[]
  unfold: boolean
  setRenderParticipantSelector: Dispatch<SetStateAction<boolean>>
  setSendingList: Dispatch<SetStateAction<string[]>>
  setDisplayPopupSendNotification: Dispatch<SetStateAction<boolean>>
  dataCollectedParticipantList: string[]
  noDataYetParticipantList: string[]
  participantSelectorHeader: string
  participantSelectorDate: string
  participantSelectorDatatype: AdherenceOverviewDataType
}

export const SendNotificationParticipantSelector = (
  props: SendNotificationParticipantSelectorProps
) => {
  const {
    participantList, 
    unfold, 
    setRenderParticipantSelector, 
    setSendingList,
    setDisplayPopupSendNotification,
    dataCollectedParticipantList,
    noDataYetParticipantList,
    participantSelectorHeader,
    participantSelectorDate,
    participantSelectorDatatype
  } = props
  const {color, fontSize, fontWeight} = selectTheme()
  const workspacesState = selectWorkspaces()
  const { workspaceId } = useCurrentWorkspaceState()
  const role = workspacesState.roleInWorkspaces[workspaceId ?? ''] || 'owner'
  const [tab, setTab] = useState<string>('noDataYet')
  useEffect(() => {
    if (participantSelectorDatatype === AdherenceOverviewDataType.JoinedProject) return setTab('dataCollected')
    return setTab('noDataYet')
  }, [participantSelectorDatatype])
  const [selectedDataCollectedParticipantList, setSelectedDataCollectedParticipantList] = useState<string[]>(dataCollectedParticipantList)
  const [selectedAllDataCollected, setSelectedAllDataCollected] = useState(true)
  const handleSelectedAllDataCollected = (e: boolean) => {
    setSelectedAllDataCollected(e)
  }

  useEffect(() => {
    if (selectedAllDataCollected) {
      return setSelectedDataCollectedParticipantList(dataCollectedParticipantList)
    }
    return setSelectedDataCollectedParticipantList([])
  }, [selectedAllDataCollected])
  const [selectedNoDataYetParticipantList, setSelectedNoDataYetParticipantList] = useState<string[]>(noDataYetParticipantList)
  const [selectedAllNoDataYet, setSelectedAllNoDataYet] = useState(true)
  const handleSelectedAllNoDataYet = (e: boolean) => {
    setSelectedAllNoDataYet(e)
  }

  useEffect(() => {
    if (selectedAllNoDataYet) {
      return setSelectedNoDataYetParticipantList(noDataYetParticipantList)
    }
    return setSelectedNoDataYetParticipantList([])
  }, [selectedAllNoDataYet])

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false)
  useEffect(() => {
    if (tab === 'dataCollected') {
      setSubmitBtnDisabled(selectedDataCollectedParticipantList.length === 0)
    } else {
      setSubmitBtnDisabled(selectedNoDataYetParticipantList.length === 0)
    }
  }, [tab, selectedNoDataYetParticipantList, selectedDataCollectedParticipantList,
  ])

  const handleSubmit = () => {
    if (tab === 'dataCollected') {
      setSendingList(dataCollectedParticipantList)
    } else {
      setSendingList(noDataYetParticipantList)
    }
    setRenderParticipantSelector(false)
    setDisplayPopupSendNotification(true)
  }
  
  const generateTagText = () => {
    if (participantSelectorDatatype === AdherenceOverviewDataType.JoinedProject) {
      return ['Joined This Project', '']
    } else if ([
      AdherenceOverviewDataType.DexcomEGV,
      AdherenceOverviewDataType.GarminConnect,
      AdherenceOverviewDataType.GarminDirect,
      AdherenceOverviewDataType.LabfronTask,
    ].includes(participantSelectorDatatype)) {
      return ['Data Collected', 'No Data Yet']
    }
    return ['Setup Completed', 'Not Setup Yet']
  }

  return (
    <div css={{
      width: unfold ? '360px' : '0',
      height: 'calc(100vh - 106px)',
      position: 'fixed',
      right: 0,
      bottom: 0,
      boxShadow: '-8px 0px 36px 0px #C0C0C040',
      transition: 'width .6s ease-in-out',
      backgroundColor: color.white,
      zIndex: 105,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }}>
      <div css={{
        width: '100%',
        padding: '16px',
        backgroundColor: color.surface.grey.dark,
      }}>
        <ButtonCancel bgColor={color.surface.grey.dark} onClick={()=>setRenderParticipantSelector(false)}/>
        <p css={{
          fontSize: fontSize.h4,
          fontWeight: fontWeight.thick,
          width: '100%',
          margin: '16px 0',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>{participantSelectorHeader}</p>
        <div css={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <img width="18" css={{marginRight: '8px'}} src={CalendarGreyIcon}/>
          <p css={{
            fontSize: fontSize.h6, 
            color: color.textIcon.secondary
          }}>{participantSelectorDate}</p>
        </div>
      </div>
      <div css={{
        padding: '8px 16px 16px 16px',
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div css={{
          display: 'flex',
          width: '100%',
          borderBottom: `1px solid ${color.border._160}`,
        }}>
          <button 
            onClick={()=>setTab('dataCollected')}
            css={{
              padding: '8px 0',
              marginRight: '32px',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              borderBottom: tab === 'dataCollected' ? `2px solid ${color.border.blue.blue}` : 'none',
              color: tab === 'dataCollected' ? color.primary : color.textIcon.secondary,
            }}
          >{`${generateTagText()[0]} (${dataCollectedParticipantList.length})`}</button>
          {RIF(
            participantSelectorDatatype !== AdherenceOverviewDataType.JoinedProject,
            <button 
              onClick={()=>setTab('noDataYet')}
              css={{
                padding: '8px 0',
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                borderBottom: tab === 'noDataYet' ? `2px solid ${color.border.blue.blue}` : 'none',
                color: tab === 'noDataYet' ? color.primary : color.textIcon.secondary,
              }}
            >{`${generateTagText()[1]} (${noDataYetParticipantList.length})`}</button>
          )}
        </div>
        <div css={{
          flex: 1,
          margin: '16px 0',
          border: `1px solid ${color.border._160}`,
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {RIF(
            tab === 'dataCollected',
            <ParticipantList {...{
              participantList,
              participantIdList: dataCollectedParticipantList,
              selectedParticipantList: selectedDataCollectedParticipantList,
              setSelectedParticipantList: setSelectedDataCollectedParticipantList,
              selectedAll: selectedAllDataCollected,
              handleSelectAll: handleSelectedAllDataCollected,
              tab,
            }}/>
          )}
          {RIF(
            tab === 'noDataYet',
            <ParticipantList {...{
              participantList,
              participantIdList: noDataYetParticipantList,
              selectedParticipantList: selectedNoDataYetParticipantList,
              setSelectedParticipantList: setSelectedNoDataYetParticipantList,
              selectedAll: selectedAllNoDataYet,
              handleSelectAll: handleSelectedAllNoDataYet,
              tab
            }}/>
          )}
        </div>
        {RIF(
          ['viewer', 'project_collaborator'].includes(role),
          <Button disabled={true} btnPadding="large" children="Project Viewers Cannot Send Messages"/>
        )}
        {RIF(
          ['owner', 'admin', 'editor'].includes(role),
          <Button 
            onClick={handleSubmit} 
            css={{width: '100%'}} 
            btnPadding="mediumSmall" 
            disabled={submitBtnDisabled}
            children={
              <span css={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <img width={18} css={{marginRight: '4px'}} src={MessageWhiteIcon}/>
                <p css={{
                  fontSize: fontSize.h6,
                  fontWeight: fontWeight.thick,
                  color: color.white,
                }}>Send Message to {tab === 'dataCollected' ? dataCollectedParticipantList.length : noDataYetParticipantList.length} selected participants</p>
              </span>
            }
          />
        )}
        
      </div>
    </div>
  )
}

interface ParticipantListProps {
  participantList: IParticipant[]
  participantIdList: string[]
  selectedParticipantList: string[]
  setSelectedParticipantList: Dispatch<SetStateAction<string[]>>
  selectedAll: boolean
  handleSelectAll: (arg: boolean) => void
  tab: string
}

const ParticipantList = (props: ParticipantListProps) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {
    participantList,
    participantIdList,
    selectedParticipantList, 
    setSelectedParticipantList,
    selectedAll, 
    handleSelectAll,
    tab,
  } = props

  useEffect(() => {
    if (selectedAll) setSelectedParticipantList(participantIdList)
  }, [participantIdList])

  const [displayParticipantList, setDisplayParticipantList] = useState<(IParticipant|undefined)[]>([])
  useEffect(() => {
    const result = participantIdList.map((participantId: string) => {
      const participant = _.find(
        participantList,
        ['id', participantId]
      )
      return participant 
    })
    setDisplayParticipantList(result)
  }, [participantIdList])
  

  return (
    <>
      <div css={{
        padding: '16px',
        borderBottom: `1px solid ${color.border._160}`,
      }}>
        <label css={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}>
          <Checkbox 
            value={selectedAll} 
            onChange={handleSelectAll}
          />
          <p css={{
            marginLeft: '8px',
            fontSize: fontSize.h7,
          }}>
            {`Select all participants with${tab === 'dataCollected' ? '' : 'out'} data`}
          </p>
        </label>
      </div>
      <div css={{
        flex: 1,
        padding: '16px',
        height: '100%',
      }}>
        <Scrollbars
          autoHide={false}
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          {displayParticipantList.map((participant: IParticipant | undefined) => (
            participant ?
            <ParticipantSelectorEntry {...{
              key: participant?.id,
              participant,
              selectedParticipantList,
              setSelectedParticipantList,
            }}/> : ''
          ))}
        </Scrollbars>
      </div>
    </>
  )
}

interface ParticipantSelectorEntryProps {
  participant: IParticipant
  selectedParticipantList: string[]
  setSelectedParticipantList: Dispatch<SetStateAction<string[]>>
  key: string
}

const ParticipantSelectorEntry = (props: ParticipantSelectorEntryProps) => {
  const {fontSize, fontWeight} = selectTheme()
  const {participant, selectedParticipantList, setSelectedParticipantList} = props
  const selected = selectedParticipantList.includes(participant.id)

  const handleSelectParticipant = (e: boolean) => {
    const result = [...selectedParticipantList]
    if (e) {
      result.push(participant.id)
    } else {
      _.remove(result, (id) => id === participant.id)
    }
    setSelectedParticipantList(result)
  }

  return (
    <label css={{
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: '16px',
      cursor: 'pointer',
    }}>
      <Checkbox 
        value={selected} 
        onChange={handleSelectParticipant}
      />
      <p css={{
        marginLeft: '8px',
        fontSize: fontSize.h7,
        fontWeight: fontWeight.medium,
      }}>
        {participant?.insignia}
      </p>
    </label>
  )
}