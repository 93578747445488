import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'
import {doREQUEST_ERROR, doREQUEST_COMPLETE, doMETHOD_UPDATE_TASK} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_TASK_GARMIN_DEVICE_UPDATE = 'REQUEST_TASK_GARMIN_DEVICE_UPDATE'
export const doREQUEST_TASK_GARMIN_DEVICE_UPDATE = createDoRequestAction(REQUEST_TASK_GARMIN_DEVICE_UPDATE)

export const requestTaskGarminDeviceUpdateActionCreators = {
  doREQUEST_TASK_GARMIN_DEVICE_UPDATE,
}

export function* requestTaskGarminDeviceUpdate({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestTaskGarminDevice')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        taskId: v.string().uuid().exist(),
        garminDevice: v.object({
          type: v.string(),
          accelerometerEnable: v.boolean(),
          zeroCrossingEnable: v.boolean(),
          stepsEnable: v.boolean(),
          stressEnable: v.boolean(),
          heartRateEnable: v.boolean(),
          pulseOxEnable: v.boolean(),
          respirationEnable: v.boolean(),
          bbiEnable: v.boolean(),
          actigraphyEnable: v.boolean().optional(),
          zeroCrossingSampleRate: v.number().min(30).max(3600).optional(),
          stressSampleRate: v.number().min(1).max(3600).optional(),
          heartRateSampleRate: v.number().min(1).max(3600).optional(),
          pulseOxSampleRate: v.number().min(1).max(3600).optional(),
          respirationSampleRate: v.number().min(1).max(3600).optional(),
          actigraphySampleRate: v.number().valid(60, 30, 15, 10).optional(),
          actigraphyZeroCrossingThreshold: v.number().min(1).max(100).optional(),
          actigraphyEnergyThreshold: v.number().min(1).max(100).optional(),
        }),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_GARMIN_DEVICE_UPDATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {taskId, garminDevice} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-garmin-device-update`,
    accessToken,
    data: {
      taskId,
      garminDevice,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_GARMIN_DEVICE_UPDATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-garmin-device-update',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  delete result.payload.enabled
  yield put(doMETHOD_UPDATE_TASK(result.payload))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_TASK_GARMIN_DEVICE_UPDATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* taskGarminDeviceUpdateSaga() {
  yield takeEvery(REQUEST_TASK_GARMIN_DEVICE_UPDATE, requestTaskGarminDeviceUpdate)
}
