import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'

export const REQUEST_PHONE_REGISTER = 'REQUEST_PHONE_REGISTER'
export const doREQUEST_PHONE_REGISTER = createDoRequestAction(REQUEST_PHONE_REGISTER)

export const requestPhoneRegisterActionCreators = {
  doREQUEST_PHONE_REGISTER,
}

export function* requestPhoneRegister({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestPhoneRegister')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        phoneNumber: v.number().exist(), // it's 'String' in API spec
        phoneCountryCode: v.number().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PHONE_REGISTER,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {phoneNumber, phoneCountryCode} = payload

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/identity-phone-register-request`,
    data: {
      phoneNumber,
      phoneCountryCode,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)

    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_PHONE_REGISTER,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/identity-phone-register-request',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  return yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_PHONE_REGISTER,
      requestId: payload.requestId,
    }, setRequestResult),
  )
}

export function* requestPhoneRegisterSaga() {
  yield takeEvery(REQUEST_PHONE_REGISTER, requestPhoneRegister)
}
