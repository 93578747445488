import React, { useRef } from 'react'
import { selectTheme } from '../../store'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'

export interface ContinuousInputFieldsProps {
  values: string[]
  numFields: number
  hasError?: boolean
  handleChange: (value: string[]) => void
}

export const ContinuousInputFields: (props: ContinuousInputFieldsProps) => EmotionJSX.Element = (props) => {
  const {color} = selectTheme()
  const {values, numFields, handleChange} = props

  const inputRefs = useRef<HTMLInputElement[]>(Array(numFields).fill(null))

  const handleInputChange = (index: number, value: string) => { 
    if (!value) return
    const newValues = [...values]
    const onlyDigits = value.replace(/\D/g, '')
    newValues[index] = onlyDigits
    handleChange(newValues)
    if (onlyDigits) focusNextInput(index)
  }

  const focusNextInput = (index: number) => {
    if (index < numFields - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus()
    }
  }

  const focusPreviousInput = (index: number) => {
    if (index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus()
    }
  }
  
  const handlePaste = (index: number, e: React.ClipboardEvent) => {
    e.preventDefault()
    const newValues = [...values]
    const pastedText = e.clipboardData.getData('text')
    if(/[^0-9]/.test(pastedText)) return
    if (index + pastedText.length > 10) {
      pastedText.slice(0, 10 - index)
    }
    newValues.splice(index, pastedText.length, ...pastedText.split(''))
    handleChange(newValues)
    if (index + pastedText.length > 10) {
      return inputRefs.current[9].focus()
    }
    return inputRefs.current[index + pastedText.length - 1].focus()
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // update existing value if pressing numbers
    if (/^\d$/.test(event.key)) {
      const newValues = [...values]
      if (newValues[index]) {
        newValues[index] = event.key
        handleChange(newValues)
        focusNextInput(index)
      }
    }

    if (event.key === 'ArrowRight') {
      focusNextInput(index)
    }

    if (event.key === 'ArrowLeft') {
      focusPreviousInput(index)
    }

    // clear value in the cell, move backwards if there is no value
    if (event.key === 'Backspace') {
      const newValues = [...values]
      if (newValues[index]) {
        newValues[index] = ''
        handleChange(newValues)
      } else {
        newValues[index - 1] = ''
        handleChange(newValues)
        focusPreviousInput(index)
      }
    }
  }

  return (
    <>
      {Array.from({ length: numFields }, (_, index) => (
        <input
        key={index}
        ref={(ref) => (inputRefs.current[index] = ref as HTMLInputElement)}
        value={values[index]}
        onChange={(e) => handleInputChange(index, e.target.value)}
        onPaste={(e) => handlePaste(index, e)}
        maxLength={1}
        onKeyDown={(e) => handleKeyDown(e, index)}
        autoFocus={index === 0}
        data-testid='continuous_input'
        css={{
          width: '40px',
          height: '40px',
          borderRadius: '5px',
          border: `1px solid ${props.hasError ? color.attention : color.grey_160}`,
          boxShadow: 'none',
          fontSize: '20px',
          marginRight: '10px',
          textAlign: 'center',
          caretColor: 'transparent',
          ':focus': {
            border: `2px solid ${props.hasError ? color.attention : color.primary}`,
            boxShadow: `0px 2px 2px ${color.primary}`,
            outline: 'none',
          },
        }}
        />
      ))}
    </>
  )
}
