import { Dispatch, SetStateAction,  } from "react"
import { CloseNewIcon } from "../../asset/image"
import { selectTheme } from "../../store"
import { handleEsc } from "../../lib"

export interface PopupConfirmDeleteTagProps {
  onClose: Dispatch<SetStateAction<boolean>>
  onDelete: () => void
}

export const PopupConfirmDeleteTag = (props: PopupConfirmDeleteTagProps) => {
  const { color, fontSize, fontWeight } = selectTheme()
  const { onDelete } = props
  const onClose = () => {
    if (props.onClose) {
      props.onClose(false)
    }
  }

  handleEsc(onClose)

  return (
    <div
      data-testid='tag_confirm_delete_pop'
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        background: '#00000040',
        zIndex: 99999}}>
      <div style={{
        position: 'absolute',
        top: 'calc(50vh - 145px)',
        left: 'calc(50vw - 300px)',
        width: 600,
        borderRadius: 5, 
        padding: 30,
        background: color.white, 
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.10)', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start', 
        display: 'flex',
        gap: 54
      }}>
        <div style={{
          width: '100%',
          height: '100%',
          flexDirection: 'column', 
          justifyContent: 'center',
          alignItems: 'flex-start', 
          display: 'inline-flex',
          gap: 24
        }}>
          <img onClick={onClose} src={CloseNewIcon} width={20} height={20}/>
          <div style={{
            width: '100%', 
            height: '100%', 
            flexDirection: 'column', 
            justifyContent: 'flex-start', 
            alignItems: 'flex-start', 
            gap: 16, 
            display: 'inline-flex'
          }}>
            <span style={{
              color: color.black, 
              fontSize: fontSize.h3, 
              fontWeight: fontWeight.thick, 
              wordWrap: 'break-word'
            }}>Are you sure you want to delete this tag in this project?</span>
            <span style={{
              color: color.black, 
              fontSize: fontSize.h6,
              fontWeight: fontWeight.medium, 
              wordWrap: 'break-word'
            }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies amet augue sit amet, consectetur adipiscing elit. Ultricies amet augue.</span>
          </div>
        </div>
        <div style={{
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
          alignItems: 'center', 
          display: 'inline-flex'
        }}>
          <div onClick={onClose} style={{
            paddingLeft: 16, 
            paddingRight: 16, 
            paddingTop: 12, 
            paddingBottom: 12, 
            borderRadius: 5, 
            justifyContent: 'center', 
            alignItems: 'flex-start', 
            display: 'inline-flex'
          }}>
            <span style={{
              textAlign: 'center', 
              color: color.primary, 
              fontSize: fontSize.h6, 
              fontWeight: fontWeight.thick, 
              wordWrap: 'break-word'
            }}>Cancel</span>
          </div>
          <div onClick={onDelete} style={{
            paddingLeft: 16, 
            paddingRight: 16, 
            paddingTop: 12, 
            paddingBottom: 12, 
            background: color.warning, 
            borderRadius: 5, 
            justifyContent: 'center', 
            alignItems: 'flex-start', 
            display: 'inline-flex'
          }}>
            <span style={{
              textAlign: 'center', 
              color: color.white, 
              fontSize: fontSize.h6, 
              fontWeight: fontWeight.thick, 
              wordWrap: 'break-word'
            }}>Delete</span>
          </div>
        </div>
      </div>
    </div>
  )
}