import {Column, ColumnConfig} from '@ant-design/charts'
import {Chart} from '@antv/g2/lib'
import {Meta} from '@antv/g2plot'

import {ChartDataProps, DailyChartData} from '../../../model/chart'
import {dailyGarminStepTheme} from '../theme/daily_garmin_step_theme'
import {ChartCard} from '../template/chart_card'
import {defaultDailyChartXAxisConfig} from '../../../lib/chart_data/util/formatter'
import {useEffect, useMemo, useState} from 'react'

export const GarminDailySummaryWearTimeChart = (props: ChartDataProps<DailyChartData>) => {
  const {height, width, data} = props
  const [chartInstance, setChartInstance] = useState<Chart | undefined>()
  const xField: keyof DailyChartData = 'yymmdd'
  const yField: keyof DailyChartData = 'value'
  const meta: {[key in keyof DailyChartData]: Meta} = {
    yymmdd: {
      alias: 'Date',
    },
    value: {
      alias: 'Wearing duration',
    },
  }
  const yAxisConfig = {
    title: {text: 'Hours'},
  }

  useEffect(() => {
    if (chartInstance) {
      chartInstance.changeData(data)
    }
  }, [chartInstance, data])

  const columnConfig: ColumnConfig = {
    data: [],
    autoFit: true,
    xField,
    yField,
    xAxis: defaultDailyChartXAxisConfig,
    yAxis: yAxisConfig,
    meta,
    theme: dailyGarminStepTheme,
    color: '#0f759c',
    onReady: (chart) => {
      setChartInstance(chart.chart)
    },
  }

  const config = useMemo<ColumnConfig>(() => {
    return columnConfig
  }, [])


  const chartCardProps = {
    height: height,
    width: width,
    chartTitle: 'Garmin Device Wearing Time',
    hintDescription: '',
    chart: <Column {...config} />,
  }
  return <ChartCard {...chartCardProps} />
}
