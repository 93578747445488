import {useState, useEffect, Dispatch, SetStateAction} from 'react'
import {v, debug, useForm, useTranslation, RIF, RequestResult} from '../../lib'
import {selectTheme, selectProfile, createDispatchActions,} from '../../store'
import {
  Button,
  SaveBar,
  Legend,
  LabelBox,
  Input,
  InputDisabled,
  ProfileSettingsProcessPage,
  Label,
  TextLink,
  AccountDeletePop,
  DeveloperTokenPage,
} from '..'
import {UploadIcon, ProfileImageDefault} from '../../asset/image'

const schema = v.object({
  firstName: v.string().exist(),
  lastName: v.string().exist(),
  title: v.string().allow('').optional(), // TODO key is not consistent in UI and reducer
})

export interface ProfileSettingsPagePropsT {
  setIsProfileSettingsPage: Dispatch<SetStateAction<boolean>>
}

export type ProfileSettingsProcessMethodString = '' | 'change_email' | 'reset_password'

export const ProfileSettingsPage = (props: ProfileSettingsPagePropsT) => {
  const {t} = useTranslation()
  const {pad, color, fontWeight} = selectTheme()

  const {setIsProfileSettingsPage} = props

  const {
    doREQUEST_PROFILE_IMAGE_UPLOAD,
    doREQUEST_PROFILE_UPDATE,
    doREQUEST_EMAIL_UPDATE,
    doPASSWORD_RESET_REQUEST,
  }: any = createDispatchActions()

  const [requestResultOfProfileUpdate, setRequestResultOfProfileUpdate] = useState<RequestResult | null>(null)
  const [requestResultOfImageUpload, setRequestResultOfImageUpload] = useState<RequestResult | null>(null)

  const profile = selectProfile()

  const [profileSettingsProcessMethod, setProfileSettingsProcessMethod] =
    useState<ProfileSettingsProcessMethodString>('')
  const [renderProfileSettingsProcessPage, setRenderProfileSettingsProcessPage] = useState(false)
  const [avatar, setAvatar] = useState<File>()
  const [orgCharLimit, setOrgCharLimit] = useState(50)
  const [displayAccountDeletePop, setDisplayAccountDeletePop] = useState(false)
  const [displayDeveloperTokenPage, setDisplayDeveloperTokenPage] = useState(false)

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [finishedSubmitting, setFinishedSubmitting] = useState(false)

  const {
    watch,
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({schema})

  useEffect(() => {
    setOrgCharLimit(50 - watch('title').length)
  }, [watch('title')])

  const onSubmit = (data: any) => {
    if (data.title === '') delete data.title
    debug('ProfileForm.onSubmit', {data})
    setIsSubmitting(true)
    doREQUEST_PROFILE_UPDATE({
      setRequestResult: setRequestResultOfProfileUpdate,
      payload: {
        ...data,
      },
    })
    if (avatar) {
      doREQUEST_PROFILE_IMAGE_UPLOAD({
        setRequestResult: setRequestResultOfImageUpload,
        payload: {
          image: avatar,
        },
      })
    }
  }

  useEffect(() => {
    if (requestResultOfImageUpload && !requestResultOfImageUpload.success) return
    if (requestResultOfProfileUpdate && !requestResultOfProfileUpdate.success) return

    setFinishedSubmitting(true)
    const timeout = setTimeout(() => {
      setIsSubmitting(false)
      setFinishedSubmitting(false)
    }, 2000)
    return () => {
      clearTimeout(timeout)
    }
  }, [requestResultOfImageUpload, requestResultOfProfileUpdate])

  const onError = (error: any) => {
    debug('ProfileForm.onError', {error})
  }

  const requestChangeEmail = () => {
    doREQUEST_EMAIL_UPDATE({
      payload: {},
    })
    setProfileSettingsProcessMethod('change_email')
  }

  const requestChangePassword = () => {
    doPASSWORD_RESET_REQUEST({
      payload: {
        email: profile?.email,
      },
    })
    setProfileSettingsProcessMethod('reset_password')
  }

  useEffect(() => {
    if (watch('title') !== profile?.title) return setSaveButtonDisabled(false)
    if (watch('firstName') !== profile?.firstName) return setSaveButtonDisabled(false)
    if (watch('lastName') !== profile?.lastName) return setSaveButtonDisabled(false)
    setSaveButtonDisabled(true)
  }, [watch('title'), watch('firstName'), watch('lastName')])

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        overflowY: 'scroll',
        background: color.white,
        zIndex: 102,
        padding: '60px',
      }}
    >
      {RIF(displayAccountDeletePop, <AccountDeletePop {...{setDisplayAccountDeletePop}} />)}

      {RIF(
        renderProfileSettingsProcessPage,
        <ProfileSettingsProcessPage
          {...{
            profileSettingsProcessMethod,
            setProfileSettingsProcessMethod,
            setRenderProfileSettingsProcessPage,
          }}
        />,
      )}

      {RIF(displayDeveloperTokenPage, <DeveloperTokenPage {...{setDisplayDeveloperTokenPage}} />)}

      <div css={{fontSize: '20px', fontWeight: fontWeight.bold, margin: '50px auto', zIndex: 103, width: '600px'}}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <SaveBar
            {...{
              closeAction: setIsProfileSettingsPage,
              disabled: saveButtonDisabled,
              isSubmitting,
              finishedSubmitting,
            }}
          />

          <Legend>{t('profile.profile')}</Legend>
          <div css={{fontWeight: fontWeight.bold, marginBottom: pad.large}}>{t('profile.profile_heads_up')}</div>

          {/* avatar upload */}
          <div css={{display: 'flex', marginBottom: pad.widthPadding}}>
            <img
              src={avatar ? URL.createObjectURL(avatar) : profile?.imageUrl ? profile?.imageUrl : ProfileImageDefault}
              width='74'
              height='74'
              css={{borderRadius: '50%', marginRight: pad.large}}
            />
            <div
              css={{
                fontWeight: fontWeight.bold,
                marginRight: pad.medium,
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'end',
                flexDirection: 'column',
              }}
            >
              <div>My Avatar</div>
              <Label
                htmlFor='avatar_upload'
                css={{
                  padding: '8px 16px',
                  marginTop: '8px',
                  border: `1px solid ${color.primary}`,
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                <span css={{display: 'flex', alignItems: 'center', color: color.primary}}>
                  <img width='12' css={{marginRight: pad.small}} src={UploadIcon} />
                  {t('profile.upload_button')}
                </span>
              </Label>
              <input
                id='avatar_upload'
                type='file'
                onChange={(e) => {
                  setAvatar(e.target.files?.[0])
                  setSaveButtonDisabled(false)
                }}
                css={{display: 'none'}}
              />
            </div>
          </div>

          <div css={{display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '30px'}}>
            <LabelBox {...{required: true, label: t('profile.first_name')}}>
              <Input
                {...register('firstName')}
                maxLength={50}
                value={profile?.firstName}
                style={{width: '282px', height: '43px'}}
              />
            </LabelBox>
            <LabelBox {...{required: true, label: t('profile.last_name')}}>
              <Input
                {...register('lastName')}
                maxLength={50}
                value={profile?.lastName}
                style={{width: '282px', height: '43px'}}
              />
            </LabelBox>
          </div>

          <div css={{marginBottom: '30px'}}>
            <LabelBox {...{label: t('profile.organization'), leftChracters: orgCharLimit}}>
              <Input
                {...register('title')}
                maxLength={50}
                value={profile?.title}
                css={{width: '100%', height: '43px'}}
              />
            </LabelBox>
          </div>
        </form>

        <div
          css={{
            width: '100%',
            marginBottom: '30px',
          }}
        >
          <LabelBox {...{required: true, label: t('profile.email')}}>
            <div css={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
              <InputDisabled content={profile?.email} css={{width: '490px'}} />
              <Button onClick={requestChangeEmail} children={t('profile.change_button')} />
            </div>
          </LabelBox>
        </div>

        {/* <div css={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px'}}>
          <div css={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'}}>
            <LabelBox {...{label: t('profile.country')}}>
              <InputDisabled content='---' css={{width: '178px'}}/>
            </LabelBox>
            <LabelBox {...{label: t('profile.phone')}} >
              <div css={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'}}>
                <InputDisabled content='---' css={{width: '287px', marginRight: '25px'}}/>
                <Button
                  // onClick={() => alert('function not yet')}
                  children={t('profile.change_button')}
                  disabled/>
              </div>
            </LabelBox>
          </div>
        </div> */}

        <div
          css={{
            width: '100%',
            marginBottom: '30px',
          }}
        >
          <LabelBox {...{label: t('profile.password')}}>
            <div css={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
              <InputDisabled content='********' css={{width: '490px'}} />
              <Button onClick={requestChangePassword} children={t('profile.change_button')} />
            </div>
          </LabelBox>
        </div>

        {/* <div css={{
          borderTop: `1px solid ${color.grey_160}`,
          paddingTop: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '30px'}}>
          <div css={{width: '490px'}}>
            <div>Multi-Factor Authentication</div>
            <div css={{color: color.grey_400, marginTop: '10px'}}>
              Add an extra layer of security to your account by requiring more than just a password to sign in.</div>
          </div>
          <div css={{display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '80px',
            color: color.grey_400}}>
            <SlideCheckbox value={false}/>
            Off
          </div>
        </div> */}

        <div
          css={{
            borderTop: `1px solid ${color.grey_160}`,
            padding: '30px 0',
          }}
        >
          <div css={{width: '490px', marginBottom: '30px'}}>
            <div>Developer Access</div>
            <div css={{color: color.grey_400, marginTop: '6px'}}>
              Generate authentication token to have programmatic access to Labfront data. Your token will allow you to
              access data your account has viewer permissions for.
            </div>
          </div>
          <a
            onClick={() => setDisplayDeveloperTokenPage(true)}
            css={{
              color: color.primary,
              fontWeight: fontWeight.thick,
              cursor: 'pointer',
            }}
          >
            Developer Keys
          </a>
        </div>

        <div
          css={{
            borderTop: `1px solid ${color.grey_160}`,
            padding: '30px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div css={{width: '490px'}}>
            <div>Delete Account</div>
            <div css={{color: color.grey_400, marginTop: '6px'}}>
              Permanently delete your account and all data associated with it.
            </div>
          </div>
          <TextLink onClick={() => setDisplayAccountDeletePop(true)} textColor='warning'>
            Delete
          </TextLink>
        </div>
      </div>
    </div>
  )
}
