import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'

import {PurchaseItem} from '../../../model'

export enum PurchaseActionType {
  PURCHASE_SET = 'PURCHASE_SET',
  PURCHASE_LIST_SET = 'PURCHASE_LIST_SET',
  PURCHASE_RESET = 'PURCHASE_RESET',
}

export const doPURCHASE_SET = createAction(PurchaseActionType.PURCHASE_SET)
export const doPURCHASE_LIST_SET = createAction(PurchaseActionType.PURCHASE_LIST_SET)
export const doPURCHASE_RESET = createAction(PurchaseActionType.PURCHASE_RESET)

export type PurchaseState = {
  [workspaceId: string]: PurchaseItem[]
}

export const selectPurchase = () => {
  return useSelector((state: {purchase: PurchaseState}) => state.purchase)
}

export const purchaseActionCreators = {
  doPURCHASE_SET,
  doPURCHASE_LIST_SET,
  doPURCHASE_RESET,
}

export const purchaseDefaultState: PurchaseState = {}

type Action =
  | {
      type: PurchaseActionType.PURCHASE_SET
      payload: {
        workspaceId: string
        purchaseList: PurchaseItem[]
      }
    }
  | {
      type: PurchaseActionType.PURCHASE_LIST_SET
      payload: [
        {
          workspaceId: string
          purchaseList: PurchaseItem[]
        },
      ]
    }
  | {
      type: PurchaseActionType.PURCHASE_RESET
      payload: {
        workspaceId: string
      }
    }

const purchaseItemSchema = v.object({
  workspaceId: v.string().uuid().exist(),
  purchaseList: v.array().items(
    v.object({
      id: v.string().uuid().exist(),
      createdAt: v.string().exist(),
      updatedAt: v.string().exist(),
      product: v
        .object({
          id: v.string().uuid().exist(),
          updatedAt: v.string().exist(),
          createdAt: v.string().exist(),
          name: v.string().exist(),
          price: v.string().exist(),
          capabilityList: v.array().exist(),
          plan: v.string().allow(null),
        })
        .allow(null),
      expireAt: v.string().allow(null),
      workspaceId: v.string().uuid().exist(),
      productId: v.string().uuid().exist(),
    }),
  ),
})

export const purchaseReducer = (state = {...purchaseDefaultState}, {type, payload}: Action): PurchaseState => {
  const newState = {...state}
  switch (type) {
    case PurchaseActionType.PURCHASE_SET:
      assertPartialSchema({
        payload,
        schema: purchaseItemSchema,
      })

      newState[payload.workspaceId] = payload.purchaseList
      return newState

    case PurchaseActionType.PURCHASE_LIST_SET:
      assertPartialSchema({
        payload,
        schema: v.array().items(purchaseItemSchema),
      })

      for (const {workspaceId, purchaseList} of payload) {
        newState[workspaceId] = purchaseList
      }
      return newState

    case PurchaseActionType.PURCHASE_RESET:
      assertPartialSchema({
        payload,
        schema: v.object({
          workspaceId: v.string().uuid().exist(),
        }),
      })

      delete newState[payload.workspaceId]
      return newState

    default:
      return {...state}
  }
}
