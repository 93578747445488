import {ChartConfig, TimeSeriesChartType, DataConverter, DataConverterOption} from '../template/time_series_data_chart'
import {TimeSeriesDataSource} from '../../../model'
import {StreamDataChart, StreamDataChartProps} from '../template/stream_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import { VisualizerGraphDataType } from '../../../shared/db'

export const MovesenseStreamBbiChart = (props: StreamDataChartProps) => {
  const {height, width, completionIdList, plotDataRange, commonTaskDataMarkerList} = props

  const bbiChartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
  }

  const defaultDataConverterOption: DataConverterOption = {
    label: 'BBI',
    value: 'BBI',
    chartConfig: bbiChartConfig,
  }

  const fetchSnrData: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    const result = plotData.map((item) => {
      return {
        t: item.t,
        v: item.snr,
      }
    })
    return result
  }

  const statusChartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
  }

  const dataConverterOptions: DataConverterOption[] = [
    defaultDataConverterOption,
    {
      label: 'SNR',
      value: 'SNR',
      chartConfig: statusChartConfig,
      dataConverter: fetchSnrData,
    },
  ]

  const dataConfig = {
    defaultDataConverterOption,
    dataConverterOptions
  }

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
  }

  const chartCardConfig = {
    height,
    width,
    title: 'Movesense BBI',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const streamDataChartProp = {
    dataType: VisualizerGraphDataType.MovesenseECGRR,
    config,
    completionIdList,
    plotDataRange,
    commonTaskDataMarkerList,
  }

  return (
    <>
      <StreamDataChart {...streamDataChartProp} />
    </>
  )
}
