import { CorrelationVariableTypeDisplayNameMap } from "../../../model"
import { Variable, VariableType } from "../../../shared/analysis"

export const getVariableCode = (index: number) => {
  return String.fromCharCode(65 + index)
}

export const getCharCodeForLastCharacter = (str: string): number | null => {
  if (str.length === 0) return null
  return str.charCodeAt(str.length - 1)
}

export const getVariableDisplayName = ({dataType, variableType}: Variable): string => {
  const displayNameMap = CorrelationVariableTypeDisplayNameMap[dataType] as Record<VariableType, string>
  return displayNameMap ? displayNameMap[variableType] : variableType
}