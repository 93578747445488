import { IEntityModel } from './base'

export enum ScheduleType {
  Absolute = 'absolute',
  Relative = 'relative',
  Recurring = 'recurring',
}

/*
  A date component object that represents both absolute and relative date.

  Examples:
    absolute date: { year: 2024, month: 11, day: 30, hour: 18, minute: 45, second: 30 }
    relative date: { day: 2 }
    recurring every Tuesday: { weekday: 2 }
    recurring every 2 days: { day: 2 } 
    recurring monthly on the 2nd week: { weekOfMonth: 2 }

  References:
    date-fns DateValues: https://date-fns.org/v3.6.0/docs/set#types/DateValues/1840
    date-fns Duration: https://date-fns.org/v3.6.0/docs/formatDuration#types/Duration/1851
    Swift DateComponents: https://developer.apple.com/documentation/foundation/datecomponents
**/
export interface ScheduleComponents {
  year?: number
  month?: number
  day?: number
  hour?: number
  minute?: number
  second?: number
  weekday?: number
}

export function isScheduleComponentsIdentical(
  a: ScheduleComponents,
  b: ScheduleComponents,
): boolean {
  return (
    a.year === b.year &&
    a.month === b.month &&
    a.day === b.day &&
    a.hour === b.hour &&
    a.minute === b.minute &&
    a.second === b.second &&
    a.weekday === b.weekday
  )
}

export const absoluteScheduleComponentKeys: readonly (keyof ScheduleComponents)[] =
  ['year', 'month', 'day', 'hour', 'minute', 'second'] as const

export const relativeScheduleComponentKeys: readonly (keyof ScheduleComponents)[] =
  [...absoluteScheduleComponentKeys] as const

export const recurringScheduleComponentKeys: readonly (keyof ScheduleComponents)[] =
  [...relativeScheduleComponentKeys, 'weekday'] as const

export interface ITaskSchedule extends IEntityModel {
  taskId: string
  type: ScheduleType
  schedule: ScheduleComponents
  inactive: boolean
}

export function isScheduleIdentical(
  a: Pick<ITaskSchedule, 'type' | 'schedule'>,
  b: Pick<ITaskSchedule, 'type' | 'schedule'>,
): boolean {
  return (
    String(a.type) === String(b.type) &&
    isScheduleComponentsIdentical(a.schedule, b.schedule)
  )
}

export function isAbsoluteRepresentableScheduleComponents(
  components: ScheduleComponents,
): components is Omit<ScheduleComponents, 'year' | 'month'> &
  Required<Pick<ScheduleComponents, 'year' | 'month'>> {
  const keys = Object.keys(components) as (keyof ScheduleComponents)[]
  return Boolean(
    keys.length &&
      keys.every((key) => absoluteScheduleComponentKeys.includes(key)) &&
      Boolean(components.year && components.month),
  )
}

export function isRelativeRepresentableScheduleComponents(
  components: ScheduleComponents,
): boolean {
  const keys = Object.keys(components) as (keyof ScheduleComponents)[]
  return Boolean(
    keys.length &&
      keys.every((key) => relativeScheduleComponentKeys.includes(key)),
  )
}

export function isRecurringRepresentableScheduleComponents(
  components: ScheduleComponents,
): boolean {
  const keys = Object.keys(components) as (keyof ScheduleComponents)[]
  const allValidKeys = keys.every((key) =>
    recurringScheduleComponentKeys.includes(key),
  )
  const nonWeekdayKeys = keys.filter((key) => key !== 'weekday')
  return Boolean(
    keys.length &&
      allValidKeys &&
      (!nonWeekdayKeys.length || keys.length === nonWeekdayKeys.length), // when weekday key is present, other keys should not. vice versa.
  )
}

export function absoluteScheduleComponentsToDate(
  components: ScheduleComponents,
): Date | undefined {
  if (!isAbsoluteRepresentableScheduleComponents(components)) return
  return new Date(
    components.year,
    components.month - 1,
    components.day,
    components.hour,
    components.minute,
    components.second,
  )
}

export function scheduleComponentsFromDate(date: Date): ScheduleComponents {
  return {
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate(),
    hour: date.getUTCHours(),
    minute: date.getUTCMinutes(),
    second: date.getUTCSeconds(),
  }
}

export function validateTaskSchedule(
  taskSchedule: Pick<ITaskSchedule, 'type' | 'schedule'>,
) {
  const { type, schedule } = taskSchedule
  switch (type) {
    case ScheduleType.Absolute: {
      if (!isAbsoluteRepresentableScheduleComponents(schedule)) {
        throw new Error(
          'task schedule of type absolute does not support non absolute components',
        )
      }
      break
    }
    case ScheduleType.Relative: {
      if (!isRelativeRepresentableScheduleComponents(schedule)) {
        throw new Error(
          'task schedule of type relative does not support non relative components',
        )
      }
      break
    }
    case ScheduleType.Recurring: {
      if (!isRecurringRepresentableScheduleComponents(schedule)) {
        throw new Error(
          'task schedule of type recurring does not support non recurring components',
        )
      }
      break
    }
    default:
      throw new Error('unhandled schedule type')
  }
}
