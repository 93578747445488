export const setSessionStorage = (payload) => {
  const now = new Date()
  const load = JSON.parse(window.sessionStorage.getItem('labfront'))
  const item = {
    ...load,
    ...payload,
    // expiry: now.getTime() + 600000,
  }
  window.sessionStorage.setItem('labfront', JSON.stringify(item))
}

export const loadSessionStorage = () => {
  const load = window.sessionStorage.getItem('labfront')
  if (!load) return null
  const item = JSON.parse(load)
  // const now = new Date()
  // if (now.getTime() > item.expiry) {
  //   window.localStorage.removeItem('labfront')
  //   return null
  // }
  return item
}
