import {
  ChartConfig,
  TimeSeriesChartType,
} from '../template/time_series_data_chart'
import {AnalysisDataType} from '../../../shared/mongo'
import {DailyTimeSeriesDataChart, DailyTimeSeriesChartProps} from '../template/daily_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import { VisualizerGraphDataType } from '../../../shared/db'

export const TimeSeriesActigraphySleepChart = (props: DailyTimeSeriesChartProps) => {
  const {height, width, participantId, plotDataRange, commonTaskDataMarkerList} = props

  const defaultDataTypeOption = {
    label: 'Sleep Score',
  }

  const subDataTypeOptions = [
    defaultDataTypeOption,
    {
      label: 'Sleep Detect',
      value: 'sw',
    },
  ]

  const dataConfig = {
    defaultDataTypeOption,
    subDataTypeOptions,
  }

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Area,
  }

  const chartCardConfig = {
    height,
    width,
    title: 'Actigraphy Sleep',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const timeSeriesChartProp = {
    dataType: VisualizerGraphDataType.AnalysisSleepScore,
    config,
    participantId,
    plotDataRange,
    commonTaskDataMarkerList,
  }

  return (
    <>
      <DailyTimeSeriesDataChart {...timeSeriesChartProp} />
    </>
  )
}
