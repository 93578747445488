import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {
  doQUESTIONNAIRES_SET,
  doQUESTIONNAIRES_DELETE,
  doREQUEST_ERROR,
  doREQUEST_COMPLETE,
  doMETHOD_ADD_TASK,
  doTEMP_TASK_DATA_REPLACE_TEMP_TASK,
} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_TASK_QUESTIONNAIRE_CREATE = 'REQUEST_TASK_QUESTIONNAIRE_CREATE'
export const doREQUEST_TASK_QUESTIONNAIRE_CREATE = createDoRequestAction(REQUEST_TASK_QUESTIONNAIRE_CREATE)

export const requestTaskQuestionnaireCreateActionCreators = {
  doREQUEST_TASK_QUESTIONNAIRE_CREATE,
}

interface Payload {
  payload: {
    requestId: string
    name: string
    description?: string
    methodId: string
    scheduleRepeat: boolean
    scheduleDescription: string
    reminderList?: Record<string, string>[]
    color: string
    tempTaskId?: string
    sectionList: Record<string, any>[]
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestTaskQuestionnaireCreate({payload, setRequestResult}: Payload): SagaIterator {
  debug('saga*requestTaskQuestionnaireCreate')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        methodId: v.string().uuid().exist(),
        name: v.string().exist(),
        description: v.string().optional(),
        scheduleRepeat: v.boolean().optional(),
        scheduleDescription: v.string().optional(),
        reminderList: v.array().optional(),
        sectionList: v.array(),
        color: v.string().exist(),
        tempTaskId: v.string().uuid().optional(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_QUESTIONNAIRE_CREATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {
    name,
    description,
    methodId,
    scheduleRepeat,
    scheduleDescription,
    reminderList,
    sectionList,
    color,
    tempTaskId,
  } = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/task-questionnaire-create`,
    accessToken,
    data: {
      name,
      description,
      methodId,
      scheduleRepeat,
      scheduleDescription,
      reminderList,
      sectionList,
      color,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_TASK_QUESTIONNAIRE_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/task-questionnaire-create',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(doQUESTIONNAIRES_SET({...result.payload.questionnaire, methodId}))
  yield put(doQUESTIONNAIRES_DELETE({id: 'questionnaireDraft'}))
  if (tempTaskId) {
    yield put(
      doTEMP_TASK_DATA_REPLACE_TEMP_TASK({
        tempTaskId,
        newId: result.payload.id,
      }),
    )
  } else {
    yield put(doMETHOD_ADD_TASK({...result.payload}))
  }

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_TASK_QUESTIONNAIRE_CREATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* taskQuestionnaireCreateSaga() {
  yield takeEvery(REQUEST_TASK_QUESTIONNAIRE_CREATE as any, requestTaskQuestionnaireCreate)
}
