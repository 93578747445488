import React from 'react'
import {selectTheme} from '../../store'

export const PageIndicator = (props) => {
  const {color, pad} = selectTheme()

  const range = (i) => {
    return i ? range(i - 1).concat(i) : []
  }

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {range(props.length).map((i) => {
        return (
          <div
            key={i}
            css={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              margin: '0 2px',
              marginTop: '20px',
              backgroundColor: i === props.index ? color.primary : color.grey_100,
            }}
          />
        )
      })}
    </div>
  )
}
