import { Dispatch, SetStateAction } from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
  RequestResult,
} from '../../lib'

import {doREQUEST_ERROR, doREQUEST_COMPLETE, doCORRELATION_VARIABLE_LIST_SET} from '../state'
import { getToken } from './token_fetcher'

export const REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH =
  'REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH'
export const doREQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH = createDoRequestAction(
  REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
)

export const requestAnalyticGraphCorrelationVariableListFetchActionCreators = {
  doREQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
}

interface RequestAnalyticGraphCorrelationVariableListFetchPayload {
  requestId: string
  projectId: string
}

export function* requestAnalyticGraphCorrelationVariableListFetch({
  payload,
  type,
  setRequestResult
}: {
  payload: RequestAnalyticGraphCorrelationVariableListFetchPayload
  type: string
  setRequestResult: Dispatch<SetStateAction<RequestResult>>
}): SagaIterator {
  debug('saga*requestAnalyticGraphCorrelationVariableListFetch')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
        requestId: payload.requestId,
        error,
      }, setRequestResult),
    )
  }

  const {projectId} = payload
  const accessToken = yield call(getToken)
  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/analytic-graph-correlation-variable-list-fetch`,
    accessToken,
    data: {
      projectId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/analytic-graph-correlation-variable-list-fetch',
        ...result,
        error,
      }, setRequestResult),
    )
  }

  yield put(
    doCORRELATION_VARIABLE_LIST_SET({
      projectId,
      correlationVariableList: result.payload,
    }),
  )

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* analyticGraphCorrelationVariableListFetchSaga() {
  yield takeEvery(
    REQUEST_ANALYTIC_GRAPH_CORRELATION_VARIABLE_LIST_FETCH,
    requestAnalyticGraphCorrelationVariableListFetch,
  )
}
