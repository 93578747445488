import {assertPartialSchema, createAction, useSelector, v} from '../../../lib'
import {IIdentityProjectSettings, VisualizerGraphResolution} from '../../../shared/db'

export enum ProjectSettingsActionType {
  PROJECT_SETTINGS_SET = 'PROJECT_SETTINGS_SET',
  PROJECT_SETTINGS_LIST_SET = 'PROJECT_SETTINGS_LIST_SET',
  PROJECT_SETTINGS_DELETE = 'PROJECT_SETTINGS_DELETE',
}

export const doPROJECT_SETTINGS_SET = createAction(ProjectSettingsActionType.PROJECT_SETTINGS_SET)
export const doPROJECT_SETTINGS_LIST_SET = createAction(ProjectSettingsActionType.PROJECT_SETTINGS_LIST_SET)
export const doPROJECT_SETTINGS_DELETE = createAction(ProjectSettingsActionType.PROJECT_SETTINGS_DELETE)

// project id to settings dictionary
export type ProjectSettingsState = Record<string, IIdentityProjectSettings>

interface RootState {
  projectSettings: ProjectSettingsState
}

export const selectProjectSettings = () => useSelector((state: RootState) => state.projectSettings)

export const projectSettingsActionCreators = {
  doPROJECT_SETTINGS_SET,
  doPROJECT_SETTINGS_DELETE,
}

const projectSettingsDefaultState: ProjectSettingsState = {}

const projectSettingsSchema = v.object({
  id: v.string().uuid().exist(),
  createdAt: v.string().exist(),
  updatedAt: v.string().exist(),
  identityId: v.string().uuid().exist(),
  projectId: v.string().uuid().exist(),
  visualizerSidebarSetting: v
    .object(
      Object.fromEntries(
        Object.values(VisualizerGraphResolution).map((key) => [
          key,
          v
            .array()
            .allow(null)
            .items(
              v.object({
                type: v.string().exist(),
                index: v.number().exist(),
                option: v.any().optional(),
              }),
            ),
        ]),
      ),
    )
    .exist(),
})

type Action =
  | {type: ProjectSettingsActionType.PROJECT_SETTINGS_SET; payload: IIdentityProjectSettings}
  | {type: ProjectSettingsActionType.PROJECT_SETTINGS_LIST_SET; payload: IIdentityProjectSettings[]}
  | {type: ProjectSettingsActionType.PROJECT_SETTINGS_DELETE; payload: {identityProjectSettingsIds: string[]}}

export const projectSettingsReducer = (
  state = projectSettingsDefaultState,
  {type, payload}: Action,
): ProjectSettingsState => {
  const newState: ProjectSettingsState = {...state}

  switch (type) {
    case ProjectSettingsActionType.PROJECT_SETTINGS_LIST_SET: {
      assertPartialSchema({
        payload,
        schema: v.array().items(projectSettingsSchema),
      })
      for (const settings of payload) {
        newState[settings.projectId] = settings
      }
      return {...newState}
    }
    case ProjectSettingsActionType.PROJECT_SETTINGS_SET: {
      assertPartialSchema({
        payload,
        schema: projectSettingsSchema,
      })
      newState[payload.projectId] = payload
      return {...newState}
    }
    case ProjectSettingsActionType.PROJECT_SETTINGS_DELETE: {
      assertPartialSchema({
        payload,
        schema: v.object({
          projectId: v.array().items(v.string().uuid().exist()),
        }),
      })
      Object.values(newState)
        .filter(({id}) => payload.identityProjectSettingsIds.includes(id))
        .forEach(({projectId}) => {
          delete newState[projectId]
        })
      return {...newState}
    }
    default: {
      return {...newState}
    }
  }
}
