import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  call,
} from '../../lib'

import {getToken} from './token_fetcher'
import {doREQUEST_ERROR, doREQUEST_COMPLETE, doPROJECT_SCHEDULE_CONFIG_SET} from '../state'

export const REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH = 'REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH'
export const doREQUEST_PROJECT_SCHEDULE_CONFIG_FETCH = createDoRequestAction(REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH)

export const requestProjectScheduleConfigFetchActionCreators = {
  doREQUEST_PROJECT_SCHEDULE_CONFIG_FETCH,
}

export function* requestProjectScheduleConfigFetch({payload, setRequestResult}: any): SagaIterator {
  debug('saga*requestProjectScheduleConfigFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        projectId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const {requestId, ...data} = payload

  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'POST',
    url: `${API_URL}/v1/web/project-schedule-config-fetch`,
    accessToken,
    data,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH,
          fromPayload: payload,
          requestId,
          url: '/v1/web/project-schedule-config-fetch',
          ...result,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(doPROJECT_SCHEDULE_CONFIG_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH,
        requestId: payload.requestId,
        ...result,
      },
      setRequestResult,
    ),
  )
}

export function* projectScheduleConfigFetchSaga() {
  yield takeEvery(REQUEST_PROJECT_SCHEDULE_CONFIG_FETCH, requestProjectScheduleConfigFetch)
}
