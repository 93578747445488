import React from 'react'

import {selectTheme} from '../../store'

import {useTranslation} from '../../lib'

import PolarHelpcenter from '../../asset/image/polar_helpcenter.png'

export const PolarHelpCenterBlock = (props) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()
  const {t} = useTranslation()
  return (
    <div
      css={{
        width: '100%',
        borderLeft: `5px solid ${color.primary}`,
        borderRadius: '5px',
        display: 'flex',
        padding: '32px 38px 28px 25px',
        background: color.white,
        marginBottom: pad.large,
      }}
    >
      <img
        src={PolarHelpcenter}
        width='115'
        css={{
          marginRight: pad.large,
        }}
      />
      <div>
        <p
          css={{
            marginBottom: pad.large,
          }}
        >
          Participants are required to wear Polar and do Polar task on the app to activate the device and record wearing
          timestamps. To learn more about how Polar works, please visit the following articles:
        </p>
        <div
          css={{
            display: 'flex',
          }}
        >
          <a
            href='#'
            css={{
              color: color.primary,
              fontWeight: fontWeight.bold,
              marginRight: pad.xl,
              whitespace: 'nowrap',
            }}
          >
            How does Polar Task works?
          </a>
          <a
            href='#'
            css={{
              color: color.primary,
              fontWeight: fontWeight.bold,
              marginRight: pad.xl,
              whitespace: 'nowrap',
            }}
          >
            How to setup Polar?
          </a>
          <a
            href='#'
            css={{
              color: color.primary,
              fontWeight: fontWeight.bold,
              marginRight: pad.xl,
              whitespace: 'nowrap',
            }}
          >
            Participant Guide: Polar
          </a>
        </div>
      </div>
    </div>
  )
}
