import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {selectTheme} from '../../store'
import {RIF} from '../../lib'
import {ToolTipHover} from '../atoms'

export type DayNumberWeekT = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7
export interface RepeatDateDotPropsT {
  text: 'S' | 'M' | 'T' | 'W' | 'F'
  setReminderRepeatDays: Dispatch<SetStateAction<DayNumberWeekT[]>>
  value: DayNumberWeekT
  reminderRepeatDays: DayNumberWeekT[]
}

export const RepeatDateDot = (props: RepeatDateDotPropsT) => {
  const {color} = selectTheme()
  const {text, setReminderRepeatDays, value, reminderRepeatDays} = props
  const match = reminderRepeatDays.some((day) => day === value)
  const [hoverState, setHoverState] = useState(false)

  const handleClick = () => {
    if (!match) setReminderRepeatDays((prev) => [...prev, value])
    if (match) setReminderRepeatDays((prev) => prev.filter((item) => item !== value))
  }

  const convertDayNumberToString = (value: number) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    return days[value % 7]
  }

  return (
    <div
      onClick={handleClick}
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
      css={{
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        border: `1px solid ${match ? color.primary : color.grey_100}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        cursor: 'pointer',
        marginRight: '16px',
        background: match ? color.primary : hoverState ? color.hover : color.white,
        position: 'relative',
        color: match ? color.white : color.black,
      }}
    >
      {text}
      {RIF(
        hoverState,
        <ToolTipHover
          {...{
            title: convertDayNumberToString(value),
            hoverTopPosition: '-130%',
            pseudoTopPosition: '90%',
            pseudoRightPosition: '50%',
          }}
        />,
      )}
    </div>
  )
}
