import {
  ProjectCollaboratorPermissionLayout,
  ProjectCollaboratorRole, ProjectCollaboratorType, WorkspaceCollaboratorPermissionLayout,
  WorkspaceCollaboratorRole,
  WorkspaceCollaboratorType,
} from '../shared/db'
import {CollaboratorTypeInProject} from './custom_hooks'

const workspaceCollaboratorTypeList = Object.values(WorkspaceCollaboratorType)
  .map((type) => type as string)

export const collaboratorTypeHasRole = (arg: {
  collaboratorType: CollaboratorTypeInProject,
  projectRole?: ProjectCollaboratorRole
  workspaceRole: WorkspaceCollaboratorRole
}): boolean => {
  const {collaboratorType, projectRole, workspaceRole} = arg
  if (collaboratorType === 'owner') {
    return true
  }
  const isWorkspaceCollaboratorType = workspaceCollaboratorTypeList.includes(collaboratorType)

  if (isWorkspaceCollaboratorType) {
    return WorkspaceCollaboratorPermissionLayout[collaboratorType as WorkspaceCollaboratorType][workspaceRole]
  } else {
    if (!projectRole) {
      return false
    }
    return ProjectCollaboratorPermissionLayout[collaboratorType as ProjectCollaboratorType][projectRole]
  }
}