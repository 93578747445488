import { IEntityModel } from './base'
import { IIdentityProjectSettings } from './identity_project_settings'
import { IInvite } from './invite'
import { IPhone } from './phone'
import { IProfile } from './profile'
import { ISettings } from './settings'
import { IWorkspace } from './workspace'
import { IWorkspaceFavorite } from './workspace_favorite'

export enum IdentityType {
  Admin = 'admin',
  User = 'user',
}

export interface IIdentity extends IEntityModel {
  type: IdentityType
  email: string
  emailVerified: boolean
  secretPasswordHash: string
  secretOtpSeed?: string
  secretRecoveryCodeList?: string
  phoneId?: string
  phone?: IPhone
  profileId?: string
  profile?: IProfile
  workspaceId?: string
  workspace?: IWorkspace
  settingsId?: string
  settings?: ISettings
  mfaEnabled: boolean
  deactivated: boolean
  workspaceFavoriteList?: IWorkspaceFavorite[]
  projectSettingsList?: IIdentityProjectSettings[]
  inviteList?: IInvite[]
}
