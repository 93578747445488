import {useSelector} from 'react-redux'

const COUNT_SUM = 'COUNT_SUM'
const COUNT_SUBTRACT = 'COUNT_SUBTRACT'

export const doCOUNT_SUM = (payload) => {
  return {
    type: COUNT_SUM,
    payload: payload || 1,
  }
}

export const doCOUNT_SUBTRACT = (amount) => {
  return {
    type: COUNT_SUBTRACT,
    payload: amount || 1,
  }
}

export const countActionCreators = {
  doCOUNT_SUM,
  doCOUNT_SUBTRACT,
}

// REDUCER
const initState = 0

/** used in the ReduxExample component in storybook */
export const countReducer = (state = initState, action) => {
  switch (action.type) {
    case COUNT_SUM:
      return state + (action.payload || 1)
    case COUNT_SUBTRACT:
      return state - (action.payload || 1)
    default:
      return state
  }
}

export const selectCount = (state) => {
  return useSelector((state) => state.count)
}
