import {useState} from 'react'
import {RIF} from '../../lib'
import {selectTheme} from '../../store'
import {TaskTempStateType, TaskType, getTaskContentName} from '../../model'
import {convertMovesenseDescription, convertGarminStreamDescription} from '..'
import {TodoBlackIcon, SurveyBlackIcon, TimerBlackIcon, RepeatIcon, DeviceIcon} from '../../asset/image'
import MovesenseImg from '../../asset/image/movesense.png'

export interface HiddenTaskTagPropsT {
  task: TaskTempStateType
  key?: string
  onEditing: () => void
}

export const HiddenTaskTag = (props: HiddenTaskTagPropsT) => {
  const {color, pad, fontWeight, fontSize} = selectTheme()

  const {task, onEditing} = props
  const taskContentName = getTaskContentName(task.type)
  const [hovered, setHovered] = useState(false)

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const handleClick = (e: any) => {
    if (e.target.tagName !== 'BUTTON') onEditing()
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      css={{
        width: '100%',
        height: 'fit-content',
        position: 'relative',
        marginBottom: pad.mediumSmall,
        display: 'flex',
      }}
    >
      <a
        onClick={handleClick}
        css={{
          width: '100%',
          height: '100%',
          borderRadius: '5px',
          border: `1px solid ${hovered ? color.grey_400 : color.grey_160}`,
          borderLeft: `6px solid ${color.disabled}`,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `${pad.medium} ${pad.mediumSmall}`,
          background: hovered ? color.grey_20 : color.white,
          cursor: 'pointer',
        }}
      >
        <div css={{display: 'flex', height: '100%', alignItems: 'center'}}>
          {RIF(
            task.type === TaskType.Todo,
            <img src={TodoBlackIcon} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
          )}
          {RIF(
            task.type === TaskType.Timer,
            <img src={TimerBlackIcon} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
          )}
          {RIF(
            task.type === TaskType.Questionnaire,
            <img src={SurveyBlackIcon} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
          )}
          {RIF(
            task.type === TaskType.StopwatchMovesenseStream || task.type === TaskType.StopwatchMovesenseLogData,
            <img src={MovesenseImg} width='24' height='24' css={{marginRight: pad.slightlyLarger}} />,
          )}
          {RIF(
            task.type === TaskType.StopwatchGarminStream,
            <img src={DeviceIcon} width='22' height='22' css={{marginRight: pad.slightlyLarger}} />,
          )}
          <div css={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%'}}>
            <p css={{fontWeight: fontWeight.thick, fontSize: fontSize.h6}}>{task[taskContentName]?.name}</p>
            <div css={{display: 'flex', alignItems: 'center'}}>
              {RIF(task.scheduleRepeat, <img src={RepeatIcon} width='14' height='14' css={{marginRight: pad.xs}} />)}
              <p css={{color: color.grey_400, fontSize: fontSize.h7}}>{task.scheduleDescription}</p>
            </div>
            {RIF(
              task.type === TaskType.StopwatchMovesenseStream ||
                task.type === TaskType.StopwatchMovesenseLogData ||
                task.type === TaskType.StopwatchGarminStream,
              <div
                css={{
                  background: color.background,
                  borderRadius: '8px',
                  padding: '8px 12px',
                  marginTop: '8px',
                  color: color.grey_600,
                  fontSize: '12px',
                }}
              >
                {(task.type === TaskType.StopwatchMovesenseStream ||
                  task.type === TaskType.StopwatchMovesenseLogData) &&
                  convertMovesenseDescription(task[taskContentName])}
                {task.type === TaskType.StopwatchGarminStream && convertGarminStreamDescription(task[taskContentName])}
              </div>,
            )}
          </div>
        </div>
      </a>
    </div>
  )
}
