import {
  ChartConfig,
  TimeSeriesChartType,
} from '../template/time_series_data_chart'
import {DailyTimeSeriesDataChart, DailyTimeSeriesChartProps} from '../template/daily_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import { VisualizerGraphDataType } from '../../../shared/db'

export const TimeSeriesRespirationChart = (props: DailyTimeSeriesChartProps) => {
  const {height, width, participantId, plotDataRange, commonTaskDataMarkerList} = props

  const dataConfig = {}

  const chartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
  }

  const chartCardConfig = {
    height,
    width,
    title: 'Respiration',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const timeSeriesChartProp = {
    // dataType: VisualizerGraphDataType.GarminDirectRespiration, // todo
    config,
    participantId,
    plotDataRange,
    commonTaskDataMarkerList,
  }

  return (
    <>
      {/* <DailyTimeSeriesDataChart {...timeSeriesChartProp} /> */}
    </>
  )
}
