import {ProjectCollaboratorType, WorkspaceCollaboratorType} from '../../shared/db'
import {selectCollaborators, selectProfile, selectWorkspaces} from '../../store'
import {_, useCurrentWorkspaceState} from '..'
import {useParams} from 'react-router-dom'

type CollaboratorTypeInWorkspace = WorkspaceCollaboratorType | 'owner'
export type CollaboratorTypeInProject = WorkspaceCollaboratorType | ProjectCollaboratorType | 'owner'

export const useCollaboratorTypeInProject = (arg?: {
  //will default to current selected project id (id in the url path)
  projectId?: string
}): CollaboratorTypeInProject => {
  const projectId = arg?.projectId || useParams().projectId
  const profile = selectProfile()
  const collaboratorState = selectCollaborators()
  const { workspaceId, workspace} = useCurrentWorkspaceState({projectId})
  const { roleInProjects, roleInWorkspaces} = selectWorkspaces()
  if (projectId && roleInProjects[projectId]) {
    return roleInProjects[projectId]
  }
  if (workspaceId && roleInWorkspaces[workspaceId]) {
    return roleInWorkspaces[workspaceId]
  }
  if (!collaboratorState) {
    return WorkspaceCollaboratorType.Viewer
  }
  if (!workspaceId || !workspace) {
    return WorkspaceCollaboratorType.Viewer
  }
  if (!profile) {
    return WorkspaceCollaboratorType.Viewer
  }
  if (workspace.ownerProfile && workspace.ownerProfile.identityId === profile.identityId) {
    return 'owner'
  }
  if (collaboratorState[workspaceId]) {
    const collaborator = _.find(
      collaboratorState[workspaceId],
      ['identityId', profile.identityId]
    )
    if (collaborator) {
      if (collaborator.type !== 'project_collaborator' || !projectId) {
        return collaborator.type as CollaboratorTypeInWorkspace
      } else {
        const ProjectCollaborator = _.find(
          collaborator.projectCollaboratorList,
          ['projectId', projectId]
        )
        if (ProjectCollaborator) {
          return ProjectCollaborator.type
        }
      }
    }
  }
  return WorkspaceCollaboratorType.Viewer
}