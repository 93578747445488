import {Dispatch, SetStateAction} from 'react'
import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  RequestResult,
} from '../../lib'
import {doGLOBAL_WORKSPACE_LIST_SET, doREQUEST_ERROR, doREQUEST_COMPLETE} from '../state'
import {Action} from 'redux'

export const REQUEST_GLOBAL_WORKSPACE_LIST_FETCH = 'REQUEST_GLOBAL_WORKSPACE_LIST_FETCH'
export const doREQUEST_GLOBAL_WORKSPACE_LIST_FETCH = createDoRequestAction(REQUEST_GLOBAL_WORKSPACE_LIST_FETCH)

export const requestGlobalWorkspaceListFetchActionCreators = {
  doREQUEST_GLOBAL_WORKSPACE_LIST_FETCH,
}

interface Payload extends Action {
  payload: {
    requestId: string
  }
  setRequestResult: Dispatch<SetStateAction<RequestResult>>
}

export function* requestGlobalWorkspaceListFetch({payload, setRequestResult}: Payload): SagaIterator {
  debug('saga*requestGlobalWorkspaceListFetch')

  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
      }),
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_GLOBAL_WORKSPACE_LIST_FETCH,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/global-workspace-list-fetch`,
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR(
        {
          fromType: REQUEST_GLOBAL_WORKSPACE_LIST_FETCH,
          fromPayload: payload,
          requestId: payload.requestId,
          error,
        },
        setRequestResult,
      ),
    )
  }

  yield put(doGLOBAL_WORKSPACE_LIST_SET(result.payload))

  yield put(
    doREQUEST_COMPLETE(
      {
        fromType: REQUEST_GLOBAL_WORKSPACE_LIST_FETCH,
        requestId: payload.requestId,
        payload: result,
      },
      setRequestResult,
    ),
  )
}

export function* globalWorkspaceListFetchSaga(): SagaIterator {
  yield takeEvery(REQUEST_GLOBAL_WORKSPACE_LIST_FETCH, requestGlobalWorkspaceListFetch)
}
