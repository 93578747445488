import {PopupBackground, PopupSimple} from '..'
import {selectTheme} from '../../store'

const deviceTypeData = {
  stopwatch_movesense_stream: {
    name: 'Movesense Task Missing',
    description:
      'It seems like you’ve selected to use the Movesense but have not added a Movesense task. You’ll need to have a Movesense Task in order to collect data from the Movesense.',
    cancelText: 'Remove Movesense and go live',
    buttonText: 'Add Movesense Task',
  },
  stopwatch_garmin_stream: {
    name: 'ACC Streaming Task Missing',
    description:
      'You’ve selected to use Garmin device for Accelerometer streaming, but did not add the relevant task. Please go to the “Participant Tasks” section of the page and add an “ACC Streaming” task.',
    cancelText: 'I am no longer using ACC Streaming',
    buttonText: 'Add Acc Streaming Task',
  },
}

export interface DeviceTaskMissingPopPropsT {
  deviceTaskType: 'stopwatch_movesense_stream' | 'stopwatch_garmin_stream'
  closeAction: any
  doAction: any
  exitAction: any
}

export const DeviceTaskMissingPop = (props: DeviceTaskMissingPopPropsT) => {
  const {color} = selectTheme()
  const {closeAction, doAction, exitAction, deviceTaskType} = props

  return (
    <PopupBackground>
      <PopupSimple
        {...{
          name: deviceTypeData[deviceTaskType].name,
          description: deviceTypeData[deviceTaskType].description,
          cancelText: deviceTypeData[deviceTaskType].cancelText,
          buttonText: deviceTypeData[deviceTaskType].buttonText,
          cancelTextColor: color.warning,
          closeAction,
          exitAction,
          doAction,
        }}
      />
    </PopupBackground>
  )
}
