import {createDispatchActions} from '../../store'
import {debug} from '../../lib'

import {PopupConfirmation} from '..'

export const WorkspaceDeletePop = (props: any) => {
  const {doREQUEST_DELETE_WORKSPACE}: any = createDispatchActions()

  const onSubmit = (data: any) => {
    debug('WorkspacePop.onSubmit', {data})
    doREQUEST_DELETE_WORKSPACE()
  }

  return (
    <div
      css={{
        background: '#00000040',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PopupConfirmation
        {...props}
        method='Delete'
        name='Workspace'
        description='Your project will reset to draft with the current setup.'
        term1='I understand that this will disconnect all participants and log them out of the project on the participant app.'
        term2='I understand that this will delete all the participants information and data.'
        buttonText='Delete Workspace'
        submit={onSubmit}
      />
    </div>
  )
}
