import {promisify} from 'util'

import {ReplayRelay} from './replay_relay'
import {Result, Ok, Err} from '../../shared/engine/result'

export class Action<Input, Element, ActionError = Error> {
  readonly outputs = new ReplayRelay<Result<Element, ActionError>>()
  readonly executing = new ReplayRelay(false)

  constructor(private readonly workFactory: (input: Input) => Promise<Element>) {}

  async execute(input: Input): Promise<Element> {
    const action = promisify<Input, Element | undefined>((input, callback) => {
      this.executing.subscribeOnceWithReplay((executing) => {
        if (!executing) {
          this.executing.setValue(true)
          this.workFactory(input)
            .then((element) => {
              this.outputs.setValue(new Ok(element))
              callback(undefined, element)
            })
            .catch((error) => {
              this.outputs.setValue(new Err(error))
              callback(error, undefined)
            })
            .finally(() => this.executing.setValue(false))
        } else {
          // if executing, wait for current action to complete and return its result
          this.outputs.subscribeOnce((result) => {
            try {
              callback(undefined, result.unwrap())
            } catch (error) {
              callback(error, undefined)
            }
          })
        }
      })
    })

    const result = await action(input)
    return result as Element
  }
}
