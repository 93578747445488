import { IEntityModel } from './base'
import { IDigestParticipant } from './digest_participant'
import { IParticipant } from './participant'
import { IProject } from './project'
import { ITask } from './task'
import { IWorkspace } from './workspace'

export enum DigestParticipantItemType {
  Todo = 'todo',
  Timer = 'timer',
  Questionnaire = 'questionnaire',
  GarminDevice = 'garmin_device',
  StopwatchMovesenseStream = 'stopwatch_movesense_stream',
  StopwatchGarminStream = 'stopwatch_garmin_stream',
  GarminConnect = 'garamin_connect',
}

export interface IDigestParticipantItem extends IEntityModel {
  taskId?: string
  projectId: string
  workspaceId: string
  participantId: string
  digestParticipantId: string
  unixTimestampStart?: number
  unixTimestampComplete?: number
  type: DigestParticipantItemType
  data?: object
  task?: ITask
  project?: IProject
  workspace?: IWorkspace
  participant?: IParticipant
  digestParticipant?: IDigestParticipant
}
