import {selectTheme} from '../../store'

export interface CheckboxProps {
  value: boolean
  name?: string
  color?: string
  disableCheck?: boolean
  onChange?: any
  disabled?: boolean
  from?: string // for RTL
}

/* a styled checkbox */
export const Checkbox = (props: CheckboxProps) => {
  const {color} = selectTheme()

  const {value, name, disableCheck, onChange, disabled} = props
  const checkboxColor = disabled ? color.disabled : props.color ? props.color : color.primary
  const checkboxBorderColor = disabled ? color.disabled : color.grey_600

  const handleChange = (e: any) => {
    if (disableCheck) return e.preventDefault()
    if (onChange) onChange(e.target.checked)
  }

  return (
    <label
      data-testid='checkbox_label'
      css={{
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer',
        height: '16px',
        width: '16px',
        background: `${value ? checkboxColor : color.white}`,
        border: `${value ? '' : `1px solid ${checkboxBorderColor}`}`,
        borderRadius: '3px',
      }}
    >
      <input
        data-testid={`checkbox_input${props.from ? props.from : ''}`}
        type='checkbox'
        name={name}
        checked={value || false}
        onChange={handleChange}
        disabled={disabled}
        css={{display: 'none'}}
      />
      <span
        css={{
          position: 'absolute',
          top: '1.5px',
          left: '5px',
          height: '11px',
          width: '6px',
          border: `solid ${color.white}`,
          borderWidth: '0 2px 2px 0',
          transform: 'rotate(45deg)',
          display: `${value ? 'block' : 'none'}`,
        }}
      />
    </label>
  )
}
