import {Chart} from '@antv/g2/lib'
import {
  ChartConfig,
  TimeSeriesChartType,
  DataConverter,
  DataConverterOption,
} from '../template/time_series_data_chart'
import {TimeSeriesDataSource} from '../../../model'
import {useSelectSettings} from '../../../store'
import {DailyTimeSeriesDataChart, DailyTimeSeriesChartProps} from '../template/daily_time_series_chart'
import {GenericTimeSeriesDataChartConfig} from '../template/time_series_data_chart'
import { VisualizerGraphDataType } from '../../../shared/db'

export const TimeSeriesBbiChart = (props: DailyTimeSeriesChartProps) => {
  const {height, width, participantId, plotDataRange, commonTaskDataMarkerList} = props
  const {enhancedBbiEnabled} = useSelectSettings()

  const enhancedBBIChartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
    enableManualSetYAxis: true,
    lineStyle: {
      stroke: '#D7D7D7',
    },
  }

  const defaultDataConverterOption: DataConverterOption = {
    label: 'BBI',
    value: 'BBI',
    chartConfig: enhancedBBIChartConfig,
  }

  const fetchStatusData: DataConverter = (plotData: TimeSeriesDataSource[]) => {
    const result = plotData.map((item) => {
      return {
        t: item.t,
        v: item.status,
        status: item.status,
      }
    })
    return result
  }

  const statusChartConfig: ChartConfig = {
    chartType: TimeSeriesChartType.Line,
    scaleRange: [-1, 1],
  }

  const dataConverterOptions: DataConverterOption[] = [
    defaultDataConverterOption,
    {
      label: 'Confidence',
      value: 'Confidence',
      chartConfig: statusChartConfig,
      dataConverter: fetchStatusData,
    },
  ]

  // const developLock = process.env.DEPLOY_STAGE === 'develop' || process.env.DEPLOY_STAGE === 'staging'

  const dataConfig = enhancedBbiEnabled
    ? {
        defaultDataConverterOption,
        dataConverterOptions,
      }
    : {}

  const chartConfig: ChartConfig = enhancedBbiEnabled
    ? enhancedBBIChartConfig
    : {
        chartType: TimeSeriesChartType.Line,
        enableManualSetYAxis: true
      }

  const chartCardConfig = {
    height,
    width,
    title: 'BBI',
    hintDescription: '',
  }

  const config: GenericTimeSeriesDataChartConfig = {
    dataConfig,
    chartConfig,
    chartCardConfig,
  }

  const statusColorAttrCallback = (t: number, status: number) => {
    let color = '#F57E06'
    if (status === 0) color = '#0000FF'
    else if (status === -1) color = '#FF0000'
    return color
  }

  const chartReadyCallback = (chart: Chart) => {
    chart.point().position('t*v').shape('line').size(1.5).color('t*status', statusColorAttrCallback).tooltip(false)
  }

  const timeSeriesChartProp = {
    dataType: VisualizerGraphDataType.GarminDirectBBI,
    config,
    participantId,
    plotDataRange,
    commonTaskDataMarkerList,
    chartReadyCallback: enhancedBbiEnabled? chartReadyCallback: undefined,
  }

  return (
    <>
      <DailyTimeSeriesDataChart {...timeSeriesChartProp} />
    </>
  )
}
