import {MouseEventHandler} from 'react'
import {selectTheme} from '../../store'
import {MoreIcon} from '../../asset/image'

export interface ButtonMorePropsT {
  onClick: MouseEventHandler
  id?: string
  type?: 'button' | 'submit' | 'reset'
}

export const ButtonMore = (props: ButtonMorePropsT) => {
  const {color} = selectTheme()

  return (
    <button
      {...props}
      type={props.type || 'button'}
      css={{
        width: '24px',
        height: '24px',
        borderRadius: '5px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        backgroundImage: `url(${MoreIcon})`,
        backgroundPosition: 'center',
        backgroundSize: '1.286rem 1.286rem',
        backgroundRepeat: 'no-repeat',
        ':hover': {
          backgroundColor: color.hover,
        },
      }}
    />
  )
}
