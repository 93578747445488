import {
  API_URL,
  v,
  put,
  debug,
  request,
  takeEvery,
  createDoRequestAction,
  assertTotalSchema,
  SagaIterator,
  TakeableChannel,
  call,
  RequestResult,
} from '../../lib'

import { 
  doREQUEST_ERROR, 
  doREQUEST_COMPLETE, 
  doWORKSPACE_DEXCOM_INTEGRATION_SET,
} from '../state'

import { createMockDexcomIntegration } from '../../test/mock/create_mock_dexcom_integration'
import { getToken } from './token_fetcher'
import { Dispatch, SetStateAction } from 'react'

export const REQUEST_DEXCOM_INTEGRATION_CREATE = 'REQUEST_DEXCOM_INTEGRATION_CREATE'
export const doREQUEST_DEXCOM_INTEGRATION_CREATE = createDoRequestAction(REQUEST_DEXCOM_INTEGRATION_CREATE)

export const requestDexcomIntegrationCreateActionCreators = {
  doREQUEST_DEXCOM_INTEGRATION_CREATE,
}

export interface RequestDexcomIntegrationCreatePayload {
  payload: {
    requestId: string
    workspaceId: string
    dexcomApp?: any
    name: string
    description: string
    investigator: string
    organization: string
    countries: string
    contactDescription: string
  }
  setRequestResult?: Dispatch<SetStateAction<RequestResult>>
}

export function* requestDexcomIntegrationCreate({payload, setRequestResult}: RequestDexcomIntegrationCreatePayload): SagaIterator {
  debug('saga*requestDexcomIntegrationCreate')
  try {
    assertTotalSchema({
      payload,
      schema: v.object({
        requestId: v.string().uuid().exist(),
        name: v.string().exist(),
        description: v.string().exist(),
        investigator: v.string().exist(),
        organization: v.string().exist(),
        countries: v.string().exist(),
        contactDescription: v.string().exist(),
        workspaceId: v.string().uuid().exist(),
      })
    })
  } catch (error) {
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_DEXCOM_INTEGRATION_CREATE,
        requestId: payload.requestId,
        error,
      }, setRequestResult)
    )
  }

  const {
    name,
    description,
    investigator,
    organization,
    countries,
    contactDescription,
    workspaceId,
  } = payload
  
  const accessToken = yield call(getToken)

  const result = yield request({
    method: 'post',
    url: `${API_URL}/v1/web/dexcom-integration-create`,
    accessToken,
    data: {
      name,
      description,
      investigator,
      organization,
      countries,
      contactDescription,
      workspaceId,
    },
  })

  if (!result.success || result.error) {
    const error = result.error || new Error('request did not succeed')
    debug(error)
    return yield put(
      doREQUEST_ERROR({
        fromType: REQUEST_DEXCOM_INTEGRATION_CREATE,
        fromPayload: payload,
        requestId: payload.requestId,
        url: '/v1/web/dexcom-integration-create',
        ...result,
        error,
      }, setRequestResult)
    )
  }
  
  yield(put(
    doWORKSPACE_DEXCOM_INTEGRATION_SET({...result.payload})
  ))

  yield put(
    doREQUEST_COMPLETE({
      fromType: REQUEST_DEXCOM_INTEGRATION_CREATE,
      requestId: payload.requestId,
      ...result,
    }, setRequestResult),
  )
}

export function* dexcomIntegrationCreateSaga() {
  yield takeEvery(REQUEST_DEXCOM_INTEGRATION_CREATE as any, requestDexcomIntegrationCreate)
}