import {Dispatch, SetStateAction, useEffect, useState} from 'react'

import {ParticipantStateType} from '../../model'

import {
  selectTheme,
  selectBatchData,
  selectDataDownloadStatus,
  createDispatchActions,
  DataDownloadState,
  selectAuth,
} from '../../store'

import {RIF, _, useCurrentWorkspaceAccountPlan, t, useCurrentProjectState, RequestResult} from '../../lib'

import {subDays} from 'date-fns'
import {format} from 'date-fns-tz'

import {RadioGroup as ReactRadioGroup, Radio as ReactRadio} from 'react-radio-group'

import DatePicker, {DateObject} from 'react-multi-date-picker'

import {
  SaveBar,
  Button,
  TextLink,
  Checkbox,
  UpgradeTag,
  UpgradeContactPop,
  PopupPreparingDataDownload,
  PopupDataDownloading, PopupDownloadSuccess,
} from '..'

import {
  DownloadWhiteIcon,
  ParticipantGreyIcon,
  AnalyticsReportImg,
  ResourcesIcon,
  HRVCourseImg,
  ActigraphyCollectionImg,
  CalendarGreyIcon,
} from '../../asset/image'

import {Scrollbars} from 'react-custom-scrollbars-2'
import {FileSystemFileHandle} from 'native-file-system-adapter'
import {DataDownloader} from '../../lib/data_downloader'

export interface DataDownloadPagePopups {
  closeAction: () => void
}

enum ProjectUpdateStatus {
  Idle,
  Updating,
  UpdateFinished,
  Downloading,
  Failed,
  Retry,
}

type TimeFrameOption = 'lastSevenDays' | 'lastThirtyDays' | 'allTime' | 'specificPeriod'

export const DataDownloadPage = (props: DataDownloadPagePopups) => {
  const {color, fontSize, fontWeight} = selectTheme()
  const {closeAction} = props
  const {
    doREQUEST_PROJECT_DATA_UPDATE_REQUEST,
    doREQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH,
    doDATA_DOWNLOAD_STATUS_RESET,
  }: any = createDispatchActions()

  // extract basic state
  const {projectId, project} = useCurrentProjectState()
  const auth = selectAuth()
  const batchId = project?.batchList?.[0]?.id ?? ''
  const batch = selectBatchData()?.[batchId]
  const participantList = batch?.participantList || []
  const {hasActivePlanPurchase} = useCurrentWorkspaceAccountPlan()

  const dataDownloadStatus = selectDataDownloadStatus()
  const relativeTimeFramePeriod = ((): Record<Exclude<TimeFrameOption, 'specificPeriod'>, (Date | undefined)[]> => {
    const endDay = new Date()
    const dayRangeCapped = (days: number) => [subDays(endDay, days), endDay]
    return {
      lastSevenDays: dayRangeCapped(7),
      lastThirtyDays: dayRangeCapped(30),
      allTime: [undefined, endDay],
    }
  })()

  // custom hooks
  const [selectedParticipantList, setSelectedParticipantList] = useState<ParticipantStateType[] | never[]>([])
  const [selectedAll, setSelectedAll] = useState(true)
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrameOption>('lastSevenDays')
  const [period, setPeriod] = useState<Date[]>([])
  const [renderUpgradePopup, setRenderUpgradePopup] = useState<boolean>(false)
  const [requestResultOfProjectUpdate, setRequestResultOfProjectUpdate] = useState<RequestResult | null>(null)
  const [requestResultOfProjectUpdateMetadataFetch, setRequestResultOfProjectUpdateMetadataFetch] =
    useState<RequestResult | null>(null)

  const [projectUpdateStatus, setProjectUpdateStatus] = useState(ProjectUpdateStatus.Idle)
  const [renderPopupDataDownloading, setRenderPopupDataDownloading] = useState<boolean>(false)
  const [downloadingFilename, setDownloadingFilename] = useState<string | undefined>()
  const [fileHandle, setFileHandle] = useState<FileSystemFileHandle | undefined>(undefined)
  const [showConfirmDownloadComplete, setShowConfirmDownloadComplete] = useState<boolean>(false)

  // default select all participant after loading participantList
  useEffect(() => {
    if (participantList.length) setSelectedParticipantList(participantList)
  }, [participantList])

  // control state of selected all after changing selected participant list
  useEffect(() => {
    if (selectedParticipantList.length === participantList.length) return setSelectedAll(true)
    if (selectedAll) return setSelectedAll(false)
  }, [selectedParticipantList])

  // start fetching metadata after request project update
  useEffect(() => {
    if (!requestResultOfProjectUpdate) return
    if (!requestResultOfProjectUpdate.success) return endDataDownloadProcessWhenError()
    doREQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH({
      setRequestResult: setRequestResultOfProjectUpdateMetadataFetch,
      payload: {
        projectId,
      },
    })
  }, [requestResultOfProjectUpdate])

  // request project update metadata every 3 seconds after starting
  // break if fail or finished
  const PROJECT_METADATA_FETCHING_INTERVAL = 3000
  useEffect(() => {
    if (!requestResultOfProjectUpdateMetadataFetch) return
    if (!requestResultOfProjectUpdateMetadataFetch.success) return endDataDownloadProcessWhenError()
    const updateStatus = resolveProjectUpdateStatus()
    setProjectUpdateStatus(updateStatus)
    switch (updateStatus) {
      case ProjectUpdateStatus.Updating:
        break
      case ProjectUpdateStatus.Failed:
        alert('OOPS! Something went wrong with the download!')
        doDATA_DOWNLOAD_STATUS_RESET()
        return
      default:
        return
    }
    setTimeout(() => {
      doREQUEST_PROJECT_DATA_UPDATE_METADATA_FETCH({
        setRequestResult: setRequestResultOfProjectUpdateMetadataFetch,
        payload: {
          projectId,
        },
      })
    }, PROJECT_METADATA_FETCHING_INTERVAL)
  }, [requestResultOfProjectUpdateMetadataFetch])

  useEffect(() => {
    switch (projectUpdateStatus) {
      // start download after update finish
      case ProjectUpdateStatus.UpdateFinished: {
        if (!auth || !projectId || !selectedParticipantList.length) {
          return
        }
        setProjectUpdateStatus(ProjectUpdateStatus.Downloading)
        const participantIds = selectedParticipantList.map((participant) => participant.id)
        const [startDate, endDate] = getTimeFramePeriod(selectedTimeFrame).map((date) =>
          date ? t.utcToLocalTime(date) : undefined,
        )

        const dataDownloader = new DataDownloader({
          auth,
          onFetch: (filename: string) => setDownloadingFilename(filename),
        })
        dataDownloader
          .run({
            payload: {
              projectId,
              participantIds,
              startDate,
              endDate,
            },
            fileHandle: t.unwrap(fileHandle)
          })
          .then(() => {
            setShowConfirmDownloadComplete(true)
            setFileHandle(undefined)
            setDownloadingFilename(undefined)
            setProjectUpdateStatus(ProjectUpdateStatus.Idle)
            doDATA_DOWNLOAD_STATUS_RESET()
          })
        // return setRenderPopupDataDownloading(true)
        return
      }
      case ProjectUpdateStatus.Retry: {
        startDataDownload()
        return
      }
      default:
        break
    }
  }, [projectUpdateStatus])

  const resolveProjectUpdateStatus = (): ProjectUpdateStatus => {
    if (!(projectId && Object.keys(dataDownloadStatus).includes(projectId))) {
      return ProjectUpdateStatus.Idle
    }
    const {state} = dataDownloadStatus[projectId]
    switch (state) {
      case DataDownloadState.Started:
        return ProjectUpdateStatus.Updating
      case DataDownloadState.Failed:
        return ProjectUpdateStatus.Failed
      case DataDownloadState.OutdatedCompleted:
        return ProjectUpdateStatus.Retry
      case DataDownloadState.Completed:
        return ProjectUpdateStatus.UpdateFinished
      case DataDownloadState.Unknown:
        return ProjectUpdateStatus.Idle
    }
  }

  const getTimeFramePeriod = (TimeFrame: TimeFrameOption) => {
    switch (TimeFrame) {
      case 'specificPeriod':
        return period
      default:
        return relativeTimeFramePeriod[TimeFrame]
    }
  }

  const generateTimeFrameText = (TimeFrame: TimeFrameOption) => {
    const [startDay, endDay] = getTimeFramePeriod(TimeFrame)
    const formatDate = (date: Date | undefined) => (date ? format(date, 'MMM d, yyyy') : undefined)
    const formattedDates = [formatDate(startDay), formatDate(endDay)]
    if (formattedDates.every(Boolean)) {
      formattedDates.splice(1, 0, '~')
    }
    return formattedDates.map((dateString) => dateString ?? '~').join(' ')
  }

  const handleChangeTimeFrame = (e: TimeFrameOption) => {
    setSelectedTimeFrame(e)
  }

  const startDataDownload = async () => {
    if ([ProjectUpdateStatus.Updating, ProjectUpdateStatus.Downloading].includes(projectUpdateStatus)) return
    /**
     * we use var requires here because native-file-system-adapter breaks jest
     * see: https://github.com/jimmywarting/native-file-system-adapter/issues/37
     */
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const nativeFileSystemAdapter = require('native-file-system-adapter')
    const fileHandle = await nativeFileSystemAdapter.showSaveFilePicker({
      _preferPolyfill: false,
      suggestedName: generateOutputZipFileName(),
      types: [{accept: {'application/zip': ['.zip']}}],
      excludeAcceptAllOption: false,
    })
    setFileHandle(fileHandle)
    alert('Please do not close or leave this page until the download process is finished.')
    setProjectUpdateStatus(ProjectUpdateStatus.Updating)
    doREQUEST_PROJECT_DATA_UPDATE_REQUEST({
      setRequestResult: setRequestResultOfProjectUpdate,
      payload: {
        projectId,
      },
    })
  }

  const handleSelectAll = () => {
    if (selectedAll) {
      return setSelectedParticipantList([])
    }
    return setSelectedParticipantList(participantList)
  }

  const handleChangePeriod = (value: DateObject[] | null) => {
    if (!value) return
    // if (value.length !== 2) return
    setPeriod(value.map((dateObject) => dateObject.toDate()))
  }

  const checkIfNeedUpgrade = () => {
    if (!hasActivePlanPurchase) setRenderUpgradePopup(true)
  }

  const endDataDownloadProcessWhenError = () => {
    setProjectUpdateStatus(ProjectUpdateStatus.Failed)
    doDATA_DOWNLOAD_STATUS_RESET()
    alert('OOPS! Something is wrong with the server!')
  }

  const generateOutputZipFileName = () => {
    const c = new Date()
    return `${encodeURIComponent(project?.name ?? '')}-${format(c, 'ddMMyyyy')}.zip`
  }

  return (
    <div
      css={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        overflow: 'scroll',
        background: color.background,
        zIndex: 104,
        top: 0,
        left: 0,
      }}
    >
      {RIF(renderUpgradePopup, <UpgradeContactPop setDisplayUpgradeContactPop={setRenderUpgradePopup} />)}
      {RIF(
        showConfirmDownloadComplete,
        <PopupDownloadSuccess setShowConfirmDownloadComplete={setShowConfirmDownloadComplete} />
      )}
      {RIF(
        [ProjectUpdateStatus.Updating, ProjectUpdateStatus.Downloading].includes(projectUpdateStatus),
        <PopupPreparingDataDownload downloadingFilename={downloadingFilename} />,
      )}

      {RIF(renderPopupDataDownloading, <PopupDataDownloading {...{setRenderPopupDataDownloading}} />)}

      {/* save bar at the top of the window */}
      <SaveBar
        hideButton
        closeAction={closeAction}
        title='Download Settings'
        html={
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.medium,
                marginRight: '4px',
              }}
            >
              Questions about your data?
            </p>
            <TextLink target='_blank' href='https://help.labfront.com/data-metrics-and-analytics'>
              Visit our Help Center.
            </TextLink>
          </div>
        }
      />

      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          height: '100%',
          width: '100%',
          paddingTop: '50px',
        }}
      >
        {/* left part of the window */}
        <div
          css={{
            width: 'calc(100vw - 354px)',
            height: '100%',
            padding: '40px 96px 136px 166px',
            position: 'relative',
          }}
        >
          {/* horizontal title bar with download data button */}
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '824px',
              marginBottom: '24px',
            }}
          >
            <div>
              <p
                css={{
                  fontSize: fontSize.h3,
                  fontWeight: fontWeight.thick,
                  marginBottom: '8px',
                }}
              >
                Prepare your data download
              </p>
              <p
                css={{
                  color: color.grey_600,
                  fontWeight: fontWeight.medium,
                  fontSize: fontSize.h7,
                }}
              >
                Please select the participants and time frame to be included in your download file.
              </p>
            </div>
            <Button onClick={startDataDownload}>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img css={{marginRight: '8px'}} width={18} src={DownloadWhiteIcon} />
                <p
                  css={{
                    fontWeight: fontWeight.thick,
                    color: color.white,
                  }}
                >
                  Download Data
                </p>
              </div>
            </Button>
          </div>
          <div
            css={{
              width: '824px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {/* participant selector */}
            <div
              css={{
                height: '500px',
                width: '400px',
                borderRadius: '5px',
                border: `1px solid ${color.grey_100}`,
                background: color.white,
              }}
            >
              <div
                css={{
                  width: '100%',
                  padding: '16px 24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: `1px solid ${color.grey_100}`,
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img width={20} css={{marginRight: '8px'}} src={ParticipantGreyIcon} />
                  <p
                    css={{
                      fontWeight: fontWeight.medium,
                      color: color.grey_600,
                    }}
                  >
                    Participants
                  </p>
                </div>
                <p
                  css={{
                    fontSize: fontSize.h7,
                    fontWeight: fontWeight.medium,
                    color: color.grey_600,
                  }}
                >
                  {selectedParticipantList.length} Participant(s) Select
                </p>
              </div>
              <div
                css={{
                  width: '100%',
                  padding: '24px',
                }}
              >
                <label
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginBottom: '16px',
                  }}
                >
                  <Checkbox value={selectedAll} onChange={handleSelectAll} />
                  <p
                    css={{
                      marginLeft: '8px',
                      fontSize: fontSize.h7,
                      fontWeight: fontWeight.medium,
                    }}
                  >
                    All participants
                  </p>
                </label>

                {/* participant selector table */}
                <div
                  css={{
                    width: '100%',
                    height: '374px',
                    padding: '16px',
                    background: color.background,
                    borderRadius: '8px',
                  }}
                >
                  <Scrollbars
                    autoHide={false}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    {participantList.map((participant: ParticipantStateType) => (
                      <ParticipantSelectorEntry
                        {...{
                          key: participant.id,
                          participant,
                          selectedParticipantList,
                          setSelectedParticipantList,
                        }}
                      />
                    ))}
                  </Scrollbars>
                </div>
              </div>
            </div>

            {/* time frame selector */}
            <div
              css={{
                height: '500px',
                width: '400px',
                borderRadius: '5px',
                border: `1px solid ${color.grey_100}`,
                background: color.white,
              }}
            >
              {/* horizontal TimeFrame bar */}
              <div
                css={{
                  width: '100%',
                  padding: '16px 24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: `1px solid ${color.grey_100}`,
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img width={20} css={{marginRight: '8px'}} src={ParticipantGreyIcon} />
                  <p
                    css={{
                      fontWeight: fontWeight.medium,
                      color: color.grey_600,
                    }}
                  >
                    Time Frame
                  </p>
                </div>
                <p
                  css={{
                    fontSize: fontSize.h7,
                    fontWeight: fontWeight.medium,
                    color: color.grey_600,
                  }}
                >
                  {generateTimeFrameText(selectedTimeFrame)}
                </p>
              </div>
              <ReactRadioGroup
                name='TimeFrameSelector'
                onChange={handleChangeTimeFrame}
                selectedValue={selectedTimeFrame}
              >
                <div
                  css={{
                    width: '100%',
                    padding: '20px 24px',
                  }}
                >
                  <label
                    css={{
                      width: '100%',
                      padding: '16px',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      border: `1px solid ${selectedTimeFrame === 'lastSevenDays' ? color.primary : 'transparent'}`,
                      marginBottom: '16px',
                      cursor: 'pointer',
                      ':hover': {
                        background: color.hover,
                      },
                    }}
                  >
                    <ReactRadio
                      css={{
                        width: '16px',
                        height: '16px',
                        margin: '0',
                      }}
                      value={'lastSevenDays'}
                    />
                    <div
                      css={{
                        marginLeft: '16px',
                      }}
                    >
                      <p
                        css={{
                          fontWeight: fontWeight.medium,
                          marginBottom: '4px',
                        }}
                      >
                        Last 7 Days
                      </p>
                      <p
                        css={{
                          fontWeight: fontWeight.medium,
                          fontSize: fontSize.h7,
                          color: color.grey_600,
                        }}
                      >
                        {generateTimeFrameText('lastSevenDays')}
                      </p>
                    </div>
                  </label>
                  <label
                    css={{
                      width: '100%',
                      padding: '16px',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      border: `1px solid ${selectedTimeFrame === 'lastThirtyDays' ? color.primary : 'transparent'}`,
                      marginBottom: '16px',
                      cursor: 'pointer',
                      ':hover': {
                        background: color.hover,
                      },
                    }}
                  >
                    <ReactRadio
                      css={{
                        width: '16px',
                        height: '16px',
                        margin: '0',
                      }}
                      value={'lastThirtyDays'}
                    />
                    <div
                      css={{
                        marginLeft: '16px',
                      }}
                    >
                      <p
                        css={{
                          fontWeight: fontWeight.medium,
                          marginBottom: '4px',
                        }}
                      >
                        Last 30 Days
                      </p>
                      <p
                        css={{
                          fontWeight: fontWeight.medium,
                          fontSize: fontSize.h7,
                          color: color.grey_600,
                        }}
                      >
                        {generateTimeFrameText('lastThirtyDays')}
                      </p>
                    </div>
                  </label>
                  <label
                    onClick={checkIfNeedUpgrade}
                    css={{
                      width: '100%',
                      padding: '16px',
                      borderRadius: '8px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      border: `1px solid ${selectedTimeFrame === 'allTime' ? color.primary : 'transparent'}`,
                      marginBottom: '16px',
                      cursor: 'pointer',
                      ':hover': {
                        background: color.hover,
                      },
                    }}
                  >
                    <div
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ReactRadio
                        disabled={!hasActivePlanPurchase}
                        css={{
                          width: '16px',
                          height: '16px',
                          margin: '0',
                        }}
                        value={'allTime'}
                      />
                      <div
                        css={{
                          marginLeft: '16px',
                        }}
                      >
                        <p
                          css={{
                            fontWeight: fontWeight.medium,
                            marginBottom: '4px',
                          }}
                        >
                          All Time
                        </p>
                        <p
                          css={{
                            fontWeight: fontWeight.medium,
                            fontSize: fontSize.h7,
                            color: color.grey_600,
                          }}
                        >
                          {generateTimeFrameText('allTime')}
                        </p>
                      </div>
                    </div>
                    {RIF(!hasActivePlanPurchase, <UpgradeTag />)}
                  </label>
                  <div
                    onClick={checkIfNeedUpgrade}
                    css={{
                      width: '100%',
                      borderRadius: '8px',
                      border: `1px solid ${selectedTimeFrame === 'specificPeriod' ? color.primary : 'transparent'}`,
                      marginBottom: '16px',
                      ':hover': {
                        background: color.hover,
                      },
                    }}
                  >
                    <label
                      css={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        cursor: 'pointer',
                        width: '100%',
                        padding: '16px',
                      }}
                    >
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ReactRadio
                          disabled={!hasActivePlanPurchase}
                          css={{
                            width: '16px',
                            height: '16px',
                            margin: '0',
                          }}
                          value={'specificPeriod'}
                        />
                        <div
                          css={{
                            marginLeft: '16px',
                          }}
                        >
                          <p
                            css={{
                              fontWeight: fontWeight.medium,
                            }}
                          >
                            Specific Period
                          </p>
                        </div>
                      </div>
                      {RIF(!hasActivePlanPurchase, <UpgradeTag />)}
                    </label>
                    <div
                      css={{
                        position: 'relative',
                        margin: '0 16px 16px 48px',
                      }}
                    >
                      <DatePicker
                        value={period}
                        onChange={handleChangePeriod}
                        range={true}
                        rangeHover={true}
                        format='MMM D, YYYY'
                        maxDate={new Date()}
                        disabled={selectedTimeFrame !== 'specificPeriod'}
                        style={{
                          padding: '9px 16px',
                          width: '284px',
                          height: '42px',
                          fontWeight: fontWeight.medium,
                          border: `1px solid ${color.grey_160}`,
                          color: selectedTimeFrame !== 'specificPeriod' ? color.disabled : color.black,
                        }}
                      />
                      <img
                        src={CalendarGreyIcon}
                        width={20}
                        css={{
                          position: 'absolute',
                          top: '11px',
                          right: '16px',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ReactRadioGroup>
            </div>
          </div>
        </div>
        <div
          css={{
            width: '354px',
            height: '100%',
            background: color.grey_60,
            padding: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p
            css={{
              width: '100%',
              fontSize: fontSize.h5,
              fontWeight: fontWeight.thick,
              marginBottom: '16px',
            }}
          >
            Turn data into insights with Labfront Analytics
          </p>
          <div
            css={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '40px',
            }}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                width: '100%',
              }}
            >
              <p
                css={{
                  width: '143px',
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                  color: color.grey_600,
                }}
              >
                Comprised of alumni from Harvard&apos;s Center for Dynamical Biomarkers, our team is dedicated to
                helping you maximize the value of your data.
              </p>
              <a
                href={'https://www.labfront.com/analytics/report'}
                target='_blank'
                css={{
                  textAlign: 'center',
                  width: '82px',
                  padding: '4px 8px',
                  background: color.black,
                  color: color.white,
                  fontSize: fontSize.h7,
                  fontWeight: fontWeight.medium,
                  borderRadius: '3px',
                }}
              >
                Learn More
              </a>
            </div>
            <img width={119} src={AnalyticsReportImg} />
          </div>
          <div
            css={{
              width: '100%',
              borderRadius: '8px',
              background: color.white,
              padding: '16px',
            }}
          >
            <p
              css={{
                fontWeight: fontWeight.thick,
                fontSize: fontSize.h7,
                color: color.grey_600,
                marginBottom: '8px',
              }}
            >
              Useful Resources
            </p>
            <ResourcesLink
              icon={<img src={HRVCourseImg} width={57} />}
              text='HRV Course'
              href='https://www.labfront.com/course/hrv-1'
            />
            <ResourcesLink
              icon={<img src={ActigraphyCollectionImg} width={57} />}
              text='Actigraphy Colloection'
              href='https://www.labfront.com/collection/actigraphy'
            />
            <ResourcesLink
              icon={<img src={ResourcesIcon} width={16} />}
              text='Labfront Data Dictionary'
              href='https://drive.google.com/file/d/1F_GjQM7_qDLDveXfm605j6ePNhYCfzVq/view'
            />
            <ResourcesLink
              icon={<img src={ResourcesIcon} width={16} />}
              text='Understanding Garmin Sleep Data'
              href='https://help.labfront.com/garmin-sleep-data-defined'
            />
            <ResourcesLink
              icon={<img src={ResourcesIcon} width={16} />}
              text='How Data is Organized in Labfront'
              href='https://help.labfront.com/how-data-is-organized'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface ParticipantSelectorEntryProps {
  participant: ParticipantStateType
  selectedParticipantList: ParticipantStateType[] | never[]
  setSelectedParticipantList: Dispatch<SetStateAction<ParticipantStateType[]>>
  key: string
}

const ParticipantSelectorEntry = (props: ParticipantSelectorEntryProps) => {
  const {fontSize, fontWeight} = selectTheme()
  const {participant, selectedParticipantList, setSelectedParticipantList} = props
  const selected =
    selectedParticipantList.findIndex((selectedParticipant) => selectedParticipant.id === participant.id) !== -1

  const handleSelectParticipant = () => {
    setSelectedParticipantList((prevState) => {
      const newState = [...prevState]
      if (selected) {
        _.remove(newState, (selectedParticipant) => {
          return selectedParticipant.id === participant.id
        })

        return newState
      }
      return [...newState, participant]
    })
  }

  return (
    <label
      css={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '12px',
        cursor: 'pointer',
      }}
    >
      <Checkbox value={selected} onChange={handleSelectParticipant} />
      <p
        css={{
          marginLeft: '8px',
          fontSize: fontSize.h7,
          fontWeight: fontWeight.medium,
        }}
      >
        {participant.insignia}
      </p>
    </label>
  )
}

interface ResourcesLinkProps {
  icon: JSX.Element
  text: string
  href: string
}

const ResourcesLink = (props: ResourcesLinkProps) => {
  const {icon, text, href} = props
  const {color, fontSize, fontWeight} = selectTheme()

  return (
    <a
      href={href}
      target='_blank'
      css={{
        width: '100%',
        marginTop: '8px',
        background: color.background,
        borderRadius: '8px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {icon}
      <p
        css={{
          marginLeft: '8px',
          fontSize: fontSize.h7,
          fontWeight: fontWeight.medium,
        }}
      >
        {text}
      </p>
    </a>
  )
}
